import {
    Button,
    Card,
    CardBody,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown
}                               from "reactstrap"

import { 
    MapPin,
    RotateCcw, 
    Share2 
}                               from "react-feather"

import { 
    useContext, 
    useEffect, 
    useState
}                               from "react"

import { 
    Marker,
    Popup
}                               from "react-map-gl"
import { GisContext }           from "../../context/GisContext"

import WidgetKejagung           from "./component/WidgetKejagung"
import WidgetFilter             from "./component/WidgetFilter"
import WidgetPerkara            from "./component/WidgetPerkara"
import WidgetJumlahProduk       from "./component/WidgetJumlahProduk"
import WidgetJumlahKejadian     from "./component/WidgetJumlahKejadian"
import WidgetTempatKejadian     from "./component/WidgetTempatKejadian"
import WidgetTerlibatKejadian   from "./component/WidgetTerlibatKejadian"
import WidgetMap                from "./component/WidgetMap"
import WidgetJumlahLaporan      from "./component/WidgetJumlahLaporan"

import MapIcon                  from "../../assets/images/icons/svg/IcPin"
import Helper                   from "../../helper"
import Skeleton                 from "react-loading-skeleton"

const currentDate  = new Date()
const currentMonth = currentDate.getMonth()
const currentYear  = currentDate.getFullYear()
const lastDate     = new Date(currentYear, currentMonth + 1, 0).getDate()

const GIS = () => {

    const { fallbackImage_ }        = Helper

    const share = [
        "D.IN 1",
        "D.IN 2",
        "D.IN 3",
        "D.IN 4",
        "D.IN 5",
        "D.IN 6"
    ]

    const {
        GisData,
        getDataGis,
        loading
    } = useContext(GisContext)

    const [selectedMarker, setSelectedMarker] = useState(null)
    const [listMarker, setListMarker]         = useState([])
    const [typeMarker, setTypeMarker]         = useState("scene")
    const [periodeStr, setPeriodeStr]         = useState(`${currentYear}-${currentMonth + 1}-1 - ${currentYear}-${currentMonth + 1}-${lastDate}`)

    const renderMarker = (data, type) => {
        return (
            <>
                {
                    data?.map((dt, idx) => {
                        return (
                            <>
                                {
                                    !isNaN(parseFloat(dt?.location?.latitude)) && !isNaN(parseFloat(dt?.location?.longitude)) ?
                                        <Marker
                                            key         = {idx}
                                            latitude    = {dt?.location?.latitude}
                                            longitude   = {dt?.location?.longitude}
                                        >
                                            <button 
                                                style   = {{ border: "none", background: "none" }}
                                                onClick = {() => {
                                                    setSelectedMarker({...dt})
                                                }}
                                            >
                                                {
                                                    type === "scene" ? (
                                                        <MapIcon
                                                    
                                                        />
                                                    ) : (
                                                        <img
                                                            className   = "me-1 rounded-circle"
                                                            src         = {dt?.icon}
                                                            style       = {{
                                                                width   : '24px',
                                                                height  : '24px'
                                                            }}
                                                            onError     = {fallbackImage_}
                                                        />
                                                    )
                                                }
                                            </button>
                                        </Marker> : ""
                                }
                            </>
                        )
                    })
                }

                {
                    selectedMarker?.location && (!isNaN(parseFloat(selectedMarker?.location?.latitude)) && !isNaN(parseFloat(selectedMarker?.location?.longitude))) && (
                        <>
                            <Popup
                                anchor       = "top"
                                tipSize      = {10}
                                onClose      = {setSelectedMarker}
                                latitude     = {selectedMarker?.location?.latitude}
                                longitude    = {selectedMarker?.location?.longitude}
                                closeOnClick = {false}
                            >
                                <div
                                    className   = "pt-1 d-flex1 text-center justify-content-betwee1n"
                                    style       = {{
                                        width : '150px'
                                    }}
                                >
                                    <div className="">
                                        {selectedMarker?.location?.name}
                                    </div>

                                    <div className="text-center">
                                        {selectedMarker?.report_ids?.length} Berkas
                                    </div>
                                </div>
                                
                            </Popup>
                        </>
                    )
                }
            </>
        )
    }

    useEffect(() => {
        const params = {
            start_date : `${currentYear}-${currentMonth + 1}-1`,
            end_date   : `${currentYear}-${currentMonth + 1}-${lastDate}`,
        }
        getDataGis(params)
    }, [])

    useEffect(() => {
        if (GisData) {
            setListMarker(GisData.scene)
        }
    }, [GisData])
    
    return (
        <>
            <div className="mb-2 d-flex justify-content-between">
                <div>
                    <div className="h3 text-uppercase"><strong>Data Peta</strong></div>
                    <div className="text-uppercase"><strong>Wilayah Perkara Kejaksaan Agung RI</strong></div>
                </div>
                <div
                    className="mt-1 d-flex"
                >
                    <UncontrolledDropdown className=''>
                        <DropdownToggle className='cursor-pointer btn btn-primary btn-icon me-1' tag='span'>
                            <Share2 size={18} />
                        </DropdownToggle>
                        <DropdownMenu start>
                            {
                                share !== null &&
                                    share?.map((dt) => {
                                        return (
                                            <DropdownItem className='d-flex align-items-center w-100'>
                                                Kirim Ke {dt}
                                            </DropdownItem>
                                        )
                                    })
                            }
                        </DropdownMenu>
                    </UncontrolledDropdown>

                    <Button
                        size        = "sm"
                        color       = "primary"
                        className   = "btn-icon"
                        onClick     = {() => {

                        }}
                    >
                        <RotateCcw
                                
                        />
                    </Button>
                </div>
            </div>
            
            <div
                className   = "row pb-2"
            >
                <div
                    className   = "col-lg-3 col-md-12 col-12 d-flex flex-column"
                >
                    <div>
                        <WidgetKejagung />
                    </div>

                    <div className="flex-grow-1">
                        <WidgetFilter 
                            getData         = {getDataGis}
                            setPeriodeStr   = {setPeriodeStr}
                            setTypeMarker   = {setTypeMarker}
                        />
                    </div>
                </div>

                <div
                    className   = "col-lg-6 col-md-6 col-12"
                >
                    {
                        loading ? (
                            <Skeleton height={'530px'}/>
                        ) : (
                            <WidgetMap 
                                height      = {500}
                                handleReset = {() => {
                                    setListMarker(GisData?.scene)
                                    setTypeMarker("scene")
                                }}
                            >
                                {renderMarker(listMarker, typeMarker)}
                            </WidgetMap>
                        )
                    }
                </div>

                <div
                    className   = "col-lg-3 col-md-6 col-12 d-flex flex-column"
                >
                     <div className="flex-grow-1">
                        {
                            loading ? (
                                <Skeleton height={'256px'} className="mb-1"/>
                            ) : (
                                <WidgetTempatKejadian 
                                    data                = {GisData?.scene}
                                    setSelectedMarker   = {setSelectedMarker}
                                />
                            )
                        }
                    </div>
                    
                    <div className="flex-grow-1">
                        {
                            loading ? (
                                <Skeleton height={'256px'}/>
                            ) : (
                                <WidgetTerlibatKejadian 
                                    data                = {GisData?.involved_in_the_incident}
                                />
                            )
                        }
                        
                    </div>
                </div>
            </div>

            <div
                className   = "row row-cols-lg-4 row-cols-md-2 row-cols-2 mb-5 mb-lg-2"
            >
                <div
                    className   = "col-lg-3 col-12 d-flex flex-column"
                >
                    {
                        loading ? (
                            <Skeleton height={'380px'}/>
                        ) : (
                            <WidgetPerkara 
                                data    = {GisData?.kind_of_thing}
                            />
                        )
                    }
                    
                </div>

                <div
                    className   = "col-lg-3 col-12 d-flex flex-column"
                >
                    {
                        loading ? (
                            <Skeleton height={'380px'}/>
                        ) : (
                            <WidgetJumlahLaporan 
                                data       = {GisData?.intel_product_per_dir_period}
                                periodeStr = {periodeStr}
                            />
                        )
                    }
                </div>

                <div
                    className   = "col-lg-3 col-12 d-flex flex-column"
                >
                    {
                        loading ? (
                            <Skeleton height={'380px'}/>
                        ) : (
                            <WidgetJumlahProduk 
                                data    = {GisData?.intel_product_per_dir}
                            />
                        )
                    }
                </div>

                <div
                    className   = "col-lg-3 col-12 d-flex flex-column"
                >
                    {
                        loading ? (
                            <Skeleton height={'380px'}/>
                        ) : (
                            <WidgetJumlahKejadian 
                                data              = {GisData?.events_per_sector}
                                setListMarker     = {setListMarker}
                                setTypeMarker     = {setTypeMarker}
                            />
                        )
                    }
                </div>
            </div>
        </>
    )
}

export default GIS