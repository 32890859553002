import * as yup from "yup"

const RecapitulationFilterValidationUser = yup
	.object()
	.shape({
		user			: yup.object().shape({ value : yup.number().required("Isian Tidak Boleh Kosong")}).required("Isian Tidak Boleh Kosong"),
	})
	.required()

export default RecapitulationFilterValidationUser
