import DataTablesReportProfile      from "../../../@core/components/data-tables-report-profile"

const ProfileReportTable = (props) => {

    const {
        reportData,
		header,
        userId
    }               = props

    return (
        <>
           <DataTablesReportProfile
				print				= {true}
				header              = {header}
				reportData			= {reportData}
                userId              = {userId}
			/>
        </>
    )
}

export default ProfileReportTable