import { useEffect, useState }      from "react"
import { getFieldName }             from "../../../utility/report-field-id"

import RecyleBinTable               from "./component/RecyleBinTable"
import ModalDelete                  from "../../other/ModalDelete"
import ModalRestore                 from "../../other/ModalRestore"
import RecyleBinAPI                 from "../../../service/pages/recyle-bin"
import reportAPI                    from "../../../service/report"
import userManagementAPI            from "../../../service/pages/user-management"

const RecyleBinConfiguration = () => {

    const [params, setParams]                               = useState(null)
    const [isLoading, setIsLoading]                         = useState(true)

    const [selected, setSelected]                           = useState([])
    const [isDeleteModalVisible, setIsDeleteModalVisible]   = useState(false)
    const [isRestoreModalVisible, setIsRestoreModalVisible] = useState(false)
    const [clearSelected, setClearSelected]                 = useState(false)
    const [pagination, setPagination]                       = useState(false)

    const [data, setData]                                   = useState([])

    const getExpiredStatusAprrovement = (track_status) => {
        const filter_ = track_status.filter((data) => data.status_approvement === 2)
        if (filter_.length > 0) {
            return moment(filter_[0]).add(7, 'd').format('DD MMMM YYYY')
        } else {
            return "-"
        }
    }

    const getLastStatusApprovement = (track_status) => {

		if (track_status?.length > 0) {
			const lastIndex_ = track_status[track_status.length - 1]

            const obj_ = {
                message : lastIndex_.messages
            }

            if (lastIndex_.status_approvement === 1) {
                obj_.id = 1 
                obj_.status = "Baru Dibuat"
            } else if (lastIndex_.status_approvement === 2) {
                obj_.id = 2
                obj_.status = "Disetujui"
            } else if (lastIndex_.status_approvement === 3) {
                obj_.id = 3
                obj_.status = "Diproses"
            } else if (lastIndex_.status_approvement === 4) {
                obj_.id = 4
                obj_.status = "Ditolak"
            } else if (lastIndex_.status_approvement === 5) {
                obj_.id = 5
                obj_.status = "Diperbaiki"
            } else if (lastIndex_.status_approvement === 6) {
                obj_.id = 6
                obj_.status = "Disposisi"
            } else if (lastIndex_.status_approvement === 7) {
                obj_.id = 7
                obj_.status = "Selesai"
            }

			return obj_
		} else {
            return {
                id :1,
                status : 'Baru Dibuat'
            }
        }
	}

    const getSumberSuratData = (id) => {
        const params = {
            id      : id,
        }

        return reportAPI.getReport(params)
    }

    const getData = async (params) => {
        setIsLoading(true)
        setSelected([])
        setClearSelected(true)
        
        RecyleBinAPI.getRecyleBin(params)
            .then(async (res) => {

                const resultData = []

                const pagination = {
                    count       : res.count,
                    page        : res.page,
                    page_size   : res.page_size,
                    next        : res.next,
                    previous    : res.previous
                }
                
                setPagination(pagination)

                if (Array.isArray(res?.results)) {
                    await Promise.all(
                        await res?.results?.map(async (dt, index) => {
                            const getReportType     = await reportAPI.getReportType(dt.report?.report_type?.id)
                            let data_               = {}
                            data_                   = await getFieldName(dt.report, getReportType?.data?.field_report_type)
    
                            // GET MORE FIELD
                            data_.idRecyle          = dt?.id
                            data_.is_draft          = dt.report?.is_draft
                            data_.created_by        = dt.report?.created_by
                            data_.track_status      = dt.report?.report_approvement_report
                            data_.expired_date      = await getExpiredStatusAprrovement(dt.report?.report_approvement_report)
                            data_.last_track_status = await getLastStatusApprovement(dt.report?.report_approvement_report)
    
                            // GET WORKUNIT
                            const getUserData_      = await userManagementAPI.getUserManagement({user: parseInt(dt.report?.created_by)})
                            data_.created_by        = getUserData_?.results[0]
                            data_.satuan_kerja      = getUserData_?.results[0]?.work_unit?.name
    
                            // GET SUMBER SURAT
                            if (!isNaN(parseInt(data_?.sumber_surat))) {
                                const getSumberSurat    = await getSumberSuratData(parseInt(data_?.sumber_surat))
                                if (getSumberSurat.count > 0) {
                                    const getSumberSuratField   = await reportAPI.getReportType(getSumberSurat?.results[0]?.report_type?.id)
                                    data_.sumber_surat          = await getFieldName(getSumberSurat.results[0], getSumberSuratField.data.field_report_type)
                                } else {
                                    data_.sumber_surat          = '-'
                                }
                            }
    
                            data_.index             = index + 1
                            resultData.push(data_)
                        })
                    )
                }

                setIsLoading(false)

                const sortedData = [...resultData].sort((a, b) => {
                    if (a.index < b.index) return -1
                    if (a.index > b.index) return 1
                    return 0
                  })

                setData(sortedData)
            })
    }

    const handleDelete = () => {
        console.log("handleDelete", data)
        if (selected.length > 0) {
            selected?.map((data) => {
                RecyleBinAPI.deletePermanent(data.idRecyle, params).then(
                    () => {
                        getData()
                    }
                ).catch( 
                    err => {
                        console.log(err)
                    }
                )
            })

            setSelected([])
        }
    }

    const handleRestore = () => {
        if (selected.length > 0) {
            selected.map((data) => (
                RecyleBinAPI.restore(data.idRecyle).then(
                    () => {
                        getData()
                    }
                ).catch( 
                    err => {
                        console.log(err)
                    }
                )
            ))

            setSelected([])
        }
    }

    useEffect(() => {
        if (clearSelected) {
            setClearSelected(!clearSelected)
        }
    }, [clearSelected])

    useEffect(() => {
        if (!isDeleteModalVisible) {
            setSelected([])
            setClearSelected(true)
        }
    }, [isDeleteModalVisible])

    useEffect(() => {
        getData()
    }, [])

    return (
        <>
            <RecyleBinTable
                data                    = {data}
                params                  = {params}
                setData                 = {setData}
                getData                 = {getData}
                loading                 = {isLoading}
                selected                = {selected}
                setParams               = {setParams}
                pagination              = {pagination}
                setSelected             = {setSelected}
                clearSelected           = {clearSelected}
                setClearSelected        = {setClearSelected}
                setIsDeleteModalVisible = {setIsDeleteModalVisible}
                setIsRestoreModalVisible= {setIsRestoreModalVisible}
            />

            <ModalDelete
                show        = {isDeleteModalVisible}
                setShow     = {setIsDeleteModalVisible}
                size        = "sm"
                title       = "Hapus Recyle Bin"
                handleDelete= {handleDelete}
                data        = {selected}
                type        = "recyle_bin"
            />

            <ModalRestore
                show        = {isRestoreModalVisible}
                setShow     = {setIsRestoreModalVisible}
                size        = "sm"
                title       = "Restore Recyle Bin"
                handleDelete= {handleRestore}
                data        = {selected}
            />
        </>
    )
}

export default RecyleBinConfiguration