import {get, delete_, post, put, patch}  from '../../index'

//get
const getReportDaily                       = (params) => get(`/report`, params)
const getDashboarData                       = (params) => get(`/dashboard/report`, params)
const getDashboardStatus                    = (params) => get(`/dashboard/report?data_type=report_by_status_count`, params)

//post
const reducePerformancePoint                = (formData) => post('/performance/log-point/deduction', formData)


const DashboardAPI = {
    //Get
    getReportDaily,
    getDashboarData,
    getDashboardStatus,
   

    // Post
    reducePerformancePoint,
}

export default DashboardAPI