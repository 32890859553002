import { 
	Fragment, 
	useContext, 
	useEffect, 
	useState 
} from "react"

import {
	Button,
	Input,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
} from "reactstrap"

import { ReportContext }			 	from "../../../context/reportContext"
import { getReportField } 				from "../../../utility/report-type-id"
import SubmitButton 					from "../../../@core/components/submit-button"
import moment 							from "moment"

const ModalInstructionData = (props) => {

	const {
		dataInstruction, 
        setDataInstruction,
		showModalInstructionData, 
		setShowModalInstructionData,
		reportTypeField,

		updateReport
	} = useContext(ReportContext)

	const [loading, setLoading] 					= useState(false)
	const [keterangan, setKeterangan]				= useState("")

	useEffect(() => {
		if (!showModalInstructionData) {
			setShowModalInstructionData(null)
		}
	}, [showModalInstructionData])

	useEffect(() => {
		
		if (dataInstruction) {
			if (dataInstruction?.data?.petunjuk_pimpinan && dataInstruction?.data?.petunjuk_pimpinan !== "-") {
				const parser            = new DOMParser().parseFromString(dataInstruction?.data?.petunjuk_pimpinan, "text/html")
				const htmlDoc           = parser.all
				const body              = htmlDoc[2]
				setKeterangan(body.innerText)
			} else {
				setKeterangan("")
			}
		} else {
			setKeterangan("")
		}
	}, [dataInstruction])

	return (
		<Fragment>
			<Modal
				size		= {"md"}
				isOpen		= {showModalInstructionData}
				centered	= {true}
				onClosed	= {() => { setDataInstruction(null) }}
				scrollable	= {true}
			>
				<ModalHeader toggle={() => {
					setShowModalInstructionData(false)
					setDataInstruction(null)
				}}>Petunjuk Pimpinan</ModalHeader>

				<ModalBody className="">
					{/* {console.log("MODALREVIEW", dataInstruction)} */}
					<p style={{textAlign:"justify"}} dangerouslySetInnerHTML={{ __html: keterangan }}></p>
				</ModalBody>

				<ModalFooter className="d-flex justify-content-end">
					<Button
						color	= "primary"
						onClick	= {() => {
							setShowModalInstructionData(false)
						}}
					>
						Tutup
					</Button>
				</ModalFooter>
			</Modal>
		</Fragment>
	)
}

export default ModalInstructionData
