import {get, delete_, post, put, patch}  from '../../index'

//get
const getActivityAgent                      = (params) => get(`/performance/personal-performance/last-activity`, params)
const getActivityWorkunit                   = (params) => get(`/performance/work-unit-performance/last-activity`, params)
const getPerformanceAgent                   = (params) => get(`/performance/personal-performance`, params)
const getPerformanceWorkunit                = (params) => get(`/performance/work-unit-performance`, params)
const getLogPointPersonal                   = (params) => get(`/performance/log-point`, params)
const getLogPointWorkunit                   = (params) => get(`/performance/log-point/work-unit`, params)


const getPersonalBadge                      = (params) => get(`/performance/personal-badge`, params)
const getWorkunitBadge                      = (params) => get(`/performance/work-unit-badge`, params)


//post
const reducePerformancePoint                = (formData) => post('/performance/log-point/deduction', formData)


const PerformanceAPI = {
    //Get
    getWorkunitBadge,
    getActivityAgent,
    getPersonalBadge,
    getLogPointPersonal,
    getActivityWorkunit,
    getPerformanceAgent,
    getLogPointWorkunit,
    getPerformanceWorkunit,

    // Post
    reducePerformancePoint,
}

export default PerformanceAPI