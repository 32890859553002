import { param } from 'jquery'
import {get, delete_, post, put, patch}  from '../../index'

//get
const getNotification                   = (params) => get('/notification/notify/', params)

//post
const createList               = (formData) => post(`/communication/room/create/`, formData)

//patch
const notificationHasRead                 = (id) => put(`/notification/notify/mark-as-read/${id}`)

//delete
const deleteMessage            = (id) => delete_(`/communication/message/${id}/delete/`)

const NotificationAPI = {
    // Get
    getNotification,
    
    // Post
    createList,
 
    // Patch
    notificationHasRead,
   
    // Delete
    deleteMessage,
}

export default NotificationAPI