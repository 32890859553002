import moment from "moment"

const mergeValueWithSameKey = (array) => {
    // Create an object to store the merged values
    const mergedData = {}

    // Iterate through the array
    array.forEach((item) => {
        const keys = Object.keys(item)

        keys.forEach((key) => {
            if (!mergedData[key]) {
                // If the key doesn't exist in mergedData, store the value directly
                mergedData[key] = item[key]
            } else {
                // If the key already exists, check if it's an array
                if (!Array.isArray(mergedData[key])) {
                    // If it's not an array, convert it to an array with the existing value and the current value
                    mergedData[key] = [mergedData[key], item[key]]
                } else {
                    // If it's already an array, push the current value into the array
                    mergedData[key].push(item[key])
                }
            }
        })
    })

    return mergedData
}

export const getFieldName = (fields, fieldTypeId) => {
    
    const {
        report_values : fieldsName
    } = fields

    if (fieldsName?.length > 0) {
        const newData_ = []
        
        newData_.push({created_at: moment(fields?.created_at).format('DD MMMM YYYY')})

        //looping semua field
        fieldsName?.map((data) => {
            //looping field dari report tertentu
            fieldTypeId?.map((fieldData) => (
                (fieldData.id === data.field) && 
                    newData_.push({[fieldData.name.replace(/-/g, '_')] : data.value})
            ))
        })
        
        //merge value when have same key
        const finalData_ = [mergeValueWithSameKey(newData_)]

        const object_ = {}
        object_['report_id'] = fieldsName[0].report

        for (const item of finalData_) {
            Object.assign(object_, item)
        }

        return object_
    }
}