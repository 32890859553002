import { 
    Button,
    Input,
    InputGroup, 
    Label, 
    Modal, 
    ModalBody, 
    ModalFooter, 
    ModalHeader 
}                           from "reactstrap"
import { useEffect, useState }         from "react"
import { Plus, X }          from "react-feather"
import Select               from 'react-select'
import AssessmentIndicatorAPI from "../../../../service/pages/assesment-indicator"
import toast from "react-hot-toast"

const EvaluationIndicatorCreateCategory = ({
    show,
    setShow,
    title,
    size,
    value,
    getData
}) => {


    const [data, setData]               = useState([
        {
            indicator : {
                label : "Register",
                value : "register"
            },
            point     : 0,
            exp_point : 0
        }
    ])

    const [indicator, setIndicator]     = useState([
        {
            label : "Register",
            value : "register"
        }, {
            label : "Analisis",
            value : "analysis"
        }
    ])

    const handleCreate = (data) => {
        {
            data.map((val) => {
                const formData = {
                    name  : val?.indicator?.value,
                    note  : val?.indicator?.value,
                    performance_point : val?.point,
                    exp_point : val?.exp_point
                }
            AssessmentIndicatorAPI.createPoint(formData)
                .then((res) => {
                    toast.success('Point Berhasil Di Tambahkan')
                    getData()
                    setShow(false)
                })
                .catch(() => {
                    toast.error('Point Gagal Di Tambahkan')

                })

            })
        }
    }

    const handleUpdate = (data) => {
        {
            data.map((val) => {
                const formData = {
                    name  : val?.indicator?.value,
                    note  : val?.indicator?.value,
                    performance_point : val?.point,
                    exp_point : val?.exp_point
                }
            AssessmentIndicatorAPI.editData('point', value?.id, formData)
                .then((res) => {
                    toast.success('Point Berhasil Di Edit')
                    getData()
                    setShow(false)
                })
                .catch(() => {
                    toast.error('Point Gagal Di Edit')

                })

            })
        }
    }

    const onSubmit = (data) => {
        if (value !== null) {
            handleUpdate(data)
        } else {
            handleCreate(data)
        }
    }

    const handleAddConfig = () => {
        setData([
            ...data, {
                indicator : {
                    label : "Jumlah Register",
                    value : "register"
                },
                point     : 0,
                exp_point : 0
            }
        ])
    }

    const handleUpdateConfig = () => {
        const temp = []
        data?.map((dt) => {
            temp.push(dt)
        })
        setData(temp)
        setIndicator(indicator)
    }

    const handleRemoveConfig = (idx) => {
        const temp = []
        data?.map((dt, index) => {
            if (index !== idx) {
                temp.push(dt)
            }
        })
        setData(temp)
    }

    useEffect(() => {
        if (value) {
           setData([
            {
                indicator : {
                    label :  value?.name,
                    value :  value?.name
                },
                point     : value?.performance_point,
                exp_point : value?.exp_point
            }
        ])
     
        }
    }, [value])

    return (
        <>
            <Modal
                isOpen  = {show} 
                toggle  = {() => { setShow(!show) }} 
                size    = {size || "sm"}
            >
                <ModalHeader 
                    toggle={() => setShow(!show)} 
                > 
                    {title}
                </ModalHeader>
                <ModalBody>
                    <div>
                        {
                            data?.map((dt, idx) => {
                                return (
                                    <>
                                        <div
                                            className="d-flex justify-content-between mb-1"
                                        >
                                            <div
                                                className="col-5"
                                            >
                                                <Label><small>Nama Indikator</small></Label>
                                                <Select
                                                    className           = "react-select"
                                                    classNamePrefix     = "select"
                                                    placeholder         = "Nama Indikator"
                                                    options             = {indicator}
                                                    value               = {dt.indicator}
                                                    onChange            = {(e) => {
                                                        dt.indicator    = e
                                                        handleUpdateConfig(idx)
                                                    }}
                                                />
                                            </div>

                                            <div
                                                className="col-3 px-1"
                                            >
                                                <Label><small>Point</small></Label>
                                                <Input
                                                    placeholder         = "Point"
                                                    type                = "number"
                                                    value               = {dt.point}
                                                    onChange            = {(e) => {
                                                        dt.point        = e.target.value
                                                        handleUpdateConfig(idx)
                                                    }}
                                                />
                                            </div>

                                            <div
                                                className="col-3 px-1"
                                            >
                                                <Label><small>Exp Point</small></Label>
                                                <Input
                                                    placeholder         = "Exp Point"
                                                    type                = "number"
                                                    value               = {dt.exp_point}
                                                    onChange            = {(e) => {
                                                        dt.exp_point    = e.target.value
                                                        handleUpdateConfig(idx)
                                                    }}
                                                />
                                            </div>

                                            <div
                                                className="col-1 text-center"
                                                style={{
                                                    paddingTop : '24px'
                                                }}
                                            >
                                                {
                                                    idx !== 0 &&
                                                    <Button
                                                        color       = "dark"
                                                        size        = "sm"
                                                        className   = "p-0 m-0"
                                                        style       = {{
                                                            width   : '38px',
                                                            height  : '38px'
                                                        }}
                                                        onClick     = {() => {
                                                            handleRemoveConfig(idx)
                                                        }}
                                                        outline
                                                    >
                                                        <X
                                                            size    = {20}
                                                        />
                                                    </Button>
                                                }
                                            </div>
                                        </div>
                                    </>
                                )
                            })
                        }
                        <div
                            className       = "mt-1"
                        >
                            {
                                !value && (
                                     <Button
                                        color       = "primary"
                                        size        = "sm"
                                        className   = ""
                                        onClick     = {() => {
                                            handleAddConfig()
                                        }}
                                        outline
                                    >
                                        <Plus /> Tambah Pengaturan Nilai
                                    </Button>
                                )
                            }
                           
                        </div>
                    </div>
                </ModalBody>

                <ModalFooter>
                    <div
                        className   = "d-flex justify-content-between col-12"
                    >
                        <Button
                            color   = "primary"
                            outline
                            onClick = {() => {
                                setShow(false)
                            }}
                        >
                            Batal
                        </Button>

                        <Button
                            color   = "primary"
                            onClick = {() => {
                                onSubmit(data)
                            }}
                        >
                            Simpan
                        </Button>
                    </div>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default EvaluationIndicatorCreateCategory