import { 
    useState,
    createContext,
    useEffect
}                       from "react"

//Context
const WorkunitLevelContext  = createContext(null)

//API
import workunitLevelAPI      from "../service/pages/workunit-level"

const WorkunitLevelProvider = ({ children }) => {

    //State
    const [workunitLevelOption, setWorkunitLevelOption]   = useState([])

    const getDataAll = () => {
        
        workunitLevelAPI.getWorkunitLevel({
            page_size : 100
        }).then(
            res => {

                const formatData_ = []

                if (res.count !== 0) {
                    res.results.map((data) => {
                        formatData_.push({
                            label : data?.name,
                            value : data?.id
                        })
                    })

                    setWorkunitLevelOption(formatData_)
                }

            }
        ).catch(
            err => {
                console.log(err, 'err')
            }
        )
    }

    useEffect(() => {
        if (localStorage.getItem('userData')) {
            getDataAll()
        }
    }, [])

    return <WorkunitLevelContext.Provider
        value={{
            workunitLevelOption
        }}
    > 
        {children}
    </WorkunitLevelContext.Provider>
}

export { WorkunitLevelContext, WorkunitLevelProvider }