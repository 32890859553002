import { 
    useContext, 
    useEffect, 
    useState 
}                           from "react"

import { 
    Button, 
    Modal, 
    ModalBody, 
    ModalFooter, 
    ModalHeader 
}                           from "reactstrap"

import { 
    CheckCircle, 
    Edit2, 
    Slash, 
    Trash 
}                           from "react-feather"

import { RoleContext }      from "../../../../context/roleContext"

import DataTablesGlobal     from "../../../../@core/components/data-tables-global"
import ModalField           from "../../../other/ModalField"

const CategoryTable = ({
    data,
    type,
	params,
	getData,
	loading,
	setParams,
	pagination,
	setIsCreateModalVisible,
	setIsUpdateModalVisible,
	setIsDeleteModalVisible,
	
    selected,
	setSelected,
	clearSelected,
	setClearSelected
}) => {
    
    const [hideColumn, setHideColumn]                       = useState([])
    const [modalIsActive, setModalIsActive]                 = useState(false) 
	const [isHeaderConfigVisible, setIsHeaderConfigVisible] = useState(false) 
    const [selectedRole, setSelectedRole]                   = useState(null)

    const header = [
        {
            omit            : hideColumn[0] === false,
            name            : 'Nama',
            center          : false,
            wrap            : true,
            compact         : true,
            selector        : row => { return (<div className="d-flex justify-content-start align-items-center text-start"><p className="m-0">{row.report_type_name ? row.report_type_name : row.name}</p></div>) },
            sortable		: true
        },
        {
            omit            : hideColumn[1] === false,
            name            : 'Urutan',
            center          : true,
            // wrap            : false,
            // compact         : false,
            selector        : row => row.sequence,
            // sortable		: true
        },
        {
            omit            : hideColumn[2] === false,
            name            : 'Aksi',
            center          : true,
            wrap            : true,
            compact         : true,
            selector        : row => (
                <>
                    <Button
                        color       = "light"
                        size        = "sm"
                        onClick     = {() => {
                            setClearSelected(true)
                            setSelected([row])
                            setIsCreateModalVisible(true)
                        }}
                    >
                        <Edit2
                            size        = {17}
                            className   = "text-primary"
                        />
                    </Button>

                    <Button
                        color       = "light"
                        size        = "sm"
                        onClick     = {() => {
                            setClearSelected(true)
                            setSelected([row])
                            setIsDeleteModalVisible(true)
                        }}
                    >
                        <Trash
                            size        = {17}
                            className   = "text-primary"
                        />
                    </Button>
                </>
            ),
            sortable		: true
        }
    ]

    const {
        updateRoleStatus
    } = useContext(RoleContext)

    useEffect(() => {
		setHideColumn(() => header.map(() => true))
	}, [])

    return (
        <>
            <DataTablesGlobal
               data               		= {data}
               limit              		= {pagination?.page_size}
               header             		= {header}
               params             		= {params}
               getData            		= {getData}
               loading            		= {loading}
               selected           		= {selected}
               totalData          		= {pagination?.count}
               setParams          		= {setParams}
               setSelected        		= {setSelected}
               toggledClearRows   		= {clearSelected}
               setIsCreateModalVisible = {setIsCreateModalVisible}
               setIsDeleteModalVisible = {setIsDeleteModalVisible}
            //    setIsFilterModalVisible = {setIsFilterModalVisible}
               setIsHeaderConfigVisible= {setIsHeaderConfigVisible}
               visibility  	        = {{
                   create          : true,
                   delete			: true,
                   header_config   : true,
					search 			: true

               }}
            //    totalData={50}
            />

            <ModalField
                show        	= {isHeaderConfigVisible}
                setShow     	= {setIsHeaderConfigVisible}
                size        	= "sm"
                title       	= "Select"
                data        	= {header}
				hideColumn  	= {hideColumn}
				setHideColumn 	= {setHideColumn}
            />

            {/* <Modal
                isOpen      = {modalIsActive} 
                toggle      = {() => { setModalIsActive(!modalIsActive) }} 
                size        = "sm"
                className   = "modal-dialog-centered"
            >
                <ModalHeader
                    toggle  = {() => setModalIsActive(!modalIsActive)} 
                > 
                    {selectedRole?.is_active ? "Non-Aktifkan Role" : "Aktifkan Role"}
                </ModalHeader>
                <ModalBody>
                    <div className="text-center">
                        Yakin Anda ingin {selectedRole?.is_active ? "Non-Aktifkan Role" : "Aktifkan Role"}<br/>
                        <strong>{selectedRole?.name}</strong>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="d-flex justify-content-between col-12">
                        <Button
                            outline
                            color   = "primary"
                            onClick = {() => {
                                setModalIsActive(false)
                            }}
                        >
                            Batal
                        </Button>
                        <Button
                            color   = "primary"
                            onClick = {() => {
                                handleUpdateStatus()
                            }}
                        >
                            Ya
                        </Button>
                    </div>
                </ModalFooter>
            </Modal> */}
        </>
    )
}

export default CategoryTable