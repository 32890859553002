import React from 'react'

import { 
    CornerUpRight,
    Download,
    Edit,
    Eye,
    File,
    FileText,
    Filter, 
    Folder, 
    Grid, 
    Link, 
    List, 
    MoreVertical, 
    Plus, 
    RefreshCw, 
    Share, 
    Share2, 
    Star,
    Tag,
    Trash2
}                           from "react-feather"

import {
    Button, 
    Card, 
    CardBody, 
    CardHeader, 
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Input,
    Row,
    Spinner,
    UncontrolledDropdown
}                           from "reactstrap"


const DriveDropDown = ({
    item,
    layout,
    active,
    addFavorite,
    setSelected,
    deleteToTags,
    restoreDrive,
    setDetailData,
    setShowDelete,
    setShowDetail,
    setShowAddTag,
    setShowFolder,
    deleteFavorite,
    setSelectDrive, 
    setShowMoveFolder, 
    setShowDeletePermanent,
    setBreadcums,
  }) => {
  return (
    <UncontrolledDropdown >
        <DropdownToggle className='cursor-pointer ' tag='span'>
            <MoreVertical size={18} />
        </DropdownToggle>
        <DropdownMenu className='overflow-auto'>
            {
                active !== 'trash' ? (
                    <>
                        <>
                            {
                                item.is_favorite ? (
                                    <DropdownItem 
                                        onClick     = {() => { deleteFavorite(item) }}
                                        className   = 'd-flex align-items-center w-100'
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24" className="me-1">
                                            <g fill="none">
                                                <path fill="currentColor" d="m12 2l.681-.314a.75.75 0 0 0-1.362 0L12 2Zm3.104 6.728l-.681.315c.109.236.333.4.592.43l.088-.745Zm7.358.873l.509.55a.75.75 0 0 0-.421-1.295l-.088.745Zm-3.996 12.298l-.367.655a.75.75 0 0 0 1.102-.801l-.735.146ZM12 18.28l.366-.654a.75.75 0 0 0-.732 0l.366.654ZM5.534 21.9l-.735-.147a.75.75 0 0 0 1.102.8l-.367-.654Zm1.444-7.268l.736.146a.75.75 0 0 0-.226-.697l-.51.55ZM1.538 9.6l-.088-.745a.75.75 0 0 0-.42 1.296l.508-.551Zm7.297-.11A.75.75 0 1 0 8.658 8l.177 1.49Zm9.515 7.977a.75.75 0 1 0-1.471.292l1.47-.292ZM9.948 4.658a.75.75 0 1 0 1.362.628l-1.362-.628Zm8.262 7.853a.75.75 0 1 0 1.018 1.102L18.21 12.51ZM11.319 2.314l3.103 6.729l1.363-.629l-3.104-6.728l-1.362.628Zm3.696 7.16l7.358.872l.177-1.49l-7.358-.872l-.177 1.49Zm3.817 11.77l-6.466-3.618l-.732 1.308l6.465 3.62l.733-1.31Zm-7.198-3.618l-6.466 3.619l.733 1.309l6.465-3.62l-.732-1.308Zm-5.364 4.42l1.444-7.268l-1.471-.293l-1.444 7.268l1.471.292Zm1.218-7.965l-5.44-5.03l-1.019 1.1l5.44 5.031l1.019-1.101Zm-5.861-3.735l7.208-.855l-.177-1.49l-7.208.855l.177 1.49Zm15.252 7.414l.851 4.285l1.471-.292l-.851-4.285l-1.471.292ZM11.31 5.286l1.371-2.972l-1.362-.628l-1.37 2.972l1.361.628ZM21.952 9.05l-3.742 3.461l1.018 1.102l3.743-3.461l-1.019-1.102Z"/>
                                                <path stroke="currentColor" stroke-linecap="round" stroke-width="1.5" d="m4 4l16 16"/>
                                            </g>
                                        </svg>{' '} Hapus Favorit
                                    </DropdownItem>
                                ) : (
                                    <DropdownItem 
                                        onClick     = {() => { addFavorite(item) }}
                                        className   = 'd-flex align-items-center w-100'
                                    >
                                        <Star size={17} className="me-1" /> Favorit
                                    </DropdownItem>    
                                )
                            }
                        </>
                        <>
                            {
                                item.is_tags ? (
                                    <DropdownItem 
                                        onClick = {() => {
                                            deleteToTags(item)
                                            setSelectDrive(item)
                                        }}
                                        className='d-flex align-items-center w-100'
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" className='me-1' width="17" height="17" viewBox="0 0 24 24">
                                            <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m14 5l-1.414-1.414A2 2 0 0 0 11.172 3H5a2 2 0 0 0-2 2v6.172a2 2 0 0 0 .586 1.414L5 14m14-4l1.586 1.586a2 2 0 0 1 0 2.828l-6.172 6.172a2 2 0 0 1-2.828 0L10 19M7 7h.001M21 3L3 21"/>
                                        </svg> Hapus Tag
                                    </DropdownItem>
                                ) : (
                                    <DropdownItem 
                                        onClick = {() => {
                                            setShowAddTag(true)
                                            setSelectDrive(item)
                                        }}
                                        className='d-flex align-items-center w-100'
                                    >
                                        <Tag size={17} className="me-1" /> Tambah Tag
                                    </DropdownItem>
                                )
                            }
                        </>
                       {
                        active === 'all' && (
                            <DropdownItem 
                                onClick = {() => {
                                    setShowMoveFolder(true)
                                    setSelectDrive(item)
                                    setBreadcums({ type: "create", name: "Semua File", url: "" })
                                }}
                                className='d-flex align-items-center w-100'
                            >
                                <Folder size={17} className="me-1" /> Pindah Folder
                            </DropdownItem>
                        )
                       }
                        <DropdownItem
                            onClick={() => {
                                setShowFolder(true)
                                setSelectDrive(item)
                            }} 
                            className='d-flex align-items-center w-100'>
                            <Edit size={17} className="me-1" /> Ubah Nama
                        </DropdownItem>
                        {
                            layout === 'list' && (
                                 <DropdownItem 
                                    onClick = {() => {
                                        setDetailData(item)
                                        setShowDetail(true)
                                    }}
                                    className='d-flex align-items-center w-100'
                                >
                                    <Eye size={17} className="me-1" /> Lihat Detail
                                </DropdownItem>
                            )
                        }
                       
                        <DropdownItem
                            className='d-flex align-items-center w-100'
                            onClick={() => window.open(`${window.location.origin}/api/drive/${item.mime.data === 'file' ? 'files' : item.mime.data }/download/${item.code}`, '_blank')}
                        >
                            
                            <Download size={17} className="me-1" /> Unduh
                        
                        </DropdownItem>
                        <DropdownItem
                            onClick     = {() => {
                                setShowDelete(true)
                                setSelectDrive(item)
                                setSelected([item.name ? item : item.data])
                            }}
                            className   = 'd-flex align-items-center w-100'
                        >
                            <Trash2 size={17} className="me-1" /> Hapus
                        </DropdownItem>
                    </>
                ) : (
                    <>
                        
                            <DropdownItem 
                            onClick = {() => restoreDrive(item)}
                            className='d-flex align-items-center w-100'
                        >
                            <RefreshCw size={17} className="me-1" /> Pulihkan
                        </DropdownItem>
                        <DropdownItem 
                            onClick = {() => {
                                setSelectDrive(item)
                                setShowDeletePermanent(true)
                            }}
                            className='d-flex align-items-center w-100'
                        >
                            <Trash2 size={17} className="me-1" /> Hapus Selamanya
                        </DropdownItem>
                    </>
                )
            }
            
            
        </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export default DriveDropDown
