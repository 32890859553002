import { useContext, useEffect, useState} from "react"
import { AbilityContext, Can }  from "../../../utility/context/Can"
import { ReportContext }        from "../../../context/reportContext"
import DataTable                from "react-data-table-component"

//Css
import './index.scss'
import NotAuthorized            from "../not-authorized"


const DataTablesReportProfile = (props) => {
    const {
        header,
        reportData,
        conditionalStyle,
        paginationOptions,
        userId
    }                           = props

    const {
        //state
        data,
        limit,
        params,
        loading,
        totalData,
        setParams,

        setSelected,

        setFetchStatus,
        
        //function
        getData,
        getAccessFeatures,
    }                               = useContext(ReportContext)

    const ability                   = useContext(AbilityContext)

    const [readRules, setReadRules]     = useState(true)
    const [finalHeader, setFinalHeader]  = useState([])

    const conditionalRowStyles  = []

    const paginationComponentOptions = {
        rowsPerPageText     : 'Baris per Halaman :',
        selectAllRowsItem   : false,
        rangeSeparatorText  : 'dari',
    }

    const setExtraHeader = () => {
        const header_ = [...header]

        const newHeader = {
            omit    : false,
			name    : 'Status',
            cell    : (row) => (
                row?.is_draft ?
                    <div className="">
                        <p className="">Draft</p>
                    </div>
                :
                    row?.last_track_status?.id === 1 ?
                        <div className="">
                            <p className="">Menunggu Persetujuan</p>
                        </div>
                    :
                        row?.last_track_status?.id === 2 ?
                            <div className="">
                                <p className="">Disetujui</p>
                            </div>
                        :
                            row?.last_track_status?.id === 3 ?
                                <div className="">
                                    <p className="">Proses</p>
                                </div>
                            :
                                row?.last_track_status?.id === 4 ?
                                    <div className="">
                                        <p className="">Ditolak</p>
                                    </div>
                                :
                                    row?.last_track_status?.id === 5 ?
                                        <div className="">
                                            <p className="">Diperbaiki</p>
                                        </div>
                                    :
                                        <div className="">
                                            <p className="">Selesai</p>
                                        </div>
            ),
            center  : true,
            compact : true
        }

        header_.push(newHeader)

        setFinalHeader(header_)
    }

    const checkRules = () => {
        const rules_ = ability.rules
        const check_ = {action: 'read', subject: 'IN.1'}

        const exists = rules_.some((element) => {
            // Compare the objects using a deep comparison method.
            return JSON.stringify(element) === JSON.stringify(check_)
        })

        if (exists) {
            setReadRules(true)
        } else {
            setReadRules(false)
        }
    }

    useEffect(async() => {
        await getAccessFeatures(reportData)
        await checkRules()

        if (ability.can('read', reportData?.report)) {
            if (userId) {
                getData(reportData?.reportTypeId, header, userId)
            }
        }
    }, [])

    useEffect(() => {
        if (params && ability.can('read', reportData?.report)) {
            if (userId) {
                getData(reportData?.reportTypeId, header, userId)
            }
        }
    }, [params])

    useEffect(() => {
        if (userId) {
            getData(reportData?.reportTypeId, header, userId)
        }
        setExtraHeader()
    }, [header])

    return (
        <>
            <div style={{ marginBottom : "0px" }}>
                <div>
                    <DataTable
                        data                        = {data}
                        columns                     = {finalHeader}
                        selectableRows              = {true}
                        progressPending             = {false || loading}
                        conditionalRowStyles        = {conditionalStyle ? conditionalStyle : conditionalRowStyles}
                        paginationComponentOptions  = {paginationOptions ? paginationOptions : paginationComponentOptions}

                        //pagination
                        pagination
                        paginationServer
                        paginationPerPage           = {limit}
                        paginationTotalRows         = {totalData}
                        paginationDefaultPage       = {1}
                        
                        // handle action
                        onChangePage                = {(res) => { setParams({...params, page:res}, setFetchStatus(true)) }}
                        onChangeRowsPerPage         = {(res) => { setParams({...params, page: 1, page_size:res}, setFetchStatus(true)) }}
                        onSelectedRowsChange        = {(res) => { 
                            const selectedRow_      = res?.selectedRows
                            const filter_ = selectedRow_.filter((data) => {
                                const lastIndex_ = data?.track_status[data?.track_status.length - 1]
                                return lastIndex_?.status_approvement < 2
                            })

                            setSelected(filter_)
                        }}

                        // other props
                        pointerOnHover
                        highlightOnHover
                    />
                </div>
            </div>
        </>

    )
}

export default DataTablesReportProfile