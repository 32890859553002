// ** Icons Import
import { Layout } from 'react-feather'

export default [
  {
    id          : 'dashboard',
    icon        : <Layout size={20} />,
    title       : 'Dashboard',
    action      : 'read',
    navLink     : "/dashboard",
    resource    : 'dashboard'
  }
]
