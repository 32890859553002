import moment               from "moment"
import pdfMake              from "pdfmake/build/pdfmake"

import {
    Document, 
    Paragraph, 
    Header, 
    AlignmentType, 
    TextRun, 
    Table, 
    TableRow, 
    TableCell, 
    WidthType, 
    BorderStyle, 
    PageOrientation, 
    VerticalAlign, 
    convertMillimetersToTwip,
    UnderlineType,
    SectionType
}                       from 'docx'

let fontSize      = 8
let pageSize      = "A4"
let content       = []

const enterNewLine = () => {
    return (
        new Paragraph({
            spacing: {
                after: 200
            }
        })
    )
}

const DocxRecapData = (
    title,
    tableList,
    _pageSize,
    _fontSize
) => {
    content = []

    pageSize = _pageSize ? _pageSize : "A4"
    fontSize = _fontSize ? _fontSize : 9

    // HEADER DOC
    const titleComps = [] 
    title.map((dt) => {
        titleComps.push(
            new TableRow({
                children: [
                    new TableCell({
                        borders: {
                            top     : { style: BorderStyle.NONE, size: 0, color: "ff0000" },
                            bottom  : { style: BorderStyle.NONE, size: 0, color: "ff0000" },
                            left    : { style: BorderStyle.NONE, size: 0, color: "ff0000" },
                            right   : { style: BorderStyle.NONE, size: 0, color: "ff0000" },
                        },
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children : [
                                    new TextRun({
                                        text        : dt,
                                        size        : 22,
                                        bold        : true
                                    })
                                ]
                            })
                        ]
                    })
                ]
            })
        )
    })

    content.push(
        new Table({
            width       : {
                size    : 100,
                type    : WidthType.PERCENTAGE
            },
            rows        : titleComps
        })
    )

    content.push(enterNewLine())

    tableList?.map((table) => {

        const tableBody      = []

        content.push(
            new Paragraph({
                children : [
                    new TextRun({
                        text        : `${table.title}`,
                        size        : 22,
                        bold        : true
                    })
                ]
            })
        )

        table.header?.map((list, index) => {
            const header         = []
    
            list.map((dt, idx) => {
                header.push(
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children : [
                                    new TextRun({
                                        text        : `${dt.label}`,
                                        size        : 22,
                                        bold        : true
                                    })
                                ]
                            })
                        ]
                    })
                )
            })
    
            tableBody.push(
                new TableRow({
                    children: header
                })
            )
    
        })

        table.data?.map((list, index) => {
            const body         = []
    
            list.map((dt, idx) => {
                body.push(
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: typeof (dt.label) === "number" ? AlignmentType.CENTER : AlignmentType.LEFT,
                                children : [
                                    new TextRun({
                                        text        : `${dt.label}`,
                                        size        : 22,
                                    })
                                ]
                            })
                        ]
                    })
                )
            })
    
            tableBody.push(
                new TableRow({
                    children: body
                })
            )
        })

        content.push(
            new Table({
                width       : {
                    size    : 100,
                    type    : WidthType.PERCENTAGE
                },
                rows        : tableBody
            })
        )

        content.push(enterNewLine())
        
    })

    content.push(enterNewLine())

    const doc = new Document({
        sections: [
            {
                properties: {
                    type: SectionType.CONTINUOUS,
                },
                children: content
            }
        ]
    })

    return doc
}

export default DocxRecapData