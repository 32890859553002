import PropTypes from 'prop-types'
import { Fragment } from 'react'
import { Col, Row } from 'reactstrap'
import classNames from 'classnames'
import "./index.scss"


const CustomTableHeader = props => {

    // props
    const {
        data,
        className,
        style,
    } = props

    // content
    return (
        <Fragment>
            <div 
                style       = {style}
                className   = {classNames('wrap-header', className)} 
            >
                <Row md={12}>
                    {data && data.map((item, index) => (
                        <Col md={item.size} key={index}>
                            <h6 className={`fw-bolder ${item.className}`} style={item.style}> {item.title} </h6>
                        </Col>
                    ))}
                </Row>
            </div>
        </Fragment>
    )

}

// validation props
CustomTableHeader.propTypes = {
    className: PropTypes.string,
    data: PropTypes.array.isRequired,
    style: PropTypes.object
}

export default CustomTableHeader