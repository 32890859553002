import baseURL, {get, delete_, post, put}  from '../../index'

//get
const getWorkunit     = (params) => get(`/management/work-unit/`, params)

//post
const createWorkunit  = (formData) => post('/management/work-unit/create', formData)

//put
const updateWorkunit  = (formData, id) => put(`/management/work-unit/update/${id}`, formData)

//delete
const deleteWorkunit  = (id) => delete_(`/management/work-unit/delete/${id}`)

const workunitAPI = {
    getWorkunit,

    createWorkunit,

    updateWorkunit,

    deleteWorkunit
}

export default workunitAPI