import { 
    Clock, 
    CloudOff, 
    CornerUpRight, 
    Folder, 
    Link, 
    PieChart, 
    Plus, 
    Star, 
    Tag, 
    Trash2, 
    User, 
    Users 
}                   from "react-feather"

import { useState }             from "react"
import DataManagementContent    from "./component/DataManagementContent"
import DataManagementSidebar    from "./component/DataManagementSidebar"
import { DataManagerProvider } from "../../context/dataManagerContext"

import "./index.scss"
import { Button, Col, Row } from "reactstrap"

const DataManagement = () => {

    const [showComponentSmall, setShowComponentSmall] = useState("content")

    const [active, setActive]                         = useState("all")
    const [activeChild, setActiveChild]               = useState("all")

    const data = [
        {
            name        : "Folder Baru 1",
            type        : "folder",
            shared      : true,
            last_update : "Jan 17, 2022"
        },
        {
            name        : "Folder Baru 1 Favorit",
            type        : "folder-fav",
            shared      : false,
            last_update : "Mar 24, 2022"
        },
        {
            name        : "Folder Baru 2",
            type        : "folder-share",
            shared      : true,
            last_update : "Jan 17, 2022"
        },
        {
            name        : "Folder Baru 2 Favorit",
            type        : "folder-share-fav",
            shared      : false,
            last_update : "Jan 8, 2022"
        },
        {
            name        : "File Mp3",
            type        : "mp3",
            shared      : false,
            last_update : "Feb 20, 2022"
        },
        {
            name        : "File Mp3 Favorit",
            type        : "mp3-fav",
            shared      : false,
            last_update : "Jan 17, 2022"
        },
        {
            name        : "File Mp4",
            type        : "mp4",
            shared      : false,
            last_update : "Feb 20, 2022"
        },
        {
            name        : "File Mp4 Favorit",
            type        : "mp4-fav",
            shared      : false,
            last_update : "Jan 17, 2022"
        },
        {
            name        : "File JPG",
            type        : "jpg",
            shared      : false,
            last_update : "Feb 20, 2022"
        },
        {
            name        : "File JPG Favorit",
            type        : "jpg-fav",
            shared      : false,
            last_update : "Jan 17, 2022"
        },
        {
            name        : "File PNG",
            type        : "png",
            shared      : false,
            last_update : "Feb 20, 2022"
        },
        {
            name        : "File PNG Favorit",
            type        : "png-fav",
            shared      : false,
            last_update : "Jan 17, 2022"
        },
        {
            name        : "File PDF",
            type        : "pdf",
            shared      : false,
            last_update : "Feb 20, 2022"
        },
        {
            name        : "File PDF Favorit",
            type        : "pdf-fav",
            shared      : false,
            last_update : "Jan 17, 2022"
        },
        {
            name        : "File DOC",
            type        : "doc",
            shared      : false,
            last_update : "Feb 20, 2022"
        },
        {
            name        : "File DOC Favorit",
            type        : "doc-fav",
            shared      : false,
            last_update : "Jan 17, 2022"
        },
        {
            name        : "File XLS",
            type        : "xls",
            shared      : false,
            last_update : "Feb 20, 2022"
        },
        {
            name        : "File XLS Favorit",
            type        : "xls-fav",
            shared      : false,
            last_update : "Jan 17, 2022"
        },
        {
            name        : "File PPT",
            type        : "ppt",
            shared      : false,
            last_update : "Feb 20, 2022"
        },
        {
            name        : "File PPT Favorit",
            type        : "ppt-fav",
            shared      : false,
            last_update : "Jan 17, 2022"
        },
        {
            name        : "File ZIP",
            type        : "zip",
            shared      : false,
            last_update : "Feb 20, 2022"
        },
        {
            name        : "File ZIP Favorit",
            type        : "zip-fav",
            shared      : false,
            last_update : "Jan 17, 2022"
        },
        {
            name        : "File Unknown",
            type        : "unknown",
            shared      : false,
            last_update : "Feb 20, 2022"
        },
        {
            name        : "File Unknown Favorit",
            type        : "unknown-fav",
            shared      : false,
            last_update : "Jan 17, 2022"
        },
        {
            name        : "File Baru Favorit",
            type        : "unknown-fav",
            shared      : false,
            last_update : "Jan 17, 2022"
        },
    ]

    const listMenu = [
        {
            name : "Semua Berkas",
            code : "all",
            icon : <Folder size={22} />,
            child: []
        },
        {
            name : "Baru Dibuka",
            code : "recent",
            icon : <Clock size={22} />,
            child: []
        },
        {
            name : "Favorit",
            code : "favorite",
            icon : <Star size={22} />,
            child: []
        },
        {
            name : "Bagikan",
            code : "share",
            icon : <CornerUpRight size={22} />,
            child: [
                {
                    name : "Dengan Yang Lain",
                    code : "share-others",
                    icon : <Users size={22} />,
                    child: []
                },
                {
                    name : "Dengan Anda",
                    code : "share-me",
                    icon : <User size={22} />,
                    child: []
                },
                {
                    name : "Dengan Link",
                    code : "share-link",
                    icon : <Link size={22} />,
                    child: []
                },
                {
                    name : "Terhapus",
                    code : "share-delete",
                    icon : <CloudOff size={22} />,
                    child: []
                }
            ]
        },
        {
            name : "Tag",
            code : "tag",
            icon : <Tag size={22} />,
            child: [
                {
                    name : "Tambah Tag Baru",
                    code : "tag-add",
                    icon : <Plus size={22} />,
                    child: []
                }
            ]
        }
    ]

    const [isOpen, setIsOpen] = useState(false)

  const toggleSidebar = () => {
    setIsOpen(!isOpen)
  }

    return (
        <DataManagerProvider>
            <div>
                <Row >
                    <Col lg = {3} xs={isOpen ? 4 : 1} className={` py-0 m-0 h-100 app ${isOpen ? 'open' : ''}`}>
                        <DataManagementSidebar 
                                listMenu                = {listMenu}
                                active                  = {active}
                                setActive               = {setActive}
                                activeChild             = {activeChild}
                                setActiveChild          = {setActiveChild}
                                setShowComponentSmall   = {setShowComponentSmall}
                            />
                        
                    {/* Tambahkan konten utama Anda di sini */}
                    </Col>
                    <Col lg={9} xs={isOpen ? 8 : 10} className={`ps-2 m-0`}>
                        <DataManagementContent 
                            data                    = {data}
                            toggleSidebar           = {toggleSidebar}
                            setShowComponentSmall   = {setShowComponentSmall}
                        />
                    </Col>
                </Row>
            </div>
        </DataManagerProvider>
    )
}

export default DataManagement