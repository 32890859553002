import { 
	selectThemeColors 
} 							from "@utils"

import { 
	useEffect, 
	useRef, 
	useState 
} 							from "react"

import Select 				from "react-select"
import classNames 			from "classnames"

import reportAPI 			from "../../../service/report"
import toast 				from "react-hot-toast"

const SelectSector = (props) => {

	const {
		bidangId,
		seksiId,
	}					 = props

	const ref = useRef()

	const [sectorOption, setSectorOptions] = useState([])
	const [oldBidang, setOldBidang]		   = useState(null)

	const getData = () => {	

		const params = {
			source 		: 'sektor',
			page_size 	: 100,
		}

		if (bidangId) {
			params.high_parent 	= parseInt(bidangId)
		} else if (seksiId) {
			params.parent 		= parseInt(seksiId)
		}

		reportAPI.getAdditionalData(params).then(
			(res) => {
				const sectorOptions_ = []

				if (res.results) {
					res.results.map((data) => (
						sectorOptions_.push({
							label : data.name,
							value : data.id
						})
					))
					setSectorOptions(sectorOptions_)
				} else {
					setSectorOptions([])
				}

				if (props.field.value) {
					const temp = sectorOptions_.length > 0 ? sectorOptions_.find(opt => opt.value === props.field.value || opt.label === props.field.value) : null
					props.onChange(temp)
				}

			}	
		).catch(() => {})
	}

	const checkFunction = (type) => {
		// setSectorOptions([])

		if (type === "bidang") {
			if (!bidangId) {
				toast.error('Pilih bidang terlebih dahulu.')
			} else {
				getData()
			}
		} else if (type === "seksi") {
			if (!seksiId) {
				toast.error('Pilih bidang terlebih dahulu.')
			} else {
				getData()
			}
		}
	}

	useEffect(() => {
		if (oldBidang !== null) {
			if (bidangId !== oldBidang) {
				ref.current.setValue([])
			}
		}

		if (bidangId) {
			checkFunction("bidang")
			setOldBidang(bidangId)
		}
	}, [bidangId])

	useEffect(() => {
		if (oldBidang !== null) {
			if (seksiId !== oldBidang) {
				ref.current.setValue([])
			}
		}

		if (seksiId) {
			checkFunction("seksi")
			setOldBidang(seksiId)
		}
	}, [seksiId])

	return (
		<Select
			ref				= {ref}
			theme			= {selectThemeColors}
			value			= {sectorOption.length > 0 ? sectorOption.find(opt => opt.value === props.field.value || opt.label === props.field.value) : ""}
			styles			= {{ menu: base => ({ ...base, zIndex: 9999 }) }}
			
			options			= {sectorOption}
			className		= {classNames("react-select", { "is-invalid": props.invalid })}
			isDisabled		= {props?.disabled ? true : !(sectorOption.length > 0)}
			placeholder		= "Pilih Sektor"
			classNamePrefix = "select"
			id 				= "selectSektor"
			{...props} 
			onChange		= {(e) => { 
				if (e.value) {
					props.onChange(e)
				}
			 }}
		/>
	)
}

export default SelectSector
