import { 
    Input,
    Label, 
    Modal, 
    ModalBody, 
    ModalHeader,
    ModalFooter,
    Button,
    Form,
    FormFeedback,
}                               from "reactstrap"

import { 
    Check, 
    X 
}                               from "react-feather"

import { 
    useContext, 
    useState 
}                               from "react"

import { 
    PositionContext 
}                               from "../../../../context/positionContext"

import { 
    Controller, 
    useForm 
}                               from "react-hook-form"

import { 
    yupResolver 
}                               from "@hookform/resolvers/yup"

import Select                   from 'react-select'
import PositionValidation       from "./PositionValidation"
import { WorkunitLevelContext } from "../../../../context/workunitLevelContext"
import { SectorContext }        from "../../../../context/sectorContext"

const PositionCreate = ({
    show,
    setShow,
    title,
    size,
    data,
    handleCreate
}) => {

    const {
		reset,
		control,
		setValue,
		setError,
		clearErrors,
        getValues,
		handleSubmit,
		formState: { errors },
	} = useForm({
		mode        : "onChange",
		resolver    : yupResolver(PositionValidation),
	})

    const [isAssistant, setIsAssistant] = useState(false)

    const positionTypeOption = [
        {
            label : "PELAKSANA",
            value : 1
        }, 
        {
            label : "FUNGSIONAL",
            value : 2
        }, 
        {
            label : "STRUKTURAL",
            value : 3
        }
    ]

    const {
        workunitLevelOption
    } = useContext(WorkunitLevelContext)

    const {
        sectorOption
    } = useContext(SectorContext)

    const {
        positionOption
    } = useContext(PositionContext)

    const CustomLabel = ({ htmlFor }) => (
        <Label className="form-check-label" htmlFor={htmlFor}>
            <span className="switch-icon-left">
                <Check size={14} />
            </span>
            <span className="switch-icon-right">
                <X size={14} />
            </span>
        </Label>
    )

    return (
        <>
            <div>
                <Modal
                    isOpen  = {show} 
                    toggle  = {() => { setShow(!show) }} 
                    size    = {size || "sm"}
                >
                    <ModalHeader 
                        toggle={() => setShow(!show)} 
                    > 
                        {title}
                    </ModalHeader>
                    <Form onSubmit={handleSubmit(handleCreate)}>
                        <ModalBody>
                            <div
                                className="d-flex"
                            >
                                <div className="col-6 pe-1">
                                    <div
                                        className   = "mb-1"
                                    >
                                        <Label>Induk</Label>
                                        <Controller
                                            name        = "parent"
                                            control     = {control}
                                            render      = {({ field }) => (
                                                <Select
                                                    {...field} 
                                                    className           = {`react-select ${errors.parent?.value ? "is-invalid" : ""}`}
                                                    classNamePrefix     = "select"
                                                    placeholder         = "Induk"
                                                    options             = {positionOption}
                                                />
                                            )}
                                        />
                                        {errors.parent?.value && <small className="text-danger">{errors.parent?.value.message}</small>}
                                    </div>

                                    <div
                                        className   = "mb-1"
                                    >
                                        <Label>Tipe Jabatan</Label>
                                        <Controller
                                            name        = "position_type"
                                            control     = {control}
                                            render      = {({ field }) => (
                                                <Select
                                                    {...field} 
                                                    className           = {`react-select ${errors.position_type?.value ? "is-invalid" : ""}`}
                                                    classNamePrefix     = "select"
                                                    placeholder         = "Tipe Jabatan"
                                                    options             = {positionTypeOption}
                                                />
                                            )}
                                        />
                                        {errors.position_type?.value && <small className="text-danger">{errors.position_type?.value.message}</small>}
                                    </div>

                                    <div
                                        className   = "mb-1"
                                    >
                                        <Label>Tingkat Satuan Kerja</Label>
                                        <Controller
                                            name        = "work_unit_level_id"
                                            control     = {control}
                                            render      = {({ field }) => (
                                                <Select
                                                    {...field} 
                                                    className           = {`react-select ${errors.work_unit_level_id?.value ? "is-invalid" : ""}`}
                                                    classNamePrefix     = "select"
                                                    placeholder         = "Tingkat Satuan Kerja"
                                                    options             = {workunitLevelOption}
                                                />
                                            )}
                                        />
                                        {errors.work_unit_level_id?.value && <small className="text-danger">{errors.work_unit_level_id?.value.message}</small>}
                                    </div>

                                    <div
                                        className   = "mb-1"
                                    >
                                        <Label>Unit Kerja</Label>
                                        <Controller
                                            name        = "sector"
                                            control     = {control}
                                            render      = {({ field }) => (
                                                <Select
                                                    {...field} 
                                                    className           = {`react-select ${errors.sector?.value ? "is-invalid" : ""}`}
                                                    classNamePrefix     = "select"
                                                    placeholder         = "Unit Kerja"
                                                    options             = {sectorOption}
                                                />
                                            )}
                                        />
                                        {errors.sector?.value && <small className="text-danger">{errors.sector?.value.message}</small>}
                                    </div>
                                </div>

                                <div className="col-6 ps-1">
                                    <div
                                        className   = "mb-1"
                                    >
                                        <Label>Nama</Label>
                                        <Controller
                                            name        = "name"
                                            control     = {control}
                                            render      = {({ field }) => (
                                                <Input
                                                    {...field} 
                                                    invalid             = {errors.name && true}
                                                    placeholder         = "Nama"
                                                />
                                            )}
                                            defaultValue={data?.name}
                                        />
                                        {errors.name && <FormFeedback>{errors.name.message}</FormFeedback>}
                                    </div>
                                    
                                    <div
                                        className   = "mb-1"
                                    >
                                        <Label>Deskripsi</Label>
                                        <Controller
                                            name        ="description"
                                            control     ={control}
                                            render      ={({ field }) => (
                                                <Input
                                                    {...field} 
                                                    invalid             = {errors.description && true}
                                                    type                = "textarea"
                                                    placeholder         = "Deskripsi"
                                                />
                                            )}
                                            defaultValue={data?.description}
                                        />
                                        {errors.description && <FormFeedback>{errors.description.message}</FormFeedback>}
                                    </div>

                                    <div
                                        className   = "mb-1"
                                    >
                                        <Label>Asisten</Label>
                                        <div className="form-switch form-check-primary">
                                            <Input
                                                checked     = {isAssistant}
                                                onChange    = {() => {
                                                    setIsAssistant(!isAssistant)
                                                }}
                                                type        = "switch"
                                                id          = "icon-primary"
                                            />
                                            <CustomLabel htmlFor="icon-primary" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>

                        <ModalFooter>
                            <div
                                className   = "d-flex justify-content-between col-12"
                            >
                                <Button
                                    color   = "primary"
                                    outline
                                    onClick = {() => {
                                        setShow(false)
                                    }}
                                >
                                    Batal
                                </Button>

                                <Button
                                    color   = "primary"
                                    type    = "submit"
                                >
                                    Simpan
                                </Button>
                            </div>
                        </ModalFooter>
                    </Form>
                    
                </Modal>
            </div>
        </>
    )
}

export default PositionCreate