import Logout		 from "../../views/pages/authentication/Logout"
import VerifySSO 	 from "../../views/pages/authentication/VerifySSO"

const SSORoutes = [
	{
		path	: '/verify-sso',
		element	: <VerifySSO/>,
	},
	{
		path	: '/logout',
		element	: <Logout/>,
	}
]

export default SSORoutes
