import * as yup from "yup"

const RecapitulationFilterValidationWorkunit = yup
	.object()
	.shape({
		work_unit_id	: yup.object().shape({ value : yup.number().required("Isian Tidak Boleh Kosong")}).required("Isian Tidak Boleh Kosong"),
	})
	.required()

export default RecapitulationFilterValidationWorkunit
