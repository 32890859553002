import { 
	Fragment, 
	useContext, 
	useEffect, 
	useState 
} 							from "react"

import { 
	Download, 
	Plus, 
	Trash2 
} 							from "react-feather"

import { 
	Button, 
	Col, 
	Row 
} 							from "reactstrap"

import { 
	ReportContext 
} 							from "../../../context/reportContext"

import toast 				from "react-hot-toast"
import moment 				from "moment"

import ModalForm 			from "./modal-form"
import headerTable 			from "./header-table"
import FormDelete 			from "../form-delete"
import CustomTable 			from "../custom-table"
import ActionButton 		from "../action-button"
import CardCreatedBy 		from "../card-createdby"
import reportAPI 			from "../../../service/report"
import userManagementAPI 	from "../../../service/pages/user-management"
import { saveAs } 			from "file-saver"
import ReadMore from "../read-more"

const DataAttachment = (props) => {

	const [showForm, setForm] 				= useState(false)
	const [loading, setLoading] 			= useState(false)
	const [dataFiles, setDataFiles] 		= useState([])
	const [dataSelected, setDataSelected] 	= useState(null)
	const [showFormDelete, setFormDelete] 	= useState(false)
	const [loadingDelete, setLoadingDelete] = useState(false)

	const {
		dataSelected : selectedData
	}										= useContext(ReportContext)

	// custom table
	const {
		CustomTableHeader,
		CustomTableBody,
		CustomTableSearch,
		CustomTableLoading,
		CustomTableEmptyData,
	} = CustomTable

	const getData = async (keyword) => {
		setLoading(true)

		try {
			const params = {
				report_id: selectedData?.report_id
			}

			if (keyword) {
				params.keyword = keyword
			}
			
			const getAttachment = await reportAPI.getAttachment(params)
			const newData_ = []
	
			if (getAttachment.results && getAttachment.results.length > 0) {
				await Promise.all(
					getAttachment.results.map(async (data) => {

						const fetchUser = await userManagementAPI.getUserManagement({user: parseInt(data.created_by)})
						data.created_by = await fetchUser.results[0]

						newData_.push(data)
					})
				)
			}

			setDataFiles(newData_)
			setLoading(false)

		} catch (error) {
			toast.error('Terjadi kesalahan saat mengambil data file pendukung.')
			console.log(error, 'err')	
			setLoading(false)
		}
	}

	const onDelete = async() => {

		try {
			const deleteAttachment = await reportAPI.deleteAttachment(dataSelected.id)

			if (deleteAttachment) {
				toast.success("File Berhasil Dihapus")

				getData()
				setLoadingDelete(false)
				setFormDelete(false)
			}

		} catch (error) {
			setLoadingDelete(false)
			toast.error("Terjadi kesalahan saat menghapus file pendukung.")
		}
	}

	const saveFile = (url, name) => {
		saveAs(
		  url,
		  name
		)
	  }

	useEffect(() => {
		getData()
	}, [])

	return (
		<Fragment>
			{/* Form Delete */}
			<FormDelete
				loading			= {loadingDelete}
				show			= {showFormDelete}
				title			= "Hapus File Pendukung"
				onClose			= {() => setFormDelete(false)}
				onDelete		= {onDelete}
			>
				<p>Apakah anda yakin akan menghapus data ini?</p>
				<p> Deskripsi : {dataSelected?.description} </p>
			</FormDelete>

			{/* modal Form */}
			<ModalForm
				show		= {showForm}
				onClose		= {() => setForm(!showForm)}
				getData		= {getData}
			/>

			<Row>
				<Col className="">
					<Button.Ripple
						size		= "sm"
						color		= "primary"
						onClick		= {() => setForm(true)}
						className	= "btn-icon text-white"
					>
						<Plus size = {18} />
					</Button.Ripple>
				</Col>
				<Col md={6}>
					<CustomTableSearch
						title		= "cari"
						onSearch	= {(e) => getData(e)}
					/>
				</Col>
			</Row>

			<CustomTableHeader data={headerTable} />

			{/* table body */}
			{!loading ? (
				dataFiles?.length > 0 ? (
					dataFiles?.map((item, index) => (
						<CustomTableBody key={index}>
							<Col md={2}>
								{moment(item.created_at).format("DD-MM-YYYY")} <br />
								{moment(item.created_at).format("HH:mm")}
							</Col>
							<Col md={4}>
								<CardCreatedBy
									data = {item?.created_by}
								/>
							</Col>
							<Col md={4}><ReadMore textLength={50}> {item.description} </ReadMore></Col>
							<Col md={2} className="p-0">
								<ActionButton
									id			="btn-download"
									title		="Download File"
									onClick={() => {
										// window.open(item.files_urls)
										saveFile(item.files_urls, item.name)
									}}
								>
									<Download size={20} />
								</ActionButton>

								<ActionButton
									id="btn-delete-file"
									title="Hapus File"
									onClick={() => {
										setDataSelected(item)
										setFormDelete(!showFormDelete)
									}}
								>
									<Trash2 size={20} />
								</ActionButton>
							</Col>
						</CustomTableBody>
					))
				) : (
					<CustomTableEmptyData />
				)
			) : (
				<CustomTableLoading />
			)}
		</Fragment>
	)
}

export default DataAttachment
