// ** Icons Import
import { Col, Row } from 'reactstrap'

const Footer = () => {
	return (
		<div className='clearfix mb-0'>
			<Row>
				<Col md={4}>
					<small className='m-0'>
						Intelijen Kejaksaan Republik Indonesia © {new Date().getFullYear()}{' '}
					</small><br />
					<small className=''>
						Powered by Direktorat E JAM Intelijen Kejaksaan Agung RI
					</small>
				</Col>
				<Col md={8} className='d-flex align-items-center'>
					{/* <small style={{
						color: 'red',
						textDecoration: 'i'
					}}>
						<i>
							Saat ini Sistem E-Admintel masih dalam proses pengembangan, mohon maaf jika ada beberapa fitur yang masih belum dapat digunakan.
						</i>
					</small> */}
				</Col>
			</Row>
		</div>
	)
}

export default Footer
