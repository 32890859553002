import { 
    Modal, 
    ModalBody, 
    ModalHeader,
    ModalFooter,
    Button
}                   from "reactstrap"


import toast        from "react-hot-toast"
import HelpDeskAPI  from "../../../../service/pages/helpdesk"

import '@styles/react/libs/editor/editor.scss'

const HelpActive = ({
    data,
    show,
    size,
    title,
    getData,
    setShow,
}) => {

    const guideActive = (params) => {

        if (params.is_active) {
            const dataForm = {
                title : params.title,
                category : params.category,
                content : params.content,
                is_active : false
            }
            HelpDeskAPI.updateGuide(params.id, dataForm)
                .then(() => {
                    getData()
                    toast.success('Berhasil Mengaktifkan')
                    setShow(false)
                }).catch(() => {
                    toast.error('Gagal Mengaktifkan')
                })
        } else {
            const dataForm = {
                title : params.title,
                category : params.category,
                content : params.content,
                is_active : true
            }
            HelpDeskAPI.updateGuide(params.id, dataForm)
                .then(() => {
                    getData()
                    toast.success('Berhasil Menonaktifkan')
                    setShow(false)
                }).catch(() => {
                    toast.error('Gagal Menonaktifkan')
                })
        }
        
    }
    return (
        <>
            <Modal
                isOpen  = {show} 
                centered
                toggle  = {() => { setShow(!show) }} 
                size    = {size || "sm"}
            >
                <ModalHeader 
                    toggle={() => setShow(!show)} 
                > 
                    {title}
                </ModalHeader>
                <ModalBody>
                    <div>
                        Yakin Ingin {!data.is_active ? 'Aktifkan' : 'Menonaktifkan' } <b>{data.title}?</b>
                    </div>
                </ModalBody>

                <ModalFooter>
                    <div
                        className   = "d-flex justify-content-between col-12"
                    >
                        <Button
                            color   = "primary"
                            onClick = {() => {
                                setShow(false)
                            }}
                        >
                            Batal
                        </Button>
                        <Button
                            color   = "primary"
                            outline
                            onClick={() => guideActive(data)}
                        >
                            Submit
                        </Button>
                    </div>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default HelpActive