import { Navigate } 	from 'react-router-dom'
import GIS 				from '../../views/gis'

const GisRoutes = [
	{
		path	: '/gis',
		element	: <GIS/>,
		meta	: {
			action: 'read',
			resource: 'data-peta',
			menuCollapsed: true
		}
	}
]

export default GisRoutes
