import { lazy } from 'react'
import { Navigate } from 'react-router-dom'

const DIN1 = lazy(() => import('../../views/reports/din/din-1'))
const DIN2 = lazy(() => import('../../views/reports/din/din-2'))
const DIN3 = lazy(() => import('../../views/reports/din/din-3'))
const DIN4 = lazy(() => import('../../views/reports/din/din-4'))
const DIN5 = lazy(() => import('../../views/reports/din/din-5'))
const DIN6 = lazy(() => import('../../views/reports/din/din-6'))
const DIN7 = lazy(() => import('../../views/reports/din/din-7'))
const DIN8 = lazy(() => import('../../views/reports/din/din-8'))
const DIN9 = lazy(() => import('../../views/reports/din/din-9'))
const DIN10 = lazy(() => import('../../views/reports/din/din-10'))
const DIN11 = lazy(() => import('../../views/reports/din/din-11'))
const DIN12 = lazy(() => import('../../views/reports/din/din-12'))
const DIN13 = lazy(() => import('../../views/reports/din/din-13'))
const DIN14 = lazy(() => import('../../views/reports/din/din-14'))
const DIN15 = lazy(() => import('../../views/reports/din/din-15'))
const DIN16 = lazy(() => import('../../views/reports/din/din-16'))

const DinRoutes = [
	{
		path: '/din',
		exact: true,
		element: <Navigate to='/din/1' />,
		meta: {
			action: 'read',
			resource: 'din-data'
		}
	},
    {
        path: "/din/1",
        element: < DIN1 />,
        meta: {
            action: "read",
            resource: "din1-data-peta"
        }
    },
    {
        path: "/din/2",
        element: < DIN2 />,
        meta: {
            action: "read",
            resource: "din2-peta-simbol-ipolhankam"
        }
    },
    {
        path: "/din/3",
        element: < DIN3 />,
        meta: {
            action: "read",
            resource: "din3-peta-simbol-sosbudmas"
        }
    },
    {
        path: "/din/4",
        element: < DIN4 />,
        meta: {
            action: "read",
            resource: "din4-peta-simbol-ekokeu"
        }
    },
    {
        path: "/din/5",
        element: < DIN5 />,
        meta: {
            action: "read",
            resource: "din5-peta-simbol-pps"
        }
    },
    {
        path: "/din/6",
        element: < DIN6 />,
        meta: {
            action: "read",
            resource: "din6-peta-simbol-tikproin"
        }
    },
    {
        path: "/din/7",
        element: < DIN7 />,
        meta: {
            action: "read",
            resource: "din7-kegiatan-penkum"
        }
    },
    {
        path: "/din/8",
        element: < DIN8 />,
        meta: {
            action: "read",
            resource: "din8-dokumentasi-kegiatan-penkum"
        }
    },
    {
        path: "/din/9",
        element: < DIN9 />,
        meta: {
            action: "read",
            resource: "din9-target-kegiatan-penkum"
        }
    },
    {
        path: "/din/10",
        element: < DIN10 />,
        meta: {
            action: "read",
            resource: "din10-formulir-data-orang-asing"
        }
    },
    {
        path: "/din/11",
        element: < DIN11 />,
        meta: {
            action: "read",
            resource: "din11-grafik"
        }
    },
    {
        path: "/din/12",
        element: < DIN12 />,
        meta: {
            action: "read",
            resource: "din12-kt-biodata"
        }
    },
    {
        path: "/din/13",
        element: < DIN13 />,
        meta: {
            action: "read",
            resource: "din13-kt-barang-cetakan"
        }
    },
    {
        path: "/din/14",
        element: < DIN14 />,
        meta: {
            action: "read",
            resource: "din14-kt-organisasi"
        }
    },
    {
        path: "/din/15",
        element: < DIN15 />,
        meta: {
            action: "read",
            resource: "din15-tersangka"
        }
    },
    {
        path: "/din/16",
        element: < DIN16 />,
        meta: {
            action: "read",
            resource: "din16-kt-pengawasan-media"
        }
    }
]

export default DinRoutes