import { lazy } from "react"

const Notifications = lazy(() => import('../../views/notifications'))

const NotificationsRoutes = [
    {
        path    :'/notification',
        element :<Notifications/>,
        meta: {
            appLayout: true,
            menuHidden: true,
            action: 'read',
			resource: 'in-surat'
        }
    }
]

export default NotificationsRoutes