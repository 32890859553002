import React, { 
    useState, 
    useContext,
    createContext,
    useEffect,
}                                       from "react"

import toast                            from "react-hot-toast"
import { getFieldName }                 from "../utility/report-field-id"

//Context
import { AbilityContext }               from "../utility/context/Can"

//API
import reportAPI                        from "../service/report"
import FeatureAPI                       from "../service/pages/feature"
import serviceAPI                       from "../service/drive/service"
import userManagementAPI                from "../service/pages/user-management"

//Utility
import reportRules                      from "../utility/report-rules/getDataRules"
import acceptTaskRules                  from "../utility/report-rules/acceptTaskRules"

//Third Party
import { getReportField }               from "../utility/report-type-id"
import moment, { lang }                           from "moment"
import workunitAPI from "../service/pages/workunit"

const ListCoordinate = [
    [-6.119490201440935, 106.65407672688582],
    [-6.102421608891286, 106.73956409194048],
    [-6.133486040615893, 106.72789111839889],
    [-6.1450920676617535, 106.71450153110116],
    [-6.18946571529206, 106.67467609195924],
    [-6.213016369868497, 106.66334644116885],
    [-6.2410027336790295, 106.65270343588092],
    [-6.179908628252462, 106.73956409194048],
    [-6.203459709329032, 106.73784747818436],
    [-6.203459709329032, 106.76599994378468],
    [-6.196974733841344, 106.7824794358434],
    [-6.160793936583997, 106.80513873742417],
    [-6.218818543408851, 106.80067554165825],
    [-6.260114513744958, 106.787972599863],
    [-6.120172933813992, 106.83260455752205],
    [-6.219159845741476, 106.84427753106365],
    [-6.140654498946987, 106.89096942523005],
    [-6.1853698420001795, 106.87586322417623],
    [-6.164889999962415, 106.95311084320151],
    [-6.1365582488515376, 106.81578174252687],
    [-6.137240959387886, 106.88993945679114],
    [-6.184345868718197, 106.87380328748364],
    [-6.2331530504856, 106.89440265255705],
    [-6.159087234179548, 106.92461505466473],
    [-6.204483645536191, 106.92495837741595],
    [-6.095935401098463, 106.95826068428462],
    [-6.220183751410019, 107.00323596469491],
    [-6.248852299266616, 106.98950305464597]
]

const ReportContext  = createContext(null)

const ReportProvider = (props) => {
    const ability                                               = useContext(AbilityContext)

    const [report, setReport]                                   = useState([])
    const [dashboardReport, setDashboardReport]                 = useState([])
    const [reportPaginate, setReportPaginate]                   = useState([])
    const [paramsReport, setParamsReport]                       = useState(null)
    const [loadingReport, setLoadingReport]                     = useState(false)
    const [viewerCount, setViewerCount]                         = useState({
        1 : 0,
        2 : 0,
        3 : 0,
        21 : 0,
    })

    const [data, setData]                                       = useState([])
    const [params, setParams]                                   = useState(null)
    const [paramsDate, setParamsDate]                           = useState(null)

    const [paginate, setPaginate]                               = useState(false)
    const [showPrint, setPrint]                                 = useState(false)
    const [loading, setLoading]                                 = useState(false)
    const [showForm, setShowForm]                               = useState(false)
    const [selected, setSelected]                               = useState([])
    const [typePrint, setTypePrint]                             = useState("")
    const [fetchData, setFetchData]                             = useState(false)
    const [totalData, setTotalData]                             = useState(0)
    const [hideColumn, setHideColumn]                           = useState([])
    const [fetchStatus, setFetchStatus]                         = useState(false)
	const [countCopies, setCountCopies]                         = useState({pdf: 0, word: 0})
    const [showModalBio, setShowModalBio]                       = useState(false)
    const [dataSelected, setDataSelected]                       = useState(null)
    const [dataReview, setDataReview]                           = useState(null)
    const [dataInstruction, setDataInstruction]                 = useState(null)

    const [lapbulSelected, setLapbulSelected]                   = useState(null)
    const [dataCategory, setDataCategory]                       = useState(null)
    const [originalData, setOriginalData]                       = useState(null) //use for update approvement
    const [loadingDelete, setLoadingDelete]                     = useState(false)
    const [showFormDelete, setShowFormDelete]                   = useState(false)
    const [showModalFilter, setShowModalFilter]                 = useState(false)
    const [showModalDetail, setShowModalDetail]                 = useState(false)
    const [showModalStatus, setShowModalStatus]                 = useState(false)

    const [toggledClearRows, setToggleClearRows]                = useState(false)
    const [showModalPreview, setShowModalPreview]               = useState(false)
    const [originalMasterData, setOriginalMasterData]           = useState(null) //used for get report values when create letter number
    const [showModalPreviewMap, setShowModalPreviewMap]         = useState(false)
    const [showModalAcceptTask, setShowModalAcceptTask]         = useState(false)
    const [showModalApprovement, setShowModalApprovement]       = useState(false)

    const [showModalReqPerpanjangan, setShowModalReqPerpanjangan] = useState(false)
    const [showModalAccPerpanjangan, setShowModalAccPerpanjangan] = useState(false)

    const [showModalFilterOrder, setShowModalFilterOrder]       = useState(false)
    const [showModalTrackStatus, setShowModalTrackStatus]       = useState(false)
    const [showModalEditHistory, setShowModalEditHistory]       = useState(false)
    const [showModalFilterColumn, setShowModalFilterColumn]     = useState(false)
    const [showModalCompleteTask, setShowModalCompleteTask]     = useState(false)
    const [showModalReview, setShowModalReview]                 = useState(false)
    const [showModalInstruction, setShowModalInstruction]       = useState(false)
    const [showModalInstructionData, setShowModalInstructionData] = useState(false)

    const [detailDinData, setDetailDinData]                     = useState(null)

    const [loadingLapbul, setLoadingLapbul]                     = useState(false)
    const [reportTypeField, setReportTypeField]                 = useState([])
    const [reportTypeFieldLapbul, setReportTypeFieldLapbul]     = useState([])

    const [currentPage, setCurrentPage]                         = useState(1)

    const getAccessFeatures = async(reportData) => {
        const data_ = ability?.rules

        const params = {
            report_type_id : reportData?.reportTypeId,
        }

        const getFeatureAPI = await FeatureAPI.getFeatures(params)
        const features_     = getFeatureAPI?.results[0]

        if (features_?.can_approve_or_reject) {
            data_.push({
                action: 'approve_or_reject',
                subject: reportData?.report
            })
        }
        
        if (features_?.can_create) {
            data_.push({
                action: 'create',
                subject: reportData?.report
            })
        }
        
        if (features_?.can_delete) {
            data_.push({
                action: 'delete',
                subject: reportData?.report
            })
        }
        
        if (features_?.can_read) {
            data_.push({
                action: 'read',
                subject: reportData?.report
            })
        }

        if (features_?.can_request) {
            data_.push({
                action: 'request',
                subject: reportData?.report
            })
        }

        if (features_?.can_update) {
            data_.push({
                action: 'update',
                subject: reportData?.report
            })
        }

        ability?.update(data_)
    }

    const getBidangData = (id) => {
        const params = {
            id      : id,
            source  : 'bidang'
        }

        return reportAPI.getAdditionalData(params)
    }

    const getSektorData = (id) => {
        const params = {
            id      : id,
            source  : 'sektor'
        }

        return reportAPI.getAdditionalData(params)
    }

    const getSubdirektoratData = (id) => {
        const params = {
            id      : id,
            source  : 'subdirektorat'
        }

        return reportAPI.getAdditionalData(params)
    }

    const getSeksiData = (id) => {
        const params = {
            id      : id,
            source  : 'seksi'
        }

        return reportAPI.getAdditionalData(params)
    }

    const getUserData = async (id) => {
        const params = {
            id : id
        }

        const response = await userManagementAPI.getUserManagement(params)
       
        await Promise.all(
            await response?.results?.map(async(result) => {
                if (!isNaN(parseInt(result.code_id))) {
                    await getBidangData(parseInt(result.code_id)).then(
                        res => {
                            if (res.results) {
                                result.data_bidang  = res.results[0]
                            }
                        }
                    )
                }
    
                if (!isNaN(parseInt(result.code_sector_id))) {
                    await getSektorData(parseInt(result.code_sector_id)).then(
                        res => {
                            if (res.results) {
                                result.data_sektor  = res.results[0]
                            }
                        }
                    )
                }
    
                if (!isNaN(parseInt(result.code_subdir_id))) {
                    await getSubdirektoratData(parseInt(result.code_subdir_id)).then(
                        res => {
                            if (res.results) {
                                result.data_subdir  = res.results[0]
                            }
                        }
                    )
                }
    
                if (!isNaN(parseInt(result.code_seksi_id))) {
                    await getSeksiData(parseInt(result.code_seksi_id)).then(
                        res => {
                            if (res.results) {
                                result.data_seksi   = res.results[0]
                            }
                        }
                    )
                }
            })
        )
        
        return response
    } 

    const getUserDataByUser = async (id) => {

        const params = {
            user : id
        }

        const response = await userManagementAPI.getUserManagement(params)

        await Promise.all(
            await response?.results?.map(async(result) => {
                if (!isNaN(parseInt(result.code_id))) {
                    await getBidangData(parseInt(result.code_id)).then(
                        res => {
                            if (res.results) {
                                result.data_bidang  = res.results[0]
                            }
                        }
                    )
                }
    
                if (!isNaN(parseInt(result.code_sector_id))) {
                    await getSektorData(parseInt(result.code_sector_id)).then(
                        res => {
                            if (res.results) {
                                result.data_sektor  = res.results[0]
                            }
                        }
                    )
                }
    
                if (!isNaN(parseInt(result.code_subdir_id))) {
                    await getSubdirektoratData(parseInt(result.code_subdir_id)).then(
                        res => {
                            if (res.results) {
                                result.data_subdir  = res.results[0]
                            }
                        }
                    )
                }
    
                if (!isNaN(parseInt(result.code_seksi_id))) {
                    await getSeksiData(parseInt(result.code_seksi_id)).then(
                        res => {
                            if (res.results) {
                                result.data_seksi   = res.results[0]
                            }
                        }
                    )
                }
            })
        )
        
        return response
    }

    const getStatusData = (id) => {

        const params = {
            id   : id
        }

        return reportAPI.getReportStatus(params)
    }

    const getSumberSuratData = (id) => {
        const params = {
            id      : id,
        }

        return reportAPI.getReport(params)
    }

    const getReportExtraData = (id) => {
        const params = {
            report_id : id,
        }

        return reportAPI.getReportCheck(params)
    }

    const getFoto = (code) => {
        const params = `code=${code}`
        return serviceAPI.getFileService(params)
    }

    const getChildData = async (data, id) => {
        let result 
        try {
            const dataId = await reportAPI.getReportType(id)
            result =  getFieldName(data, dataId.data?.field_report_type)
            return result
        } catch (error) {
            
        }
    
    }

    const getLastStatusApprovement = (track_status) => {

		if (track_status?.length > 0) {
			const lastIndex_ = track_status[track_status.length - 1]

            const obj_ = {
                message : lastIndex_.messages
            }

            if (lastIndex_.status_approvement === 1) {
                obj_.id = 1 
                obj_.status = "Baru Dibuat"
            } else if (lastIndex_.status_approvement === 2) {
                obj_.id = 2
                obj_.status = "Disetujui"
            } else if (lastIndex_.status_approvement === 3) {
                obj_.id = 3
                obj_.status = "Diproses"
            } else if (lastIndex_.status_approvement === 4) {
                obj_.id = 4
                obj_.status = "Ditolak"
            } else if (lastIndex_.status_approvement === 5) {
                obj_.id = 5
                obj_.status = "Diperbaiki"
            } else if (lastIndex_.status_approvement === 6) {
                obj_.id = 6
                obj_.status = "Disposisi"
            } else if (lastIndex_.status_approvement === 7) {
                obj_.id = 7
                obj_.status = "Selesai"
            } else if (lastIndex_.status_approvement === 8) {
                obj_.id = 8
                obj_.status = "Masa Tenggang"
            } else if (lastIndex_.status_approvement === 9) {
                obj_.id = 9
                obj_.status = "Tidak Selesai"
            } 

			return obj_
		} else {
            return {
                id :1,
                status : 'Baru Dibuat'
            }
        }
	}

    const getExpiredStatusAprrovement = (track_status, reportTypeId) => {

        const filter_ = track_status.filter((data) => data.status_approvement === 2)

        if (filter_.length > 0) {
            if (parseInt(reportTypeId) === 3) {
                return moment(filter_[0].created_at).add(30, 'd').format('DD MMMM YYYY')
            } else {
                return moment(filter_[0].created_at).add(7, 'd').format('DD MMMM YYYY')
            }
        } else {
            return null
        }
    }

    const getDetailSumberSurat = async(reportId) => {
        if (Array.isArray(reportId)) {
            const arr_ = await Promise.all(reportId?.map(async (item) => {
                const getSumberSurat = await getSumberSuratData(parseInt(item))
                if (getSumberSurat?.count > 0) {
                    const getSumberSuratField = await reportAPI.getReportType(getSumberSurat?.results[0]?.report_type?.id)
                    return await {
                        ...getFieldName(getSumberSurat.results[0], getSumberSuratField.data.field_report_type),
                        report_type : getSumberSuratField.data
                    }
                } else {
                    return {}
                }
            }))
            return arr_
        } else {
            const getSumberSurat = await getSumberSuratData(parseInt(reportId))
            if (getSumberSurat?.count > 0) {
                const getSumberSuratField = await reportAPI.getReportType(getSumberSurat?.results[0]?.report_type?.id)
                const dataSumberSurat     = await getFieldName(getSumberSurat.results[0], getSumberSuratField.data.field_report_type)
                dataSumberSurat.report_type = getSumberSuratField.data

                if ("mengetahui" in dataSumberSurat) {
                    await getUserDataByUser(parseInt(dataSumberSurat.mengetahui)).then(
                        res => {
                            if (res.results) {
                                dataSumberSurat.mengetahui = res.results[0]
                            }
                        }
                    )
                }

                if ("pembuat" in dataSumberSurat) {
                    await getUserData(parseInt(dataSumberSurat.pembuat)).then(
                        res => {
                            if (res.results) {
                                dataSumberSurat.pembuat = res.results[0]
                            }
                        }
                    )
                }

                if ("penandatangan" in dataSumberSurat) {
                    await getUserDataByUser(parseInt(dataSumberSurat.penandatangan)).then(
                        res => {
                            if (res.results) {
                                dataSumberSurat.penandatangan = res.results[0]
                            }
                        }
                    )
                }

                if ("memerintahkan" in dataSumberSurat) {
                    await getUserDataByUser(parseInt(dataSumberSurat.memerintahkan)).then(
                        res => {
                            if (res.results) {
                                dataSumberSurat.memerintahkan = res.results[0]
                            }
                        }
                    )
                }

                return dataSumberSurat
            } else {
                return '-'
            }
        }
    }

    const getDataParent = async (reportId, current_data) => {
        const newData           = current_data ? current_data : []
        let dataSumberSurat     = {}
        const getSumberSurat    = await getSumberSuratData(parseInt(reportId))
        if (getSumberSurat?.count > 0) {
            const getSumberSuratField   = await reportAPI.getReportType(getSumberSurat?.results[0]?.report_type?.id)
            dataSumberSurat             = {
                report_type : getSumberSurat?.results[0]?.report_type?.name,
                data        : await getFieldName(getSumberSurat.results[0], getSumberSuratField.data.field_report_type)
            }

            if (dataSumberSurat?.data?.sektor) {
                if (!isNaN(parseInt(dataSumberSurat?.data?.sektor))) {
                    await getSektorData(parseInt(dataSumberSurat?.data?.sektor)).then(
                        res => {
                            if (res.results) {
                                dataSumberSurat.data.sektor = res.results[0]
                            }
                        }
                    )
                }
            }

            if (dataSumberSurat?.data?.bidang) {
                if (!isNaN(parseInt(dataSumberSurat?.data?.bidang))) {
                    await getBidangData(parseInt(dataSumberSurat?.data?.bidang)).then(
                        res => {
                            if (res.results) {
                                dataSumberSurat.data.bidang = res.results[0]
                            }
                        }
                    )
                }
            }

            if (dataSumberSurat?.data?.memerintahkan) {
                if (Array.isArray(dataSumberSurat?.data?.memerintahkan)) {
                    const userData_ = []

                    await Promise.all(
                        dataSumberSurat?.data?.memerintahkan.map(async (data, index) => (
                            await getUserDataByUser(parseInt(data)).then(
                                res => {
                                    if (res.results) {
                                        userData_[index] = res.results[0]
                                    }
                                }
                            )
                        ))
                    )
                    
                    dataSumberSurat.data.memerintahkan = userData_
                } else {

                    await getUserDataByUser(parseInt(dataSumberSurat?.data?.memerintahkan)).then(
                        res => {

                            if (res.results) {
                                dataSumberSurat.data.memerintahkan = res.results[0]
                            }
                        }
                    )
                }
            }

            if (dataSumberSurat?.data?.sumber_surat) {
                await getDataParent(dataSumberSurat?.data?.sumber_surat, newData)
                // const parent                    = await getDataParent(dataSumberSurat?.data?.sumber_surat, newData)
                // dataSumberSurat.data.parent     = parent
            }

            newData.push(dataSumberSurat)
            
        }

        return newData
    }
    
    const getData = async (reportTypeId, headerReport, userId) => {
        try {
            setData([])
            setLoading(true)

            if (parseInt(reportTypeId) === 1 || parseInt(reportTypeId) === 2 || parseInt(reportTypeId) === 3 || parseInt(reportTypeId) === 21) {
                setDashboardReport([])
            } 

            const defaultParams = {
                page            : 1,
                reload          : true,
                is_draft        : 'all',
                page_size       : 10,
                report_type_id  : reportTypeId,
            }


            if (paramsDate) {
                Object.assign(defaultParams, paramsDate)
            }
            
            if (userId) {
                defaultParams.created_by = userId
            }

            
            //params berdasarkan role
            const moreParams_ = reportRules(reportTypeId)

            if (params) {
                Object.assign(defaultParams, params)
            }

            if (params?.isFilterStatus) {
                params.report_approvement_id = params.report_approvement_id_new
                delete defaultParams.is_draft
                // delete params.isFilterStatus
            } else if (Object.keys(moreParams_).length > 0) {
                Object.assign(defaultParams, moreParams_)
            }
            
            // Pengecekan data tambahan berdasarkan header
            const header_ = headerReport.map((data) => (data.unique_key))

            // Pengambilan data getReportType dan reportAPI.getReport secara bersamaan
            const [getReport, getReportType] = await Promise.all([
                reportAPI.getReport(defaultParams),
                reportAPI.getReportType(reportTypeId)
            ])

            await setOriginalMasterData(getReport?.results)
            await setReportTypeField(getReportType?.data?.field_report_type)

            if (getReport?.results !== null) {
                const newFormatData_ = []

                await Promise.all(
                    await getReport?.results.map(async (result, index) => {

                        let data_               = {}
                        data_                   = await getFieldName(result, getReportType?.data?.field_report_type)
                        data_.is_draft          = result?.is_draft
                        data_.is_read           = result?.is_read
                        data_.index             = ((((getReport?.page - 1) * getReport?.page_size)) + (index + 1))
                        data_.created_by        = result?.created_by
                        data_.track_status      = result?.report_approvement_report
                        data_.expired_date      = await getExpiredStatusAprrovement(result?.report_approvement_report, reportTypeId)
                        data_.last_track_status = await getLastStatusApprovement(result?.report_approvement_report)
                        data_.report_type       = result.report_type
                        data_.created_at_unix    = moment(data_.created_at, "D MMMM YYYY", "id").startOf("day").unix()
                        data_.expired_date_unix  = moment(data_.expired_date, "D MMMM YYYY", "id").startOf("day").unix()

                        
                        if (parseInt(reportTypeId) === 62) {
                            data_.parent        = await getDataParent(data_?.sumber_surat, [])
                        }

                        //pengecekan data tambahan
                        if (header_.includes("satuan_kerja")) {
                            // Get user data asynchronously
                            const getUserData_  = await getUserDataByUser(parseInt(result?.created_by))
                            data_.created_by    = getUserData_.results[0]
                            data_.satuan_kerja  = `${getUserData_?.results[0]?.work_unit?.work_unit_level?.name} ${getUserData_?.results[0]?.work_unit?.name}`

                            data_.penyelenggara                  = data_.created_by?.code?.name
                            data_.sub_penyelenggara              = data_.created_by?.code_subdir?.name
                            data_.sub_sub_penyelenggara          = data_.created_by?.code_seksi?.name
                            data_.sub_sub_sub_penyelenggara      = data_.created_by?.code_sector?.name
                        }

                        if (header_.includes("sumber_surat")) {
                            if (!isNaN(parseInt(data_?.sumber_surat))) {
                                data_.sumber_surat = await getDetailSumberSurat(data_?.sumber_surat)
                            }
                        }

                        if (header_.includes("identitas_lengkap")) {
                            if (!isNaN(parseInt(data_?.identitas_lengkap))) {
                                data_.identitas_lengkap = await getDetailSumberSurat(data_?.identitas_lengkap)
                            }
                        }

                        if (header_.includes("sp_operasi_intelijen")) {
                            if (!isNaN(parseInt(data_?.sp_operasi_intelijen))) {
                                data_.sp_operasi_intelijen = await getDetailSumberSurat(data_?.sp_operasi_intelijen)
                            }
                        }

                        if (header_.includes("kartutik_barang_cetakan")) {

                            const getSumberSurat      = await getSumberSuratData(parseInt(data_?.kartutik_barang_cetakan))
                            if (getSumberSurat.count > 0) {
                                const getSumberSuratField = await reportAPI.getReportType(getSumberSurat?.results[0]?.report_type?.id)
                                const resultSumberSurat   = await getFieldName(getSumberSurat.results[0], getSumberSuratField.data.field_report_type)
                                data_.kartutik_barang_cetakan = resultSumberSurat
                            } else {
                                data_.kartutik_barang_cetakan = '-'
                            }
                        }

                        if (header_.includes("kartutik_media_komunikasi")) {

                            const getSumberSurat      = await getSumberSuratData(parseInt(data_?.kartutik_media_komunikasi))
                            if (getSumberSurat.count > 0) {
                                const getSumberSuratField = await reportAPI.getReportType(getSumberSurat?.results[0]?.report_type?.id)
                                const resultSumberSurat   = await getFieldName(getSumberSurat.results[0], getSumberSuratField.data.field_report_type)
                                data_.kartutik_media_komunikasi = resultSumberSurat
                            } else {
                                data_.kartutik_media_komunikasi = '-'
                            }
                        }

                        if (header_.includes("kartutik_organisasi_kemasyarakatan")) {

                            const getSumberSurat      = await getSumberSuratData(parseInt(data_?.kartutik_organisasi_kemasyarakatan))
                            if (getSumberSurat.count > 0) {
                                const getSumberSuratField = await reportAPI.getReportType(getSumberSurat?.results[0]?.report_type?.id)
                                const resultSumberSurat   = await getFieldName(getSumberSurat.results[0], getSumberSuratField.data.field_report_type)
                                data_.kartutik_organisasi_kemasyarakatan = resultSumberSurat
                            } else {
                                data_.kartutik_organisasi_kemasyarakatan = '-'
                            }
                        }

                        if (header_.includes("kartutik_tersangka")) {

                            const getSumberSurat      = await getSumberSuratData(parseInt(data_.kartutik_tersangka))
                            if (getSumberSurat.count > 0) {
                                const getSumberSuratField = await reportAPI.getReportType(getSumberSurat?.results[0]?.report_type?.id)
                                const resultSumberSurat   = await getFieldName(getSumberSurat.results[0], getSumberSuratField.data.field_report_type)
                                data_.kartutik_tersangka = resultSumberSurat
                            } else {
                                data_.kartutik_tersangka = '-'
                            }
                        }

                        if (header_.includes("bidang")) {
                            await getBidangData(parseInt(data_?.bidang)).then(
                                res => {
                                    if (res.results) {
                                        data_.bidang = res.results[0]
                                    }
                                }
                            )
                        }

                        if (header_.includes("sektor")) {
                            await getSektorData(parseInt(data_.sektor)).then(
                                res => {
                                    if (res.results) {
                                        data_.sektor = res.results[0]
                                    }
                                }
                            )
                        }
                        
                        if (header_.includes("kartutik_identitas")) {

                            const getSumberSurat      = await getSumberSuratData(parseInt(data_.kartutik_identitas))
                            if (getSumberSurat.count > 0) {
                                const getSumberSuratField = await reportAPI.getReportType(getSumberSurat?.results[0]?.report_type?.id)
                                const resultSumberSurat   = await getFieldName(getSumberSurat.results[0], getSumberSuratField.data.field_report_type)
                                data_.kartutik_identitas = resultSumberSurat
                            } else {
                                data_.kartutik_identitas = '-'
                            }
                        }

                        if (parseInt(reportTypeId) === 1 || parseInt(reportTypeId) === 3 || parseInt(reportTypeId) === 30) {
                            const extraData      = await getReportExtraData(parseInt(data_.report_id))
                            data_.extra_data      = extraData?.results
                        } 

                        if (header_.includes("penandatangan")) {

                            if (!isNaN(parseInt(data_.penandatangan))) {
                                await getUserDataByUser(parseInt(data_.penandatangan)).then(
                                    res => {
                                        if (res.results) {
                                            data_.penandatangan = res.results[0]
                                        }
                                    }
                                )
                            }
                            
                        }
                        
                        newFormatData_.push(data_)
                    })

                )

                console.log("newFormatData_", newFormatData_)

                if (parseInt(reportTypeId) === 1 || parseInt(reportTypeId) === 2 || parseInt(reportTypeId) === 3 || parseInt(reportTypeId) === 21) {
                   setDashboardReport(newFormatData_.sort((a, b) => a.index - b.index))
                } 
                setData(newFormatData_.sort((a, b) => a.index - b.index))
                setTotalData(getReport?.count)
                setPaginate(getReport)

                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
            console.error("Error:", error)
            // Handle the error appropriately
        }
    }

    const getData_ = async (reportTypeId, headerReport, userId) => {
        try {
            setReport([])
            setLoadingReport(true)

            const defaultParams = {
                page            : 1,
                reload          : true,
                is_draft        : 'all',
                page_size       : 10,
                report_type_id  : reportTypeId,
            }

            if (params) {
                Object.assign(defaultParams, paramsReport)
            }

            if (userId) {
                defaultParams.created_by = userId
            }

            //params berdasarkan role
            const moreParams_ = reportRules(reportTypeId)

            if (Object.keys(moreParams_).length > 0) {
                Object.assign(defaultParams, moreParams_)
            }

            // Pengecekan data tambahan berdasarkan header
            const header_ = headerReport.map((data) => (data.unique_key))

            // Pengambilan data getReportType dan reportAPI.getReport secara bersamaan
            const [getReport, getReportType] = await Promise.all([
                reportAPI.getReport(defaultParams),
                reportAPI.getReportType(reportTypeId)
            ])

            await setOriginalMasterData(getReport?.results)
            await setReportTypeField(getReportType?.data?.field_report_type)

            if (getReport?.results !== null) {
                const newFormatData_ = []

                await Promise.all(
                    await getReport?.results.map(async (result, index) => {

                        let data_               = {}
                        data_                   = await getFieldName(result, getReportType?.data?.field_report_type)
                        data_.is_draft          = result?.is_draft
                        data_.index             = ((((getReport?.page - 1) * getReport?.page_size)) + (index + 1))
                        data_.created_by        = result?.created_by
                        data_.track_status      = result?.report_approvement_report
                        data_.expired_date      = await getExpiredStatusAprrovement(result?.report_approvement_report, reportTypeId)
                        data_.last_track_status = await getLastStatusApprovement(result?.report_approvement_report)
                        data_.report_type       = result.report_type
                        
                        if (parseInt(reportTypeId) === 62) {
                            data_.parent        = await getDataParent(data_?.sumber_surat, [])
                        }

                        //pengecekan data tambahan
                        if (header_.includes("satuan_kerja")) {
                            // Get user data asynchronously
                            const getUserData_  = await getUserDataByUser(parseInt(result?.created_by))
                            data_.created_by    = getUserData_.results[0]
                            data_.satuan_kerja  = `${getUserData_?.results[0]?.work_unit?.work_unit_level?.name} ${getUserData_?.results[0]?.work_unit?.name}`

                            data_.penyelenggara                  = data_.created_by?.code?.name
                            data_.sub_penyelenggara              = data_.created_by?.code_subdir?.name
                            data_.sub_sub_penyelenggara          = data_.created_by?.code_seksi?.name
                            data_.sub_sub_sub_penyelenggara      = data_.created_by?.code_sector?.name
                        }

                        if (header_.includes("sumber_surat")) {
                            if (!isNaN(parseInt(data_?.sumber_surat))) {
                                data_.sumber_surat = await getDetailSumberSurat(data_?.sumber_surat)
                            }
                        }

                        if (header_.includes("identitas_lengkap")) {
                            if (!isNaN(parseInt(data_?.identitas_lengkap))) {
                                data_.identitas_lengkap = await getDetailSumberSurat(data_?.identitas_lengkap)
                            }
                        }

                        if (header_.includes("sp_operasi_intelijen")) {
                            if (!isNaN(parseInt(data_?.sp_operasi_intelijen))) {
                                data_.sp_operasi_intelijen = await getDetailSumberSurat(data_?.sp_operasi_intelijen)
                            }
                        }

                        if (header_.includes("kartutik_barang_cetakan")) {

                            const getSumberSurat      = await getSumberSuratData(parseInt(data_?.kartutik_barang_cetakan))
                            if (getSumberSurat.count > 0) {
                                const getSumberSuratField = await reportAPI.getReportType(getSumberSurat?.results[0]?.report_type?.id)
                                const resultSumberSurat   = await getFieldName(getSumberSurat.results[0], getSumberSuratField.data.field_report_type)
                                data_.kartutik_barang_cetakan = resultSumberSurat
                            } else {
                                data_.kartutik_barang_cetakan = '-'
                            }
                        }

                        if (header_.includes("kartutik_media_komunikasi")) {

                            const getSumberSurat      = await getSumberSuratData(parseInt(data_?.kartutik_media_komunikasi))
                            if (getSumberSurat.count > 0) {
                                const getSumberSuratField = await reportAPI.getReportType(getSumberSurat?.results[0]?.report_type?.id)
                                const resultSumberSurat   = await getFieldName(getSumberSurat.results[0], getSumberSuratField.data.field_report_type)
                                data_.kartutik_media_komunikasi = resultSumberSurat
                            } else {
                                data_.kartutik_media_komunikasi = '-'
                            }
                        }

                        if (header_.includes("kartutik_organisasi_kemasyarakatan")) {

                            const getSumberSurat      = await getSumberSuratData(parseInt(data_?.kartutik_organisasi_kemasyarakatan))
                            if (getSumberSurat.count > 0) {
                                const getSumberSuratField = await reportAPI.getReportType(getSumberSurat?.results[0]?.report_type?.id)
                                const resultSumberSurat   = await getFieldName(getSumberSurat.results[0], getSumberSuratField.data.field_report_type)
                                data_.kartutik_organisasi_kemasyarakatan = resultSumberSurat
                            } else {
                                data_.kartutik_organisasi_kemasyarakatan = '-'
                            }
                        }

                        if (header_.includes("kartutik_tersangka")) {

                            const getSumberSurat      = await getSumberSuratData(parseInt(data_.kartutik_tersangka))
                            if (getSumberSurat.count > 0) {
                                const getSumberSuratField = await reportAPI.getReportType(getSumberSurat?.results[0]?.report_type?.id)
                                const resultSumberSurat   = await getFieldName(getSumberSurat.results[0], getSumberSuratField.data.field_report_type)
                                data_.kartutik_tersangka = resultSumberSurat
                            } else {
                                data_.kartutik_tersangka = '-'
                            }
                        }

                        if (header_.includes("bidang")) {
                            await getBidangData(parseInt(data_.bidang)).then(
                                res => {
                                    if (res.results) {
                                        data_.bidang = res.results[0]
                                    }
                                }
                            )
                        }

                        if (header_.includes("sektor")) {
                            await getSektorData(parseInt(data_.sektor)).then(
                                res => {
                                    if (res.results) {
                                        data_.sektor = res.results[0]
                                    }
                                }
                            )
                        }
                        
                        if (header_.includes("kartutik_identitas")) {

                            const getSumberSurat      = await getSumberSuratData(parseInt(data_.kartutik_identitas))
                            if (getSumberSurat.count > 0) {
                                const getSumberSuratField = await reportAPI.getReportType(getSumberSurat?.results[0]?.report_type?.id)
                                const resultSumberSurat   = await getFieldName(getSumberSurat.results[0], getSumberSuratField.data.field_report_type)
                                data_.kartutik_identitas = resultSumberSurat
                            } else {
                                data_.kartutik_identitas = '-'
                            }
                        }

                        if (parseInt(reportTypeId) === 1 || parseInt(reportTypeId) === 3 || parseInt(reportTypeId) === 55) {
                            const extraData      = await getReportExtraData(parseInt(data_.report_id))
                            data_.extra_data      = extraData?.results
                        } 

                        if (header_.includes("penandatangan")) {

                            if (!isNaN(parseInt(data_.penandatangan))) {
                                await getUserDataByUser(parseInt(data_.penandatangan)).then(
                                    res => {
                                        if (res.results) {
                                            data_.penandatangan = res.results[0]
                                        }
                                    }
                                )
                            }
                            
                        }
                        
                        newFormatData_.push(data_)
                    })
                )

                setReport(newFormatData_.sort((a, b) => a.index - b.index))
                // setTotalData(getReport?.count)
                setReportPaginate(getReport)

                setLoadingReport(false)
            }
        } catch (error) {
            setLoadingReport(false)
            console.error("Error:", error)
            // Handle the error appropriately
        }
    }

    const getDataById = async (reportID, reportTypeId, withData) => {
        const newFormatData_ = []

        console.log('tidak ada', reportID, reportTypeId)

        const [getReport, getReportType] = await Promise.all([
            reportAPI.getReport({id : reportID}),
            reportAPI.getReportType(reportTypeId)
        ])

        if (getReport?.results !== null) {

            await Promise.all(
                await getReport?.results.map(async (result, index) => {

                    let data_               = {}
                    data_                   = await getFieldName(result, getReportType?.data?.field_report_type)
                    data_.is_draft          = result?.is_draft
                    data_.index             = ((((getReport?.page - 1) * getReport?.page_size)) + (index + 1))
                    data_.created_by        = result?.created_by
                    data_.track_status      = result?.report_approvement_report
                    data_.expired_date      = await getExpiredStatusAprrovement(result?.report_approvement_report, reportTypeId)
                    data_.last_track_status = await getLastStatusApprovement(result?.report_approvement_report)

                    const getUserData_      = await getUserDataByUser(parseInt(result?.created_by))
                    data_.created_by        = getUserData_.results[0]
                    data_.satuan_kerja      = `${getUserData_?.results[0]?.work_unit?.work_unit_level?.name} ${getUserData_?.results[0]?.work_unit?.name}`
                    data_.report_type       = getReportType.data
                    
                    data_.penyelenggara                  = data_.created_by?.code?.name
                    data_.sub_penyelenggara              = data_.created_by?.code_subdir?.name
                    data_.sub_sub_penyelenggara          = data_.created_by?.code_seksi?.name
                    data_.sub_sub_sub_penyelenggara      = data_.created_by?.code_sector?.name

                    if (data_?.sumber_surat) {
                        data_.sumber_surat = await getDetailSumberSurat(data_?.sumber_surat)
                    }

                    if (data_?.identitas_lengkap) {
                        data_.identitas_lengkap = await getDetailSumberSurat(data_?.identitas_lengkap)
                    }
                    // if (condition) {
                        
                    // }
                    if (withData) {

                        if ("memerintahkan" in data_) {
                            if (Array.isArray(data_.memerintahkan)) {
                                const userData_ = []
            
                                await Promise.all(
                                    data_.memerintahkan.map(async (data, index) => (
                                        await getUserDataByUser(parseInt(data)).then(
                                            res => {
                                                if (res.results) {
                                                    userData_[index] = res.results[0]
                                                }
                                            }
                                        )
                                    ))
                                )
                                
                                data_.memerintahkan = userData_
                            } else {
            
                                await getUserDataByUser(parseInt(data_.memerintahkan)).then(
                                    res => {
            
                                        if (res.results) {
                                            data_.memerintahkan = res.results[0]
                                        }
                                    }
                                )
                            }
                        }

                        if ("pelaksana_tugas" in data_) {
                            if (Array.isArray(data_.pelaksana_tugas)) {
                                const userData_ = []
            
                                await Promise.all(
                                    data_.pelaksana_tugas.map(async (data, index) => (
                                        await getUserDataByUser(parseInt(data)).then(
                                            res => {
                                                if (res.results) {
                                                    userData_[index] = res.results[0]
                                                }
                                            }
                                        )
                                    ))
                                )
                                
                                data_.pelaksana_tugas = userData_
                            } else {
            
                                await getUserDataByUser(parseInt(data_.pelaksana_tugas)).then(
                                    res => {
            
                                        if (res.results) {
                                            data_.pelaksana_tugas = res.results[0]
                                        }
                                    }
                                )
                            }
                        }
    
                        if ("penandatangan" in data_) {
                            if (Array.isArray(data_.penandatangan)) {
                                const userData_ = []
            
                                await Promise.all(
                                    data_.penandatangan.map(async(data) => (
                                        await getUserDataByUser(parseInt(data)).then(
                                            res => {
            
                                                if (res.results) {
                                                    userData_.push(res.results[0])
                                                }
                                            }
                                        )
                                    ))
                                )
            
                                data_.penandatangan = userData_
                            } else {
    
                                
                                await getUserDataByUser(parseInt(data_.penandatangan)).then(
                                    res => {
            
                                        if (res.results) {
                                            data_.penandatangan = res.results[0]
                                        }
                                    }
                                )
                            }
                        }

                        if ("penandatangan_otentikasi" in data_) {
                            if (Array.isArray(data_.penandatangan_otentikasi)) {
                                const userData_ = []
            
                                await Promise.all(
                                    data_.penandatangan_otentikasi.map(async(data) => {
                                        if (!isNaN(parseInt(data))) {
                                            await getUserDataByUser(parseInt(data)).then(
                                                res => {
                
                                                    if (res.results) {
                                                        userData_.push(res.results[0])
                                                    }
                                                }
                                            )
                                        }
                                    })
                                )
            
                                data_.penandatangan_otentikasi = userData_
                            } else {
    
                                if (!isNaN(parseInt(data_.penandatangan_otentikasi))) {
                                    await getUserDataByUser(parseInt(data_.penandatangan_otentikasi)).then(
                                        res => {
                
                                            if (res.results) {
                                                data_.penandatangan_otentikasi = res.results[0]
                                            }
                                        }
                                    )
                                }
                               
                            }
                        }

                        // if ("sumber_surat" in data_) {
                        //     if (!isNaN(parseInt(data_?.sumber_surat))) {
                        //         data_.sumber_surat = await getDetailSumberSurat(data_?.sumber_surat)
                        //     }
                        // }

                    }

                    newFormatData_.push(data_)
                })
            )
        }

        return newFormatData_
    }

    const getDetailData = async(detailData) => {
        toast.success('Mohon menunggu proses sedang berlangsung.')

        try {
            const data_ = {...detailData}

            setDataSelected(null)
    
            if (typeof data_ === 'object') {
                if ("memerintahkan" in data_) {
                    if (Array.isArray(data_.memerintahkan)) {
                        const userData_ = []
    
                        await Promise.all(
                            data_.memerintahkan.map(async (data, index) => (
                                await getUserDataByUser(parseInt(data)).then(
                                    res => {
                                        if (res.results) {
                                            userData_[index] = res.results[0]
                                        }
                                    }
                                )
                            ))
                        )
                        
                        data_.memerintahkan = userData_
                    } else {
    
                        await getUserDataByUser(parseInt(data_.memerintahkan)).then(
                            res => {
    
                                if (res.results) {
                                    data_.memerintahkan = res.results[0]
                                }
                            }
                        )
                    }
                }
                        
                if ("pelaksana_tugas" in data_) {
                    if (Array.isArray(data_.pelaksana_tugas)) {
                        const userData_ = []
    
                        await Promise.all(
                            data_.pelaksana_tugas.map(async (data, index) => (
                                await getUserDataByUser(parseInt(data)).then(
                                    res => {
                                        if (res.results) {
                                            userData_[index] = res.results[0]
                                        }
                                    }
                                )
                            ))
                        )
                        
                        data_.pelaksana_tugas = userData_
                    } else {
    
                        await getUserDataByUser(parseInt(data_.pelaksana_tugas)).then(
                            res => {
    
                                if (res.results) {
                                    data_.pelaksana_tugas = res.results[0]
                                }
                            }
                        )
                    }
                }
    
                if ("penandatangan" in data_) {
                    if (Array.isArray(data_.penandatangan)) {
                        const userData_ = []
    
                        await Promise.all(
                            data_.penandatangan.map(async(data) => (
                                await getUserDataByUser(parseInt(data)).then(
                                    res => {
    
                                        if (res.results) {
                                            userData_.push(res.results[0])
                                        }
                                    }
                                )
                            ))
                        )
    
                        data_.penandatangan = userData_
                    } else {

                        if (typeof data_.penandatangan === "string") {
                            await getUserDataByUser(parseInt(data_.penandatangan)).then(
                                res => {
        
                                    if (res.results) {
                                        data_.penandatangan = res.results[0]
                                    }
                                }
                            )
                        }
                    }
                }
                
                if ("penandatangan_otentikasi" in data_) {
                    if (Array.isArray(data_.penandatangan_otentikasi)) {
                        const userData_ = []
    
                        await Promise.all(
                            data_.penandatangan_otentikasi.map(async(data) => {
                                if (!isNaN(parseInt(data))) {
                                    await getUserDataByUser(parseInt(data)).then(
                                        res => {
        
                                            if (res.results) {
                                                userData_.push(res.results[0])
                                            }
                                        }
                                    )
                                }
                            })
                        )
    
                        data_.penandatangan_otentikasi = userData_
                    } else {
                        if (!isNaN(parseInt(data_.penandatangan_otentikasi))) {
                            await getUserDataByUser(parseInt(data_.penandatangan_otentikasi)).then(
                                res => {
        
                                    if (res.results) {
                                        data_.penandatangan_otentikasi = res.results[0]
                                    }
                                }
                            )
                        }
                    }
                }

                if ("created_by" in data_) {
                    if (Array.isArray(data_.created_by)) {
                        const userData_ = []
    
                        await Promise.all(
                            data_.created_by.map(async(data) => (
                                !isNaN(parseInt(data)) &&
                                await getUserDataByUser(parseInt(data)).then(
                                    res => {
    
                                        if (res.results) {
                                            userData_.push(res.results[0])
                                        }
                                    }
                                )
                            ))
                        )
    
                        data_.created_by = userData_
                    } else {
                        if (!isNaN(parseInt(data_.created_by))) {
                            await getUserDataByUser(parseInt(data_?.created_by?.id)).then(
                                res => {
                                    if (res.results) {
                                        data_.created_by = res.results[0]
                                    }
                                }
                            )
                        }
                    }
                }
    
                if ("bertemu_dengan" in data_) {
                        if (Array.isArray(data_.bertemu_dengan)) {
                            const userData_ = []
    
                            await data_.bertemu_dengan.map((data) => (
                                getUserData(parseInt(data)).then(
                                    res => {
                                        if (res.results) {
                                            userData_.push(res.results[0])
                                        }
                                    }
                                )
                            ))
    
                            data_.bertemu_dengan = userData_
                        } else {
                            await getUserData(parseInt(data_.bertemu_dengan)).then(
                                res => {
    
                                    if (res.results) {
                                        data_.bertemu_dengan = res.results[0]
                                    }
                                }
                            )
                        }
                }
    
                if ("petugas_wawancara" in data_) {
                        if (Array.isArray(data_.petugas_wawancara)) {
                            const userData_ = []
    
                            await data_.petugas_wawancara.map((data) => (
                                 getUserDataByUser(parseInt(data)).then(
                                    res => {
    
                                        if (res.results) {
                                            userData_.push(res.results[0])
                                        }
                                    }
                                )
                            ))
    
                            data_.petugas_wawancara = userData_
                        } else {
                            await getUserDataByUser(parseInt(data_.petugas_wawancara)).then(
                                res => {
    
                                    if (res.results) {
                                        data_.petugas_wawancara = res.results[0]
                                    }
                                }
                            )
                        }
                }
    
                if ("pelaksana" in data_) {
                    if (Array.isArray(data_.pelaksana)) {
                        const userData_ = []
                        
                        await Promise.all(
                            data_.pelaksana.map(async(data) => (
                                await getUserDataByUser(parseInt(data)).then(
                                    res => {
                                        if (res.results) {
                                            userData_.push(res.results[0])
                                        }
                                    }
                                )
                            ))
                        )

                        const sorting   = []
                        data_.pelaksana?.map((id) => {
                            const filter = userData_?.filter((dt) => `${dt?.user_id}` === `${id}`)
                            if (filter?.length > 0) {
                                sorting.push(filter[0])
                            }
                        })
    
                        data_.pelaksana = sorting
                    } else {
                        await getUserDataByUser(parseInt(data_.pelaksana)).then(
                            res => {
    
                                if (res.results) {
                                    data_.pelaksana = res.results[0]
                                }
                            }
                        )
                    }
                }

                if ("pembuat" in data_) {
                    if (Array.isArray(data_.pembuat)) {
                        const userData_ = []
    
                        await Promise.all(
                            data_.pembuat.map(async(data) => (
                                await getUserData(parseInt(data)).then(
                                    res => {
    
                                        if (res.results) {
                                            userData_.push(res.results[0])
                                        }
                                    }
                                )
                            ))
                        )
    
                        data_.pembuat = userData_
                    } else {
                        await getUserData(parseInt(data_.pembuat)).then(
                            res => {
    
                                if (res.results) {
                                    data_.pembuat = res.results[0]
                                }
                            }
                        )
                    }
                }
    
                if ("mengetahui" in data_) {
                    if (Array.isArray(data_.mengetahui)) {
                        const userData_ = []
    
                        await Promise.all(
                            data_.mengetahui.map(async(data) => (
                                await getUserDataByUser(parseInt(data)).then(
                                    res => {
    
                                        if (res.results) {
                                            userData_.push(res.results[0])
                                        }
                                    }
                                )
                            ))
                        )

                        const reorderedArray = data_.mengetahui.map((item) => {
                            return userData_.find(element => parseInt(element?.user_id) === parseInt(item))
                        })
                        console.log('mengetahui', reorderedArray)
                        data_.mengetahui = reorderedArray
                    } else {
                        await getUserDataByUser(parseInt(data_.mengetahui)).then(
                            res => {
    
                                if (res.results) {
                                    data_.mengetahui = res.results[0]
                                }
                            }
                        )
                    }
                }

                if ("kartutik_tersangka" in data_) { 
                    if (!isNaN(parseInt(data_.kartutik_tersangka))) {
                        const getSumberSurat = await getSumberSuratData(parseInt(data_?.kartutik_tersangka))
                        if (getSumberSurat.count > 0) {
                            const getSumberSuratField = await reportAPI.getReportType(getSumberSurat?.results[0]?.report_type?.id)
                            data_.kartutik_tersangka = await getFieldName(getSumberSurat.results[0], getSumberSuratField.data.field_report_type)
                        } else {
                            data_.kartutik_tersangka = '-'
                        }
                    }
                }
    
                if ("peminjam" in data_) {
                    if (Array.isArray(data_.peminjam)) {
                        const userData_ = []
    
                        await Promise.all(
                            data_.peminjam.map(async(data) => (
                                await getUserData(parseInt(data)).then(
                                    res => {
    
                                        if (res.results) {
                                            userData_.push(res.results[0])
                                        }
                                    }
                                )
                            ))
                        )
    
                        data_.peminjam = userData_
                    } else {
                        await getUserData(parseInt(data_.peminjam)).then(
                            res => {
                                if (res.results) {
                                    data_.peminjam = res.results[0]
                                }
                            }
                        )
                    }
                }
    
                if ("penanggung_jawab" in data_) {
                    if (Array.isArray(data_.penanggung_jawab)) {
                        const userData_ = []
    
                        await Promise.all(
                            data_.penanggung_jawab.map(async(data) => (
                                await getUserData(parseInt(data)).then(
                                    res => {
    
                                        if (res.results) {
                                            userData_.push(res.results[0])
                                        }
                                    }
                                )
                            ))
                        )
    
                        data_.penanggung_jawab = userData_
                    } else {
                        await getUserData(parseInt(data_.penanggung_jawab)).then(
                            res => {
                                if (res.results) {
                                    data_.penanggung_jawab = res.results[0]
                                }
                            }
                        )
                    }
                }

                if ("pelaksana_operasi" in data_) {
                    if (Array.isArray(data_.pelaksana_operasi)) {
                        const userData_ = []

                        await Promise.all(
                            data_.pelaksana_operasi.map(async(data) => (
                                await getUserDataByUser(parseInt(data)).then(
                                    res => {
    
                                        if (res.results) {
                                            userData_.push(res.results[0])
                                        }
                                    }
                                )
                            ))
                        )

                        const sorting   = []
                        data_.pelaksana_operasi?.map((id) => {
                            const filter = userData_?.filter((dt) => `${dt?.user_id}` === `${id}`)

                            if (filter?.length > 0) {
                                sorting.push(filter[0])
                            }
                        })
    
                        data_.pelaksana_operasi = sorting
                    } else {
                        await getUserDataByUser(parseInt(data_.pelaksana_operasi)).then(
                            res => {
                                if (res.results) {
                                    data_.pelaksana_operasi = [res.results[0]]
                                }
                            }
                        )
                    }
                }
    
                if ("status_dokumen" in data_) {
                    await getStatusData(parseInt(data_.status_dokumen)).then(
                        res => {
                            if (res.results) {
                                data_.status_dokumen = res.results[0]
                            }
                        }
                    )
                }
    
                if ("bidang" in data_) {
                    if (!isNaN(parseInt(data_.bidang))) {
                        await getBidangData(parseInt(data_.bidang)).then(
                            res => {
                                if (res.results) {
                                    data_.bidang = res.results[0]
                                }
                            }
                        )
                    }
                }
    
                if ("sektor" in data_) {
                    if (!isNaN(parseInt(data_.sektor))) {
                        await getSektorData(parseInt(data_.sektor)).then(
                            res => {
                                if (res.results) {
                                    data_.sektor = res.results[0]
                                }
                            }
                        )
                    }
                }
    
                if ("sumber_surat" in data_) {
                    if (!isNaN(parseInt(data_.sumber_surat))) {
                        if (Array.isArray(data_?.sumber_surat)) {
                            const arr_ = await Promise.all(data_.sumber_surat.map(async (item) => {
                                const getSumberSurat = await getSumberSuratData(parseInt(item))
                                if (getSumberSurat.count > 0) {
                                    const getSumberSuratField = await reportAPI.getReportType(getSumberSurat?.results[0]?.report_type?.id)
                                    const formated          = getFieldName(getSumberSurat.results[0], getSumberSuratField.data.field_report_type)
                                    formated.report_type    = getSumberSurat?.results[0]?.report_type

                                    return formated
                                } else {
                                    return {}
                                }
                            }))
                            data_.sumber_surat = arr_
                        } else {
                            const getSumberSurat = await getSumberSuratData(parseInt(data_?.sumber_surat))
                            if (getSumberSurat.count > 0) {
                                const getSumberSuratField = await reportAPI.getReportType(getSumberSurat?.results[0]?.report_type?.id)
                                data_.sumber_surat = await getFieldName(getSumberSurat.results[0], getSumberSuratField.data.field_report_type)

                                // const temp = await getFieldName(getSumberSurat.results[0], getSumberSuratField.data.field_report_type)
                                // data.sumber_surat = {
                                //     ...temp,
                                //     report_type : getSumberSuratField.data.field_report_type
                                // }
                            } else {
                                data_.sumber_surat = '-'
                            }
                        }
                    }
                }

                if ("identitas_lengkap" in data_) {
                    if (!isNaN(parseInt(data_.identitas_lengkap))) {
                        const getSumberSurat = await getSumberSuratData(parseInt(data_?.identitas_lengkap))
                        if (getSumberSurat.count > 0) {
                            const getSumberSuratField = await reportAPI.getReportType(getSumberSurat?.results[0]?.report_type?.id)
                            data_.identitas_lengkap = await getFieldName(getSumberSurat.results[0], getSumberSuratField.data.field_report_type)
                        } else {
                            data_.identitas_lengkap = '-'
                        }
                    }
                }

                if ("id_perpanjangan" in data_) { 
                    if (!isNaN(parseInt(data_.id_perpanjangan))) {
                        const getSumberSurat      = await getSumberSuratData(parseInt(data_?.id_perpanjangan))
                        if (getSumberSurat.count > 0) {
                            const getSumberSuratField = await reportAPI.getReportType(getSumberSurat?.results[0]?.report_type?.id)
                            const resultSumberSurat   = await getFieldName(getSumberSurat.results[0], getSumberSuratField.data.field_report_type)
                            data_.id_perpanjangan = resultSumberSurat
                        } else {
                            data_.id_perpanjangan = '-'
                        }
                    }
                }
    
                if ("kartutik_barang_cetakan" in data_) { 
                    if (!isNaN(parseInt(data_.kartutik_barang_cetakan))) {
                        await getSumberSuratData(parseInt(data_.kartutik_barang_cetakan)).then(
                            res => {
                                if (res.results) {
                                    data_.kartutik_barang_cetakan = res.results[0]
                                }
                            }
                        )
                    }
                }
    
                if ("sd_permintaan_keterangan" in data_) { 
                    await getSumberSuratData(parseInt(data_.sd_permintaan_keterangan))
                    .then(
                        async res => {
                            if (res.results) {
                                const getSumberSuratField = await reportAPI.getReportType(res.results[0]?.report_type?.id)
                                const resultSumberSurat   = await getFieldName(res.results[0], getSumberSuratField.data.field_report_type)
                                data_.sd_permintaan_keterangan = resultSumberSurat
                            }
                        }
                    )
                }
    
                if ("kartutik_biodata" in data_) { 
                    await getSumberSuratData(parseInt(data_.kartutik_biodata))
                    .then(
                        async res => {
                            
                            if (res.results) {
                                data_.kartutik_biodata = await getChildData(res.results[0], 86)
                            }
                        }
                    )
                }

                if ("kartutik_identitas" in data_) { 
                    if (!isNaN(parseInt(data?.kartutik_identitas))) {
                        await getSumberSuratData(parseInt(data_.kartutik_identitas))
                            .then(
                                async res => {
                                    
                                    if (res.results) {
                                        data_.kartutik_identitas = await getChildData(res.results[0], 86)
                                    }
                                }
                            )
                    }
                }

                if ("sp-operasi-intelijen" in data_) { 
                    await getSumberSuratData(parseInt(data_.sp_operasi_intelijen))
                    .then(
                        res => {
                            if (res.results) {
                                data_.sp_operasi_intelijen = res.results[0]
                            }
                        }
                    )
                }
                
                if ("foto" in data_) { 
                    await getFoto(data_.foto)
                    .then(
                        res => {
                            if (res.results) {
                                data_.foto = res.results[0]
                            }
                        }
                    )
                }

                if ("unggah_foto" in data_) { 
                    await getFoto(data_.unggah_foto)
                    .then(
                        res => {
                            if (res.results) {
                                data_.unggah_foto = res.results[0]
                            }
                        }
                    )
                }

                if ("sumber_kartutik" in data_) { 
                    if (Array.isArray(data_.sumber_kartutik)) {
                        const userData_ = []

                        data_.sumber_kartutik.map(async(data) => (
                            await getSumberSuratData(parseInt(data)).then(
                                async res => {
                                    if (res.results) {
                                        const getSumberSuratField = await reportAPI.getReportType(res.results[0]?.report_type?.id)
                                        const resultSumberSurat   = await getFieldName(res.results[0], getSumberSuratField.data.field_report_type)
                                        userData_.push(resultSumberSurat)
                                    }
                                }
                            )
                        ))

                        data_.sumber_kartutik = userData_
                    } else {
                        await getSumberSuratData(parseInt(data_.sumber_kartutik)).then(
                            async res => {
                                if (res.results) {
                                    const getSumberSuratField = await reportAPI.getReportType(res.results[0]?.report_type?.id)
                                    const resultSumberSurat   = await getFieldName(res.results[0], getSumberSuratField.data.field_report_type)
                                    data_.sumber_kartutik = [resultSumberSurat]
                                }
                            }
                        )
                    }
                }
            }

            if (
                detailData?.report_type?.id !== 93 &&
                detailData?.report_type?.id !== 94 &&
                detailData?.report_type?.id !== 95 &&
                detailData?.report_type?.id !== 96

            ) {
                const extraData      = await getReportExtraData(parseInt(data_.report_id))
                data_.extra_data     = extraData?.results
            }

            setDataSelected(data_)
        } catch (error) {
            console.error(error, 'error')            
        }
    }

    const getDetailDataMap = async(detailData, reportData) => {

        const [getReport] = await Promise.all([
            reportAPI.getReport({
                id : detailData.report_id,
                "d.in" : reportData.din
            }),
        ])

        if ("bidang" in detailData) {
            if (!isNaN(parseInt(detailData.bidang))) {
                await getBidangData(parseInt(detailData.bidang)).then(
                    res => {
                        if (res.results) {
                            detailData.bidang = res.results[0]
                        }
                    }
                )
            }
        }

        const results = []

        getReport?.data?.results?.map((dt, idx) => {
            if (dt?.latitude && dt?.longitude) {
                const lat = parseFloat(dt?.latitude)
                const long = parseFloat(dt?.longitude)
                results.push({
                    sector_id       : dt?.sektor?.id,
                    count           : 1,
                    name            : dt?.sektor?.name,
                    icon            : dt?.sektor?.icon,
                    coordinate      : [ListCoordinate[idx]],
                    coordinate      : [[lat, long]]
                })
            }
        })

        console.log("detailData", detailData)

        console.log("detailData", results)

        const data    = {
            data    : detailData,
            header  : {
                name  : detailData?.bidang?.name,
				count : results?.length,
            },
            detail  : results
        }

        return data
    }

    const getReportCountView = (id) => {
        const params = {
            report_type_id      : id,
            not_seen_yet        : true
        }

        return reportAPI.getReportCountView(params)
    }

    const createReportCountView = (id, reportTypeId, headerReport) => {
        const formData = {
            report      : id,
        }

        reportAPI.createReportCountView(formData)
            .then(async (res) => {
                console.log("createReportCountView", reportTypeId)

                getData(reportTypeId, headerReport)

                if (
                    reportTypeId === 1 ||
                    reportTypeId === 2 ||
                    reportTypeId === 3 ||
                    reportTypeId === 21
                ) {
                    const count = await getReportCountView(reportTypeId)
    
                    const temp = viewerCount
                    viewerCount[reportTypeId] = count?.data?.count
                    setViewerCount({...temp})
                }

            })
    }

    const convertDataResultLapbul = async (result, field_report_type, index) => {
        let data_                           = {}
        data_                               = await getFieldName(result, field_report_type)
        data_.is_draft                      = result?.is_draft
        data_.index                         = index + 1
        data_.created_by                    = result?.created_by
        data_.track_status                  = result?.report_approvement_report
        data_.expired_date                  = await getExpiredStatusAprrovement(result?.report_approvement_report, result?.report_type_id)
        data_.last_track_status             = await getLastStatusApprovement(result?.report_approvement_report)

        const getUserData_                  = await getUserDataByUser(parseInt(result?.created_by))
        data_.created_by                    = getUserData_.results[0]
        data_.satuan_kerja                  = getUserData_?.results[0]?.work_unit?.name
        data_.penyelenggara                 = data_.created_by?.code?.name
        data_.sub_penyelenggara             = data_.created_by?.code_subdir?.name
        data_.sub_sub_penyelenggara         = data_.created_by?.code_seksi?.name
        data_.sub_sub_sub_penyelenggara     = data_.created_by?.code_sector?.name

        if (typeof data_ === 'object') {
            if ("memerintahkan" in data_) {
                if (Array.isArray(data_.memerintahkan)) {
                    const userData_ = []

                    await Promise.all(
                        data_.memerintahkan.map(async (data, index) => (
                            await getUserDataByUser(parseInt(data)).then(
                                res => {
                                    if (res.results) {
                                        userData_[index] = res.results[0]
                                    }
                                }
                            )
                        ))
                    )

                    data_.memerintahkan = userData_
                } else {

                    await getUserDataByUser(parseInt(data_.memerintahkan)).then(
                        res => {

                            if (res.results) {
                                data_.memerintahkan = res.results[0]
                            }
                        }
                    )
                }
            }

            if ("penandatangan" in data_) {
                await getUserDataByUser(parseInt(data_.penandatangan)).then(
                    res => {
                        if (res.results) {
                            data_.penandatangan = res.results[0]
                        }
                    }
                )
            }

            if ("petugas_wawancara" in data_) {
                if (Array.isArray(data_.petugas_wawancara)) {
                    const userData_ = []

                    data_.petugas_wawancara.map((data) => (
                        getUserData(parseInt(data)).then(
                            res => {

                                if (res.results) {
                                    userData_.push(res.results[0])
                                }
                            }
                        )
                    ))

                    data_.petugas_wawancara = userData_
                } else {
                    getUserData(parseInt(data_.petugas_wawancara)).then(
                        res => {

                            if (res.results) {
                                data_.petugas_wawancara = res.results[0]
                            }
                        }
                    )
                }
            }

            if ("pelaksana" in data_) {
                if (Array.isArray(data_.pelaksana)) {
                    const userData_ = []

                    await Promise.all(
                        data_.pelaksana.map(async (data) => (
                            await getUserDataByUser(parseInt(data)).then(
                                res => {

                                    if (res.results) {
                                        userData_.push(res.results[0])
                                    }
                                }
                            )
                        ))
                    )

                    const sorting   = []
                    data_.pelaksana?.map((id) => {
                        const filter = userData_?.filter((dt) => `${dt.user_id}` === `${id}`)

                        if (filter?.length > 0) {
                            sorting.push(filter[0])
                        }
                    })
    
                    data_.pelaksana = sorting
                } else {
                    await getUserDataByUser(parseInt(data_.pelaksana)).then(
                        res => {

                            if (res.results) {
                                data_.pelaksana = res.results[0]
                            }
                        }
                    )
                }
            }

            if ("pelaksana_operasi" in data_) {
                if (Array.isArray(data_.pelaksana_operasi)) {
                    const userData_ = []

                    await Promise.all(
                        data_.pelaksana_operasi.map(async (data) => (
                            await getUserDataByUser(parseInt(data)).then(
                                res => {

                                    if (res.results) {
                                        userData_.push(res.results[0])
                                    }
                                }
                            )
                        ))
                    )

                    data_.pelaksana_operasi = userData_
                } else {
                    await getUserDataByUser(parseInt(data_.pelaksana_operasi)).then(
                        res => {
                            if (res.results) {
                                data_.pelaksana_operasi = [res.results[0]]
                            }
                        }
                    )
                }
            }

            if ("bidang" in data_) {
                if (!isNaN(parseInt(data_.bidang))) {
                    await getBidangData(parseInt(data_.bidang)).then(
                        res => {
                            if (res.results) {
                                data_.bidang = res.results[0]
                            }
                        }
                    )
                }
            }

            if ("sektor" in data_) {
                if (!isNaN(parseInt(data_.sektor))) {
                    await getSektorData(parseInt(data_.sektor)).then(
                        res => {
                            if (res.results) {
                                data_.sektor = res.results[0]
                            }
                        }
                    )
                }
            }
        }

        return data_
    }

    const getDetailDataLapbul = async (detailData, reportData) => {
        setLapbulSelected(detailData)

        const newFormatData_ = []

        const payload = {
            lapbul_id   : detailData?.report_id,
        }
        const getReport     = await reportAPI.getReport(payload)
        const getReportType = await reportAPI.getReportType(getReport?.results[0]?.report_type_id)

        if (reportData?.report === "LIN.10" || reportData?.report === "LIN.12") {
            if (getReport?.results !== null && getReport?.results?.length > 0) {
                if (getReport?.results[0]?.results !== null && getReport?.results[0]?.results?.length > 0) {
    
                    const getReportType = await reportAPI.getReportType(getReport?.results[0]?.results[0]?.report_type_id)
    
                    await Promise.all(
                        await getReport?.results[0]?.results?.map(async (result, index) => {
    
                            let data_    = {}
                            data_        = await convertDataResultLapbul(result, getReportType?.data?.field_report_type, index)
    
                            if (result?.source) {
                                const tempChild1 = []
                                const tempChild2 = []
                                const tempChild3 = []

                                await Promise.all(
                                    result?.source?.map(async (dtLvl1) => {
                                        const getReportTypeChild1   = await reportAPI.getReportType(dtLvl1?.report_type_id)
                                        const child1                = await convertDataResultLapbul(dtLvl1, getReportTypeChild1?.data?.field_report_type, 0)
                                        setReportTypeFieldLapbul(getReportTypeChild1?.data?.field_report_type)
                                        tempChild1.push({
                                            report  : getReportTypeChild1?.data?.name,
                                            data_values : dtLvl1,
                                            data    : child1
                                        })
                                        
                                        data_.child1                = tempChild1
            
                                        if (dtLvl1?.source) {
                                            await Promise.all(
                                                dtLvl1?.source?.map(async (dtLvl2) => {
                                                    const getReportTypeChild2   = await reportAPI.getReportType(dtLvl2?.report_type_id)
                                                    const child2                = await convertDataResultLapbul(dtLvl2, getReportTypeChild2?.data?.field_report_type, 0)
                                                    tempChild2.push({
                                                        report  : getReportTypeChild2?.data?.name,
                                                        data    : child2
                                                    })
        
                                                    data_.child2                = tempChild2
        
                                                    if (dtLvl2?.source) {
                                                        await Promise.all(
                                                            dtLvl2?.source?.map(async (dtLvl3) => {
                                                                const getReportTypeChild3   = await reportAPI.getReportType(dtLvl3?.report_type_id)
                                                                const child3                = await convertDataResultLapbul(dtLvl3, getReportTypeChild3?.data?.field_report_type, 0)
                                                                tempChild3.push({
                                                                    report  : getReportTypeChild3?.data?.name,
                                                                    data    : child3
                                                                })
            
                                                                data_.child3                = tempChild3
            
                                                            })
                                                        )
                                                    }
                                                })
                                            )
                                        }
                                    })
                                )
                            }
                            newFormatData_.push(data_)
                        })
                    )

                    console.log("newFormatData_", newFormatData_)
                    
                    setLoadingLapbul(false)
                } else {
                    setLoadingLapbul(false)
                }
            }
        } else {
            if (getReport?.results !== null && getReport?.results?.length > 0) {

                let data_    = {}
                data_        = await convertDataResultLapbul(getReport?.results[0], getReportType?.data?.field_report_type, 0)
                setLapbulSelected(data_)

                if (getReport?.results[0]?.results !== null && getReport?.results[0]?.results?.length > 0) {
                    getReport.results[0].results?.map((dt) => {
                        dt.bidang = data_?.bidang
                        newFormatData_.push(dt)
                    })
                }
                setLoadingLapbul(false)
            } else {
                setLoadingLapbul(false)
            }
        }

        setDataSelected(newFormatData_)
    }
    
    const onDelete = (reportId, otherAction) => {
        setLoadingDelete(true)

        // delete multiple id
        reportId.map((data) => (
            reportAPI.deleteReport(data.report_id).then(
                () => {
                    setToggleClearRows(true)
                    toast.success('Laporan Berhasil Dihapus!')
                    otherAction()
                    setToggleClearRows(false)
                    setLoadingDelete(false)
                }
            )
        ))
    }

    const handleCountCopies = (reportId) => {

        const params = {
			kind	  : "pdf",
			report_id : reportId
		}

		reportAPI.getReportCounting(params).then(
			res => {
				const count_ = {...countCopies}

                count_.pdf = res?.count
                return count_
			}
		).then (
            res => {

                const paramsWord = {
                    kind	  : "word",
                    report_id : reportId
                }

                reportAPI.getReportCounting(paramsWord).then(
                    (result) => {
                        const count_ = res

                        count_.word = result.count
                        setCountCopies(count_)
                    }
                )
            }
        )
    }

    const handleUpdateCountCopies = (reportId, type) => {

        const formData = {
            kind   : type,
            report : reportId
        }
        
        reportAPI.createReportCounting(formData).then(
            () => {

                handleCountCopies(reportId, type)

                toast.success('Total Download File Berhasil Direkap.')
            }
        )
    }

    const setUpModalCompleteTask = async(data) => {
        await getDetailData(data)
        setShowModalCompleteTask(true)
    }
    
    const setUpModalDetail = async(data) => {        
        handleCountCopies(data?.report_id)
        
        await getDetailData(data)
        setShowModalDetail(true)
    }

    const setUpModalDetailLapbul = async(data, reportData) => {      
        setLoadingLapbul(true)
        setShowModalDetail(true)
        await getDetailDataLapbul(data, reportData)
    }

    const setUpModalStatusAndInstruction = async(data) => {        
        await getDetailData(data)
        setShowModalStatus(true)
    }

    const setUpModalTrackStatus = async(data) => {
        await getDetailData(data)
        setShowModalTrackStatus(true)
    }

    const setUpModalEdit = async(data) => {
        console.log(data)
        await getDetailData(data)
        setShowForm(true)
    }

    const setUpModalPreview = async(data, showPreviewMap, reportData) => {
        await handleCountCopies(data?.report_id)
        
        if (showPreviewMap) {
            const dataRes = await getDetailDataMap(data, reportData)
            setDetailDinData(dataRes)
            setShowModalPreviewMap(true)
        } else {

            await getDetailData(data)
            setShowModalPreview(true)
        }
    }

    const setUpModalApprovement = async(data) => {
        await getDetailData(data)
        setOriginalData(data)
        setShowModalApprovement(true)
    }

    const setUpModalRequestPerpanjangan = async(data) => {
        await getDetailData(data)
        setOriginalData(data)
        setShowModalReqPerpanjangan(true)
    }

    const setUpModalAcceptPerpanjangan = async(data) => {
        await getDetailData(data)
        setOriginalData(data)
        setShowModalAccPerpanjangan(true)
    }

    const setUpModalBio = async(data) => {
        await getDetailData(data)
        setShowModalBio(true)
    }

    const setUpModalReview = async (data, report_type_id) => {
        setDataReview({
            data            : data,
            report_type_id  : report_type_id
        })
        setShowModalReview(true)
    }

    const setUpModalInstruction = async (data, report_type_id) => {
        setDataInstruction({
            data            : data,
            report_type_id  : report_type_id
        })
        setShowModalInstruction(true)
    }

    const setUpModalAcceptTask = async(data) => {
        await getDetailData(data)
        setShowModalAcceptTask(true)
    }

    const setUpModalEditHistory = async(data) => {
        await getDetailData(data)
        setShowModalEditHistory(true)
    }

    const onPressPrint = () => {
        setTypePrint("preview")
        setPrint(true)
    }

    const onPressDocx = () => {
        setTypePrint("docx")
        setPrint(true)
    }

    const uploadAttachment = async (files, report_id) => {

        const formData_ = new FormData()
        formData_.append("file", files[0])
        formData_.append("service", "report")

        const uploadFileAttachment = await serviceAPI.uploadFileService(formData_)
        
        if (uploadFileAttachment) {
            toast.success("File berhasil ditambahkan.")
        }

        const params = {
            name        : uploadFileAttachment?.data?.name,
            report_id   : report_id,
            files_urls  : uploadFileAttachment?.data?.file,
            description : ""
        }

        await reportAPI.uploadAttachment(params)
    }

    const createReport = (formData, otherAction, files) => {
        reportAPI.createReport(formData).then(
            (res) => {
                toast.success('Laporan Berhasil Dibuat!')
                otherAction(res)

                if (files) {
                    uploadAttachment(files, res?.id)
                }
            }
        )
    }

    const updateReport = (reportId, formData, otherAction, files) => {
        reportAPI.updateReport(reportId, formData).then(
            (res) => {
                toast.success('Laporan Berhasil Diubah!')
                otherAction()

                if (files) {
                    uploadAttachment(files, res?.id)
                }
            }
        ).catch(
            (err) => {
                console.error(err, 'err')
            }
        )
    }

    const handleUpdateLetterNumberValue = (fieldId, fieldValue) => {

        const formData = {
            value : fieldValue
        }

        reportAPI.updateValue(fieldId, formData).then(
            (res) => {
                toast.success('Nomor surat berhasil dibuat!')
            }
        ).catch(
            (err) => {
                console.error(err, 'err')
            }
        )
    }

    const handleApprovement = async(status, messages) => {

        try {
            const formData = {
                report             : dataSelected?.report_id,
                messages           : messages,
                status_approvement : status
            }
    
            await reportAPI.createStatus(formData)
            
            if (status === 2) {
                toast.success('Surat berhasil disetujui.')

                const newFieldData_    = getReportField(originalData, reportTypeField)
                
                const getReport        = originalMasterData.find(item => item.id === dataSelected?.report_id)
                const getFieldId       = reportTypeField.find(item => item.name === 'nomor-surat')
                
                const getFieldValue    = newFieldData_?.find(item => item.field === getFieldId.id)
                const getReportValueId = getReport?.report_values?.find(item => item.field === getFieldId.id)

                await handleUpdateLetterNumberValue(getReportValueId?.id, getFieldValue?.value)
            } else {
                toast.success('Terkirim.')
            }
    
            setDataSelected(null)
            setShowModalApprovement(false)
            
        } catch (error) {
            console.error(error, 'handle approvement erro')
        }

        
    }

    const handleAcceptTask = (reportData, reportId, statusId, getData, toReportData) => { 
        const formData = {}
        
        if (reportId) {
            formData.report = reportId
            
        } else {
            formData.report = dataSelected?.report_id
        }

        //get status approvement berdasarkan report
        let status 

        if (statusId) {
            status = statusId
        } else {
            status = acceptTaskRules(reportData, toReportData)
        }
        
        formData.status_approvement = status

        reportAPI.createStatus(formData).then(
            () => {

                if (getData) {
                    getData()
                }
                
                setDataSelected(null)
                setShowModalAcceptTask(false)
            }
        )
    }

    const getReportCategory = () => {
        reportAPI.getReportCategory()
        .then((res) => {
            setDataCategory(res.results)
        })
        .catch((err) => {
            toast.error('Gagal Mendapakatka Kategori')
        })
    }

    useEffect(() => {
        console.log("PARAMSAPPREPORT", params)
    }, [params])

    useEffect(async () => {
        if (localStorage.getItem('userData')) {
            const [in1, in2, in3, in21] = await Promise.all([
                getReportCountView(1),
                getReportCountView(2),
                getReportCountView(3),
                getReportCountView(21)
            ])
    
            setViewerCount({
                1 : in1?.data?.count,
                2 : in2?.data?.count,
                3 : in3?.data?.count,
                21 : in21?.data?.count,
            })
        }
    }, [])

    return (
        <ReportContext.Provider
            value = {{
                data,
                setData,

                report,
                setReport,

                dashboardReport, 
                setDashboardReport,

                paramsReport,
                setParamsReport,

                loadingReport,
                setLoadingReport,

                reportPaginate, 
                setReportPaginate,

                loading,
                setLoading,

                paginate,
                setPaginate,

                selected,
                setSelected,

                ListCoordinate,
                detailDinData, 
                setDetailDinData,

                totalData,
                setTotalData,

                showForm,
                setShowForm,

                showModalFilterColumn,
                setShowModalFilterColumn,

                showModalCompleteTask, 
                setShowModalCompleteTask,

                showModalDetail,
                setShowModalDetail,

                dataCategory, 
                setDataCategory,

                hideColumn,
                setHideColumn,

                showModalFilter,
                setShowModalFilter,

                showFormDelete,
                setShowFormDelete,

                loadingDelete,
                setLoadingDelete,

                dataSelected,
                setDataSelected,

                originalData,
                setOriginalData,

                typePrint, 
                setTypePrint,

                showPrint,
                setPrint,

                toggledClearRows,
                setToggleClearRows,

                params,
                setParams,

                fetchStatus,
                setFetchStatus,

                fetchData,
                setFetchData,

                showModalPreview,
                setShowModalPreview,

                showModalPreviewMap,
                setShowModalPreviewMap,

                showModalFilterOrder,
                setShowModalFilterOrder,

                showModalApprovement,
                setShowModalApprovement,

                showModalReqPerpanjangan, 
                setShowModalReqPerpanjangan,

                showModalAccPerpanjangan, 
                setShowModalAccPerpanjangan,

                showModalBio,
                setShowModalBio,

                showModalAcceptTask,
                setShowModalAcceptTask,

                showModalTrackStatus,
                setShowModalTrackStatus,

                showModalEditHistory,
                setShowModalEditHistory,

                showModalReview, 
                setShowModalReview,

                showModalInstruction, 
                setShowModalInstruction,

                showModalInstructionData, 
                setShowModalInstructionData,

                showModalStatus,
                setShowModalStatus,

                reportTypeField,
                setReportTypeField,

                countCopies,
                setCountCopies,

                setDataSelected,
                dataSelected,

                dataReview, 
                setDataReview,

                dataInstruction, 
                setDataInstruction,

                lapbulSelected,
                loadingLapbul,

                paramsDate,
                setParamsDate,

                currentPage, 
                setCurrentPage,

                viewerCount,
                setViewerCount,
                
                reportTypeFieldLapbul,

                //function
                getData,
                getData_,
                getDataById,
                onDelete,
                onPressDocx,
                onPressPrint,
                setUpModalBio,
                setUpModalEdit,
                setUpModalDetail,
                setUpModalDetailLapbul,
                setUpModalStatusAndInstruction,
                getReportCategory,
                getLastStatusApprovement,
                getUserDataByUser,
                getAccessFeatures,
                setUpModalPreview,
                setUpModalAcceptTask,
                getDetailSumberSurat,
                setUpModalApprovement,
                setUpModalRequestPerpanjangan,
                setUpModalAcceptPerpanjangan,
                setUpModalEditHistory,
                setUpModalTrackStatus,
                setUpModalCompleteTask,
                setUpModalReview,
                setUpModalInstruction,
                getReportCountView,
                createReportCountView,
               

                createReport,
                updateReport,
                handleAcceptTask,
                handleApprovement,
                handleCountCopies,
                handleUpdateCountCopies,
                getChildData,
                getDetailData,
            }}
        >
            {props.children}
        </ReportContext.Provider>
    )
}

export { ReportProvider, ReportContext }