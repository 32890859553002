import axios from "axios"
import toast from "react-hot-toast"
// import toast from "react-hot-toast"

export const url_siacc  = !process.env.NODE_ENV || process.env.NODE_ENV === 'production' ? window._env_.REACT_APP_SIACC_URL : process.env.REACT_APP_SIACC_URL

// 'https://edm-siacc.underdev.team'

const axiosInstance = axios.create({
    baseURL: url_siacc,
    headers: {
        "Accept"                      : "*/*",
        "Cache-Control"               : "no-cache",
        "Pragma"                      : "no-cache",
        "Expires"                     : "0",
        "Access-Control-Allow-Origin" : ".sysdev.id, .underdev.team, .mapbox.com, localhost:9091"
    }
})

axiosInstance.interceptors.request.use(
    req => {

        req.headers.Authorization = `Bearer ${process.env.REACT_APP_TOKEN_SIACC}`

        if (req.data instanceof FormData) {
            req.headers.ContentType = "multipart/form-data"
            
        } else {
            req.headers.ContentType = "application/json"
            req.headers.timeout = 50000
        }

        if (req.url === 'drive/download') {
            req.responseType = 'blob'
        }

        return req
    })

axiosInstance.interceptors.response.use(
    (response) => new Promise((resolve) => {
        resolve(response.data)
    }),
    (error) => {
        //for http status other than 200 - 299

        const res_ = {
            status: error?.response?.status, 
            result: error?.response?.data
        }

        if (res_.status === 401) {
            if (res_.result.code === 'token_not_valid') {
                toast.error('Sesi telah berakhir.')
                setTimeout(() => {
                    localStorage.clear()
                    window.location.href = '/login'
                }, 3000)
            }
        } else if (res_.status === 500) {
            toast.error('Terjadi kesalahan saat mengambil data.')
            
        } else {
            return new Promise((resolve, reject) => {
                reject(res_)
            })
        }
    }
)

export default axiosInstance
