import { ChevronLeft, ChevronRight, ChevronsLeft, ChevronsRight } from "react-feather"
import AgentReportCard          from "../../../@core/components/agent-report/AgentReportCard"
import { Col, Row } from "reactstrap"
import Skeleton from "react-loading-skeleton"
import CustomTableEmptyData from "../../../@core/components/custom-table/empty"

const AgentReport = ({
    data,
    getData,
    loading,
    pagination,
    keyword,
    setPagination,
}) => {

    return (
        <>
            <div>
                <div className="d-flex justify-content-between ">
                      <div
                        className="h4 my-1"
                    >
                        Berita
                    </div>
                    <div 
                        style       = {{ right : 0, bottom : 0 }}
                        className   = ' d-flex justify-content-end my-2' 
                    >
                        
                        {
                            pagination?.has_previous ? (
                                <>
                                    
                                    <div 
                                        onClick     = {() => getData({ keyword : keyword, page:pagination?.current_page - 1 })}
                                        className   = 'cursor-pointer'
                                    >
                                        <ChevronLeft  />
                                    </div>
                                </>
                            ) : (
                                <>
                                    
                                    <div className=' text-muted'>
                                        <ChevronLeft  />
                                    </div>
                                </>
                            )
                        }
                        <div className='mx-2'>
                            <p>
                                {(pagination?.current_page * pagination?.page_size) - 9} - {' '} 
                                {pagination?.page_size * pagination?.current_page } dari {' '}
                                {pagination?.data_total}
                            </p>
                        </div>
                        {
                            pagination?.has_next ? (
                                <>
                                    <div 
                                        onClick     = {() => getData({ keyword : keyword, page:pagination?.current_page + 1 })}
                                        className   = 'cursor-pointer'
                                    >
                                        <ChevronRight />
                                    </div>
                                   
                                </>
                            ) : (
                                <>
                                    <div className='text-muted'>
                                        <ChevronRight />
                                    </div>
                                   
                                </>
                            ) 
                        }
                    </div>
                </div>
                <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-1">
                    {
                        data !== null && !loading && data?.length > 0 &&
                            data?.map((item) => {
                                return (
                                    <AgentReportCard 
                                        data = {item}
                                    />
                                )
                            })
                    }
                  {
                    loading && (
                        <>
                            <Col lg={4}>
                                <Skeleton  height={'300px'}/>
                            </Col>
                            <Col lg={4}>
                                <Skeleton height={'300px'}/>
                            </Col>
                            <Col lg={4}>
                                <Skeleton height={'300px'}/>
                            </Col>
                            <Col lg={4}>
                                <Skeleton height={'300px'}/>
                            </Col>
                            <Col lg={4}>
                                <Skeleton height={'300px'}/>
                            </Col>
                            <Col lg={4}>
                                <Skeleton height={'300px'}/>
                            </Col>
                        </>
                    )
                  }

                  {
                    !loading && Array.isArray(data) && data.length === 0 && (
                        <CustomTableEmptyData />
                    )
                  }
                </div>
            </div>
        </>
    )
}

export default AgentReport