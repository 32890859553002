import { 
	Fragment, 
	useContext, 
	useEffect, 
	useState 
} from "react"

import {
	Input,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
} from "reactstrap"

import { ReportContext }			 	from "../../../context/reportContext"
import { getReportField } 				from "../../../utility/report-type-id"
import SubmitButton 					from "../../../@core/components/submit-button"
import moment 							from "moment"

const ModalInstruction = (props) => {

	const {
		dataInstruction, 
        setDataInstruction,
		showModalInstruction, 
		setShowModalInstruction,
		reportTypeField,

		updateReport
	} = useContext(ReportContext)

	const [loading, setLoading] 					= useState(false)
	const [keterangan, setKeterangan]				= useState("")

	useEffect(() => {
		if (!showModalInstruction) {
			setShowModalInstruction(null)
		}
	}, [showModalInstruction])

	useEffect(() => {
		if (dataInstruction) {
			if (dataInstruction?.data?.petunjuk_pimpinan && dataInstruction?.data?.petunjuk_pimpinan !== "__blank") {
				setKeterangan(dataInstruction?.data?.petunjuk_pimpinan)
			} else {
				setKeterangan("")
			}
		} else {
			setKeterangan("")
		}
	}, [dataInstruction])

	const actionSubmitReview = (reportId, formData) => {

		const newFieldData_ = getReportField(formData, reportTypeField)

		const finalFormData = {
			report_type_id 		: dataInstruction?.report_type_id,
			report_values 		: newFieldData_,
			report_attachments 	: []
		}

		if (formData?.submit_type === "draft") {
            finalFormData.is_draft = true
        } else {
            finalFormData.is_draft = false
        }

		const otherAction = async() => {
			setShowModalInstruction(false)
			setDataInstruction(null)
		}

		updateReport(reportId, finalFormData, otherAction)
	}

	const handleSubmit = () => {
		const formData = dataInstruction?.data
		formData.petunjuk_pimpinan 			= keterangan
		formData.tanggal_petunjuk_pimpinan 	= moment(new Date()).unix()
		actionSubmitReview(dataInstruction?.data?.report_id, formData)
	}

	return (
		<Fragment>
			<Modal
				size		= {"md"}
				isOpen		= {showModalInstruction}
				centered	= {true}
				onClosed	= {() => { setDataInstruction(null) }}
				scrollable	= {true}
			>
				<ModalHeader toggle={() => {
					setShowModalInstruction(false)
					setDataInstruction(null)
				}}>Beri Petunjuk Pimpinan</ModalHeader>

				<ModalBody className="">
					<Input
						type		= "textarea"
						placeholder	= "Beri Petunjuk Pimpinan.."
						value 		= {keterangan}
						onChange	= {(e) => {
							setKeterangan(e.target.value)
						}}
					/>
				</ModalBody>

				<ModalFooter className="d-flex justify-content-end">
					<SubmitButton
                        title       = {"Simpan"}
                        loading     = {loading} 
                        onClick     = {() => {
							handleSubmit()
						}}
                    />
				</ModalFooter>
			</Modal>
		</Fragment>
	)
}

export default ModalInstruction
