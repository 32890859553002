import { 
    useState,
    createContext,
    useEffect
}                       from "react"

//Context
const ProfileContext  = createContext(null)

//API
import reportAPI      from "../service/report/index"

const ProfileProvider = ({ children }) => {

    //State
    const [reportTypeOption, setReportTypeOption]   = useState([])
    const [reportList, setReportList]               = useState([])

    const getReportTypeOption = () => {
        
        reportAPI.getReportTypeAll({
            page_size : 100
        }).then(
            res => {

                const tempIN    = []
                const tempLIN   = []
                const tempRIN   = []
                const tempDIN   = []

                res.results.map((dt) => {
                    if (dt.type === "IN") {
                        tempIN.push({
                            label           : `${dt?.name} ${dt?.description}`,
                            value           : dt?.id,
                            report          : dt?.name,
                            reportType      : dt?.type,
                            reportTitle     : dt?.description,
                            reportTypeId    : dt?.id
                        })
                    } else if (dt.type === "L.IN") {
                        tempLIN.push({
                            label           : `${dt?.name} ${dt?.description}`,
                            value           : dt?.id,
                            report          : dt?.name,
                            reportType      : dt?.type,
                            reportTitle     : dt?.description,
                            reportTypeId    : dt?.id
                        })
                    } else if (dt.type === "R.IN") {
                        tempRIN.push({
                            label           : `${dt?.name} ${dt?.description}`,
                            value           : dt?.id,
                            report          : dt?.name,
                            reportType      : dt?.type,
                            reportTitle     : dt?.description,
                            reportTypeId    : dt?.id
                        })
                    } else if (dt.type === "D.IN") {
                        tempDIN.push({
                            label           : `${dt?.name} ${dt?.description}`,
                            value           : dt?.id,
                            report          : dt?.name,
                            reportType      : dt?.type,
                            reportTitle     : dt?.description,
                            reportTypeId    : dt?.id
                        })
                    }
                })

                setReportList(res?.results)

                setReportTypeOption({
                    IN      : tempIN,
                    LIN     : tempLIN,
                    RIN     : tempRIN,
                    DIN     : tempDIN
                })
            }
        ).catch(
            err => {
                console.log(err, 'err')
            }
        )
    }

    useEffect(() => {
        if (localStorage.getItem('userData')) {
            getReportTypeOption()
        }
    }, [])

    return <ProfileContext.Provider
        value={{
            reportTypeOption,
            reportList
        }}
    > 
        {children}
    </ProfileContext.Provider>
}

export { ProfileContext, ProfileProvider }