import { 
    useContext, 
    useEffect, 
    useState 
}                               from 'react'
import { 
    ChevronLeft, 
    ChevronRight, 
    Search 
}                               from 'react-feather'
import Flatpickr 			    from "react-flatpickr"

import { 
    Button, 
    Card, 
    CardBody, 
    CardFooter, 
    Col, 
    Input, 
    InputGroup, 
    InputGroupText, 
    Row 
}                               from 'reactstrap'

import { ReportContext }        from '../../../../context/reportContext'
import { DashboardContext }     from '../../../../context/dashboardContext'

import moment                   from 'moment'
import Select                   from 'react-select'
import MasterModal              from '../../../reports/components/masterModal'
import { useParams }            from 'react-router-dom'
import { contentIN1 }           from '../../../reports/in/in-1/components/bodyReport'
import TasklistDropdown         from '../TasklistDropdown'

import { 
    generatePDF, 
    headerSection, 
    signDefault, 
    tembusanSec, 
    subHeaderWithLetterNumber, 
}                               from '../../../../@core/components/pdfmake'
import TaskListCategory from '../TaskListCategory'
import ReportCategory from './reportCategory'

const DetailWidget = () => {

    const {
        listReport,
        listReportLoading,
        listReportPaginate,

        getDetailDashboard
    } = useContext(DashboardContext)

    const {
        data,
        params, 
        setParams, 
        paginate, 
        loading, 
        showForm, 
        setShowForm,
        setUpModalEdit,
        dataSelected,
        dashboardReport, 
        setDataSelected,
        setSelected,
        setHideColumn,

        getData, 
        setUpModalPreview,
        setUpModalAcceptTask,
        setUpModalTrackStatus,
        setUpModalEditHistory,
        setUpModalAcceptPerpanjangan,
        setUpModalRequestPerpanjangan,
    } = useContext(ReportContext)

    const {id}                                          = useParams()
    const [page, setPage]                               = useState(1)
    const [endDate, setEndDate]                         = useState(moment().endOf('month').format('YYYY-MM-DD'))
    const [startDate, setStartDate]                     = useState(moment().startOf('month').format('YYYY-MM-DD'))
    const [reportStatus, setReportStatus]               = useState(id)
    const [selectSort, setSelectSort]                   = useState(null)
    const [keyword, setkeyword]                         = useState(null)

    const [header, setHeader] = useState([      
        {
            unique_key      : 'satuan_kerja'
        }
    ])

    const [reportData, setReportdata]                   = useState({
        report          : "IN.1",
        reportType      : "IN",
        reportTitle     : "Surat Perintah Tugas",
        reportTypeId    : "1"
    })

    const sortOptions = [
        {value : 'asc', label : 'Terlama'},
        {value : 'desc', label : 'Terbaru'}
    ]

    const handlePDF     = (data, type, copies) => {
        
        const content = [
			...headerSection(data, reportData?.report, copies),
            ...contentIN1(data),
			...signDefault(data?.tanggal, data?.dikeluarkan_di, data?.penandatangan?.position?.name, data?.penandatangan?.name, data?.penandatangan?.grade?.name, data?.penandatangan?.nip, data?.penandatangan?.classes?.name),
			...tembusanSec(data),
		]

        if (reportData.reportTypeId === '21') {
            content.splice(2, 0, ...subHeaderText([
				'',
				{
					bold : true,
					text : 'SURAT PERINTAH',
				},
				{
					bold : true,
					text : `NOMOR : ${data?.nomor_surat ? data?.nomor_surat : "-"}`,
				},
				{
					bold : true,
					text : `${data?.penandatangan?.position?.name}`
				},
			], 
			"center"))
        } else {
            content.splice(2, 0, ...subHeaderWithLetterNumber(data, reportData.reportTitle))
        }

        return generatePDF(data, reportData.reportTypeId, reportData?.reportTitle, reportData?.reportType, type, content, copies ? copies : 0)
    }

    useEffect(async () => {
        setHideColumn(() => header.map(() => true))
    }, [])

    useEffect(() => {
        getDetailDashboard({
                page                    : page,
                type                    : reportStatus,
                order                   : selectSort,
                report_type             : reportData.reportTypeId,
                value                   : keyword ? keyword : null,
                field_id                : reportData?.reportTypeId === '1' ? 9 : reportData?.reportTypeId === '2' ? 26 : reportData?.reportTypeId === '3' ? 44 : reportData?.reportTypeId === '3' ? 44 : 274,
                endDate                 : moment(endDate).format('YYYY-MM-DD'),
                startDate               : moment(startDate).format('YYYY-MM-DD'),
        })
    }, [reportStatus, startDate, endDate, page, reportData, selectSort])

    console.log('listReport', listReport)

  return (
    <>
    <MasterModal
            header     = {header}
            handlePDF  = {handlePDF}
            reportData = {reportData} 
        />
    <Row>
        <Col xs={8}>
            <div className="d-flex justify-content-between mb-1">
                <div className="h1"><strong>Daftar Berkas </strong></div>
            </div>
        </Col>
        <Col xs={4}>
            <div className='d-flex justify-content-between '>
                <Flatpickr 
                    value       = {startDate} 
                    className   = 'form-control me-1' 
                    onChange    = {(start) => {
                        setStartDate(start[0])
                    }} 
                />
                <Flatpickr 
                    value       = {endDate} 
                    className   = 'form-control' 
                    onChange    = { (end) => {
                        setEndDate(end[0])
                    }} 
                />
            </div>
        </Col>
        <Col xs={12}>
            <Card>
                <CardBody>
                    <Button
                        outline = {reportStatus !== '2' }
                        className = 'round me-1'
                        onClick = {() => {
                            setReportStatus('2')
                        }}
                    >
                        Sudah Disetujui
                    </Button>
                    <Button
                        outline = {reportStatus !== '1' }
                        
                        className='round me-1'
                        onClick = {() => {
                            setReportStatus('1')
                        }}
                    >
                        Belum Disetujui
                    </Button>
                    <Button
                        outline = {reportStatus !== '5' }

                        className='round '
                        onClick = {() => {
                            setReportStatus('5')
                        }}
                    >
                        Revisi
                    </Button>
                </CardBody>
            </Card>
        </Col>
        <Col xs={2}>
            <Select
                  key={'select-filter'}
                //   options={workunitRanking}
                  className="basic-single round"
                  isClearable
                  classNamePrefix="select"
                  placeholder="Filter"
                //   onChange={(e) => setSelectWorkunit(e ? e.value : null)}
            />
           
        </Col>
        <Col xs={2}>
            <Select
                  key={'select-order'}
                  options={sortOptions}
                  className="basic-single round"
                  isClearable
                  classNamePrefix="select"
                  placeholder="Urutkan"
                  onChange={(e) => setSelectSort(e ? e.value : null)}
            />
            {/* <Card>
                <Button
                    outline
                >
                    Urutkan
                </Button>
            </Card> */}
        </Col>
        <Col xs={8} className={'d-flex justify-content-end'}>
            <form 
                onSubmit = {(e) => {
                    e.preventDefault()
                    getDetailDashboard({
                        page                    : page,
                        type                    : reportStatus,
                        value                   : keyword ? keyword : null,
                        report_type             : reportData.reportTypeId,
                        field_id                : reportData?.reportTypeId === '1' ? 9 : reportData?.reportTypeId === '2' ? 26 : reportData?.reportTypeId === '3' ? 44 : reportData?.reportTypeId === '3' ? 44 : 274,
                        endDate                 : moment(endDate).format('YYYY-MM-DD'),
                        startDate               : moment(startDate).format('YYYY-MM-DD'),
                    })
                }}
                className='w-50'
            >
                <InputGroup className = 'input-group-merge mb-2'>
                    <InputGroupText className = 'round'>
                        <Search size = {14} />
                    </InputGroupText>
                    <Input 
                        onChange    = {(e) => setkeyword(e.target.value)} 
                        className   = 'round'
                        placeholder = 'search...' 
                    />
                </InputGroup>
            </form>
        </Col>
        <Col xs={12}>
            <ReportCategory 
                reportData={reportData}
                setReportdata={setReportdata}
            />
        </Col>
        <Col xs={12}>
            <Card className='mt-1'>
                <CardBody className={'pb-0'}>
                    {
                        Array.isArray(listReport) && listReport.map((data, index) => (
                            <Card 
                                key={index}
                                className={'p-0 m-0'}
                            >
                                <Row>
                                    <Col xs='1' className={'d-flex justify-content-center align-items-center text-center'}>
                                        {/* <Input type='checkbox' /> */}
                                        <div>{index + 1}.</div>
                                    </Col>
                                    <Col xs={3} className={'d-flex justify-content-center align-items-center text-center'}>
                                       {data?.report_data?.nomor_surat}
                                    </Col>
                                    <Col xs={3} className={'text-center d-flex justify-content-center align-items-center '}>
                                        {
                                            reportData.reportTypeId === '21' ?
                                                data?.report_data?.jenis_kegiatan_penkum
                                            :   
                                                data?.report_data?.judul

                                        }
                                    </Col>
                                    <Col xs={2} className={'d-flex justify-content-center align-items-center teext-center'}>
                                        {data?.report_data?.created_by?.work_unit?.work_unit_level?.name} {data?.report_data?.created_by?.work_unit?.name} 
                                    </Col>
                                    <Col xs={1} className={'d-flex justify-content-center text-center align-items-center'}>
                                        <div>
                                            {moment.unix(data?.report_data?.tanggal).format('DD/MM/YYYY')}
                                        </div>
                                    </Col>
                                    <Col xs={1} className={'d-flex justify-content-end text-end align-items-center '}>
                                       
                                        <div>
                                            <TasklistDropdown 
                                                data                            = {data?.report_data}
                                                reportData                      = {data?.report_type}
                                                setShowForm                     = {setShowForm}
                                                setUpModalEdit                  = {setUpModalEdit}
                                                setUpModalEditHistory           = {setUpModalEditHistory}
                                                setUpModalAcceptTask            = {setUpModalAcceptTask}
                                                setUpModalTrackStatus           = {setUpModalTrackStatus}
                                                setUpModalAcceptPerpanjangan    = {setUpModalAcceptPerpanjangan}
                                                setUpModalRequestPerpanjangan   = {setUpModalRequestPerpanjangan}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={1} className='pe-0 d-flex justify-content-end'>
                                        {
                        
                                            data?.report_data?.is_draft ?
                                            <div className="status-box-draft">
                                                <p className="status-text-draft">Draft</p>
                                            </div>
                                        :
                                            data?.report_data?.last_track_status?.id === 1 ?
                                                <div className="status-box-need-approve">
                                                    <p className="status-text-need-approve">Menunggu Persetujuan</p>
                                                </div>
                                            :
                                                data?.report_data?.last_track_status?.id === 2 ?
                                                    <div className="status-box-approve">
                                                        <p className="status-text-approve">Disetujui</p>
                                                    </div>
                                                :
                                                    data?.report_data?.last_track_status?.id === 3 ?
                                                        <div className="status-box-process">
                                                            <p className="status-text-process">Proses</p>
                                                        </div>
                                                    : 
                                                        data?.report_data?.last_track_status?.id === 4 ?
                                                            <div className="status-box-decline">
                                                                <p className="status-text-decline">Ditolak</p>
                                                            </div>
                                                        :
                                                            data?.report_data?.last_track_status?.id === 5 ?
                                                                <div className="status-box-correct">
                                                                    <p className="status-text-correct">Diperbaiki</p>
                                                                </div>
                                                            :
                                                                data?.report_data?.last_track_status?.id === 8 ?
                                                                    <div className="status-box-grace-period">
                                                                        <p className="status-text-grace-period">Masa Tenggang</p>
                                                                    </div>
                                                                :
                                                                    data?.report_data?.last_track_status?.id === 9 ?
                                                                        <div className="status-box-not_completed">
                                                                            <p className="status-text-not_completed">Tidak Selesai</p>
                                                                        </div>
                                                                    :
                                                                        data?.report_data?.last_track_status?.id === 10 ?
                                                                            <div className="status-box-correct">
                                                                                <p className="status-text-correct">Perpanjangan Ditolak</p>
                                                                            </div>
                                                                        :
                                                                            <div className="status-box-done">
                                                                                <p className="status-text-done">Selesai</p>
                                                                            </div>
                                        }
                                    </Col>
                                <hr />
                                </Row>
                            </Card>
                        ))
                    }
                    {
                        listReportLoading && (
                            <div className='d-flex justify-content-center align-items-center '>
                                <h4>Loading...</h4>
                            </div>
                        )
                    }
                    {
                       (Array.isArray(listReport) && listReport.length === 0) && (
                            <div className='d-flex justify-content-center align-items-center '>
                                <h4>Data Not Found</h4>
                            </div>
                        )
                    }
                </CardBody>
                <div className='d-flex justify-content-end me-1 '>
                    {
                        listReportPaginate.previous ? (
                            <>
                                
                                <div 
                                    onClick     = {() => setPage(listReportPaginate.page - 1)}
                                    className   = 'cursor-pointer'
                                >
                                    <ChevronLeft  />
                                </div>
                            </>
                        ) : (
                            <>
                                
                                <div className='text-muted'>
                                    <ChevronLeft  />
                                </div>
                            </>
                        )
                    }
                    <div className='mx-2'>
                        <p>
                            page : {listReportPaginate.page} {' '} 
                                dari {listReportPaginate.total_page}
                        </p>
                    </div>
                    {
                        listReportPaginate.next ? (
                            <>
                                <div 
                                    onClick     = {() => setPage(listReportPaginate.page + 1)}
                                    className   = 'cursor-pointer'
                                >
                                    <ChevronRight />
                                </div>
                                
                            </>
                        ) : (
                            <>
                                <div className='text-muted'>
                                    <ChevronRight />
                                </div>
                                
                            </>
                        ) 
                    }
                </div>
               
            </Card>
        </Col>
    </Row>
       
    </>
  )
}

export default DetailWidget