import { useContext, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { 
    Form, 
    Label, 
    Modal, 
    Button, 
    ModalBody, 
    ModalHeader,
    ModalFooter,
    Row,
    Col, 
} from "reactstrap"

import SubmitButton from "@components/submit-button"

import Flatpickr 			        from "react-flatpickr"
import 'flatpickr/dist/themes/light.css' 

import toast                    from "react-hot-toast"
import moment                   from "moment"
import Select                   from 'react-select'
import { selectThemeColors }    from '@utils'
import { ReportContext }        from "../../../context/reportContext"
import { ArrowLeft } from "react-feather"
import { useLocation } from "react-router-dom"

const currentDate  = new Date()
const currentMonth = currentDate.getMonth()
const currentYear  = currentDate.getFullYear()
const lastDate     = new Date(currentYear, currentMonth + 1, 0).getDate()


const ModalFormFilterNew = ({ show, onClose, reportData}) => {

    const [year, setYear]       = useState(null)
    const [month, setMonth]     = useState(null)
    const [loading, setLoading] = useState(false)

    const [semester, setSemester]                   = useState(null)
    const [status, setStatus]                       = useState(null)
    const [statusInstruction, setStatusInstruction] = useState(null)

    const [filterOption, setFilterOption]   = useState(0)
    const [endDate, setEndDate]             = useState(new Date(`${currentYear}-${currentMonth + 1}-${lastDate}`))
    const [endDateErr, setEndDateErr]               = useState(false)


    const [startDate, setStartDate]         = useState(new Date(`${currentYear}-${currentMonth + 1}-1`))
    const [startDateErr, setStartDateErr]           = useState(false)

    const { 
        setParams, 
        setParamsDate, 
        setCurrentPage
    }         = useContext(ReportContext)

    const location = useLocation()

    const {
        handleSubmit,
        reset,
        formState: { }
    } = useForm({
        mode: 'onChange'
    })

    const validateError = (state) => {
        if (startDate && endDate) {
            if (startDate > endDate) {
                if (state === "start") {
                    setStartDateErr("Tanggal Awal Tidak Boleh Lebih Besar Dari Tanggal Akhir")
                } else if (state === "end") {
                    setEndDateErr("Tanggal Akhir Tidak Boleh Lebih Kecil Dari Tanggal Awal")
                }
            }
        } else {
            setStartDateErr(false)
            setEndDateErr(false)
        }
    }

    useEffect(() => {
        setStartDateErr(false)
        setEndDateErr(false)
        validateError("start")
    }, [startDate])

    useEffect(() => {
        setStartDateErr(false)
        setEndDateErr(false)
        validateError("end")
    }, [endDate])


    const onSubmit = () => {
        
        setLoading(true)
        setCurrentPage(1)

        if (filterOption?.value === 1) {
            if (semester.value === 1) {
                setParamsDate({
                    start_created_at : moment().month(0).startOf('month').format('YYYY-MM-DD'),
                    end_created_at : moment().month(5).endOf('month').format('YYYY-MM-DD')
                })
                setLoading(false)
            } else if (semester.value === 2) {
                setParamsDate({
                    start_created_at : moment().month(6).startOf('month').format('YYYY-MM-DD'),
                    end_created_at : moment().month(11).endOf('month').format('YYYY-MM-DD')
                })
                setLoading(false)
            } else {
                setParamsDate({
                    start_created_at : moment().month(0).startOf('month').format('YYYY-MM-DD'),
                    end_created_at : moment().month(11).endOf('month').format('YYYY-MM-DD')
                })
                setLoading(false)
            }
        } else if (filterOption?.value === 2) {
            setParamsDate({
                start_created_at : moment(startDate).format('YYYY-MM-DD'),
                end_created_at : moment(endDate).format('YYYY-MM-DD')
            })
            setLoading(false)
        } else if (filterOption?.value === 3) {
            setParams({
                report_approvement_id       : status.value,
                report_approvement_id_new   : status.value,
                isFilterStatus              : true
            })
            setLoading(false)
        } else if (filterOption.value === 4) {
            setParams({
                field_id    : reportData?.reportTypeId === "27" ? 388 : 410,
                value       : statusInstruction?.value === 1 ? '__ne' : "__blank"
            })
            setLoading(false)
        }
        // setParams()
        onClose()
    }

    const onReset = () => {
        setParams({
            page        : 1,
            // limit       : 10,
        })
        reset()
        setStatus(null)
        setFilterOption(0)
        setCurrentPage(1)
        setParamsDate(null)
        setSemester(null)
        setEndDate(moment().endOf('month').format('YYYY-MM-DD'))
        setStartDate(moment().startOf('month').format('YYYY-MM-DD'))
        // onClose()
    }

    const [filterOptions, setFilterOptions] = useState([
        {label : 'Filter Semester',  value : 1},
        {label : 'Filter Tanggal',  value : 2}
    ])

    const semesterOption = [
        {label : 'Semua Semester',  value : 0},
        {label : 'Semester 1',  value : 1},
        {label : 'Semester 2',  value : 2}
    ]

    const [statusOption, setStatusOption] = useState([
        {label : 'Menunggu Persetujuan',  value : 1},
        {label : 'Disetujui',  value : 2},
        {label : 'Proses',  value : 3},
        {label : 'Ditolak',  value : 4},
        {label : 'Diperbaiki',  value : 5},
        {label : 'Selesai',  value : 7},
        {label : 'Menunggu Perpanjangan Disetujui',  value : 8},
        {label : 'Perpanjangan Disetujui',  value : 9},
        {label : 'Perpanjangan Ditolak',  value : 10},
    ])

    const instructionOption = [
        {label : 'Sudah Diberikan',  value : 1},
        {label : 'Belum Diberikan',  value : 0},
    ]

    const createTahunOptions = () => {
        const tahunSekarang = new Date().getFullYear()

        const tahunAwal = 2023

        const dataTahun = []

        for (let tahun = tahunAwal; tahun <= tahunSekarang; tahun++) {
            dataTahun.push({
                label: tahun.toString(), 
                value: tahun 
            })
        }

        setYearOptions(dataTahun)

    }   

    useEffect(() => {
        return () => {
            if (location.pathname !== "/dashboard") {
                onReset()
                // if (paramsDate) {
                //     window.location.reload()
                // }
            }
        }
    }, [location.pathname])

    // useEffect(() => {
    //     if (filterOption?.value) {
    //         setCurrentPage(1)
    //     }
               
    // }, [filterOption])


    useEffect(() => {
        // console.log("reportTypeField", reportData)

        if (
            reportData?.reportTypeId === "1" ||
            reportData?.reportTypeId === "2" ||
            reportData?.reportTypeId === "3" ||
            reportData?.reportTypeId === "21" ||
            reportData?.reportTypeId === "27" ||
            reportData?.reportTypeId === "28"
        ) {
            const temp               = []
            const checkStatus        = filterOptions?.filter((dt) => dt?.value === 3)
            const checkInstructiom   = filterOptions?.filter((dt) => dt?.value === 4)

            if (checkStatus?.length === 0) {
                temp.push({label : 'Filter Status Laporan',  value : 3})
            }

            if (
                reportData?.reportTypeId === "27" ||
                reportData?.reportTypeId === "28"
            ) {
                if (checkInstructiom?.length === 0) {
                    temp.push({label : 'Filter Petunjuk Pimpinan',  value : 4})
                }
            }

            setFilterOptions([
                ...filterOptions,
                ...temp
            ])
        }

        if (
            reportData?.reportTypeId === "1" ||
            reportData?.reportTypeId === "3"
        ) {
            setStatusOption([
                {label : 'Menunggu Persetujuan',  value : 1},
                {label : 'Disetujui',  value : 2},
                {label : 'Proses',  value : 3},
                {label : 'Ditolak',  value : 4},
                {label : 'Diperbaiki',  value : 5},
                {label : 'Selesai',  value : 7},
                {label : 'Masa Tenggang',  value : 8},
                {label : 'Tidak Selesai',  value : 9},
                // {label : 'Menunggu Perpanjangan Disetujui',  value : 8},
                // {label : 'Perpanjangan Disetujui',  value : 9},
                // {label : 'Perpanjangan Ditolak',  value : 10},
            ])
        } else if (
            reportData?.reportTypeId === "21"
        ) {
            setStatusOption([
                {label : 'Menunggu Persetujuan',  value : 1},
                {label : 'Disetujui',  value : 2},
                {label : 'Ditolak',  value : 4},
                {label : 'Diperbaiki',  value : 5},
            ])
        } else if (
            reportData?.reportTypeId === "27" ||
            reportData?.reportTypeId === "28"
        ) {
            setStatusOption([
                {label : 'Proses',  value : 3},
                {label : 'Selesai',  value : 7},
            ])
        }
        
    }, [reportData])

    return (
        <Modal
            isOpen={show}
            unmountOnClose={true}
        >
            <ModalHeader toggle={() => { onClose() }}>Filter Data</ModalHeader>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <ModalBody>
                    <div>
                        <Label className='form-label'>Pilih Filter:</Label>
                        <Select
                            value          = {filterOption} 
                            theme          = {selectThemeColors}
                            options        = {filterOptions} 
                            className      = "react-select"
                            onChange       = {(e) => setFilterOption(e)} 
                            placeholder    = "Pilih Filter"
                            classNamePrefix = "select"
                        />
                    </div>
                        
                    {
                        filterOption.value === 1 ? (
                            <div className="mt-2">
                                <Label className='form-label'>Pilih Semester:</Label>
                                <Select
                                    value          = {semester} 
                                    theme          = {selectThemeColors}
                                    options        = {semesterOption} 
                                    className      = "react-select"
                                    onChange       = {(e) => setSemester(e)} 
                                    placeholder    = "Pilih Semester"
                                    classNamePrefix = "select"
                                />
                            </div>
                        ) : filterOption.value === 2 ? (
                            <div className=' mt-2 mb-1'>
                                <Row>
                                    <Col xs={6}>
                                          <Flatpickr 
                                                value       = {startDate} 
                                                onChange    = {(start) => {
                                                    setStartDate(start[0])
                                                }} 
                                                className   = 'form-control round me-1' 
                                            />
                                            {
                                                startDateErr ? <small className="text-danger mb-1">{startDateErr}</small> : ""
                                            }
                                    </Col>
                                    <Col xs={6}>
                                        <Flatpickr 
                                            value       = {endDate} 
                                            onChange    = { (end) => {
                                                setEndDate(end[0])
                                            }} 
                                            className   = 'form-control round' 
                                        />
                                        {
                                            endDateErr ? <small className="text-danger mb-1">{endDateErr}</small> : ""
                                        }
                                    </Col>
                                </Row>
                              
                            </div>
                        ) : filterOption.value === 3 ? (
                            <div className="mt-2">
                                <Label className='form-label'>Pilih Status:</Label>
                                <Select
                                    value          = {status} 
                                    theme          = {selectThemeColors}
                                    options        = {statusOption} 
                                    className      = "react-select"
                                    onChange       = {(e) => setStatus(e)} 
                                    placeholder    = "Pilih Status"
                                    classNamePrefix = "select"
                                />
                            </div>
                        ) : filterOption.value === 4 && (
                            <div className="mt-2">
                                <Label className='form-label'>Pilih Status Petunjuk Pimpinan:</Label>
                                <Select
                                    value          = {statusInstruction} 
                                    theme          = {selectThemeColors}
                                    options        = {instructionOption} 
                                    className      = "react-select"
                                    onChange       = {(e) => setStatusInstruction(e)} 
                                    placeholder    = "Pilih Status Petunjuk Pimpinan"
                                    classNamePrefix = "select"
                                />
                            </div>
                        )
                    }

                </ModalBody>

                <ModalFooter className="d-flex justify-content-between">
                    <Button.Ripple
                        color="primary"
                        outline={true}
                        onClick={() => {
                            onReset()
                            onClose()
                        }}
                    >
                        Reset
                    </Button.Ripple>
                    {
                        filterOption !== 0 ? (
                            <SubmitButton
                                disabled    = {filterOption.value === 2 ? startDateErr || endDateErr : false}
                                title       = "Filter Data"
                                loading     = {loading}
                            />
                        ) : (
                            <Button 
                                color       = 'primary'
                                disabled 
                            >
                                Filter Data
                            </Button>
                        )
                    }
                </ModalFooter>
            </Form>
        </Modal>
    )
}

export default ModalFormFilterNew
