import { lazy } 		from 'react'
import { Navigate } 	from 'react-router-dom'
import HelpdekTabMenu 	from '../../views/helpdesk'

const Dashboard = lazy(() => import('../../views/dashboard'))

const helpdeskRoutes = [
	{
		path	: '/helpdesk',
		element	: <HelpdekTabMenu />,
		meta	: {
			action		: 'read',
			resource	: 'helpdesk'
		}
	},
	{
		path	: '/helpdesk/:type',
		element	: <HelpdekTabMenu/>,
		meta	: {
			action		: 'read',
			resource	: 'helpdesk'
		}
	},
	{
		path	: '/helpdesk/:type/:id',
		element	: <HelpdekTabMenu/>,
		meta	: {
			action		: 'read',
			resource	: 'helpdesk'
		}
	}
]

export default helpdeskRoutes
