import * as yup from "yup"

const PositionValidation = yup
	.object()
	.shape({
		parent				: yup.object({value : yup.string().required("Isian Tidak Boleh Kosong")}),
		position_type		: yup.object({value : yup.string().required("Isian Tidak Boleh Kosong")}),
		work_unit_level_id	: yup.object({value : yup.string().required("Isian Tidak Boleh Kosong")}),
		sector				: yup.object({value : yup.string().required("Isian Tidak Boleh Kosong")}),
		name				: yup.string().required("Isian Tidak Boleh Kosong"),
		description			: yup.string().required("Isian Tidak Boleh Kosong"),
	})
	.required()

export default PositionValidation
