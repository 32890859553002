import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap"
import PropTypes from 'prop-types'

const FormDelete = props => {

    // props
    const {
        show,
        onClose,
        title,
        children,
        onDelete,
        loading
    } = props

    // content
    return (
        <Modal
            isOpen={show}
            centered={true}
            size="sm"
        >
            <ModalHeader
                toggle={onClose}> {title}  </ModalHeader>
            <ModalBody className="text-center">
                {children}
            </ModalBody>
            <ModalFooter className="d-flex justify-content-between">
                <Button.Ripple
                    color="primary"
                    outline
                    onClick={onClose}>
                    Batal
                </Button.Ripple>

                <Button.Ripple
                    color="primary"
                    onClick={onDelete}
                    disabled={loading}>
                    {!loading ? "Hapus" : <Spinner color="success" size="sm" />}
                </Button.Ripple>

            </ModalFooter>
        </Modal>
    )
}

// validation props
FormDelete.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    children: PropTypes.element.isRequired,
    onDelete: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired
}

export default FormDelete