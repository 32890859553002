// ** Icons Import
import { PieChart, Circle } from 'react-feather'

export default [
  {
    id: 'din',
    icon: <PieChart size={20} />,
    title: 'DIN - Data',
    action: 'read',
    resource: 'din-data',
    children: [
      {
        id: 75,
        icon: <Circle size={12} />,
        title: "D.IN.1 - Data Peta",
        action: "read",
        navLink: "/din/1",
        resource: "din1-data-peta"
      },
      {
        id: 76,
        icon: <Circle size={12} />,
        title: "D.IN.2 - Peta Simbol IPOLHANKAM",
        action: "read",
        navLink: "/din/2",
        resource: "din2-peta-simbol-ipolhankam"
      },
      {
        id: 77,
        icon: <Circle size={12} />,
        title: "D.IN.3 - Peta Simbol SOSBUDMAS",
        action: "read",
        navLink: "/din/3",
        resource: "din3-peta-simbol-sosbudmas"
      },
      {
        id: 78,
        icon: <Circle size={12} />,
        title: "D.IN.4 - Peta Simbol EKOKEU",
        action: "read",
        navLink: "/din/4",
        resource: "din4-peta-simbol-ekokeu"
      },
      {
        id: 79,
        icon: <Circle size={12} />,
        title: "D.IN.5 - Peta Simbol PPS",
        action: "read",
        navLink: "/din/5",
        resource: "din5-peta-simbol-pps"
      },
      {
        id: 80,
        icon: <Circle size={12} />,
        title: "D.IN.6 - Peta Simbol TIKPROIN",
        action: "read",
        navLink: "/din/6",
        resource: "din6-peta-simbol-tikproin"
      },
      {
        id: 81,
        icon: <Circle size={12} />,
        title: "D.IN.7 - Kegiatan PENKUM",
        action: "read",
        navLink: "/din/7",
        resource: "din7-kegiatan-penkum"
      },
      {
        id: 82,
        icon: <Circle size={12} />,
        title: "D.IN.8 - Dokumentasi Kegiatan PENKUM",
        action: "read",
        navLink: "/din/8",
        resource: "din8-dokumentasi-kegiatan-penkum"
      },
      {
        id: 83,
        icon: <Circle size={12} />,
        title: "D.IN.9 - Target Kegiatan PENKUM",
        action: "read",
        navLink: "/din/9",
        resource: "din9-target-kegiatan-penkum"
      },
      {
        id: 84,
        icon: <Circle size={12} />,
        title: "D.IN.10 - Formulir Data Orang Asing",
        action: "read",
        navLink: "/din/10",
        resource: "din10-formulir-data-orang-asing"
      },
      {
        id: 85,
        icon: <Circle size={12} />,
        title: "D.IN.11 - Grafik",
        action: "read",
        navLink: "/din/11",
        resource: "din11-grafik"
      },
      {
        id: 86,
        icon: <Circle size={12} />,
        title: "D.IN.12 KT Biodata",
        action: "read",
        navLink: "/din/12",
        resource: "din12-kt-biodata"
      },
      {
        id: 87,
        icon: <Circle size={12} />,
        title: "D.IN.13 - KT Barang Cetakan",
        action: "read",
        navLink: "/din/13",
        resource: "din13-kt-barang-cetakan"
      },
      {
        id: 88,
        icon: <Circle size={12} />,
        title: "D.IN.14 - KT Organisasi",
        action: "read",
        navLink: "/din/14",
        resource: "din14-kt-organisasi"
      },
      {
        id: 89,
        icon: <Circle size={12} />,
        title: "D.IN.15 - Tersangka",
        action: "read",
        navLink: "/din/15",
        resource: "din15-tersangka"
      },
      {
        id: 90,
        icon: <Circle size={12} />,
        title: "D.IN.16 - KT Pengawasan Media",
        action: "read",
        navLink: "/din/16",
        resource: "din16-kt-pengawasan-media"
      }
    ]
  }
]
