import { useEffect, useState } from "react"
import { 
    Doughnut
}                   from "react-chartjs-2"

import {
    Card,
    CardBody,
    Col
}                   from "reactstrap"

const WidgetPerkara = (props) => {

    const {
        data,
    } = props

    const optionsDonut   = {
        responsive          : true,
        maintainAspectRatio : false,
        plugins     : {
            legend: {
                display : false
            },
            title: {
                display: false
            }
        },
        scales: {
            x: {
                display : false
            },
            y: {
                display : false
            }
        }
    }

    const [dataDonut, setDataDounut]     = useState({
        labels  : [],
        datasets: []
    })
    
    // {
    //     labels: ['Korupsi', 'Narkotika', 'Terorisme', 'Atensi Masyarakat', 'Penerangan Hukum'],
    //     datasets: [
    //         {
    //             label: '# of Votes',
    //             data: [12, 19, 3, 5, 2],
    //             backgroundColor: [
    //                 'rgba(255, 99, 132, 1)',
    //                 'rgba(54, 162, 235, 1)',
    //                 'rgba(255, 206, 86, 1)',
    //                 'rgba(75, 192, 192, 1)',
    //                 'rgba(153, 102, 255, 1)'
    //             ],
    //             borderColor: [
    //                 'rgba(255, 99, 132, 1)',
    //                 'rgba(54, 162, 235, 1)',
    //                 'rgba(255, 206, 86, 1)',
    //                 'rgba(75, 192, 192, 1)',
    //                 'rgba(153, 102, 255, 1)'
    //             ],
    //             borderWidth: 1
    //         }
    //     ]
    // }

    useEffect(() => {
        if (data) {
            const labels = []
            const datas  = []

            data?.map((dt) => {
                labels.push(dt?.name)
            })

            data?.map((dt) => {
                datas.push(dt?.count?.length)
            })

            setDataDounut(
                {
                    labels: labels,
                    datasets: [
                        {
                            label: '# of Votes',
                            data: datas,
                            backgroundColor: [
                                'rgba(255, 99, 132, 1)',
                                'rgba(54, 162, 235, 1)',
                                'rgba(255, 206, 86, 1)',
                                'rgba(75, 192, 192, 1)',
                                'rgba(153, 102, 255, 1)'
                            ],
                            borderColor: [
                                'rgba(255, 99, 132, 1)',
                                'rgba(54, 162, 235, 1)',
                                'rgba(255, 206, 86, 1)',
                                'rgba(75, 192, 192, 1)',
                                'rgba(153, 102, 255, 1)'
                            ],
                            borderWidth: 1
                        }
                    ]
                }
            )
        }
    }, [data])

    return (
        <>
            <Col
                className   = "h-1001 pb-2"
                style       = {{
                    height  : '400px'
                }}
            >
                <Card className="h-100 p-0 m-0">
                    <CardBody>
                        <div
                            className   = "h-100 d-flex flex-column"
                        >
                            <div className = "d-flex pb-2">
                                <div 
                                    className = "bg-primary rounded-circle me-1"
                                    style = {{
                                        width  : '20px',
                                        height : '20px'
                                    }}
                                />
                                <strong>Jenis Perkara</strong>
                            </div>
                            <div 
                                className   = 'flex-grow-1 bg-white' 
                                style       = {{ position: 'relative', width: '100%', height : '100%'}}
                            >
                                <Doughnut
                                    data    = {dataDonut} 
                                    options = {optionsDonut}
                                />
                            </div>
                            <div className="col-12 m-0">
                                {
                                    dataDonut.labels !== null &&
                                        dataDonut.labels?.map((dt, idx) => {
                                            return (
                                                <small>
                                                    <div className="d-flex justify-content-between">
                                                        <div
                                                            className="d-flex"
                                                            style = {{
                                                                padding : '4px'
                                                            }}
                                                        >
                                                            <div
                                                                className="me-1"
                                                                style      = {{
                                                                    width  : '15px',
                                                                    height : '15px',
                                                                    backgroundColor : `${dataDonut.datasets[0]?.backgroundColor[idx]}`
                                                                }}
                                                            /> {dt}
                                                        </div>
                                                        <div>
                                                            {dataDonut.datasets[0]?.data[idx]}
                                                        </div>
                                                    </div>
                                                </small>
                                            )
                                        }
                                    )
                                }
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </>
    )
}

export default WidgetPerkara