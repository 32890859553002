import { 
    useContext, 
    useEffect, 
    useState 
}                                   from "react"
import {
    Card,
    Button,
    CardBody,
    CardHeader,
    Row,
    Col,
}                                   from "reactstrap"

import {
    Trash2
}                                   from "react-feather"

// Component
import toast                        from "react-hot-toast"
import TicketingChatMessages        from "./component/TicketingChatMessages"
import TicketingChatCount           from "./component/TicketingChatCount"
import TicketingChatList            from "./component/TicketingChatList"
import { UserManagementContext }    from "../../../context/userManagementContext"

// API
import HelpDeskAPI                  from "../../../service/pages/helpdesk"

import "./index.scss"

const Ticketing = () => {

    const {
        getData,
        listData
    }                                               = useContext(UserManagementContext)

    const userData =  JSON.parse(localStorage.getItem('userData'))

    const [message, setMessage]                     = useState(false)
    const [selected, setSelected]                   = useState(null)
    const [listTicket, setListTicket]               = useState(false)
    const [ticketSelected, setTicketSelected]       = useState([])
    const [showDeleteTicket, setShowDeleteTicket]   = useState(false)


    const handleViewUser = (data) => {
        const category = listData && listData.find((item) => item.user_id ===  data)
        return category && category.name 
    }

    const handleViewPosition = (data) => {
        const category = listData && listData.find((item) => item.user_id ===  data)
        return category && category.work_unit?.name 
    }


    const getTicket = (params) => {
        HelpDeskAPI.getTicket(params)
            .then(res => {
                setListTicket(res.results)
            }).catch(() => {
                toast.error('Gagal Menghubungkan Data')
            })
    }

    const getMessages = (param) => {
        HelpDeskAPI.getMessage(param)
            .then(res => {
                setMessage(res.results)
            }).catch(() => {
                toast.error('Gagal Menghubungkan Data')
            })
    }
    
    useEffect(() => {
        getTicket()
        getData({page_size : 100})
    }, [])

    useEffect(() => {
        getMessages({ticket : selected && selected.id})
    }, [selected])


    return (
        <>
        {
            userData.role === 'operator-operator' && listTicket  &&
                <div
                    className="h-100"
                >
                    <div className = "d-inline-flex ">
                         <div className = "d-flex">
                            <TicketingChatCount 
                                listTicket = {listTicket}
                            />
                        </div>
                        <div 
                            style       = {{ right : 30, marginTop : '30px' }}
                            className   = "position-fixed" 
                        >
                            <Button 
                                color       ="primary"
                                onClick     = {() => setShowDeleteTicket(true)} 
                                disabled    = {ticketSelected.length === 0 }
                                className   = "btn-icon " 
                            >
                                <Trash2 />
                            </Button>
                        </div>
                    </div>
                   
                    <Row>
                        <Col 
                            lg          = {4}
                            style       = {{height: '630px'}}
                            className   = {`${selected ? 'list-none' : 'list-chat'} m-0`}
                        >
                            <TicketingChatList 
                                data                = {listTicket}
                                selected            = {selected}
                                getTicket           = {getTicket}
                                setSelected         = {setSelected}
                                handleViewUser      = {handleViewUser}
                                showDeleteTicket    = {showDeleteTicket} 
                                ticketSelected      = {ticketSelected} 
                                setTicketSelected   = {setTicketSelected}
                                setShowDeleteTicket = {setShowDeleteTicket} 
                            />
                        </Col>
                        <Col lg={8} className={`pe-0 ${selected ? '' : 'chat-message'}`}>
                            <TicketingChatMessages 
                                selected            = {selected}
                                messages            = {message}
                                userData            = {userData}
                                getTicket           = {getTicket}
                                setSelected         = {setSelected}
                                getMessages         = {getMessages}
                                handleViewUser      = {handleViewUser}
                                handleViewPosition  = {handleViewPosition}
                            />
                        </Col>
                    </Row>
                </div>
        }

        {
            userData.role !== 'operator-operator' && listTicket  &&
                <div className  = "h-100">
                    <Card>
                        <CardHeader 
                            tag         = {'h3'}
                            className   = "font-weight-bolder" 
                        >Daftar Pertanyaan Yang Di Ajukan</CardHeader>
                        <Row className = "p-1">
                            <Col 
                                lg          = {4}
                                style       ={{
                                    height      : '630px',
                                    boxShadow   : 'rgba(0, 0, 0, 0.24) 0px 3px 8px'
                                }}
                                className   = {`${selected ? 'list-none' : 'list-chat'} mx-1 rounded`}

                            >
                                <TicketingChatList 
                                    data                = {listTicket}
                                    selected            = {selected}
                                    getTicket           = {getTicket}
                                    setSelected         = {setSelected}
                                    handleViewUser      = {handleViewUser}
                                    ticketSelected      = {ticketSelected} 
                                    showDeleteTicket    = {showDeleteTicket}
                                    setTicketSelected   = {setTicketSelected}
                                    setShowDeleteTicket = {setShowDeleteTicket} 

                                />
                            </Col>
                            <Col 
                                lg          = {7}
                                style       = {{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'}}
                                className   = {` ${selected ? '' : 'chat-message'}mx-1 rounded `}
                            >
                                <TicketingChatMessages 
                                    selected            = {selected}
                                    messages            = {message}
                                    userData            = {userData}
                                    getTicket           = {getTicket}
                                    setSelected         = {setSelected}
                                    getMessages         = {getMessages}
                                    handleViewUser      = {handleViewUser}
                                    handleViewPosition  = {handleViewPosition}
                                />
                            </Col>
                        </Row>
                    </Card>
                    
                </div>
        }
        {
            !listTicket  &&
                <Card >
                    <CardBody className="d-flex justify-content-center font-weight-bolder text-dark p-2">
                        <h1>
                            Loading...
                        </h1>
                    </CardBody>
                </Card> 
        }
        </>
    )
}

export default Ticketing