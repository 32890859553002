const acceptTaskRules = (reportData, toReportData) => {

    const {reportTypeId} = reportData

    let params = {}

    if (parseInt(reportTypeId) === 1) { //IN.1
        params = 3
    } else if (parseInt(reportTypeId) === 2) { //IN.2
        params = 3
    } else if (parseInt(reportTypeId) === 3) { //IN.3
        params = 3
    } else if (parseInt(reportTypeId) === 4) { //IN.4
        //tidak ada penerimaan tugas
    } else if (parseInt(reportTypeId) === 5) { //IN.5
        //tidak ada penerimaan tugas
    } else if (parseInt(reportTypeId) === 6) { //IN.6
        params = 3
    } else if (parseInt(reportTypeId) === 7) { //IN.7
        params = 3
    } else if (parseInt(reportTypeId) === 8) { //IN.8
        params = 3
    } else if (parseInt(reportTypeId) === 9) { //IN.9
        params = 7
    } else if (parseInt(reportTypeId) === 10) { //IN.10
        params = 7
    } else if (parseInt(reportTypeId) === 11) { //IN.11
        //tidak ada penerimaan tugas
    } else if (parseInt(reportTypeId) === 12) { //IN.12
        //tidak ada penerimaan tugas
    } else if (parseInt(reportTypeId) === 13) { //IN.13
        params = 7
    } else if (parseInt(reportTypeId) === 14) { //IN.14
        //tidak ada penerimaan tugas
    } else if (parseInt(reportTypeId) === 15) { //IN.15
        params = 7
    } else if (parseInt(reportTypeId) === 16) { //IN.16
        //tidak ada penerimaan tugas
    } else if (parseInt(reportTypeId) === 17) { //IN.17
        //tidak ada penerimaan tugas
    } else if (parseInt(reportTypeId) === 18) { //IN.18
        //tidak ada penerimaan tugas
    } else if (parseInt(reportTypeId) === 19) { //IN.19
        //tidak ada penerimaan tugas
    } else if (parseInt(reportTypeId) === 20) { //IN.20
        params = 7
    } else if (parseInt(reportTypeId) === 21) { //IN.21
        params = 7
    } else if (parseInt(reportTypeId) === 22) { //IN.22
        //tidak ada penerimaan tugas
    } else if (parseInt(reportTypeId) === 23) { //IN.22
        //tidak ada penerimaan tugas
    } else if (parseInt(reportTypeId) === 24) { //L.IN.1
        params = 7
    } else if (parseInt(reportTypeId) === 25) { //L.IN.2
        params = 7
    } else if (parseInt(reportTypeId) === 26) { //L.IN.3
        params = 7
    } else if (parseInt(reportTypeId) === 27) { //L.IN.4
        params = 7
    } else if (parseInt(reportTypeId) === 28) { //L.IN.5
        params = 7
    } else if (parseInt(reportTypeId) === 29) { //L.IN.6
        params = 7
    } else if (parseInt(reportTypeId) === 30) { //L.IN.7
        params = 3
    } else if (parseInt(reportTypeId) === 31) { //L.IN.8
        params = 7
    } else if (parseInt(reportTypeId) === 32) { //L.IN.9
        params = 7
    } else if (parseInt(reportTypeId) === 33) { //L.IN.10
        params = 7
    }
    
    return params

}

export default acceptTaskRules