import * as yup from "yup"

const RecapitulationFilterValidationPeriode = yup
	.object()
	.shape({
		year				: yup.object().shape({ value : yup.number().required("Isian Tidak Boleh Kosong")}).required("Isian Tidak Boleh Kosong"),
		month				: yup.object().shape({ value : yup.number().required("Isian Tidak Boleh Kosong")}).required("Isian Tidak Boleh Kosong"),
	})
	.required()

export default RecapitulationFilterValidationPeriode
