// ** Icons Import
import { AlertOctagon } from 'react-feather'

export default [
  {
    id          : 'helpdesk',
    icon        : <AlertOctagon size={20} />,
    title       : 'Helpdesk',
    action      : 'read',
    navLink     : "/helpdesk",
    resource    : 'helpdesk'
  }
]
