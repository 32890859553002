import {
    Card,
    CardBody,
    CardHeader,
    CardFooter,
    Button,
    Input
} from "reactstrap"

import {
    ArrowLeft,
    File,
    Image,
    Link,
    Send
} from "react-feather"

import { useEffect, useRef, useState }        from "react"

import ReactDOM                     from 'react-dom'
import classnames                   from "classnames"
import Avatar                       from '@components/avatar'
import PerfectScrollbar             from 'react-perfect-scrollbar'

import '@styles/base/pages/app-chat.scss'
import '@styles/base/pages/app-chat-list.scss'
import moment from "moment"
import HelpDeskAPI from "../../../../service/pages/helpdesk"
import RenderCardMessage from "./RenderCardMessage"
import { Item } from "react-contexify"
import RenderCardFooter from "./RenderCardFooter"
import toast from "react-hot-toast"
import serviceAPI from "../../../../service/drive/service"

const TicketingChatMessages = ({
    userData,
    selected,
    messages,
    getTicket,
    getMessages,
    setSelected,
    handleViewUser,
    handleViewPosition
}) => {


    const chatArea    = useRef(null)
    const ChatWrapper = Object.keys(Array.isArray(messages) && messages).length ? PerfectScrollbar : 'div'
    const [message, setMessage] = useState(false)

    const renderCardHeader = () => {
        return (
            <CardHeader
                className="border-bottom p-1"
            >
                <div className="d-flex">
                <Button.Ripple 
                        color='flat-primary'
                        onClick = {() => setSelected(null)}
                        className='btn-icon rounded-circle p-0 me-1 back-chat-list' 
                    >
                        <ArrowLeft />
                    </Button.Ripple>
                    <Avatar
                        className="me-1 p-0"
                        size="md"
                    />
                    <div>
                        <div>
                            <strong>{handleViewUser(selected?.user)} {handleViewPosition(selected?.user)}</strong>
                        </div>
                    </div>
                </div>
            </CardHeader>
        )
    }

    const sendMessage = (messages, kind) => {
        setTimeout(() => {
            const formData = {
                ticket: selected.id,
                message: messages ? messages : message,
                sender : userData.user_id,
                kind   : kind ? kind : 4
            }
    
            HelpDeskAPI.createMessage(formData)
                .then((res) => {
                    getMessages({ticket : res.data.ticket})
                    document.getElementById("message").value = ""
                    toast.success(res.message)
                    setSelected(selected)
                    getTicket()
                }).catch((err) => {
                    toast.error(err)
                })
        }, 3000)
    }

    const uploadFileService = (data, type) => {

        const formData = new FormData
        formData.append('file', data[0])
        formData.append('service', 'test-1')

        serviceAPI.uploadFileService(formData)
            .then((result) => {
                const formData = {
                    ticket      : selected.id,
                    message     : result.data.name,
                    kind        : type,
                    url_file    : window.location.origin + result.data.url_file,
                    code_file   : result.data.code,
                }
                HelpDeskAPI.createMessage(formData)
                    .then((res) => {
                        getMessages({ticket : res.data.ticket})
                        toast.success('Berhasil Upload')
                    }).catch(() => {
                        toast.error('Gagal')
                    })

            }).catch(() => {
                toast.error('Gagal Menambahkan')
            })
    }

    const scrollToBottom = () => {
        const chatContainer = ReactDOM.findDOMNode(chatArea.current)
        chatContainer.scrollTop = Number.MAX_SAFE_INTEGER
    }

    useEffect(() => {
        if (selected !== null) {
            scrollToBottom()
        }
    }, [selected])

    return (
        <>
            <Card
                className="h-100 chat-widget"
            >
                {
                    selected !== null &&
                    <>
                        {renderCardHeader()}
                        <CardBody
                            className = "p-0 m-0"
                        >
                            <div className='chat-app-window'>
                                <div
                                    className="active-chat"
                                >
                                    <ChatWrapper ref={chatArea} className='user-chats bg-white m-0' style={{
                                        height : '460px'
                                    }} options={{ wheelPropagation: false }}>
                                        {
                                            messages && messages.length > 0 ? <div className='chats'>
                                                {
                                                    messages?.map((dt, idx) => {
                                                        return (
                                                            <RenderCardMessage 
                                                                item = {dt}
                                                                index = {idx}
                                                                userData = {userData}
                                                            />
                                                        )
                                                    })
                                                }
                                            </div> : null
                                        }
                                    </ChatWrapper>
                                </div>
                            </div>
                        </CardBody>
                        <RenderCardFooter 
                            selected = {selected}
                            message={message}
                            setMessage={setMessage}
                            sendMessage = {sendMessage}
                            uploadFileService = {uploadFileService}
                        />
                    </>
                }
            </Card>
        </>
    )
}

export default TicketingChatMessages