// ** Icons Import
import { File } from 'react-feather'

export default [
  {
    id          : 'siacc',
    icon        : <File size={20} />,
    title       : 'Berita SIACC',
    action      : 'read',
    navLink     : "/siacc",
    resource    : 'berita-siacc'
  }
]
