import React, { useEffect, useState } from 'react'
import { Check, X } from 'react-feather'
import { Col, Input, Modal, ModalBody, CustomInput, ModalHeader, Row, Label, Button, ModalFooter } from 'reactstrap'

const PengaturanTautan = ({
    show,
    setShow,
    setPassword,
    setExpired,
    expired,
    password,
    restrictions,
    setRestrictions,
}) => {

    const [date, setDate] = useState(false)
    const [pass, setPass] = useState(false)
    const [limitOpen, setLimitOpen] = useState(false)

    const CustomLabel = ({ htmlFor }) => (
        <Label className="form-check-label" htmlFor={htmlFor}>
            <span className="switch-icon-left">
                <Check size={14} />
            </span>
            <span className="switch-icon-right">
                <X size={14} />
            </span>
        </Label>
    )

  return (
    <Modal
        isOpen= {show}
        toggle={() => setShow(false)}
    >
        <ModalHeader>
            Pengaturan Tautan
        </ModalHeader>
        <ModalBody>
            <Row>
                <Col md={3}>
                    <div className="form-switch form-check-primary ms-1">
                        <Input
                            id                 = {`icon-primary-asc`}
                            type               = "switch"
                            checked            = {limitOpen}
                            onChange           = {(e) => {
                                if (e.target.checked) {
                                    setLimitOpen(true)
                                } else {
                                    setLimitOpen(false)
                                    setRestrictions(false)
                                }
                            }}
                        />
                        <CustomLabel htmlFor={`icon-primary-asc`} />
                    </div>
                </Col>
                <Col md={9}>
                    {
                        !limitOpen ?  <b> Pembatasan Pembukaan Berkas di nonaktifkan</b> : (
                            <Row>
                                <Col>
                                    <Label>Batasi Buka Berkas :</Label></Col>
                                <Col>
                                    <Input type="number" defaultValue={restrictions} onChange={(e) => setRestrictions(e.target.value)} />
                                </Col>
                                <Col>Kali</Col>
                            </Row>
                        )

                    }
                   
                    <div>
                        Batas pengguna mengkases berkas dengan tautan
                    </div>
                </Col>
                <Col md={3}>
                    <div className="form-switch form-check-primary ms-1">
                        <Input
                            id                 = {`date`}
                            type               = "switch"
                            checked            = {date}
                            onChange           = {(e) => {
                                if (e.target.checked) {
                                    setDate(true)
                                } else {
                                    setDate(false)
                                    setExpired(false)
                                }
                            }}
                        />
                        <CustomLabel htmlFor={`date`} />
                    </div>
                </Col>
                <Col md={9}>
                    {
                        !date ?  <b> Tanggal Kadaluarsa di nonaktifkan</b> : (
                            <Row>
                                <Col>
                                    <Label>Kadaluarsa :</Label></Col>
                                <Col>
                                    <Input type="number" defaultValue={expired} onChange={(e) => setExpired(e.target.value)}/>
                                </Col>
                                <Col>Hari</Col>
                            </Row>
                        )

                    }
                   
                    <div>
                        Akses berkas dihapus pada tanggal tertentu
                    </div>
                </Col>
                <Col md={3}>
                    <div className="form-switch form-check-primary ms-1">
                        <Input
                            id                 = {`password`}
                            type               = "switch"
                            checked            = {pass}
                            onChange           = {(e) => {
                                if (e.target.checked) {
                                    setPass(true)
                                } else {
                                    setPass(false)
                                    setPassword(false)
                                }
                            }}
                        />
                        <CustomLabel htmlFor={`password`} />
                    </div>
                </Col>
                <Col md={9}>
                    {
                        !pass ?  <b> Kata Sandi Di nonaktifkan</b> : (
                            <Row>
                                <Col>
                                    <Label>Atur Password :</Label></Col>
                                <Col>
                                    <Input type="password" defaultValue={password} onChange={(e) => { pass ? setPassword(e.target.value) : setPassword(false) }}/>
                                </Col>
                                <Col></Col>
                            </Row>
                        )

                    }
                   
                    <div>
                        Lindungi Tautan Dengan Kata Sandi Unik
                    </div>
                </Col>

            </Row>
        </ModalBody>
        <ModalFooter className='d-flex justify-content-between'>
            <Button outline onClick={() => setShow(false)}>Batal</Button> 
            <Button color='primary' onClick={() => setShow(false)}>Submit</Button>  
        </ModalFooter>
      
    </Modal>
  )
}

export default PengaturanTautan
