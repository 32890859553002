import {
    Card,
    CardBody,
    Col
}               from "reactstrap"
import Avatar   from '@components/avatar'

const WidgetKejagung = () => {
    return (
        <>
            <Col
                className="pb-2 m-0"
            >
                <Card className="h-100 p-0 m-0">
                    <CardBody>
                        <div className="h4 m-0">
                            <Avatar
                                className   = "me-1 p-0 bg-white"
                                size        = "lg"
                                img         = "https://upload.wikimedia.org/wikipedia/commons/b/b6/Kejaksaan_Agung_Republik_Indonesia_new_logo.png"
                            />
                            <strong>KEJAKSAAN AGUNG RI</strong>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </>
    )
}

export default WidgetKejagung