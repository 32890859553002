import { useDispatch }          from 'react-redux'
import { handleLogout }         from '@store/authentication'
import { useEffect }            from 'react'

import { 
  sso_client_id, 
  sso_realm, 
  sso_url 
}                               from '../../../interceptor/axiosInstance'

const Logout = () => {
  // ** Store Vars

  const href  		= window.location.href
	const split 		= href.split("/")
	const url   		= `${split[0]}//${split[2]}`
  const dispatch  = useDispatch()

  useEffect(() => {
    dispatch(handleLogout())
    // window.location.href = "/login"
    window.location.href = `${sso_url}realms/${sso_realm}/protocol/openid-connect/auth?client_id=${sso_client_id}&redirect_uri=${url}/verify-sso&scope=openid&response_type=code`
  }, [])

  return (
    <>
    
    </>
  )
}

export default Logout