import { Modal, ModalBody, ModalHeader, Spinner } from "reactstrap"
import { ReportContext } from "../../../context/reportContext"
import { useContext, useState } from "react"
import Timeline from "../timeline"
import CustomTableEmptyData from "../custom-table/empty"
import reportAPI from "../../../service/report"
import userManagementAPI from "../../../service/pages/user-management"

const ModalEditHistory = () => {
    const {
        dataSelected,

        showModalEditHistory,
        setShowModalEditHistory
    }                                             = useContext(ReportContext)

    const [isLoading, setIsLoading]               = useState(false)
    const [editHistoryData, setEditHistoryData]   = useState(null)


    const getData = async () => {
        setIsLoading(true)
       
        const params = {
            report_id : dataSelected?.report_id
        }

        const getEditHistory   = await reportAPI.getReportEditHistory(params)
        const editHistoryData_ = getEditHistory?.results

        //reformat data
        if (editHistoryData_ && editHistoryData_.length > 0) {
            const newData_ = []

            await Promise.all(
                editHistoryData_.map(async (data) => {
                    const getUserData_  = await userManagementAPI.getUserManagement({user: parseInt(data.created_by)})
    
                    const obj_          = {}
                    obj_.created_by     = getUserData_?.results[0]
                    obj_.title          = "Disunting"
                    obj_.created_at     = data.created_at
                    obj_.color          = "secondary"

                    newData_.push(obj_)
                })
            )

            setEditHistoryData(newData_)
        } else {
            setEditHistoryData([])
        }

        setIsLoading(false)
    }

    return (
        <>

            <Modal
                isOpen   = {showModalEditHistory}
                centered = {true}
                onOpened = {() => { getData() }}
            >
                <ModalHeader
                    toggle = {() => {
                        setShowModalEditHistory(false)
                    }}
                >
                    Riwayat Penyuntingan
                </ModalHeader>
                <ModalBody
                    className="p-3"
                >
                    <div className="mb-3 text-center">
                        {(dataSelected && "nomor_surat" in dataSelected) ? `Nomor Surat : ${dataSelected?.nomor_surat}` : null} 
                    </div>
                    <div className="text-center">
                        {
                            isLoading ? 
                                <Spinner/>
                            :
                                editHistoryData === null ?
                                    <CustomTableEmptyData/>
                                :
                                    <Timeline data={editHistoryData}/>
                        }
                    </div>

                </ModalBody>
            </Modal>

        </>
    )
}

export default ModalEditHistory