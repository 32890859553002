import React, { 
    useEffect, 
    useState 
}                       from 'react'
import { 
    Accordion, 
    AccordionBody, 
    AccordionHeader, 
    AccordionItem, 
    Card, 
    CardBody, 
    Col, 
    Input, 
    InputGroup, 
    InputGroupText, 
    Row 
}                       from 'reactstrap'
import { 
    Search,
    ChevronLeft, 
    ChevronRight, 
    ChevronsLeft, 
    ChevronsRight, 
}                       from 'react-feather'

// Component
import Help             from "../../../../assets/images/banner/helpdesk.png"
import HelpSupport      from "../../../../assets/images/banner/helpdesk-support.png"


const HelpCard = ({data, getData, setActive, pagination}) => {
    const [open, setOpen] = useState('')

    const toggle = id => {
        open === id ? setOpen() : setOpen(id)
    }

    useEffect(() => {
        getData()
    }, [])
  return (
    <> 
        <Card  className='h-100'>
            <CardBody className='mt-3 h-100'>
                <Row>
                    <Col md={8}>
                        <div>
                            <InputGroup className='input-group-merge mb-2'>
                                <InputGroupText>
                                    <Search size={14} />
                                </InputGroupText>
                                <Input placeholder='search...' className='' onChange={(e) => getData({keyword : e.target.value})}/>
                            </InputGroup>
                        </div>
                        <div>
                            <Accordion className='accordion-margin' open={open} toggle={toggle}>
                                {
                                    data && data.map((item, i) => (
                                        <AccordionItem>
                                            <AccordionHeader targetId={i}>{item.title}</AccordionHeader>
                                            <AccordionBody accordionId={i}>{item.content}</AccordionBody>
                                        </AccordionItem>
                                    ))
                                }
                            </Accordion>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className='d-flex justify-content-center'>
                            <img src={Help} />
                        </div>
                        <div className='bg-primary rounded cursor-pointer' style={{ marginTop : '200px' }} onClick={() => setActive(2)}>
                            <div className='d-flex p-1'>
                                <div className='me-1'>
                                    <img src={HelpSupport} />
                                </div>
                                <div className='text-white'>
                                    Chat dengan Helpdesk Support kami untuk info lebih lanjut 
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </CardBody>
        </Card>
            <div 
                style       = {{ right : 0, bottom : 0, marginTop : '20px' }}
                className   = 'd-flex justify-content-end'
            >
                {
                    pagination && pagination.previous ? (
                        <>
                            <div 
                                onClick     = {() => getData({ page : 1 })}
                                className   = 'cursor-pointer'
                            >
                                <ChevronsLeft  />
                            </div>
                            <div 
                                onClick     = {() => getData({ page:pagination.page - 1 })}
                                className   = 'cursor-pointer'
                            >
                                <ChevronLeft  />
                            </div>
                        </>
                    ) : (
                        <>
                            <div className='text-muted'>
                                <ChevronsLeft  />
                            </div>
                            <div className=' text-muted'>
                                <ChevronLeft  />
                            </div>
                        </>
                    )
                }
                <div className='mx-4'>
                    <p>
                        {(pagination && pagination.page * pagination.page_size) - 9} - {' '} 
                        {pagination && pagination.count > 10 ? pagination.page_size * pagination.page : pagination && pagination.count} dari {' '}
                        {pagination && pagination.count}
                    </p>
                </div>
                    {
                        pagination && pagination.next ? (
                            <>
                                <div 
                                    onClick     = {() => getData({ page:pagination.page + 1 })}
                                    className   = 'cursor-pointer'
                                >
                                    <ChevronRight />
                                </div>
                                <div 
                                    onClick     = {() => getData({ page:pagination.total_page })}
                                    className   = 'cursor-pointer'
                                >
                                    <ChevronsRight />
                                </div>
                            </>
                        ) : (
                            <>
                                <div className='text-muted'>
                                    <ChevronRight />
                                </div>
                                <div className='text-muted'>
                                    <ChevronsRight />
                                </div>
                            </>
                        ) 
                    }
                </div>
    </>
    
  )
}

export default HelpCard
