export default [
    {
        title: "Tanggal",
        size: 2
    },
    {
        title: "Dibuat Oleh",
        size: 4
    },
    {
        title: "Deskripsi",
        size: 4
    },
    {
        title: "Aksi",
        size: 2
    }
]