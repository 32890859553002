import { Fragment, useContext, useEffect, useState } from "react"
import { RotateCw } from "react-feather"
import { Button, Input, InputGroup } from "reactstrap"
import ModalFormEmployee from "../modal/employee"
import { UserManagementContext } from "../../../context/userManagementContext"

const InputPenandatangan = (props) => {

	const {
		value,
		onChange,
		invalid,
		signer = true,
		setPegawaiDocx,
		placeholder = "Pilih Penandatanganan",
		className,
		disabled
	} = props

	const {
		employeeOptionSigner
	} = useContext(UserManagementContext)

	const [dataPegawai, setDataPegawai] 			= useState(null)
	const [showModalPegawai, setShowModalPegawai] 	= useState(false)

	const getPegawai = (id) => {
		const selected = employeeOptionSigner?.filter((dt) => dt.value === parseInt(id))
		if (selected?.length > 0) {
			return selected[0]
		} else {
			return null
		}
	}

	useEffect (() => {

		if (dataPegawai) {
			onChange(dataPegawai)
		}

	}, [dataPegawai])

	useEffect(() => {

		if (value) {
			setDataPegawai(value)
		}

	}, [value])


	return (
		<Fragment>
			{/* modal penandatangan */}
			<ModalFormEmployee
				data		= {dataPegawai}
				show		= {showModalPegawai}
				signer		= {signer}
				dataPegawai	= {(pegawai) => {
					setDataPegawai(pegawai)
					if (setPegawaiDocx) {
						setPegawaiDocx(pegawai)
					}
				}}
				onClose		= {() => setShowModalPegawai(!showModalPegawai)}
			/>

			<InputGroup id="penandatangan">
				<Input
					rows		= "3"
					id			= "penandatangan"
					value		= {dataPegawai !== null ? dataPegawai?.name : getPegawai(value)?.label}
					// invalid		= {invalid}
					className 	= {`${className ? className : ""} ${invalid ? 'border-danger' : ''}`}
					readOnly
					placeholder	= {placeholder}
				/>
				<Button.Ripple
					style={{
						zIndex: "0",
					}}
					color="primary"
					onClick={() => {
						setShowModalPegawai(!showModalPegawai)
					}}
					disabled = {disabled}
					id		 = "selectSigner"
				>
					<RotateCw size={16} /> Ubah
				</Button.Ripple>
			</InputGroup>
			<strong>	
				<small>
					Pangkat: {dataPegawai?.grade?.name}
				</small>
			</strong>
			<br/>
			<strong>
				<small>
					Jabatan: {dataPegawai?.position?.name}
				</small>
			</strong>
			<br/>
		</Fragment>
	)
}

export default InputPenandatangan