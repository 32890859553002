import {get, delete_, post, put}  from '../../index'

//get
const getGuide               = (params) => get('/helpdesk/guide', params)
const getTicket               = (params) => get('/helpdesk/ticket?page_size=100', params)
const getMessage               = (params) => get('/helpdesk/message?sort=asc&page_size=100', params)
const getGuideCategory       = () => get('/helpdesk/guide-category')

//post
const createGuide            = (formData) => post(`/helpdesk/guide/create`, formData)
const createTicket               = (params) => post('/helpdesk/ticket/create', params)
const createMessage               = (params) => post('/helpdesk/message/create', params)
const createAttachment            = (formData) => post(`/helpdesk/attachment/create`, formData)

//put
const updateGuide            = (id, formData) => put(`/helpdesk/guide/update/${id}`, formData)
const updateTicket            = (id, formData) => put(`/helpdesk/ticket/update/${id}`, formData)
const updateMessage            = (id, formData) => put(`/helpdesk/message/update/${id}`, formData)

//delete
const deleteGuide            = (code) => delete_(`/helpdesk/guide/delete/${code}`)
const deleteTicket            = (code) => delete_(`/helpdesk/ticket/delete/${code}`)
const deleteMessage            = (code) => delete_(`/helpdesk/message/delete/${code}`)


const HelpDeskAPI = {
    // Get
    getGuide,
    getTicket,
    getMessage,
    getGuideCategory,
   
    // Post
    createGuide,
    createTicket,
    createMessage,
    createAttachment,
    
    // Put
    updateGuide,
    updateTicket,
    updateMessage,

    // Delete
    deleteGuide,
    deleteTicket,
    deleteMessage,
}

export default HelpDeskAPI