import { useEffect, useState }                  from "react"
import DataTablesGlobal                         from "../../../@core/components/data-tables-global"
import ModalField                               from "../../other/ModalField"
import ModalSorting                             from "../../other/ModalSorting"
import DropdownTableGlobal                      from "../../../@core/components/data-tables-global/dropdown"
import CardCreatedBy                            from "../../../@core/components/card-createdby"
import ModalSortingRecapitulation               from "./ModalSortingRecapitulation"
import RecapitulationFilter                     from "./RecapitulationFilter"
import FeatureAPI from "../../../service/pages/feature"
import NotAuthorized from "../../../@core/components/not-authorized"

const RecapitulationTable = ({
    data,
	params,
	getData,
	loading,
	setParams,
	pagination,
	setIsCreateModalVisible,
	setIsUpdateModalVisible,
	setIsDeleteModalVisible,
    setIsDetailModalVisible,

    handleDetail,
	
    selected,
	setSelected,
	clearSelected,
	setClearSelected
}) => {
    
    const [hideColumn, setHideColumn]                       = useState([])
    const [isFilterModalVisible, setIsFilterModalVisible]   = useState(false) 
    const [isSortingModalVisible, setIsSortingModalVisible] = useState(false) 
    const [isHeaderConfigVisible, setIsHeaderConfigVisible] = useState(false) 
    const [accessStatus, setAccessStatus]                   = useState(null)

    const [sort, setSort]           = useState("-")
    const [sort_by, setSort_by]     = useState("-")

    const header        = [      
        {
			omit            : hideColumn[0] === false,
			name            : 'No',
            width           : '50px',
			center          : false,
			wrap            : true,
            compact         : true,
			selector        : row => row.index,
			sortable		: true
		},
		{
			omit            : hideColumn[1] === false,
			name            : 'Judul',
			center          : false,
			wrap            : true,
            compact         : true,
			selector        : row => row.title,
            cell            : row => (
                <div
                    className   = "text-start me-3"
                >
                    {row.title}
                </div>
            ),
			sortable		: true
		},
        {
			omit            : hideColumn[2] === false,
			name            : 'Dibuat Oleh',
			unique_key      : 'info',
			cell            : (row) => (
                <div
                    style   = {{
                        paddingTop    : '8px',
                        paddingBottom : '8px'
                    }}
                >
                    <CardCreatedBy
                        data = {row?.user}
                        style = {{width: '250px'}}
                    />
                </div>
			),
			width           : '250px',
			center          : true,
			compact         : true,
			selector        : row => row.action,
		},
        {
			omit            : hideColumn[3] === false,
			name            : 'Aksi',
			center          : true,
			wrap            : true,
            compact         : true,
			cell            : row => (
                <>
                    <DropdownTableGlobal
                        data 		        = {data}
						row 		        = {row}
						showDetail          = {handleDetail}
						showEdit 	        = {setIsUpdateModalVisible}
						showDelete 	        = {setIsDeleteModalVisible}
						setSelected         = {setSelected}
						setClearSelected    = {setClearSelected}
                        isHideEdit          = {true}
                        isHideDelete        = {accessStatus ? !accessStatus?.can_delete : false}
                    />
                </>
            ),
			sortable		: true
		}
	] 

    const getAccessFeatures = async() => {
        const params = {
            menu_id : 98
        }

        const getFeatureAPI = await FeatureAPI.getFeatures(params)
        const features_     = getFeatureAPI?.results[0]

        setAccessStatus(features_)

        console.log("getAccessFeatures", features_)
    }

    const renderTable = () => {
        return (
            <DataTablesGlobal
                data               		= {data}
                limit              		= {pagination?.page_size}
                header             		= {header}
                params             		= {params}
                getData            		= {getData}
                loading            		= {loading}
                selected           		= {selected}
                totalData          		= {pagination?.count}
                setParams          		= {setParams}
                setSelected        		= {setSelected}
                toggledClearRows   		= {clearSelected}
                setIsCreateModalVisible = {setIsCreateModalVisible}
				setIsDeleteModalVisible = {setIsDeleteModalVisible}
				setIsFilterModalVisible = {setIsFilterModalVisible}
                setIsSortingModalVisible= {setIsSortingModalVisible}
                setIsHeaderConfigVisible= {setIsHeaderConfigVisible}
                visibility  	        = {{
                    create          : accessStatus ? accessStatus?.can_create : true,
                    filter          : true,
                    header_config   : true,
					delete			: accessStatus ? accessStatus?.can_delete : true,
                    print           : false,
                    export          : false,
                    sorting         : true
                }}
            />
        )
    }
    useEffect(() => {
		setHideColumn(() => header.map(() => true))

        getAccessFeatures()
	}, [])

    return (
        <>

            {
                accessStatus ? (
                    accessStatus?.can_read ? (
                        renderTable()
                    ) : <NotAuthorized />
                ) : (
                    renderTable()
                )
            }

            <ModalField
                show        	= {isHeaderConfigVisible}
                setShow     	= {setIsHeaderConfigVisible}
                size        	= "sm"
                title       	= "Select"
                data        	= {header}
				hideColumn  	= {hideColumn}
				setHideColumn 	= {setHideColumn}
            />

            <ModalSortingRecapitulation
                show        	= {isSortingModalVisible}
                setShow     	= {setIsSortingModalVisible}
                size        	= "sm"
                title       	= "Sorting"
                getData         = {getData}
                sort            = {sort}
                setSort         = {setSort}
                sort_by         = {sort_by}
                setSort_by      = {setSort_by}
                data        	= {[
                    {
                        name    : "Judul",
                        value   : "title"
                    },
                    {
                        name    : "Bulan - Tahun",
                        value   : "period"
                    },
                    {
                        name    : "Tanggal Awal",
                        value   : "start_date"
                    },
                    {
                        name    : "Tanggal Akhir",
                        value   : "end_date"
                    },
                    {
                        name    : "Dibuat Pada",
                        value   : "created_at"
                    },
                ]}
            />

            <RecapitulationFilter
                show        	= {isFilterModalVisible}
                setShow     	= {setIsFilterModalVisible}
                size        	= "md"
                getData         = {getData}
            />
            
        </>
    )
}

export default RecapitulationTable