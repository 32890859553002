import { 
    useState,
    createContext,
    useEffect
}                       from "react"

//Context
const GlobalContext  = createContext(null)

import reportAPI from "../service/report"

const GlobalProvider = ({ children }) => {

    //State
    const [sidebarCount, setSidebarCount] = useState(null)

    const getDataReportCount = async() => {
        
        const params = {
            status : 1
        }

        try {
            const getReportCount = await reportAPI.getReportCountStatus(params)
            setSidebarCount(getReportCount)
        } catch (error) {
            setSidebarCount([])
        }

    }

    useEffect(() => {
        if (localStorage.getItem('userData')) {
            getDataReportCount()
        }
    }, [])

    return <GlobalContext.Provider
        value={{
            sidebarCount
        }}
    > 
        {children}
    </GlobalContext.Provider>
}

export { GlobalContext, GlobalProvider }