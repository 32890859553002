import { offset } from "@popperjs/core"
import reportAPI from "../../service/report"
import getReportIdIn from "./in"

const getReportId = (reportType, reportCategory) => {
	switch (reportCategory) {
		// case "LIN":
		// 	return getReportIdLIN(reportType);
		// case "RIN":
		// 	return getReportIdRin(reportType);
		case "IN":
			return getReportIdIn(reportType)
		// case "DIN":
		// 	return getReportIdDin(reportType);
	}
}

export const getReportField = (formData, reportTypeField) => {

	const finalResult_   = []
	const keys_      	= Object.keys(formData)

	if (reportTypeField !== null) {
		keys_.map((data, index) => {

			reportTypeField.find((field) => (
				(field.name.replace(/-/g, '_') === data) && (
					(Array.isArray(formData?.[keys_[index]])) ?
						formData?.[keys_[index]].map((val) => (
							finalResult_.push({
								field  : field.id,
								value : val
							})
						))
					:
						finalResult_.push({
							field : field.id,
							value : formData?.[keys_[index]] === undefined ? "" : formData?.[keys_[index]] 
						})
				
				)
			))
		})
	}


	return finalResult_
}

export default getReportId