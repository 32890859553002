import {get, delete_, post, put, patch}  from '../../index'


const getSiaccFeeds                   = (params) => get(`/report/siacc`, params)

const SiaccAPI = {
    //Get
    getSiaccFeeds,
}

export default SiaccAPI