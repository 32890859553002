const statusPenyelesaianOptionLin5 = [
    {
        value   : "Selesai - Dikirim Ke Instansi Lain",
        label   : "Selesai - Dikirim Ke Instansi Lain"
    },
    {
        value   : "Selesai - Ditutup",
        label   : "Selesai - Ditutup"
    },
    {
        value   : "Selesai - Diserahkan Ke Bidang Lain",
        label   : "Selesai - Diserahkan Ke Bidang Lain"
    },
    {
        value   : "Selesai - Draft",
        label   : "Selesai - Draft"
    },
]

export default statusPenyelesaianOptionLin5