import {get, post, put, delete_, getBlob, getBlobAnywhere}  from '../../index'

//get

const getFileService        = (params) => get(`/drive/service`, params)    
const getFileServiceSource  = (code)   => getBlob(`/drive/source/${code}`)  
const getFileAnywhere       = (url)   => getBlobAnywhere(`${url}`)          

const uploadFileService     = (params) => post(`/drive/service/create`, params)

const serviceAPI = {
    getFileService,
    getFileServiceSource,
    getFileAnywhere,
    uploadFileService
}

export default serviceAPI