import { 
    useContext, 
    useEffect, 
    useState 
}                           from "react"

import { 
    Button, 
    Modal, 
    ModalBody, 
    ModalFooter, 
    ModalHeader 
}                           from "reactstrap"

import { 
    CheckCircle, 
    Edit2, 
    Slash, 
    Trash 
}                           from "react-feather"

import { RoleContext }      from "../../../../context/roleContext"

import DataTablesGlobal     from "../../../../@core/components/data-tables-global"
import ModalField           from "../../../other/ModalField"

const RoleTable = ({
    data,
	params,
	getData,
	loading,
	setParams,
	pagination,
	setIsCreateModalVisible,
	setIsUpdateModalVisible,
	setIsDeleteModalVisible,
	
    selected,
	setSelected,
	clearSelected,
	setClearSelected
}) => {
    
    const [hideColumn, setHideColumn]                       = useState([])
    const [modalIsActive, setModalIsActive]                 = useState(false) 
	const [isHeaderConfigVisible, setIsHeaderConfigVisible] = useState(false) 
    const [selectedRole, setSelectedRole]                   = useState(null)
    
    const header        = [      
        {
			omit            : hideColumn[0] === false,
			name            : 'No',
            width           : '50px',
			center          : false,
			wrap            : true,
            compact         : true,
            cell            : row => (
                <>
                    {(row.index) + ((pagination?.page - 1) * pagination?.page_size)}
                </>
            ),
			sortable		: true
		},
		{
			omit            : hideColumn[1] === false,
			name            : 'Nama',
			center          : false,
			wrap            : true,
            compact         : true,
			selector        : row => row.name,
			sortable		: true
		},
        {
			omit            : hideColumn[2] === false,
			name            : 'Role',
			center          : false,
			wrap            : true,
            compact         : true,
			selector        : row => row.role_display,
			sortable		: true
		},
        {
			omit            : hideColumn[3] === false,
			name            : 'Aksi',
			center          : true,
			wrap            : true,
            compact         : true,
			selector        : row => (
                <>
                    <Button
                        color       = "light"
                        size        = "sm"
                        onClick     = {() => {
                            setClearSelected(true)
                            setSelected([row])
                            setIsUpdateModalVisible(true)
                        }}
                    >
                        <Edit2
                            size        = {17}
                            className   = "text-primary"
                        />
                    </Button>

                    <Button
                        color   = "light"
                        size    = "sm"
                        onClick = {() => {
                            setSelectedRole(row)
                            setModalIsActive(true)
                        }}
                    >
                        {
                            !row?.is_active ? (
                                <Slash
                                    size        = {17}
                                    className   = "text-danger"
                                />
                            ) : (
                                <CheckCircle
                                    size        = {17}
                                    className   = "text-primary"
                                />
                            )
                        }
                    </Button>

                    <Button
                        color       = "light"
                        size        = "sm"
                        onClick     = {() => {
                            setClearSelected(true)
							setSelected([row])
                            setIsDeleteModalVisible(true)
                        }}
                    >
                        <Trash
                            size        = {17}
                            className   = "text-primary"
                        />
                    </Button>
                </>
            ),
			sortable		: true
		}
	] 

    const {
        updateRoleStatus
    } = useContext(RoleContext)

    const handleUpdateStatus = () => {
        const action = () => {
            setModalIsActive(false)
            getData()
        }

        const menu_ids      = []
        const feature_ids   = []

        selectedRole?.menus?.map((dt) => {
            menu_ids.push(dt?.id)
        })

        selectedRole?.features?.map((dt) => {
            feature_ids.push(dt?.id)
        })

        const payload = {
            name        : selectedRole?.name,
            role        : selectedRole.role_display,
            menu_ids    : menu_ids,
            feature_ids : feature_ids,
            role        : selectedRole?.role,
            is_active   : !selectedRole?.is_active
        }

        updateRoleStatus(selectedRole?.id, payload, action)
    }

    useEffect(() => {
		setHideColumn(() => header.map(() => true))
	}, [])

    return (
        <>
            <DataTablesGlobal
                data               		= {data}
                limit              		= {pagination?.page_size}
                header             		= {header}
                params             		= {params}
                getData            		= {getData}
                loading            		= {loading}
                selected           		= {selected}
                totalData          		= {pagination?.count}
                setParams          		= {setParams}
                setSelected        		= {setSelected}
                toggledClearRows   		= {clearSelected}
				setIsCreateModalVisible = {setIsCreateModalVisible}
				setIsDeleteModalVisible = {setIsDeleteModalVisible}
                setIsHeaderConfigVisible= {setIsHeaderConfigVisible}
				visibility  	   		= {{
					filter          : true,
					delete			: true,
                    create          : true,
                    header_config   : true,
                    search 			: false
                }}
            />

            <ModalField
                show        	= {isHeaderConfigVisible}
                setShow     	= {setIsHeaderConfigVisible}
                size        	= "sm"
                title       	= "Select"
                data        	= {header}
				hideColumn  	= {hideColumn}
				setHideColumn 	= {setHideColumn}
            />

            <Modal
                isOpen      = {modalIsActive} 
                toggle      = {() => { setModalIsActive(!modalIsActive) }} 
                size        = "sm"
                className   = "modal-dialog-centered"
            >
                <ModalHeader
                    toggle  = {() => setModalIsActive(!modalIsActive)} 
                > 
                    {selectedRole?.is_active ? "Non-Aktifkan Role" : "Aktifkan Role"}
                </ModalHeader>
                <ModalBody>
                    <div className="text-center">
                        Yakin Anda ingin {selectedRole?.is_active ? "Non-Aktifkan Role" : "Aktifkan Role"}<br/>
                        <strong>{selectedRole?.name}</strong>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="d-flex justify-content-between col-12">
                        <Button
                            outline
                            color   = "primary"
                            onClick = {() => {
                                setModalIsActive(false)
                            }}
                        >
                            Batal
                        </Button>
                        <Button
                            color   = "primary"
                            onClick = {() => {
                                handleUpdateStatus()
                            }}
                        >
                            Ya
                        </Button>
                    </div>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default RoleTable