import { 
    Card, 
    CardBody 
} from "reactstrap"

const Kepja = () => {
    return (
        <>
            <Card>
                <CardBody
                    style       = {{
                        height : '750px'
                    }}
                >
                    <iframe 
                        className   = "col-12 h-100" 
                        src         = "https://media.neliti.com/media/publications/240102-aplikasi-computer-assisted-test-cat-pada-6b6b2858.pdf" 
                        
                    />
                </CardBody>
            </Card>
        </>
    )
}

export default Kepja