import { 
    useState,
    createContext,
    useEffect
}                       from "react"

//Context
const UserManagementContext  = createContext(null)

//API
import userManagementAPI from "../service/pages/user-management"

const UserManagementProvider = ({ children }) => {

    //State
    const [listData, setListData]               = useState([])
    const [isLoading, setIsLoading]             = useState(false)
    const [pagination, setPagination]           = useState(false)
    const [employeeOption, setEmployeeOption]   = useState([])
    const [employeeList, setEmployeeList]       = useState([])
    const [employeeOptionSigner, setEmployeeOptionSigner] = useState([])
    
    //Get data employee (user management)
    const getData = (params) => {
        setIsLoading(true)
        
        userManagementAPI.getUserManagement(params).then(
            res => {

                setIsLoading(false)

                const formatData_ = []

                if (res.count !== 0) {
                    res.results.map((data, idx) => {
                        data.index  = idx + 1
                        data.eselon = "Non Eselon"
                        formatData_.push(data)
                    })
                }

                const pagination = {
                    count       : res.count,
                    page        : res.page,
                    page_size   : res.page_size,
                    next        : res.next,
                    previous    : res.previous,
                    total_page  : res.total_page
                }
                
                setListData(formatData_)
                setPagination(pagination)
            }
        ).catch(
            err => {
                console.log(err, 'err')
            }
        )
    }

    const getDataAll = () => {
        
        userManagementAPI.getUserManagement({
            page_size : 100
        }).then(
            res => {

                const formatData_ = []

                if (res.count !== 0) {
                    res.results.map((data) => {
                        formatData_.push({
                            label : data?.name,
                            value : data?.id,
                            user_id : data?.user_id,
                            grade : data?.grade,
                            position : data?.position
                        })
                    })

                    setEmployeeOption(formatData_)
                }

            }
        ).catch(
            err => {
                console.log(err, 'err')
            }
        )
    }

    const getDataSearch = (keyword, page) => { 
        userManagementAPI.getUserManagement({
            keyword     : keyword,
            page        : page,
            page_size   : 10
        }).then(
            res => {
                const formatData_ = []

                if (res.count !== 0) {
                    res.results.map((data) => {
                        formatData_.push({
                            label : data?.name,
                            value : data?.id,
                            user_id : data?.user_id,
                            grade : data?.grade,
                            position : data?.position
                        })
                    })

                    setEmployeeList({
                        data        : formatData_,
                        pagination  : {
                            count       : res?.count,
                            next        : res?.next,
                            page        : res?.page,
                            page_size   : res?.page_size,
                            previous    : res?.previous,
                            total_page  : res?.total_page
                        }  
                    })
                }

            }
        ).catch(
            err => {
                console.log(err, 'err')
            }
        )
    }

    const getDataAllSigner = () => {
        
        userManagementAPI.getUserManagement({
            page_size : 100,
            signer : true,
        }).then(
            res => {

                const formatData_ = []

                if (res.count !== 0) {
                    res.results.map((data) => {
                        formatData_.push({
                            label : data?.name,
                            value : data?.id,
                            grade : data?.grade,
                            position : data?.position
                        })
                    })

                    setEmployeeOption(formatData_)
                }

            }
        ).catch(
            err => {
                console.log(err, 'err')
            }
        )
    }

    const getDetailData = (id) => {

        const params = {
            id : id
        }

        userManagementAPI.getUserManagement(params).then(
            res => {
                if (res.results) {
                    return res.results[0]
                }
            }
        )
    }

    useEffect(() => {
        if (localStorage.getItem('userData')) {
            getDataAll()
            getDataAllSigner()
        }
    }, [])

    return <UserManagementContext.Provider
        value={{
            listData,
            setListData,

            pagination,
            setPagination,

            isLoading,
            setIsLoading,

            getData,
            getDataSearch,
            employeeOption,
            employeeOptionSigner,
            employeeList,
            getDetailData
        }}
    > 
        {children}
    </UserManagementContext.Provider>
}

export { UserManagementContext, UserManagementProvider }