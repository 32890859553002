// ** Navigation imports
import IN                           from './in'
import LIN                          from './lin'
import RIN                          from './rin'
import DIN                          from './din'
import Dashboard                    from './dashboard'
import GIS                          from './gis'
import Recapitulation               from './recapitulation'
import SIACC                        from './siacc'
import DataManagement               from './data-management'
import Ranking                      from './ranking'
import OrganizationStructure        from './organization-structure'
import Helpdesk                     from './helpdesk'
import Configuration                from './configuration'

// ** Merge & Export
export default [
    ...Dashboard,
    ...IN, 
    ...LIN, 
    ...RIN, 
    ...DIN,
    ...GIS,
    ...Recapitulation,
    ...SIACC,
    ...DataManagement,
    ...Ranking,
    ...OrganizationStructure,
    ...Helpdesk,
    ...Configuration
]