import React, { Fragment, useEffect, useState } from 'react'
import Avatar from '../../../components/avatar'
import { useNavigate } from 'react-router-dom'
import Helper from '../../../../helper'
import userManagementAPI from '../../../../service/pages/user-management'
import { Col, Row } from 'reactstrap'

const ListRoom = ({
    item,
    index,
    active,
    setSelected
}) => {

    const userData                              = JSON.parse(localStorage.getItem('userData'))

    const { getTimeAgo }                        = Helper

	const navigate 							    = useNavigate()

    const [roomName, setRoomName]               = useState(null)


    const getDetailData = (id) => {
        const params = {
            user : id
        }

        userManagementAPI.getUserManagement(params).then(
            res => {
                setRoomName(res.results[0]) 
            }
        )
    }

    useEffect(() => {
        if (item && active && item?.room_type === 1) {
            getDetailData(item?.members?.length < 3 && item?.members?.find((data) => data !== userData.user_id))
        }

    }, [item, active])


  return (
    <a
        key			= {index}
        onClick		= {e => {
            setSelected(Object.assign({}, item, { employeeName: roomName?.name }))
            navigate(`/chat`)
        }}
       
    >
        <div
            className={`${item.is_read === false ? "bg-light-secondary border-light" : ""} list-item w-100`}
        >
            {!item.switch ? (
                <Fragment>
                    <Row className={`w-100`}>
                        <Col xs={9} className='d-flex justify-content-start align-items-center'>
                            <Avatar 
                            // onError={fallbackImage_} 
                                img         = {!roomName?.photo ? `https://ui-avatars.com/api/?name=${item ? roomName?.name : "UN"}&background=4e73df&color=fff&bold=true` : roomName?.photo} 
                                imgWidth    = '40'
                                imgHeight   = '40'  
                                className   = 'me-1'
                            />
                            <div style={{wordBreak : 'break-all'}}>
                                <p className='p-0 m-0 fw-bolder'>
                                    {item?.members?.length > 2 ? item.name : roomName?.name}
                                </p>
                                <small className='notification-text'>
                                    {
                                        item?.last_message?.content?.split(' ').length <= 50  ?
                                            item?.last_message?.content
                                        : 
                                            item?.last_message?.content.substr(0, item?.last_message?.content.lastIndexOf(' ', 50))
                                    }
                                </small>
                            </div>
                        </Col>
                        <Col xs={3}>
                            <p style={{ fontSize :'12px' }}>
                                {item?.last_message ? getTimeAgo(item?.last_message?.created_at ? item?.last_message?.created_at : new Date()) : null}
                            </p>
                        </Col>

                    </Row>
                    {/* <div className='list-item-body flex-grow-1'>
                        <div className='d-flex justify-content-between align-items-center p-0'>
                            <p className='p-0 m-0 fw-bolder' style={{wordBreak : 'break-all'}}>
                                {item?.members?.length > 2 ? item.name : roomName?.name}
                            </p>
                            <p style={{ fontSize :'12px' }}>
                                {item?.last_message ? getTimeAgo(item?.last_message?.created_at ? item?.last_message?.created_at : new Date()) : null}
                            </p>
                        </div>
                        <small className='notification-text'>
                        {
                            item?.last_message?.content?.split(' ').length <= 50  ?
                                item?.last_message?.content
                            : 
                                item?.last_message?.content.substr(0, item?.last_message?.content.lastIndexOf(' ', 50))
                        }
                        </small>
                    </div> */}
                </Fragment>
            ) : (
                <Fragment>
                    {item.title}
                    {item.switch}
                </Fragment>
            )}
        </div>
    </a>
  )
}

export default ListRoom