import { 
    Modal, 
    Button, 
    ListGroup, 
    ModalBody, 
    ModalHeader, 
    ModalFooter, 
    ListGroupItem
}                           from "reactstrap"

const ModalDelete = (props) => {

    const {
        data,
        show,
        size,
        title,
        setShow,
        handleDelete,
        type
    }                       = props

    return (
        <>
            <Modal
                size    = {size || "sm"}
                isOpen  = {show} 
                toggle  = {() => { setShow(!show) }} 
                centered= {true}
            >
                <ModalHeader
                    toggle={() => setShow(!show)} 
                > 
                    {title}
                </ModalHeader>
                <ModalBody>
                    <div className="text-center mb-1">
                        Yakin Ingin Menghapus Data Berikut ?
                    </div>
                    <div>
                        <ListGroup className="m-0">
                        {
                            data !== null && data?.length > 0 &&
                                data?.map((dt, idx) => {
                                    return (
                                        <ListGroupItem key={`moda_delete_list_${idx}`}>
                                            {idx + 1}. 
                                            {
                                                type === "recyle_bin" ? 
                                                    dt?.sumber_surat?.judul ? dt?.sumber_surat?.judul
                                                    : dt?.judul ? dt?.judul
                                                    : dt?.sumber_surat?.judul ? dt?.sumber_surat?.judul
						                            : dt?.judul ? dt?.judul : "-"
                                                : dt?.name ?
                                                    dt?.name
                                                    : dt?.title ?
                                                        dt?.title
                                                        : dt?.report_type_name
                                            }
                                        </ListGroupItem>
                                    )
                                })
                        }
                        </ListGroup>
                    </div>
                </ModalBody>
                <ModalFooter> 
                    <div
                        className   = "d-flex justify-content-between col-12"
                    >
                        <Button
                            color   = "primary"
                            outline
                            onClick = {() => {
                                setShow(false)
                            }}
                        >
                            Batal
                        </Button>

                        <Button
                            color   = "primary"
                            onClick = {() => {
                                setShow(false)
                                handleDelete()
                            }}
                        >
                            Hapus
                        </Button>
                    </div>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default ModalDelete