import { 
    Button,
    Card,
    CardBody,
    Form,
    Input,
    Modal, 
    ModalBody, 
    ModalFooter,
    ModalHeader
}                       from "reactstrap"

import { 
    Controller, 
    useForm 
}                       from "react-hook-form"

import { 
    useState,
    useEffect,
    useContext
}                       from "react"

import { 
    selectThemeColors 
}                       from "../../../utility/Utils"

import { 
    WorkunitContext
}                       from "../../../context/workunitContext"

import { 
    UserManagementContext
}                       from "../../../context/userManagementContext"

import { 
    yupResolver
}                       from "@hookform/resolvers/yup"

import { Search }                               from "react-feather"

import Select                                   from "react-select"
import RecapitulationFilterValidationPeriode    from "./RecapitulationFilterValidationPeriode"
import RecapitulationFilterValidationUser       from "./RecapitulationFilterValidationUser"
import RecapitulationFilterValidationWorkunit   from "./RecapitulationFilterValidationWorkunit"
import CustomTablePaginate                      from "../../../@core/components/custom-table/paginate"

const RecapitulationFilter = ({
    show, 
    setShow,
    size,
    getData
}) => {

    const [type, setType]    = useState("")

    const {
		reset,
		control,
		setValue,
		setError,
		clearErrors,
        watch,
        getValues,
		handleSubmit,
		formState: { errors },
	} = useForm({
		mode        : "onChange",
        resolver    : yupResolver(
            type.value  === "user" ?
                RecapitulationFilterValidationUser
            : type.value  === "month_year" ?
                RecapitulationFilterValidationPeriode
            : type.value  === "workunit" ?
                RecapitulationFilterValidationWorkunit
            : null
        )
	})

    const [monthOption, setMonthOption]     = useState([])
    const [yearOption, setYearOption]       = useState([])
    const [keyword, setKeyword]             = useState("")
    const [tempKeyword, setTempKeyword]     = useState("")

    const {
        workunitOption
    } = useContext(WorkunitContext)

    const {
        employeeOption,
        getDataSearch,
        employeeList
    } = useContext(UserManagementContext)

    const generateYearOption = () => {
        const tahunSekarang = new Date().getFullYear()
        const tahunAwal     = 2023
        const dataTahun       = []

        for (let tahun = tahunSekarang; tahun >= tahunAwal; tahun--) {
            dataTahun.push({
                label: tahun.toString(),
                value: tahun
            })
        }

        setYearOption(dataTahun)
    }

    const generateMonthOption = () => {
        const listYearNumber    = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
        const listYearName      = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"]
        const dataBulan         = []

        listYearNumber.map(async (dt, idx) => {
            dataBulan.push({
                label : `${listYearName[idx]}`,
                value : dt
            })
        })
        
        setMonthOption(dataBulan)
    }

    const onSubmit = (formData) => {

        const payload = {

        }

        if (type.value === "month_year") {
            payload.month           = formData.month?.value
            payload.year            = formData.year?.value

            payload.user            = null
            payload.work_unit_id    = null
        } else if (type.value === "user") {
            payload.user            = formData.user?.user_id

            payload.month           = null
            payload.year            = null
            payload.work_unit_id    = null
        } else if (type.value === "workunit") {
            payload.work_unit_id    = formData.work_unit_id?.value

            payload.month           = null
            payload.year            = null
            payload.user            = null
        }

        getData(payload)
        setShow(false)
        console.log("onSubmit", type.value, payload)
    }

    const onReset = () => {
        const payload = {
            isReset : true
        }

        reset()
        setType("")

        getData(payload)
        setShow(false)
    }

    useEffect(() => {
        generateMonthOption()
        generateYearOption()

        getDataSearch(keyword, 1)
    }, [])

    return (
        <>
            <Modal
                isOpen  = {show} 
                toggle  = {() => { setShow(!show) }} 
                size    = {size || "sm"}
            >
                <ModalHeader
                    toggle={() => setShow(!show)} 
                > 
                    Filter Rekap Data
                </ModalHeader>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <ModalBody>
                        
                        <div
                            className="col-12"
                        >
                            <div className="col-12 mb-1">
                                <div className=""><strong>Pilih Filter</strong></div>
                                <Select
                                    className           = {`react-select`}
                                    classNamePrefix     = "select"
                                    placeholder         = "Pilih Filter"
                                    options             = {[
                                        {
                                            label   : "Dibuat Oleh",
                                            value   : "user"
                                        },
                                        {
                                            label   : "Bulan & Tahun",
                                            value   : "month_year"
                                        },
                                        {
                                            label   : "Satuan Kerja",
                                            value   : "workunit"
                                        },
                                    ]}
                                    onChange            = {(e) => {
                                        setType(e)
                                    }}
                                    value               = {type}
                                />
                            </div>

                            {
                                type.value === "month_year" && 
                                <>
                                    <div className="col-12 mb-1">
                                        <div className=""><strong>Pilih Bulan</strong></div>
                                        <Controller
                                            name        = "month"
                                            control     = {control}
                                            render      = {({ field }) => (
                                                <Select
                                                    {...field} 
                                                    className           = {`react-select ${errors.month ? "is-invalid" : ""}`}
                                                    classNamePrefix     = "select"
                                                    placeholder         = "Pilih Bulan"
                                                    options             = {monthOption}
                                                />
                                            )}
                                        />
                                        {errors.month && <div className="text-xs text-danger">{errors.month?.value?.message}</div>}
                                    </div>
                                    
                                    <div className="col-12 mb-1">
                                        <div className=""><strong>Pilih Tahun</strong></div>
                                        <Controller
                                            name        = "year"
                                            control     = {control}
                                            render      = {({ field }) => (
                                                <Select
                                                    {...field} 
                                                    className           = {`react-select ${errors.year ? "is-invalid" : ""}`}
                                                    classNamePrefix     = "select"
                                                    placeholder         = "Pilih Tahun"
                                                    options             = {yearOption}
                                                />
                                            )}
                                        />
                                        {errors.year && <div className="text-xs text-danger">{errors.year?.value?.message}</div>}
                                    </div>
                                </>
                            }

                            {
                                type.value === "workunit" &&
                                <>
                                    <div className="col-12">
                                        <div className=""><strong>Pilih Satuan Kerja</strong></div>
                                        <Controller
                                            name        = "work_unit_id"
                                            control     = {control}
                                            render      = {({ field }) => (
                                                <Select
                                                    {...field} 
                                                    className           = {`react-select ${errors.work_unit_id ? "is-invalid" : ""}`}
                                                    classNamePrefix     = "select"
                                                    placeholder         = "Pilih Satuan Kerja"
                                                    options             = {workunitOption}
                                                    theme               = {selectThemeColors}
                                                />
                                            )}
                                        />
                                        {errors.work_unit_id && <div className="text-xs text-danger">{errors.work_unit_id?.value?.message}</div>}
                                    </div>
                                </>
                            }

                            {
                                type.value === "user" &&
                                <>
                                    <div className="col-12">
                                        <div className=""><strong>Pilih Pengguna</strong></div>
                                        <div
                                            className   = "d-flex"
                                        >
                                            <Input
                                                size        = "md"
                                                placeholder = "Cari Pengguna"
                                                className   = "me-1"
                                                onChange    = {(e) => {
                                                    setTempKeyword(e?.target?.value)
                                                }}
                                            />

                                            <Button
                                                size    = "md"
                                                onClick = {() => {
                                                    setKeyword(tempKeyword)
                                                    getDataSearch(tempKeyword, 1)
                                                    setValue("user", null)
                                                }}
                                            >
                                                <Search
                                                    size    = {14}
                                                />
                                            </Button>
                                        </div>

                                        <div className="d-flex justify-content-end mt-1">
                                            <CustomTablePaginate
                                                total			= {employeeList?.pagination?.count}
                                                limit			= {10}
                                                currentPage		= {employeeList?.pagination?.page}
                                                next			= {employeeList?.pagination?.next}
                                                prev			= {employeeList?.pagination?.previous}
                                                onClick			= {(value) => {
                                                    console.log("CustomTablePaginate", value)
                                                    if (value === "next") {
                                                        getDataSearch(keyword, employeeList?.pagination?.page + 1)
                                                    } else {
                                                        getDataSearch(keyword, employeeList?.pagination?.page - 1)
                                                    }
                                                }}
                                            />
                                        </div>
                                        <Controller
                                            name        = "user"
                                            control     = {control}
                                            render      = {({ field }) => (
                                                <ul 
                                                    className   = "list-group mt-1"
                                                >
                                                    {
                                                        employeeList?.data?.map((dt) => {
                                                            return (
                                                                <li
                                                                    className   = {`list-group-item list-group-item-action ${dt?.value === field?.value?.value ? "active" : "cursor-pointer"}`}
                                                                    onClick     = {() => {
                                                                        field.onChange(dt)
                                                                    }}
                                                                >
                                                                    {dt?.label}
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            )}
                                        />
                                        {errors.user && <div className="text-xs text-danger">{errors.user?.value?.message}</div>}
                                    </div>
                                </>
                            }

                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div
                            className       = "d-flex justify-content-between col-12"
                        >
                            <Button 
                                className   = ""
                                onClick     = {() => {
                                    // setShow(false)
                                    onReset()
                                }}
                                outline 
                            >
                                Reset
                            </Button>
                            <Button 
                                className   = ""
                                onClick     = {() => {
                                    handleSubmit()
                                }}
                                type        = "submit"
                            >
                                Simpan
                            </Button>
                        </div>
                    </ModalFooter>
                </Form>
            </Modal>
        </>
    )
}

export default RecapitulationFilter