import { 
    useEffect, 
    useState 
}                   from "react"

import { 
    ChevronLeft, 
    ChevronRight, 
    List 
}                   from "react-feather"

import {
    Button,
    Card,
    CardBody,
    Col
}                   from "reactstrap"
import Helper       from "../../../helper"
import baseURL      from "../../../service"
import serviceAPI   from "../../../service/drive/service"

import reportAPI    from "../../../service/report"

const WidgetJumlahKejadian = (props) => {

    const {
        data,
        setListMarker,
        setTypeMarker
    } = props

    const { fallbackImage_ }        = Helper

    const [bidang, setBidang]       = useState([])
    const [sektor, setSektor]       = useState([]) 
    const [active, setActive]       = useState(0)

    const handleChangeCategory = (action) => {
        if (action === "back") {
            setActive(active - 1)
        } else if (action === "next") {
            setActive(active + 1)
        }
    }

    const handleSelectMarker = (data) => {
        const temp = []
        data?.locations?.map((dt) => {
            dt.icon = `${baseURL.replace("/api", "")}${data.icon}`
            // dt.icon = "https://play-lh.googleusercontent.com/DTzWtkxfnKwFO3ruybY1SKjJQnLYeuK3KmQmwV5OQ3dULr5iXxeEtzBLceultrKTIUTr"
            temp.push(dt)
        })

        setListMarker(temp)
    }

    const renderCardResult = (dt) => {
        return (
            <>
                <small>
                    <Card 
                        color       = "light"
                        className   = "cursor-pointer"
                        style       = {{
                            marginBottom : '5px'
                        }}
                        onClick     = {() => {
                            handleSelectMarker(dt)
                            setTypeMarker("sector")
                        }}
                    >
                        <CardBody 
                            className   = "m-0"
                            style       = {{
                                padding : '8px'
                            }}
                        >
                            <div
                                className       = "d-flex justify-content-between"
                            >
                                <div
                                    className   = "d-flex me-1"
                                >
                                    <div
                                        className       = "d-flex align-items-center"
                                    >
                                        <img
                                            className   = "me-1 rounded-circle"
                                            src         = {`${baseURL.replace("/api", "")}${dt.icon}`}
                                            style       = {{
                                                width   : '30px',
                                                height  : '30px'
                                            }}
                                            onError     = {fallbackImage_}
                                        />
                                    </div>
                                    <div
                                        className       = "d-flex align-items-center"
                                    >
                                        {dt.name}
                                    </div>
                                </div>
                                <div
                                    className   = "text-center"
                                >
                                    <strong>{dt.count?.length} <br/> Berkas</strong>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </small>
            </>
        )
    }

    useEffect(() => {
        if (active !== 0) {
            if (bidang) {
                const temp = bidang[active - 1]?.sectors
                setSektor(temp)
            }
        }
    }, [active])

    useEffect(() => {
        if (data) {
            setBidang({...data})
            setActive(1)
            if (data) {
                const temp = data[active - 1]?.sectors
                setSektor(temp)
            }
        }
    }, [data])

    return (
        <>
            <Col
                className   = "h-1001 pb-2"
                style       = {{
                    height  : '400px'
                }}
            >
                <Card className="h-100 p-0 m-0">
                    <CardBody>
                        <div
                            className   = "h-100 d-flex flex-column"
                        >
                            <div
                            >
                                <div className = "d-flex mb-1">
                                    <div 
                                        className = "bg-primary rounded-circle me-1"
                                        style = {{
                                            width  : '20px',
                                            height : '20px'
                                        }}
                                    />
                                    <strong>Jumlah Kejadian Per Sektor</strong>
                                </div>
                                <div
                                    className   = "d-flex justify-content-between mb-1"
                                >
                                    <div>
                                        {
                                            active !== 1 &&
                                                <Button
                                                    color       = "light"
                                                    size        = "sm"
                                                    className   = "p-0"
                                                    onClick     = {() => {
                                                        handleChangeCategory("back")
                                                    }}
                                                >
                                                    <ChevronLeft />
                                                </Button>
                                        }
                                    </div>
                                    <div
                                        className="text-center"
                                    >
                                        {bidang[active - 1]?.name}
                                    </div>
                                    <div>
                                        {
                                            active !== Object.keys(bidang)?.length &&
                                                <Button
                                                    color       = "light"
                                                    size        = "sm"
                                                    className   = "p-0"
                                                    onClick     = {() => {
                                                        handleChangeCategory("next")
                                                    }}
                                                >
                                                    <ChevronRight />
                                                </Button>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div
                                className   = "overflow-auto flex-grow-1"
                                style       = {{
                                    maxHeight : '240px'
                                }}
                            >
                                {
                                    sektor !== null &&
                                        sektor?.map((dt) => {
                                            return (
                                                <>
                                                    {renderCardResult(dt)}
                                                </>
                                            )
                                        })
                                }
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </>
    )
}

export default WidgetJumlahKejadian