import React        from 'react'

import moment       from 'moment'

import { Button }   from 'reactstrap'

const TaskListCategory = ({
    setParams,
    reportData,
    setOpenTask,
    setReportdata,
    setReportApprovemenId
}) => {
  return (
   <>
     <Button.Ripple 
        color       = 'primary' 
        outline
        onClick     = {() => { 
            setReportdata({
                report          : "IN.1",
                reportType      : "IN",
                reportTitle     : "Surat Perintah Tugas",
                reportTypeId    : "1"
            })
            setParams({ 
                page                :1,
                start_created_at    : moment().startOf('month').format('YYYY-MM-DD'),
                end_created_at      : moment().endOf('month').format('YYYY-MM-DD') 
            })
            setReportApprovemenId(null)
            setOpenTask(false)
        }
    }
        className   = {`round me-1 ${reportData?.reportTypeId === '1' && 'active'}`}
    >
        IN.1 - SP. TUG
    </Button.Ripple>
    <Button.Ripple 
        color       = 'primary' 
        outline
        onClick     = {() => {
            setReportdata({
                report          : "IN.2",
                reportType      : "IN",
                reportTitle     : "Surat Perintah Pengamanan Pembangunan Strategis",
                reportTypeId    : "2"
            })
            setParams({ 
                page                :1,
                start_created_at    : moment().startOf('month').format('YYYY-MM-DD'),
                end_created_at      : moment().endOf('month').format('YYYY-MM-DD') 
            })
            setReportApprovemenId(null)
            setOpenTask(false)

        }
        }
        className   = {`round me-1 ${reportData?.reportTypeId === '2' && 'active'}`}
    >
        IN.2 - SP. PPS
    </Button.Ripple>
    <Button.Ripple 
        color       ='primary' 
        outline
        onClick     = {() => {
            setReportdata({
                report          : "IN.3",
                reportType      : "IN",
                reportTitle     : "Surat Perintah Operasi Intelijen",
                reportTypeId    : "3"
            })
            setParams({ 
                page                :1,
                start_created_at    : moment().startOf('month').format('YYYY-MM-DD'),
                end_created_at      : moment().endOf('month').format('YYYY-MM-DD') 
            })
            setReportApprovemenId(null)
            setOpenTask(false)
            }
        }
        className   = {`round me-1 ${reportData?.reportTypeId === '3' && 'active'}`}
    >
        IN3. - SP. OPS
    </Button.Ripple>
    <Button.Ripple 
        color       = 'primary' 
        outline
        onClick     = {() => {
            setReportdata({
                report			: "IN.21",
                reportType      : "IN",
                reportTitle     : "Surat Perintah Kegiatan Penerangan Hukum",
                reportTypeId    : "21"
            })
            setParams({ 
                page                :1,
                start_created_at    : moment().startOf('month').format('YYYY-MM-DD'),
                end_created_at      : moment().endOf('month').format('YYYY-MM-DD') 
            })
            setReportApprovemenId(null)
            setOpenTask(false)
            }
        }
        className   = {`round me-1 ${reportData?.reportTypeId === '21' && 'active'}`}
    >
        IN.21 -SP. PENKUM
    </Button.Ripple>
   </>
  )
}

export default TaskListCategory