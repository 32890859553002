import { Col, Modal, ModalBody, ModalHeader, Row, Spinner } from "reactstrap"
import { ReportContext } from "../../../context/reportContext"
import { useContext, useEffect, useState } from "react"
import Timeline from "../timeline"
import CustomTableEmptyData from "../custom-table/empty"


const ModalTrackingStatus = (props) => {

    const {
        reportData
    }                    = props


    const {
        dataSelected,
        getUserDataByUser,
        showModalTrackStatus,
        setShowModalTrackStatus
    }                     = useContext(ReportContext)

    const [isLoading, setIsLoading]         = useState(false)
    const [trackingData, setTrackingData]   = useState(null)

    const reformatData = async () => {
        setIsLoading(true)
        const data_ = dataSelected?.track_status.sort((a, b) => b.id - a.id)

        const newData_ = []
    
        if (data_ && data_.length > 0) {
            await Promise.all(data_.map(async (data) => {
                const obj_ = { created_at: data.created_at, id: data.id }
                const getUserData = await getUserDataByUser(data.user)
                const userData_ = getUserData?.results[0]
                
                if (data.status_approvement === 1) {
                    obj_.title = `${reportData?.reportTitle} dibuat oleh ${userData_?.name}`
                } else if (data.status_approvement === 2) {
                    obj_.title = `${reportData?.reportTitle} disetujui oleh ${userData_?.name}`
                } else if (data.status_approvement === 3) {
                    obj_.title = `${reportData?.reportTitle} dalam proses pengerjaan oleh ${userData_?.name}`
                } else if (data.status_approvement === 4) {
                    obj_.title = `${reportData?.reportTitle} ditolak oleh ${userData_?.name}`
                } else if (data.status_approvement === 5) {
                    obj_.title = `${reportData?.reportTitle} direvisi oleh ${userData_?.name}`
                } else if (data.status_approvement === 6) {
                    obj_.title = `${reportData?.reportTitle} didisposisi oleh ${userData_?.name}`
                } else if (data.status_approvement === 7) {
                    obj_.title = `${reportData?.reportTitle} telah selesai`
                }
                
                newData_.push(obj_)
            }))
            
            setTrackingData(newData_.sort((a, b) => b.id - a.id))
        } else {
            setTrackingData([])
        }
        
        setIsLoading(false)
    }
    
    useEffect(() => {
        if (dataSelected?.track_status?.length > 0) {
            reformatData()
        }
    }, [dataSelected])

    return (
        <>
            <Modal
                // size     = "lg"
                style       = {{maxWidth: '1200px', width: 1200, height:1000}}
                isOpen      = {showModalTrackStatus}
                centered    = {true}
            >
                <ModalHeader
                    toggle = {() => {
                        setShowModalTrackStatus(false)
                    }}
                >
                    Status {reportData?.reportTitle}
                </ModalHeader>
                <ModalBody
                    className="p-3"
                >
                    <Row className="mb-2">
                        <Col md={2}>
                            No. Surat :
                        </Col>  
                        <Col md={4}>
                            <strong>{dataSelected?.nomor_surat ? dataSelected?.nomor_surat : "-"}</strong>
                        </Col>  
                        <Col md={2}>
                            Penandatangan :
                        </Col>  
                        <Col md={3}>
                            {
                                Array.isArray(dataSelected?.penandatangan) ?
                                    dataSelected?.penandatangan?.map((data, index) => (
                                        <strong>
                                            {index}. {data?.name?.toUpperCase()}
                                        </strong>
                                    ))
                                :
                                    <strong>
                                        1. {dataSelected?.penandatangan?.name?.toUpperCase()}
                                    </strong>
                            }
                        </Col>  
                    </Row>

                    <Row className="mb-2">
                        <Col md={2}>
                            Judul :
                        </Col>  
                        <Col md={4}>
                            <strong>{dataSelected?.judul ? dataSelected?.judul : "-"}</strong>
                        </Col>  
                        <Col md={2}>
                            Dibuat Oleh :
                        </Col>  
                        <Col md={4}>
                            <strong>{dataSelected?.created_by?.name ? dataSelected?.created_by?.name : "-"}</strong>
                        </Col>  
                    </Row>

                    <Row>
                        <Col md={2}>
                            Tanggal Kadaluarsa :
                        </Col>  
                        <Col md={10}>
                            <strong>
                                {dataSelected?.expired_date ? dataSelected?.expired_date : "-"}
                            </strong>
                        </Col>  
                    </Row>

                    <div className="mt-3 py-2 text-center">
                        {
                            isLoading ? 
                                <Spinner/>
                            :
                                trackingData === null ?
                                    <CustomTableEmptyData/>
                                :
                                    <Timeline data={trackingData}/>
                        }
                    </div>

                </ModalBody>
            </Modal>

        </>
    )
}

export default ModalTrackingStatus