import { 
    useContext,
    useEffect,
    useState 
}                   from "react"

import { 
    Clock, 
    CloudOff, 
    CornerUpRight, 
    Folder, 
    Link, 
    PieChart, 
    Plus, 
    Star, 
    Tag, 
    Trash2, 
    User, 
    Users, 
    X
}                   from "react-feather"

import { 
    Badge,
    Button,
    Card, 
    CardBody, 
    ListGroup, 
    ListGroupItem,
    Progress
}                   from "reactstrap"
import { DataManagerContext } from "../../../context/dataManagerContext"

const DataManagementSidebar = () => {

    const {
        size,
        active,
        listTags,
        getSize,
        getTags,
        setActive,
        setFolder,
        activeChild, 
        setSelected,
        setBreadcums,
        setShowDelete,
        setShowFormTag,
        setSelectDrive,
        setActiveChild,
        setUrlApiActive
    } = useContext(DataManagerContext)

    
    const menuTag = Object.keys(listTags).map(key => ({
        name : listTags[key].name,
        code : listTags[key].id,
        icon : <Tag size={22} />,
        child: []
      }))

    menuTag.push({
        name : "Tambah Tag Baru",
        code : "tag-add",
        icon : <Plus size={22} />,
        child: []
    })
    const listMenu = [
        {
            name : "Semua Berkas",
            code : "all",
            icon : <Folder size={22} />,
            child: []
        },
        {
            name : "Baru Dibuka",
            code : "last-open",
            icon : <Clock size={22} />,
            child: []
        },
        {
            name : "Favorit",
            code : "favorite",
            icon : <Star size={22} />,
            child: []
        },
        {
            name : "Bagikan",
            code : "share",
            icon : <CornerUpRight size={22} />,
            child: [
                {
                    name : "Dengan Yang Lain",
                    code : "shared",
                    icon : <Users size={22} />,
                    child: []
                },
                {
                    name : "Dengan Anda",
                    code : "accept",
                    icon : <User size={22} />,
                    child: []
                },
                // {
                //     name : "Dengan Link",
                //     code : "share-link",
                //     icon : <Link size={22} />,
                //     child: []
                // },
                // {
                //     name : "Terhapus",
                //     code : "share-delete",
                //     icon : <CloudOff size={22} />,
                //     child: []
                // }
            ]
        },
        {
            name : "Tag",
            code : "tags",
            icon : <Tag size={22} />,
            child: 
                menuTag
        }
    ]
        
    const renderCardListChild = (data) => {
        return (
            <>
                <ListGroupItem 
                    key={data.id}
                    onClick={() => {
                        setSelectDrive(data)
                        if (active === 'share') {
                            setActiveChild(data.code)
                            setBreadcums({ type: "create", name: data.name, url: `/share?${data.code}=true`})
                            setUrlApiActive(`/share?${data.code}=true`)
                        } else { 
                            if (data.code === 'tag-add') {
                                setShowFormTag(true)
                            } else {
                                setActiveChild(data.code)
                                setBreadcums({ type: "create", name: data.name, url: `/${active}/detail/${data.code}`})
                                setUrlApiActive(`/${active}/detail/${data.code}`)
                            }
                        }
                       
                    }}
                    className={`cursor-pointer d-flex justify-content-between rounded border-0 col-12 m-0 p-0 ${activeChild === data.code && "active"}`}
                >
                    <div className="p-1 d-flex">
                        <div className="me-1">
                        {   data.icon} 
                        </div>
                        <div>
                            {data.name}
                        </div>
                    </div>
                    {
                        active === 'tags' && data.code !== 'tag-add' ? (
                            <Button.Ripple  className='btn-icon rounded-circle' onClick={() => setShowDelete(true)} color='flat-primary'>
                                <X size={15} color="white" />
                            </Button.Ripple>
                        ) : null
                    }
                    
                </ListGroupItem>
            </>
        )
    }

    const renderCardList = (data) => {
        return (
            <>
                <ListGroupItem 
                    key={data.id}
                    className={`cursor-pointer rounded border-0 col-12 m-0 p-0 ${data.child?.length === 0 && active === data.code && "active"}`}
                    onClick={() => {
                        if (data.code === "share") {
                            setActive(data.code)
                            setActiveChild("shared")
                            setUrlApiActive(`/share?shared=true`)
                            setBreadcums({ type: "create", name: 'Dengan Yang Lain', url: `/${data.code === 'all' ? '' : data.code}`})
                        } else if (data.code === 'tags') {
                            if (listTags.length > 0) {
                               setActiveChild(listTags[0].id)
                                setUrlApiActive(`/tags/detail/${listTags[0].id}`)
                                setActive(data.code)
                            } else {
                                setActiveChild('tag-add')
                                setActive(data.code)
                            }
                        } else {
                            setSelected([])
                            setActive(data.code)
                            setBreadcums({ type: "create", name: data.name, url: `/${data.code === 'all' ? '' : data.code}`})
                            setUrlApiActive(`/${data.code === 'all' ? '' : data.code}`)
                        }
                        setFolder(false)
                    }}
                >
                    <div className="p-1 d-flex">
                        <div className="me-1">
                        {   data.icon} 
                        </div>
                        <div>
                            {data.name}
                        </div>
                    </div>   
                </ListGroupItem>
                {
                    active === data.code &&
                        <div className="ps-2">
                            <ListGroup>
                                {
                                    data.child !== null &&
                                        data.child?.map((item) => {
                                            return (
                                                renderCardListChild(item)
                                            )
                                        })
                                }
                            </ListGroup>
                        </div>
                }
            </>
        )
    }

    useEffect(() => {
        getSize()
        getTags()
   }, [])

    return (
        <>
            <div>
                <Card className="m-0 p-0 sidebar h-100"  >
                    <CardBody className="h-100">
                        <div className="h-75">
                            <ListGroup>
                                {
                                    listMenu !== null &&
                                        listMenu?.map((item) => {
                                            return (
                                                renderCardList(item)
                                            )
                                        })
                                }
                            </ListGroup>
                        </div>

                        <div className="h-25 px-1">
                            <Button 
                                color       = "light"
                                className   = {`col-12 text-start mb-2 p-1 ${active === 'trash' ? 'bg-primary text-white' : null}`}
                                onClick     ={() => { 
                                    setActive('trash')
                                    setBreadcums({ type: "create", name: 'Dihapus', url: '/trash'})
                                    setUrlApiActive(`/trash`)
                                }}
                            >
                                <Trash2 
                                    size        = {20}
                                    className   = "me-1"
                                /> Baru Saja Dihapus
                            </Button>

                            <Button 
                                color       = "light"
                                className   = "col-12 text-start mb-2 p-1"
                            >
                                <div className="d-flex justif-content-start">
                                    <PieChart 
                                        size        = {20}
                                        className   = "text-dark me-1"
                                    />
                                    <div className="col-10">
                                        <div>{size.used_size} of {size.max_size} used</div>
                                        <div className = "col-12">
                                            <Progress
                                                value={Math.floor(size.percent)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Button>
                        </div>
                    </CardBody>
                </Card>
            </div>

        </>
    )
}

export default DataManagementSidebar