import { 
    useContext, 
    useEffect, 
    useState
}                                   from 'react'

import { 
    Button,
    Input,
    InputGroup,
    InputGroupText,
    Label,
    ListGroup, 
    ListGroupItem, 
    Modal, 
    ModalBody, 
    ModalFooter, 
    ModalHeader 
}                                   from 'reactstrap'

import toast                        from 'react-hot-toast'
import Avatar                       from '@components/avatar'
import { Search }                   from 'react-feather'
import CustomTableEmptyData         from '../../../@core/components/custom-table/empty'

// API
import chatAPI                      from '../../../service/pages/chat'

// Context
import { UserManagementContext }    from '../../../context/userManagementContext'

const AddMember = ({show, setShow, selected, getListGroup}) => {
    const {
        // State
        listData,

        // Function
        getData
    } = useContext(UserManagementContext)

    const userData =  JSON.parse(localStorage.getItem('userData'))

    const [keyword, setKeyword] = useState(false)
    const [grupName, setGrupName] = useState(false)
    const [userSelected, setUserSelected]   = useState([])


    const selectUser = id => {
        if (userSelected.indexOf(id) !== -1) {
            setUserSelected(userSelected.filter(opt => opt !== id))
        } else {
            setUserSelected([...userSelected, id])
        }
    }

    const onSubmit = () => {
             const formData = { 
                members : userSelected
            }
            chatAPI.addMember(selected.uuid, formData)
                .then(() => {
                    setShow(false)
                    toast.success('Berhasil Menambahkan Member')
                    getListGroup({uuid:selected.uuid})
                })
                .catch(() => {
                    toast.error('Gagal Menambahkan')
                })
    }

    useEffect(() => {
        getData()
    }, [])

  return (
    <Modal
        isOpen={show}
        toggle={() => setShow(false)}

    >
        <ModalHeader toggle={() => setShow(false)}>

        </ModalHeader>
            <ModalBody>
                <InputGroup className='input-group-merge my-1'>
                    <InputGroupText>
                        <Search size={14} />
                    </InputGroupText>
                    <Input 
                        onChange    = {(e) => { setKeyword(e.target.value) }} 
                        placeholder = 'Masukkan Nama Pengguna ...' 
                    />
                    <Button 
                        color      = 'primary' 
                        outline
                        onClick    = {() => { getData({keyword : keyword}) }} 
                    >
                        Cari
                    </Button>
                </InputGroup>
                <div className = 'overflow-auto' style={{ height : '500px' }}>
                    <ListGroup>
                        {
                            Array.isArray(listData) && listData.map((item) => (
                                <ListGroupItem 
                                    key         = {item.id} 
                                    active      = {userSelected.indexOf(item.user_id) !== -1  } 
                                    onClick     = {() => { selectUser(item.user_id) }} 
                                    className   = "cursor-pointer" 
                                >
                                    <div className='d-flex align-items-center'>
                                        <div>
                                            <Avatar 
                                                className   = "me-1 p-0"
                                                size        = "md"
                                            />
                                        </div>
                                        <div>
                                            <h5 className='fw-bolder'>{item.name}</h5>
                                            <h6 className='fw-bolder text-muted'>{item.work_unit?.name}</h6>
                                        </div>
                                    </div>
                                    
                                </ListGroupItem>
                            ))
                        }
                    </ListGroup>
                    {listData && listData.length === 0 && (
                        <CustomTableEmptyData />
                    )}
                </div>
            
            </ModalBody>
            <ModalFooter className='d-flex justify-content-between'>
                <Button
                    color='primary'
                    onClick={() => setShow(false)}
                >
                    Batal
                </Button>
                <Button
                    type    = 'submit'
                    color   = 'primary'
                    outline
                    onClick = {onSubmit}
                >
                    Submit</Button>
            </ModalFooter>
    </Modal>
  )
}

export default AddMember
