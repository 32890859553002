import React, { useContext, useEffect } from 'react'

import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import Select               from 'react-select'
import { Button, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { ManagementFileContext } from '../../../../context/managementFileContext'
import FileManagementAPI from '../../../../service/pages/file-management'
import toast                        from "react-hot-toast"

const schema = yup.object().shape({
    category: yup.object().shape({
        value: yup.string().required('Please select an option'),
        label: yup.string().required('Please select an option'),
      }),
  
    report: yup.object().shape({
        value: yup.string().required('Please select an option'),
        label: yup.string().required('Please select an option'),
      }),
    
  })

const CreateFile = ({
    show,
    data_,
    params,
    size,
    getData,
    title,
    setShow
}) => {

    const {
        //State
        reportOptions,

        categoryOptions,

        getReportOptions,
        getCategoryOptions,

    }                   = useContext(ManagementFileContext)

    const { handleSubmit, setValue, control, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
      })


      const create = (data) => {

        const formData = {
             category_id : data?.category?.value,
             report_type_id : data?.report?.value
        }

        FileManagementAPI.create(formData)
            .then(() => {
                toast.success('Berhasil Menambahkan Data')
                getData()
                setShow(false)
            })
            .catch((err) => {
                console.log(err)
                toast.error(`${data?.report?.label} Telah Terpakai`)

            })
      }

      const update = (data) => {

        const formData = {
             category_id : data?.category?.value,
             report_type_id : data?.report?.value
        }

        FileManagementAPI.update(data_?.id, formData)
            .then(() => {
                toast.success('Berhasil Ubah Data')
                getData(params)
                setShow(false)
            })
            .catch((err) => {
                console.log(err)
                toast.error(`${data?.report?.label} Sudah Terpakai Di Kategori ${data?.category?.label}`)

            })
      }
      
      const onSubmit = (data) => {
        if (data_) {
            update(data)
            
        } else {
            create(data)
            
        }
      } 


    //   console.log(data_)

      useEffect(() => {
        if (data_) {
            setValue('category', {
                value : data_?.category, 
                label : data_?.category_name
            })
            setValue('report', {
                value : data_?.report_type, 
                label : data_?.report_type_name
            })
        }
      }, [data_])

      useEffect(() => {
        if (show && data_) {
            setValue('category', {
                value : data_?.category, 
                label : data_?.category_name
            })
            setValue('report', {
                value : data_?.report_type, 
                label : data_?.report_type_name
            })
        } else {
            setValue('category', '')
            setValue('report', '')

        }
      }, [show && data_])

      useEffect(() => {
        if (show) {
            getReportOptions()
            getCategoryOptions()
        }
      }, [show])

  return (
    <Modal
        isOpen  = {show} 
        toggle  = {() => { setShow(!show) }} 
        size    = {size || "sm"}
        centered
    >
        <ModalHeader 
            toggle={() => setShow(!show)} 
        > 
            {title}
        </ModalHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
            <ModalBody>
                <div className='mb-1'>
                    <Label>Pilih Kategori</Label>
                    <Controller
                        name="category"
                        control={control}
                        render={({ field }) => (
                            <Select
                                id="category"
                                classNamePrefix     = "select"
                                placeholder         = "Pilih Kategori"
                                {...field}
                                options             = {categoryOptions}
                                // value               = {dt.level}
                                // onChange            = {(e) => {
                                //     dt.level        = e
                                //     handleUpdateThen()
                                // }}
                            />
                        )}
                    />
                    {
                        errors && errors.category && 
                            <Label style={{ color: 'red' }}>
                                {errors.category.label.message}
                            </Label>
                    }
                </div>
                <div>
                    <Label>Pilih Report</Label>
                    <Controller
                        name="report"
                        control={control}
                        render={({ field }) => (
                            <Select
                                id="report"
                                classNamePrefix     = "select"
                                placeholder         = "Pilih Report"
                                {...field}
                                options             = {reportOptions}
                                // isDisabled          = {data_}
                                // value               = {dt.level}
                                // onChange            = {(e) => {
                                //     dt.level        = e
                                //     handleUpdateThen()
                                // }}
                            />
                        )}
                    />
                    {
                        errors && errors.category && 
                            <Label style={{ color: 'red' }}>
                                {errors.category.label.message}
                            </Label>
                    }
                </div>
            </ModalBody>

            <ModalFooter>
                <div
                    className   = "d-flex justify-content-between col-12"
                >
                    <Button
                        color   = "primary"
                        outline
                        onClick = {() => {
                            setShow(false)
                        }}
                    >
                        Batal
                    </Button>

                    <Button
                        color   = "primary"
                        type="submit"
                    >
                        Simpan
                    </Button>
                </div>
            </ModalFooter>
        </form>
    </Modal>
  )
}

export default CreateFile