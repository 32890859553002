const statusPenyelesaianOptionLin4 = [
    {
        value   : "Selesai - Ditingkatkan ke Operasi Intelijen",
        label   : "Selesai - Ditingkatkan ke Operasi Intelijen"
    },
    {
        value   : "Selesai - Ditutup",
        label   : "Selesai - Ditutup"
    },
    {
        value   : "Selesai - Diserahkan Ke Bidang Lain",
        label   : "Selesai - Diserahkan Ke Bidang Lain"
    },
    {
        value   : "Selesai - Draft",
        label   : "Selesai - Draft"
    },
]

export default statusPenyelesaianOptionLin4