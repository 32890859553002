import { 
    useEffect, 
    useState 
}                               from "react"

import { 
    useLocation, 
    useNavigate, 
    useSearchParams
}                               from "react-router-dom"

import { 
    Coffee, 
	Key, 
	X 
} 					            from 'react-feather'

import { 
    getHomeRouteForLoggedInUser 
}                               from '@utils'

import { Spinner }              from "reactstrap"

// ** Custom Hooks
import useAxios                 from '@hooks/useAxios'

// ** Base URL
import baseUrl                  from '../../../service/index'

import logoDataManagement       from '../../../assets/images/logo/data-management.png'

import { 
	sso_client_id, 
}                               from "../../../interceptor/axiosInstance"
import toast 					from 'react-hot-toast'
import Avatar 					from '@components/avatar'

const useHashParams = () =>  {
    const { hash } = useLocation()
    return new URLSearchParams(hash.substring(1)) // Menghilangkan '#' dari awal hash
}

const ToastContent = ({ t, name, role }) => {
	return (
		<div className='d-flex'>
			<div className='me-1'>
				<Avatar size='sm' color='success' icon={<Coffee size={12} />} />
			</div>
			<div className='d-flex flex-column'>
				<div className='d-flex justify-content-between'>
					<h6>Selamat</h6>
					<X size={12} className='cursor-pointer' onClick={() => toast.dismiss(t.id)} />
				</div>
				<span>Berhasil login sebagai {role}.</span>
			</div>
		</div>
	)
}

const formatAbility = (userAbilities) => {

	const newAbility_   = []

    //add ability for sidebar
    if (userAbilities) {
        userAbilities.map((data) => {

            if (data.is_active) {
                newAbility_.push({
                    action: 'read',
                    subject: data.slug
                })
            }

            if (data.children.length > 0) {
                data.children.map((dataChildren) => {
                    if (dataChildren.is_active) {
                        newAbility_.push({
                            action  : 'read',
                            subject : dataChildren.slug
                        })
                    }
                })
            }
        })
    } 

	localStorage.setItem('ability', JSON.stringify(newAbility_))
}

const VerifySSO = () => {

    const [searchParams]    = useSearchParams()
	const navigate          = useNavigate()

    const urlApiSSO         = "https://fairly-closing-eel.ngrok-free.app/api"
    const href  		    = window.location.href
	const split 		    = href.split("/")
	const url   		    = `${split[0]}//${split[2]}`

    // Ambil nilai parameter "code"
    const code              = searchParams.get('code')

    // ** Hooks
	const api 		        = useAxios()

    const [loading, setLoading]             = useState(true)
    const [successLogin, setSuccessLogin]   = useState(null)
    const [loginMessage, setLoginMessage]   = useState("")

    const handleVerifyLogin = async () => {

        setLoading(true)

        try {
            const formData = {
                code             : code,
                grant_type       : 'authorization_code',
                client_id        : sso_client_id,
                redirect_uri     : `${url}/verify-sso`,
                // get_token_uri    : 'https://sso.4tz.web.id/realms/master/protocol/openid-connect/token'
            }
            
			const login = await api.post(`${baseUrl}/authentication/login/?sso=true`, formData)

            if (login && !login.data?.error) {
				if (!(!process.env.NODE_ENV || process.env.NODE_ENV === 'production')) {
					await localStorage.setItem('access_token', login.data.access_token)
					await localStorage.setItem('refresh_token', login.data.refresh_token)
				}

				await localStorage.setItem('is_sso_login', true)

				if (login?.data?.first_login) {
					window.location.href = '/change-password'
				}

                setLoginMessage("Login Berhasil, Anda Akan Diarahkan Ke Halaman Beranda.")

                // ** Get Sidebar list menu
                const getSidebar = await api.get(`${baseUrl}/authentication/menus?parent=null&page_size=15&sidebar`)
    
                if (getSidebar) {
                    formatAbility(getSidebar.results)
                }
    
                // ** get user data
                const getUserData = await api.get(`${baseUrl}/management/employee/detail`)

                console.log("getUserData", getUserData)
    
                if (getUserData) {
                    setTimeout(() => {
                        setSuccessLogin(true)
                    }, 1000)

                    const newUserData_ = {
                        ...getUserData,
                        role		: getUserData.role.slug,
                        role_detail : getUserData.role
                    }
        
                    // ** set user data
                    localStorage.setItem('userData', JSON.stringify(newUserData_))
    
                    await toast(t => (
                        <ToastContent t={t} role={newUserData_.role} name={newUserData_.name} />
                    ))
        
                    // ** redirect to next page
                    setTimeout(() => {
                        window.location.href = getHomeRouteForLoggedInUser(newUserData_?.role)
                        setLoading(false)
                    }, 1500)
                    
                }

			} else {
                setLoginMessage("Login Gagal, Anda Akan Diarahkan Kembali Ke Halaman Login.")

                setTimeout(() => {
                    setSuccessLogin(false)
                }, 1000)
            }

        } catch (error) {
            console.error('Terjadi Kesalahan Saat Verifikasi SSO :', error)
            setLoading(false)
            setLoginMessage("Login Gagal, Anda Akan Diarahkan Kembali Ke Halaman Login.")

            setTimeout(() => {
                setSuccessLogin(false)
            }, 1000)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        handleVerifyLogin()
    }, [])

    useEffect(() => {
        if (!loading) {
            console.log("GETDATASTATUSLOGIN", successLogin)
            setTimeout(() => {
                if (successLogin === false) {
                    navigate("/login")
                }
            }, 1000)
        }
    }, [successLogin])

    return (
        <div>
            <div
                style       = {{ height: '100vh' }} 
                className   = "bg-secondary d-flex justify-content-center align-items-center"
            >
                {
                    !loading ? 
                        <>
                            <div className="d-flex flex-column justify-content-center align-items-center">
                                <img 
                                    src			= {logoDataManagement} 
                                    alt			= 'Login Cover' 
                                    className	= 'w-25 mb-3'
                                />
                                <p className="text-white h4">
                                    {/* Code Token Dari SSO adalah : {code} */}


                                    {
                                        loginMessage
                                    }
                                </p>
                            </div>
                        </>
                    :
                        <div className="d-flex flex-column justify-content-center align-items-center">
                            <img 
                                src			= {logoDataManagement} 
                                alt			= 'Login Cover' 
                                className	= 'w-25 mb-5'
                            />
                            <h3 className="text-white mb-3">
                                Proses verifikasi sedang berlangsung, harap menunggu... 
                            </h3>
                            <Spinner 
                                style={{
                                    height: '3rem', 
                                    width: '3rem',
                                    color: 'white'
                                }}
                            />
                        </div>
                }
            </div>
        </div>
    )
}

export default VerifySSO