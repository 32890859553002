import { Card, CardBody, Row } from "reactstrap"
import PropTypes from 'prop-types'

const CustomTableBody = props => {

    // props
    const {
        children,
        className,
        style,
        onClick,
        id
    } = props

    // content
    return (
        <Card
            id = {id}
            className={`mb-1 ${className}`} style={style} onClick={() => {
                if (onClick) {
                    onClick()
                }
            }}
        >
            <CardBody className="py-1" >
                <Row md={12} className="d-flex align-items-center">
                    {children}
                </Row>
            </CardBody>
        </Card>
    )
}

// validation props
CustomTableBody.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object
}

export default CustomTableBody