import * as yup from "yup"

export const UserManagementSchema = yup
	.object()
	.shape({
		nip							: yup.string().required("Isian Tidak Boleh Kosong"),
		name 						: yup.string().required("Isian Tidak Boleh Kosong"),
		grade						: yup.object().shape({value : yup.string().required("Isian Tidak Boleh Kosong")}).required("Isian Tidak Boleh Kosong"),
		class						: yup.object().shape({value : yup.string().required("Isian Tidak Boleh Kosong")}).required("Isian Tidak Boleh Kosong"),
		position					: yup.object().shape({value : yup.string().required("Isian Tidak Boleh Kosong")}).required("Isian Tidak Boleh Kosong"),
		role						: yup.object().shape({value : yup.string().required("Isian Tidak Boleh Kosong")}).required("Isian Tidak Boleh Kosong"),
		workunit					: yup.object().shape({value : yup.string().required("Isian Tidak Boleh Kosong")}).required("Isian Tidak Boleh Kosong"),
		sector						: yup.object().shape({value : yup.string().required("Isian Tidak Boleh Kosong")}).required("Isian Tidak Boleh Kosong"),
		phone_number				: yup.string().matches(/^[0-9]+$/, 'No. Telepon Harus Angka').required('Phone number is required'),
		email 						: yup.string().email("Email Tidak Sesuai").required("Isian Tidak Boleh Kosong"),
		bidang						: yup.number().required("Isian Tidak Boleh Kosong"),
		subdirektorat				: yup.number().required("Isian Tidak Boleh Kosong"),
		seksi						: yup.number().required("Isian Tidak Boleh Kosong"),
		sektor						: yup.number().required("Isian Tidak Boleh Kosong"),
		address						: yup.string().required("Isian Tidak Boleh Kosong"),
	})
	.required()

export const UserManagementSchemaNonDirChild = yup
	.object()
	.shape({
		nip							: yup.string().required("Isian Tidak Boleh Kosong"),
		name 						: yup.string().required("Isian Tidak Boleh Kosong"),
		grade						: yup.object().shape({value : yup.string().required("Isian Tidak Boleh Kosong")}).required("Isian Tidak Boleh Kosong"),
		class						: yup.object().shape({value : yup.string().required("Isian Tidak Boleh Kosong")}).required("Isian Tidak Boleh Kosong"),
		position					: yup.object().shape({value : yup.string().required("Isian Tidak Boleh Kosong")}).required("Isian Tidak Boleh Kosong"),
		role						: yup.object().shape({value : yup.string().required("Isian Tidak Boleh Kosong")}).required("Isian Tidak Boleh Kosong"),
		workunit					: yup.object().shape({value : yup.string().required("Isian Tidak Boleh Kosong")}).required("Isian Tidak Boleh Kosong"),
		sector						: yup.object().shape({value : yup.string().required("Isian Tidak Boleh Kosong")}).required("Isian Tidak Boleh Kosong"),
		phone_number				: yup.string().matches(/^[0-9]+$/, 'No. Telepon Harus Angka').required('Phone number is required'),
		email 						: yup.string().email("Email Tidak Sesuai").required("Isian Tidak Boleh Kosong"),
		bidang						: yup.number().required("Isian Tidak Boleh Kosong"),
		address						: yup.string().required("Isian Tidak Boleh Kosong"),
	})
	.required()
