import moment from "moment"

const IN_1 = [      
    {
        name            : 'No.',
        width           : '65px',
        selector        : row => row.index,
		unique_key      : 'index',
    },
    {
        name            : 'No. Surat',
        selector        : row => row.nomor_surat,
		unique_key      : 'nomor_surat',
    },
    {
        name            : 'Judul',
        selector        : row => row.judul,
		unique_key      : 'judul',
    },
    {
        name            : 'Tanggal Kadaluarsa',
        selector        : row => row.expired_date,
		unique_key      : 'expired_date',
    }
]

const IN_2 = [      
    {
        name            : 'No.',
        width           : '65px',
        selector        : row => row.index,
		unique_key      : 'index',
    },
    {
        name            : 'No. Surat',
        selector        : row => row.nomor_surat,
		unique_key      : 'nomor_surat',
    },
    {
        name            : 'Judul',
        selector        : row => row.judul,
		unique_key      : 'judul',
    },
    {
        name            : 'Tanggal Kadaluarsa',
        cell            : (row) => (
            <>
                {moment.unix(row.tanggal_kadaluarsa).format('DD-MM-YYYY')}
            </>
        ),
        selector        : row => row.tanggal_kadaluarsa,
    },
]

const IN_4 = [      
    {
        name            : 'No.',
        width           : '65px',
        selector        : row => row.index,
		unique_key      : 'index',
    },
    {
        name            : 'Judul',
        selector        : row => row.judul,
		unique_key      : 'judul',
    },
]

const IN_6 = [      
    {
        name            : 'No.',
        width           : '65px',
        selector        : row => row.index,
		unique_key      : 'index',
    },
    {
        name            : 'Sumber',
        selector        : row => row.sumber_surat?.nomor_surat,
        unique_key		: 'sumber_surat'
    },
]

const IN_9 = [      
    {
        name            : 'No.',
        width           : '65px',
        selector        : row => row.index,
		unique_key      : 'index',
    },
    {
        name            : 'No. Surat',
        selector        : row => row.nomor_surat,
		unique_key      : 'nomor_surat',
    },
    {
        name            : 'Sumber',
        selector        : row => row?.sumber_surat?.judul,
        unique_key		: 'sumber_surat'
    },
]

const IN_11 = [      
    {
        name            : 'No.',
        width           : '65px',
        selector        : row => row.index,
		unique_key      : 'index',
    },
    {
        name            : 'Sumber',
        selector        : row => row?.sumber_surat?.judul,
        unique_key		: 'sumber_surat'
    },
]

const IN_12 = [      
    {
        name            : 'No.',
        width           : '65px',
        selector        : row => row.index,
		unique_key      : 'index',
    },
    {
        name            : 'Sumber',
        selector        : row => row?.sumber_surat?.nomor_surat,
        unique_key		: 'sumber_surat'
    },
]

const IN_13 = [      
    {
        name            : 'No.',
        width           : '65px',
        selector        : row => row.index,
		unique_key      : 'index',
    },
    {
        name            : 'No. Surat',
        selector        : row => row.nomor_surat,
        unique_key      : 'nomor_surat',
    },
]

const IN_16 = [      
    {
        name            : 'No.',
        width           : '65px',
        selector        : row => row.index,
		unique_key      : 'index',
    },
    {
        name            : 'Nomor',
        selector        : row => row.nomor_surat,
    },
    {
        name            : 'SP.PPS',
        selector        : row => row?.sumber_surat?.nomor_surat,
        unique_key      : 'sumber_surat',
    },
]

const IN_22 = [      
    {
        name            : 'No.',
        width           : '65px',
        selector        : row => row.index,
		unique_key      : 'index',
    },
    {
        name            : 'Judul Berita',
        selector        : row => row.judul_berita,
    },
]
const IN_23 = [      
    {
        name            : 'No.',
        width           : '65px',
        selector        : row => row.index,
		unique_key      : 'index',
    },
    {
        name            : 'Nama Pelapor',
        selector        : row => row.nama_pelapor,
    },
]

const HEADER_IN = {
    IN_1        : IN_1,
    IN_2        : IN_2,
    IN_3        : IN_1,
    IN_4        : IN_4,
    IN_5        : IN_4,
    IN_6        : IN_6,
    IN_7        : IN_6,
    IN_8        : IN_6,
    IN_9        : IN_9,
    IN_10       : IN_6,
    IN_11       : IN_11,
    IN_12       : IN_12,
    IN_13       : IN_13,
    IN_14       : IN_13,
    IN_15       : IN_12,
    IN_16       : IN_16,
    IN_17       : IN_16,
    IN_18       : IN_13,
    IN_19       : IN_13,
    IN_20       : IN_13,
    IN_21       : IN_13,
    IN_22       : IN_22,
    IN_23       : IN_23,
}

export default HEADER_IN