import { Navigate } 	from 'react-router-dom'
import DataManagement 	from '../../views/data-management'

const DataManagementRoutes = [
	{
		path	: '/data-management',
		element	: <DataManagement />,
		meta	: {
			action: 'read',
			resource: 'data-management'
		}
	}
]

export default DataManagementRoutes
