export default [
	{
		title: "Nama",
		size: 2,
	},
	{
		title: "NIP",
		size: 2,
	},
	{
		title: "Kejaksaan",
		size: 3,
	},
	{
		title: "Pangkat",
		size: 2,
	},
	{
		title: "Jabatan",
		size: 2,
	}
]
