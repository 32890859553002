// ** Icons Import
import { FileText } from 'react-feather'

export default [
  {
    id          : 'recapitulation',
    icon        : <FileText size={20} />,
    title       : 'Rekap Data',
    action      : 'read',
    navLink     : "/recapitulation",
    resource    : 'rekap-data'
  }
]
