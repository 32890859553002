import { lazy } 		from 'react'
import { Navigate } 	from 'react-router-dom'
import SIACC 			from '../../views/siacc'

const Dashboard = lazy(() => import('../../views/dashboard'))

const SIACCRoutes = [
	{
		path	: '/siacc',
		element	: <SIACC/>,
		meta	: {
			action: 'read',
			resource: 'berita-siacc'
		}
	}
]

export default SIACCRoutes
