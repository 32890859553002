import { 
    useRef,
    useState,
    createContext,
}                   from "react"

import toast        from "react-hot-toast"
import chatAPI from "../service/pages/chat"
import ReactDOM                     from 'react-dom'
import serviceAPI from "../service/drive/service"
import userManagementAPI from "../service/pages/user-management"
import { socket_url } from "../interceptor/axiosInstance"

const ChatContext = createContext(null)

const ChatProvider = ({ children }) => {

    const chatArea    = useRef(null)
    const scrollDown  = useRef(null)

    const [page, setPage]                                   = useState(1)
    const [counter, setCounter]                 = useState(0)
    const [message, setMessage]                 = useState([])
    const [selected, setSelected]               = useState(null)
    const [listData, setListData]               = useState(null)
    const [listDataNotification, setListDataNotification]               = useState(null)
    const [listGroup, setListGroup]             = useState([])
    const [listAdmin, setListAdmin]             = useState([])
    const [chatSocket, setChatSocket]           = useState(null)
    const [showGroup, setShowGroup]             = useState(false)
    // const [currentPage, setCurrentPage] = useState(1);
    const [chatRoomPopup, setChatRoomPopup]     = useState(false)
    const [chatPopup, setChatPopup]             = useState(false)


    // const [pagination, setPagination]           = useState(false)

    // const userData =  JSON.parse(localStorage.getItem('userData'))

    const connectChatSocket = roomId => {

        let socketUrl = ''

        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'production') {
            socketUrl = `${socket_url}/chat/${roomId}`
        } else {
            socketUrl = `${socket_url}/chat/${roomId}?token=${localStorage.getItem('access_token')}`
        }

        const socket = new WebSocket(socketUrl)
        setChatSocket(socket)
    }

    const scrollToBottom = () => {
        if (ReactDOM.findDOMNode(chatArea.current)) {
            const chatContainer = ReactDOM.findDOMNode(chatArea.current)
            chatContainer.scrollTop = Number.MAX_SAFE_INTEGER
        } else {
            const chatContainer = ReactDOM.findDOMNode(scrollDown.current)
            chatContainer.scrollTop = Number.MAX_SAFE_INTEGER
        }
    }

    const popupScroll = () => {
        const chatContainer = ReactDOM.findDOMNode(scrollDown.current)
        chatContainer.scrollTop = Number.MAX_SAFE_INTEGER
    }
    
    const getList = (param) => {
        chatAPI.getList(param)
            .then((res) => {
                if (!res.is_error) {
                    if (page === 1) {
                        setListData(res)
                    } else {
                        setListData(listData.concat(res))
                    }
                }
            })
            .catch((err) => {
                console.log('error', err)
                toast.error('List Error')
            })
    }

    const getListNotification = (param) => {
        chatAPI.getList(param)
            .then((res) => {
                if (!res.is_error) {
                    if (page === 1) {
                        setListDataNotification(res)
                    } else {
                        setListDataNotification(listData.concat(res))
                    }
                }
            })
            .catch((err) => {
                console.log('error', err)
                toast.error('List Error')
            })
    }

    const getListGroup = (param) => {
        chatAPI.getListGroup(param)
            .then((res) => {
                if (!res.is_error) {
                    setListAdmin(res[0].admins)
                    const data = []
                    res[0].members.map(item => {
                       if (!isNaN(item)) {
                            userManagementAPI.getUserManagement({user:item})
                            .then(result => {
                                data.push(result.results[0])
                                setTimeout(() => {
                                    setListGroup(data)
                                }, 3000)
                            })
                       }
                    })
                }
            })
            .catch(() => {
                toast.error('List Error')
            })
    }

    const getMessage = (uuid) => {
        setMessage([])
        chatAPI.getMessage(uuid)
            .then((res) => {
                if (!res.is_error) {
                    setMessage(res.results)
                    // scrollToBottom()
                }
            })
            .catch(() => {
                toast.error('Message Error')
            })
    }
    if (chatSocket !== null) {
        chatSocket.onopen = function() {
            console.log("Chat Connected")
        }
        
        chatSocket.onmessage = function(event) {
            const res = JSON.parse(event.data)
            const oldChat = message ? message : []
            if (message?.length > 0) {
                const lastElement = message.at(-1)
                if (res?.data?.id !== lastElement?.id) {
                    oldChat.push(res)
                    setMessage([...oldChat])
                }
            } else {
                oldChat.push(res)
                setMessage([...oldChat])
            }
            getList()
            scrollToBottom()
            // getMessage(selected.uuid)
        }
        
        chatSocket.onclose = function() {
            console.log('reconnect chat socket')

            const counter_ = counter + 1

            if (counter_ <= 3) {
                setTimeout(() => {
                    setCounter(counter_)
                    connectChatSocket(selected.uuid)
                }, 2000)
            }
   
        }
        
        chatSocket.onerror = function() {
            console.log(`[error]`)
        }
    }

   
    const sendMessage = (data, type) => {
        const formData = {
            room: selected.id,
            content_type: type,
            content: data.message,
            message_attachments: []
        }

        chatAPI.sendMessage(formData)
            .then(() => {
                toast.success('Chat Terkirim')
                // getMessage(selected.uuid)
                // scrollToBottom()
            })
            .catch(() => {
                toast.error('Gagal Mengirim')
            })

    }

    const sendAttachment = (data, type) => {

        const formData = new FormData
        formData.append('file', data[0])
        formData.append('service', 'test-1')

        serviceAPI.uploadFileService(formData)
            .then((result) => {
                const formData = {
                    room: selected.id,
                    content_type: 3,
                    content: result.data.name,
                    message_attachments: [
                        {
                            content: window.location.origin + result.data.url_file,
                            content_type : type
                        }
                    ]
                }
                chatAPI.sendMessage(formData)
                    .then(() => {
                        toast.success('Chat Terkirim')
                        // getMessage(selected.uuid)
                        // setTimeout(() => {
                        //         scrollToBottom()
                        //   }, 00) 
                    })
                    .catch(() => {
                        toast.error('Gagal Mengirim')
                    })
            }).catch(() => {
                toast.error('Gagal Menambahkan')
            })

    }

    const deleteMessage = (id) => {
        
        chatAPI.deleteMessage(id)
            .then(() => {
                toast.success('Chat Terhapus')
                getMessage(selected.uuid)
                // scrollToBottom()
            })
            .catch(() => {
                toast.error('Gagal Menghapus')
            })

    }

    const invisibleMessage = (id) => {
        
        chatAPI.invisibleMessage(id)
            .then(() => {
                toast.success('Chat Ditarik')
                getMessage(selected.uuid)
                // scrollToBottom()
            })
            .catch(() => {
                toast.error('Gagal Menarik Chat')
            })

    }

    const archiveChat = (id) => {
        
        chatAPI.archiveChat(id)
            .then(() => {
                toast.success('Room Diarsipkan')
                // getMessage(selected.uuid)
                getList()
                // scrollToBottom()
            })
            .catch(() => {
                toast.error('Gagal Diarsipkan')
            })
    }

    const deleteMember = (uuid, id) => {
        
        const formData = {
            members : id
        }

        chatAPI.deleteMember(uuid, formData)
            .then(() => {
                toast.success('Berhasil Menghapus Member')
                getListGroup({uuid:selected.uuid})
            })
            .catch(() => {
                toast.error('Gagal Menghapus Member')
            })
    }

    const deleteRoom = (uuid) => {

        chatAPI.deleteRoom(uuid)
            .then(() => {
                toast.success('Berhasil Menghapus Room')
                getListGroup({uuid:selected.uuid})
            })
            .catch(() => {
                toast.error('Gagal Menghapus Room')
            })
    }

    const addAdmin = (uuid, id) => {

        setListGroup([])
        
        const formData = {
            admins : id
        }

        chatAPI.addAdmins(uuid, formData)
            .then(() => {
                toast.success('Berhasil Menambah Admin')
                getListGroup({uuid:selected.uuid})
            })
            .catch(() => {
                toast.error('Gagal Menambah Admin')
            })
    }


    const unArchiveChat = (id) => {
        
        chatAPI.unArchiveChat(id)
            .then(() => {
                toast.success('Berhasil Menghapus Room Dari Arsip')
                // getMessage(selected.uuid)
                getList({is_archive:true})
                // scrollToBottom()
            })
            .catch(() => {
                toast.error('Gagal Menghapus Room Dari Arsip')
            })

    }

    return <ChatContext.Provider
        value={{
            // State
                page, 
                setPage,

                chatArea,
                scrollDown,

                listData, 
                setListData,

                listDataNotification,
                setListDataNotification,
                
                selected, 
                setSelected,

                message, 
                setMessage,

                showGroup, 
                setShowGroup,

                listGroup,

                listAdmin, 
                setListAdmin,

                chatRoomPopup, 
                setChatRoomPopup,

                chatPopup, 
                setChatPopup,

            // Function
                getList,
                getMessage,
                getListGroup,
                getListNotification,

                sendMessage,
                sendAttachment,

                addAdmin,
                archiveChat,
                unArchiveChat,
                invisibleMessage,

                deleteMessage,
                deleteMember,

                deleteRoom,

                popupScroll,
                scrollToBottom,
                connectChatSocket

        }} >{children}</ChatContext.Provider>
}

export { ChatContext, ChatProvider }

