import {
    Button,
    Card,
    CardBody,
    Col
}                       from "reactstrap"
import {
    useEffect,
    useState
}                       from "react"

import MapWorkunit      from "../../other/MapWorkunit"

const WidgetMap = (props) => {

    const {
        height,
        defaultData,
        _zoom,
        children,
        handleReset
    } = props

    const [isFullScreen, setIsFullScreen] = useState(false)
    
    const detectFullscreen = () => {

        if ((document.fullscreen)) {
            return true
        } else {
            return false
        }

    }

    const handleFullscreen = () => {

        const ref = document.getElementById("fs-component")

        if (!detectFullscreen()) {
            if (ref.requestFullscreen) {
                ref.requestFullscreen()
            } else if (ref.webkitRequestFullscreen) {
                ref.webkitRequestFullscreen()
            } else if (ref.msRequestFUllscreen) {
                ref.msRequestFUllscreen()
            }

            const color                 = window.getComputedStyle(document.body).getPropertyValue("background-color")
            ref.style.backgroundColor   = color
        } else {
            ref.style.paddingTop        = ""
            ref.style.backgroundColor   = ""

            try {
                document.webkitExitFullscreen()
            } catch (e) {
                console.log(e)
            }
        }
    }

    return (
        <>
            <Col
                className   = "h-100"
                id          = "fs-component"
            >
                <Card className="h-100 p-0 m-0">
                    <CardBody className="">
                        <MapWorkunit
                            height              = {height ? height : 300}
                            showFullScreenBtn   = {true}
                            isFullScreen        = {isFullScreen}
                            setIsFullScreen     = {setIsFullScreen}
                            handleFullscreen    = {handleFullscreen}
                            handleReset         = {handleReset}
                            defaultData         = {defaultData}
                            _zoom               = {_zoom}
                        >
                            {children}
                        </MapWorkunit>
                    </CardBody>
                </Card>
            </Col>
        </>
    )
}

export default WidgetMap