import * as yup from "yup"

const RecapitulationValidationPeriode = yup
	.object()
	.shape({
		start_date			: yup.date().nullable().transform((curr, orig) => (orig === '' ? null : curr)).required("Isian Tidak Boleh Kosong"),
		end_date			: yup.date().nullable().transform((curr, orig) => (orig === '' ? null : curr)).required("Isian Tidak Boleh Kosong"),
	})
	.required()

export default RecapitulationValidationPeriode
