// ** Icons Import
import { PieChart, Circle } from 'react-feather'

export default [
  {
    id: 'rin',
    icon: <PieChart size={20} />,
    title: 'RIN - Register',
    action: 'read',
    resource: 'rin-register',
    children: [
      {
          id: 53,
          icon: <Circle size={12} />,
          title: "R.IN.1 - Surat Masuk",
          action: "read",
          navLink: "/rin/1",
          resource: "rin1-surat-masuk"
      },
      {
          id: 54,
          icon: <Circle size={12} />,
          title: "R.IN.2 - Surat Keluar",
          action: "read",
          navLink: "/rin/2",
          resource: "rin2-surat-keluar"
      },
      {
          id: 55,
          icon: <Circle size={12} />,
          title: "R.IN.3 - Kerja Intelijen",
          action: "read",
          navLink: "/rin/3",
          resource: "rin3-kerja-intelijen"
      },
      {
          id: 56,
          icon: <Circle size={12} />,
          title: "R.IN.4 - Ekspedisi Surat",
          action: "read",
          navLink: "/rin/4",
          resource: "rin4-ekspedisi-surat"
      },
      {
          id: 57,
          icon: <Circle size={12} />,
          title: "R.IN.5 - Produk Intelijen",
          action: "read",
          navLink: "/rin/5",
          resource: "rin5-produk-intelijen"
      },
      {
          id: 58,
          icon: <Circle size={12} />,
          title: "R.IN.6 - Arsip",
          action: "read",
          navLink: "/rin/6",
          resource: "rin6-arsip"
      },
      {
          id: 59,
          icon: <Circle size={12} />,
          title: "R.IN.7 - Kegiatan Bid. Ipolhankam",
          action: "read",
          navLink: "/rin/7",
          resource: "rin7-kegiatan-bid-ipolhankam"
      },
      {
          id: 60,
          icon: <Circle size={12} />,
          title: "R.IN.8 - Kegiatan Bid. Sosbudmas",
          action: "read",
          navLink: "/rin/8",
          resource: "rin8-kegiatan-bid-sosbudmas"
      },
      {
          id: 61,
          icon: <Circle size={12} />,
          title: "R.IN.9 - Kegiatan Bid. Ekonomi dan Keuangan",
          action: "read",
          navLink: "/rin/9",
          resource: "rin9-kegiatan-bid-ekonomi-dan-keuangan"
      },
      {
          id: 62,
          icon: <Circle size={12} />,
          title: "R.IN.10 - R.K PPS",
          action: "read",
          navLink: "/rin/10",
          resource: "rin10-rk-pps"
      },
      {
          id: 63,
          icon: <Circle size={12} />,
          title: "R.IN.11 - R.K Teknologi Informasi",
          action: "read",
          navLink: "/rin/11",
          resource: "rin11-rk-teknologi-informasi"
      },
      {
          id: 64,
          icon: <Circle size={12} />,
          title: "R.IN.12 - Ops Intelijen Bid. Ipolhankam",
          action: "read",
          navLink: "/rin/12",
          resource: "rin12-ops-intelijen-bid-ipolhankam"
      },
      {
          id: 65,
          icon: <Circle size={12} />,
          title: "R.IN.13 - Ops Intelijen Bid. Sosbudmas",
          action: "read",
          navLink: "/rin/13",
          resource: "rin13-ops-intelijen-bid-sosbudmas"
      },
      {
          id: 66,
          icon: <Circle size={12} />,
          title: "R.IN.14 - Ops Intelijen Bid. Ekonomi dan Keuangan",
          action: "read",
          navLink: "/rin/14",
          resource: "rin14-ops-intelijen-bid-ekonomi-dan-keuangan"
      },
      {
          id: 67,
          icon: <Circle size={12} />,
          title: "R.IN.15 - Ops Intelijen Bid. PPS",
          action: "read",
          navLink: "/rin/15",
          resource: "rin15-ops-intelijen-bid-pps"
      },
      {
          id: 68,
          icon: <Circle size={12} />,
          title: "R.IN.16 - Ops Intelijen Bid. Teknologi Informasi dan Produk Intelijen",
          action: "read",
          navLink: "/rin/16",
          resource: "rin16-ops-intelijen-bid-teknologi-informasi-dan-produk-intelijen"
      },
      {
          id: 69,
          icon: <Circle size={12} />,
          title: "R.IN.17 - Berita Masuk",
          action: "read",
          navLink: "/rin/17",
          resource: "rin17-berita-masuk"
      },
      {
          id: 70,
          icon: <Circle size={12} />,
          title: "R.IN.18 - Berita Keluar",
          action: "read",
          navLink: "/rin/18",
          resource: "rin18-berita-keluar"
      },
      {
          id: 71,
          icon: <Circle size={12} />,
          title: "R.IN.19 - Telaahan Intelijen",
          action: "read",
          navLink: "/rin/19",
          resource: "rin19-telaahan-intelijen"
      },
      {
          id: 72,
          icon: <Circle size={12} />,
          title: "R.IN.20 - Ekspedisi Berita",
          action: "read",
          navLink: "/rin/20",
          resource: "rin20-ekspedisi-berita"
      },
      {
          id: 73,
          icon: <Circle size={12} />,
          title: "R.IN.21 - Tamu Pos Pelayanan",
          action: "read",
          navLink: "/rin/21",
          resource: "rin21-tamu-pos-pelayanan"
      },
      {
          id: 74,
          icon: <Circle size={12} />,
          title: "R.IN.22 - Pelaksanaan Kegiatan Penerangan Hukum dan Penyuluhan Hukum",
          action: "read",
          navLink: "/rin/22",
          resource: "rin22-pelaksanaan-kegiatan-penerangan-hukum-dan-penyuluhan-hukum"
      }
    ]
  }
]
