import {
    getUserData
}                               from "../../utility/Utils"

import ProfileBiodata           from "./component/profile_biodata"
import ProfileRank              from "./component/profile_rank"
import ProfileReport            from "./component/profile_report"
import ProfileReward            from "./component/profile_reward"

const Profile = () => {
    
    return (
        <div>
            <div
                className   = "row row-cols-lg-2 row-cols-1 g-1 mb-1"
            >
                <div className="col-lg-8 col-12">
                    <ProfileBiodata
                        userBiodata     = {getUserData()}
                        isProfile       = {true}
                    />
                </div>
                
                <div className="col-lg-4 col-12">
                    <ProfileRank

                    />
                </div>
            </div>

            <div
                className   = "row row-cols-lg-2 row-cols-1 g-1"
            >
                <div className="col-lg-8 col-12">
                    <ProfileReport
                        userId = {getUserData()?.user_id}
                    />
                </div>
                
                <div className="col-lg-4 col-12">
                    <ProfileReward

                    />
                </div>
            </div>
        </div>
    )
}

export default Profile