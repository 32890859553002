import { 
    Card, 
    CardBody, 
    CardHeader
}   from "reactstrap"

const TicketingChatCount = ({
    listTicket
}) => {

    const notOpen = listTicket && listTicket.filter((item) => item.status ===  1)
    const process = listTicket && listTicket.filter((item) => item.status ===  2)
    const finish = listTicket && listTicket.filter((item) => item.status ===  3)

    const renderCardCount = (color, title, count) => {
        return (
            <Card
                color       = {color}
                className   = "me-1 mb-2"
                style       = {{
                    width   : '200px' 
                }}
            >
                <CardBody>
                    <div 
                        className   = "text-center text-white px-2"
                    >
                        <div>{title}</div>
                        <h3 className="text-white m-0">
                            <strong>{count}</strong>
                        </h3>
                    </div>
                </CardBody>
            </Card>
        )
    }

    return (
        <>
            {renderCardCount("primary", "Belum Dibuka", notOpen.length)}
            {renderCardCount("success", "Diproses", process.length)}
            {renderCardCount("danger",  "Selesai", finish.length)}
        </>
    )
}

export default TicketingChatCount