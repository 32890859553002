// ** Icons Import
import { PieChart, Circle } from 'react-feather'

export default [
  {
    id: 'lin',
    icon: <PieChart size={20} />,
    title: 'LIN - LAPORAN',
    action: 'read',
    resource: 'lin-laporan',
    children: [
      {
        id: 24,
        icon: <Circle size={12} />,
        title: "L.IN.1 - LapInHar",
        action: "read",
        navLink: "/lin/1",
        resource: "lin1-lapinhar"
      },
      {
        id: 25,
        icon: <Circle size={12} />,
        title: "L.IN.2 - LapInSus",
        action: "read",
        navLink: "/lin/2",
        resource: "lin2-lapinsus"
      },
      {
        id: 26,
        icon: <Circle size={12} />,
        title: "L.IN.3 - Laporan Intelijen Khusus",
        action: "read",
        navLink: "/lin/3",
        resource: "lin3-laporan-intelijen-khusus"
      },
      {
        id: 27,
        icon: <Circle size={12} />,
        title: "L.IN.4 - LapHasTug",
        action: "read",
        navLink: "/lin/4",
        resource: "lin4-laphastug"
      },
      {
        id: 28,
        icon: <Circle size={12} />,
        title: "L.IN.5 - LaOpsin",
        action: "read",
        navLink: "/lin/5",
        resource: "lin5-laopsin"
      },
      {
        id: 29,
        icon: <Circle size={12} />,
        title: "L.IN.6 - Laporan Atensi",
        action: "read",
        navLink: "/lin/6",
        resource: "lin6-laporan-atensi"
      },
      {
        id: 30,
        icon: <Circle size={12} />,
        title: "L.IN.7 - Lahin",
        action: "read",
        navLink: "/lin/7",
        resource: "in7-lahin"
      },
      {
        id: 31,
        icon: <Circle size={12} />,
        title: "L.IN.8 - Perkiraan Keadaan Intelijen",
        action: "read",
        navLink: "/lin/8",
        resource: "in8-perkiraan-keadaan-intelijen"
      },
      {
        id: 32,
        icon: <Circle size={12} />,
        title: "L.IN.9 - Troop Info",
        action: "read",
        navLink: "/lin/9",
        resource: "in9-troop-info"
      },
      {
        id: 33,
        icon: <Circle size={12} />,
        title: "L.IN.10 - Labul SpTug",
        action: "read",
        navLink: "/lin/10",
        resource: "lin10-labul-sptug"
      },
      {
        id: 34,
        icon: <Circle size={12} />,
        title: "L.IN.11 - Labul Rekap Tugas",
        action: "read",
        navLink: "/lin/11",
        resource: "lin11-labul-rekap-tugas"
      },
      {
        id: 35,
        icon: <Circle size={12} />,
        title: "L.IN.12 - Labul Pelaksanaan Ops",
        action: "read",
        navLink: "/lin/12",
        resource: "lin12-labul-pelaksanaan-ops"
      },
      {
        id: 36,
        icon: <Circle size={12} />,
        title: "L.IN.13 - Labul Rekapitulasi Ops",
        action: "read",
        navLink: "/lin/13",
        resource: "lin13-labul-rekapitulasi-ops"
      },
      {
        id: 37,
        icon: <Circle size={12} />,
        title: "L.IN.14 - Labul Pencegahan dan Penangkalan",
        action: "read",
        navLink: "/lin/14",
        resource: "lin14-labul-pencegahan-dan-penangkalan"
      },
      {
        id: 38,
        icon: <Circle size={12} />,
        title: "L.IN.15 - Labul Pengawasan Lalu Lintas Orang Asing",
        action: "read",
        navLink: "/lin/15",
        resource: "lin15-labul-pengawasan-lalu-lintas-orang-asing"
      },
      {
        id: 39,
        icon: <Circle size={12} />,
        title: "L.IN.16 - Labul Tindak Pidana Asing",
        action: "read",
        navLink: "/lin/16",
        resource: "lin16-labul-tindak-pidana-asing"
      },
      {
        id: 40,
        icon: <Circle size={12} />,
        title: "L.IN.17 - Labul Pengaman Sumber Daya Org. Kejaksaan dan Pengamanan Penanganan Perkara",
        action: "read",
        navLink: "/lin/17",
        resource: "lin17-labul-pengaman-sumber-daya-org-kejaksaan-dan-pengamanan-penanganan-perkara"
      },
      {
        id: 41,
        icon: <Circle size={12} />,
        title: "L.IN.18 - Labul Pengawasan Barang Cetakan",
        action: "read",
        navLink: "/lin/18",
        resource: "lin18-labul-pengawasan-barang-cetakan"
      },
      {
        id: 42,
        icon: <Circle size={12} />,
        title: "L.IN.19 - Labul Pengawasan Media Komunikasi",
        action: "read",
        navLink: "/lin/19",
        resource: "lin19-labul-pengawasan-media-komunikasi"
      },
      {
        id: 43,
        icon: <Circle size={12} />,
        title: "L.IN.20 - Labul Pengobatan Tradisional",
        action: "read",
        navLink: "/lin/20",
        resource: "lin20-labul-pengobatan-tradisional"
      },
      {
        id: 44,
        icon: <Circle size={12} />,
        title: "L.IN.21 - Labul Pengawasan Aliran Kepercayaan Masyarakat",
        action: "read",
        navLink: "/lin/21",
        resource: "lin21-labul-pengawasan-aliran-kepercayaan-masyarakat"
      },
      {
        id: 45,
        icon: <Circle size={12} />,
        title: "L.IN.22 - Labul Pengawasan Aliran Keagamaan",
        action: "read",
        navLink: "/lin/22",
        resource: "lin22-labul-pengawasan-aliran-keagamaan"
      },
      {
        id: 46,
        icon: <Circle size={12} />,
        title: "L.IN.23 - Labul Pengawasan Organisasi Kemasyarakatan",
        action: "read",
        navLink: "/lin/23",
        resource: "lin23-labul-pengawasan-organisasi-kemasyarakatan"
      },
      {
        id: 47,
        icon: <Circle size={12} />,
        title: "L.IN.24 - Labul Ketertiban dan Ketentraman Umum",
        action: "read",
        navLink: "/lin/24",
        resource: "lin24-labul-ketertiban-dan-ketentraman-umum"
      },
      {
        id: 48,
        icon: <Circle size={12} />,
        title: "L.IN.25 - Labul Pembinaan Masyarakat Taat Hukum",
        action: "read",
        navLink: "/lin/25",
        resource: "lin25-labul-pembinaan-masyarakat-taat-hukum"
      },
      {
        id: 49,
        icon: <Circle size={12} />,
        title: "L.IN.26 - Labul Pencegahan Konflik Sosial",
        action: "read",
        navLink: "/lin/26",
        resource: "lin26-labul-pencegahan-konflik-sosial"
      },
      {
        id: 50,
        icon: <Circle size={12} />,
        title: "L.IN.27 - Labul Posko Perwakilan Kejaksaan",
        action: "read",
        navLink: "/lin/27",
        resource: "lin27-labul-posko-perwakilan-kejaksaan"
      },
      {
        id: 51,
        icon: <Circle size={12} />,
        title: "L.IN.28 - Labul Keg. PPS",
        action: "read",
        navLink: "/lin/28",
        resource: "lin28-labul-keg-pps"
      },
      {
        id: 52,
        icon: <Circle size={12} />,
        title: "L.IN.29 - Tangkap Buronan",
        action: "read",
        navLink: "/lin/29",
        resource: "lin29-tangkap-buronan"
      },
      {
        id: 94,
        icon: <Circle size={12} />,
        title: "Laporan Kegiatan Kunjungan Lapangan",
        action: "read",
        navLink: "/lin/laporan_kegiatan_kunjungan_lapangan",
        resource: "lin30-laporan-kegiatan-kunjungan-lapangan"
      },
      {
        id: 95,
        icon: <Circle size={12} />,
        title: "Laporan Kegiatan PPS",
        action: "read",
        navLink: "/lin/laporan_kegiatan_pps",
        resource: "lin31-laporan-kegiatan-pps"
      },
      {
        id: 96,
        icon: <Circle size={12} />,
        title: "Ringkasan Potensi AGHT & Batasan PPS",
        action: "read",
        navLink: "/lin/ringkasan_aght",
        resource: "lin32-ringkasan-aght"
      }
    ]
  }
]
