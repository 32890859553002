import useAxios            from '../utility/hooks/useAxios'
import useAxiosSiacc       from '../utility/hooks/useAxiosSiacc'

import { 
    url as baseURL, 
}                           from '../interceptor/axiosInstance'
import { url_siacc }        from '../interceptor/axiosInstanceSiacc'

const api       = useAxios()
const apiSiacc  = useAxiosSiacc()

export const get = (url, queryParams) => {
    return api.get(`${baseURL}${url}`, {
        params: queryParams
    })
}

export const getSiacc = (url, queryParams) => {
    return apiSiacc.get(`${url_siacc}${url}`, {
        params: queryParams
    })
}

export const getBlob = (url, queryParams) => {
    return api.get(`${baseURL}${url}`, {
        params          : queryParams,
        responseType    : "blob"
    })
}

export const getBlobAnywhere = (url, queryParams) => {
    return api.get(`${url}`, {
        params          : queryParams,
        responseType    : "blob"
    })
}

export const post = (url, formData, queryParams) => {
    return api.post(`${baseURL}${url}`, formData, {
        params: queryParams
    })
}

export const put = (url, formData, queryParams) => {
    return api.put(`${baseURL}${url}`, formData, {
        params: queryParams
    })
}

export const patch = (url, formData, queryParams) => {
    return api.patch(`${baseURL}${url}`, formData, {
        params: queryParams
    })
}

export const delete_ = (url, formData) => {
    return api.delete(`${baseURL}${url}`, {data : formData})
}

export default baseURL