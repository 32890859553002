import * as yup from "yup"

export const UserManagementSchema = yup
	.object()
	.shape({
		nip							: yup.string().required("Isian Tidak Boleh Kosong"),
		name 						: yup.string().required("Isian Tidak Boleh Kosong"),
		phone_number				: yup.string().matches(/^[0-9]+$/, 'No. Telepon Harus Angka').required('Phone number is required'),
		email 						: yup.string().email("Email Tidak Sesuai").required("Isian Tidak Boleh Kosong"),
		address						: yup.string().required("Isian Tidak Boleh Kosong"),
	})
	.required()