import React                    from 'react'
import { 
    Button, 
    Input, 
    Label, 
    Modal, 
    ModalBody, 
    ModalFooter, 
    ModalHeader 
}                               from 'reactstrap'

import { useForm, Controller }  from 'react-hook-form'
import { yupResolver }          from '@hookform/resolvers/yup'
import * as yup                 from 'yup'

const schema = yup.object().shape({
    message: yup.string().required('message is required'),
  })

const InputLink = ({show, setShow, setMessage, sendMessage}) => {

    const { handleSubmit, setValue, control, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    })

    const onSubmit = (data) => {
            sendMessage(data.message, 1)
            setShow(false)        
    }

  return (
    <Modal
        isOpen = {show}
        toggle = {() => setShow(false)}

    >
        <ModalHeader toggle={() => setShow(false)}>
            Input Link
        </ModalHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
            <ModalBody>
                <Label>Link</Label>
                <Controller
                    name            = "message"
                    control         = {control}
                    defaultValue    = ""
                    render={({ field }) => (
                        <Input
                            id      ="message"
                            type    ="text"
                            {...field}
                        />
                    )}
                />
                <p>{errors.message?.message}</p>
            </ModalBody>
            <ModalFooter className = 'd-flex justify-content-between'>
                <Button color = 'primary'>
                    Cancel
                </Button>
                <Button 
                    type    = 'submit'
                    color   = 'primary'
                    outline
                >
                    Submit
                </Button>
            </ModalFooter>
        </form>
    </Modal>
  )
}

export default InputLink
