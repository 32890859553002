import { 
    useState,
    createContext,
    useEffect
}                       from "react"

//Context
const PositionContext  = createContext(null)

//API
import positionAPI      from "../service/pages/position"

const PositionProvider = ({ children }) => {

    //State
    const [positionOption, setPositionOption]   = useState([])
    const [listData, setListData]               = useState([])
    const [isLoading, setIsLoading]             = useState(false)
    const [pagination, setPagination]           = useState(false)
    
    //Get data Position
    const getData = (params) => {
        setIsLoading(true)
        
        positionAPI.getPosition(params).then(
            res => {

                setIsLoading(false)

                const formatData_ = []

                if (res.count !== 0) {
                    res.results.map((data, idx) => {
                        data.index          = idx + 1
                        data.Position       = data.name
                        formatData_.push(data)
                    })
                }

                const pagination = {
                    count       : res.count,
                    page        : res.page,
                    page_size   : res.page_size,
                    next        : res.next,
                    previous    : res.previous,
                    total_data  : res.count
                }
                
                setListData(formatData_)
                setPagination(pagination)
            }
        ).catch(
            err => {
                console.log(err, 'err')
            }
        )
    }

    const getDataAll = () => {
        
        positionAPI.getPosition({
            page_size : 400
        }).then(
            res => {
                const formatData_ = []

                if (res.count !== 0) {
                    res.results.map((data) => {
                        formatData_.push({
                            label : `${data?.name} ${data?.code ? `(${data?.code})` : "" }`,
                            value : data?.id,
                            level : data?.work_unit_level_id
                        })
                    })

                    setPositionOption(formatData_)
                }
            }
        ).catch(
            err => {
                console.log(err, 'err')
            }
        )
    }

    const reformat = (data, idx) => {
        data.map((dt, index) => {
            dt.title = dt.name
            reformat(dt.children, `${idx}-${index}`)
        })
    }
 
    const getDataOrganization = (workunit_level, setData, type, setLoading) => {
        setIsLoading(true)
        
        positionAPI.getOrgStructure({
            work_unit_level     : workunit_level
        }).then(
            res => {
                setLoading(false)
                res?.map((dt, idx) => {
                    dt.title = dt.name
                    reformat(dt.children, `${idx}`)
                })

                if (type === "list") {
                    setData({
                        id : res[0].id,
                        name : res[0].name,
                        title : res[0].title,
                        children    : [
                            {
                                id          : "",
                                name        : "",
                                title       : "",
                                children    : []
                            }, {
                                id          : "",
                                name        : "",
                                title       : "",
                                children    : []
                            }
                        ]
                    })
                } else {
                    setData(res[0])
                }
            }
        ).catch(
            err => {
                console.log(err, 'err')
            }
        )
    }

    useEffect(() => {
        if (localStorage.getItem('userData')) {
            getData()
            getDataAll()
        }
    }, [])

    return <PositionContext.Provider
        value={{

            listData,
            setListData,

            pagination,
            setPagination,

            isLoading,
            setIsLoading,

            getData,

            positionOption,
            getDataOrganization,
        }}
    > 
        {children}
    </PositionContext.Provider>
}

export { PositionContext, PositionProvider }