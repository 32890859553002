import { 
    Button,
    Col,
    Input,
    InputGroup, 
    Label, 
    Modal, 
    ModalBody, 
    ModalFooter, 
    ModalHeader, 
    Row
}                           from "reactstrap"
import { useState }         from "react"
import { Plus, X }          from "react-feather"
import Select               from 'react-select'
import AssessmentIndicatorAPI from "../../../../service/pages/assesment-indicator"
import toast from "react-hot-toast"

const ModalBadge = ({
    show,
    size,
    title,
    setShow,
    setFile,
    image, 
    setImage,
    listBadge,
    setExistingBadge,
}) => {

    const handleImage = (e) => {
        setFile(e.target.files[0])
        const reader = new FileReader(),
            files = e.target.files
            reader.onload = function () {
                setImage(reader.result)
            }
            reader.readAsDataURL(files[0])
        setImage(e.target.files[0])
    }

    return (
        <>
            <Modal
                isOpen  = {show} 
                toggle  = {() => { setShow(!show) }} 
                size    = {size || "sm"}
            >
                <ModalHeader 
                    toggle={() => setShow(!show)} 
                > 
                    {title}
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col xs={12} className="d-flex align-items-center ">
                            <div className="me-1">
                                <img src={image} className="rounded bg-primary " height={100} width={100}/>
                            </div>
                            <Input
                                id          = 'badge'
                                name        = "template"
                                type        = 'file' 
                                accept      = "image/*" 
                                onChange    = {(e) => handleImage(e)}
                                style       = {{ display : 'none'}}
                            /> 
                            <Label 
                                for='badge'
                                className   = 'rounded cursor-pointer btn btn-primary'  
                            >
                               Pilih Foto
                            </Label>
                        </Col>
                        <Col xs={12}>
                            <p className="my-1">
                                Pilih Data Yang Sudah Ada
                            </p>
                            <Row className="overflow-auto " style={{height : '300px'}}>
                                {
                                    Array.isArray(listBadge) && listBadge.map((data) => (
                                        <Col 
                                            xs          = {3} 
                                            className   = "cursor-pointer" 
                                            onClick     = {() => {
                                                setImage(window.location.origin + data.file)
                                                setExistingBadge(true)
                                            }}
                                        >
                                            <img src={window.location.origin + data.file} className="rounded-circle bg-primary " height={70} width={70}/>
                                        </Col>
                                    ))
                                }
                            </Row>
                        </Col>

                    </Row>
                </ModalBody>

                <ModalFooter>
                    <div
                        className   = "d-flex justify-content-between col-12"
                    >
                        <Button
                            color   = "primary"
                            outline
                            onClick = {() => {
                                setShow(false)
                            }}
                        >
                            Batal
                        </Button>

                        <Button
                            color   = "primary"
                            onClick = {() => {
                                setShow(false)
                            }}
                        >
                            Simpan
                        </Button>
                    </div>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default ModalBadge