import { Input, InputGroup, InputGroupText } from "reactstrap"
import { Search } from "react-feather"

const NavbarSearch = () => {
    return (
        <>
            <InputGroup className='input-group-merge ms-1 w-50'>
                <InputGroupText className='round'>
                  <Search className='text-muted' size={14} />
                </InputGroupText>
                <Input
                //   value={query}
                  className='round'
                  placeholder='Pencarian'
                //   onChange={handleFilter}
                />
            </InputGroup>
        </>
    )
}

export default NavbarSearch