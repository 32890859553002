import { Lock } from "react-feather"
import { Card, CardBody } from "reactstrap"

const NotAuthorized = () => {
    return (
        <>
            <Card>
                <CardBody className="py-5 d-flex justify-content-center text-center flex-column">
                    <div className="mb-2">
                        <Lock size={50}/>
                    </div>
                    <h1 className="text-center">Anda tidak memiliki akses ke halaman ini.</h1>

                </CardBody>
            </Card>
        </>
    )
}

export default NotAuthorized