import { useContext, useEffect, useState }  from "react"
import { Bar }                  from "react-chartjs-2"
import {
    Card,
    CardBody,
    Col
}                               from "reactstrap"
import { ProfileContext } from "../../../context/profileContext"

const WidgetJumlahProduk = (props) => {

    const {
        data
    } = props

    const {
        reportList
    }   = useContext(ProfileContext)

    const labelsBar      = ["DIR A", "DIR B", "DIR C", "DIR D", "DIR E"]
    const color          = [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgb(255, 140, 0, 1)'
    ]
    
    const optionsBar  = {
        responsive          : true,
        maintainAspectRatio : false,
        plugins     : {
            legend: {
                display : false
            },
            title: {
                display: false
            }
        },
        scales: {
            y: {
                display : false
            }
        }
    }

    const [dataBar, setDataBar]     = useState(null)

    const dataBar1       = {
        labels : labelsBar,
        datasets: [
            {
                label: 'A',
                data:  [50, 20, 30, 20, 20],
                backgroundColor: 'rgba(255, 206, 86, 1)',
                stack: 'Stack 2'
            }, 
            {
                label: 'B',
                data:  [20, 40, 20, 70, 60],
                backgroundColor: 'rgba(75, 192, 192, 1)',
                stack: 'Stack 3'
            }
        ]
    }

    useEffect(() => {
       if (data && reportList?.length > 0) {
            const datasets  = []
            const arrKey    = Object.keys(data)
            let productId   = []
            if (Array.isArray(arrKey)) {
                arrKey?.map((dirKey, idx) => {
                    if (idx === 0) {
                        productId = Object.keys(data[dirKey])
                    }
                })

                productId?.map((productId, idx) => {
                    datasets.push({
                        label           : `${reportList.find((dt) => `${dt.id}` === `${productId}`)?.name}`,
                        id              : productId,
                        data            : [],
                        backgroundColor : color[idx]
                    })

                })

                arrKey?.map((dirKey, idx) => {
                    datasets.map((dt) => {
                        dt.data.push(data[dirKey][dt.id])
                    })
                })
            }

            const temp = {
                labels   : labelsBar,
                datasets : datasets
            }

            setDataBar({...temp})
       }
    }, [data, reportList])

    return (
        <>
            <Col
                className   = "h-1001 pb-2"
                style       = {{
                    height  : '400px'
                }}
            >
                <Card className="h-100 p-0 m-0">
                    <CardBody
                        className   = ""
                    >
                        <div
                            className   = "h-100 d-flex flex-column"
                        >
                            <div className = "d-flex pb-2">
                                <div 
                                    className = "bg-primary rounded-circle me-1"
                                    style = {{
                                        width  : '20px',
                                        height : '20px'
                                    }}
                                />
                                <strong>Jumlah Produk Intelijen Setiap Direktorat</strong>
                            </div>
                            <div className="flex-grow-1">
                                {
                                    dataBar &&
                                        <Bar
                                            data    = {dataBar} 
                                            options = {optionsBar}
                                        />
                                }
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </>
    )
}

export default WidgetJumlahProduk