// ** Icons Import
import { GitPullRequest } from 'react-feather'

export default [
  {
    id          : 'organization-structure',
    icon        : <GitPullRequest size={20} />,
    title       : 'Struktur Organisasi',
    action      : 'read',
    navLink     : "/organization-structure",
    resource    : 'struktur-organisasi'
  }
]
