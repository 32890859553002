import moment               from "moment"
import pdfMake              from "pdfmake/build/pdfmake"
import pdfFonts             from "pdfmake/build/vfs_fonts"
import HtmlConverter        from "../../../helper/html-converter"
import getURLMAP            from "../../../helper/mapbox"
import bookman              from '../../../assets/fonts/bookman'
import bookmanBold          from '../../../assets/fonts/bookmanBold'
import bookmanItalics       from '../../../assets/fonts/bookmanItalics'
import bookmanBoldItalics   from '../../../assets/fonts/bookmanBoldItalics'
pdfMake.vfs                 = pdfFonts.pdfMake.vfs

const fonts_ = {...pdfFonts.pdfMake.vfs, ...bookman, ...bookmanBold, ...bookmanItalics, ...bookmanBoldItalics }

pdfMake.vfs = fonts_
pdfMake.fonts = {
    bookman:{
        normal      : "bookman.ttf",
        bold        : "bookmanBold.ttf",
        italics     : "bookmanItalics.ttf",
        bolditalics : "bookmanBoldItalics.ttf"
    }
}

let fontSize      = 8
let pageSize      = "A4"
let content       = []

const enterNewLine = () => {
    content.push({
        text: { text: ` `, alignment: "right", fontSize: fontSize }     })
}

const PdfMakeRecapData = (
    title,
    tableList,
    fileName,
    _pageSize,
    _fontSize
) => {
    content = []

    pageSize = _pageSize ? _pageSize : "A4"
    fontSize = _fontSize ? _fontSize : 9

    title.map((dt) => {
        content.push({
            text: { text: `${dt}`, alignment: "center", fontSize: fontSize + 2, bold: true }
        })
    })

    enterNewLine()

    tableList?.map((table) => {

        const tableBody      = []
        const width          = []
        const number         = []

        content.push({
            text: { text: `${table.title}`, alignment: "left", fontSize: fontSize, bold: true }
        })

        table.header?.map((list, index) => {
            const header         = []
    
            list.map((dt, idx) => {
    
                if (index === 0) {
                    width.push(dt.key === "index" ? 50 : "*")
                    number.push({ text: `${idx + 1}`, alignment: "center", fontSize: fontSize, bold: true })
                }
                header.push({ text: `${dt.label}`, alignment: "center", fontSize: fontSize, bold: true, colSpan : dt.colSpan, rowSpan : dt.rowSpan })
            })
    
            tableBody.push(header)
    
        })

        table.data?.map((list, index) => {
            const body         = []
    
            list.map((dt, idx) => {
                body.push({ text: `${dt.label}`, alignment: `${typeof (dt.label) === "number" ? "center" : "left"}`, fontSize: fontSize, bold: false, colSpan : dt.colSpan, rowSpan : dt.rowSpan })
            })
    
            tableBody.push(body)
    
        })

        content.push({
            table: {
                headerRows: 1,
                widths: width,
    
                body: tableBody
            }
        })

        enterNewLine()
        
    })

    enterNewLine()

    const docDefinition = {
        pageSize    : pageSize ? pageSize : 'A4',
        content     : content,
        info: {
            title: `${fileName}`,
        },
        pageOrientation     : 'potrait',
        defaultStyle        : {
            font            : "bookman",
        }
    }

    const pdfDocGenerator = pdfMake.createPdf(docDefinition)
    pdfDocGenerator.getBlob((blob) => {
        window.open(URL.createObjectURL(blob))
    })

}

export default PdfMakeRecapData