import { 
    useState,
    createContext,
    useEffect
}                       from "react"

//Context
const GradeContext  = createContext(null)

//API
import GradeAPI      from "../service/pages/grade"

const GradeProvider = ({ children }) => {

    //State
    const [gradeOption, setGradeOption]   = useState([])

    const getDataAll = () => {
        
        GradeAPI.getGrade({
            page_size : 100
        }).then(
            res => {

                const formatData_ = []

                if (res.count !== 0) {
                    res.results.map((data) => {
                        formatData_.push({
                            label : data?.name,
                            value : data?.id
                        })
                    })

                    setGradeOption(formatData_)
                }

            }
        ).catch(
            err => {
                console.log(err, 'err')
            }
        )
    }

    useEffect(() => {
        if (localStorage.getItem('userData')) {
            getDataAll()
        }
    }, [])

    return <GradeContext.Provider
        value={{
            gradeOption
        }}
    > 
        {children}
    </GradeContext.Provider>
}

export { GradeContext, GradeProvider }