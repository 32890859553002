import OrganizationStructure 	from '../../views/organization-structure'

const OrganizationStructureRoutes = [
	{
		path	: '/organization-structure',
		element	: <OrganizationStructure/>,
		meta	: {
			action: 'read',
			resource: 'struktur-organisasi'
		}
	},

	{
		path	: '/organization-structure/:id',
		element	: <OrganizationStructure/>,
		meta	: {
			action: 'read',
			resource: 'struktur-organisasi'
		}
	}
]

export default OrganizationStructureRoutes
