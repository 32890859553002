import { Navigate } 	from 'react-router-dom'
import { lazy } 		from 'react'
import DetailWidget from '../../views/dashboard/component/detailWidget'
const Dashboard = lazy(() => import('../../views/dashboard'))
const CalendarComponent = lazy(() => import('../../views/dashboard/component/calendar/index'))


const DashboardRoutes = [
	{
		path: '/dashboard',
		element: <Dashboard/>,
		meta: {
			action: 'read',
			resource: 'dashboard'
		}
	},
	{
		path: '/dashboard/detail/:id',
		element: <DetailWidget/>,
		meta: {
			action: 'read',
			resource: 'dashboard'
		}
	},
	{
		path: '/dashboard/calendar',
		element: <CalendarComponent/>,
		meta: {
			action: 'read',
			resource: 'dashboard'
		}
	}
]

export default DashboardRoutes
