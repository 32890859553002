import React, { Fragment } from 'react'
import { Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader } from 'reactstrap'

const ModalHideColumn = (props) => {

    const {
        show, 
        onClose, 
        data, 
        hideColumn, 
        setHideColumn
    }                       = props

    const handleClick = (event) => {
        const index = parseInt(event.target.value)
        const newhideColumn = [...hideColumn]
        newhideColumn[index] = !newhideColumn[index]

        setHideColumn(newhideColumn)
    }

    const printDataForm = () => {
        return data.map((val, idx) => {
            if (val.name !== 'id' && val.name !== "") {
                return (
                    <FormGroup
                        switch
                        className='my-1'
                    >
                        <Input 
                            type="switch" 
                            value={idx}
                            onClick={handleClick}
                            checked={hideColumn[idx]}
                        />
                        <Label check>
                            {val.name}
                        </Label>
                    </FormGroup>
                )

            }
        })
    }

    return ( 
        <Fragment>
            <Modal
                size='xs'
                isOpen={show}
                centered='true'
            >
                <ModalHeader
                    toggle={() => {
                        onClose()
                    }}
                >
                    Pilih Header
                </ModalHeader>
                <ModalBody>
                    <Form>
                        {printDataForm()}
                    </Form>
                </ModalBody>
            </Modal>
        </Fragment>
    )
}

export default ModalHideColumn