import { 
    Button, 
    ButtonGroup, 
    Card, 
    CardBody 
}                   from 'reactstrap'

import { 
    useEffect, 
    useState 
}                   from 'react'

import { 
    Maximize, 
    Minimize,
    RefreshCcw,
    RotateCcw,
    ZoomIn, 
    ZoomOut 
}                   from 'react-feather'

import ReactMapGL, { 
    FullscreenControl 
}                   from 'react-map-gl'

// import Map from 'react-map-gl'

import mapboxgl   from 'mapbox-gl'

import './style.css'

mapboxgl.accessToken = 'pk.eyJ1IjoiemVwaHlyZm4iLCJhIjoiY2trNzI5bWN1MDlubDJ1cW94Z3hicm9qdCJ9.HPNjaaL1I5rkMdSg1AJf5g'
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default

const MapWorkunit = props => {

    const {
        defaultData,
        height,
        showFullScreenBtn,
        isFullScreen,
        setIsFullScreen,
        handleFullscreen,
        handleReset,
        _zoom
    } = props

    const [zoom, setZoom] = useState(3.5)

    const defaultVP = {
        width       : "100%",
        height      : isFullScreen ? height ? height : 300 : "100%",
        latitude    : defaultData ? defaultData.latitude : -2.5616,
        longitude   : defaultData ? defaultData.longitude : 117.7359,
        zoom        : zoom ?? zoom
    }

    const [viewport, setViewport] = useState(defaultVP)

    const handleZoon = (action) => {
        if (action === "+") {
            setViewport({
                ...viewport,
                zoom        : zoom + 0.5
            })
        } else {
            setViewport({
                ...viewport,
                zoom        : zoom - 0.5
            })
        }
    }

    const handleDefault = () => {
        setViewport({
            width       : "100%",
            height      : isFullScreen ? height ? height : 300 : "100%",
            latitude    : -2.5616,
            longitude   : 117.7359,
            zoom        : 3.5
        })
    }

    useEffect(() => {
        if (_zoom !== undefined) {
            setZoom(_zoom)
        }
    }, [_zoom])

    useEffect(() => {
        
    }, [zoom])

    useEffect(() => {
        if (defaultData !== undefined) {
            setViewport({
                width       : "100%",
                height      : height ? height : 300,
                latitude    : defaultData ? defaultData.latitude : -2.54893,
                longitude   : defaultData ? defaultData.longitude : 118.01486,
                zoom        : zoom ?? zoom
            })
        }
    }, [defaultData])

    useEffect(() => {
        if (viewport) {
            console.log("viewport", viewport)
            setZoom(viewport?.zoom)
        }
    }, [viewport])
    
    const onFullscreen = () => {
        if (!isFullScreen) {
            defaultVP.zoom = 4.5
        } else {
            defaultVP.zoom = 3.5
        }
        setViewport(defaultVP)

        setIsFullScreen(!isFullScreen)
        handleFullscreen()
    }

    return (
        <Card className='mt-0 mb-0 p-0 h-100'>
            <CardBody 
                style       = {{ height:`${isFullScreen ? height ? `${height}px` : "300px" : "100%"}`, width:"100%" }}  
                className   = "col-12 p-0 m-0 h-1001 overflow-auto"
            >
                <ReactMapGL
					mapboxApiAccessToken    = "pk.eyJ1IjoiemVwaHlyZm4iLCJhIjoiY2trNzI5bWN1MDlubDJ1cW94Z3hicm9qdCJ9.HPNjaaL1I5rkMdSg1AJf5g"
					mapStyle                = {"mapbox://styles/zephyrfn/ckra22zgd4uh517m5plawan4p"}
                    dragRotate              = {false}
                    onMove                  = {(evt) => {
                        setViewport(evt.viewState)
                    }}
                    attributionControl      = {false}
					{...viewport}
                >
                    {props.children}
                </ReactMapGL>

                <div
                    className='float-end'
                    style={{
                        marginTop : '-170px',
                        position : 'relative'
                    }}
                >
                    <div className='d-flex1 rounded mb-1'>
                        <div className='mb-1 bg-primary rounded'>
                            <Button
                                size        = "sm"
                                color       = "primary"
                                className   = "btn-icon"
                                onClick     = {() => {
                                    handleZoon("+")
                                }}
                            >
                                <ZoomIn size={17}/>
                            </Button>
                            <br className='border-white'/>

                            <Button
                                size        = "sm"
                                color       = "primary"
                                className   = "btn-icon"
                                onClick     = {() => {
                                    handleZoon("-")
                                }}
                            >
                                <ZoomOut size={17}/>
                            </Button>
                            <br className='border-white'/>

                            <Button
                                size        = "sm"
                                color       = "primary"
                                className   = "btn-icon"
                                onClick     = {() => {
                                    handleDefault()
                                    handleReset()
                                }}
                            >
                                <RotateCcw size={17}/>
                            </Button>
                        </div>
                        {
                            showFullScreenBtn === true &&
                                <Button
                                    size        = "sm"
                                    color       = "primary"
                                    className   = "btn-icon"
                                    onClick     = {() => {
                                        onFullscreen()
                                    }}
                                >
                                    {
                                        !isFullScreen ? <Maximize size={17}/> : <Minimize size={17}/>
                                    }
                                </Button>
                        }
                        
                    </div>
                </div>
            </CardBody>
        </Card>
    )
}

export default MapWorkunit