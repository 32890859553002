import {
    Badge,
    Card,
    CardBody,
    Col
}                           from "reactstrap"

import { 
    useContext, 
    useEffect, 
    useState 
}                           from "react"

import { Line }             from "react-chartjs-2"
import { ProfileContext }   from "../../../context/profileContext"

const WidgetJumlahLaporan = (props) => {

    const {
        data,
        periodeStr
    } = props

    const {
        reportList
    }   = useContext(ProfileContext)
    
    const options = {
        responsive          : true,
        maintainAspectRatio : false,
        plugins     : {
            legend: {
                display : true,
                position : "bottom"
            },
            title: {
                display : false
            }
        },
        scales: {
            y: {
                display : true,
                suggestedMin: 0, // Nilai minimum yang disarankan
                suggestedMax: 5, // Nilai maksimum yang disarankan
                ticks: {
                    beginAtZero: true,
                    stepSize: 1 // Mengatur interval sumbu Y tanpa desimal
                }
            },
            x: {
                display : false,
                ticks: {
                    maxRotation: 90,
                    minRotation: 90
                }
            }
        }
    }

    const borderColor       = [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgb(255, 140, 0, 1)'
    ]

    const backgroundColor    = [
        'rgba(255, 99, 132, 0.1)',
        'rgba(54, 162, 235, 0.1)',
        'rgba(255, 206, 86, 0.1)',
        'rgba(75, 192, 192, 0.1)',
        'rgba(153, 102, 255, 0.1)',
        'rgb(255, 140, 0, 0.1)'
    ]

    const [dataLine, setDataLine]      = useState({
        labels  : [],
        datasets: []
    })

    useEffect(() => {
        if (data && reportList?.length > 0) {
            const datasets      = []
            const labels        = []
            const arrDate       = Object.keys(data) 
            const formatedData  = []
            let listProductId   = []

            // GROUPING REPORT BY DIR
            arrDate?.map((dtDateKey) => {
                const arrKey        = Object.keys(data[dtDateKey])
                const productId     = []
                labels.push(dtDateKey)

                if (Array.isArray(arrKey)) {
                    arrKey?.map((dirKey, idx) => {
                        if (idx === 0) {
                            const temp = Object.keys(data[dtDateKey][dirKey])
                            temp.map((dt) => {
                                productId.push({
                                    id    : dt,
                                    count : 0
                                })
                            })
                            listProductId = Object.keys(data[dtDateKey][dirKey])
                        }
                    })

                    arrKey?.map((dirKey, idx) => {
                        productId.map((dtProductKey) => {
                            // console.log("PRODU123", dtDateKey, dtProductKey.id, data[dtDateKey][dirKey][dtProductKey.id])
                            dtProductKey.count += data[dtDateKey][dirKey][dtProductKey.id]
                        })
                    })
    
                    formatedData.push({
                        label : dtDateKey,
                        value : productId
                    })
                }
            })

            listProductId?.map((productId, idx) => {
                datasets.push({
                    fill            : true,
                    label           : `${reportList.find((dt) => `${dt.id}` === `${productId}`)?.name}`,
                    id              : productId,
                    data            : [],
                    backgroundColor : backgroundColor[idx],
                    borderColor     : borderColor[idx],
                    tension         : 0.4,
                })
            })

            formatedData?.map((dateKey, idx) => {
                datasets.map((dtDataset) => {
                    dtDataset.data.push(dateKey.value.find((dt) => `${dt.id}` === `${dtDataset.id}`)?.count)
                })
            })
 
            const temp = {
                labels   : labels,
                datasets : datasets
            }
 
            setDataLine({...temp})
        }
     }, [data, reportList])

    return (
        <>
            <Col
                className   = "h-1001 pb-2"
                style       = {{
                    height  : '400px'
                }}
            >
                <Card className="h-100 p-0 m-0">
                    <CardBody className="">
                        <div
                            className   = "h-100 d-flex flex-column"
                        >
                            <div
                                className="pb-2"
                            >
                                <div
                                    className="d-flex justify-content-between"
                                >
                                    <Badge
                                        color       = "primary"
                                        className   = "p-1 m-0"
                                    >
                                        Jumlah Laporan
                                    </Badge>

                                    <small>
                                        <div className="pt-1">
                                           {periodeStr}
                                        </div>
                                    </small>
                                </div>
                            </div>

                            <div 
                                className   = 'flex-grow-1 bg-white' 
                                style       = {{ position: 'relative', width: '100%', height : '100%'}}
                            >
                                {
                                    dataLine &&
                                        <Line
                                            data    = {dataLine} 
                                            options = {options}
                                            // height  = {250}
                                        />
                                }
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </>
    )
}

export default WidgetJumlahLaporan