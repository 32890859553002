import { Fragment, useContext, useEffect, useState } from "react"
import {
	Nav,
	Modal,
	Button,
	NavItem,
	NavLink,
	TabPane,
	ModalBody,
	TabContent,
	ModalFooter,
	ModalHeader,
} from "reactstrap"

import DataAttachment 	from "@components/data-attachment"
import { ReportContext } from "../../../context/reportContext"

const ModalDetail = (props) => {
	
	const {
		show,
		onClose,
		dataDetail : DataDetail,
		dataGrafik : DataGrafik,
		lapbulDetail
	} = props

	const [active, setActive] = useState("data")

	const {
		setDataSelected
	} = useContext(ReportContext)

	const toggle = (tab) => {
		if (active !== tab) {
			setActive(tab)
		}
	}

	useEffect(() => {
		if (!show) {
			setDataSelected(null)
		}
	}, [show])

	return (
		<Fragment>
			<Modal
				size		= {lapbulDetail ? "xl" : "lg"}
				isOpen		= {show}
				centered	= {true}
				onClosed	= {() => { setDataSelected(null) }}
				scrollable	= {true}
			>
				<ModalHeader toggle={() => {
					onClose()
					setDataSelected(null)
				}}>Detail Data</ModalHeader>

				<ModalBody className={lapbulDetail ? "py-0" : ""}>
					{
						!lapbulDetail && 
						<Nav tabs>
							<NavItem>
								<NavLink
									active	= {active === "data"}
									onClick	= {() => {
										toggle("data")
									}}
								>
									Data
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									active={active === "file"}
									onClick={() => {
										toggle("file")
									}}
								>
									File Pendukung
								</NavLink>
							</NavItem>
							{
								DataGrafik &&
								<NavItem>
								<NavLink
									active={active === "grafik"}
									onClick={() => {
										toggle("grafik")
									}}
								>
									Grafik
								</NavLink>
							</NavItem>
							}
						</Nav>
					}

					<TabContent className="py-2" activeTab={active}>
						<TabPane tabId="data">
							{DataDetail}
						</TabPane>
						<TabPane tabId="file"> 
							<DataAttachment/>
						</TabPane>
						<TabPane tabId="grafik">
							{DataGrafik}
						</TabPane>
					</TabContent>
				</ModalBody>

				<ModalFooter className="d-flex justify-content-end">
					<Button.Ripple 
						color	= "primary" 
						onClick	= {() => {
							onClose()
							setDataSelected(null)
						}}
					>
						Tutup
					</Button.Ripple>
				</ModalFooter>
			</Modal>
		</Fragment>
	)
}

export default ModalDetail
