import { 
    Button,
    Form,
    Input,
    Label, 
    Modal, 
    ModalBody, 
    ModalFooter, 
    ModalHeader 
}                           from "reactstrap"

import { 
    Controller, 
    useForm 
}                           from "react-hook-form"

import { 
    useContext, 
    useEffect, 
    useRef, 
    useState 
}                           from "react"

import { 
    UserManagementSchema
}                           from "./validation"

import Select               from 'react-select'
import SelectSector 	    from "@components/select-sector"
import SelectBidang 		from "@components/select-bidang"
import SelectSubdirektorat  from "@components/select-subdirektorat"
import SelectSeksi          from "@components/select-seksi"

import { yupResolver }      from "@hookform/resolvers/yup"
import { SectorContext }    from "../../../context/sectorContext"
import { WorkunitContext }  from "../../../context/workunitContext"
import { PositionContext }  from "../../../context/positionContext"
import { GradeContext }     from "../../../context/gradeContext"
import { ClassesContext }   from "../../../context/classesContext"
import { RoleContext }      from "../../../context/roleContext"

import { useDispatch }      from 'react-redux'
import { handleLogout }     from '@store/authentication'
import { useNavigate }      from "react-router-dom"

import baseURL              from "../../../service"
import Helper               from "../../../helper"
import toast                from "react-hot-toast"
import userManagementAPI    from "../../../service/pages/user-management"
import InputPasswordToggle  from "../../../@core/components/input-password-toggle"

const ProfileUpdate = ({
    show,
    setShow,
    title,
    size,
    data,
    handleUpdate
}) => {

    const dispatch              = useDispatch()
    const navigate              = useNavigate()
    const fileInputRef          = useRef(null)
    const { fallbackImage_ }    = Helper

    const [account, setAccount]                          = useState(false)
    const [refresh, setRefresh]                          = useState(false)
    const [validasiPassword, setValidasiPassword]        = useState(false)
    const [passwordData, setPasswordData]                = useState({
        old_password        : "",
        new_password        : "",
        confirm_password    : ""
    })
    const [showDirektoratChild, setShowDirektoratChild]  = useState(true)

    const [profilePicture, setProfilePicture]             = useState(null)
    const [profilePictureBase64, setProfilePictureBase64] = useState(null)

    const {
		reset,
        getValues,
        watch,
		control,
		setValue,
		setError,
		clearErrors,
		handleSubmit,
		formState: { errors },
	} = useForm({
		mode        : "onChange",
        resolver    : yupResolver(UserManagementSchema),
    })

    const {
        roleOption
    } = useContext(RoleContext)

    const {
        sectorOption
    } = useContext(SectorContext)

    const {
        gradeOption
    } = useContext(GradeContext)

    const {
        classesOption
    } = useContext(ClassesContext)

    const {
        workunitOption
    } = useContext(WorkunitContext)

    const {
        positionOption
    } = useContext(PositionContext)


    const handleFileSelect = () => {
        fileInputRef.current.click()
    }

    const removeSymbols = (e) => {
        const str       = e.target.value
        const regex     = /[^a-zA-Z.,\s]/g
        const temp      = str.replace(regex, '')
        return temp
    }

    const onSubmit = (data) => {
        handleUpdate(data, profilePicture)
    }

    const handleChangeInputPassword = (value) => {
        setPasswordData(value)
        setRefresh(true)
    }

    const handleChangePassword = () => {
        setValidasiPassword(true)

        let validasi = true

        Object.values(passwordData).forEach(value => {
            console.log(value)
            if (value === "") {
                validasi = false
            }
        })
        
        if (validasi) {
            if (passwordData?.new_password !== passwordData?.confirm_password) {
                toast.error("Kata Sandi Baru dan Konfirmasi Kata Sandi Tidak Sama!")
            } else if (passwordData?.old_password === passwordData?.new_password) {
                toast.error("Kata Sandi Lama dan Baru Tidak Boleh Sama!")
            } else {
                userManagementAPI.changePasword(passwordData)
                    .then((res) => {
                        toast.success("Kata Sandi Berhasil Diubah")
                        toast.success("Silahkan melakukan login ulang")
                        setAccount(false)

                        setTimeout(() => {
                            dispatch(handleLogout())
                            navigate("/login")
                        }, 1000)

                    })
                    .catch((err) => {
                        toast.error(`Kata Sandi Gagal Diubah!`)
                    })
            }
        }
    }

    useEffect(() => {
        if (refresh) {
            setRefresh(false)
        }
    }, [refresh])

    useEffect(() => {
        if (show) {
            if (data) {
                reset()

                console.log("userBiodata", data)

                setValue("position", {
                    label : data?.position?.name,
                    value : data?.position_id
                })

                setValue("workunit", {
                    label : data?.work_unit?.name,
                    value : data?.work_unit_id
                })

                setValue("grade", {
                    label : data?.grade?.name,
                    value : data?.grade_id
                })

                setValue("class", {
                    label : data?.classes?.name,
                    value : data?.classes_id
                })

                setValue("sector", {
                    label : data?.sector?.name,
                    value : data?.sector_id
                })

                if (data?.role_detail?.name) {
                    setValue("role", {
                        label : data?.role_detail?.name,
                        value : data?.role_id
                    })
                }

                if (data?.role?.name) {
                    setValue("role", {
                        label : data?.role?.name,
                        value : data?.role_id
                    })
                }

                console.log("SELECTEDBIDANGCOMPS", data)
                if (data?.code_id) {
                    setValue("bidang", data?.code_id)
                }
            
                if (data?.code_sector_id) {
                    setValue("subdirektorat", data?.code_subdir_id)
                }

                if (data?.code_seksi_id) {
                    setValue("seksi", data?.code_seksi_id)
                }

                if (data?.code_sector_id) {
                    setValue("sektor", data?.code_sector_id)
                }

                if (data?.photo) {
                    const url = baseURL.replace("/api", "")
                    setProfilePictureBase64(`${url}${data?.photo}`)
                }
            }
        }
    }, [data, show])

    return (
        <>
            <Modal
                isOpen  = {show} 
                toggle  = {() => { setShow(!show) }} 
                size    = {size || "sm"}
            >
                <ModalHeader 
                    toggle={() => setShow(!show)} 
                > 
                    {title}
                </ModalHeader>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <ModalBody>
                        <div>
                            <div
                                className="d-flex col-12 p-0 m-0"
                            >
                                <div
                                    className="col-6 pe-1 p-0 m-0"
                                >
                                    <div
                                        className   = "mb-1 m-0 p-0"
                                    >
                                        <Label>Foto Profil</Label>

                                        <div
                                            className = ""
                                        >
                                            <img
                                                src         = {profilePictureBase64 ? profilePictureBase64 : `https://ui-avatars.com/api/?name=${getValues('name') ? watch("name") : "-"}&background=4e73df&color=fff&bold=true`}
                                                onError     = {fallbackImage_}
                                                width       = "113"
                                                height      = "113"
                                                className   = "rounded mr-50"
                                            />

                                            <Button.Ripple
												color		= "primary"
                                                className   = "ms-1"
                                                onClick     = {handleFileSelect}
											>
							    				Pilih Foto
    										</Button.Ripple>

                                            <input
                                                type            = "file"
                                                accept          = "image/*"
                                                ref             = {fileInputRef}
                                                style           = {{ display: 'none' }}
                                                onChange        = {(e) => {
                                                    const selectedFile = e.target.files[0]
                                                    setProfilePicture(selectedFile)

                                                    const reader = new FileReader()
                                                    reader.onload = () => {
                                                        setProfilePictureBase64(reader.result)
                                                    }
                                                    reader.readAsDataURL(selectedFile)
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div
                                        className   = "mb-1"
                                    >
                                        <Label>NIP/NRP</Label>
                                        <Controller
                                            name        = "nip"
                                            control     = {control}
                                            render      = {({ field }) => (
                                                <Input
                                                    {...field} 
                                                    invalid             = {errors.nip && true}
                                                    placeholder         = "NIP/NRP"
                                                />
                                            )}
                                            defaultValue= {data?.nip}
                                        />
                                        {errors.nip && <small className="text-danger">{errors.nip.message}</small>}
                                    </div>

                                    <div
                                        className   = "mb-1"
                                    >
                                        <Label>Nama Lengkap</Label>
                                        <Controller
                                            name        = "name"
                                            control     = {control}
                                            render      = {({ field }) => (
                                                <Input
                                                    {...field} 
                                                    invalid             = {errors.name && true}
                                                    placeholder         = "Nama Lengkap"
                                                    defaultValue        = {data?.name}
                                                    onChange            = {(e) => {
                                                        const temp = removeSymbols(e)
                                                        field.onChange(temp)
                                                    }}   
                                                />
                                            )}
                                            defaultValue= {data?.name}
                                        />
                                        {errors.name && <small className="text-danger">{errors.name.message}</small>}
                                    </div>

                                    <div
                                        className   = "mb-1"
                                    >
                                        <Label> </Label>
                                        <Label>Pangkat</Label>
                                        <Controller
                                            name        = "grade"
                                            control     = {control}
                                            render      = {({ field }) => (
                                                <Select
                                                    {...field} 
                                                    className           = {`react-select ${errors.grade?.value && "is-invalid"}`}
                                                    classNamePrefix     = "select"
                                                    placeholder         = "Pangkat"
                                                    options             = {gradeOption}
                                                    isDisabled          = {true}
                                                />
                                            )}
                                            defaultValue= {data?.grade}
                                        />
                                        {errors.grade && <small className="text-danger">{errors.grade?.value?.message}</small>}
                                    </div>

                                    <div
                                        className   = "mb-0"
                                    >
                                        <Label>Golongan</Label>
                                        <Controller
                                            name        = "class"
                                            control     = {control}
                                            render      = {({ field }) => (
                                                <Select
                                                    {...field} 
                                                    className           = {`react-select ${errors.class?.value && "is-invalid"}`}
                                                    classNamePrefix     = "select"
                                                    placeholder         = "Golongan"
                                                    options             = {classesOption}
                                                    isDisabled          = {true}
                                                />
                                            )}
                                            defaultValue= {data?.class}
                                        />
                                        {errors.class && <small className="text-danger">{errors.class?.value?.message}</small>}
                                    </div>

                                </div>
                                <div
                                    className="col-6 ps-1"
                                >
                                    <div
                                        className   = "mb-1"
                                    >
                                        <Label>Jabatan</Label>
                                        <Controller
                                            name        = "position"
                                            control     = {control}
                                            render      = {({ field }) => (
                                                <Select
                                                    {...field} 
                                                    className           = {`react-select ${errors.position?.value && "is-invalid"}`}
                                                    classNamePrefix     = "select"
                                                    placeholder         = "Jabatan"
                                                    options             = {positionOption}
                                                    isDisabled          = {true}
                                                />
                                            )}
                                            defaultValue= {data?.position}
                                        />
                                        {errors.position && <small className="text-danger">{errors.position?.value?.message}</small>}
                                    </div>

                                    <div
                                        className   = "mb-1"
                                    >
                                        <Label>Privilage Role</Label>
                                        <Controller
                                            name        = "role"
                                            control     = {control}
                                            render      = {({ field }) => (
                                                <Select
                                                    {...field} 
                                                    className           = {`react-select ${errors.role?.value && "is-invalid"}`}
                                                    classNamePrefix     = "select"
                                                    placeholder         = "Privilage Role"
                                                    options             = {roleOption}
                                                    isDisabled          = {true}
                                                />
                                            )}
                                            defaultValue= {data?.role}
                                        />
                                        {errors.role && <small className="text-danger">{errors.role?.value?.message}</small>}
                                    </div>

                                    <div
                                        className   = "mb-1"
                                    >
                                        <Label>Satuan Kerja</Label>
                                        <Controller
                                            name        = "workunit"
                                            control     = {control}
                                            render      = {({ field }) => (
                                                <Select
                                                    {...field} 
                                                    className           = {`react-select ${errors.workunit?.value && "is-invalid"}`}
                                                    classNamePrefix     = "select"
                                                    placeholder         = "Satuan Kerja"
                                                    options             = {workunitOption}
                                                    isDisabled          = {true}
                                                />
                                            )}
                                            defaultValue= {data?.workunit}
                                        />
                                        {errors.workunit && <small className="text-danger">{errors.workunit?.value?.message}</small>}
                                    </div>

                                    <div
                                        className   = "mb-1"
                                    >
                                        <Label>Struktur/Bidang</Label>
                                        <Controller
                                            name        = "sector"
                                            control     = {control}
                                            render      = {({ field }) => (
                                                <Select
                                                    {...field} 
                                                    className           = {`react-select ${errors.sector?.value && "is-invalid"}`}
                                                    classNamePrefix     = "select"
                                                    placeholder         = "Struktur/Bidang"
                                                    options             = {sectorOption}
                                                    isDisabled          = {true}
                                                />
                                            )}
                                            defaultValue= {data?.sector}
                                        />
                                        {errors.sector && <small className="text-danger">{errors.sector?.value?.message}</small>}
                                    </div>

                                    <div
                                        className   = "my-1"
                                    >
                                        <Label>No. Telepon <small className="text-primary">(Harus Terhubung Dengan Whatsapp)</small></Label>
                                        <Controller
                                            name        = "phone_number"
                                            control     = {control}
                                            render      = {({ field }) => (
                                                <Input
                                                    {...field} 
                                                    invalid             = {errors.phone_number && true}
                                                    placeholder         = "No. Telepon"
                                                    defaultValue        = {data?.name}
                                                />
                                            )}
                                            defaultValue= {data?.phone_number}
                                        />
                                        {errors.phone_number && <small className="text-danger">{errors.phone_number.message}</small>}
                                    </div>
                                    
                                    <div
                                        className   = "mb-0"
                                    >
                                        <Label>Surel</Label>
                                        <Controller
                                            name        = "email"
                                            control     = {control}
                                            render      = {({ field }) => (
                                                <Input
                                                    {...field} 
                                                    invalid             = {errors.email && true}
                                                    placeholder         = "Surel"
                                                    defaultValue        = {data?.name}
                                                />
                                            )}
                                            defaultValue= {data?.email}
                                        />
                                        {errors.email && <small className="text-danger">{errors.email.message}</small>}
                                    </div>
                                </div>
                            </div>
                            
                            <div className="my-1">
								<Label className="form-label" for="bidang">
							    	Pilih Direktorat
								</Label>
								<Controller
									id		= "bidang"
									name	= "bidang"
									render	= {({ field }) => (
										<SelectBidang
											field		= {field}
											onChange    = {(bidang) => { 
                                                if (bidang) {
                                                    field.onChange(bidang.value) 
                                                    if (bidang.label === "Sekretariat Jaksa Agung Muda Bidang Intelijen") {
                                                        setShowDirektoratChild(false)
                                                    } else {
                                                        setShowDirektoratChild(true)
                                                    }
                                                }
                                            }}
											className   = {`react-select ${errors.bidang && "is-invalid"}`}
                                            placeholder = "Pilih Direktorat"
                                            isDisabled  = {true}
										/>
									)}
									control ={control}
								/>
								{errors.bidang && (<small className="text-danger">{errors.bidang.message}</small>)}
							</div>

                            {
                                showDirektoratChild &&
                                    <>
                                        <div className="my-1">
                                            <Label className="form-label" for="subdirektorat">
                                                Pilih Subdirektorat
                                            </Label>
                                            <Controller
                                                id		= "subdirektorat"
                                                name	= "subdirektorat"
                                                render	= {({ field }) => (
                                                    <SelectSubdirektorat
                                                        field	   = {field}
                                                        bidangId   = {watch('bidang')}
                                                        onChange   = {(subdirektorat) => { field.onChange(subdirektorat.value) }}
                                                        className  = {`react-select ${errors.subdirektorat && "is-invalid"}`}
                                                        isDisabled = {true}
                                                    />
                                                )}
                                                control = {control}
                                            />
                                            {errors.subdirektorat && (<small className="text-danger">{errors.subdirektorat.message}</small>)}
                                        </div>

                                        <div className="my-1">
                                            <Label className="form-label" for="seksi">
                                                Pilih Seksi
                                            </Label>
                                            <Controller
                                                id		= "seksi"
                                                name	= "seksi"
                                                render	= {({ field }) => (
                                                    <SelectSeksi
                                                        field	            = {field}
                                                        subdirektoratId	    = {watch('subdirektorat')}
                                                        onChange            = {(seksi) => { field.onChange(seksi.value) }}
                                                        className           = {`react-select ${errors.seksi && "is-invalid"}`}
                                                        isDisabled          = {true}
                                                    />
                                                )}
                                                control ={control}
                                            />
                                            {errors.seksi && (<small className="text-danger">{errors.seksi.message}</small>)}
                                        </div>

                                        <div className="my-1">
                                            <Label className="form-label" for="sector">
                                                Pilih Sektor
                                            </Label>
                                            <Controller
                                                defaultValue= ""
                                                control     = {control}
                                                id          = "sektor"
                                                name        = "sektor"
                                                render      ={({ field }) => (
                                                    <SelectSector
                                                        field		= {field}
                                                        seksiId	    = {watch('seksi')}
                                                        onChange 	= {(sector) => { field.onChange(sector.value) }}
                                                        className	= {`react-select ${errors.sektor && "is-invalid"}`}
                                                        isDisabled  = {true}
                                                    />
                                                )}
                                            />
                                            {errors.sektor && (<small className="text-danger">Isian Tidak Boleh Kosong</small>)}
                                        </div>
                                    </>
                            }
                            
                            <div
                                className   = "mb-0"
                                >
                                <Label>Alamat</Label>
                                <Controller
                                    name        = "address"
                                    control     = {control}
                                    render      = {({ field }) => (
                                        <Input
                                            {...field} 
                                            invalid             = {errors.address && true}
                                            type                = "textarea"
                                            placeholder         = "Alamat"
                                        />
                                    )}
                                    defaultValue= {data?.address}
                                />
                                {errors.address && <small className="text-danger">{errors.address.message}</small>}
                            </div>
                        </div>
                    </ModalBody>

                    <ModalFooter>
                        <div
                            className   = "d-flex justify-content-between col-12"
                        >
                            <Button
                                outline
                                color   = "primary"
                                onClick = {() => {
                                    setShow(false)
                                }}
                            >
                                Batal
                            </Button>
                            
                            <Button
                                outline
                                color   = "primary"
                                onClick = {() => {
                                    setAccount(true)
                                }}
                            >
                                Ubah Kata Sandi
                            </Button>

                            <Button
                                color   = "primary"
                                type    = "submit"
                            >
                                Simpan
                            </Button>
                        </div>
                    </ModalFooter>
                </Form>
            </Modal>

            <Modal
                isOpen  = {account} 
                toggle  = {() => { setAccount(!show) }} 
                size    = "sm"
            >
                <ModalHeader 
                    toggle={() => setAccount(!show)} 
                > 
                    Ubah Kata Sandi
                </ModalHeader>
                <ModalBody>
                    <div
                        className   = "mb-1"
                    >
                        <Label>Kata Sandi Lama</Label>
                        <Controller
							control	= {control}
							render	= {({ field }) => (
								<InputPasswordToggle 
									{...field} 
									className	            = 'input-group-merge' 
									placeholder 	        = 'Kata sandi Lama'
                                    invalid                 = {(validasiPassword && passwordData?.old_password === "")}
                                    onChange                = {(e) => {
                                        const data          = passwordData
                                        data.old_password   = e.target.value
                                        handleChangeInputPassword(data)
                                    }}
								/>
							)}
						/>
                        { validasiPassword && passwordData?.old_password === "" ? <div className="text-danger">Isian Tidak Boleh Kosong!</div> : "" }
                    </div>

                    <div
                        className   = "mb-1"
                    >
                        <Label>Kata Sandi Baru</Label>
                        <Controller
							control	= {control}
							render	= {({ field }) => (
								<InputPasswordToggle 
									{...field} 
									className	            = 'input-group-merge' 
									placeholder 	        = 'Kata sandi Baru'
                                    invalid                 = {(validasiPassword && passwordData?.new_password === "")}
                                    onChange                = {(e) => {
                                        const data          = passwordData
                                        data.new_password   = e.target.value
                                        handleChangeInputPassword(data)
                                    }}
								/>
							)}
						/>
                        { validasiPassword && passwordData?.new_password === "" ? <div className="text-danger">Isian Tidak Boleh Kosong!</div> : "" }
                    </div>

                    <div
                        className   = "mb-1"
                    >
                        <Label>Ulangi Kata Sandi Baru</Label>
                        <Controller
							control	= {control}
							render	= {({ field }) => (
								<InputPasswordToggle 
									{...field} 
									className	            = 'input-group-merge' 
									placeholder 	        = 'Ulangi Kata sandi Baru'
                                    invalid                 = {(validasiPassword && passwordData?.confirm_password === "")}
                                    onChange                = {(e) => {
                                        const data          = passwordData
                                        data.confirm_password   = e.target.value
                                        handleChangeInputPassword(data)
                                    }}
								/>
							)}
						/>
                        { validasiPassword && passwordData?.confirm_password === "" ? <div className="text-danger">Isian Tidak Boleh Kosong!</div> : "" }
                    </div>

                    <div>
                        <Button
                            color           = "primary"
                            className       = "col-12"
                            onClick         = {() => {
                                handleChangePassword()
                            }}
                        >
                            Ubah
                        </Button>
                    </div>
                </ModalBody>
            </Modal>
        </>
    )
}

export default ProfileUpdate