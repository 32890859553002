// ** React Imports
import { Fragment, useContext, useEffect, useState } 		from 'react'

// ** Custom Components
import Avatar 				from '@components/avatar'

// ** Third Party Components
import classnames 			from 'classnames'
import PerfectScrollbar 	from 'react-perfect-scrollbar'
import { 
	X, 
	Check, 
	AlertTriangle, 
	MessageCircle 
} 							from 'react-feather'

// ** Reactstrap Imports
import { 
	Button, 
	Badge, 
	Input, 
	DropdownMenu, 
	DropdownItem, 
	DropdownToggle, 
	UncontrolledDropdown 
} 							from 'reactstrap'
import { useLocation, useNavigate, useParams } 		from 'react-router-dom'
import { ChatContext } from '../../../../context/chatContext'
import Helper from '../../../../helper'
import ListRoom from '../message-component/ListRoom'

const MessageDropdown = () => {

	const {       
		listDataNotification,
		setSelected,

		getListNotification
    } = useContext(ChatContext)

	const navigate 							  = useNavigate()
	const location 							  = useLocation()

	const [active, setActive] 				  = useState(false)
	useEffect(() => {
		// if (active) {
			getListNotification({is_read : false})
		// }
	}, [active])


	return (
		<div>
			{
				location.pathname !== '/chat' && (
					<UncontrolledDropdown tag='li' className='dropdown-notification nav-item me-25'>
						<DropdownToggle 
							tag='a' 
							className='nav-link' 
							href='/' 
							onClick={(e) => {
								e.preventDefault()
								setActive(true)
							}}>
							<MessageCircle size={21} className='text-primary' />
							<Badge pill color='danger' className='badge-up'>
								{listDataNotification?.length}
							</Badge>
						</DropdownToggle>
						<DropdownMenu end tag='ul' className='dropdown-menu-media mt-0'>
							<li className='dropdown-menu-header'>
								<DropdownItem className='d-flex' tag='div' header>
									<h4 className='notification-title mb-0 me-auto'>Percakapan</h4>
									<Badge tag='div' color='light-primary' pill>
										{listDataNotification?.length} New
									</Badge>
								</DropdownItem>
							</li>
							<PerfectScrollbar
								component='li'
								className='media-list scrollable-container'
								options={{
									wheelPropagation: false
								}}
							>
								{Array.isArray(listDataNotification) && listDataNotification.map((item, index) => {
									return (
										<ListRoom 
											item={item}
											index={index}
											active={active}
											setSelected={setSelected}
										/>
									)
								})}
							</PerfectScrollbar>
							<li className='dropdown-menu-footer'>
								<Button 
									color		= 'primary' 
									onClick		= {() => {
										navigate(`/chat`)
									}}
									block
								>
									Lihat Semua
								</Button>
							</li>
						</DropdownMenu>
					</UncontrolledDropdown>

				)
			}
		</div>
			
	)
}

export default MessageDropdown
