import { useState } from 'react'

const ReadMore = ({ children, textLength, isHtml }) => {

    let text

    if (isHtml) {
      const temp  = `${children}`.replace(/<[^>]*>?/gm, '')
      text        = temp
    } else {
      text        = children[1]
    }

    const [isReadMore, setIsReadMore] = useState(true)

    const toggleReadMore = () => {
      setIsReadMore(!isReadMore)
    }

    return (
      <div className='text-start'>
        {
          (text?.length <= textLength) ? <span>{text}</span> : <>
              {isReadMore ? text?.slice(0, textLength) : text}

              <span className='text-primary fw-bold' style={{cursor:'pointer'}} onClick={toggleReadMore}>
              {
                text?.length ? 
                  isReadMore ? 
                    " ...read more" 
                      : " show less" 
                        : ''
              }
              </span>
            </>
        }
      </div>
    )
  }

  export default ReadMore