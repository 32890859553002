import { 
    Button, 
    Modal, 
    ModalBody, 
    ModalFooter, 
    ModalHeader 
}                               from "reactstrap"

//Context
import { ReportContext }        from "../../../context/reportContext"
import { useContext, useState } from "react"
import reportAPI                from "../../../service/report"

const ModalAcceptPerpanjangan = (props) => {

    const {
        getData,
        reportData,
    }                  = props

    const {
        dataSelected,
        setDataSelected,
        originalData,
        setOriginalData,
        setShowModalPreview,
        showModalAccPerpanjangan, 
        setShowModalAccPerpanjangan,

        handleApprovement
    }                                           = useContext(ReportContext)

    const [reportNumber, setReportNumber]       = useState(null)

    const createReportNumber = () => {
		const params = {
            sign_id        : dataSelected?.penandatangan?.user_id,
			report_type_id : reportData?.reportTypeId,
		}

		reportAPI.getReportNumber(params)
		.then(
			res => {
                //set report number
				setReportNumber(res.data.number)

                //update data selected
                const data_ = {...dataSelected}
                data_.nomor_surat = res.data.number
                setDataSelected(data_)

                const original_ = {...originalData}
                original_.nomor_surat = res.data.number
                setOriginalData(original_)

			}
		).catch(
			err => {
				console.log(err, 'create reprt')
			}
		)
	}

    return (
        <>

            <Modal
                size        = "lg"
                isOpen      = {showModalAccPerpanjangan}
                onOpened    = {() => { createReportNumber() }}
                centered    = {true}
            >
                <ModalHeader
                    toggle = {() => {
                        setShowModalAccPerpanjangan(false)
                    }}
                >
                    Persetujuan Perpanjangan Surat
                </ModalHeader>

                <ModalBody className="my-3">
                    <div className="text-center">
                        <strong>{reportData?.reportTitle}</strong> Nomor : {reportNumber}

                        <h3 className="my-1 text-primary"> <strong> MEMINTA PERSETUJUAN PERPANJANGAN SURAT </strong></h3>

                        <p>
                            Surat Ini Telah Melewati Batas Tanggal Yang Telah Ditentukan <strong>({dataSelected?.expired_date}).</strong><br/>dan meminta persetujuan perpanjangan surat dari anda sebagai pimpinan.
                        </p>

                        <h4 className="cursor-pointer mt-3" onClick={() => { setShowModalPreview(true) }}>
                            <u>LIHAT SURAT</u>
                        </h4>
                    </div>
                </ModalBody>
                
                <ModalFooter className="d-flex justify-content-between">
                    <Button
                        color   = "danger"
                        onClick = {async() => { 
                            setShowModalAccPerpanjangan(false) 
                            await handleApprovement(10, null, reportData) 
                            getData()
                        }}
                        outline
                    >
                        Tolak
                    </Button>
                    <Button
                        color   = "primary"
                        onClick = {async() => { 
                            setShowModalAccPerpanjangan(false) 
                            await handleApprovement(9, null, reportData) 
                            getData()
                        }}
                    >
                        Setujui
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default ModalAcceptPerpanjangan