import { param } from 'jquery'
import {get, getBlob, post, put, delete_, patch}  from '../index'

//get
const getReport              = (params) => get(`/report`, params)
const getReportType          = (params) => get(`/report/type/${params}`)
const getAttachment          = (params) => get(`/report/attachment`, params)
const getReportNumber        = (params) => get(`/report/number`, params)
const getReportStatus        = (params) => get(`/report/status`, params) 
const getImageService        = (code) => getBlob(`/drive/source/${code}?thumb=true`)
const getReportTypeAll       = (params) => get(`/report/type`, params)
const getReportCategory      = (params) => get(`/report/category`, params)
const getAdditionalData      = (params) => get(`/report/additional-data`, params)
const getReportCounting      = (params) => get(`/report/counting`, params)
const getReportCountStatus   = (params) => get(`/report/count`, params)
const getReportCountView     = (params) => get(`/report/`, params)

const getReportEditHistory   = (params) => get(`/report/history`, params)
const getStatusApprovement   = (params) => get(`/report/status/approvement`, params)
const getReportConditionLink = (url) => get(`/report${url}`)
const getReportCheck         = (params) => get(`/report/check`, params)

//post
const createStatus           = (formData) => post(`/report/status/create`, formData)
const createReport           = (formData, params) => post(`/report/create`, formData, params)
const createAdditionalData   = (formData) => post('/report/additional-data/create', formData)
const createReportCounting   = (formData) => post('/report/counting/create', formData)
const uploadAttachment       = (formData) => post('/report/attachment/create', formData)
const createReportCountView  = (formData) => post(`/report/viewer`, formData)

//put
const updateReport           = (reportId, formData, params) => put(`/report/update/${reportId}`, formData, params)
const updateAdditionalData   = (id, formData) => put(`/report/additional-data/update/${id}/`, formData)


//delete
const deleteReport           = (reportId) => delete_(`/report/delete/${reportId}`)
const deleteAttachment       = (attachmendId) => delete_(`/report/attachment/delete/${attachmendId}`)
const deleteAdditionalData   = (id) => delete_(`/report/additional-data/delete/${id}`)

//patch 
const updateValue            = (fieldId, fieldValue) => patch(`/report/value/${fieldId}`, fieldValue)

const reportAPI = {
    getReport,
    getReportType,
    getAttachment,
    getReportNumber,
    getReportStatus,
    getReportTypeAll,
    getReportCategory,
    getAdditionalData,
    getReportCounting,
    getReportCountStatus,
    getStatusApprovement,
    getReportConditionLink,
    getReportCheck,
    getImageService,
    getReportCountView,

    createStatus,
    createReport,
    createAdditionalData,
    createReportCounting,
    uploadAttachment,
    createReportCountView,

    updateReport,
    updateAdditionalData,

    deleteReport,
    deleteAttachment,
    deleteAdditionalData,
    getReportEditHistory,

    updateValue
}

export default reportAPI