import { 
    Fragment, 
    useContext, 
    useEffect, 
    useState 
}                                   from "react"

//Third Party
import { 
    Form, 
    Input, 
    Label, 
    Modal, 
    Button, 
    ModalBody, 
    ModalHeader, 
    ModalFooter, 
    FormFeedback
}                                   from "reactstrap"

import { 
    yupResolver 
}                                   from "@hookform/resolvers/yup"

import { 
    ReportContext 
}                                   from "../../../context/reportContext"

import { 
    useForm, 
    Controller
}                                   from "react-hook-form"

import toast                        from "react-hot-toast"
import SubmitButton                 from "../submit-button"
import attachmentRole               from "./validation"
import serviceAPI                   from "../../../service/drive/service"
import reportAPI                    from "../../../service/report"

const ModalForm = props => {

    const {
        show,
        getData,
        onClose,
    } = props

    const {
        dataSelected
    }                               = useContext(ReportContext)

    const [file, setFile]           = useState(null)
    const [hasSubmit, setHasSubmit] = useState(false)
    const [loading, setLoading]     = useState(false)

    const {
        reset,
        setValue,
        getValues,
        control,
        handleSubmit,
        formState: { errors }
    } = useForm({
        mode        : 'onChange', 
        resolver    : yupResolver(attachmentRole)
    })

    const onSubmit = async (formData) => {
        // setHasSubmit(true)
        if (file !== null) {
            setLoading(true)

            const formData_ = new FormData()
            formData_.append("file", file)
            formData_.append("service", dataSelected?.report_id)

            const uploadFileAttachment = await serviceAPI.uploadFileService(formData_)
            
            if (uploadFileAttachment) {
                toast.success("File berhasil ditambahkan.")
                onClose()
                setTimeout(() => {
                    getData()
                }, 1000)
            }

            const params = {
                name            : uploadFileAttachment?.data?.name,
                report_id       : dataSelected?.report_id,
                files_urls      : uploadFileAttachment?.data?.url_file,
                description     : formData.description
            }

            await reportAPI.uploadAttachment(params)

            reset()
            setLoading(false)
        }
    }

    useEffect(() => {
        if (!show) {
            setHasSubmit(false)
            setLoading(false)
            reset()
            setFile(null)
        }
    }, [show])

    return (
        <Fragment>
            <Modal
                isOpen  = {show}
                toggle  = {onClose}
            >
                <ModalHeader toggle={() => onClose()}>  Upload File Pendukung </ModalHeader>

                <Form onSubmit={handleSubmit(onSubmit)}>
                    <ModalBody>
                        <div className="mb-1">
                            <Label> Deskripsi </Label>
                            <Controller
                                id          = 'description'
                                name        = 'description'
                                control     = {control}
                                render      = {({ field }) => (
                                    <Input
                                        {...field}
                                        type        = "textarea"
                                        invalid     = {errors.description && true} 
                                        placeholder = 'Deskripsi'
                                    />
                                )}
                            />

                            {errors.description && <FormFeedback>{errors.description.message}</FormFeedback>}
                        </div>
                        
                        <div className="mb-1">
                            <Label> File </Label>
                            <Controller
                                id          = 'file'
                                name        = 'file'
                                control     = {control}
                                render      = {({ field }) => (
                                    <Input
                                        {...field}
                                        type        = "file"
                                        onChange    = {(e) => {
                                            const file = e.target.files[0]
                                            setFile(file)
                                        }}
                                        invalid     = {(file === null && hasSubmit) && true} 
                                    />
                                )}
                            />
                            {(file === null && hasSubmit) && <div className="text-danger text-sm">Pilih File Terlebih Dahulu</div>}
                        </div>
                    </ModalBody>

                    <ModalFooter className="d-flex justify-content-between">
                        <Button.Ripple
                            color       = "primary"
                            onClick     = {onClose}
                            outline
                        >
                            Batal
                        </Button.Ripple>

                        <SubmitButton
                            loading = {loading}
                            title   = "Tambah"
                            onClick = {() => {
                                setHasSubmit(true)
                            }}
                        />
                    </ModalFooter>
                </Form>
            </Modal>
        </Fragment>
    )
}

export default ModalForm