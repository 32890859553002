import {get, delete_, post}  from '../../index'

//get
const getRecap              = (params)      => get(`/report/recapt`, params)
const getDetailRecap        = (params, id)  => get(`/report/recapt/${id}`, params)

//post
const createRecap           = (formData)    => post('/report/recapt', formData)

//delete
const deleteRecap           = (id)          => delete_(`/report/recapt/${id}`)

const recapitulationAPI = {
    getRecap,
    getDetailRecap,

    createRecap,

    deleteRecap
}

export default recapitulationAPI