import BID_A_1      from "../../../assets/images/icons/sector-icon/BID_A_1.png"
import BID_A_2      from "../../../assets/images/icons/sector-icon/BID_A_2.png"
import BID_A_3      from "../../../assets/images/icons/sector-icon/BID_A_3.png"
import BID_A_4      from "../../../assets/images/icons/sector-icon/BID_A_4.png"
import BID_A_5      from "../../../assets/images/icons/sector-icon/BID_A_5.png"
import BID_A_6      from "../../../assets/images/icons/sector-icon/BID_A_6.png"
import BID_A_7      from "../../../assets/images/icons/sector-icon/BID_A_7.png"
import BID_A_8      from "../../../assets/images/icons/sector-icon/BID_A_8.png"
import BID_A_9      from "../../../assets/images/icons/sector-icon/BID_A_9.png"
import BID_A_10     from "../../../assets/images/icons/sector-icon/BID_A_10.png"
import BID_A_11     from "../../../assets/images/icons/sector-icon/BID_A_11.png"
import BID_A_12     from "../../../assets/images/icons/sector-icon/BID_A_12.png"
import BID_A_13     from "../../../assets/images/icons/sector-icon/BID_A_13.png"
import BID_B_14     from "../../../assets/images/icons/sector-icon/BID_B_14.png"
import BID_B_15     from "../../../assets/images/icons/sector-icon/BID_B_15.png"
import BID_B_16     from "../../../assets/images/icons/sector-icon/BID_B_16.png"
import BID_B_17     from "../../../assets/images/icons/sector-icon/BID_B_17.png"
import BID_B_18     from "../../../assets/images/icons/sector-icon/BID_B_18.png"
import BID_B_19     from "../../../assets/images/icons/sector-icon/BID_B_19.png"
import BID_B_20     from "../../../assets/images/icons/sector-icon/BID_B_20.png"
import BID_B_21     from "../../../assets/images/icons/sector-icon/BID_B_21.png"
import BID_B_22     from "../../../assets/images/icons/sector-icon/BID_B_22.png"
import BID_B_23     from "../../../assets/images/icons/sector-icon/BID_B_23.png"
import BID_B_24     from "../../../assets/images/icons/sector-icon/BID_B_24.png"
import BID_B_25     from "../../../assets/images/icons/sector-icon/BID_B_25.png"
import BID_C_26     from "../../../assets/images/icons/sector-icon/BID_C_26.png"
import BID_C_27     from "../../../assets/images/icons/sector-icon/BID_C_27.png"
import BID_C_28     from "../../../assets/images/icons/sector-icon/BID_C_28.png"
import BID_C_29     from "../../../assets/images/icons/sector-icon/BID_C_29.png"
import BID_C_30     from "../../../assets/images/icons/sector-icon/BID_C_30.png"
import BID_C_31     from "../../../assets/images/icons/sector-icon/BID_C_31.png"
import BID_C_32     from "../../../assets/images/icons/sector-icon/BID_C_32.png"
import BID_C_33     from "../../../assets/images/icons/sector-icon/BID_C_33.png"
import BID_C_34     from "../../../assets/images/icons/sector-icon/BID_C_34.png"
import BID_C_35     from "../../../assets/images/icons/sector-icon/BID_C_35.png"
import BID_C_36     from "../../../assets/images/icons/sector-icon/BID_C_36.png"
import BID_C_37     from "../../../assets/images/icons/sector-icon/BID_C_37.png"
import BID_C_38     from "../../../assets/images/icons/sector-icon/BID_C_38.png"
import BID_C_39     from "../../../assets/images/icons/sector-icon/BID_C_39.png"
import BID_C_40     from "../../../assets/images/icons/sector-icon/BID_C_40.png"
import BID_C_41     from "../../../assets/images/icons/sector-icon/BID_C_41.png"
import BID_D_42     from "../../../assets/images/icons/sector-icon/BID_D_42.png"
import BID_D_43     from "../../../assets/images/icons/sector-icon/BID_D_43.png"
import BID_D_44     from "../../../assets/images/icons/sector-icon/BID_D_44.png"
import BID_D_45     from "../../../assets/images/icons/sector-icon/BID_D_45.png"
import BID_D_46     from "../../../assets/images/icons/sector-icon/BID_D_46.png"
import BID_D_47     from "../../../assets/images/icons/sector-icon/BID_D_47.png"
import BID_D_48     from "../../../assets/images/icons/sector-icon/BID_D_48.png"
import BID_D_49     from "../../../assets/images/icons/sector-icon/BID_D_49.png"
import BID_D_50     from "../../../assets/images/icons/sector-icon/BID_D_50.png"
import BID_D_51     from "../../../assets/images/icons/sector-icon/BID_D_51.png"
import BID_D_52     from "../../../assets/images/icons/sector-icon/BID_D_52.png"
import BID_D_53     from "../../../assets/images/icons/sector-icon/BID_D_53.png"
import BID_D_54     from "../../../assets/images/icons/sector-icon/BID_D_54.png"
import BID_D_55     from "../../../assets/images/icons/sector-icon/BID_D_55.png"
import BID_D_56     from "../../../assets/images/icons/sector-icon/BID_D_56.png"
import BID_D_57     from "../../../assets/images/icons/sector-icon/BID_D_57.png"
import BID_D_58     from "../../../assets/images/icons/sector-icon/BID_D_58.png"
import BID_D_59     from "../../../assets/images/icons/sector-icon/BID_D_59.png"
import BID_D_60     from "../../../assets/images/icons/sector-icon/BID_D_60.png"
import BID_D_61     from "../../../assets/images/icons/sector-icon/BID_D_61.png"
import BID_E_62     from "../../../assets/images/icons/sector-icon/BID_E_62.png"
import BID_E_63     from "../../../assets/images/icons/sector-icon/BID_E_63.png"
import BID_E_64     from "../../../assets/images/icons/sector-icon/BID_E_64.png"
import BID_E_65     from "../../../assets/images/icons/sector-icon/BID_E_65.png"
import BID_E_66     from "../../../assets/images/icons/sector-icon/BID_E_66.png"
import BID_E_67     from "../../../assets/images/icons/sector-icon/BID_E_67.png"
import BID_E_68     from "../../../assets/images/icons/sector-icon/BID_E_68.png"
import BID_E_69     from "../../../assets/images/icons/sector-icon/BID_E_69.png"
import BID_E_70     from "../../../assets/images/icons/sector-icon/BID_E_70.png"
import BID_E_71     from "../../../assets/images/icons/sector-icon/BID_E_71.png"
import BID_E_72     from "../../../assets/images/icons/sector-icon/BID_E_72.png"
import BID_E_73     from "../../../assets/images/icons/sector-icon/BID_E_73.png"
import BID_E_74     from "../../../assets/images/icons/sector-icon/BID_E_74.png"
import BID_E_75     from "../../../assets/images/icons/sector-icon/BID_E_75.png"

const RenderIconSecor = (id) => {
    if (id === 1) {
        return BID_A_1
    } else if (id === 2) {
        return BID_A_2
    } else if (id === 3) {
        return BID_A_3
    } else if (id === 4) {
        return BID_A_4
    } else if (id === 5) {
        return BID_A_5
    } else if (id === 6) {
        return BID_A_6
    } else if (id === 7) {
        return BID_A_7
    } else if (id === 8) {
        return BID_A_8
    } else if (id === 9) {
        return BID_A_9
    } else if (id === 10) {
        return BID_A_10
    } else if (id === 11) {
        return BID_A_11
    } else if (id === 12) {
        return BID_A_12
    } else if (id === 13) {
        return BID_A_13
    } else if (id === 14) {
        return BID_B_14
    } else if (id === 15) {
        return BID_B_15
    } else if (id === 16) {
        return BID_B_16
    } else if (id === 17) {
        return BID_B_17
    } else if (id === 18) {
        return BID_B_18
    } else if (id === 19) {
        return BID_B_19
    } else if (id === 20) {
        return BID_B_20
    } else if (id === 21) {
        return BID_B_21
    } else if (id === 22) {
        return BID_B_22
    } else if (id === 23) {
        return BID_B_23
    } else if (id === 24) {
        return BID_B_24
    } else if (id === 25) {
        return BID_B_25
    } else if (id === 25) {
        return BID_B_25
    } else if (id === 25) {
        return BID_B_25
    } else if (id === 25) {
        return BID_B_25
    } else if (id === 25) {
        return BID_B_25
    } else if (id === 25) {
        return BID_B_25
    } else if (id === 25) {
        return BID_B_25
    } else if (id === 25) {
        return BID_B_25
    } else if (id === 25) {
        return BID_B_25
    } else if (id === 25) {
        return BID_B_25
    } else if (id === 25) {
        return BID_B_25
    } else if (id === 25) {
        return BID_B_25
    } else if (id === 25) {
        return BID_B_25
    } else if (id === 25) {
        return BID_B_25
    } else if (id === 26) {
        return BID_C_26
    } else if (id === 27) {
        return BID_C_27
    } else if (id === 28) {
        return BID_C_28
    } else if (id === 29) {
        return BID_C_29
    } else if (id === 30) {
        return BID_C_30
    } else if (id === 31) {
        return BID_C_31
    } else if (id === 32) {
        return BID_C_32
    } else if (id === 33) {
        return BID_C_33
    } else if (id === 34) {
        return BID_C_34
    } else if (id === 35) {
        return BID_C_35
    } else if (id === 36) {
        return BID_C_36
    } else if (id === 37) {
        return BID_C_37
    } else if (id === 38) {
        return BID_C_38
    } else if (id === 39) {
        return BID_C_39
    } else if (id === 40) {
        return BID_C_40
    } else if (id === 41) {
        return BID_C_41
    } else if (id === 42) {
        return BID_D_42
    } else if (id === 43) {
        return BID_D_43
    } else if (id === 44) {
        return BID_D_44
    } else if (id === 45) {
        return BID_D_45
    } else if (id === 46) {
        return BID_D_46
    } else if (id === 47) {
        return BID_D_47
    } else if (id === 48) {
        return BID_D_48
    } else if (id === 49) {
        return BID_D_49
    } else if (id === 50) {
        return BID_D_50
    } else if (id === 51) {
        return BID_D_51
    } else if (id === 52) {
        return BID_D_52
    } else if (id === 53) {
        return BID_D_53
    } else if (id === 54) {
        return BID_D_54
    } else if (id === 55) {
        return BID_D_55
    } else if (id === 56) {
        return BID_D_56
    } else if (id === 57) {
        return BID_D_57
    } else if (id === 58) {
        return BID_D_58
    } else if (id === 59) {
        return BID_D_59
    } else if (id === 60) {
        return BID_D_60
    } else if (id === 61) {
        return BID_D_61
    } else if (id === 62) {
        return BID_E_62
    } else if (id === 63) {
        return BID_E_63
    } else if (id === 64) {
        return BID_E_64
    } else if (id === 65) {
        return BID_E_65
    } else if (id === 66) {
        return BID_E_66
    } else if (id === 67) {
        return BID_E_67
    } else if (id === 68) {
        return BID_E_68
    } else if (id === 69) {
        return BID_E_69
    } else if (id === 70) {
        return BID_E_70
    } else if (id === 71) {
        return BID_E_71
    } else if (id === 72) {
        return BID_E_72
    } else if (id === 73) {
        return BID_E_73
    } else if (id === 74) {
        return BID_E_74
    } else if (id === 75) {
        return BID_E_75
    } else if (id === 61) {
        return BID_E_76
    } 
    
}

export default RenderIconSecor