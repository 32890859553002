import { param } from 'jquery'
import {get, delete_, post, put, patch}  from '../../index'

//get
const getList               = (params) => get('/user-preference/shortcut?order=-id', params)
const getCategory           = (params) => get('/report/category?order=sequence', params)
const getDetailCategory     = (params) => get('/report/category', params)


//post
const create                = (formData) => post('/user-preference/shortcut/', formData)
const createCategory        = (formData) => post('/report/category', formData)

// put
const update                = (id, formData) => put(`/user-preference/shortcut/${id}`, formData)
const updateCategory        = (id, formData) => put(`/report/category/${id}`, formData)

//delete
const deleteData            = (id) => delete_(`/user-preference/shortcut/${id}`)
const deleteCategory        = (id) => delete_(`/report/category/${id}`)

const FileManagementAPI = {
    // Get
    getList,
    getCategory,
    getDetailCategory,
   
    // Post
    create,
    createCategory,

    // Patch
    update,
    updateCategory,

    // Delete
    deleteData,
    deleteCategory
}

export default FileManagementAPI