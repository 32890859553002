import { useEffect, useState }                  from "react"
import { Button }                               from "reactstrap"
import { CheckCircle, Edit2, Eye, Slash, Trash }             from "react-feather"
import DataTablesGlobal                         from "../../../../@core/components/data-tables-global"
import ModalField                               from "../../../other/ModalField"
import ModalSorting                             from "../../../other/ModalSorting"
import HelpFilter from "./HelpFilter"

const HelpTable = ({
    data,
	params,
	getData,
	loading,
	setParams,
	pagination,
    handleViewCategory,
	setIsCreateModalVisible,
	setIsUpdateModalVisible,
	setIsDeleteModalVisible,
    setIsDetailModalVisible,
    selected,
	setSelected,
	clearSelected,
	setShowActive,
	setClearSelected
}) => {
    
    const [hideColumn, setHideColumn]                       = useState([])
    const [isFilterModalVisible, setIsFilterModalVisible]   = useState(false) 
    const [isSortingModalVisible, setIsSortingModalVisible] = useState(false) 
    const [isHeaderConfigVisible, setIsHeaderConfigVisible] = useState(false) 

    const header        = [      
        {
			omit            : hideColumn[0] === false,
			name            : 'No',
            width           : '50px',
			center          : false,
			wrap            : true,
            compact         : true,
			selector        : row => row.index,
			sortable		: true
		},
		{
			omit            : hideColumn[1] === false,
			name            : 'Judul',
			center          : false,
			wrap            : true,
            compact         : true,
			selector        : row => row.title,
			sortable		: true
		},
        {
			omit            : hideColumn[2] === false,
			name            : 'Kategori',
			center          : false,
			wrap            : true,
            compact         : true,
			selector        : row => handleViewCategory(row.category),
			sortable		: true
		},
        {
			omit            : hideColumn[3] === false,
			name            : 'Ringkasan Isi Bantuan',
			center          : false,
			wrap            : true,
            compact         : true,
			selector        : row => row.content,
			sortable		: true
		},
        {
			omit            : hideColumn[4] === false,
			name            : 'Aksi',
			center          : true,
			wrap            : true,
            compact         : true,
			selector        : row => (
                <>
                    <Button
                        color       = "light"
                        size        = "sm"
                        onClick     = {() => {
                            setClearSelected(true)
                            setSelected(row)
                            setIsDetailModalVisible(true)
                        }}
                    >
                        <Eye
                            size        = {17}
                            className   = "text-primary"
                        />
                    </Button>

                    <Button
                        color       = "light"
                        size        = "sm"
                        onClick     = {() => {
                            setClearSelected(true)
                            setSelected([row])
                            setIsCreateModalVisible(true)
                        }}
                    >
                        <Edit2
                            size        = {17}
                            className   = "text-primary"
                        />
                    </Button>

                    <Button
                        color   = "light"
                        size    = "sm"
                    >
                        {
                            !row.is_active ? 
                                <CheckCircle
                                    size        = {17}
                                    className   = "text-primary"
                                    onClick     = {() => {
                                        setClearSelected(true)
                                        setSelected(row)
                                        setShowActive(true)
                                    }}
                                />
                            :
                                <Slash
                                    size        = {17}
                                    className   = "text-primary"
                                    onClick     = {() => {
                                        setClearSelected(true)
                                        setSelected(row)
                                        setShowActive(true)
                                    }}
                                />
                        }
                    </Button>

                    <Button
                        color       = "light"
                        size        = "sm"
                        onClick     = {() => {
                            setClearSelected(true)
							setSelected([row])
                            setIsDeleteModalVisible(true)
                        }}
                    >
                        <Trash
                            size        = {17}
                            className   = "text-primary"
                        />
                    </Button>
                </>
            ),
			sortable		: true
		}
	] 

    const sortBy = [
        {
            name : "Title",
            value : "title"
        }, {
            name : "Deskrips",
            value : "content"
        }, {
            name : "Tanggal Diubah",
            value : "created_at"
        }
    ]

    useEffect(() => {
		setHideColumn(() => header.map(() => true))
	}, [])

    return (
        <>
            <DataTablesGlobal
                data               		= {data}
                limit              		= {pagination?.page_size}
                header             		= {header}
                params             		= {params}
                getData            		= {getData}
                loading            		= {loading}
                selected           		= {selected}
                totalData          		= {pagination?.count}
                setParams          		= {setParams}
                setSelected        		= {setSelected}
                toggledClearRows   		= {clearSelected}
                setIsCreateModalVisible = {setIsCreateModalVisible}
				setIsDeleteModalVisible = {setIsDeleteModalVisible}
				setIsFilterModalVisible = {setIsFilterModalVisible}
                setIsSortingModalVisible= {setIsSortingModalVisible}
                setIsHeaderConfigVisible= {setIsHeaderConfigVisible}
                visibility  	        = {{
                    create          : true,
                    filter          : true,
                    header_config   : true,
					delete			: true,
                    sorting         : true
                }}
            />

            <ModalField
                show        	= {isHeaderConfigVisible}
                setShow     	= {setIsHeaderConfigVisible}
                size        	= "sm"
                title       	= "Select"
                data        	= {header}
				hideColumn  	= {hideColumn}
				setHideColumn 	= {setHideColumn}
            />

            <HelpFilter
                show        	= {isSortingModalVisible}
                setShow     	= {setIsSortingModalVisible}
                size        	= "sm"
                title       	= "Filter"
                data        	= {sortBy}
                getData         = {getData}
            />
        </>
    )
}

export default HelpTable