import { 
    Button,
    Card,
    CardBody,
    Form,
    FormFeedback,
    Input,
    Label,
    Modal, 
    ModalBody, 
    ModalFooter,
    ModalHeader
}                                       from "reactstrap"

import { 
    useContext,
    useEffect,
    useState 
}                                       from "react"

import { 
    getUserData,
    selectThemeColors 
}                                       from "../../../utility/Utils"

import { Controller, useForm }          from "react-hook-form"
import { yupResolver }                  from "@hookform/resolvers/yup"
import { WorkunitContext }              from "../../../context/workunitContext"
import Select                           from "react-select"
import Flatpickr 			            from "react-flatpickr"
import moment                           from "moment"
import classNames                       from "classnames"
import RecapitulationValidationPeriode  from "./RecapitulationValidationPeriode"
import RecapitulationValidation         from "./RecapitulationValidation"

const RecapitulationForm = ({
    show, 
    setShow,
    size,
    handleCreate
}) => {

    const [kind, setKind]       = useState({
        label   : "Bulan & Tahun",
        value   : "month_year"
    })

    const {
		reset,
		control,
		setValue,
		setError,
		clearErrors,
        watch,
        getValues,
		handleSubmit,
		formState: { errors },
	} = useForm({
		mode        : "onChange",
		resolver    : yupResolver(
            kind?.value === "date_range" ?
                RecapitulationValidationPeriode
            : RecapitulationValidation
        ),
	})

    const {
        workunitOption
    } = useContext(WorkunitContext)

    const [monthOption, setMonthOption]     = useState([])
    const [yearOption, setYearOption]       = useState([])

    const onSubmit = (dataForm) => {
        let payload = {}

        if (kind?.value === "month_year") {
            payload = {
                month : dataForm?.month?.value,
                year  : dataForm?.year?.value,
            }
        } else if (kind?.value === "date_range") {
            payload = {
                start_date : moment(`${moment(dataForm?.start_date).format("YYYY-MM-DD")} 00:00`).unix(),
                end_date   : moment(`${moment(dataForm?.end_date).format("YYYY-MM-DD")} 23:59`).unix(),
            }
        }

        if (getUserData()?.work_unit_id === 1) {
            const temp = []

            if (dataForm.work_unit_id) {
                dataForm.work_unit_id.map((dt) => {
                    temp.push(dt.value)
                })
            }

            payload.work_unit_id = temp
        } else {
            payload.work_unit_id = [getUserData()?.work_unit_id]
        }

        handleCreate(payload)
    }

    const generateYearOption = () => {
        const tahunSekarang = new Date().getFullYear()
        const tahunAwal     = 2023
        const dataTahun       = []

        for (let tahun = tahunSekarang; tahun >= tahunAwal; tahun--) {
            dataTahun.push({
                label: tahun.toString(),
                value: tahun
            })
        }

        setYearOption(dataTahun)
    }

    const generateMonthOption = () => {
        const listYearNumber    = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
        const listYearName      = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"]
        const dataBulan         = []

        listYearNumber.map(async (dt, idx) => {
            dataBulan.push({
                label : `${listYearName[idx]}`,
                value : dt
            })
        })
        
        setMonthOption(dataBulan)
    }

    useEffect(() => {
        generateMonthOption()
        generateYearOption()
    }, [])

    useEffect(() => {
        if (!show) {
            reset()
        }
    }, [show])

    return (
        <>
            <Modal
                isOpen  = {show} 
                toggle  = {() => { setShow(!show) }} 
                size    = {size || "sm"}
            >
                <ModalHeader
                    toggle={() => setShow(!show)} 
                > 
                    Tambah Rekap Data
                </ModalHeader>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <ModalBody>
                        <div
                            className="col-12"
                        >
                            <div className="col-12 mb-1">
                                <div className=""><strong>Pilih Jenis Periode Rekap</strong></div>
                                <Select
                                    className           = {`react-select`}
                                    classNamePrefix     = "select"
                                    placeholder         = "Pilih Jenis Periode Rekap"
                                    options             = {[
                                        {
                                            label   : "Bulan & Tahun",
                                            value   : "month_year"
                                        },
                                        {
                                            label   : "Rentang Tanggal",
                                            value   : "date_range"
                                        }
                                    ]}
                                    onChange            = {(e) => {
                                        setKind(e)
                                    }}
                                    value               = {kind}
                                />
                            </div>

                            {
                                kind?.value === "month_year" &&
                                    <>
                                        <div className="col-12 mb-1">
                                            <div className=""><strong>Pilih Bulan</strong></div>
                                            <Controller
                                                name        = "month"
                                                control     = {control}
                                                render      = {({ field }) => (
                                                    <Select
                                                        {...field} 
                                                        className           = {`react-select ${errors.month ? "is-invalid" : ""}`}
                                                        classNamePrefix     = "select"
                                                        placeholder         = "Pilih Bulan"
                                                        options             = {monthOption}
                                                    />
                                                )}
                                            />
                                            {errors.month && <div className="text-xs text-danger">{errors.month?.value?.message}</div>}
                                        </div>
                                        
                                        <div className="col-12 mb-1">
                                            <div className=""><strong>Pilih Tahun</strong></div>
                                            <Controller
                                                name        = "year"
                                                control     = {control}
                                                render      = {({ field }) => (
                                                    <Select
                                                        {...field} 
                                                        className           = {`react-select ${errors.year ? "is-invalid" : ""}`}
                                                        classNamePrefix     = "select"
                                                        placeholder         = "Pilih Tahun"
                                                        options             = {yearOption}
                                                    />
                                                )}
                                            />
                                            {errors.year && <div className="text-xs text-danger">{errors.year?.value?.message}</div>}
                                        </div>
                                    </>
                            }

                            {
                                kind?.value === "date_range" &&
                                    <>
                                        <div className="col-12 mb-1">
                                            <div className=""><strong>Pilih Tanggal Awal</strong></div>
                                            <Controller
                                                name        = "start_date"
                                                control     = {control}
                                                render      = {({ field }) => (
                                                    <Flatpickr
                                                        {...field}
                                                        onChange	    = {(start_date) => field.onChange(start_date)}
                                                        className		= {classNames("form-control", {
                                                            "is-invalid": errors.start_date && true,
                                                        })}
                                                        options			= {{
                                                            dateFormat	: "d-m-Y",
                                                            time_24hr	: true,
                                                            enableTime	: false,
                                                            static		: true
                                                        }}
                                                        id				= "Tanggal Awal"
                                                        placeholder		= "Tanggal Awal"
                                                        invalid			= {errors.start_date && true}
                                                    />
                                                )}
                                            />
                                            {errors.start_date && <div className="text-xs text-danger">{errors.start_date.message}</div>}
                                        </div>
                                        
                                        <div className="col-12 mb-1">
                                            <div className=""><strong>Pilih Tanggal Akhir</strong></div>
                                            <Controller
                                                name        = "end_date"
                                                control     = {control}
                                                render      = {({ field }) => (
                                                    <Flatpickr
                                                        {...field}
                                                        onChange	    = {(end_date) => field.onChange(end_date)}
                                                        className		= {classNames("form-control", {
                                                            "is-invalid": errors.end_date && true,
                                                        })}
                                                        options			= {{
                                                            dateFormat	: "d-m-Y",
                                                            time_24hr	: true,
                                                            enableTime	: false,
                                                            static		: true
                                                        }}
                                                        id				= "Tanggal Awal"
                                                        placeholder		= "Tanggal Awal"
                                                        invalid			= {errors.end_date && true}
                                                    />
                                                )}
                                            />
                                            {errors.end_date && <div className="text-xs text-danger">{errors.end_date.message}</div>}
                                        </div>
                                    </>
                            }

                            {
                                getUserData()?.work_unit_id === 1 &&
                                    <div className="col-12">
                                        <div className=""><strong>Pilih Satuan Kerja</strong></div>
                                        <Controller
                                            name        = "work_unit_id"
                                            control     = {control}
                                            render      = {({ field }) => (
                                                <Select
                                                    {...field} 
                                                    className           = {`react-select`}
                                                    classNamePrefix     = "select"
                                                    placeholder         = "Pilih Satuan Kerja"
                                                    options             = {workunitOption}
                                                    theme               = {selectThemeColors}
                                                    isMulti
                                                />
                                            )}
                                        />
                                    </div>
                            }
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div
                            className   = "d-flex justify-content-between col-12"
                        >
                            <Button
                                color   = "primary"
                                outline
                                onClick = {() => {
                                    setShow(false)
                                }}
                            >
                                Batal
                            </Button>

                            <Button
                                color   = "primary"
                                type    = "submit"
                            >
                                Simpan
                            </Button>
                        </div>
                    </ModalFooter>
                </Form>
            </Modal>
        </>
    )
}

export default RecapitulationForm