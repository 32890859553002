import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Input,
    ListGroupItem,
    Row
}               from "reactstrap"

import {
    File, 
    Image, 
    Link, 
    Send
}               from "react-feather"

import { useContext, useEffect, useState }             from "react"
import ChatList                 from "./component/ChatList"
import ChatMessages             from "./component/ChatMessages"
import { ChatContext } from "../../context/chatContext"

import "./index.scss"
import CardGroup from "./component/CardGroup"

const Chat = () => {

    const {
        selected, 
        showGroup, 
        setSelected,
    }                                               = useContext(ChatContext)

    return (
            <div
                className="h-100"
            >
                    <Row className="h-100">
                        <Col lg={3}
                            className={`${selected ? 'list-none' : 'list-chat'} `}
                            style={{
                                minHeight : '80vh'
                            }}
                        >
                             <ChatList 
                                selected    = {selected}
                                setSelected = {setSelected}
                            />
                        </Col>
                        <Col 
                            lg={ selected?.room_type === 2 && showGroup ? 6 : 9} 
                            className={` ${selected ? '' : 'chat-message'}`}
                        >
                            <ChatMessages 
                                selected    = {selected}
                            />
                        </Col>
                        <Col 
                            lg={selected?.room_type === 2 && showGroup ? 3 : 12 } 
                            className={` ${selected?.room_type === 2 && showGroup ? 'd-block' : 'd-none'}`}
                        >
                            <CardGroup />
                        </Col>
                    </Row>
                </div>
    )
}

export default Chat