import baseURL, {get, delete_, post, put}  from '../../index'

//get
const getRecyleBin     = (params) => get(`/report/recyclebin/`, params)

//delete
const restore          = (id, params) => delete_(`/report/recyclebin/${id}`, params)
const deletePermanent  = (id, params) => delete_(`/report/recyclebin/${id}?force=true`, params)


const RecyleBinAPI = {
    getRecyleBin,

    restore,
    deletePermanent
}

export default RecyleBinAPI