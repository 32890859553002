import * as yup from "yup"

const WorkunitValidation = yup
	.object()
	.shape({
		parent				: yup.object({value : yup.string().required("Isian Tidak Boleh Kosong")}),
		code				: yup.string().required("Isian Tidak Boleh Kosong"),
		phone_number		: yup.string().required("Isian Tidak Boleh Kosong"),
		work_unit_level_id	: yup.object({value : yup.string().required("Isian Tidak Boleh Kosong")}),
		name				: yup.string().required("Isian Tidak Boleh Kosong"),
		address				: yup.string().required("Isian Tidak Boleh Kosong"),
		latitude			: yup.string().required("Isian Tidak Boleh Kosong"),
		longitude			: yup.string().required("Isian Tidak Boleh Kosong"),
		description			: yup.string().required("Isian Tidak Boleh Kosong"),
	})
	.required()

export default WorkunitValidation
