// ** Icons Import
import { Settings } from 'react-feather'

export default [
  {
    id          : 'configuration',
    icon        : <Settings size={20} />,
    title       : 'Pengaturan',
    action      : 'read',
    navLink     : "/configuration",
    resource    : 'pengaturan'
  }
]
