import React, { useContext, useState } from 'react'
import Select               from 'react-select'
import { UserManagementContext } from '../../../context/userManagementContext'
import { MoreVertical } from 'react-feather'
import { Button, CardFooter } from 'reactstrap'
import { DataManagerContext } from '../../../context/dataManagerContext'
import UserAccess from '../../../@core/components/share/UserAccess'
import driveAPI from '../../../service/pages/drive'

const DetailShare = ({data}) => {

    const {
        employeeOption
    } = useContext(UserManagementContext)

    const {
        expired, 
        password, 
        restrictions, 
        setShowConfiguration
    } = useContext(DataManagerContext)

    const [listData, setListData] = useState([])
    const [dataUser, setDataUser]   = useState(false)
    const [showAccess, setShowAccess]   = useState(false)

    const selectUserFull = (data) => {
        data.map((item) => {
            setListData([...listData, {id : item.value, name : item.label, access : 'read', user_id : item.value}])
        })
    }

    const updateAccess = (data, type) => {
        const updatedUsers = listData.map(user => {
          if (user.id === data.id) {
            return { ...user, access: type }
          }
          return user
        })
      
        setListData(updatedUsers)
      }

    const shareDrive = () => {
        // const userdata = JSON.parse(localStorage.getItem('userData'))

        const formData = {
            code_file    : data.code,
            // owner   : userdata.id,
            recipient  : listData.map(user => user.user_id),
            is_folder :  data.mime.data === 'folder',
            is_password: password && true,
            password: password ? password : null,
            is_expired: expired && true,
            is_restrictions: restrictions && true,
            restrictions_count: restrictions ? restrictions : 0,
            expired_count: expired ? expired : null
        }


        driveAPI.shareDrive(formData)
            .then((res) => {
                if (!res.is_error) {
                    listData.map(user => {
                        driveAPI.UserAccessAPI({id : res.data.id,  user_id : user.user_id, access : user.access})
                        .then(res => {
                            if (!res.is_error) {
                                toast.success('Share Sucessed!')
                                getDrive(folder ? `?dir=${folder.code}` : null)
                                setShow(false)
                            }
                        }).catch((err) => {
                            console.log(err.result.message)
                        })
                    })
                    
                } else {
                    toast.success(err.result.message)
                }
            }).catch((err) => {
                console.log(err.result.message)
            })
    }

  return (
    <div>
        <div className='mb-2'>
            <Select 
                isMulti
                className           = "react-select"
                classNamePrefix     = "select"
                placeholder         = "User"
                options={employeeOption}
                onChange={(e) => selectUserFull(e)}
            />
        </div>

        <div>
            <Button color='primary' className='w-100 mb-1' disabled >Salin Link</Button>
            <Button color='primary' className='w-100' onClick={() => setShowConfiguration(true)}>Pengaturan Tautan</Button>
        </div>

        <div style={{ maxHeight : '400px' }} className='overflow-auto p-1' >
            {
                Array.isArray(listData) && listData.map((data, i) => (
                    <div className='mt-1' key={i}>
                        <div className='d-flex justify-content-between p-1 border rounded'>
                            <div>
                                {data.name}
                            </div>
                            <div>
                            {   
                                data.access === 'read' ? 
                                    'Hanya Bisa Membaca'
                                : data.access === 'upload' ?
                                    "Hanya Bisa Unggah Berkas"
                                : 
                                    "Perbolehkan Unggah dan Ubah Berkas"
                            }
                            </div>
                            <div>
                                <MoreVertical 
                                    onClick={() => {
                                        setShowAccess(true)
                                        setDataUser(data)
                                    }} 
                                />
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>
        <CardFooter className='d-flex justify-content-end'>
            {
                listData.length > 0 && <Button color='primary' onClick={shareDrive}>
                    Submit
                </Button>
            }
            
        </CardFooter>

        <UserAccess 
            show = {showAccess}
            setShow = {setShowAccess}
            dataUser = {dataUser}
            updateAccess = {updateAccess}
            userSelected = {listData}
        />
    </div>
  )
}

export default DetailShare
