import {
    Button,
    Card,
    CardBody,
    Col,
    Input,
    InputGroup,
    Nav,
    NavItem,
    NavLink
}               from "reactstrap"

import {
    MapPin,
    Search,
    User,
    Users
}                   from "react-feather"

import { 
    useEffect, 
    useState 
}                   from "react"
import serviceAPI   from "../../../service/drive/service"
import Helper       from "../../../helper"
import baseURL      from "../../../service"

const tempblob    = []
const tempblobOrg = []

const WidgetTerlibatKejadian = (props) => {

    const {
        data
    } = props

    const { fallbackImage_ }                    = Helper

    const [active, setActive]                   = useState(1)
    const [dataIndividu, setDataIndividu]       = useState([]) 
    const [dataOrganisasi, setDataOrganisasi]   = useState([]) 
    const [keyword, setKeyword]                 = useState("")
    const [listBlob, setListBlob]               = useState([])
    const [listBlobOrg, setListBlobOrg]         = useState([])

    const toggle = tab => {
        if (active !== tab) {
            setActive(tab)

            setKeyword("")
        }
    }

    const tabs = [
        {
            id: 1,
            name: "Individu"
        }, {
            id: 2,
            name: "Organisasi"
        }
    ]

    const getFoto = async(code) => {
        try {
            const file = await serviceAPI.getFileAnywhere(`${baseURL}/drive/source/${code}`)
            const reader = new FileReader()
            reader.onload = () => {
                tempblob.push({
                    code : code,
                    blob : reader.result
                })
                setListBlob(tempblob)
                return reader.result
            }
            reader.readAsDataURL(file)
        } catch (ex) {
            console.log("getFileServiceSource", ex)
        }
        
    }

    const getFotoOrg = async(code) => {
        try {
            const file = await serviceAPI.getFileAnywhere(`${baseURL}/drive/source/${code}`)
            const reader = new FileReader()
            reader.onload = () => {
                tempblobOrg.push({
                    code : code,
                    blob : reader.result
                })
                setListBlobOrg(tempblobOrg)
                return reader.result
            }
            reader.readAsDataURL(file)
        } catch (ex) {
            console.log("getFileServiceSource", ex)
        }
        
    }

    const renderCardResult = (dt, idx, type) => {
        return (
            <>
                <Card color="light"
                    style={{
                        marginBottom : '5px'
                    }}
                >
                    <CardBody 
                        className   = "m-0"
                        style       = {{
                            padding : '8px'
                        }}
                    >
                        <div
                            className   = "d-flex justify-content-between"
                        >
                            <div
                                className   = "d-flex"
                            >
                                <div className      ="d-flex align-items-center">
                                    <img
                                        key         = {`logo_individu_${idx}`}
                                        className   = "me-1 rounded-circle"
                                        src         = {type === "bio" ? listBlob.find((dtBlob) => dtBlob.code === dt.photo)?.blob : listBlobOrg.find((dtBlob) => dtBlob.code === dt.photo)?.blob }
                                        style       = {{
                                            width: '25px',
                                            height: '25px'
                                        }}
                                        onError     = {fallbackImage_}
                                    />
                                </div>
                                <div
                                    className="d-flex align-items-center"
                                >
                                    {dt.name}
                                </div>
                            </div>

                            <div
                                className   = "d-flex align-items-center text-center"
                            >
                                {dt?.count ? dt?.count : 0} Berkas
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </>
        )
    }

    const renderIndividu = () => {
        return (
            <>
                <small>
                    <div
                        className   = "overflow-auto"
                        style       = {{
                            height  : '120px'
                        }}
                        >
                            {
                                dataIndividu !== null &&
                                    dataIndividu?.map((dt, idx) => {
                                        return (
                                            <>
                                                {renderCardResult(dt, idx, "bio")}
                                            </>
                                        )
                                    }
                                )
                            }
                    </div>
                </small>
            </>
        )
    }

    const renderOrganisasi = () => {
        return (
            <>
                <small>
                    <div
                        className   = "overflow-auto"
                        style       = {{
                            height  : '120px'
                        }}
                        >
                            {
                                dataOrganisasi !== null &&
                                    dataOrganisasi?.map((dt, idx) => {
                                        return (
                                            <>
                                                {renderCardResult(dt, idx, "org")}
                                            </>
                                        )
                                    }
                                )
                            }
                    </div>
                </small>
            </>
        )
    }

    const renderContent = () => {
        if (active === 1) {
            return renderIndividu()
        } else if (active === 2) {
            return renderOrganisasi()
        }
    }

    const handleSearch = () => {
        if (active === 1) {
            setDataIndividu([])
            const filteredResults = data?.bio?.filter(item => item?.name.toLowerCase().includes(keyword.toLowerCase()))
            setDataIndividu([...filteredResults])
        } else {
            setDataOrganisasi([])
            const filteredResults = data?.org.filter(item => item?.name.toLowerCase().includes(keyword.toLowerCase()))
            setDataOrganisasi([...filteredResults])
        }
    }

    useEffect(() => {
        setDataIndividu(data?.bio)

        setDataOrganisasi(data?.org)
    }, [data])
    
    useEffect(() => {
        if (dataIndividu) {
            dataIndividu?.map(async (dt) => {
                await getFoto(dt.photo)
            })
        }
    }, [dataIndividu])

    useEffect(() => {
        if (dataOrganisasi) {
            dataOrganisasi?.map(async (dt) => {
                await getFotoOrg(dt.photo)
            })
        }
    }, [dataOrganisasi])

    useEffect(() => {
        if (listBlob) {
            if (dataIndividu?.length !== listBlob.length) {
                setListBlob([...tempblob])
            }
        }
    }, [listBlob])

    useEffect(() => {
        if (listBlobOrg) {
            if (dataOrganisasi?.length !== listBlobOrg.length) {
                setListBlobOrg([...tempblobOrg])
            }
        }
    }, [listBlobOrg])

    return (
        <>
            <Col
                className="pb-0 h-100"
            >
                <Card className="h-100 p-0 m-0">
                    <CardBody>
                        <div className = "d-flex"
                            style={{
                                marginBottom : '0px'
                            }}
                        >
                            <small><strong>Terlibat Kejadian</strong></small>
                        </div>

                        <small>
                            <Nav
                                className   = "border-bottom  mb-1"
                                tabs fill
                            >
                                {
                                    tabs?.map((item) => {
                                        return (
                                            <NavItem>
                                                <NavLink
                                                    active={active === item.id}
                                                    onClick={() => {
                                                        toggle(item.id)
                                                    }}
                                                >
                                                    {item.name}
                                                </NavLink>
                                            </NavItem>
                                        )
                                    })
                                }
                            </Nav>
                        </small>

                        <div
                            style={{
                                marginBottom : '10px'
                            }}
                        >
                            <InputGroup>
                                <Input
                                    size        = "md"
                                    type        = "text"
                                    className   = "form-control-md bg-light"
                                    placeholder = "Cari ..."
                                    onChange    = {(e) => {
                                        setKeyword(e.target.value)
                                    }}
                                    value       = {keyword}
                                />
                                <Button
                                    size        = "sm"
                                    onClick     = {() => handleSearch()}
                                >
                                    <Search
                                        size    = {18}
                                    />
                                </Button>
                            </InputGroup>
                        </div>

                        <div>
                            {renderContent()}
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </>
    )
}

export default WidgetTerlibatKejadian