import { Navigate } 	from 'react-router-dom'
import Profile 			from '../../views/profile'

const ProfileRoutes = [
	{
		path	: '/profile',
		element	: <Profile/>,
		meta	: {
			action: 'read',
			resource: 'data-peta',
			menuCollapsed: true
		}
	}
]

export default ProfileRoutes
