import { 
	selectThemeColors 
} 						from "@utils"

import { 
	useEffect, 
	useRef, 
	useState 
} 						from "react"

import Select 			from "react-select"
import classNames 		from "classnames"
import reportAPI 		from "../../../service/report"
import toast 			from "react-hot-toast"

const SelectSeksi = (props) => {

	const {
		subdirektoratId,
	}					 = props

	const ref = useRef()

	const [sectorOption, setSectorOptions] = useState([])
	const [oldBidang, setOldBidang]		   = useState(null)

	const getData = () => {	
		const params = {
			source 		: 'seksi',
			page_size 	: 100,
			parent		: parseInt(subdirektoratId),
		}

		reportAPI.getAdditionalData(params).then(
			(res) => {
				const sectorOptions_ = []

				if (res.results) {
					res.results.map((data) => (
						sectorOptions_.push({
							label : data.name,
							value : data.id
						})
					))
					setSectorOptions(sectorOptions_)
				} else {
					setSectorOptions([])
				}

			}	
		).catch(() => {})
	}

	const checkFunction = () => {
		if (!subdirektoratId) {
			toast.error('Pilih subdirektorat terlebih dahulu.')
		} else {
			getData()
		}
	}

	useEffect(() => {
		if (oldBidang !== null) {
			if (subdirektoratId !== oldBidang) {
				ref.current.setValue([])
			}
		}

		if (subdirektoratId) {
			checkFunction()
			setOldBidang(subdirektoratId)
		}
	}, [subdirektoratId])

	return (
		<Select
			ref				= {ref}
			theme			= {selectThemeColors}
			value			= {sectorOption.length > 0 ? sectorOption.find(opt => opt.value === props.field.value || opt.label === props.field.value) : null}
			styles			= {{ menu: base => ({ ...base, zIndex: 9999 }) }}
			options			= {sectorOption}
			onChange		= {(e) => { props.onChange(e) }}
			className		= {classNames("react-select", { "is-invalid": props.invalid })}
			isDisabled		= {!(sectorOption.length > 0)}
			placeholder		= "Pilih Seksi"
			classNamePrefix = "select"
			{...props}
		/>
	)
}

export default SelectSeksi
