export default {
    data_bulan: [
        {
            id: 1,
            label: "Semua",
            value: 0,
        },
        {
            id: 2,
            label: "Januari",
            value: 1,
        },
        {
            id: 3,
            label: "Februari",
            value: 2,
        },
        {
            id: 4,
            label: "Maret",
            value: 3,
        },
        {
            id: 5,
            label: "April",
            value: 4,
        },
        {
            id: 6,
            label: "Mei",
            value: 5,
        },
        {
            id: 7,
            label: "Juni",
            value: 6,
        },
        {
            id: 8,
            label: "Juli",
            value: 7,
        },
        {
            id: 9,
            label: "Agustus",
            value: 8,
        },
        {
            id: 10,
            label: "September",
            value: 9,
        },
        {
            id: 11,
            label: "Oktober",
            value: 10,
        },
        {
            id: 12,
            label: "November",
            value: 11,
        },
        {
            id: 13,
            label: "Desember",
            value: 12,
        },
    ],
    
    data_tahun: [
        {
            id: 1,
            label: 2023,
            value: 2023,
        },
        {
            id: 2,
            label: 2024,
            value: 2024,
        },
        {
            id: 3,
            label: 2025,
            value: 2025,
        },
    ]
}