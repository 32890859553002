import React from 'react'
import {
	Button,
	Card,
	CardBody,
	Label,
	Row,
} 										from "reactstrap"
import { XCircle } 			            from "react-feather"
import LabelRequired from '../label-required'

const InputMultipleSumber = (props) => {

    const { onClick, value, invalid, placeholder, setValue } = props

  return (
    <>
		<Card className='mb-0'>
            <CardBody className={`${invalid && "border-danger rounded"}`}>
                <Label>Pilih Kartutik <LabelRequired /></Label>
                <Row> 
                    {value && value.map((data, index) =>  (
						<div className="d-flex justify-content-between">
							<p className="mt-1">
                                {
                                    "nomor_surat" in data ?
                                    data?.nomor_surat
                                :
                                    "judul" in data ?
                                        data?.judul
                                    :
                                        "nama_lengkap" in data ?
                                            data?.nama_lengkap
                                        :
                                            data?.nomor
                                }
                            </p>
							<XCircle
								className="cursor-pointer text-danger mt-1"
								onClick={() => {
									setValue(value.filter(item => item?.report_id !== data?.report_id))
								}}
								size={25}
							/>
						</div>
                    ))}
                </Row>
            </CardBody>
        </Card>
            <Button.Ripple
                className="btn-icon fw-bolder"
                color="flat-primary"
                onClick={onClick}
            >
                [ + Tambah  ]
            </Button.Ripple>
		</>
  )
}

export default InputMultipleSumber