import * as yup from "yup"

export const PrintIn6Schema = yup
	.object()
	.shape({
		sumber_surat				: yup.object().required("Isian Tidak Boleh Kosong"),
		pelaksana					: yup.object().shape({ id : yup.number().required("Isian Tidak Boleh Kosong")}),
		penandatangan1 				: yup.object().shape({ id : yup.number().required("Isian Tidak Boleh Kosong")}),
		tanggal_surat			  	: yup.date().nullable().transform((curr, orig) => (orig === '' ? null : curr)).required("Isian Tidak Boleh Kosong"),
		pokok_masalah				: yup.string().required("Isian Tidak Boleh Kosong"),
	})
	.required()

export const PrintIn7Schema = yup
	.object()
	.shape({
		sumber_surat				: yup.object().required("Isian Tidak Boleh Kosong"),
		penandatangan1 				: yup.object().shape({ id : yup.number().required("Isian Tidak Boleh Kosong")}),
		penandatangan2 				: yup.object().shape({ id : yup.number().required("Isian Tidak Boleh Kosong")}),
		tanggal_surat			  	: yup.date().nullable().transform((curr, orig) => (orig === '' ? null : curr)).required("Isian Tidak Boleh Kosong"),
		tentang						: yup.string().required("Isian Tidak Boleh Kosong"),
	})
	.required()

export const PrintIn8Schema = yup
	.object()
	.shape({
		sumber_surat				: yup.object().required("Isian Tidak Boleh Kosong"),
		kategori					: yup.string().required("Isian Tidak Boleh Kosong"),
		penandatangan1 				: yup.object().shape({ id : yup.number().required("Isian Tidak Boleh Kosong")}).required("Isian Tidak Boleh Kosong"),
		penandatangan2 				: yup.object().shape({ id : yup.number().required("Isian Tidak Boleh Kosong")}).required("Isian Tidak Boleh Kosong"),
		tanggal_surat			  	: yup.date().nullable().transform((curr, orig) => (orig === '' ? null : curr)).required("Isian Tidak Boleh Kosong"),
		tentang						: yup.string().required("Isian Tidak Boleh Kosong"),
	})
	.required()

export const PrintLinSchema = yup
	.object()
	.shape({
		bulan						: yup.number().required("Isian Tidak Boleh Kosong"),
		tahun						: yup.number().required("Isian Tidak Boleh Kosong"),
		penandatangan 				: yup.object().shape({ id : yup.number().required("Isian Tidak Boleh Kosong")}),
		tanggal_surat			  	: yup.date().nullable().transform((curr, orig) => (orig === '' ? null : curr)).required("Isian Tidak Boleh Kosong"),
	})
	.required()

export const PrintLinSchema29 = yup
	.object()
	.shape({
		bulan						: yup.number().required("Isian Tidak Boleh Kosong"),
		tahun						: yup.number().required("Isian Tidak Boleh Kosong"),
		penandatangan1 				: yup.object().shape({ id : yup.number().required("Isian Tidak Boleh Kosong")}),
		penandatangan2 				: yup.object().shape({ id : yup.number().required("Isian Tidak Boleh Kosong")}),
		tanggal_surat			  	: yup.date().nullable().transform((curr, orig) => (orig === '' ? null : curr)).required("Isian Tidak Boleh Kosong"),
	})
	.required()

export const PrintRinSchema = yup
	.object()
	.shape({
		bulan						: yup.number().required("Isian Tidak Boleh Kosong"),
		tahun						: yup.number().required("Isian Tidak Boleh Kosong"),
		tanggal_surat			  	: yup.date().nullable().transform((curr, orig) => (orig === '' ? null : curr)).required("Isian Tidak Boleh Kosong"),
		penandatangan1 				: yup.object().shape({ id : yup.number().required("Isian Tidak Boleh Kosong")}).required("Isian Tidak Boleh Kosong"),
		penandatangan2 				: yup.object().shape({ id : yup.number().required("Isian Tidak Boleh Kosong")}).required("Isian Tidak Boleh Kosong"),
	})
	.required()

export const PrintRinSchema74 = yup
	.object()
	.shape({
		bulan						: yup.number().required("Isian Tidak Boleh Kosong"),
		tahun						: yup.number().required("Isian Tidak Boleh Kosong"),
		jenis_penkum				: yup.string().required("Isian Tidak Boleh Kosong"),
		tanggal_surat			  	: yup.date().nullable().transform((curr, orig) => (orig === '' ? null : curr)).required("Isian Tidak Boleh Kosong"),
		penandatangan1 				: yup.object().shape({ id : yup.number().required("Isian Tidak Boleh Kosong")}).required("Isian Tidak Boleh Kosong"),
		penandatangan2 				: yup.object().shape({ id : yup.number().required("Isian Tidak Boleh Kosong")}).required("Isian Tidak Boleh Kosong"),
	})
	.required()

export const PrintDinSchema = yup
	.object()
	.shape({
		bulan						: yup.number().required("Isian Tidak Boleh Kosong"),
		tahun						: yup.number().required("Isian Tidak Boleh Kosong"),
		penandatangan 				: yup.object().shape({ id : yup.number().required("Isian Tidak Boleh Kosong")}),
		tanggal_surat			  	: yup.date().nullable().transform((curr, orig) => (orig === '' ? null : curr)).required("Isian Tidak Boleh Kosong"),
	})
	.required()
