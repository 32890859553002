const MAPBOX_SKIN  = "mapbox://styles/zephyrfn/ckra22zgd4uh517m5plawan4p"
const MAPBOX_TOKEN = "pk.eyJ1IjoiemVwaHlyZm4iLCJhIjoiY2trNzI5bWN1MDlubDJ1cW94Z3hicm9qdCJ9.HPNjaaL1I5rkMdSg1AJf5g"

const getURLMAP = () => {
    const urlMap = MAPBOX_SKIN.replace(
        "mapbox://",
        "https://api.mapbox.com/"
    ).replace("styles/", "styles/v1/")
    return `${urlMap}/static/[93.8885,-12.85,141.257,7.8559]/800x400?access_token=${MAPBOX_TOKEN}&attribution=false&logo=false`
}

export default getURLMAP