import React, { useEffect, useState } from 'react'
import Avatar       from '@components/avatar'
import moment from 'moment'
import { Col, DropdownItem, DropdownMenu, DropdownToggle, ListGroupItem, Row, UncontrolledDropdown } from 'reactstrap'
import { MoreVertical } from 'react-feather'
import Helper from "../../../helper"
import userManagementAPI from '../../../service/pages/user-management'

const ListRoom = ({
    data, 
    message,
    selected, 
    deleteRoom,
    getMessage, 
    openArchive, 
    archiveChat, 
    setSelected, 
    setShowGroup, 
    unArchiveChat, 
    connectChatSocket
}) => {

    const { getTimeAgo }            = Helper

    const userData                  = JSON.parse(localStorage.getItem('userData'))
    
    const time                      = getTimeAgo(data?.last_message?.created_at ? data?.last_message?.created_at : new Date())

    const [roomName, setRoomName]   = useState(null)

    const getDetailData = (id) => {
        if (id && !isNaN(parseInt(id))) {
            const params = {
                user : id
            }
    
            userManagementAPI.getUserManagement(params).then(
                res => {
                    setRoomName(res.results[0]) 
                }
            )
        }
    }

    useEffect(() => {
        getDetailData(data?.members?.length < 3 && data?.members?.find((item) => item !== userData.user_id))
        // console.log(data?.members?.length < 3 && data?.members?.find((item) => item !== data.admins[0]))
    }, [data])

    return (
        <>
            <ListGroupItem 
                key         = {data.id}
                className   = {`rounded border-0 border-bottom cursor-pointer ${selected?.id === data?.id ? "active" : ""}`}
                onClick     = {() => {
                    setSelected(Object.assign({}, data, { employeeName: roomName?.name, employeePhoto : roomName?.photo }))
                    connectChatSocket(data.uuid)
                }}
            >
                <Row>
                    <Col xs={8} className='d-flex justify-content-start align-items-center'>
                        <Avatar 
                            className   = "me-1"
                            size        = "md"
                            img         = {!roomName?.photo ? `https://ui-avatars.com/api/?name=${data ? roomName?.name : "UN"}&background=4e73df&color=fff&bold=true` : roomName?.photo} 
                            // img         = {`https://ui-avatars.com/api/?name=${data ? data?.name : "UN"}&background=4e73df&color=fff&bold=true`}
                        />
                        <div style={{wordBreak : 'break-all'}}>
                            <strong><small>{ data?.members?.length > 2 ? data.name : roomName?.name}</small> </strong>
                            <div>
                                <small>
                                    {
                                        data?.last_message?.content.length > 10 ? 
                                            `${data?.last_message?.content.substr(0, 10)}...`
                                        : data?.last_message?.content
                                    }
                                </small>
                            </div>
                        </div>
                    </Col>
                    <Col 
                        xs={4} 
                        className='d-flex justify-content-between align-items-center text-muted'
                        style={{wordBreak : 'break-all'}}
                    >
                        
                        <small style={{ fontSize : '10px' }}>{data?.last_message ? time : null}</small> 
                        <UncontrolledDropdown >
                            <DropdownToggle 
                                tag         = 'div' 
                                className   = 'cursor-pointer'
                            >
                                <MoreVertical />
                            </DropdownToggle>
                            <DropdownMenu 
                                className   = 'cursor-pointer'
                            >
                                {
                                    data.room_type === 2 && (
                                        <DropdownItem tag = "a" onClick={() => setShowGroup(true)}>
                                            Lihat Detail Group
                                        </DropdownItem>
                                    )
                                }
                                <DropdownItem tag = "a" onClick={() => deleteRoom(data.uuid)}>
                                    Hapus Percakapan
                                </DropdownItem>
                                {
                                    openArchive ? (
                                        <DropdownItem tag = "a" onClick={() => unArchiveChat(data.uuid)}>
                                            Hapus Arsip
                                        </DropdownItem>
                                    ) : (
                                        <DropdownItem tag = "a" onClick={() => archiveChat(data.uuid)}>
                                            Arsipkan Percakapan
                                        </DropdownItem>
                                    )
                                }
                               
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </Col>
                   
                </Row>
            </ListGroupItem>
        </>
    )
  
}

export default ListRoom
