import { 
    Button,
    Input,
    Label, 
    Modal, 
    ModalBody, 
    ModalFooter, 
    ModalHeader, 
    Nav,
    NavItem,
    NavLink, 
}                       from "reactstrap"

import {
    useState,
    useContext,
    useEffect 
}                       from "react"

import {
    ChevronDown, ChevronUp
}                       from "react-feather"

import { RoleContext }  from "../../../../context/roleContext"

import Select           from 'react-select'
import toast            from "react-hot-toast"

const RoleUpadte = ({
    show,
    setShow,
    title,
    size,
    data,
    getData,
    params
}) => {

    const {
        roleOptionDetault,
        reportType,
        updateRole,
        menus,
        setMenus,
        getMenus
    } = useContext(RoleContext)

    const [feature, setFeature]             = useState([])
    const [reload, setReload]               = useState(false)
    const [selectedRole, setSelectedRole]   = useState(null)
    const [name, setName]                   = useState("")
    const [active, setActive]               = useState(1)

    const tempRecapFeature = {
        name   : "Lainnya",
        report : [
            {
                report_type             : null,
                menu                    : 98,
                type                    : "other",
                name                    : "-",
                description             : "Rekap Data",
                can_create              : false,
                can_read                : false,
                can_update              : false,
                can_delete              : false,
                can_request             : false,
                can_approve_or_reject   : false
            }
        ]
    }

    const tabs = [
        {
            id      : 1,
            name    : "Menu"
        }, {
            id      : 2,
            name    : "Fitur"
        }
    ]

    const handleUpdateChecked = (data) => {
        setFeature(data)
        setReload(true)
    }

    const handleUpdate = () => {

        const action = () => {
            setShow(false)
            getData(params)
        }

        if (name === "") {
            toast.error("Isi Nama Terlebih Dahulu!")
        } else if (selectedRole === null) {
            toast.error("Pilih Role Terlebih Dahulu!")
        } else if (selectedRole === null) {
            toast.error("Pilih Role Terlebih Dahulu!")
        } else {
            
            const payload = {
                name        : name,
                role        : selectedRole.value,
                menu_ids    : [1, 2, 14, 26, 43, 57, 80, 96, 97, 98, 99, 102, 103],
                feature_ids : []
            }
    
            const _feature = []
            feature.map((dt) => {
                dt.report.map((report) => {
                    _feature.push(report)
                })
            })
            updateRole(payload, _feature, menus, data.id, action)
        }
    }

    const toggle = tab => {
        if (active !== tab) {
            setActive(tab)
        }
    }

    const renderCardCheckList = (dt, idx) => {
        return (
            <>
                <div className="col-12 mb-1"><strong>{dt.name}</strong></div>
                <div className="mb-2">
                {
                    dt.report?.map((item, index) => {
                        return (
                            <>
                                <div className="col-12 d-flex">
                                    <div className="col-6 d-flex">
                                        <div style={{minWidth : '80px'}}><strong>{item.name}</strong></div>
                                        {item.description}
                                    </div>
                                    <div className="col-1 text-center">
                                        <Input 
                                            type        = "checkbox" 
                                            className   = "cursor-pointer" 
                                            checked     = {item.can_create}
                                            onChange    = {(e) => {
                                                const temp = feature
                                                temp[idx].report[index].can_create = e.target.checked
                                                handleUpdateChecked(feature)
                                            }}
                                        />
                                    </div>
                                    <div className="col-1 text-center">
                                        <Input 
                                            type        = "checkbox" 
                                            className   = "cursor-pointer" 
                                            checked     = {item.can_read}
                                            onChange    = {(e) => {
                                                feature[idx].report[index].can_read = e.target.checked
                                                handleUpdateChecked(feature)
                                            }}
                                        />
                                    </div>
                                    <div className="col-1 text-center">
                                        <Input 
                                            type        = "checkbox" 
                                            className   = "cursor-pointer" 
                                            checked     = {item.can_update}
                                            onChange    = {(e) => {
                                                feature[idx].report[index].can_update = e.target.checked
                                                handleUpdateChecked(feature)
                                            }}
                                        />
                                    </div>
                                    <div className="col-1 text-center">
                                        <Input 
                                            type        = "checkbox" 
                                            className   = "cursor-pointer" 
                                            checked     = {item.can_delete}
                                            onChange    = {(e) => {
                                                feature[idx].report[index].can_delete = e.target.checked
                                                handleUpdateChecked(feature)
                                            }}
                                        />
                                    </div>
                                    <div className="col-1 text-center">
                                        <Input 
                                            type        = "checkbox" 
                                            className   = "cursor-pointer" 
                                            checked     = {item.can_request}
                                            onChange    = {(e) => {
                                                feature[idx].report[index].can_request = e.target.checked
                                                handleUpdateChecked(feature)
                                            }}
                                        />
                                    </div>
                                    <div className="col-1 text-center">
                                        <Input 
                                            type        = "checkbox" 
                                            className   = "cursor-pointer" 
                                            checked     = {item.can_approve_or_reject}
                                            onChange    = {(e) => {
                                                feature[idx].report[index].can_approve_or_reject = e.target.checked
                                                handleUpdateChecked(feature)
                                            }}
                                        />
                                    </div>
                                </div>
                            </>
                            )
                        })
                    }
                </div>
                <hr />
            </>
        )
    }

    const renderFeatureForm = () => {
        return (
            <>
                <div>
                    <div
                        className   = "mb-1 col-12"
                    >
                        <Label>Checklist Fitur</Label>
                        <div
                            className = "col-12 d-flex mt-1"
                        >
                            <div className="col-6"></div>
                            <div className="col-1 text-center">Create</div>
                            <div className="col-1 text-center">Read</div>
                            <div className="col-1 text-center">Update</div>
                            <div className="col-1 text-center">Delete</div>
                            <div className="col-1 text-center">Request</div>
                            <div className="col-1 text-center">Acc/Reject</div>
                        </div>
                        <div
                            className="overflow-auto"
                            style={{
                                maxHeight : '550px'
                            }}
                        >
                            {
                                feature !== null &&
                                    feature?.map((dt, idx) => {
                                        return (
                                            <>
                                                {renderCardCheckList(dt, idx)}
                                            </>
                                            )
                                    })
                            }
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const renderMenusForm = () => {
        return (
            <>
                {
                    menus?.map((dt) => {
                        return (
                            <>
                                <div
                                    className   = ""
                                >
                                    <div className="d-flex justify-content-between ps-2 pe-2 py-1 border-bottom bg-light">
                                        <div
                                            className   = "d-flex"
                                        >
                                            <Button
                                                className   = "btn-icon p-0 me-1"
                                                size        = "sm" 
                                                color       = "primary"
                                                outline
                                                onClick     = {() => {
                                                    dt.expand = !dt.expand
                                                    setMenus([...menus])
                                                }}
                                            >
                                                {
                                                    !dt.expand ? <ChevronUp size="20" /> : <ChevronDown size="20" />
                                                }
                                            </Button>
                                            <div className="text-start">
                                                {dt.name}
                                            </div>
                                        </div>

                                        <div className="d-flex">
                                            {dt.name === "Dashboard" && <small><div className="me-1 text-primary text-xs">(Default)</div></small>}
                                            <Input 
                                                type        = "checkbox" 
                                                className   = "cursor-pointer" 
                                                checked     = {dt.checked}
                                                onChange    = {(e) => {
                                                    dt.checked = e.target.checked

                                                    if (!e.target.checked) {
                                                        const temp = []
                                                        dt.children.map((dtChild) => {
                                                            dtChild.checked = e.target.checked
                                                            temp.push(dtChild)
                                                        })
                                                        dt.children = temp
                                                    }
                                                    setMenus([...menus])
                                                }}
                                                disabled    = {dt.name === "Dashboard"}
                                            />
                                        </div>
                                    </div>

                                    {
                                        (dt.expand) &&
                                        <div 
                                            className   = {`${(dt.children?.length > 0) ? "d-block" : "d-block"} col-12`}
                                        >
                                            {
                                                dt.children?.length > 0 ?
                                                <div
                                                    className="d-flex ps-2 pe-2 border-bottom bg-white"
                                                >
                                                    <div 
                                                        className   = ""
                                                        style       = {{
                                                            width : '1px',
                                                            border: `1px dashed #CCC`
                                                        }}    
                                                    />
                                                    <div className="flex-grow-1 py-1">
                                                        {
                                                            dt?.children?.map((dtChild) => {
                                                                return (
                                                                    <>
                                                                        <div
                                                                            className   = "d-flex justify-content-between border-bottom"
                                                                        >
                                                                            <div
                                                                                className   = "d-flex align-items-center"
                                                                            >
                                                                                <hr
                                                                                    style   = {{
                                                                                        width   : '20px',
                                                                                        border  : `1px dashed #CCC`
                                                                                    }}
                                                                                    className   = "me-1"
                                                                                />
                                                                                <div className="w-full">
                                                                                    {dtChild.name}
                                                                                </div>
                                                                            </div>

                                                                            <div
                                                                                className   = "d-flex align-items-center"
                                                                            >
                                                                                <Input 
                                                                                    type        = "checkbox" 
                                                                                    className   = "cursor-pointer" 
                                                                                    checked     = {dtChild.checked}
                                                                                    onChange    = {(e) => {
                                                                                        dtChild.checked = e.target.checked
                                                                                        setMenus([...menus])
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )
                                                            })
                                                        }
                                                    
                                                    </div>
                                                </div>
                                                :   <div
                                                        className   = "alert alert-danger p-2"
                                                    >
                                                        Data Child Menu Tidak Ditemukan!
                                                    </div>
                                            }
                                        </div>
                                    }
                                </div>
                            </>
                        )
                    })
                }
            </>
        )
    }

    const renderContent = () => {
        if (active === 1) {
            return renderMenusForm()
        } else if (active === 2) {
            return renderFeatureForm()
        }
    }

    useEffect(() => {
        setFeature([
            ...reportType,
            // tempRecapFeature
        ])
    }, [reportType])

    useEffect(() => {
        const temp = roleOptionDetault.filter((dt) => dt.value === data?.role)
        if (temp?.length > 0) {
            setSelectedRole(temp[0])
        }
        setName(data?.name)

        const tempFeature = []
        reportType.map((report) => {
            const tempReport = []
            report.report.map((dt) => {
                const temp = data?.features?.filter((item) => item.report_type === dt.report_type)
                if (temp?.length > 0) {
                    const selected = temp[0]
                    tempReport.push({
                        id                       : selected.id,
                        can_approve_or_reject    : selected.can_approve_or_reject,
                        can_create               : selected.can_create,
                        can_delete               : selected.can_delete,
                        can_read                 : selected.can_read,
                        can_request              : selected.can_request,
                        can_update               : selected.can_update,

                        report_type             : dt.report_type,
                        type                    : dt.type,
                        name                    : dt.name,
                        description             : dt.description,
                    })
                } else {
                    tempReport.push(dt)
                }
            })

            tempFeature.push({
                name    : report.name,
                report  : tempReport
            })
        })

        const tempRecap = data?.features?.filter((item) => item.menu === 98)

        if (tempRecap?.length > 0) {
            const selected = tempRecap[0]
            tempFeature.push({
                name   : "Lainnya",
                report : [
                    {
                        id                       : selected.id,
                        can_approve_or_reject    : selected.can_approve_or_reject,
                        can_create               : selected.can_create,
                        can_delete               : selected.can_delete,
                        can_read                 : selected.can_read,
                        can_request              : selected.can_request,
                        can_update               : selected.can_update,
        
                        report_type             : null,
                        menu                    : 98,
                        type                    : "other",
                        name                    : "-",
                        description             : "Rekap Data",
                    }
                ]
            })
        }

        if (tempFeature?.length > 0) {
            handleUpdateChecked(tempFeature)
        }
        
        getMenus(data?.id)
    }, [data])

    useEffect(() => {
        if (reload) {
            setReload(false)
        }
    }, [reload])

    useEffect(() => {
        if (!show) {
            reportType?.map((report) => {
                report?.report?.map((dt) => {
                    dt.can_create              = false
                    dt.can_read                = false
                    dt.can_delete              = false
                    dt.can_request             = false
                    dt.can_approve_or_reject   = false
                })
            })
            setFeature([
                ...reportType,
                // tempRecapFeature
            ])
        } 
    }, [show])

    return (
        <>
            <Modal
                isOpen  = {show} 
                toggle  = {() => { setShow(!show) }} 
                size    = {size || "sm"}
            >
                <ModalHeader 
                    toggle={() => setShow(!show)} 
                > 
                    {title}
                </ModalHeader>
                <ModalBody>
                    <div>
                        <div
                            className   = "mb-1"
                        >
                            <Label>Nama</Label>
                            <Input
                                placeholder         = "Nama"
                                onChange            = {(e) => {
                                    setName(e.target.value)
                                }}
                                value               = {name}
                            />
                        </div>
                        <div
                            className   = "mb-1"
                        >
                            <Label>Role</Label>
                            <Select
                                className           = "react-select"
                                classNamePrefix     = "select"
                                placeholder         = "Pilih Role"
                                onChange            = {(e) => setSelectedRole(e)}
                                options             = {roleOptionDetault}
                                value               = {selectedRole}
                            />
                        </div>
                        

                        <Nav
                            className="border-bottom"
                            tabs fill
                        >
                            {
                                tabs?.map((item, index) => {
                                    return (
                                        <NavItem key = {`configuration_tab_${index}`}>
                                            <NavLink
                                                active={active === item.id}
                                                onClick={() => {
                                                    toggle(item.id)
                                                }}
                                            >
                                                {item.name}
                                            </NavLink>
                                        </NavItem>
                                    )
                                })
                            }

                        </Nav>

                        {renderContent()}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div
                        className   = "d-flex justify-content-between col-12"
                    >
                        <Button
                            color   = "primary"
                            outline
                            onClick = {() => {
                                setShow(false)
                            }}
                        >
                            Batal
                        </Button>


                        <Button
                            color   = "primary"
                            type    = "submit"
                            onClick = {() => {
                                handleUpdate()
                            }}
                        >
                            Simpan
                        </Button>
                        </div>
                    </ModalFooter>
            </Modal>
        </>
    )
}

export default RoleUpadte