import { Navigate } 	from 'react-router-dom'
import Chat 			from '../../views/chat'

const ChatRoutes = [
	{
		path	: '/chat',
		element	: <Chat/>,
		meta	: {
			action: 'read',
			resource: 'data-peta',
			menuCollapsed: true
		}
	}
]

export default ChatRoutes
