import { 
    FormGroup,
    Input,
    InputGroup, 
    Label, 
    Modal, 
    ModalBody, 
    ModalHeader,
    CustomInput,
    ModalFooter,
    Button,
    FormFeedback,
    FormText
}                       from "reactstrap"

import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { CKEditor }     from 'ckeditor4-react'
import '@styles/react/libs/editor/editor.scss'
import Select from "react-select"
import HelpDeskAPI from "../../../../service/pages/helpdesk"
import { useEffect, useState } from "react"
import toast from "react-hot-toast"


const schema = yup.object().shape({
    title: yup.string().required('Title is required'),
    content: yup.string().required('Ringkasan is required'),
    category: yup.object().nullable().required('Pilih Kategori!'),
  })
  
const HelpCreate = ({
    show,
    setShow,
    getData,
    title,
    data,
    setData,
    size,
    handleViewCategory,
    getGuideCategory,
    optionGuideCategory
}) => {


    const [labelSelect, setLabelSelect] = useState(false)
    const { handleSubmit, setValue, control, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
      })

    const create = (formData) => {

        const dataForm = {
            title : formData.title,
            category : formData.category?.value,
            content : formData.content,
            is_active: true
        }

        HelpDeskAPI.createGuide(dataForm)
            .then((res) => {
                getData()
                toast.success(res.message)
                setShow(false)
                setData(false)
            }).catch((err) => {
                toast.error(err.message)
            })


    }

    const update = (id, formData) => {

        const dataForm = {
            title : formData.title,
            category : formData.category?.value,
            content : formData.content,
            is_active: true
        }

        HelpDeskAPI.updateGuide(id, dataForm)
            .then((res) => {
                getData()
                toast.success(res.message)
                setShow(false)
            }).catch((err) => {
                toast.error(err.message)
            })
    }

    const onSubmit = (formData) => {
        if (data.length > 0) {
            update(data[0].id, formData)    
        } else {
            create(formData)    
        }
    }

      useEffect(() => {
        getGuideCategory()
        
      }, [])

      useEffect(() => {
        if (data.length > 0) {
			setValue("category", {label : handleViewCategory(data[0].category), value : data[0].category})
			setValue("title", data[0].title)
			setValue("content", data[0].content)
		} else {
            setValue("category", null)
			setValue("title", '')
			setValue("content", '')
        }
      }, [data])

    return (
        <>
            <Modal
                isOpen  = {show} 
                // toggle  = {() => { setShow(!show) }} 
                size    = {size || "sm"}
            >
                <ModalHeader 
                    toggle={() => {
                        setShow(!show)
                        setData(false)
                    }} 
                > 
                    {title}
                </ModalHeader>
                <form onSubmit={handleSubmit(onSubmit)} >
                    <ModalBody>
                        <div className="form-group mb-1">
                            <Label htmlFor="title">Judul</Label>
                            <Controller
                                name        ="title"
                                control     ={control}
                                render      ={({ field }) => (
                                    <Input
                                        {...field} 
                                        invalid             = {errors.title && true}
                                        placeholder         = "Deskripsi"
                                    />
                                )}
                            />
                                {errors.title && <FormFeedback>{errors.title.message}</FormFeedback>}
                        </div>
                        <div className="form-group mb-1">
                            <Label htmlFor="category">Pilih Kategori</Label>
                                <Controller
                                    name            = "category"
                                    control         = {control}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            options     =   {optionGuideCategory}
                                            isClearable
                                            placeholder = 'Kategori'
                                        />
                                    )}
                                />
                                {errors.category && (
                                    <p style={{ color : 'red', fontSize : '12px' }}>
                                        {errors.category.message}
                                    </p>    
                                )}
                        </div>
                        <div className="form-group mb-1">
                            <Label htmlFor="content">Ringkasan</Label>
                            <Controller
                                name        ="content"
                                control     ={control}
                                defaultValue= {data ? data.content : null}
                                render      ={({ field }) => (
                                    <Input
                                        {...field} 
                                        invalid             = {errors.content && true}
                                        placeholder         = "Deskripsi"
                                        type="textarea"
                                    />
                                )}
                            />
                                {errors.content && <FormFeedback>{errors.content.message}</FormFeedback>}
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div
                            className   = "d-flex justify-content-between col-12"
                        >
                            <Button
                                color   = "primary"
                                outline
                                onClick = {() => {
                                    setShow(false)
                                    setData(false)
                                }}
                            >
                                Batal
                            </Button>

                            <Button
                                color   = "primary"
                                type="submit"
                            >
                                Simpan
                            </Button>
                        </div>
                    </ModalFooter>
                </form>
            </Modal>
        </>
    )
}

export default HelpCreate