// ** Logo
import logo from '@src/assets/images/logo/logo_kejagung.svg'

const SpinnerComponent = () => {
  return (
    <div className='fallback-spinner app-loader'>
      <img className='fallback-logo' src={logo} alt='logo' />
    </div>
  )
}

export default SpinnerComponent
