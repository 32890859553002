import React, { useState } from 'react'
import { Search } from 'react-feather'
import { Button, FormGroup, Input, InputGroup, InputGroupText, ListGroup, ListGroupItem, Media, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import Avatar from '../avatar'

const UserShare = ({
        show,
        getData,
        setShow,
        listData,
        userSelected,
        selectUserFull,
}) => {
    const userData =  JSON.parse(localStorage.getItem('userData'))
    const [keyword, setKeyword] = useState(false)

    const ListUser = listData.filter((data) => data.user_id  !== userData.user_id)

  return (
    <Modal
    isOpen={show}
    toggle={() => setShow(!show)}
    size='sm'
>
    <ModalHeader toggle={() => setShow(!show)}>Share</ModalHeader>
    <ModalBody>
        <FormGroup>
            <InputGroup className='input-group-merge'>
                {/* <InputGroupAddon addonType='prepend'> */}
                    <InputGroupText>
                        <Search size={14} />
                    </InputGroupText>
                {/* </InputGroupAddon> */}
                <Input 
                    onChange    = {(e) => { setKeyword(e.target.value) }} 
                    placeholder = 'Masukkan Nama Pengguna ...' 

                />
                <Button 
                    color      = 'primary' 
                    outline
                    onClick    = {() => { getData({keyword : keyword}) }} 
                >
                    Cari
                </Button>
            </InputGroup>
        </FormGroup>
        <ListGroup style={{ maxHeight : '400px' }} className='overflow-auto'>
            {
                Array.isArray(ListUser) && ListUser.map((data, index) => (
                    <ListGroupItem 
                        key         = {index} 
                        active      = {userSelected.map((data) => data.id).indexOf(data.id) !== -1 ? "true" : "" } 
                        onClick     = {() => { selectUserFull(data) }} 
                        className   = "cursor-pointer" 
                    >
                        <Media className='d-flex'>
                            <Media left href='#' className='me-1'>
                                <Avatar 
                                    // onError={fallbackImage_} 
                                    img={data.photo === "" ? `https://ui-avatars.com/api/?name=${data ? data.name : "UN"}&background=4e73df&color=fff&bold=true` : data.photo} 
                                    imgHeight='40' 
                                    imgWidth='40' />
                            </Media>
                            <Media body >
                                <Media header className="mb-0 ml-1">{data.name}</Media>
                                <h6 className="text-muted ml-1 mt-0">{data.position?.name}</h6>
                            </Media>
                        </Media>
                    </ListGroupItem>
                ))
            }
        </ListGroup>
    </ModalBody>
    <ModalFooter>
    <Button 
        onClick     = {() => setShow(false)}
        className   ='w-100'
    >
        Selesai
    </Button>
    </ModalFooter>
</Modal>
  )
}

export default UserShare
