import React, { useContext, useEffect, useState } from 'react'

//Third Party
import { Button, Modal, ModalBody, ModalHeader }        from 'reactstrap'
import { ChevronRight, ChevronLeft, ArrowRight, ArrowLeft } from 'react-feather'
import { ReportContext }            from '../../../context/reportContext'
import DetailReportPreview from './detail-report-preview'

const ModalPreview = (props) => {

    const {
        show,
        data,
        onClose,
        handlePDF,
        setDataSelected,
    }                                       = props

    const {
        data : listData,
		countCopies,
        handleUpdateCountCopies
	}				                        = useContext(ReportContext)

    const [dataUrl, setDataUrl]             = useState("")
    const [prevStatus, setPrevStatus]       = useState(true)
    const [nextStatus, setNextStatus]       = useState(true)
    const [fetchStatus, setFetchStatus]     = useState(false)
    const [showDetail, setShowDetail]       = useState(false)

    const handlePdfType = async() => {
		//update count
		// await handleUpdateCountCopies(data?.report_id, "pdf")

        if (handlePDF) {
            await handlePDF(data, setDataUrl, countCopies?.pdf)
        }
	}

    const prevPreview = () => {
        setFetchStatus(true)
        const indexSelectedData = listData.findIndex((val) => val.report_id === data?.report_id)

        const prevData = listData[indexSelectedData - 1]
        if (indexSelectedData === 0) {
            setDataSelected(listData[0])
            setPrevStatus(false)
        } else {
            setDataSelected(prevData)
            setNextStatus(true)
        }
    }
    
    const nextPreview = () => {
        setFetchStatus(true)
        const indexSelectedData = listData.findIndex((val) => val.report_id === data?.report_id)
        const nextData = listData[indexSelectedData + 1]
        if (nextData) {
            setDataSelected(nextData)
            setPrevStatus(true)
        } else {
            setDataSelected(listData[listData.length - 1])
            setNextStatus(false)
        }
    }

    useEffect(() => {
        const indexData = listData?.findIndex((val) => val.report_id === data?.report_id)
    
        if (indexData === 0) {
            setPrevStatus(false)
        } else {
            setPrevStatus(true)
        }
    
        if (listData?.length - 1 === indexData) {
            setNextStatus(false)
        } else {
            setNextStatus(true)
        }

        if (fetchStatus) {
            handlePdfType()
        }
    }, [data])

  return (
    <div>
        <Modal
            // style       = {{maxWidth: '800px', width: 800, height:1000}}
            isOpen      = {show}
            size        = "xl"
            onOpened    = {() => handlePdfType()}
            onClosed    = {() => { 
                setDataUrl("") 
            }}
        >
            <ModalHeader
                toggle={() => {
                    onClose()
                    setFetchStatus(false)
                }}
            >
                <Button
                    size        = "sm"
                    className   = "btn-icon me-1"
                    onClick     = {() => {
                        setShowDetail(!showDetail)
                    }}
                >
                    {
                        !showDetail ? (
                            <ArrowRight size="17" />
                        ) : (
                            <ArrowLeft size="17" />
                        )
                    }
                </Button> Preview
            </ModalHeader>

            <div className='p-0 m-0'>
                
                <div className='d-flex justify-content-center'>
                    {
                        showDetail &&
                            <div 
                                style={{
                                    width       : '100%',
                                    height      : 950,
                                    position    : 'absolute',
                                    float       : 'inline-start'
                                }}
                                className='p-1 bg-light overflow-auto'
                            >
                                <DetailReportPreview 
                                />
                            </div>
                    }
                    {/* <ChevronLeft 
                        size        = {35} 
                        style       = {{backgroundColor:"gray", fill:"white"}}
                        onClick     = {prevPreview}
                        className   = {`position-absolute top-50 start-0 translate-middle-y ms-3 shadow-sm rounded-circle ${!prevStatus && "d-none"}`} 
                    /> */}
                    <embed 
                        src     = {`${dataUrl}`}
                        type    = 'application/pdf' 
                        height  = {950} 
                        width   = {2000} 
                    />
                    {/* <ChevronRight 
                        size        = {35} 
                        style       = {{backgroundColor:"gray", fill:"white"}}
                        onClick     = {nextPreview} 
                        className   = {`position-absolute top-50 end-0 translate-middle-y me-3 shadow-sm rounded-circle ${!nextStatus && "d-none"}`} 
                    /> */}
                </div>
            </div>
        </Modal>
    </div>
  )
}

export default ModalPreview
