import React, {
	useState
} 					from 'react'

import {
	MoreHorizontal,
	MoreVertical
} 					from 'react-feather'

import {
	Dropdown,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	UncontrolledDropdown
} 					from 'reactstrap'

const DropdownTableRecyleBin = ({
	data,
	row,
	showRestore,
	showDelete,
	setSelected,
	setClearSelected
}) => {

	const [dropdownOpen, setDropdownOpen] = useState(false)
	
	const toggle = () => {
		setDropdownOpen((prevState) => !prevState)
	}

	return (
		<>
			<UncontrolledDropdown >
				<Dropdown 
					isOpen		= {dropdownOpen} 
					toggle		= {toggle} 
					direction	= 'down'
				>
					<DropdownToggle
						color='none'
					>
						<MoreHorizontal className="cursor-pointer" />
					</DropdownToggle>
					<DropdownMenu className={`bg-white ${data?.length <= 3 && "position-fixed"} `} >
						<DropdownItem className='w-100 h-100' onClick={() => {
							setClearSelected(true)
							setSelected([row])
							showRestore(true)
						}}>Kembalikan Data</DropdownItem>
						
						<DropdownItem divider />
						<DropdownItem className='w-100 h-100' onClick={() => {
							setClearSelected(true)
							setSelected([row])
							showDelete(true)
						}}>Hapus Permanen</DropdownItem>
					</DropdownMenu>	
				</Dropdown>
			</UncontrolledDropdown>
		</>
	)
}

export default DropdownTableRecyleBin