import { 
    useContext,
    useEffect, 
}                                   from "react"

import {
    Button,
    Card,
    CardBody,
    CardHeader,
}                                   from "reactstrap"

import Avatar                       from '@components/avatar'
import CardMessage                  from "./CardMessage"
import PerfectScrollbar             from 'react-perfect-scrollbar'
import ChatMessageFooter            from "./ChatMessageFooter"

import { ChatContext }              from "../../../context/chatContext"

import '@styles/base/pages/app-chat.scss'
import '@styles/base/pages/app-chat-list.scss'
import { ArrowLeft } from "react-feather"
const ChatMessages = ({
    selected,
}) => {

    const {
        // State
        chatArea,
        
        loading,
        
        message,

        setSelected,

        // Function
        getMessage,
        scrollToBottom
    } = useContext(ChatContext)

    const ChatWrapper = Object.keys(message).length ? PerfectScrollbar : 'div'

    const renderCardHeader = () => {

        return (
            <CardHeader
                className="border-bottom p-1"
            >
                <div className="d-flex align-items-center">
                    <Button.Ripple 
                        color='flat-primary'
                        onClick = {() => setSelected(null)}
                        className='btn-icon rounded-circle p-0 me-1 back-chat-list' 
                    >
                        <ArrowLeft />
                    </Button.Ripple>
                                {/* <ArrowLeft
                        className="me-1 cursor-pointer"
                    /> */}
                    <Avatar
                        size        = "md"
                        className   = "me-1 p-0"
                        img         = {!selected?.employeePhoto ? `https://ui-avatars.com/api/?name=${selected ? selected?.name : "UN"}&background=4e73df&color=fff&bold=true` : selected?.employeePhoto} 
                    />
                    <div>
                        <div>
                            <strong>{`${ selected?.members?.length > 2 ? selected?.name : selected.employeeName }`}</strong>
                            <div className="text-muted fw-bolder">
                                <span>{selected?.room_type === 2 && (selected?.members?.length)} {selected?.room_type === 2 && 'Members'}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </CardHeader>
        )
    }

    useEffect(() => {
        if (selected !== null && message?.length > 0) {
            setTimeout(() => {
                scrollToBottom()
            }, 1300)
        }
    }, [message])

    return (
        <>
            <Card
                className=" w-100 h-100 chat-widget"
            >
                {
                    selected !== null ?
                        <>
                            {renderCardHeader()}
                            <CardBody
                                className = "bg-secondary"
                                style={{
                                            height : '60vh'
                                }} 
                            >
                                <div className='chat-app-window h-100'>
                                    {/* <div
                                        className="active-chat h-100 "
                                    > */}
                                        <PerfectScrollbar 
                                            ref={chatArea} 
                                            className='user-chats bg-secondary m-0 h-100' 
                                            options={{
                                                wheelSpeed: 2,
                                                wheelPropagation: true,
                                                minScrollbarLength: 20,
                                            }}
                                        >
                                            {
                                                message && message.length > 0 ? <div className='chats'>
                                                    {
                                                        message?.map((dt, idx) => {
                                                            return (
                                                                <>
                                                                {
                                                                    !loading ? (
                                                                        <CardMessage
                                                                            item    = {dt}
                                                                            index   = {idx}
                                                                        />
                                                                    ) : (
                                                                        <p>loading</p>
                                                                    )
                                                                }
                                                                    
                                                                </>
                                                            )
                                                        })
                                                    }
                                                </div> : null
                                            }
                                        </PerfectScrollbar>
                                    {/* </div> */}
                                </div>
                            </CardBody>
                            <ChatMessageFooter
                                selected = {selected}
                                getMessage = {getMessage}
                                scrollToBottom = {scrollToBottom}
                            />
                        </>
                    :
                        null
                }
            </Card>
        </>
    )
}

export default ChatMessages