// ** React Imports
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'

// ** Custom Components
import Avatar from '@components/avatar'

// ** Utils
import { isUserLoggedIn, getInitials } from '@utils'

// ** Store & Actions
import { useDispatch } from 'react-redux'
import { handleLogout } from '@store/authentication'

// ** Third Party Components
import { User, Power } from 'react-feather'

// ** Reactstrap Imports
import { UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap'
import Helper from '../../../../helper'
import { sso_client_id, sso_realm, sso_url } from '../../../../interceptor/axiosInstance'

const UserDropdown = () => {
  // ** Store Vars
  const dispatch = useDispatch()

  // ** State
  const [userData, setUserData] = useState(null)

  //** ComponentDidMount
  useEffect(() => {
    if (isUserLoggedIn() !== null) {
      setUserData(JSON.parse(localStorage.getItem('userData')))
    }
  }, [])

  const fallbackImage_ = (val) => {
    val.target.src = `https://ui-avatars.com/api/?name=${`${userData?.name}`}&background=4e73df&color=fff&bold=true`
  }

  //** Vars
  const userAvatar = (userData && userData?.photo !== "") ? { img: userData?.photo, imgWidth: 40, imgHeight: 40, onError: fallbackImage_ } : {content : userData ? getInitials(userData?.name) : getInitials('Anonym'), color : 'light-danger'} 

  const handleClickLogout = () => {
    const href  		= window.location.href
    const split 		= href.split("/")
    const url   		= `${split[0]}//${split[2]}/logout`

    if (localStorage.getItem("is_sso_login")) {
      window.location.href = `${sso_url}realms/${sso_realm}/protocol/openid-connect/logout?client_id=${sso_client_id}&post_logout_redirect_uri=${url}/`
    } else {
      dispatch(handleLogout())
    }

    // dispatch(handleLogout())
  }

  return (
    <UncontrolledDropdown tag='li' className='dropdown-user nav-item'>
      <DropdownToggle href='/' tag='a' className='nav-link dropdown-user-link' onClick={e => e.preventDefault()}>
        <Avatar 
          {...userAvatar}
          className = "mx-1"
        />
        <div className='user-nav d-sm-flex d-none me-0' style={{width:'180px'}}>
          {/* <span className='user-name fw-bold ms-0 mb-0'>{(userData && Helper.capitalizeFirstLetter(userData.name))}</span> */}
          <span className='user-name fw-bold ms-0 mb-0'>{(userData && userData.name)}</span>
          <span className='text-wrap' style={{fontSize: '10px'}}>{(userData && Helper.capitalizeFirstLetter(userData?.position?.name))}</span>
          <span className='' style={{fontSize: '10px'}}>{(userData && `${userData?.work_unit?.work_unit_level?.name} ${userData?.work_unit?.name}`)}</span>
        </div>
      </DropdownToggle>
      <DropdownMenu end>
        <DropdownItem tag={Link} to='/profile'>
          <User size={14} className='me-75' />
          <span className='align-middle'>Profile</span>
        </DropdownItem>
        <DropdownItem tag={Link} to='/login' onClick={() => handleClickLogout()}>
          <Power size={14} className='me-75' />
          <span className='align-middle'>Logout</span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export default UserDropdown
