import React, { useEffect } from 'react'
import { Button, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'

const UserAccess = ({
    show,
    setShow,
    dataUser,
    updateAccess,
    userSelected
}) => {
    useEffect(() => {

    }, [userSelected, dataUser])

  return (
    <Modal
        isOpen = {show}
        toggle={() => { setShow(false) }}
    >
        <ModalHeader 
            toggle={() => { setShow(false) }}
        >
            Pilih Hak Akses
        </ModalHeader>
        <ModalBody>
            <Row>
                <Col xs={12}>
                    <Input 
                        id='read'
                        name='read'
                        type='radio' 
                        checked={dataUser.access === 'read'}
                        className='me-1'
                        onChange={() => { 
                            updateAccess(dataUser, 'read') 
                            setShow(false)
                        }}
                    />
                    <Label>Hanya Membaca</Label>
                </Col>
                <Col xs={12} className='mt-1'>
                    <Input 
                        id='update_upload'
                        name='read'
                        type='radio'
                        checked={dataUser.access === 'upload'}
                        className='me-1'
                        onChange={() => { 
                            updateAccess(dataUser, 'upload')
                            setShow(false)
                        }}
                    />    
                    <Label>Hanya Bisa Unggah Berkas</Label>
                </Col>
                <Col xs={12} className='mt-1'>
                    <Input 
                        id='upload'
                        name='read'
                        type='radio' 
                        checked={dataUser.access === 'upload -update'}
                        className='me-1'
                        onChange={() => { 
                            updateAccess(dataUser, 'update_upload')
                            setShow(false)
                        }}
                    />    
                    <Label>Perbolehkan Unggah dan Ubah Berkas</Label>
                </Col>
            </Row>
        </ModalBody>
    </Modal>
  )
}

export default UserAccess
