// import React from 'react'
import { MoreVertical, Search } from 'react-feather'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { UserManagementContext } from '../../../context/userManagementContext'
import { useContext, useEffect, useState } from 'react'
import { DataManagerContext } from '../../../context/dataManagerContext'
import driveAPI from '../../../service/pages/drive'
import toast from 'react-hot-toast'
import UserShare from './UserShare'
import PengaturanTautan from './PengaturanTautan'
import UserAccess from './UserAccess'

const ModalShare = ({data, show, setShow}) => {

    const {
        getData,
        listData,
    } = useContext(UserManagementContext)

    const {
        folder,
        getDrive,
        expired, 
        setExpired,
        password, 
        setPassword,
        restrictions, 
        setRestrictions,
        showConfiguration, 
        setShowConfiguration,
    } = useContext(DataManagerContext)

    const [showAccess, setShowAccess]   = useState(false)
    const [showUser, setShowUser]   = useState(false)
   
    const [dataUser, setDataUser]   = useState(false)
    // const [accesses, setAccesses]   = useState(false)

    const [userSelected, setUserSelected]   = useState([])
    
    // console.log(data.code, userSelected)

    const selectUserFull = (data) => {
        if (userSelected.map(e => e.id).indexOf(data.id) !== -1) {
            setUserSelected(userSelected.filter(opt => opt.id !== data.id))
        } else {
            setUserSelected([...userSelected, {id : data.id, name : data.name, access : 'read', user_id : data.user_id}])
        }
    }


    const updateAccess = (data, type) => {
        const updatedUsers = userSelected.map(user => {
          if (user.id === data.id) {
            return { ...user, access: type }
          }
          return user
        })
      
        setUserSelected(updatedUsers)
      }

    const shareDrive = () => {
        // const userdata = JSON.parse(localStorage.getItem('userData'))

        const formData = {
            code_file    : data.code,
            // owner   : userdata.id,
            recipient  : userSelected.map(user => user.user_id),
            is_folder :  data.mime.data === 'folder',
            is_password: password && true,
            password: password ? password : null,
            is_expired: expired && true,
            is_restrictions: restrictions && true,
            restrictions_count: restrictions ? restrictions : 0,
            expired_count: expired ? expired : null
        }

        driveAPI.shareDrive(formData)
            .then((res) => {
                if (!res.is_error) {
                    userSelected.map(user => {
                        driveAPI.UserAccessAPI({id : res.data.id,  user_id : user.user_id, access : user.access})
                        .then(res => {
                            if (!res.is_error) {
                                toast.success('Share Sucessed!')
                                getDrive(folder ? `?dir=${folder.code}` : null)
                                setShow(false)
                            }
                        }).catch((err) => {
                            console.log(err.result.message)
                        })
                    })
                    
                } else {
                    toast.success(err.result.message)
                }
            }).catch((err) => {
                console.log(err.result.message)
            })
    }

    useEffect(() => {
        getData()
    }, [])

  return (
    <>
    <Modal
        isOpen = {show}
        toggle={() => setShow(!show)}
        size='sm'
    >
        <ModalHeader toggle={() => setShow(!show)}>Share Berkas</ModalHeader>
        <ModalBody>
            <div>
                <Button color='primary' className='w-100 mb-1' onClick={() => setShowUser(true)}>Pilih Pengguna</Button>
                <Button color='primary' disabled className='w-100 mb-1'>Salin Tautan</Button>
                <Button color='primary' className='w-100 mb-1' onClick={() => setShowConfiguration(true)}>Pengaturan Tautan</Button>
            </div>
            <div className='mb-2'>
                {
                    Array.isArray(userSelected) && userSelected.map((user) => (
                        <div className='mt-1'>
                            <div className='d-flex justify-content-between p-1 border rounded'>
                                <div>
                                    {user.name}
                                </div>
                                <div>
                                    {   
                                        user.access === 'read' ? 
                                            'Hanya Bisa Membaca'
                                        : user.access === 'upload' ?
                                            "Hanya Bisa Unggah Berkas"
                                        : 
                                            "Perbolehkan Unggah dan Ubah Berkas"
                                    }
                                </div>
                                <div 
                                    onClick={() => {
                                         setShowAccess(true)
                                         setDataUser(user)
                                        }}
                                    className='cursor-pointer'
                                >
                                    <MoreVertical size={15}/>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
            
        </ModalBody>
        <ModalFooter className='d-flex justify-content-between'>
            <Button outline>Batal</Button>
            <Button type='button' color='primary' onClick={() => shareDrive()}>Submit</Button>
        </ModalFooter>
    </Modal>

    <UserShare 
        show = {showUser}
        getData = {getData}
        setShow = {setShowUser}
        listData = {listData}
        userSelected = {userSelected}
        selectUserFull = {selectUserFull}
    />

    <PengaturanTautan
        show = {showConfiguration}
        expired = {expired}
        password = {password}
        restrictions = {restrictions}
        setExpired = {setExpired}
        setPassword = {setPassword}
        setRestrictions = {setRestrictions}
        setShow = {setShowConfiguration}
    />

    <UserAccess 
        show = {showAccess}
        setShow = {setShowAccess}
        dataUser = {dataUser}
        updateAccess = {updateAccess}
        userSelected = {userSelected}
    />
    </>
    
  )
}

export default ModalShare
