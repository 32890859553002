import { param } from 'jquery'
import {get, delete_, post, put}  from '../../index'

//get
const getSize               = () => get('/drive/size')
const getDrive              = (path, params) => get(`/drive${path ?? ''}`, params)
const getTags               = () => get(`/drive/tags`)
const getFolder             = (path) => get(`/drive${path ?? ''}`)
const downloadDrive         = (type, code) => get(`/drive/${type}/download/${code}`)
const getDriveDetail        = (code, param) => get(`/drive/activities-data?code=${code}&${param}=true`)

//post
const addTags               = (code, formData) => post(`/drive/tags/add-data-tag/${code}`, formData)
const createTag             = (formData) => post('/drive/tags/create', formData)
const shareDrive            = (formData) => post('/drive/share/create?link=true', formData)
const UserAccessAPI         = (formData) => post('/drive/access-user', formData)
const addFavorite           = (type, formData) => post(`/drive/${type}/favorite${formData}`)
const createFolder          = (formData) => post('/drive/folder/create', formData)
const createFile            = (formData) => post('/drive/files/create', formData)
const createComment         = (code, formData) => post(`/drive/activities-data?code=${code}`, formData)


//put
const renameFolder          = (type, id, formData) => put(`/drive/${type}/rename/${id}`, formData)
const moveFolder            = (type, code, formData) => put(`/drive/${type}/update/${code}?move=true`, formData)

//delete
const deleteTags            = (code) => delete_(`/drive/tags/delete/${code}`)
const deleteShare           = (code) => delete_(`/drive/share/delete/${code}`)
const deleteToTags          = (code, formData) => delete_(`/drive/tags/delete-data-tag/${code}`, formData)
const deleteDrive           = (type, id) => delete_(`/drive/${type}/delete/${id}`)
const restoreDrive          = (code) => delete_(`/drive/restore/${code}`)
const deleteTofavorite      = (type, id) => delete_(`/drive/${type}/favorite${id}`)
const deletePermanentDrive  = (type, id) => delete_(`/drive/${type}/delete/permanent/${id}`)


const driveAPI = {
    // Get
    getSize,
    getTags,
    getFolder,
    getDrive,
    downloadDrive,
    getDriveDetail,

    // Post
    addTags,
    createTag,
    shareDrive,
    createFile,
    addFavorite,
    createFolder,
    createComment,
    UserAccessAPI,
 
    // Put
    moveFolder,
    renameFolder,

    // Delete
    deleteTags,
    deleteShare,
    deleteDrive,
    restoreDrive,
    deleteToTags,
    deleteTofavorite,
    deletePermanentDrive
}

export default driveAPI