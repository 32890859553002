import { 
    useState,
    createContext,
}                   from "react"

import toast        from "react-hot-toast"

import driveAPI     from "../service/pages/drive"

const DataManagerContext = createContext(null)

const DataManagerProvider = ({ children }) => {
    
    const [dir,                 setDir]                 = useState(null)
    const [size,                setSize]                = useState(false)
    const [sort,                setSort]                = useState("asc")
    const [folder,              setFolder]              = useState(0)
    const [active,              setActive]              = useState("all")
    const [layout,              setLayout]              = useState("grid")
    const [params,              setParams]              = useState({})
    const [expired,             setExpired]             = useState(false)
    const [password,            setPassword]            = useState(false)
    const [loading,             setLoading]             = useState(true)
    const [sort_by,             setSort_by]             = useState("name")
    const [selected,            setSelected]            = useState([])
    const [listData,            setListData]            = useState(false)
    const [listDetail,          setListDetail]          = useState(false)
    const [listTags,            setListTags]            = useState(false)
    const [detailData,          setDetailData]          = useState(true)
    const [showDelete,          setShowDelete]          = useState(false)
    const [pagination,          setPagination]          = useState(false)
    const [listFolder,          setListFolder]          = useState(false)
    const [showFormTag,         setShowFormTag]         = useState(false)
    const [activeChild,         setActiveChild]         = useState("all")
    const [selectDrive,         setSelectDrive]         = useState(false)
    const [restrictions,        setRestrictions]        = useState(false)
    const [dataBreadcums,       setDataBreadcums]       = useState([])
    const [folderBreadcums,     setFolderBreadcums]     = useState([])
    const [showConfiguration,   setShowConfiguration]   = useState(false)
    const [showDeletePermanent, setShowDeletePermanent] = useState(false)

    const getDrive = (dir, params) => {

        setListData(false)
        setLoading(true)

        driveAPI.getDrive(dir, params)
            .then((res) => {
                if (!res.is_error) {
                    setListData(res.results)
                    setLoading(false)

                    const pagination = {
                        count       : res.count,
                        page        : res.page,
                        page_size   : res.page_size,
                        next        : res.next,
                        previous    : res.previous,
                        total_page  : res.total_page
                    }

                setPagination(pagination)

                } else {
                    setListData(null)
                }
                
            }).catch(() => {
                setListData([])
                toast.error('Tidak Ada Data')

            })
    }

    const getDriveDetail = (code, params) => {

        setListDetail(false)
        setLoading(true)

        driveAPI.getDriveDetail(code, params)
            .then((res) => {
                if (!res.is_error) {
                    setListDetail(res.results)
                    setLoading(false)

                } else {
                    toast.error(res.message)
                }
                
            }).catch(() => {
                toast.error('Tidak Ada Data')
            })
    }

    const getMoveDrive = (dir) => {

    setListFolder(false)
    setLoading(true)

    driveAPI.getFolder(dir)
        .then((res) => {
            if (!res.is_error) {
                setListFolder(res.results)
                setLoading(false)

            } else {
                setListData(null)
            }
            
        }).catch((err) => {
            console.log(err)
        })
    }

    const getTags = (dir) => {

        setListTags(false)
        setLoading(true)
    
        driveAPI.getTags(dir)
            .then((res) => {
                if (!res.is_error) {
                    setListTags(res.results)
                    setLoading(false)
    
                } else {
                    setListData(null)
                }
                
            }).catch((err) => {
                console.log(err)
            })
    }

    const addFile = (file) => {

        const formData = new FormData()

        if (folder) {
            formData.append('file', file[0])
            formData.append('file_folder', folder && folder.id ? active === 'share' ? folder.data.id : folder.id : 0)
            formData.append('is_encrypt', true)
        } else {
            formData.append('file', file[0])
            formData.append('is_encrypt', true)
        }

        driveAPI.createFile(formData)
            .then((res) => {
                if (!res.is_error) {
                   getDrive(folder ? `?dir=${folder.code ? folder.code : folder.data.code}` : null)
                   toast.success('File Saved!')
                } else {
                   toast.error('File Failed Saved!')
                }
                
            }).catch(() => {
                toast.error('Error')
            })

    }

    const addFavorite = (data) => {

        const formData = `?code=${data.code}`

        const type = data.mime.data === 'file' ? 'files' : data.mime.data 

        driveAPI.addFavorite(type, formData)
        .then((res) => {
            if (!res.is_error) {
                toast.success('Favorite Saved!')
                getDrive(folder ? `?dir=${folder.code}` : null)
            } else {
                toast.error('Favorite Not Saved!')
            } 
            
        }).catch(() => {
            toast.error('Error')
        })
    
    }

    const createComment = (data, comment) => {
        driveAPI.createComment(data.code, comment)
        .then((res) => {
            if (!res.is_error) {
                getDriveDetail(data.code, 'comment')
                getDrive(folder ? `?dir=${folder.code}` : null)
                toast.success('Comment Saved!')
            } else {
               toast.error('Comment Failed Saved!')
            }
        }).catch(() => {
            toast.error('Error')
        })
    }


    const deleteFavorite = (data) => {

        const formData = `?code=${data.code}`

        const type = data.mime.data === 'file' ? 'files' : data.mime.data 

        driveAPI.deleteTofavorite(type, formData)
        .then((res) => {
            if (!res.is_error) {
                toast.success('Favorite Deleted!')
                getDrive(folder ? `?dir=${folder.code}` : null)
            } else {
                toast.error('Favorite Not Deleted!')
            } 
            
        }).catch(() => {
            console.log('Error')
        })
    
    }

    const deleteToTags = (code) => {

        const formData = {
            tags : [code.tags[0].id]
        }

        driveAPI.deleteToTags(code.code, formData)
            .then((res) => {
                if (!res.is_error) {
                    toast.success('Tags Deleted!')
                    getDrive(folder ? `dir=${folder.code}` : null)
                } else {
                    toast.error('Tags Not Deleted!')
                }
            }).catch(() => {
                toast.error('Error')
            })
    }

    const deleteDrive = () => {
        if (selected.length >= 1) {
            selected.map(data => {
                const type = data.mime?.data === 'file' ? 'files' : data.mime?.data 
                driveAPI.deleteDrive(type, data.code)
                    .then((res) => {
                        if (!res.is_error) {
                            toast.success('Data Deleted!')
                            getDrive(folder ? `dir=${folder.code}` : null)
                            setShowDelete(false)
                            setSelectDrive(false)
                        } else {
                            toast.error('Data Not Deleted!')
                        }
                    }).catch(() => {
                        toast.error('Error')
                    })
                })
        } else {
            const type = selectDrive.mime?.data === 'file' ? 'files' : selectDrive.mime?.data 
            driveAPI.deleteDrive(type, selectDrive.code)
                .then((res) => {
                    if (!res.is_error) {
                        toast.success('Data Deleted!')
                        getDrive(folder ? `dir=${folder.code}` : null)
                        setShowDelete(false)
                        setSelectDrive(false)
                    } else {
                        toast.error('Data Not Deleted!')
                    }
                }).catch((err) => {
                    console.log(err)
                })
        }
    }

    const deleteShare = () => {
        if (selected.length >= 1) {
            selected.map(data => {
                driveAPI.deleteShare(data.code_file ? data.code_file : data.code)
                    .then((res) => {
                        if (!res.is_error) {
                            toast.success('Share Deleted!')
                            getDrive('/share?shared=true')
                            setShowDelete(false)
                            setSelectDrive(false)
                        } else {
                            toast.error('Data Not Deleted!')
                        }
                    }).catch(() => {
                        toast.error('Error')
                    })
                })
        } else {
            driveAPI.deleteShare(selectDrive.code_file)
                .then((res) => {
                    if (!res.is_error) {
                        toast.success('Share Deleted!')
                        getDrive('/share/shared=true')
                        setShowDelete(false)
                        setSelectDrive(false)
                    } else {
                        toast.error('Data Not Deleted!')
                    }
                }).catch(() => {
                    toast.error('Error')
                })
        }
    }

    const deleteTags = () => {
        
        driveAPI.deleteTags(selectDrive.code)
            .then((res) => {
                if (!res.is_error) {
                    toast.success('Tags Deleted!')
                    getTags()
                    getDrive('/tags/detail/1')
                    setShowDelete(false)
                    setSelectDrive(false)
                } else {
                    toast.error('Tags Not Deleted!')
                }
            }).catch(() => {
                toast.error('Error')
            })
        
    }

    const deletePermanentDrive = () => {

        if (selected.length >= 1) {
            selected.map((data) => {
                const type = data.mime?.data === 'file' ? 'files' : data.mime?.data 
                driveAPI.deletePermanentDrive(type, data.code)
                    .then((res) => {
                        if (!res.is_error) {
                            toast.success('Data Deleted Permanent!')
                            getDrive('/trash')
                            setShowDelete(false)
                        } else {
                            toast.error('Data Not Deleted!')
                        }
                    }).catch(() => {
                        toast.error('Error')
                    })
                })
        } else {
            const type = selectDrive.mime?.data === 'file' ? 'files' : selectDrive.mime?.data 
            driveAPI.deletePermanentDrive(type, selectDrive.code)
                .then((res) => {
                    if (!res.is_error) {
                        toast.success('Data Deleted Permanent!')
                        getDrive('/trash')
                        setShowDelete(false)
                        setSelectDrive(false)
                    } else {
                        toast.error('Data Not Deleted!')
                    }
                }).catch((err) => {
                    toast.error(err.result.message)
                })
            }
       
    }

    const restoreDrive = (data) => {
        driveAPI.restoreDrive(data.code)
         .then(res => {
             if (!res.is_error) {
                 toast.success(`${data.mime?.data} Successfully Recovered`)
                 getDrive('/trash')
             }
         })
         .catch((err) => {
            toast.error(err.result.message)
         })
     }

    const setBreadcums = ({type, name, url}) => {
        if (type === "create") {
            setDataBreadcums([{name : name, url:url}])
            setFolderBreadcums([{name : name, url:url}])
        } else {
            setDataBreadcums([...dataBreadcums, {name:name, url : url}])
            setFolderBreadcums([...dataBreadcums, {name:name, url : url}])
        }
    }

    const getSize = () => {

        driveAPI.getSize()
            .then((res) => {
                if (!res.is_error) {
                    setSize(res.data)
                } else {
                    setListData(null)
                }
                
            }).catch(() => {
                toast.error('Size Error')
            })

    }

    const setUrlApiActive = () => {
        // setApiActive(url)
        // getDrive(url)
        // setSelected([])
        // setSelectedAllRows(false)
    }
   
    return <DataManagerContext.Provider
        value={{
            // State
            size,
            setSize,
            dir,
            setDir,
            listTags,
            sort, 
            setSort,
            sort_by, 
            setSort_by,
            active,
            setActive,
            layout,              
            setLayout,
            selected,            
            setSelected,
            folder,
            setFolder,
            expired, 
            setExpired,
            password, 
            setPassword,
            loading,
            setLoading,
            listDetail,          
            setListDetail,
            detailData,          
            setDetailData,
            activeChild, 
            setActiveChild,
            showFormTag, 
            setShowFormTag,
            showDelete,          
            setShowDelete,
            restrictions, 
            setRestrictions,
            params,
            setParams,
            pagination,
            setPagination,
            selectDrive, 
            setSelectDrive,
            listData, 
            setListData,
            showConfiguration, 
            setShowConfiguration,
            listFolder,
            dataBreadcums,
            setDataBreadcums,
            folderBreadcums,
            setFolderBreadcums,
            showDeletePermanent, 
            setShowDeletePermanent,

            // Function
            getSize,
            addFile,
            getTags,
            getDrive,
            deleteTags,
            deleteDrive,
            deleteShare,
            addFavorite,
            deleteToTags,
            restoreDrive,
            getMoveDrive,
            setBreadcums,
            createComment,
            deleteFavorite,
            getDriveDetail,
            setUrlApiActive,
            deletePermanentDrive,
        }} >{children}</DataManagerContext.Provider>
}

export { DataManagerContext, DataManagerProvider }

