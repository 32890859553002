import moment from "moment"

const LIN_1 = [      
    {
        name            : 'No.',
        width           : '65px',
        selector        : row => row.index,
		unique_key      : 'index',
    },
    {
        name            : 'No. Surat',
        selector        : row => row.nomor_surat,
		unique_key      : 'nomor_surat',
    },
]

const LIN_6 = [      
    {
        name            : 'No.',
        width           : '65px',
        selector        : row => row.index,
		unique_key      : 'index',
    },
    {
        name            : 'No. Surat',
        selector        : row => row.nomor_surat,
		unique_key      : 'nomor_surat',
    },
    {
        name            : 'Perihal',
        selector        : row => row.perihal,
		unique_key      : 'nomor_surat',
    },
]

const LIN_14 = [      
    {
        name            : 'No.',
        width           : '65px',
        selector        : row => row.index,
		unique_key      : 'index',
    },
    {
        name            : 'No. Permohonan',
        selector        : row => row.nomor_permohonan,
		unique_key      : 'nomor_permohonan',
    },
]

const LIN_15 = [      
    {
        name            : 'No.',
        width           : '65px',
        selector        : row => row.index,
		unique_key      : 'index',
    },
    {
        name            : 'Asal Negara',
        selector        : row => row.asal_negara,
		unique_key      : 'asal_negara',
    },
]

const LIN_16 = [      
    {
        name            : 'No.',
        width           : '65px',
        selector        : row => row.index,
		unique_key      : 'index',
    },
    {
        name            : 'Nama Lengkap',
        selector        : row => row.nama_lengkap,
		unique_key      : 'nama_lengkap',
    },
    {
        name            : 'Tindak Pidana',
        selector        : row => row.tindak_pidana,
		unique_key      : 'tindak_pidana',
    },
]

const LIN_17 = [      
    {
        name            : 'No.',
        width           : '65px',
        selector        : row => row.index,
		unique_key      : 'index',
    },
    {
        name            : 'Sumber Surat',
        selector        : row => row.sumber_surat?.nomor_surat,
        unique_key      : 'sumber_surat',
    },
]

const LIN_18 = [      
    {
        name            : 'No.',
        width           : '65px',
        selector        : row => row.index,
		unique_key      : 'index',
    },
    {
        name            : 'Nomor',
        selector        : row => row.kartutik_barang_cetakan?.nomor,
        unique_key      : 'kartutik_barang_cetakan',
    },
    {
        name            : 'Jenis Barang Cetakan',
        selector        : row => row.kartutik_barang_cetakan?.jenis_cetakan,
    },
]

const LIN_19 = [      
    {
        name            : 'No.',
        width           : '65px',
        selector        : row => row.index,
		unique_key      : 'index',
    },
    {
        name            : 'Jenis Media',
        selector        : row => row.kartutik_media_komunikasi?.jenis_media,
        unique_key      : 'kartutik_media_komunikasi',
    },
    {
        name            : 'Nama Perusahaan',
        selector        : row => row.kartutik_media_komunikasi?.nama_perusahaan,
    },
]

const LIN_20 = [      
    {
        name            : 'No.',
        width           : '65px',
        selector        : row => row.index,
		unique_key      : 'index',
    },
    {
        name            : 'Nama Klinik',
        selector        : row => row.nama_klinik,
    },
    {
        name            : 'Alamat Klinik',
        selector        : row => row.alamat_klinik,
    },
]

const LIN_21 = [      
    {
        name            : 'No.',
        width           : '65px',
        selector        : row => row.index,
		unique_key      : 'index',
    },
    {
        name            : 'Nama Aliran',
        selector        : row => row.nama_aliran,
    },
    {
        name            : 'Nama Pimpinan',
        selector        : row => row.nama_pimpinan,
    },
]

const LIN_22 = [      
    {
        name            : 'No.',
        width           : '65px',
        selector        : row => row.index,
		unique_key      : 'index',
    },
    {
        name            : 'Nama Aliran Kepercayaan',
        selector        : row => row.nama_aliran_kepercayaan,
    },
    {
        name            : 'Nama Pimpinan',
        selector        : row => row.nama_pimpinan,
    },
]

const LIN_23 = [      
    {
        name            : 'No.',
        width           : '65px',
        selector        : row => row.index,
		unique_key      : 'index',
    },
    {
        name            : 'Nama Organisasi',
        selector        : row => row?.kartutik_organisasi_kemasyarakatan?.nama_organisasi,
        unique_key      : 'kartutik_organisasi_kemasyarakatan',
    },
    {
        name            : 'Kedudukan',
        selector        : row => row?.kartutik_organisasi_kemasyarakatan?.kedudukanstatus,
    },
]

const LIN_24 = [      
    {
        name            : 'No.',
        width           : '65px',
        selector        : row => row.index,
		unique_key      : 'index',
    },
    {
        name            : 'Jenis Masalah',
        selector        : row => row.jenis_masalah,
        sortable        : true
      },
]

const LIN_25 = [      
    {
        name            : 'No.',
        width           : '65px',
        selector        : row => row.index,
		unique_key      : 'index',
    },
    {
        name            : 'Peserta',
        selector        : row => row.peserta,
        sortable        : true
      },
]

const LIN_27 = [      
    {
        name            : 'No.',
        width           : '65px',
        selector        : row => row.index,
		unique_key      : 'index',
    },
    {
        name            : 'Posko Perwakilan',
        selector        : row => row.posko_perwakilan,
        sortable        : true
      },
]

const LIN_28 = [      
    {
        name            : 'No.',
        width           : '65px',
        selector        : row => row.index,
		unique_key      : 'index',
    },
      {
        name            : 'Kegiatan',
        selector        : row => row.kegiatan,
        sortable        : true
      },
]

const LIN_29 = [      
    {
        name            : 'No.',
        width           : '65px',
        selector        : row => row.index,
		unique_key      : 'index',
    },
    {
        name            : 'Asal Buronan',
        selector        : row => row.asal_buronan,
        sortable        : true
      },
]

const HEADER_LIN = {
    LIN_1        : LIN_1,
    LIN_2        : LIN_1,
    LIN_3        : LIN_1,
    LIN_4        : LIN_1,
    LIN_5        : LIN_1,
    LIN_6        : LIN_6,
    LIN_7        : LIN_1,
    LIN_8        : LIN_1,
    LIN_9        : LIN_1,
    LIN_10       : LIN_1,
    LIN_11       : LIN_1,
    LIN_12       : LIN_1,
    LIN_13       : LIN_1,
    LIN_14       : LIN_14,
    LIN_15       : LIN_15,
    LIN_16       : LIN_16,
    LIN_17       : LIN_17,
    LIN_18       : LIN_18,
    LIN_19       : LIN_19,
    LIN_20       : LIN_20,
    LIN_21       : LIN_21,
    LIN_22       : LIN_22,
    LIN_23       : LIN_23,
    LIN_24       : LIN_24,
    LIN_25       : LIN_25,
    LIN_26       : LIN_24,
    LIN_27       : LIN_27,
    LIN_28       : LIN_28,
    LIN_29       : LIN_29
}

export default HEADER_LIN