// ** React Imports
import ReactDOM from "react-dom"
import { Suspense, lazy } from "react"
import { BrowserRouter } from "react-router-dom"

// ** Redux Imports
import { store } from "./redux/store"
import { Provider } from "react-redux"

// ** Intl, CASL & ThemeColors Context
import ability from "./configs/acl/ability"
import { AbilityContext } from "./utility/context/Can"
import { ThemeContext } from "./utility/context/ThemeColors"

// ** ThemeConfig
import themeConfig from "./configs/themeConfig"

// ** Toast
import { Toaster } from "react-hot-toast"

// ** i18n
import "./configs/i18n"

// ** Spinner (Splash Screen)
import Spinner from "./@core/components/spinner/Fallback-spinner"

// ** Ripple Button
import "./@core/components/ripple-button"

// ** PrismJS
import "prismjs"
import "prismjs/themes/prism-tomorrow.css"
import "prismjs/components/prism-jsx.min"

// ** React Perfect Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css"

// ** React Hot Toast Styles
import "@styles/react/libs/react-hot-toasts/react-hot-toasts.scss"

// ** Core styles
import "./@core/assets/fonts/feather/iconfont.css"
import "./@core/scss/core.scss"
import "./assets/scss/style.scss"
import "mapbox-gl/dist/mapbox-gl.css"

import "flatpickr/dist/themes/material_green.css"


// ** Service Worker
import * as serviceWorker from "./serviceWorker"
import { GradeProvider } from "./context/gradeContext"
import { ReportProvider } from "./context/reportContext"
import { SectorProvider } from "./context/sectorContext"
import { WorkunitProvider } from "./context/workunitContext"
import { ClassesProvider } from "./context/classesContext"
import { PositionProvider } from "./context/positionContext"
import { WorkunitLevelProvider } from "./context/workunitLevelContext"
import { RoleProvider } from "./context/roleContext"
import { UserManagementProvider } from "./context/userManagementContext"
import { DataManagerProvider } from "./context/dataManagerContext"
import { GlobalProvider } from "./context/globalContext"
import { ChatProvider } from "./context/chatContext"
import { ProfileProvider } from "./context/profileContext"
import { DashboardProvider } from "./context/dashboardContext"
import { PerformanceProvider } from "./context/performanceContext"
import { GisProvider } from "./context/GisContext"
import { ManagementFileProvider } from "./context/managementFileContext"
import { SSOProvider } from "./context/keyCloackContext"

// ** Lazy load app
const LazyApp = lazy(() => import("./App"))
ReactDOM.render(
	<BrowserRouter>
		<Provider store={store}>
			<Suspense fallback={<Spinner />}>
				<AbilityContext.Provider value={ability}>
					<SSOProvider>
						<ChatProvider>
							<GlobalProvider>
								<DataManagerProvider>
									<RoleProvider>
										<ManagementFileProvider>
											<ClassesProvider>
												<GradeProvider>
													<ProfileProvider>
														<WorkunitLevelProvider>
															<SectorProvider>
																<PositionProvider>
																	<WorkunitProvider>
																		<PerformanceProvider>
																			<GisProvider>
																				<UserManagementProvider>
																					<ReportProvider>
																						<DashboardProvider>
																							<ThemeContext>
																								<LazyApp />
																								<Toaster
																									position={themeConfig.layout.toastPosition}
																									toastOptions={{ className: "react-hot-toast" }}
																								/>
																							</ThemeContext>
																						</DashboardProvider>
																					</ReportProvider>
																				</UserManagementProvider>
																			</GisProvider>
																		</PerformanceProvider>
																	</WorkunitProvider>
																</PositionProvider>
															</SectorProvider>
														</WorkunitLevelProvider>
													</ProfileProvider>
												</GradeProvider>
											</ClassesProvider>
										</ManagementFileProvider>
									</RoleProvider>
								</DataManagerProvider>
							</GlobalProvider>
						</ChatProvider>
					</SSOProvider>
				</AbilityContext.Provider>
			</Suspense>
		</Provider>
	</BrowserRouter>,
	document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
