import { 
    Button,
    Card, 
    CardBody, 
    CardHeader,
    ListGroup,
    ListGroupItem
}                           from "reactstrap"

import { 
    useEffect, 
    useState 
}                           from "react"

import { Marker }           from "react-map-gl"
import { MapPin }           from "react-feather"
import imgMarker            from "../../../../assets/images/icons/point-map.png"
import MapWorkunit          from "../../../other/MapWorkunit"
import workunitAPI          from "../../../../service/pages/workunit"
import WidgetMap from "../../../gis/component/WidgetMap"

const WorkunitDetail = ({
    id
}) => {

    const childern          = ["Manokwari", "Sorong", "Fak Fak", "Teluk Bintuni", "Kaimana"]
    const [data, setData]   = useState(null)

    useEffect(() => {
        workunitAPI.getWorkunit({
            id : id
        }).then((res) => {
            if (res?.results?.length > 0) {
                setData(res?.results[0])
            }
        })
    }, [id])

    return (
        <>
            <Card
                className   = "mb-2"
                style       = {{
                    height  : '450px'
                }}
            >
                <WidgetMap
                    height  = {450}
                    defaultData = {data}
                    _zoom       = {5}
                >
                    {
                        data !== null &&
                            <Marker
                                key         = {1}
                                latitude    = {data?.latitude}
                                longitude   = {data?.longitude}
                            >
                                <button style={{ border: "none", background: "none" }}>
                                    <img src={imgMarker} style={{ width: "25px" }} />
                                </button>
                            </Marker>
                    }
                </WidgetMap>
            </Card>

            <div
                className="d-flex col-12"
            >
                <div
                    className="col-12"
                >
                    <Card
                        className   = "h-100"
                    >
                        <CardBody>
                            <div
                                className   = "text-center p-2"
                            >
                                <div
                                    className="mb-1"
                                >
                                    <img 
                                        src     = {data?.logo} 
                                        width   = {100}
                                    />
                                </div>
                                <div
                                    className   = "mb-1"
                                >
                                    <strong>ID : {data?.code}</strong>
                                </div>
                                <div className="h4"><strong>{data?.name}</strong></div>
                                <div>{data?.address}</div>
                            </div>
                        </CardBody>
                    </Card>
                </div>
            </div>
        </>
    )
}

export default WorkunitDetail