import { 
    Card, 
    CardBody, 
    Progress, 
    Table 
}               from "reactstrap"

import Avatar   from '@components/avatar'

const ProfileRank = () => {
    return (
        <Card
            className="col-12 h-100 bg-white rounded m-0 text-dark"
        >
            <CardBody>
                <div
                    className   = "d-flex"
                >
                    <div>
                        <Avatar
                            className   = "me-1 bg-primary"
                            size        = "lg"
                        />
                    </div>

                    <div>
                        Peringkat 3 <br/>
                        <strong>di Kejati DKI Jakarta</strong>
                    </div>
                </div>
               
                <div
                    className       = "py-1"
                >
                    <Table>
                        <tr>
                            <td className="">
                                Total Point
                            </td>
                            <td>

                            </td>
                            <td>
                                4567
                            </td>
                        </tr>
                        <tr>
                            <td className="col-4">
                                Total Badge
                            </td>
                            <td>

                            </td>
                            <td>
                                7
                            </td>
                        </tr>
                    </Table>
                </div>

                <div className="d-flex justify-content-between">
                    <strong>EXP Point : 20/100</strong>
                    <div className="h2 fw-bold"><strong className="text-dark">Lv.61</strong></div>
                </div>

                <div>
                <Progress
                    animated
                    color       = "primary"
                    value       = "20"
                />
                </div>
            </CardBody>
        </Card>
    )   
}

export default ProfileRank