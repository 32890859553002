import { 
	Fragment 
} from "react"

import { 
	Modal, 
	ModalBody, 
	ModalHeader 
} from "reactstrap"

const ModalCompleteTask = (props) => {
	
	const {
		show,
		data,
		onClose
	} = props

	return (
		<Fragment>
			<Modal
				size		= "md"
				isOpen		= {show}
				centered	= {true}
				scrollable	= {true}
			>
				<ModalHeader toggle={() => onClose()}>Berkas Yang Harus Dilengkapi</ModalHeader>
				<ModalBody>
					<div className="p-2 bg-light">
						<div><strong>Lampiran Pendukung</strong></div>
						<div className="mb-2">
							{
								data?.extra_data?.requirements?.data?.map((dt) => {
									return (
										<>
											<div
												className	= "text-primary cursor-pointer"
												onClick		= {() => {

												}}
											>
												<input
													type 		= "checkbox"
													className	= "me-1"
													disabled 	= {true}
													checked     = {dt?.reports?.length > 0}
												/>
												{dt?.name}
											</div>
										</>
										)
									}
								)
							}
						</div>

						<div><strong>Berkas Yang Harus Dikerjakan</strong></div>
						<div className="">
							{
								data?.extra_data?.todos?.data?.map((dt) => {
									return (
										<>
											<div
												className	= "text-primary cursor-pointer"
												onClick		= {() => {

												}}
											>
												<input
													type 		= "checkbox"
													className	= "me-1"
													disabled 	= {true}
													checked     = {dt?.reports?.length > 0}
												/>
												{dt?.name}
											</div>
										</>
										)
									}
								)
							}
						</div>

					</div>
					
				</ModalBody>
			</Modal>
		</Fragment>
	)
}

export default ModalCompleteTask
