import { Fragment } from "react"
import { Button, Spinner } from "reactstrap"

const SubmitButton = props => {
    // props
    const {
        className,
        style,
        loading,
        disabled,
        title,
        isBlock,
        onClick,
    } = props

    // content
    return (
        <Fragment>
            <Button.Ripple
                id  = "btnAdd_report"
                type="submit"
                color="primary"
                name="submitType"
                value="store"
                disabled={loading || disabled}
                className={className}
                block={isBlock ?? false}
                style={style}
                onClick={onClick}
            >

                {!loading ?
                    title :
                    <Spinner
                        color="success"
                        size="sm" />}
            </Button.Ripple>
        </Fragment>
    )
}

export default SubmitButton