import { useEffect, useState }      from "react"
import HelpTable                    from "./component/HelpTable"
import ModalDelete                  from "../../other/ModalDelete"
import HelpCreate                   from "./component/HelpCreate"
import HelpUpdate                   from "./component/HelpUpdate"
import HelpDetail                   from "./component/HelpDetail"
import HelpDeskAPI from "../../../service/pages/helpdesk"
import toast from "react-hot-toast"
import HelpCard from "./component/HelpCard"
import HelpActive from "./component/HelpActive"

const HelpDesk = ({setActive}) => {

    const [data, setData]                                 = useState(false)
    const [params, setParams]                             = useState(null)
    const [selected, setSelected]                         = useState([])
    const [isLoading, setIsLoading]                       = useState(null)
    const [pagination, setPagination]                     = useState(null)
    const [showActive, setShowActive]                     = useState(false)
    const [listCategeory, setListCategeory]               = useState(null)
    const [clearSelected, setClearSelected]               = useState(false)
    const [optionGuideCategory, setOptionGuideCategory]   = useState([])
    const [isCreateModalVisible, setIsCreateModalVisible] = useState(false)
    const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false)
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
    const [isDetailModalVisible, setIsDetailModalVisible] = useState(false)


    const [userData, setUserData]   = useState(JSON.parse(localStorage.getItem('userData')))
    
    const getData = (params) => {
        setIsLoading(true)
        HelpDeskAPI.getGuide(params)
            .then(res => {
                setData(res.results)
                setIsLoading(false)

                const pagination = {
                    count       : res.count,
                    page        : res.page,
                    page_size   : res.page_size,
                    next        : res.next,
                    previous    : res.previous,
                    total_page  : res.total_page
                }

            setPagination(pagination)

            }).catch(err => {
                toast.error(err)
            })
    }

    const getGuideCategory = () => {
        HelpDeskAPI.getGuideCategory()
            .then(res => {
                const formatData_ = []
                if (res?.count !== 0) {
                    res?.results?.map((data) => {
                        formatData_.push({
                            label : data?.name,
                            value : data?.id
                        })
                    })

                    setListCategeory(res?.results)
                    setOptionGuideCategory(formatData_)
                }
            })
      }

    const handleDelete = () => {
        if (selected.length > 0) {
            selected.map((data) => {
                HelpDeskAPI.deleteGuide(data.id)
                .then((res) => {
                    getData()
                    toast.success(res.message)
                }).catch(err => {
                    toast.error(err)
                })
            })
           
        } else {
            HelpDeskAPI.deleteGuide(selected[0].id)
                .then((res) => {
                    getData()
                    toast.success(res.message)
                }).catch(err => {
                    toast.error(err)
                })
        }
        
    }

    const handleViewCategory = (data) => {
        const category = listCategeory && listCategeory.find((item) => item.id ===  data)
        return category && category.name
    }

    useEffect(() => {
        if (clearSelected) {
            setClearSelected(!clearSelected)
        }
    }, [clearSelected])

    useEffect(() => {
        if (!isUpdateModalVisible) {
            setSelected([])
            setClearSelected(true)
        }
    }, [isUpdateModalVisible])

    useEffect(() => {
        if (!isDeleteModalVisible) {
            setSelected([])
            setClearSelected(true)
        }
    }, [isDeleteModalVisible])

    console.log(selected)
    return (
        <>
        {
            userData.role === 'operator-operator' ?
                <HelpTable
                    data                    = {data}
                    params                  = {params}
                    setData                 = {setData}
                    getData                 = {getData}
                    loading                 = {isLoading}
                    selected                = {selected}
                    setParams               = {setParams}
                    pagination              = {pagination}
                    setSelected             = {setSelected}
                    setShowActive           = {setShowActive}
                    clearSelected           = {clearSelected}
                    setClearSelected        = {setClearSelected}
                    handleViewCategory      = {handleViewCategory}
                    setIsCreateModalVisible = {setIsCreateModalVisible}
                    setIsUpdateModalVisible = {setIsUpdateModalVisible}
                    setIsDeleteModalVisible = {setIsDeleteModalVisible}
                    setIsDetailModalVisible = {setIsDetailModalVisible}
                />
            :
                <HelpCard
                    data        = {data}
                    getData     = {getData}
                    setActive   = {setActive}
                    pagination  = {pagination}
                />
        }   

            <HelpCreate
                show                = {isCreateModalVisible}
                size                = "sm"
                data                = {selected}
                title               = {selected.length > 0 ? "Ubah Bantuan" : "Tambah Bantuan"}
                setShow             = {setIsCreateModalVisible}
                setData             = {setSelected}
                getData             = {getData}
                handleViewCategory  = {handleViewCategory}
                getGuideCategory    = {getGuideCategory}
                optionGuideCategory = {optionGuideCategory}
            />

            <HelpUpdate
                show        = {isUpdateModalVisible}
                setShow     = {setIsUpdateModalVisible}
                // handleCreate= {handleCreate}
                title       = "Ubah Bantuan"
                size        = "lg"
            />

            <HelpDetail
                show        = {isDetailModalVisible}
                setShow     = {setIsDetailModalVisible}
                data        = {selected}
                title       = "Detail Bantuan"
                size        = "lg"
            />

            <HelpActive
                data={selected}
                show={showActive}
                title={!selected.is_active ? 'Aktifkan' : 'NonAktifkan' }
                getData={getData}
                setShow={setShowActive}
            />


            <ModalDelete
                show        = {isDeleteModalVisible}
                setShow     = {setIsDeleteModalVisible}
                size        = "sm"
                title       = "Hapus Privilage Role"
                handleDelete= {handleDelete}
                data        = {selected}
            />
        </>
    )
}

export default HelpDesk