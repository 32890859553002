import moment from "moment"

const RIN_1 = [      
    {
        name            : 'No.',
        width           : '65px',
        selector        : row => row.index,
		unique_key      : 'index',
    },
    {
        name            : 'Nomor Agenda',
        selector        : row => row.nomor_agenda,
    },
]

const RIN_2 = [      
    {
        name            : 'No.',
        width           : '65px',
        selector        : row => row.index,
		unique_key      : 'index',
    },
    {
        name            : 'Nomor Surat',
        selector        : row => row.nomor_surat,
    },
    {
        name            : 'Tanggal Surat',
        cell            : row => <div>{moment.unix(row.tanggal_surat).format("DD MMMM YYYY")}</div>,
    },
]

const RIN_3 = [      
    {
        name            : 'No.',
        width           : '65px',
        selector        : row => row.index,
		unique_key      : 'index',
    },
    {
        name            : 'Waktu Terima',
		cell            : row => <div>{moment.unix(row.waktu_penerimaan).format("DD MMMM YYYY")}</div>,
    },
    {
        name            : 'Sumber',
        selector        : row => row.sumber,
    },
]

const RIN_4 = [      
    {
        name            : 'No.',
        width           : '65px',
        selector        : row => row.index,
		unique_key      : 'index',
    },
    {
        name            : 'Sifat Surat',
        selector        : row => row.sifat_surat,
    },
    {
        name            : 'Nomor Surat',
        selector        : row => row.nomor_surat,
    },
    {
        name            : 'Tanggal Surat',
        cell            : row => <div>{moment.unix(row.tanggal_surat).format("DD MMMM YYYY")}</div>,
    },
]

const RIN_5 = [      
    {
        name            : 'No.',
        width           : '65px',
        selector        : row => row.index,
		unique_key      : 'index',
    },
    {
        name            : 'Jenis Produk',
        selector        : row => row.jenis_produk_intelijen,
    },
    {
        name            : 'Nomor Surat',
        selector        : row => row.nomor_surat,
    },
    {
        name            : 'Tanggal Surat',
        cell            : row => <div>{moment.unix(row.tanggal_surat).format("DD MMMM YYYY")}</div>,
    },
]

const RIN_6 = [      
    {
        name            : 'No.',
        width           : '65px',
        selector        : row => row.index,
		unique_key      : 'index',
    },
    {
        name            : 'Perihal',
        selector        : row => row.perihal,
    },
    {
        name            : 'Waktu Terima',
		cell            : row => <div>{moment.unix(row.waktu_penerimaan).format("DD MMMM YYYY")}</div>,
    },
]

const RIN_7 = [      
    {
        name            : 'No.',
        width           : '65px',
        selector        : row => row.index,
		unique_key      : 'index',
    },
    {
        name            : 'Nomor Surat',
        selector        : row => row.sumber_surat?.nomor_surat,
		unique_key      : 'sumber_surat',
    }
]

const RIN_10 = [      
    {
        name            : 'No.',
        width           : '65px',
        selector        : row => row.index,
		unique_key      : 'index',
    },
    {
        name            : 'Nama Kegiatan',
        selector        : row => row.nama_kegiatan,
    }
]

const RIN_16 = [      
    {
        name            : 'No.',
        width           : '65px',
        selector        : row => row.index,
		unique_key      : 'index',
    },
    {
        name            : 'Judul Surat',
        selector        : row => row.sumber_surat?.nomor_surat,
		unique_key      : 'sumber_surat',
    }
]

const RIN_17 = [      
    {
        name            : 'No.',
        width           : '65px',
        selector        : row => row.index,
		unique_key      : 'index',
    },
    {
        name            : 'Nomor Berita',
        selector        : row => row.nomor_berita,
    }
]

const RIN_18 = [      
    {
        name            : 'No.',
        width           : '65px',
        selector        : row => row.index,
		unique_key      : 'index',
    },
    {
        name            : 'Perihal',
        selector        : row => row.perihal,
    }
]

const RIN_19 = [      
    {
        name            : 'No.',
        width           : '65px',
        selector        : row => row.index,
		unique_key      : 'index',
    },
    {
        name            : 'Sumber',
        selector        : row => row?.sumber_surat?.nomor_surat,
		unique_key      : 'sumber_surat',
    },
    {
        name            : 'Perihal',
        selector        : row => row.perihal,
    }
]

const RIN_20 = [      
    {
        name            : 'No.',
        width           : '65px',
        selector        : row => row.index,
		unique_key      : 'index',
    },
    {
        name            : 'Nomor Berita',
        selector        : row => row.nomor_berita,
    },
    {
        name            : 'Kepada',
        selector        : row => row.kepada,
    }
]

const RIN_21 = [      
    {
        name            : 'No.',
        width           : '65px',
        selector        : row => row.index,
		unique_key      : 'index',
    },
    {
        name            : 'Kartutik',
        selector        : row => row?.kartutik_identitas?.nama_lengkap,
		unique_key      : 'kartutik_identitas',
    },
    {
        name            : 'Nama Organisasi',
        selector        : row => row.nama_organisasi,
    }
]

const RIN_22 = [      
    {
        name            : 'No.',
        width           : '65px',
        selector        : row => row.index,
		unique_key      : 'index',
    },
    {
        name            : 'Sumber',
        selector        : row => row.sumber_surat?.nomor_surat,
        unique_key      : 'sumber_surat',
    },
    {
        name            : 'Waktu',
        cell            : (row) => (
            <>
              { moment.unix(row.waktu_kegiatan).format("DD-MM-YYYY") }
            </>
        ),
    },
]

const HEADER_LIN = {
    RIN_1        : RIN_1,
    RIN_2        : RIN_2,
    RIN_3        : RIN_3,
    RIN_4        : RIN_4,
    RIN_5        : RIN_5,
    RIN_6        : RIN_6,
    RIN_7        : RIN_7,
    RIN_8        : RIN_7,
    RIN_9        : RIN_7,
    RIN_10       : RIN_10,
    RIN_11       : RIN_7,
    RIN_12       : RIN_7,
    RIN_13       : RIN_7,
    RIN_14       : RIN_7,
    RIN_15       : RIN_7,
    RIN_16       : RIN_16,
    RIN_17       : RIN_17,
    RIN_18       : RIN_18,
    RIN_19       : RIN_19,
    RIN_20       : RIN_20,
    RIN_21       : RIN_21,
    RIN_22       : RIN_22
}

export default HEADER_LIN