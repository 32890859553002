import { lazy } from 'react'
import { Navigate } from 'react-router-dom'

const IN1 = lazy(() => import('../../views/reports/in/in-1'))
const IN2 = lazy(() => import('../../views/reports/in/in-2'))
const IN3 = lazy(() => import('../../views/reports/in/in-3'))
const IN4 = lazy(() => import('../../views/reports/in/in-4'))
const IN5 = lazy(() => import('../../views/reports/in/in-5'))
const IN6 = lazy(() => import('../../views/reports/in/in-6'))
const IN7 = lazy(() => import('../../views/reports/in/in-7'))
const IN8 = lazy(() => import('../../views/reports/in/in-8'))
const IN9 = lazy(() => import('../../views/reports/in/in-9'))
const IN10 = lazy(() => import('../../views/reports/in/in-10'))
const IN11 = lazy(() => import('../../views/reports/in/in-11'))
const IN12 = lazy(() => import('../../views/reports/in/in-12'))
const IN13 = lazy(() => import('../../views/reports/in/in-13'))
const IN14 = lazy(() => import('../../views/reports/in/in-14'))
const IN15 = lazy(() => import('../../views/reports/in/in-15'))
const IN16 = lazy(() => import('../../views/reports/in/in-16'))
const IN17 = lazy(() => import('../../views/reports/in/in-17'))
const IN18 = lazy(() => import('../../views/reports/in/in-18'))
const IN19 = lazy(() => import('../../views/reports/in/in-19'))
const IN20 = lazy(() => import('../../views/reports/in/in-20'))
const IN21 = lazy(() => import('../../views/reports/in/in-21'))
const IN22 = lazy(() => import('../../views/reports/in/in-22'))
const IN23 = lazy(() => import('../../views/reports/in/in-23'))
const IN24 = lazy(() => import('../../views/reports/in/in-24'))

// const IN24 = lazy(() => import('../../views/reports/in/in-24'))
// const IN25 = lazy(() => import('../../views/reports/in/in-25'))
// const IN26 = lazy(() => import('../../views/reports/in/in-26'))
// const IN27 = lazy(() => import('../../views/reports/in/in-27'))
// const IN28 = lazy(() => import('../../views/reports/in/in-28'))
// const IN29 = lazy(() => import('../../views/reports/in/in-29'))

const InRoutes = [
	{
		path: '/in',
		exact: true,
		element: <Navigate to='/in/1' />,
		meta: {
			action: 'read',
			resource: 'in-surat'
		}
	},
	{
		path: "/in/1",
		element: < IN1 />,
		meta: {
			action: "read",
			resource: "in1-sprintug"
		}
	},
	{
		path: "/in/2",
		element: < IN2 />,
		meta: {
			action: "read",
			resource: "in2-sppps"
		}
	},
	{
		path: "/in/3",
		element: < IN3 />,
		meta: {
			action: "read",
			resource: "in3-sprintops"
		}
	},
	{
		path: "/in/4",
		element: < IN4 />,
		meta: {
			action: "read",
			resource: "in4-analisa-sasaran"
		}
	},
	{
		path: '/in/4/:report_id/:action',
		element: < IN4 />,
		meta: {
			action: "read",
			resource: "in4-analisa-sasaran"
		}
	},
	{
		path: "/in/5",
		element: < IN5 />,
		meta: {
			action: "read",
			resource: "in5-analisa-tugas"
		}
	},
	{
		path: "/in/6",
		element: < IN6 />,
		meta: {
			action: "read",
			resource: "in6-target-operasi"
		}
	},
	{
		path: "/in/7",
		element: < IN7 />,
		meta: {
			action: "read",
			resource: "in7-rencana-penyelidikan"
		}
	},
	{
		path: "/in/8",
		element: < IN8 />,
		meta: {
			action: "read",
			resource: "in8-rencana-penggalangan"
		}
	},
	{
		path: "/in/9",
		element: < IN9 />,
		meta: {
			action: "read",
			resource: "in9-sd-perm-keterangan"
		}
	},
	{
		path: "/in/10",
		element: < IN10 />,
		meta: {
			action: "read",
			resource: "in10-ba-perm-keterangan"
		}
	},
	{
		path: "/in/11",
		element: < IN11 />,
		meta: {
			action: "read",
			resource: "in11-catatan-wawancara"
		}
	},
	{
		path: "/in/12",
		element: < IN12 />,
		meta: {
			action: "read",
			resource: "in12-su-paparan-pps"
		}
	},
	{
		path: "/in/13",
		element: < IN13 />,
		meta: {
			action: "read",
			resource: "in13-surat-persetujuan-pps"
		}
	},
	{
		path: "/in/14",
		element: < IN14 />,
		meta: {
			action: "read",
			resource: "in14-pps-ditolak"
		}
	},
	{
		path: "/in/15",
		element: < IN15 />,
		meta: {
			action: "read",
			resource: "in15-perkembangan-pps"
		}
	},
	{
		path: "/in/16",
		element: < IN16 />,
		meta: {
			action: "read",
			resource: "in16-penghentian-pps"
		}
	},
	{
		path: "/in/17",
		element: < IN17 />,
		meta: {
			action: "read",
			resource: "in17-selesai-pps"
		}
	},
	{
		path: "/in/18",
		element: < IN18 />,
		meta: {
			action: "read",
			resource: "in18-koordinasi-pps"
		}
	},
	{
		path: "/in/19",
		element: < IN19 />,
		meta: {
			action: "read",
			resource: "in19-per-pemantauan"
		}
	},
	{
		path: "/in/20",
		element: < IN20 />,
		meta: {
			action: "read",
			resource: "in20-sk-membwa-p-intel"
		}
	},
	{
		path: "/in/21",
		element: < IN21 />,
		meta: {
			action: "read",
			resource: "in21-sp-penkum"
		}
	},
	{
		path: "/in/22",
		element: < IN22 />,
		meta: {
			action: "read",
			resource: "in22-analisa-berita"
		}
	},
	{
		path: "/in/23",
		element: < IN23 />,
		meta: {
			action: "read",
			resource: "in23-tt-peny-informasi"
		}
	},
	{
		path: "/in/berita_acara_kunjungan_lapangan",
		element: < IN24 />,
		meta: {
			action: "read",
			resource: "in24-berita-acara-kunjungan-lapangan"
		}
	}
]

export default InRoutes