import React, {  
  useContext, 
  useEffect, 
  useState 
}                       from 'react'
import { 
  Button, 
  Card, 
  CardBody, 
  CardHeader, 
  Col, 
  DropdownItem, 
  DropdownMenu, 
  DropdownToggle, 
  Input, 
  Row, 
  UncontrolledDropdown 
}                       from 'reactstrap'

import { 
  MoreVertical, 
  X 
}                       from 'react-feather'

import Avatar           from '@components/avatar'
import AddMember        from './AddMember'

// Context
import { ChatContext }  from '../../../context/chatContext'

// API
import userManagementAPI from '../../../service/pages/user-management'

const CardGroup = () => {

    const {
        // State
        addAdmin,
        selected,
        listGroup,
        listAdmin,
        deleteMember,
        setShowGroup,
        getListGroup,
    } = useContext(ChatContext)

    const [showMember, setShowMember] = useState(false)
    // const [employee, setEmployee] = useState(false)
  const handleViewAdmin = (id) => {
    const admin = listAdmin && listAdmin.find((item) => item ===  id)
    return admin && 'Admin'
  }
  
  useEffect(() => {
    if (selected && selected.room_type === 2) {
      getListGroup({uuid : selected && selected.uuid})
    }
  }, [selected])

  return (
    <>
      <Card className='h-100'>
        <CardHeader className='d-flex justify-content-start align-items-center'>
              <X onClick   = {() => setShowGroup(false)} className = 'cursor-pointer'/>
              <span className = 'ms-1'>
                Informasi Group
              </span>
        </CardHeader>
        <hr/>
        <CardBody style = {{ height : '100%' }}>
          <div>
            <div className='text-center'>
              <h1 className='fw-bolder'>{selected && selected.name}</h1>
              <p className='text-muted fw-bolder'>
                {listGroup && Array.isArray(listGroup) && listGroup.length} Anggota Group
              </p>
              {/* <div>
                <Input
                  className='round'
                  placeholder='Cari Percakapan'
                />
              </div> */}
              <div>
                <Button 
                  color='primary'
                  className='round my-2 w-100'
                  onClick={() => setShowMember(true)}
                >
                  Tambah Anggota
                </Button>
              </div>
            </div>
              <div className='overflow-auto ps-2 pe-1' style={{ height : '500px', overflowX : 'visible'}}>  
                {
                  listGroup && Array.isArray(listGroup) && listGroup.map(data => (
                  <Row className='p-0'>
                      <Col xs={2} className='d-flex align-items-center'>
                        <Avatar
                          // img         = {data.img}
                          size        = "md"
                          className   = "p-0"
                        />
                      </Col>
                      <Col xs={7}>
                        <h4>
                          {data?.name}
                        </h4>
                        <p className='text-primary'>
                          {handleViewAdmin(data?.user_id)}
                        </p>
                      </Col>
                      <Col xs={2} className='d-flex justify-content-center'>
                        {
                          handleViewAdmin(data?.user_id) && (
                            <UncontrolledDropdown >
                              <DropdownToggle 
                                  tag         = 'div' 
                                  className   = 'cursor-pointer me-1'
                              >
                                  <MoreVertical />
                              </DropdownToggle>
                              <DropdownMenu 
                                  className   = 'cursor-pointer'
                              >
                                {
                                  !handleViewAdmin(data?.user_id) && (
                                    <DropdownItem tag = "a" onClick={() => addAdmin(selected.uuid, [data?.user_id])}>
                                      Jadikan Admin
                                    </DropdownItem>
                                  )
                                }
                                  
                                  <DropdownItem tag = "a" onClick={() => deleteMember(selected.uuid, [data?.user_id])}>
                                      Keluarkan Dari Group
                                  </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          )
                        }
                      </Col>
                </Row>
                  ))
                }          
              </div>
          </div>
        </CardBody>
      </Card>
      <AddMember
        show={showMember}
        setShow={setShowMember}
        getListGroup={getListGroup}
        selected={selected}
      />
    </>
  )
}

export default CardGroup
