import { useEffect, useState }              from "react"
import { Nav, NavItem, NavLink }            from "reactstrap"
import { useParams }                        from "react-router-dom"
import HelpDesk                             from "./help"
import Ticketing                            from "./ticketing"
import Kepja                                from "./kepja"

const HeldeskTabMenu = () => {

    const params                = useParams()
    const type                  = params.type
    const [active, setActive]   = useState(1)
    const userData =  JSON.parse(localStorage.getItem('userData'))

    const toggle = tab => {
        if (active !== tab) {
            setActive(tab)
        }
    }

    const tabs = [
        {
            id: 1,
            name: "Bantuan"
        }, {
            id: 2,
            name: userData.role === 'operator-operator' ? "Ticketing" : "Riwayat Pertanyaan"
        }, {
            id: 3,
            name: userData.role === 'operator-operator' ? "Panduan KEPJA" : null
        }
    ]

    const renderContent = () => {
        if (active === 1) {
            return <HelpDesk setActive={setActive} />
        } else if (active === 2) {
            return <Ticketing />
        } else if (active === 3) {
            return <Kepja />
        }
    }

    useEffect(() => {
        if (type === "user-management") {
            setActive(1)
        } else if (type === "role") {
            setActive(2)
        } else if (type === "log-activity") {
            setActive(3)
        }
    }, [type])

    return (
        <>
            <Nav 
                className="border-bottom"
                tabs
            >
                {
                    tabs?.map((item) => {
                        return (
                            <NavItem>
                                <NavLink
                                    active={active === item.id}
                                    onClick={() => {
                                        toggle(item.id)
                                    }}
                                >
                                    {item.name}
                                </NavLink>
                            </NavItem>
                        )
                    })
                }
            </Nav>

            {renderContent()}
        </>
    )
}

export default HeldeskTabMenu