import baseURL, {get, delete_, post, put}  from '../../index'

const getRole           = (params)      => get("/authentication/roles/", params)
const getFeatures       = (params)      => get("/authentication/features/", params)
const getMenusAll       = (params)      => get("/authentication/menus", params)

const createRole        = (params)      => post(`/authentication/roles/`, params)
const createFeature     = (params)      => post(`/authentication/features/?all`, params)

const updateRole            = (id, body)    => put(`/authentication/roles/${id}/`, body)
const updateFeature         = (id, body)    => put(`/authentication/features/${id}/?all`, body)
const updateMenuStatus      = (id, body)    => put(`/authentication/menus/${id}/?sidebar=required`, body)
const updateMenuStatusBulk  = (id, body)    => put(`/authentication/menus/${id}/?sidebar=required&bulk_update=true`, body)

const deleteRole            = (id)          => delete_(`/authentication/roles/${id}`)

const FeatureAPI = {
    getRole,
    getFeatures,
    getMenusAll,
    
    createFeature,
    createRole,

    updateRole,
    updateFeature,
    updateMenuStatus,
    updateMenuStatusBulk,
    
    deleteRole
}

export default FeatureAPI