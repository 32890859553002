import { 
    useContext, 
    useEffect, 
    useState
}                                   from 'react'

import { 
    Button,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupText,
    ListGroup, 
    ListGroupItem, 
    Modal, 
    ModalBody, 
    ModalHeader 
}                                   from 'reactstrap'

import Avatar                       from '@components/avatar'

import { UserManagementContext }    from '../../../context/userManagementContext'
import { ChevronLeft, ChevronRight, ChevronsLeft, ChevronsRight, Search } from 'react-feather'
import chatAPI from '../../../service/pages/chat'
import toast from 'react-hot-toast'
import CustomTableEmptyData from '../../../@core/components/custom-table/empty'

const SelectSingleUser = ({show, setShow, getList}) => {
    const {
        // State
        listData,
        pagination, 
        setPagination,

        // Function
        getData
    } = useContext(UserManagementContext)

    const userData =  JSON.parse(localStorage.getItem('userData'))

    const [keyword, setKeyword] = useState(null)

    const onSelect = (data) => {
        const formData = {
            room_type: 1,
            members: [data.user_id, userData.user_id]
        }
        chatAPI.createList(formData)
            .then(() => {
                setShow(false)
                toast.success('Berhasil Menambahkan Room')
                getList()
            })
            .catch(() => {
                toast.error('Gagal Menambahkan')
            })
    }

    useEffect(() => {
        getData()
    }, [])

  return (
    <Modal
        isOpen={show}
        toggle={() => setShow(false)}

    >
        <ModalHeader toggle={() => setShow(false)}>
            Daftar User
        </ModalHeader>
        
        <ModalBody>
            <Form className='mb-1'>
                <InputGroup className='input-group-merge'>
                        <InputGroupText>
                            <Search size={14} />
                        </InputGroupText>
                    <Input 
                        onChange    = {(e) => { setKeyword(e.target.value) }} 
                        placeholder = 'Masukkan Nama Pengguna ...' 
                    />
                    <Button 
                        color       = 'primary' 
                        outline
                        onClick    = {() => { getData({keyword : keyword}) }} 
                    >
                        Cari
                    </Button>
                </InputGroup>
            </Form>
            
            <div className='overflow-auto'style={{ height : '500px' }}>
                <ListGroup>
                    {
                        Array.isArray(listData) && listData.map((item) => (
                            <ListGroupItem 
                                key         = {item.id} 
                                onClick     = {() => { onSelect(item) }}
                                className   = "cursor-pointer" 
                            >
                                <div className='d-flex align-items-center'>
                                    <div>
                                        <Avatar 
                                            className   = "me-1 p-0"
                                            size        = "md"
                                            img         = {item?.photo  === "" ? `https://ui-avatars.com/api/?name=${item ? item?.name : "UN"}&background=4e73df&color=fff&bold=true` : item?.photo}
                                        />
                                    </div>
                                    <div>
                                        <h5 className='fw-bolder'>{item.name}</h5>
                                        <h6 className='fw-bolder text-muted'>{item.work_unit?.name}</h6>
                                    </div>
                                </div>
                                
                            </ListGroupItem>
                        ))
                    }
                    {listData && listData.length === 0 && (
                        <CustomTableEmptyData />
                    )}
                </ListGroup>
            </div>
            <div 
                style       = {{ right : 0, bottom : 0 }}
                className   = 'mt-1 d-flex justify-content-end'
            >
                
                {
                    pagination.previous ? (
                        <>
                            <div 
                                onClick     = {() => getData({ page : 1, keyword : keyword })}
                                className   = 'cursor-pointer'
                            >
                                <ChevronsLeft  />
                            </div>
                            <div 
                                onClick     = {() => getData({ page:pagination.page - 1, keyword : keyword })}
                                className   = 'cursor-pointer'
                            >
                                <ChevronLeft  />
                            </div>
                        </>
                    ) : (
                        <>
                            <div className='text-muted'>
                                <ChevronsLeft  />
                            </div>
                            <div className=' text-muted'>
                                <ChevronLeft  />
                            </div>
                        </>
                    )
                }
                <div className='mx-4'>
                    <p>
                        {(pagination.page * pagination.page_size) - 9} - {' '} 
                        {pagination.count > 10 ? pagination.page_size * pagination.page : pagination.count} dari {' '}
                        {pagination.count}
                    </p>
                </div>
                {
                    pagination.next ? (
                        <>
                            <div 
                                onClick     = {() => getData({ page:pagination.page + 1, keyword : keyword })}
                                className   = 'cursor-pointer'
                            >
                                <ChevronRight />
                            </div>
                            <div 
                                onClick     = {() => getData({ page: pagination?.total_page, keyword : keyword })}
                                className   = 'cursor-pointer'
                            >
                                <ChevronsRight />
                            </div>
                        </>
                    ) : (
                        <>
                            <div className='text-muted'>
                                <ChevronRight />
                            </div>
                            <div className='text-muted'>
                                <ChevronsRight />
                            </div>
                        </>
                    ) 
                }
            </div>
        </ModalBody>
       
    </Modal>
  )
}

export default SelectSingleUser
