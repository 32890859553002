import { 
	Fragment, 
	useContext, 
	useEffect, 
	useState 
} from "react"

import {
	Input,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
} from "reactstrap"

import { ReportContext }			 	from "../../../context/reportContext"
import { getReportField } 				from "../../../utility/report-type-id"
import SubmitButton 					from "../../../@core/components/submit-button"
import moment 							from "moment"

const ModalReview = (props) => {

	const {reportData} = props
	const {
		dataReview, 
        setDataReview,
		showModalReview, 
		setShowModalReview,
		reportTypeField,
		reportTypeFieldLapbul,

		updateReport
	} = useContext(ReportContext)

	const [loading, setLoading] 					= useState(false)
	const [keterangan, setKeterangan]				= useState("")

	useEffect(() => {
		if (!showModalReview) {
			setShowModalReview(null)
		}
	}, [showModalReview])

	useEffect(() => {
		if (dataReview) {
			if (dataReview?.data?.keterangan && dataReview?.data?.keterangan !== "-") {
				setKeterangan(dataReview?.data?.keterangan)
			} else {
				setKeterangan("")
			}
		} else {
			setKeterangan("")
		}
	}, [dataReview])

	const actionSubmitReview = (reportId, formData) => {

		let newFieldData_ = []

		if (reportData.reportTypeId === '35') {
			
			if (formData.memerintahkan) {
				if (typeof formData.memerintahkan === 'object' && "id" in formData.memerintahkan) {
					formData.memerintahkan = formData.memerintahkan.user_id
				} else {
					formData.memerintahkan = formData?.memerintahkan?.map((data) => (data?.user_id))
				}
			}
	
			if (formData.penandatangan) {
				if (typeof formData.penandatangan === 'object' && "id" in formData.penandatangan) {
					formData.penandatangan = `${formData.penandatangan.user_id}`
				}
			}
	
			if (formData.mengetahui) {
				if (typeof formData.mengetahui === 'object' && "id" in formData.mengetahui) {
					formData.mengetahui = formData.mengetahui.user_id
				}
			}
	
			if (formData.bidang) {
				if (typeof formData.bidang === 'object' && "id" in formData.bidang) {
					formData.bidang = formData.bidang.id
				}
			}
			if (formData.sektor) {
				if (typeof formData.sektor === 'object' && "id" in formData.sektor) {
					formData.sektor = formData.sektor.id
				}
			}
	
			if (formData.id_perpanjangan) {
				if (typeof formData.id_perpanjangan === 'object') {
	
					if ("id" in formData.id_perpanjangan) {
						formData.id_perpanjangan = `${formData?.id_perpanjangan?.id}`
					} else {
						formData.id_perpanjangan = `${formData?.id_perpanjangan?.report_id}`
					}
				}
			}

			console.log(formData)
			newFieldData_ = getReportField(formData, reportTypeFieldLapbul)
		} else {
			newFieldData_ = getReportField(formData, reportTypeField)
		}

		console.log(newFieldData_)

		const finalFormData = {
			report_type_id 		: dataReview?.report_type_id,
			report_values 		: newFieldData_,
			report_attachments 	: []
		}

		if (formData?.submit_type === "draft") {
            finalFormData.is_draft = true
        } else {
            finalFormData.is_draft = false
        }

		const otherAction = async() => {
			setShowModalReview(false)
			setDataReview(null)
		}

		// console.log(finalFormData)

		updateReport(reportId, finalFormData, otherAction)
	}

	const handleSubmit = () => {
		const formData = dataReview?.data
		formData.keterangan = keterangan

		console.log("MODALREVIEW2", formData)

		actionSubmitReview(dataReview?.data?.report_id, formData)
	}

	return (
		<Fragment>
			<Modal
				size		= {"md"}
				isOpen		= {showModalReview}
				centered	= {true}
				onClosed	= {() => { setDataReview(null) }}
				scrollable	= {true}
			>
				<ModalHeader toggle={() => {
					setShowModalReview(false)
					setDataReview(null)
				}}>Beri Ulasan</ModalHeader>

				<ModalBody className="">
					<Input
						type		= "textarea"
						placeholder	= "Beri Ulasan.."
						value 		= {keterangan}
						onChange	= {(e) => {
							setKeterangan(e.target.value)
						}}
					/>
				</ModalBody>

				<ModalFooter className="d-flex justify-content-end">
					<SubmitButton
                        title       = {"Simpan"}
                        loading     = {loading} 
                        onClick     = {() => {
							handleSubmit()
						}}
                    />
				</ModalFooter>
			</Modal>
		</Fragment>
	)
}

export default ModalReview
