// ** Icons Import
import { Folder } from 'react-feather'

export default [
  {
    id          : 'data-management',
    icon        : <Folder size={20} />,
    title       : 'Manajemen Data',
    action      : 'read',
    navLink     : "/data-management",
    resource    : 'manajemen-data'
  }
]
