import { 
    useState,
    createContext,
    useEffect
}                       from "react"

//Context
const GisContext  = createContext(null)

//API
import GisAPI      from "../service/pages/gis"

const GisProvider = ({ children }) => {

    //State
    const [GisData, setGisData]             = useState(false)
    const [loading, setLoading]             = useState(true)

    const getDataGis = (params, action) => {
        setLoading(true)
        
        GisAPI.getDataGis(params).then(
            res => {
                setLoading(false)
                setGisData({...res})

                action()
            }
        ).catch(
            err => {
                console.log(err, 'err')
            }
        )
    }

    useEffect(() => {
        if (localStorage.getItem('userData')) {
        }
    }, [])

    return <GisContext.Provider
        value={{
            getDataGis,

            GisData,
            loading
        }}
    > 
        {children}
    </GisContext.Provider>
}

export { GisContext, GisProvider }