import {
    Button,
    Card,
    CardBody,
    Col,
    Input,
    InputGroup
}               from "reactstrap"

import {
    MapPin,
    Search
}                       from "react-feather"

import { 
    useEffect,
    useState 
}                       from "react"

const WidgetTempatKejadian = (props) => {
    
    const {
        data,
        setSelectedMarker
    } = props

    const [location, setLocation]   = useState([]) 
    const [keyword, setKeyword]     = useState("")

    const renderCardResult = (dt) => {
        return (
            <>
                <small>
                    <Card 
                        className   = "cursor-pointer"
                        color       = "light"
                        style       = {{
                            marginBottom : '7px'
                        }}
                        onClick = {() => {
                            setSelectedMarker(dt)
                        }}
                    >
                        <CardBody 
                            className="m-0"
                            style={{
                                padding : '9px'
                            }}
                        >
                            <div
                                className       = "d-flex justify-content-between"
                            >
                                <div
                                    className   = "d-flex align-items-center col-10"
                                >
                                    {dt.location?.name}
                                </div>
                                <div
                                    className   = "col-2 text-end"
                                >
                                    <strong>{dt.report_ids?.length} Kasus</strong>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </small>
            </>
        )
    }

    const handleSearch = () => {
        setLocation([])
        const filteredResults = data.filter(item => item?.location?.name.toLowerCase().includes(keyword.toLowerCase()))
        setLocation([...filteredResults])

    }

    useEffect(() => {
        if (data) {
            setLocation(data)
        }

        if (keyword === "") {
            setLocation(data)
        }
    }, [props, keyword])

    return (
        <>
            <Col
                className="pb-2 h-100"
            >
                <Card className="h-100 p-0 m-0">
                    <CardBody>
                        <div className = "d-flex"
                            style={{
                                marginBottom : '7px'
                            }}
                        >
                            <small><strong>Tempat Kejadian</strong></small>
                        </div>
                        <div
                            style={{
                                marginBottom : '10px'
                            }}
                        >
                            <InputGroup>
                                <Input
                                    size        = "md"
                                    type        = "text"
                                    className   = "form-control-md bg-light"
                                    placeholder = "Cari ..."
                                    onChange    = {(e) => {
                                        setKeyword(e.target.value)
                                    }}
                                />
                                <Button
                                    size        = "sm"
                                    onClick     = {() => handleSearch()}
                                >
                                    <Search
                                        size    = {18}
                                    />
                                </Button>
                            </InputGroup>
                        </div>
                        <div
                            className   = "overflow-auto"
                            style       = {{
                                height  : '120px'
                            }}
                        >
                            {
                                location !== null &&
                                    location?.map((dt) => {
                                        return (
                                            <>
                                                {renderCardResult(dt)}
                                            </>
                                        )
                                    })
                            }
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </>
    )
}

export default WidgetTempatKejadian