// import React from 'react'
import { ChevronRight, Folder, Search } from 'react-feather'
import { Button, FormGroup, Input, InputGroup, InputGroupText, Label, ListGroup, ListGroupItem, Media, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { useContext, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { DataManagerContext } from '../../../../context/dataManagerContext'
import driveAPI from '../../../../service/pages/drive'


const  TagList = ({data, show, setShow}) => {

    const {
        folder,
        getTags,
        getDrive,
        listTags,
    } = useContext(DataManagerContext)

    
    const [url, setUrl]                     = useState([])
    const [destination, setDestination]     = useState([])

    const selectRow = (id, code) => {  
        if (destination.indexOf(id) !== -1) {
            setDestination(destination.filter((obj) => obj !== id))
            setUrl(url.filter((obj) => obj !== code))
        } else {
            if (destination.length > 0) {
                destination.splice(0, 1)
                url.splice(0, 1)
                setDestination([...destination, id])
                setUrl([...url, code])
            } else {
                setDestination([...destination, id])
                setUrl([...url, code])

            }
            
        }
    }

    const AddTags = () => {

        const formData = {
            tags : [destination[0]]
        }

        driveAPI.addTags(data.code, formData)
            .then(res => {
                if (!res.is_error) {
                    toast.success('Tag Added!')
                    getDrive(folder ? `?dir=${folder.code}` : null)
                    setShow(false)
                }
            })
            .catch(err => {
                toast.error(err)
            })
    }


  return (
    <Modal
        isOpen={show}
        toggle={() => setShow(!show)}
        size='sm'
        centered
    >
        <ModalHeader toggle={() => setShow(!show)}>List Tag</ModalHeader>
        <ModalBody>
            <ListGroup>
                {
                    Array.isArray(listTags) && listTags.map((data, i) => (
                        <ListGroupItem
                            key             = {i}
                            active          = {destination && destination.indexOf(data.id) !== -1 }
                            className       = 'cursor-pointer '
                            onClick         = {() => selectRow(data.id, data.code) }
                        >
                            <Media className='d-flex justify-content-between'>
                                <Media left>
                                    {data.name}
                                </Media>
                                <Media right>
                                    Add Tag
                                </Media>
                            </Media>
                        </ListGroupItem>
                    ))
                }
            </ListGroup>
        </ModalBody>
        <ModalFooter className='d-flex justify-content-between'>
            <Button 
                // onClick     = {shareDrive}
                // className   ='w-100'
                type        = 'button' 
                color       = 'primary '
                outline
                onClick     = {() => setShow(false)}
            >
                Batal
            </Button>
            <Button 
                onClick     = {AddTags}
                // className   ='w-100'
                color       = 'primary '
                type        = 'submit' 
                disabled    = {destination === false || destination.length === 0 }
            >
                Selesai
            </Button>
            </ModalFooter>
    </Modal>
  )
}

export default TagList
