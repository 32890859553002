import { 
    useEffect, 
    useState,
    useContext 
}                                   from "react"

import ModalDelete                  from "../../other/ModalDelete"
import featureAPI                   from "../../../service/pages/feature"
import toast                        from "react-hot-toast"
import NewFileTable from "./component/new-file-table"
import { ReportContext } from "../../../context/reportContext"
import { ManagementFileContext } from "../../../context/managementFileContext"
import FileManagementAPI from "../../../service/pages/file-management"
import CreateFile from "./component/create-file"
import { Nav, NavItem, NavLink } from "reactstrap"
import CategoryForm from "./component/category-form"
import CategoryTable from "./component/category-table"

const NewFile = () => {

    const [type, setType]                                 = useState('file')
    const [params, setParams]                             = useState({})
    const [selected, setSelected]                         = useState([])
    const [clearSelected, setClearSelected]               = useState(false)
    const [clearSelectedCategory, setClearSelectedCategory] = useState(false)
    const [isCreateModalVisible, setIsCreateModalVisible] = useState(false)
    const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false)
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)

    const {
        //State
        listData,
        listDataCategory,
        setListDataCategory,
        setListData,
        isLoading,
        isLoadingCategory,
        pagination,
        paginationCategory,
        setPagination,
        
        // showModalCompleteTask,
  
        dataSelected,
        setDataSelected,

        showModalPreview,
        setUpModalPreview,

        //function
        getData,
        getDataCategory

    }                   = useContext(ManagementFileContext)

    const handleDelete = () => {
       
        selected.map(data => {
            FileManagementAPI.deleteData(data?.id)
                .then(() => {
                    toast.success('Berhasil Hapus Data!')
                    getData()
                    setSelected([])
                    setClearSelected(!clearSelected)
                })
                .catch(() => {
                    toast.error('Gagal Hapus Data')
                })
        })
    }

    const handleDeleteCategory = () => {
       
        selected.map(data => {
            FileManagementAPI.deleteCategory(data?.id)
                .then(() => {
                    toast.success('Berhasil Hapus Data')
                    getDataCategory()
                    setSelected([])
                    setClearSelectedCategory(!clearSelectedCategory)
                })
                .catch(() => {
                    toast.error('Gagal Hapus Data')
                })
        })
    }

    console.log('selected', selected)

    
    // useEffect(() => {
    //     if (type === 'category') {
    //         getDataCategory(params)
    //     } else if (type === 'file') {
    //         getData(params)
    //     }
    // }, [type, params])


    return (
        <>
            <Nav 
                className="border-bottom"
                tabs fill
            >
                <NavItem>
                    <NavLink 
                        onClick={() => {
                            setType('category')
                            setParams({page : 1})
                          
                        }}
                        active = {type === 'category' }
                    >
                        Category
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        onClick={() => {
                            setType('file')
                            setParams({page : 1})
                        
                        }}
                        active = {type === 'file' }
                    >
                        File
                    </NavLink>
                </NavItem>
            </Nav>
            {
                type === 'file' ? (
                    <NewFileTable
                        data                    = {listData}
                        type                    = {type}
                        params                  = {params}
                        setData                 = {setListData}
                        getData                 = {getData}
                        loading                 = {isLoading}
                        selected                = {selected}
                        setParams               = {setParams}
                        pagination              = {pagination}
                        setSelected             = {setSelected}
                        clearSelected           = {clearSelected}
                        setClearSelected        = {setClearSelected}
                        setIsCreateModalVisible = {setIsCreateModalVisible}
                        setIsUpdateModalVisible = {setIsUpdateModalVisible}
                        setIsDeleteModalVisible = {setIsDeleteModalVisible}
                    />
                ) : (
                    <CategoryTable
                        data                    = {listDataCategory}
                        type                    = {type}
                        params                  = {params}
                        setData                 = {setListDataCategory}
                        getData                 = {getDataCategory}
                        loading                 = {isLoadingCategory}
                        selected                = {selected}
                        setParams               = {setParams}
                        pagination              = {paginationCategory}
                        setSelected             = {setSelected}
                        clearSelected           = {clearSelectedCategory}
                        setClearSelected        = {setClearSelectedCategory}
                        setIsCreateModalVisible = {setIsCreateModalVisible}
                        setIsUpdateModalVisible = {setIsUpdateModalVisible}
                        setIsDeleteModalVisible = {setIsDeleteModalVisible}
                />
                )

            }
         
            {
                type === 'category' ? (
                    <CategoryForm 
                        show        = {isCreateModalVisible}
                        setShow     = {setIsCreateModalVisible}
                        getData     = {getDataCategory}
                        params      = {params}
                        title       = {selected[0] ? "Ubah Category Baru" : "Tambah Category Baru"}
                        size        = "sm"
                        setSelected = {setSelected}
                        data_       = {selected[0]}
                    />
                ) : (
                    <CreateFile
                        show        = {isCreateModalVisible}
                        setShow     = {setIsCreateModalVisible}
                        getData     = {getData}
                        params      = {params}
                        title       = {selected[0] ? "Ubah Berkas Baru" : "Tambah Berkas Baru"}
                        size        = "sm"
                        setSelected = {setSelected}
                        data_       = {selected[0]}
                    />
                )
            }
           
            {/* <RoleUpdate
                show        = {isUpdateModalVisible}
                setShow     = {setIsUpdateModalVisible}
                getData     = {getData}
                params      = {params}
                title       = "Ubah Role"
                size        = "xl"
                data        = {selected?.length === 1 ? selected[0] : null}
            /> */}

            <ModalDelete
                show        = {isDeleteModalVisible}
                setShow     = {setIsDeleteModalVisible}
                size        = "sm"
                title       = {`Hapus ${type === 'category' ? 'Category' : 'File'}`}
                handleDelete= { type === 'category' ? handleDeleteCategory : handleDelete}
                data        = {selected}
            />
        </>
    )
}

export default NewFile