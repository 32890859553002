import CongurationTabMenu 	from '../../views/configuration'
import ConfigurationDetail 	from '../../views/configuration/ConfigurationDetail'

const ConfigurationRoutes = [
	{
		path	: '/configuration',
		element	: <CongurationTabMenu />,
		meta	: {
			action: 'read',
			resource: 'pengaturan',
		}
	},
	{
		path	: '/configuration/:type',
		element	: <CongurationTabMenu/>,
		meta	: {
			action: 'read',
			resource: 'pengaturan',
		}
	},
	{
		path	: '/configuration/:type/:id',
		element	: <ConfigurationDetail/>,
		meta	: {
			action: 'read',
			resource: 'pengaturan'
		}
	}
]

export default ConfigurationRoutes
