import {
    Button,
    Card,
    CardBody,
    Col,
    Spinner
}                           from "reactstrap"

import { 
    useContext, 
    useEffect, 
    useState 
}                               from "react"

import { WorkunitContext }      from "../../../context/workunitContext"
import { ProfileContext }       from "../../../context/profileContext"
import { selectThemeColors }    from '@utils'

import Select                   from 'react-select'
import Flatpickr                from "react-flatpickr"
import moment                   from "moment"
import toast                    from "react-hot-toast"
import { getUserData }          from "../../../utility/Utils"
import { GisContext }           from "../../../context/GisContext"

const currentDate  = new Date()
const currentMonth = currentDate.getMonth()
const currentYear  = currentDate.getFullYear()
const lastDate     = new Date(currentYear, currentMonth + 1, 0).getDate()

const WidgetFilter = (props) => {

    const {
        getData,
        setPeriodeStr,
        setTypeMarker
    } = props

    const {
        loading
    } = useContext(GisContext)

    const [selectedWorkunit,  setSelectedWorkunit]  = useState([])
    const [selectedProduct, setSelectedProduct]     = useState([])
    const [endDate, setEndDate]                     = useState(new Date(`${currentYear}-${currentMonth + 1}-${lastDate}`))
    const [startDate, setStartDate]                 = useState(new Date(`${currentYear}-${currentMonth + 1}-1`))
    const [endDateErr, setEndDateErr]               = useState(false)
    const [startDateErr, setStartDateErr]           = useState(false)

    const {
		workunitOption 
	}   = useContext(WorkunitContext)

    const {
        reportTypeOption
    }   = useContext(ProfileContext)

    const [produkIntelOption, setProdukIntelOption] = useState([])

    const handleFilter = () => {
        let workunit = ""
        let product  = ""

        selectedWorkunit?.map((dt) => {
            if (workunit !== "") {
                workunit += `,${dt.value}`
            } else {
                workunit += `${dt.value}`
            }
        })

        selectedProduct?.map((dt) => {
            if (product !== "") {
                product += `,${dt.value}`
            } else {
                product += `${dt.value}`
            }
        })

        const params = {
            work_unit_id      : workunit,
            intel_product_id  : product,
            start_date        : startDate ? moment(startDate).format("YYYY-MM-DD") : null,
            end_date          : endDate ? moment(endDate).format("YYYY-MM-DD") : null,
        }

        setPeriodeStr(`${moment(startDate).format("YYYY-MM-DD")} - ${moment(endDate).format("YYYY-MM-DD")}`)

        const action = () => {
			toast.success("Data Peta Berhasil Dimuat!")
            setTypeMarker("scene")
        }
        getData(params, action)

    }

    const validateError = (state) => {
        if (startDate && endDate) {
            if (startDate > endDate) {
                if (state === "start") {
                    setStartDateErr("Tanggal Awal Tidak Boleh Lebih Besar Dari Tanggal Akhir")
                } else if (state === "end") {
                    setEndDateErr("Tanggal Akhir Tidak Boleh Lebih Kecil Dari Tanggal Awal")
                }
            }
        } else {
            setStartDateErr(false)
            setEndDateErr(false)
        }
    }

    useEffect(() => {
        const temp = []

        reportTypeOption.LIN?.map((dt) => {
            if (
                dt.report === "L.IN.1" ||
                dt.report === "L.IN.2" ||
                dt.report === "L.IN.4" ||
                dt.report === "L.IN.5"

                // dt.report === "L.IN.7"
            ) {
                temp.push({
                    label : `${dt.report} - ${dt.reportTitle}`,
                    value : dt.reportTypeId
                })
            }
        })

        reportTypeOption.DIN?.map((dt) => {
            if (
                dt.report === "D.IN.1"
                // dt.report === "D.IN.2" ||
                // dt.report === "D.IN.3" ||
                // dt.report === "D.IN.4" ||
                // dt.report === "D.IN.5" ||
                // dt.report === "D.IN.6"
            ) {
                temp.push({
                    label : `${dt.report} - ${dt.reportTitle}`,
                    value : dt.reportTypeId
                })
            }
        })

        setProdukIntelOption(temp)
    }, [reportTypeOption])

    useEffect(() => {
        setStartDateErr(false)
        setEndDateErr(false)
        validateError("start")
    }, [startDate])

    useEffect(() => {
        setStartDateErr(false)
        setEndDateErr(false)
        validateError("end")
    }, [endDate])

    return (
        <>
            <Col
                className="pb-0 h-100 pb-lg-0 pb-2"
            >
                <Card className="h-100 p-0 m-0">
                    <CardBody
                        className       = "py-2 d-flex flex-column"
                    >
                        <div
                            className   = "flex-grow-1 d-flex flex-column"
                        >
                            <div className = "d-flex d-block mb-1">
                                <div 
                                    className = "bg-primary rounded-circle me-1"
                                    style = {{
                                        width  : '20px',
                                        height : '20px'
                                    }}
                                />
                                <strong>Filter Data</strong>
                            </div>
                            <div
                                className   = "overflow-auto flex-grow-1"
                                style       = {{
                                    maxHeight : '300px'
                                }}
                            >
                                {
                                    getUserData()?.work_unit_id === 1 &&
                                        <Select
                                            theme               = {selectThemeColors}
                                            className           = "react-select mb-1"
                                            classNamePrefix     = "select"
                                            placeholder         = "Pilih Satuan Kerja"
                                            options             = {workunitOption}
                                            onChange            = {(e) => {
                                                setSelectedWorkunit(e)
                                            }}
                                            isMulti
                                        />
                                }

                                {/* <Select
                                    theme               = {selectThemeColors}
                                    className           = "react-select mb-1"
                                    classNamePrefix     = "select"
                                    placeholder         = "Pilih Produk Intelijen"
                                    options             = {produkIntelOption}
                                    onChange            = {(e) => {
                                        setSelectedProduct(e)
                                    }}
                                    isMulti
                                /> */}
                                
                                <Flatpickr
                                    id              = "start_date"
                                    onChange	    = {(start_date) => {
                                        setStartDate(start_date[0])
                                    }}
                                    className		= {`form-control ${startDateErr ? "is-invalid" : " mb-1"}`}
                                    options			= {{
                                        dateFormat	: "d-m-Y",
                                        time_24hr	: true,
                                        enableTime	: false,
                                    }}
                                    placeholder		= "Tanggal Awal"
                                    value           = {startDate}
                                />
                                <div>
                                    {
                                        startDateErr ? <small className="text-danger mb-1">{startDateErr}</small> : ""
                                    }
                                </div>

                                <Flatpickr
                                    id              = "end_date"
                                    onChange	    = {(end_date) => {
                                        setEndDate(end_date[0])
                                    }}
                                    className		= {`form-control ${endDateErr ? "is-invalid" : " mb-1"}`}
                                    options			= {{
                                        dateFormat	: "d-m-Y",
                                        time_24hr	: true,
                                        enableTime	: false,
                                    }}
                                    placeholder		= "Tanggal Akhir"
                                    value           = {endDate}
                                />
                                 <div>
                                    {
                                        endDateErr ? <small className="text-danger mb-1">{endDateErr}</small> : ""
                                    }
                                </div>
                            </div>

                            <Button
                                className           = "col-12"
                                onClick             = {() => {
                                    handleFilter()
                                }}
                                disabled            = {endDateErr || startDateErr || loading}
                            >
                                {
                                    loading ? (
                                        <Spinner size="sm" className="me-1" />
                                    ) : (
                                        ""
                                    )
                                }

                                Filter
                            </Button>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </>
    )
}

export default WidgetFilter