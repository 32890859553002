import { param } from 'jquery'
import {get, delete_, post, put, patch}  from '../../index'

//get
const getList                   = (params) => get('/communication/room/?page_size=1000', params)
const getListGroup              = (params) => get('/communication/room/?page_size=100&type=group', params)
const getMessage                = (uuid) => get(`/communication/message/?room_uuid=${uuid}&page_size=100000`)


//post
const createList                = (formData) => post(`/communication/room/create/`, formData)
const sendMessage               = (formData) => post(`/communication/message/create`, formData)

//patch
const addMember                 = (uuid, formData) => patch(`/communication/room/${uuid}/?member=add`, formData)
const addAdmins                 = (uuid, formData) => patch(`/communication/room/${uuid}/?admin=add`, formData)
const deleteMember              = (uuid, formData) => patch(`/communication/room/${uuid}/?member=delete`, formData)
const invisibleMessage          = (id) => patch(`/communication/message/${id}/visible/`)
const archiveChat               = (uuid) => patch(`/communication/room/${uuid}/?archive=true`)
const unArchiveChat             = (uuid) => patch(`/communication/room/${uuid}/?archive=false`)

// put
const deleteRoom                = (uuid) => put(`/communication/room/${uuid}/`)

//delete
const deleteMessage             = (id) => delete_(`/communication/message/${id}/delete/`)

const chatAPI = {
    // Get
    getList,
    getMessage,
    getListGroup,
 
    // Post
    createList,
    sendMessage,
   
 
    // Patch
    addMember,
    addAdmins,
    archiveChat,
    deleteMember,
    unArchiveChat,
    invisibleMessage,

    // Delete
    deleteRoom,
    deleteMessage,
}

export default chatAPI