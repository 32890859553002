import { useContext, useEffect }               from "react"

//Context 
import { ReportContext }            from "@context/reportContext"

//Custom Componetn
import FormDelete                   from "@components/form-delete"
import ModalDetail                  from "./modalDetail"
import ModalPreview                 from "@components/modal-preview"
import ModalFormFilter              from "./modalFormFilter"
import ModalHideColumn              from "@components/modal-hide-column"
import ModalAcceptTask              from "@components/modal-accept-task"
import ModalApprovement             from "@components/modal-approvement"
import ModalPrintSumber 			from "@components/modal-print-sumber"
import ModalTrackingStatus          from "@components/modal-tracking/status"
import ModalFormFilterOrder         from "./modalFormFilterOrder"
import ModalEditHistory             from "../../../@core/components/modal-tracking/editHistory"
import ModalCompleteTask            from "./modalCompleteTask"
import ModalPreviewMap              from "../../../@core/components/modal-preview-map"
import ModalRequestPerpanjangan     from "../../../@core/components/modal-perpanjangan/request"
import ModalAcceptPerpanjangan      from "../../../@core/components/modal-perpanjangan/accept"
import Helper                       from "../../../helper"
import ModalReview                  from "./modalReview"
import ModalInstruction             from "./modalInstruction"
import ModalInstructionData         from "./modalInstructionData"
import ModalFormFilterNew           from "./modalFormFilterNew"
import ModalStatusAndInstruction    from "./modalStatusAndInstruction"
import ReadMore                     from "../../../@core/components/read-more"

const MasterModal = (props) => {

    const { monthIndo } = Helper

    const {
        header,
        handlePDF,
        dataDetail : DataDetail,
        reportData,
        filteredField,
        handleReportPdf,
        handleReportDocx,
        dataGrafik : DataGrafik,
        deleteTitle,
        lapbulDetail,
        headerReport
    }               = props

    const {
        //State
        selected, 
        setSelected,

        hideColumn,
        setHideColumn, 
  
        dataSelected,
        setDataSelected,
        lapbulSelected,
  
        showModalFilter,
        setShowModalFilter,

        showModalDetail,
        setShowModalDetail,
  
        showFormDelete,
        setShowFormDelete,
  
        showModalFilterColumn,
        setShowModalFilterColumn,

        showModalFilterOrder,
        setShowModalFilterOrder,
  
        showModalPreview,
        setShowModalPreview,

        showModalPreviewMap,
        setShowModalPreviewMap,

        showModalCompleteTask, 
        setShowModalCompleteTask,

        showModalStatus,
        setShowModalStatus,

        setLoadingDelete,

        showPrint,
		setPrint,

		typePrint,

        data,
        params,
        setParams,
  
        detailDinData,

        //function
        getData,
        onDelete,
        loadingDelete,
        createReportCountView,
        getReportCountView,
		setViewerCount,
		viewerCount,
  
    }                   = useContext(ReportContext)

    const handleDelete  = (reportId) => {

        if (data?.length - selected.length === 0) {
            if (params && params.page !== 1) {
                const temp = params
                temp.page = temp?.page - 1
                setParams(temp)
            }
        }

        const otherAction = async () => {
            getData(reportData?.reportTypeId, header)
            setShowFormDelete(false)
            setSelected([])
            setDataSelected([])

            const count = await getReportCountView(reportData?.reportTypeId)
            const temp = viewerCount
            viewerCount[reportData?.reportTypeId] = count?.data?.count
            setViewerCount({...temp})
        }

        onDelete(reportId, otherAction)
    }

    useEffect(() => {
        if (showModalDetail) {
            if (dataSelected) {
                createReportCountView(dataSelected?.report_id, parseInt(reportData?.reportTypeId), header)
            } else if (lapbulSelected) {
                createReportCountView(lapbulSelected?.report_id, parseInt(reportData?.reportTypeId), header)
            }
        }
    }, [showModalDetail])

    useEffect(() => {
        console.log("showModalDetail", showModalDetail, detailDinData)
        if (detailDinData?.data?.report_id) {
            createReportCountView(detailDinData?.data?.report_id, parseInt(reportData?.reportTypeId), header)
        }
    }, [showModalPreviewMap])

    useEffect(() => {
        if (showModalPreview) {
            if (dataSelected) {
                createReportCountView(dataSelected?.report_id, parseInt(reportData?.reportTypeId), header)
            } else if (lapbulSelected) {
                createReportCountView(lapbulSelected?.report_id, parseInt(reportData?.reportTypeId), header)
            }
        }
    }, [showModalPreview])

    return (
        <>
            <ModalAcceptTask
                getData    = {() => { getData(reportData?.reportTypeId, header) }}
                reportData = {reportData}
            />

            <ModalApprovement
                getData    = {() => { getData(reportData?.reportTypeId, header) }}
                reportData = {reportData}
            />

            <ModalRequestPerpanjangan
                getData    = {() => { getData(reportData?.reportTypeId, header) }}
                reportData = {reportData}
            />

            <ModalAcceptPerpanjangan
                getData    = {() => { getData(reportData?.reportTypeId, header) }}
                reportData = {reportData}
            />

            <ModalTrackingStatus
                reportData = {reportData}
            />

            <ModalEditHistory/>

            <ModalPrintSumber
                show			= {showPrint}
				type			= {typePrint}
				onClose			= {() => setPrint(!showPrint)}
				pdfReport 		= {handleReportPdf}
				reportType		= {reportData?.reportType}
				docxReport		= {handleReportDocx}
				reportNumber	= {reportData?.reportTypeId}
				reportTypeId 	= {reportData?.reportTypeId}
            />

            <ModalDetail
                show            = {showModalDetail}
                data            = {dataSelected}
                onClose         = {() => { 
                    setSelected([])
                    setShowModalDetail(!showModalDetail)
                    setDataSelected([])
                }}
                handlePDF       = {handlePDF}
                dataDetail      = {
                    <DataDetail 
                        data            = {dataSelected}
                        handlePDF       = {handlePDF}
                        reportData      = {reportData}
                        lapbulSelected  = {lapbulSelected}
                    />
                }
                lapbulDetail    = {lapbulDetail}
                reportData      = {reportData}
                dataGrafik      = {
                    DataGrafik ?
                    <DataGrafik
                        data = {dataSelected}
                    />
                    :
                    null
                }
            />

            <ModalCompleteTask
                show            = {showModalCompleteTask}
                data            = {dataSelected}
                onClose         = {() => { 
                    setSelected([])
                    setDataSelected([])
                    setShowModalCompleteTask(!showModalCompleteTask)
                }}
            />

            <ModalFormFilterNew
                show          = {showModalFilter}
                onClose       = {() => setShowModalFilter(!showModalFilter)}
                reportData    = {reportData}

            />

            <ModalFormFilterOrder
                show          = {showModalFilterOrder}
                onClose       = {() => setShowModalFilterOrder(!showModalFilterOrder)}
                filteredField  = {filteredField ? filteredField : ['judul', 'satuan_kerja', 'tanggal', 'nomor_surat']}
            />

            <ModalHideColumn
                show            = {showModalFilterColumn}
                data            = {header}
                onClose         = {() => setShowModalFilterColumn(!showModalFilterColumn)}
                hideColumn      = {hideColumn}
                setHideColumn   = {setHideColumn}
            />

            <ModalPreview
                show            = {showModalPreview}
                data            = {dataSelected}
                onClose         = {() => { setShowModalPreview(false) }}
                handlePDF       = {handlePDF}
                setDataSelected = {setDataSelected}
            />

            <ModalStatusAndInstruction
                show            = {showModalStatus}
                data            = {dataSelected}
                onClose         = {() => { setShowModalStatus(false) }}
                handlePDF       = {handlePDF}
                setDataSelected = {setDataSelected}
                reportData      = {reportData}
                headerReport    = {headerReport}
            />

            <ModalPreviewMap
                show            = {showModalPreviewMap}
                data            = {dataSelected}
                onClose         = {() => { setShowModalPreviewMap(false) }}
                handlePDF       = {handlePDF}
                setDataSelected = {setDataSelected}
                reportData      = {reportData}
            />

            <ModalReview
                reportData = {reportData}
            />

            <ModalInstruction

            />

            <ModalInstructionData

            />

            <FormDelete
                show            = {showFormDelete}
                title           = {`Hapus ${reportData?.reportTitle}`}
                onClose         = {() => setShowFormDelete(!showFormDelete)}
                loading         = {loadingDelete}
                onDelete        = {() => handleDelete(selected)}
            >
                <p> Apakah Anda Yakin Menghapus data berikut ? </p>
                {selected.length > 0 && selected?.map((val, i) => {
                    return (
                        <div key={i} className="d-flex justify-content-center" style={{gap:'3px'}}>
                            <p>{i + 1}.</p>
                            <p>
                                {
                                    deleteTitle === "dasar" ? 
                                        <ReadMore textLength={50} isHtml = {true}> 
                                            {val.dasar}
                                        </ReadMore> 
                                    :
                                        <div
                                            className	= "text-start"
                                            dangerouslySetInnerHTML={{ __html: 
                                                deleteTitle === "judul" ? 
                                                    val.judul : 
                                                deleteTitle === "sumber_nomor_surat" ? 
                                                    val?.sumber_surat?.nomor_surat :
                                                deleteTitle === "sumber_nomor_judul" ? 
                                                    val?.sumber_surat?.judul :
                                                deleteTitle === "kartutik_barang_cetakan_nomor_surat" ? 
                                                    val?.kartutik_barang_cetakan?.nomor :
                                                deleteTitle === "nama_pelapor" ? 
                                                    val.nama_pelapor :
                                                deleteTitle === "judul_berita" ?
                                                    val.judul_berita :
                                                deleteTitle === "nomor_berita" ?
                                                    val.nomor_berita :
                                                deleteTitle === "nomor" ?
                                                    val.nomor :
                                                deleteTitle === "jenis_media" ?
                                                    val.kartutik_media_komunikasi?.jenis_media :
                                                deleteTitle === "bidang_name" ?
                                                    val?.bidang?.name :
                                                deleteTitle === "sektor_name" ?
                                                    val?.sektor?.name :
                                                deleteTitle === "nama_klinik" ?
                                                    val?.nama_klinik :
                                                deleteTitle === "nomor_permohonan" ?
                                                    val?.nomor_permohonan :
                                                deleteTitle === "asal_negara" ?
                                                    val?.asal_negara :
                                                deleteTitle === "nama_lengkap" ?
                                                    val?.nama_lengkap :
                                                deleteTitle === "nama_aliran" ?
                                                    val?.nama_aliran :
                                                deleteTitle === "sumber" ?
                                                    val?.sumber :
                                                deleteTitle === "nama_kegiatan" ?
                                                    val?.nama_kegiatan :
                                                deleteTitle === "nama_pekerjaan" ?
                                                    val?.nama_pekerjaan :
                                                deleteTitle === "nama_aliran_kepercayaan" ?
                                                    val?.nama_aliran_kepercayaan :
                                                deleteTitle === "kartutik_organisasi_kemasyarakatan" ?
                                                    val?.kartutik_organisasi_kemasyarakatan?.nama_organisasi :
                                                deleteTitle === "jenis_masalah" ?
                                                    <div dangerouslySetInnerHTML={{ __html: val.jenis_masalah }}/> :
                                                deleteTitle === "posko_perwakilan" ?
                                                    <div dangerouslySetInnerHTML={{ __html: val.posko_perwakilan }}/> :
                                                deleteTitle === "peserta" ?
                                                    val?.peserta :
                                                deleteTitle === "kegiatan" ?
                                                    val?.kegiatan :
                                                deleteTitle === "asal_buronan" ?
                                                    val?.asal_buronan :
                                                deleteTitle === "tema_kegiatan" ?
                                                    val?.tema_kegiatan :
                                                deleteTitle === "perihal" ?
                                                    val?.perihal :
                                                deleteTitle === "tahun" ?
                                                    val?.tahun :
                                                deleteTitle === "judul_lapbul" ?
                                                    `Laporan Bulanan ${val?.bidang?.name} ${monthIndo(val?.bulan - 1)} ${val?.tahun}` :
                                                val.nomor_surat
                                            }}
                                        />
                                }
                            </p>
                        </div>
                    )
                })}
            </FormDelete>

        </>
    )
}

export default MasterModal