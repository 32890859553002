import { 
    Button,
    InputGroup, 
    Modal, 
    ModalBody, 
    ModalHeader 
}                                               from "reactstrap"

import { 
    useEffect, 
    useState 
}                                               from "react"

import IcCategory                               from "../../../../assets/images/icons/config_indicator_category.svg"
import IcPeformance                             from "../../../../assets/images/icons/config_indicator.svg"
import EvaluationIndicatorCreateCategory        from "./EvaluationIndicatorCreateCategory"
import EvaluationIndicatorCreatePerformance     from "./EvaluationIndicatorCreatePerformance"

const EvaluationIndicatorCreate = ({
    show,
    setShow,
    getDataPoint,
    getDataBadge,
    perfomanceType,
    size
}) => {

    const [category, setCategory]       = useState(false)
    const [performance, setPerformance] = useState(false)

    useEffect(() => {
        if (!show) {
            setCategory(false)
            setPerformance(false)
        }
    }, [show])

    return (
        <>
           {
            perfomanceType === 'badge' ?
                <EvaluationIndicatorCreatePerformance
                    show        = {show}
                    setShow     = {setShow}
                    getData     = {getDataBadge}
                    title       = "Tambah Pengaturan Penilaian"
                    size        = "lg"
                />
            :
                <EvaluationIndicatorCreateCategory
                    show        = {show}
                    setShow     = {setShow}
                    title       = "Tambah Jenis Penilaian"
                    value       = {null}
                    size        = "lg"
                    getData     = {getDataPoint}
                />
           }
            
        </>
    )
}

export default EvaluationIndicatorCreate