// import React from 'react'
import { ChevronRight, Folder, Search } from 'react-feather'
import { Button, FormGroup, Input, InputGroup, InputGroupText, ListGroup, ListGroupItem, Media, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { useContext, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { DataManagerContext } from '../../../../context/dataManagerContext'
import CustomTableEmptyData from '../../custom-table/empty'
import BreadcrumsMoveDrive from '../../breadcrumsMoveDrive'
import driveAPI from '../../../../service/pages/drive'
import BreadcrumsManager from '../../breadcrumsManager/BreadcrumsManager'

const ModalMoveFolder = ({data, show, setShow}) => {

   
    const {
        listFolder,
        setFolder,
        dataBreadcums,
        setDataBreadcums,
        setDir,
        getDrive,
        getMoveDrive,
        setFolderBreadcums,
        folderBreadcums
    } = useContext(DataManagerContext)

    const [url, setUrl]                     = useState([])
    const [nameFolder, setNameFolder]                     = useState([])
    const [destination, setDestination]     = useState([null])


    const onOpenFolder = (data) => {
        setFolderBreadcums([...folderBreadcums, { name: data.name, url:  `?dir=${data.code}`, code : data.code }])
        setDataBreadcums([...dataBreadcums, { name: data.name, url:  `?dir=${data.code}`, code : data.code }])
        setDir(data.code)
        setFolder(data)
        getMoveDrive(`?dir=${data.code}`)
    }

    const selectRow = (id, code, name) => {  
        if (destination.indexOf(id) !== -1) {
            setDestination(destination.filter((obj) => obj !== id))
            setUrl(url.filter((obj) => obj !== code))
            setNameFolder(nameFolder.filter((obj) => obj !== name))

        } else {
            if (destination.length > 0) {
                destination.splice(0, 1)
                url.splice(0, 1)
                setDestination([...destination, id])
                setUrl([...url, code])
                setNameFolder([name])
            } else {
                setDestination([...destination, id])
                setUrl([...url, code])
                setNameFolder([name])
            }
            
        }
    }

    const moveDrive = () => {

        const type = data.mime.data === 'file' ? 'files' : data.mime.data 

        const formData = type === 'files' ? {file_folder : destination[0]} : {parent_id : destination[0]}

        driveAPI.moveFolder(type, data.code, formData)
            .then(res => {
                if (!res.is_error) {
                    setDataBreadcums([...dataBreadcums, { name: nameFolder, url:  `?dir=${data.code}`, code : data }])
                    getDrive(`${url[0] ? `?dir=${url[0]}` : ''}`)
                    getMoveDrive()
                    setShow(false)
                    toast.success('File Moved Successfully!')
                } else {
                    toast.success('File Failed To Move!')
                }
            })

    }

    const listData = listFolder && listFolder.filter((element) => element.mime?.data === 'folder')


    useEffect(() => {
        getMoveDrive()
    }, [])

  return (
    <Modal
        isOpen={show}
        toggle={() => setShow(!show)}
        size='sm'
        centered
    >
        <ModalHeader toggle={() => setShow(!show)}>Pindah Folder</ModalHeader>
        <ModalBody>
            <BreadcrumsManager />
            <ListGroup tag={'div'}>
                 
                {
                    listData && listData.map((data, i) => (
                        <ListGroupItem
                            key             ={i} 
                            tag             ='a' 
                            href            ='#'
                            active          = {destination && destination.indexOf(data.id) !== -1 }
                            className       = 'cursor-pointer d-flex justify-content-between'
                            onClick         = {() => selectRow(data.id, data.code, data.name) }
                            onDoubleClick   = {() => onOpenFolder(data)} 
                        >
                            <Media className='d-flex'>
                                <Media left className='me-1' >
                                    <Folder />
                                </Media>
                                <Media body >
                                    {data.name}
                                </Media>
                                
                            </Media>
                            <span className='text-white'>
                                Pindahkan <ChevronRight size = {20}/>
                            </span>
                        </ListGroupItem>
                    ))
                }  
                {listData && listData.length === 0 && <CustomTableEmptyData />}

            </ListGroup>
       
        </ModalBody>
        <ModalFooter className='d-flex justify-content-between'>
        <Button 
            // onClick     = {shareDrive}
            // className   ='w-100'
            type        = 'button' 
            color       = 'primary '
            outline
            onClick     = {() => setShow(false)}
        >
            Batal
        </Button>
        <Button 
            onClick     = {moveDrive}
            // className   ='w-100'
            color       = 'primary '
            type        = 'button' 
        >
            Selesai
        </Button>
        </ModalFooter>
    </Modal>
  )
}

export default ModalMoveFolder
