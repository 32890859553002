// ** Icons Import
import { Map } from 'react-feather'

export default [
  {
    id          : 'gis',
    icon        : <Map size={20} />,
    title       : 'Data Peta',
    action      : 'read',
    navLink     : "/gis",
    resource    : 'data-peta'
  }
]
