import React                from 'react'
import { 
    Col, 
    Card, 
    Spinner, 
    CardBody, 
    CardHeader,
    CardImg,
    Row, 
}                           from 'reactstrap'

import { 
    ChevronLeft,
    ChevronRight, 
    ChevronsLeft, 
    ChevronsRight, 
    CornerUpRight, 
    Link, 
    Star, 
    Tag
}                           from 'react-feather'

import Fav              from "../../../assets/images/icons/svg/fav.svg"


import DriveDropDown        from '../../../@core/components/drive-dropdown'
import CustomTableEmptyData from '../../../@core/components/custom-table/empty'


const RenderGridView = ({
    active,
    layout,
    loading,
    listData,
    setParams,
    setSelected,
    setShowDrive,
    setShowImage,
    renderIcon,
    pagination,
    addFavorite,
    onOpenFolder,
    deleteToTags,
    restoreDrive,
    setShowShared,
    setShowAddTag,
    setShowDetail,
    setShowDelete,
    setShowFolder,
    deleteFavorite,
    setSelectDrive,
    setShowMoveFolder,
    setShowDeletePermanent,
    setBreadcums,
}) => {

    const addImageFallback = (event) => {
        event.currentTarget.src = 'https://i.pinimg.com/564x/01/7c/44/017c44c97a38c1c4999681e28c39271d.jpg'
      }

    return (
        <>
            <Row>
                {
                    Array.isArray(listData) &&
                        listData?.map((item, i) => {
                            return (
                                <Col 
                                    xl        = {3}  
                                    lg        = {4}
                                    xs        = {6}
                                    key       = {i}
                                    className = "p-1 cursor-pointer" 
                                >
                                    <Card className = "h-100 m-0">
                                        <CardHeader className = "d-flex justify-content-between pt-1 pb-0 mb-1">
                                            <div className='d-flex justify-content-between'>
                                                {
                                                    active !== 'trash' ? (
                                                        !item.is_share ? (
                                                            <div
                                                                onClick     = {() => {
                                                                    setSelectDrive(item)
                                                                    setShowShared(true) 
                                                                }} 
                                                            >
                                                                <CornerUpRight  size={20} />
                                                            </div> 
                                                        ) :
                                                            (
                                                                <div>
                                                                    <Link size={20} />
                                                                </div>
                                                            )
                                                    ) : <div></div>
                                                }
                                                {
                                                    item.is_tags ? (
                                                        <div className   = "ms-1">
                                                            <Tag  size={20} />
                                                        </div>
                                                    ) : <div></div>
                                                }
                                                {
                                                    item.mime?.kind === 'image' && item.is_favorite && (
                                                        <div className='ms-1'>
                                                            <Star size={20} />
                                                        </div>
                                                    )
                                                }
                                            </div>
                                            <DriveDropDown 
                                                item                    = {item}
                                                layout                  = {layout}
                                                active                  = {active}
                                                setBreadcums            = {setBreadcums}
                                                addFavorite             = {addFavorite}
                                                deleteToTags            = {deleteToTags}
                                                restoreDrive            = {restoreDrive}
                                                setSelected             = {setSelected}
                                                setShowAddTag           = {setShowAddTag}
                                                setShowDetail           = {setShowDetail}
                                                setShowDelete           = {setShowDelete}
                                                setShowFolder           = {setShowFolder}
                                                deleteFavorite          = {deleteFavorite}
                                                setSelectDrive          = {setSelectDrive}
                                                setShowMoveFolder       = {setShowMoveFolder}
                                                setShowDeletePermanent  = {setShowDeletePermanent}
                                            />
                                        </CardHeader>
                                        <CardBody 
                                            onClick         = {() => { 
                                                if (item.mime?.extension === 'pdf' || item.mime?.kind === 'video') {
                                                    setSelectDrive(item)
                                                    setShowDrive(true)
                                                } else if (item.mime?.kind === 'image') {
                                                    setSelectDrive(item)
                                                    setShowImage(true)
                                                } else if (item.file_size !== undefined && item.mime.data === 'file') {
                                                    window.open(`${window.location.origin}${item.url_file}`, '_blank')
                                                } 
                                            }}
                                            className       = "text-center"
                                            onDoubleClick   = {() => onOpenFolder(item)} 
                                        >
                                            <div className = "mb-1">
                                                {
                                                    item.mime?.kind === 'image' ? 
                                                        <div>
                                                            <CardImg
                                                                src     = {`${window.location.origin}${item.url_file_thumb}`}
                                                                style   = {{ height : "120px"}}
                                                                onError = {addImageFallback}
                                                            />
                                                            
                                                        </div>
                                                        
                                                    :
                                                        item.mime?.data ?
                                                            renderIcon(item.mime?.data === 'file' ? item.mime?.extension : item.mime?.data, item.is_favorite, 70)
                                                            :
                                                            renderIcon(item.data?.mime?.data === 'file' ? item.data?.mime?.extension : item.data?.mime?.data, item.data?.is_favorite, 70) 
                                                }
                                        
                                            </div>
                                            <div className="text-container">
                                                <strong className="text">
                                                    {
                                                        item.name ? item.name : item.data?.name
                                                    }
                                                </strong>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            )
                        })
                }
            </Row>

            <div 
                style       = {{ right : 0, bottom : 0 }}
                className   = 'position-absolute d-flex justify-content-end'
            >
                
                {
                    pagination.previous ? (
                        <>
                            <div 
                                onClick     = {() => setParams({ page : 1 })}
                                className   = 'cursor-pointer'
                            >
                                <ChevronsLeft  />
                            </div>
                            <div 
                                onClick     = {() => setParams({ page:pagination.page - 1 })}
                                className   = 'cursor-pointer'
                            >
                                <ChevronLeft  />
                            </div>
                        </>
                    ) : (
                        <>
                            <div className='text-muted'>
                                <ChevronsLeft  />
                            </div>
                            <div className=' text-muted'>
                                <ChevronLeft  />
                            </div>
                        </>
                    )
                }
                <div className='mx-4'>
                    <p>
                        {(pagination.page * pagination.page_size) - 9} - {' '} 
                        {pagination.count > 10 ? pagination.page_size * pagination.page : pagination.count} dari {' '}
                        {pagination.count}
                    </p>
                </div>
                {
                    pagination.next ? (
                        <>
                            <div 
                                onClick     = {() => setParams({ page:pagination.page + 1 })}
                                className   = 'cursor-pointer'
                            >
                                <ChevronRight />
                            </div>
                            <div 
                                onClick     = {() => setParams({ page:pagination.total_page })}
                                className   = 'cursor-pointer'
                            >
                                <ChevronsRight />
                            </div>
                        </>
                    ) : (
                        <>
                            <div className='text-muted'>
                                <ChevronRight />
                            </div>
                            <div className='text-muted'>
                                <ChevronsRight />
                            </div>
                        </>
                    ) 
                }
            </div>

            {listData === false && loading &&
                <div 
                    className='d-flex justify-content-center align-items-center h-100' 
                >
                    <h1>Loading...</h1>
                </div>
            
            }
            {listData && listData.length === 0 && <CustomTableEmptyData />}
        </>
    )
}

export default RenderGridView
