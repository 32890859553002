import { lazy } from 'react'
import { Navigate } from 'react-router-dom'

const LIN1 = lazy(() => import('../../views/reports/lin/lin-1'))
const LIN2 = lazy(() => import('../../views/reports/lin/lin-2'))
const LIN3 = lazy(() => import('../../views/reports/lin/lin-3'))
const LIN4 = lazy(() => import('../../views/reports/lin/lin-4'))
const LIN5 = lazy(() => import('../../views/reports/lin/lin-5'))
const LIN6 = lazy(() => import('../../views/reports/lin/lin-6'))
const LIN7 = lazy(() => import('../../views/reports/lin/lin-7'))
const LIN8 = lazy(() => import('../../views/reports/lin/lin-8'))
const LIN9 = lazy(() => import('../../views/reports/lin/lin-9'))
const LIN10 = lazy(() => import('../../views/reports/lin/lin-10'))
const LIN11 = lazy(() => import('../../views/reports/lin/lin-11'))
const LIN12 = lazy(() => import('../../views/reports/lin/lin-12'))
const LIN13 = lazy(() => import('../../views/reports/lin/lin-13'))
const LIN14 = lazy(() => import('../../views/reports/lin/lin-14'))
const LIN15 = lazy(() => import('../../views/reports/lin/lin-15'))
const LIN16 = lazy(() => import('../../views/reports/lin/lin-16'))
const LIN17 = lazy(() => import('../../views/reports/lin/lin-17'))
const LIN18 = lazy(() => import('../../views/reports/lin/lin-18'))
const LIN19 = lazy(() => import('../../views/reports/lin/lin-19'))
const LIN20 = lazy(() => import('../../views/reports/lin/lin-20'))
const LIN21 = lazy(() => import('../../views/reports/lin/lin-21'))
const LIN22 = lazy(() => import('../../views/reports/lin/lin-22'))
const LIN23 = lazy(() => import('../../views/reports/lin/lin-23'))
const LIN24 = lazy(() => import('../../views/reports/lin/lin-24'))
const LIN25 = lazy(() => import('../../views/reports/lin/lin-25'))
const LIN26 = lazy(() => import('../../views/reports/lin/lin-26'))
const LIN27 = lazy(() => import('../../views/reports/lin/lin-27'))
const LIN28 = lazy(() => import('../../views/reports/lin/lin-28'))
const LIN29 = lazy(() => import('../../views/reports/lin/lin-29'))
const LIN30 = lazy(() => import('../../views/reports/lin/lin-30'))
const LIN31 = lazy(() => import('../../views/reports/lin/lin-31'))
const LIN32 = lazy(() => import('../../views/reports/lin/lin-32'))

const LinRoutes = [
	{
		path: '/lin',
		exact: true,
		element: <Navigate to='/lin/1' />,
		meta: {
			action: 'read',
			resource: 'lin-laporan'

		}
	},
	{
		path: "/lin/1",
		element: < LIN1 />,
		meta: {
			action: "read",
			resource: "lin1-lapinhar"
		}
	},
	{
		path: "/lin/2",
		element: < LIN2 />,
		meta: {
			action: "read",
			resource: "lin2-lapinsus"
		}
	},
	{
		path: "/lin/3",
		element: < LIN3 />,
		meta: {
			action: "read",
			resource: "lin3-laporan-intelijen-khusus"
		}
	},
	{
		path: "/lin/4",
		element: < LIN4 />,
		meta: {
			action: "read",
			resource: "lin4-laphastug"
		}
	},
	{
		path: "/lin/5",
		element: < LIN5 />,
		meta: {
			action: "read",
			resource: "lin5-laopsin"
		}
	},
	{
		path: "/lin/6",
		element: < LIN6 />,
		meta: {
			action: "read",
			resource: "lin6-laporan-atensi"
		}
	},
	{
		path: "/lin/7",
		element: < LIN7 />,
		meta: {
			action: "read",
			resource: "in7-lahin"
		}
	},
	{
		path: "/lin/8",
		element: < LIN8 />,
		meta: {
			action: "read",
			resource: "in8-perkiraan-keadaan-intelijen"
		}
	},
	{
		path: "/lin/9",
		element: < LIN9 />,
		meta: {
			action: "read",
			resource: "in9-troop-info"
		}
	},
	{
		path: "/lin/10",
		element: < LIN10 />,
		meta: {
			action: "read",
			resource: "lin10-labul-sptug"
		}
	},
	{
		path: "/lin/11",
		element: < LIN11 />,
		meta: {
			action: "read",
			resource: "lin11-labul-rekap-tugas"
		}
	},
	{
		path: "/lin/12",
		element: < LIN12 />,
		meta: {
			action: "read",
			resource: "lin12-labul-pelaksanaan-ops"
		}
	},
	{
		path: "/lin/13",
		element: < LIN13 />,
		meta: {
			action: "read",
			resource: "lin13-labul-rekapitulasi-ops"
		}
	},
	{
		path: "/lin/14",
		element: < LIN14 />,
		meta: {
			action: "read",
			resource: "lin14-labul-pencegahan-dan-penangkalan"
		}
	},
	{
		path: "/lin/15",
		element: < LIN15 />,
		meta: {
			action: "read",
			resource: "lin15-labul-pengawasan-lalu-lintas-orang-asing"
		}
	},
	{
		path: "/lin/16",
		element: < LIN16 />,
		meta: {
			action: "read",
			resource: "lin16-labul-tindak-pidana-asing"
		}
	},
	{
		path: "/lin/17",
		element: < LIN17 />,
		meta: {
			action: "read",
			resource: "lin17-labul-pengaman-sumber-daya-org-kejaksaan-dan-pengamanan-penanganan-perkara"
		}
	},
	{
		path: "/lin/18",
		element: < LIN18 />,
		meta: {
			action: "read",
			resource: "lin18-labul-pengawasan-barang-cetakan"
		}
	},
	{
		path: "/lin/19",
		element: < LIN19 />,
		meta: {
			action: "read",
			resource: "lin19-labul-pengawasan-media-komunikasi"
		}
	},
	{
		path: "/lin/20",
		element: < LIN20 />,
		meta: {
			action: "read",
			resource: "lin20-labul-pengobatan-tradisional"
		}
	},
	{
		path: "/lin/21",
		element: < LIN21 />,
		meta: {
			action: "read",
			resource: "lin21-labul-pengawasan-aliran-kepercayaan-masyarakat"
		}
	},
	{
		path: "/lin/22",
		element: < LIN22 />,
		meta: {
			action: "read",
			resource: "lin22-labul-pengawasan-aliran-keagamaan"
		}
	},
	{
		path: "/lin/23",
		element: < LIN23 />,
		meta: {
			action: "read",
			resource: "lin23-labul-pengawasan-organisasi-kemasyarakatan"
		}
	},
	{
		path: "/lin/24",
		element: < LIN24 />,
		meta: {
			action: "read",
			resource: "lin24-labul-ketertiban-dan-ketentraman-umum"
		}
	},
	{
		path: "/lin/25",
		element: < LIN25 />,
		meta: {
			action: "read",
			resource: "lin25-labul-pembinaan-masyarakat-taat-hukum"
		}
	},
	{
		path: "/lin/26",
		element: < LIN26 />,
		meta: {
			action: "read",
			resource: "lin26-labul-pencegahan-konflik-sosial"
		}
	},
	{
		path: "/lin/27",
		element: < LIN27 />,
		meta: {
			action: "read",
			resource: "lin27-labul-posko-perwakilan-kejaksaan"
		}
	},
	{
		path: "/lin/28",
		element: < LIN28 />,
		meta: {
			action: "read",
			resource: "lin28-labul-keg-pps"
		}
	},
	{
		path: "/lin/29",
		element: < LIN29 />,
		meta: {
			action: "read",
			resource: "lin29-tangkap-buronan"
		}
	},
	{
		path: "/lin/laporan_kegiatan_kunjungan_lapangan",
		element: < LIN30 />,
		meta: {
			action: "read",
			resource: "lin30-laporan-kegiatan-kunjungan-lapangan"
		}
	},
	{
		path: "/lin/laporan_kegiatan_pps",
		element: < LIN31 />,
		meta: {
			action: "read",
			resource: "lin31-laporan-kegiatan-pps"
		}
	},
	{
		path: "/lin/ringkasan_aght",
		element: < LIN32 />,
		meta: {
			action: "read",
			resource: "lin32-ringkasan-aght"
		}
	}
]

export default LinRoutes