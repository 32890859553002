import { 
    Card, 
    CardBody,
    CardHeader, 
}               from "reactstrap"

import Avatar   from '@components/avatar'

const ProfileReward = () => {

    const reward = [
        {
            label   : "Membuat SP.OPSIN",
            date    : "01 Oktober 2023",
            note    : "5/7 Correct"
        },
        {
            label   : "Membuat Analisa Sasaran",
            date    : "01 Oktober 2023",
            note    : ""
        },
        {
            label   : "Membuat Analisa Sasaran",
            date    : "01 Oktober 2023",
            note    : ""
        },
        {
            label   : "Membuat Target Operasi",
            date    : "01 Oktober 2023",
            note    : ""
        },
        {
            label   : "Membuat Rencana Penyelidikan",
            date    : "01 Oktober 2023",
            note    : ""
        },
        {
            label   : "Menyelesaikan LAOPSIN",
            date    : "01 Oktober 2023",
            note    : ""
        },
        {
            label   : "Membuat LAPINHAR",
            date    : "01 Oktober 2023",
            note    : ""
        },
    ]

    const renderComps = (dt) => {
        return (
            <div
                className   = "d-flex mb-1"
            >
                <div>
                    <Avatar
                        className   = "me-1 bg-primary"
                        size        = "md"
                    />
                </div>
                <div>
                    <div
                        className   = "col-12 p-9"
                    >
                        {dt.label}
                    </div>
                    <div
                        className   = ""
                    >
                        {dt.date} . {dt.note}
                    </div>
                </div>
            </div>
        )
    }
    return (
        <>
            <Card
                className="col-12 h-100 bg-white rounded m-0 text-dark"
            >
                <CardHeader
                    className="text-primary text-center"
                >
                    <div className="col-12 text-center">
                        <strong>Daftar Reward</strong>
                    </div>
                </CardHeader>
                <CardBody>
                    {
                        reward?.map((dt) => {
                            return (
                                <>
                                    {renderComps(dt)}
                                </>
                            )
                        })
                    }
                </CardBody>
            </Card>
        </>
    )
}

export default ProfileReward