import { 
    useEffect,
    useContext,
    useState 
}                           from "react"

import { 
    CornerUpRight, 
    Link, 
    List, 
    Tag, 
}                               from "react-feather"

import {
    Button, 
    Col,
    Row,
}                               from "reactstrap"

import DataTablesGlobal         from "../../../@core/components/data-tables-global"
import ModalSorting             from "../../other/ModalSorting"
import _Folder                  from "../../../assets/images/icons/svg/folder.svg"
import _FolderFav               from "../../../assets/images/icons/svg/folder-fav.svg"
import _FolderShare             from "../../../assets/images/icons/svg/folder-share.svg"
import _FolderShareFav          from "../../../assets/images/icons/svg/folder-share-fav.svg"
import _MP3                     from "../../../assets/images/icons/svg/mp3.svg"
import _MP3Fav                  from "../../../assets/images/icons/svg/mp3-fav.svg"
import _MP4                     from "../../../assets/images/icons/svg/mp4.svg"
import _MP4Fav                  from "../../../assets/images/icons/svg/mp4-fav.svg"
import _JPG                     from "../../../assets/images/icons/svg/jpg.svg"
import _JPGFav                  from "../../../assets/images/icons/svg/jpg-fav.svg"
import _PNG                     from "../../../assets/images/icons/svg/png.svg"
import _PNGFav                  from "../../../assets/images/icons/svg/png-fav.svg"
import _PDF                     from "../../../assets/images/icons/svg/pdf.svg"
import _PDFFav                  from "../../../assets/images/icons/svg/pdf-fav.svg"
import _DOC                     from "../../../assets/images/icons/svg/doc.svg"
import _DOCFav                  from "../../../assets/images/icons/svg/doc-fav.svg"
import _XLS                     from "../../../assets/images/icons/svg/xls.svg"
import _XLSFav                  from "../../../assets/images/icons/svg/xls-fav.svg"
import _PPT                     from "../../../assets/images/icons/svg/ppt.svg"
import _PPTFav                  from "../../../assets/images/icons/svg/ppt-fav.svg"
import _ZIP                     from "../../../assets/images/icons/svg/zip.svg"
import _ZIPFav                  from "../../../assets/images/icons/svg/zip-fav.svg"
import _Unknown                 from "../../../assets/images/icons/svg/unknown.svg"
import _UnknownFav              from "../../../assets/images/icons/svg/unknown-fav.svg"
import { DataManagerContext }   from "../../../context/dataManagerContext"
import ModalAddFolder           from "../../other/ModalAddFolder"
import driveAPI                 from "../../../service/pages/drive"
import ModalDelete              from "../../other/ModalDelete"
import BreadcrumsManager        from "../../../@core/components/breadcrumsManager/BreadcrumsManager"
import toast                    from "react-hot-toast"
import DetailDrive              from "../../../@core/components/detail-drive"
import ModalShare               from "../../../@core/components/share"
import ModalMoveFolder          from "../../../@core/components/modal/move-folder"
import DriveDropDown            from "../../../@core/components/drive-dropdown"
import FormTag                  from "../../../@core/components/modal/form-tag"
import TagList                  from "../../../@core/components/modal/tag-list"
import RenderGridView           from "./RenderGridView"
import DataManagementTop        from "./DataManagementTop"
import Viewer                   from "react-viewer"
import moment                   from "moment"
import ViewDrive                from "./ViewDrive"

const DataManagementContent = ({setShowComponentSmall, toggleSidebar}) => {

    const {
        // State
        sort, 
        sort_by,
        active,
        folder,
        setFolder,
        layout,              
        setLayout,
        showFormTag, 
        detailData,          
        setDetailData,
        setShowFormTag,
        selected,            
        setSelected,
        setDir,
        loading,
        showDelete,          
        setShowDelete,
        selectDrive,         
        setSelectDrive,
        params,
        setParams,
        listData,
        pagination,
        activeChild,
        dataBreadcums,
        setDataBreadcums,
        showDeletePermanent, 
        setShowDeletePermanent,
        
        // Function
        addFile,
        getDrive,
        deleteDrive,
        deleteShare,
        deleteToTags,
        addFavorite,
        setBreadcums,
        restoreDrive,
        deleteTags,
        deleteFavorite,
        deletePermanentDrive,
    } = useContext(DataManagerContext)
    
    const [showDrive,           setShowDrive]           = useState(false)
    const [showFolder,          setShowFolder]          = useState(false)
    const [hideColumn,          setHideColumn]          = useState([])
    const [showDetail,          setShowDetail]          = useState(false)
    const [showImage,           setShowImage]           = useState(false)
    const [showShared,          setShowShared]          = useState(false)
    const [showAddTag,          setShowAddTag]          = useState(false)
    const [showSorting,         setShowSroting]         = useState(false)
    const [showMoveFolder,      setShowMoveFolder]      = useState(false)

    const renderIcon = (type, favourite, size) => {
        let icon

        if (type === 'folder') {
            if (favourite) {
                icon = _FolderFav
            } else {
                icon = _Folder
            }
        } else if (type === "folder-share") {
            icon = _FolderShare
        } else if (type === "folder-share-fav") {
            icon = _FolderShareFav
        } else if (type === "mp3") {
            icon = _MP3
        } else if (type === "mp3-fav") {
            icon = _MP3Fav
        } else if (type === "mp4") {
            if (favourite) {
                icon = _MP4Fav
            } else {
                icon = _MP4
            }
        } else if (type === "jpg" || type === "jpeg") {
            if (favourite) {
                icon = _JPGFav
            } else {
                icon = _JPG
            }
        } else if (type === "png") {
            if (favourite) {
                icon = _PNGFav
            } else {
                icon = _PNG
            }
        } else if (type === "pdf") {
            if (favourite) {
                icon = _PDFFav
            } else {
                icon = _PDF
            }
        } else if (type === "docx") {
            if (favourite) {
                icon = _DOCFav
            } else {
                icon = _DOC
            }
            icon = _DOC
        } else if (type === "xlsx") {
            if (favourite) {
                icon = _XLSFav
            } else {
                icon = _XLS
            }
        } else if (type === "ppt") {
            if (favourite) {
                icon = _PPTFav
            } else {
                icon = _PPT
            }
        } else if (type === "zip") {
            icon = _ZIP
        } else if (type === "zip-fav") {
            icon = _ZIPFav
        }  else if (type === "unknown-fav") {
            icon = _UnknownFav
        } else  {
            icon = _Unknown
        }

        return (
            <>
                <img
                    src     = {icon}
                    height  = {size}
                />
            </>
        )
    }

    const onOpenFolder = (data) => {
        setDataBreadcums([...dataBreadcums, { name: data.name ? data.name : data.data.name, url:  `?dir=${data.code ? data.code : data.code_file}`, code : data }])
        setDir(data.code ? data.code : data)
        setFolder(data)
        getDrive(`?dir=${data.code ? data.code : data.code_file}`)
    }

    const onOpenFile = (row) => {
        if (row.mime?.extension === 'pdf' || row.mime?.kind === 'video') {
            setSelectDrive(row)
            setShowDrive(true)
        } else if (row.mime?.kind === 'image') {
            setSelectDrive(row)
            setShowImage(true)
        } else if (row.file_size !== undefined && row.mime.data === 'file') {
             window.open(`${window.location.origin}${row.url_file}`, '_blank')
        }
    }

    const addFolder = (data) => {

        const formData = {
            name    : data[0].webkitRelativePath.split("/")[0],
            parent  : folder && folder.id ? active === 'share' ? folder.data.id : folder.id : null,
            is_root : false
        }

        driveAPI.createFolder(formData)
            .then((res) => {
                if (!res.is_error) {
                    if (data.length > 1) {
                        for (let i = 0; i < data.length; i++) {
                            const formData = new FormData()
                            formData.append('file', data[i])
                            formData.append('file_folder', res.data.id)
                            formData.append('is_encrypt', true)   
                            driveAPI.createFile(formData)
                                .then((res) => {
                                    if (!res.is_error) {
                                        getDrive(folder ? `?dir=${folder.code ? folder.code : folder.data.code}` : null)
                                    }
                                }).catch((err) => {
                                    toast.error(err.result.message)
                                })      
                        }
                    }
                       
                    
                } else {
                    toast.error(res.message)
                }
            }).catch((err) => {
                toast.error(err.result.message)
            })
    }
    
    const header        = [      
		{
			omit            : hideColumn[0] === false,
			name            : 'Nama',
			center          : false,
			wrap            : true,
            compact         : true,
			cell            : row => (
                <div
                    key             = {row.code}
                    className       = "cursor-pointer d-flex col-12"
                    onClick   = {() => { 
                        onOpenFile(row)
                    }}
                    onDoubleClick   = {() => { 
                        onOpenFolder(row) 
                    }}
                >
                    <div className       = "me-1">
                        {
                             row.mime?.data ?
                                renderIcon(row.mime?.data === 'file' ? row.mime?.extension : row.mime?.data, row.is_favorite, 20)
                             :
                                renderIcon(row.data?.mime?.data === 'file' ? row.data?.mime?.extension : row.data?.mime?.data, row.data?.is_favorite, 20)

                        }
                    </div>
                    <div>
                        {
                           
                            row.name ? row.name : row.data?.name
                        }
                    </div>
                </div>
            ),
			sortable		: true
		},
        {
			omit            : hideColumn[1] === false,
			name            : '',
			center          : true,
			wrap            : true,
            compact         : true,
			cell            : row => (
                <>
                    <div
                        className="col-4 d-flex justify-content-around align-items-center"
                    >
                        {
                            row.is_tags ? 
                                <Button
                                    size            = "sm"
                                    color           = "light"
                                    className       = "btn-icon "
                                
                                >
                                    <Tag
                                        size        = {15}
                                    />
                                </Button>
                            : null
                        }
                        {
                            active !== 'trash' ?
                                row.is_share ?
                                    <>
                                        <Button
                                            size            = "sm"
                                            color           = "white"
                                            className       = "btn-icon"
                                            onClick         = {() => {
                                                
                                            }}
                                        >
                                            <Link
                                                size        = {17}
                                                className   = "me-1"
                                            />
                                        </Button>
                                    </> : 
                                    <>
                                        <Button
                                            size            = "sm"
                                            color           = "light"
                                            className       = "btn-icon"
                                            onClick         = {() => {
                                                setShowShared(true)
                                                setSelectDrive(row)
                                            }}
                                        >
                                            <CornerUpRight
                                                size        = {17}
                                            />
                                        </Button>
                                    </>
                                : null
                        }
                        <DriveDropDown 
                            item                    = {row}
                            active                  = {active}
                            layout                  = {layout}
                            addFavorite             = {addFavorite}
                            deleteToTags            = {deleteToTags}
                            restoreDrive            = {restoreDrive}
                            setShowAddTag           = {setShowAddTag}
                            setShowDetail           = {setShowDetail}
                            setBreadcums            = {setBreadcums}
                            setShowFolder           = {setShowFolder}
                            setDetailData           = {setDetailData}
                            setSelected             = {setSelected}
                            setShowDelete           = {setShowDelete}
                            setSelectDrive          = {setSelectDrive}
                            deleteFavorite          = {deleteFavorite}
                            setShowMoveFolder       = {setShowMoveFolder}
                            setShowDeletePermanent  = {setShowDeletePermanent}
                        />
                    </div>
                   
                </>
            ),
			sortable		: true
		},
        {
			omit            : hideColumn[1] === false,
			name            : 'Ukuran',
			center          : false,
			wrap            : true,
            compact         : true,
			selector        : row => row.file_size,
			sortable		: true
		}, 
        {
			omit            : hideColumn[5] === false,
			name            : 'Terakhir Diubah',
			center          : false,
			wrap            : true,
            compact         : true,
			cell            : row => (
                <>
                    {moment(row.created_at).format('DD-MM-YYYY')}
                </>
            ),
			sortable		: true
		}
	] 

    const sortBy = [
        {
            name : "Nama",
            value : "name"
        }, {
            name : "Ukuran",
            value : "file_size"
        }, {
            name : "Tanggal Diubah",
            value : "created_at"
        }
    ]

    const renderTableView = () => {

        return (
            <>
                <DataTablesGlobal
                    header                  = {header}
                    data                    = {listData}
                    getData                 = {() => { }}
                    loading                 = {loading}
                    totalData               = {pagination.count}
                    limit                   = {pagination.size}
                    params                  = {params}
                    setParams               = {setParams}
                    selected                = {selected}
                    setSelected             = {setSelected}
                    setIsDeleteModalVisible = {setShowDelete}
                    fixedHeaderScrollHeight = {'700px'}
                    visibility  	   		= {{
                        delete			: true,
                    }}
                />
            </>
        )
    }

    useEffect(() => {
        if (active === 'trash' && showDelete) {
            setShowDelete(false)
            setShowDeletePermanent(true)
        } 
    }, [active, showDelete])

    useEffect(() => {
		setHideColumn(() => header.map(() => true))
	}, [])

    useEffect(() => {
        setBreadcums({ type: "create", name: "Semua File", url: "" })
    }, [])

    useEffect(() => {
        if (active === 'share') {
            getDrive(`/${active === 'all' ? '' : active === 'share' ? `share?${activeChild}=true` : active}`)
        } else {
            const param = {
                dir         : folder.code,
                page        : params.page,
                sort        : sort,
                sort_by     : sort_by,
                page_size   : params.page_size,
            }
            getDrive(`/${active === 'all' ? '' : active === 'share' ? `share?${activeChild}=true` : active}`, param)
    }
    }, [params, sort, sort_by])

    useEffect(() => {
       getDrive()
    }, [])


    return (
        <>
            <div>
                <DataManagementTop 
                    addFile         = {addFile}
                    layout          = {layout}
                    getDrive        = {getDrive}
                    setLayout       = {setLayout}
                    addFolder       = {addFolder}
                    toggleSidebar   = {toggleSidebar}
                    setShowFolder   = {setShowFolder}
                    setSelectDrive  = {setSelectDrive}
                    setShowSroting  = {setShowSroting}
                />
                <div className={` ${layout === "grid" ? 'mt-1' : 'mt-3 position-absolute '}`}>
                      <BreadcrumsManager />
                </div>
                <div>
                    <Row>
                         {
                            layout === "grid" ? (
                                <Col xs={12} className = 'overflow-auto' style = {{ height : '800px' }}>
                                    <RenderGridView 
                                        active                  = {active}
                                        layout                  = {layout}
                                        loading                 = {loading}
                                        listData                = {listData} 
                                        setParams               = {setParams}
                                        renderIcon              = {renderIcon}
                                        setBreadcums            = {setBreadcums}
                                        pagination              = {pagination}
                                        setSelected             = {setSelected}
                                        setDetailData           = {setDetailData}
                                        setShowDrive            = {setShowDrive}
                                        addFavorite             = {addFavorite}
                                        setShowImage            = {setShowImage}
                                        deleteToTags            = {deleteToTags}
                                        onOpenFolder            = {onOpenFolder}
                                        restoreDrive            = {restoreDrive}
                                        setShowShared           = {setShowShared}
                                        setShowAddTag           = {setShowAddTag}
                                        setShowDetail           = {setShowDetail}
                                        setShowDelete           = {setShowDelete}
                                        setShowFolder           = {setShowFolder}
                                        deleteFavorite          = {deleteFavorite}
                                        setSelectDrive          = {setSelectDrive}
                                        setShowMoveFolder       = {setShowMoveFolder}
                                        setShowDeletePermanent  = {setShowDeletePermanent}
                                        setShowComponentSmall   = {setShowComponentSmall}
                                    />
                                </Col>
                            ) : (
                                <>
                                    <Col xs={showDetail ? 7 : 12} >
                                        {renderTableView()}   
                                    </Col>
                                    <Col xs={5}>
                                        {
                                            showDetail && 
                                                <DetailDrive
                                                    data = {detailData}
                                                    setShow={setShowDetail}

                                                />
                                        }
                                    </Col>
                                </>
                            )
                        }
                    </Row>
                </div>
            </div>

            <Viewer
                visible = {showImage}
                onClose = {() => { setShowImage(false) } }
                images  = {[{src: `${window.location.origin}${selectDrive && selectDrive.url_file}`, alt: 'foto'}]}
            />

            <ModalSorting
                data        	= {sortBy}
                show        	= {showSorting}
                setShow     	= {setShowSroting}
                size        	= "sm"
                title       	= "Select"
            />

            <ModalAddFolder
                data            = {selectDrive}
                show            = {showFolder}
                setShow     	= {setShowFolder}
            />

            <ModalMoveFolder
                data            = {selectDrive}
                show            = {showMoveFolder}
                setShow     	= {setShowMoveFolder}
            />

            <ModalDelete 
                data            = {selected}
                title           = {active === 'share' ? 'Hapus Share' : active === 'tags' ? 'Hapus Tags' : 'Hapus'}
                show            = {showDelete}
                setShow         = {setShowDelete}
                handleDelete    = {active === 'share' ? deleteShare : active === 'tags' ? deleteTags : deleteDrive}
            />

            <ModalDelete
                data            = {selected}
                title           = {'Hapus Permanent!'}
                show            = {showDeletePermanent}
                setShow         = {setShowDeletePermanent}
                handleDelete    = {deletePermanentDrive}
            />

            <ModalShare
                data            = {selectDrive}
                show            = {showShared}
                setShow         = {setShowShared}
            />

            <FormTag 
                 show           = {showFormTag}
                 setShow        = {setShowFormTag}
            />

            <TagList 
                data            = {selectDrive}
                show            = {showAddTag}
                setShow         = {setShowAddTag}
            />

            <ViewDrive
                data            = {selectDrive}
                show            = {showDrive}
                setShow         = {setShowDrive}   
             />
            
        </>
    )
}

export default DataManagementContent