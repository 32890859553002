import { useState } from "react"
import { Check, X }     from "react-feather"
import toast from "react-hot-toast"
import { 
    Button,
    Input, 
    Label, 
    Modal, 
    ModalBody, 
    ModalHeader 
}                       from "reactstrap"

const ModalSortingRecapitulation = ({
    show,
    setShow,
    size,
    title,
    data,
    getData,
    sort,
    setSort,
    sort_by,
    setSort_by,
}) => {

    const CustomLabel = ({ htmlFor }) => (
        <Label className="form-check-label" htmlFor={htmlFor}>
            <span className="switch-icon-left">
                <Check size={14} />
            </span>
            <span className="switch-icon-right">
                <X size={14} />
            </span>
        </Label>
    )

    const handleSubmit = () => {
        if (sort === "-") {
           toast.error("Pilih Urutan Terlebih Dahulu")
        } else if (sort_by === "-") {
            toast.error("Pilih Field Yang Akan diurutan Terlebih Dahulu")
        } else {
            const params = {
                all     : true,
                order   : `${sort === "desc" ? "-" : ""}${sort_by}`,
            }
    
            getData(params)
            setShow(false)
        }
    }
   
    return (
        <>
            <Modal
                isOpen  = {show} 
                toggle  = {() => { setShow(!show) }} 
                size    = {size || "sm"}
            >
                <ModalHeader toggle={() => setShow(!show)} > 
                    {title}
                </ModalHeader>
                <ModalBody>
                    <div>
                        <div className="d-flex py-1 mb-1 border-bottom">
                            <div className="d-flex align-items-center">
                                <div className="form-switch form-check-primary ms-1">
                                    <Input
                                        checked            = {sort === "asc"}
                                        onChange           = {() => {
                                            setSort("asc")
                                        }}
                                        type               = "switch"
                                        id                 = {`icon-primary-asc`}
                                    />
                                    <CustomLabel htmlFor={`icon-primary-asc`} />
                                </div>
                                <small>Naik</small>
                            </div>

                            <div className="d-flex align-items-center">
                                <div className="form-switch form-check-primary ms-1">
                                    <Input
                                        checked            = {sort === "desc"}
                                        onChange           = {() => {
                                            setSort("desc")
                                        }}
                                        type               = "switch"
                                        id                 = {`icon-primary-desc`}
                                    />
                                    <CustomLabel htmlFor={`icon-primary-desc`} />
                                </div>
                                <small>Menurun</small>
                            </div>
                        </div>
                    </div>
                    {
                        data !== null && data?.length > 0 &&
                            data?.map((dt, idx) => {
                                return (
                                    <>
                                        {
                                            dt.name !== "" && dt.name !== "Aksi" &&
                                            <div className="d-flex mb-1 align-items-center" key={idx}>
                                                <div className="form-switch form-check-primary ms-1 d-flex ">
                                                    <Input
                                                        checked            = {sort_by === dt.value}
                                                        onChange           = {() => {
                                                            setSort_by(dt.value)
                                                        }}
                                                        type               = "switch"
                                                        id                 = {`icon-primary${idx}`}
                                                    />
                                                    <CustomLabel htmlFor={`icon-primary${idx}`} />
                                                </div>
                                                <small>{dt.name}</small>
                                            </div>
                                        }
                                    </>
                                )
                            })
                    }

                    <Button
                        onClick = {() => {
                            handleSubmit()
                        }}
                    >
                        Urutkan
                    </Button>
                </ModalBody>
            </Modal>
        </>
    )
}

export default ModalSortingRecapitulation