import baseURL, {get, delete_}  from '../../index'

//get
const getSector     = (params) => get(`/management/sector/`, params)

//delete
const deleteSector  = (id) => delete_(`/management/sector/delete/${id}`)

const sectorAPI = {
    getSector,

    deleteSector
}

export default sectorAPI