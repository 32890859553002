import { useState, useContext, useEffect }     from "react"
import Select from "react-select"
import { 
    Button, 
    Input, 
    Label, 
    ListGroup, 
    ListGroupItem, 
    Modal, 
    ModalBody, 
    ModalFooter, 
    ModalHeader 
}                       from "reactstrap"

const ModalFilter = ({
    show,
    setShow,
    size,
    title,
    data,
    selectFilter, 
    setSelectFilter,
    getData
}) => {

    const [selectOrder, setSelectOrder] = useState(null)

    const roleOption = [
        // { value: '0', label: 'Satuan Kerja' },
        { value: "status", label: 'Status' },
    ]

    const statusOption =  [
        { value: 1, label: 'Lokal' },
        { value: 2, label: 'Nasional' },
    ]

    const onSubmit = () => {
        const params = {
            status : selectFilter
        }

        getData(params)
        setShow(false)
    }
   
    return (
        <>
            <Modal
                isOpen  = {show} 
                toggle  = {() => { setShow(!show) }} 
                size    = {size || "sm"}
            >
                <ModalHeader toggle={() => setShow(!show)} > 
                    {title}
                </ModalHeader>
                <ModalBody>
                    {/* <Select
                        key={'select-role'}
                        options={roleOption}
                        className="basic-single"
                        isClearable
                        classNamePrefix="select"
                        placeholder="Pilih Filter"
                        onChange={(e) => setSelectOrder(e ? e.value : 0)}
                    /> */}
                        <Select
                        key={'select-status'}
                        options={statusOption}
                        className="basic-single mt-1"
                        isClearable
                        classNamePrefix="select"
                        placeholder={"Pilih Status"}
                        onChange={(e) => setSelectFilter(e ? e.value : 0)}
                    />

                </ModalBody>
                <ModalFooter className="d-flex justify-content-between ">
                    <Button  
                        outline 
                        onClick={() => {
                            setShow(false)
                        }}
                    > 
                        Batal
                    </Button>
                    <Button color="primary" onClick={onSubmit}> Submit</Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default ModalFilter