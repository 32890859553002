import React from 'react'
import { Button } from 'reactstrap'

const ReportCategory = ({
    reportData,
    setReportdata
}) => {
  return (
    <div>
        <Button.Ripple 
            color       = 'primary' 
            outline
            onClick     = {() => { 
                setReportdata({
                    report          : "IN.1",
                    reportType      : "IN",
                    reportTitle     : "Surat Perintah Tugas",
                    reportTypeId    : "1"
                })
            
            }
        }
            className   = {`round me-1 ${reportData?.reportTypeId === '1' && 'active'}`}
        >
            IN.1 - SP. TUG
        </Button.Ripple>
        <Button.Ripple 
            color       = 'primary' 
            outline
            onClick     = {() => {
                setReportdata({
                    report          : "IN.2",
                    reportType      : "IN",
                    reportTitle     : "Surat Perintah Pengamanan Pembangunan Strategis",
                    reportTypeId    : "2"
                })
               
            }}
            className   = {`round me-1 ${reportData?.reportTypeId === '2' && 'active'}`}
        >
            IN.2 - SP. PPS
        </Button.Ripple>
        <Button.Ripple 
            color       ='primary' 
            outline
            onClick     = {() => {
                setReportdata({
                    report          : "IN.3",
                    reportType      : "IN",
                    reportTitle     : "Surat Perintah Operasi Intelijen",
                    reportTypeId    : "3"
                })
                }}
            className   = {`round me-1 ${reportData?.reportTypeId === '3' && 'active'}`}
        >
            IN3. - SP. OPS
        </Button.Ripple>
        <Button.Ripple 
        color       = 'primary' 
        outline
        onClick     = {() => {
            setReportdata({
                report			: "IN.21",
                reportType      : "IN",
                reportTitle     : "Surat Perintah Kegiatan Penerangan Hukum",
                reportTypeId    : "21"
            })
           
            }}
        className   = {`round me-1 ${reportData?.reportTypeId === '21' && 'active'}`}
    >
        IN.21 -SP. PENKUM
    </Button.Ripple>
    </div>
  )
}

export default ReportCategory