// ** React Imports
import { NavLink } from 'react-router-dom'

// ** Third Party Components
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'

// ** Reactstrap Imports
import { Badge } from 'reactstrap'

// ** Helper
import Helper from '../../../../../helper'

const VerticalNavMenuLink = ({ item, activeItem }) => {
  // ** Conditional Link Tag, if item has newTab or externalLink props use <a> tag else use NavLink
  const LinkTag = item.externalLink ? 'a' : NavLink

  // ** Hooks
  const { t } = useTranslation()

  return (
    <li
      className={classnames({
        'nav-item': !item.children,
        disabled: item.disabled,
        active: item.navLink === activeItem
      })}
      id={item.resource}
    >
      <LinkTag
        className='d-flex align-items-center'
        target={item.newTab ? '_blank' : undefined}
        /*eslint-disable */
        {...(item.externalLink === true
          ? {
              href: item.navLink || '/'
            }
          : {
              to: item.navLink || '/',
              className: ({ isActive }) => {
                if (isActive && !item.disabled) {
                  return 'd-flex align-items-center justify-content-between active'
                } else {
                  return 'd-flex align-items-center justify-content-between'
                }
              }
            })}
        onClick={e => {
          if (item.navLink.length === 0 || item.navLink === '#' || item.disabled === true) {
            e.preventDefault()
          }
        }}
      >
        <div>
          {item.icon}
          <span className='menu-item text-truncat' style={{marginRight:'10px'}}>{Helper.shortenWord(item.title, !isNaN(parseInt(item.badgeText)) ? 17 - `${item.badgeText}`?.length : 15)}</span>
        </div>

        {console.log("item.resource", item)}

        {item.badge && item.badgeText ? (
          <Badge className='mr-1' color={item.badge} pill>
            <small>{item.badgeText}</small>
          </Badge>
        ) : null}
      </LinkTag>
    </li>
  )
}

export default VerticalNavMenuLink
