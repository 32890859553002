import { lazy } 		from 'react'
import { Navigate } 	from 'react-router-dom'
import Recapitulation   from '../../views/recapitulation'

const Dashboard = lazy(() => import('../../views/dashboard'))

const RecapitulationRoutes = [
	{
		path	: '/recapitulation',
		element	: <Recapitulation />,
		meta	: {
			action: 'read',
			resource: 'rekap-data'
		}
	}
]

export default RecapitulationRoutes
