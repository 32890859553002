import { 
    useState,
    createContext,
    useEffect
}                       from "react"
import FileManagementAPI from "../service/pages/file-management"

//Context
const ManagementFileContext  = createContext(null)

import toast                                from "react-hot-toast"
import reportAPI from "../service/report"

//API
// import ManagementFileAPI      from "../service/pages/ManagementFile"

const ManagementFileProvider = ({ children }) => {

    //State
    const [params, setParams]                                   = useState({})
    const [listData, setListData]                               = useState([])
    const [listDataCategory, setListDataCategory]               = useState([])
    const [isLoading, setIsLoading]                             = useState(false)
    const [isLoadingCategory, setIsLoadingCategory]             = useState(false)
    const [pagination, setPagination]                           = useState(false)
    const [paginationCategory, setPaginationCategory]           = useState(false)

    const [categoryOptions, setCategoryOptions] = useState([])
    const [reportOptions, setReportOptions]     = useState([])

    
    // const [ManagementFileOption, setManagementFileOption]       = useState([])
    
    const getData = (params) => {
        setIsLoading(true)
        FileManagementAPI.getList(params)
            .then((res) => {
                setIsLoading(false)

                setListData(res?.data?.results)
                const _pagination = {
                    count       : res?.data?.count,
                    page        : res?.data?.page,
                    page_size   : res?.data?.page_size,
                    next        : res?.data?.next,
                    previous    : res?.data?.previous
                }
                setPagination(_pagination)

            })
    }

    const getDataCategory = (params) => {
        setIsLoadingCategory(true)
        FileManagementAPI.getCategory(params)
            .then((res) => {
                setIsLoadingCategory(false)

                setListDataCategory(res?.results)
                const _pagination = {
                    count       : res?.count,
                    page        : res?.page,
                    page_size   : res?.page_size,
                    next        : res?.next,
                    previous    : res?.previous
                }
                setPaginationCategory(_pagination)

            })
    }

    const getCategoryOptions = () => {
        const params = {
			page_size : 10000
		}
        reportAPI.getReportCategory(params)
            .then((res) => {
                
                const newArray = res?.results.map(item => {
                    return {
                      value: item?.id,
                      label : item?.name
                    }
                  })

                  setCategoryOptions(newArray)
            })
            .catch((err) => {
                console.log(err)

            })
    }

    const getReportOptions = () => {

        const params = {
			page_size : 100
		}

        reportAPI.getReportTypeAll(params)
            .then((res) => {
                const newArray = res?.results.map(item => {
                    return {
                      value: item?.id,
                      label : `${item?.name} ${item?.description}`
                    }
                  })

                  setReportOptions(newArray)
            })
            .catch((err) => {
                console.log(err)

            })
    }

    return <ManagementFileContext.Provider

        value={{
            listData,
            setListData,

            listDataCategory, 
            setListDataCategory,

            reportOptions,

            categoryOptions,

            isLoading,
            isLoadingCategory,

            pagination,
            setPagination,
            
            paginationCategory, 
            setPaginationCategory,

            getData,
            getDataCategory,
            getReportOptions,
            getCategoryOptions
        }}
    > 
        {children}
    </ManagementFileContext.Provider>
}

export { ManagementFileContext, ManagementFileProvider }