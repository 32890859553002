import { 
    Col, 
    Row 
}                                   from "reactstrap"
import OrganizationStructureCard    from "./OrganizationStructureCard"

const OrganizationStructureList = ({
    setActive,
    setActiveId,
    activeId
}) => {

    const workunitLevel = [
        {
            name : "KEJAKSAAN AGUNG R.I",
            id   : 1
        }, {
            name : "KEJAKSAAN TINGGI",
            id   : 2
        }, {
            name : "KEJAKSAAN NEGERI",
            id   : 3
        }, {
            name : "CABANG KEJAKSAAN NEGERI",
            id   : 4
        }
    ]

    return (
        <>
            <Row>
                {
                    workunitLevel?.map((dt) => {
                        return (
                            <Col
                                className = "cursor-pointer"
                                onClick   = {() => {
                                    setActive("detail")
                                    setActiveId(dt.id)
                                }}
                            >
                                <OrganizationStructureCard
                                    id      = {dt.id}
                                    type    = "list"
                                />
                            </Col>
                        )
                    })
                }
            </Row>
        </>
    )
}

export default OrganizationStructureList