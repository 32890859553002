import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

const ViewDrive = ({data, show, setShow}) => {
  return (
    <Modal
        isOpen = {show}
        size='lg'
    >
        <ModalHeader toggle={() => setShow(false)}>
            View {data && data.name}
        </ModalHeader>
        <ModalBody style={{ height : '900px' }}>
            <iframe title="Drive Viewer" src={`${window.location.origin}${data && data.url_file}`} width="100%" height="100%"></iframe>
        </ModalBody>
    </Modal>
  )
}

export default ViewDrive
