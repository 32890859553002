// import React from 'react'
import { ChevronRight, Folder, Search } from 'react-feather'
import { Button, FormGroup, Input, InputGroup, InputGroupText, Label, ListGroup, ListGroupItem, Media, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { useContext, useEffect, useState } from 'react'
import toast from 'react-hot-toast'

import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import HelpDeskAPI from '../../../../service/pages/helpdesk'

// import driveAPI from '../../../../service/pages/drive'
// import { DataManagerContext } from '../../../../context/dataManagerContext'

const schema = yup.object().shape({
  title: yup.string().required('Isi Judul Terlebih Dahulu'),
  description: yup.string().required('Isi Deskripsi Terlebih Dahulu'),
})

const CreateNewQuestion = ({data, show, setShow, getTicket}) => {

    const { handleSubmit, control, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
      })

      const create = (formData) => {
        HelpDeskAPI.createTicket(formData)
            .then((res) => {
                if (!res.is_error) {
                    toast.success('Pertanyaan Baru Di Tambahkan')
                    setShow(false)
                    getTicket()
                }
            })
            .catch(() => {
                toast.error('Pertanyaan Gagal Di Tambahkan')
            })
       
      }
    
      const onSubmit = (formData) => {
        create(formData)
      }
  return (
    <Modal
        isOpen={show}
        toggle={() => setShow(!show)}
        size='sm'
        centered
    >
        <ModalHeader toggle={() => setShow(!show)}>Tag Form</ModalHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
            <ModalBody>
                    <div>
                        <Label htmlFor="title">Judul</Label>
                        <Controller
                            name="title"
                            control={control}
                            render={({ field }) => (
                                <Input
                                    type="text"
                                    id="title"
                                    {...field}
                                />
                            )}
                        />
                        <p>{errors.title?.message}</p>
                    </div>
                    <div>
                        <Label htmlFor="description">Deskripsi</Label>
                        <Controller
                            name="description"
                            control={control}
                            render={({ field }) => (
                                <Input
                                    type="textarea"
                                    id="description"
                                    {...field}
                                />
                            )}
                        />
                        <p>{errors.description?.message}</p>
                    </div>
            </ModalBody>
            <ModalFooter className='d-flex justify-content-between'>
                <Button 
                    // onClick     = {shareDrive}
                    // className   ='w-100'
                    type        = 'button' 
                    color       = 'primary '
                    outline
                    onClick     = {() => setShow(false)}
                >
                    Batal
                </Button>
                <Button 
                    // onClick     = {moveDrive}
                    // className   ='w-100'
                    color       = 'primary '
                    type        = 'submit' 
                    // disabled    = {destination === false || destination.length === 0 }
                >
                    Selesai
                </Button>
            </ModalFooter>
        </form>

    </Modal>
  )
}

export default CreateNewQuestion
