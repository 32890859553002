import { Fragment, useContext } from "react"
import { Breadcrumb, BreadcrumbItem, Button } from "reactstrap"
import { DataManagerContext } from "../../../context/dataManagerContext"

const BreadcrumsManager = () => {

    const { dataBreadcums, getDrive, getMoveDrive, setDataBreadcums, setFolderBreadcums, folderBreadcums, setFolder } = useContext(DataManagerContext)
    
    // const cekMenu = menuActive.indexOf("tag")
    const itemBreadcums =  (data, i) => {
        
        if (i === dataBreadcums.length - 1) {
            return <Fragment> {data.name}</Fragment>
        } else {
            return (
                <span
                    onClick={() => {
                        getDrive(data.url)
                        getMoveDrive(data.url)
                        setFolder(data.code)
                        setDataBreadcums(dataBreadcums.splice(0, i + 1))
                        setFolderBreadcums(folderBreadcums.splice(0, i + 1))
                    }}
                    className="cursor-pointer text-success font-weight-bolder "
                > 
                    {data.name}
                </span>
            )
        }
    }

    return (
        <div>
            <div className='justify-content-start breadcrumb-wrapper'>
                <Breadcrumb className='ms-1'>
                    {dataBreadcums.map((data, i) => (
                        <BreadcrumbItem key={i}>
                           {itemBreadcums(data, i)}
                        </BreadcrumbItem>
                    ))}


                </Breadcrumb>
            </div>
            {/* {
                cekMenu != -1 && menuActive != "tag" && menuActive != "tag-add-tag" &&
                <ModalDeleteTag />
            } */}

        </div>
    )
}

export default BreadcrumsManager