import moment from "moment"

const DIN_1 = [      
    {
        name            : 'No.',
        width           : '65px',
        selector        : row => row.index,
		unique_key      : 'index',
    },
    {
        name            : 'Bidang',
        cell            : (row) => (
            <div className="text-start">
                {row.bidang?.name}
            </div>
        ),
		unique_key      : 'bidang',
    },
    {
        name            : 'Sektor',
        cell            : (row) => (
            <div className="text-start">
                {row.sektor?.name}
            </div>
        ),
		unique_key      : 'sektor',
    },
]

const DIN_2 = [      
    {
        name            : 'No.',
        width           : '65px',
        selector        : row => row.index,
		unique_key      : 'index',
    },
    {
        name            : 'Sektor',
        cell            : (row) => (
            <div className="text-start">
                {row.sektor?.name}
            </div>
        ),
		unique_key      : 'sektor',
    },
    {
        name            : 'Lokasi',
        cell            : (row) => (
            <div>
                {row?.lokasi ? JSON.parse(row.lokasi).locationName : "-"}
            </div>
        ),
        unique_key      : 'lokasi',
    },
]

const DIN_7 = [      
    {
        name            : 'No.',
        width           : '65px',
        selector        : row => row.index,
		unique_key      : 'index',
    },
    {
        name            : 'Tema Kegiatan',
        selector        : row => row.tema_kegiatan,
    },
    {
        name            : 'Waktu Kegiatan',
        cell            : row => <div>{moment.unix(row.waktu_kegiatan).format("DD MMMM YYYY")}</div>,
    },
]

const DIN_9 = [      
    {
        name            : 'No.',
        width           : '65px',
        selector        : row => row.index,
		unique_key      : 'index',
    },
    {
        name            : 'Tahun',
        selector        : row => row.tahun,
    }
]

const DIN_10 = [      
    {
        name            : 'No.',
        width           : '65px',
        selector        : row => row.index,
		unique_key      : 'index',
    },
    {
        name            : 'Nama Lengkap',
        selector        : row => row.nama_lengkap,
    }
]

const DIN_13 = [      
    {
        name            : 'No.',
        width           : '65px',
        selector        : row => row.index,
		unique_key      : 'index',
    },
    {
        name            : 'Nomor',
        selector        : row => row.nomor,
    },
    {
        name            : 'Jenis Barang Cetakan',
        selector        : row => row.jenis_cetakan,
    }
]

const DIN_14 = [      
    {
        name            : 'No.',
        width           : '65px',
        selector        : row => row.index,
		unique_key      : 'index',
    },
    {
        name            : 'Nomor',
        selector        : row => row.nomor,
    },
    {
        name            : 'Nama Organisasi',
        selector        : row => row.nama_organisasi,
    }
]

const DIN_16 = [      
    {
        name            : 'No.',
        width           : '65px',
        selector        : row => row.index,
		unique_key      : 'index',
    },
    {
        name            : 'Nomor',
        selector        : row => row.nomor,
    }
]

const HEADER_LIN = {
    DIN_1        : DIN_1,
    DIN_2        : DIN_2,
    DIN_3        : DIN_2,
    DIN_4        : DIN_2,
    DIN_5        : DIN_2,
    DIN_6        : DIN_2,
    DIN_7        : DIN_7,
    DIN_8        : DIN_7,
    DIN_9        : DIN_9,
    DIN_10       : DIN_10,
    // DIN_11       : DIN_7,
    DIN_12       : DIN_10,
    DIN_13       : DIN_13,
    DIN_14       : DIN_14,
    DIN_15       : DIN_10,
    DIN_16       : DIN_16
}

export default HEADER_LIN