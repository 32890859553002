import { Check, X }     from "react-feather"
import { 
    Input, 
    Label, 
    Modal, 
    ModalBody, 
    ModalHeader 
} from "reactstrap"


const ModalField = (props) => {

    const {
        show,
        setShow,

        size,
        data,
        title,
        hideColumn,
        setHideColumn
    }                 = props

    const CustomLabel = ({ htmlFor }) => (
        <Label className="form-check-label" htmlFor={htmlFor}>
            <span className="switch-icon-left">
                <Check size={14} />
            </span>
            <span className="switch-icon-right">
                <X size={14} />
            </span>
        </Label>
    )

    const handleCheckChange = (idx) => {
        const temp = []

        hideColumn?.map((dt, index) => {
            if (idx === index) {
                dt = !dt
                temp.push(dt)
            } else {
                temp.push(dt)
            }
        })
        setHideColumn(temp)
    }

    return (
        <>
            <Modal
                isOpen  = {show} 
                toggle  = {() => { setShow(!show) }} 
                size    = {size || "sm"}
            >
                <ModalHeader
                    toggle  = {() => setShow(!show)} 
                > 
                    {title}
                </ModalHeader>
                <ModalBody>
                    {
                        data !== null && data?.length > 0 &&
                            data?.map((dt, idx) => {
                                return (
                                    dt?.name !== "" &&
                                    <div
                                        key         = {`modal_field_${idx}`}
                                        className   = "d-flex mb-1">
                                        <div className="form-switch form-check-primary ms-1">
                                            <Input
                                                defaultChecked     = {hideColumn[idx]}
                                                onChange           = {() => {
                                                    handleCheckChange(idx)
                                                }}
                                                type               = "switch"
                                                id                 = {`icon-primary${idx}`}
                                            />
                                            <CustomLabel htmlFor={`icon-primary${idx}`} />
                                        </div>
                                        <small>{dt.name}</small>
                                    </div>
                                )
                            })
                    }
                </ModalBody>
            </Modal>
        </>
    )
}

export default ModalField 