import { 
    FormGroup,
    Input,
    InputGroup, 
    Label, 
    Modal, 
    ModalBody, 
    ModalHeader,
    CustomInput,
    ModalFooter,
    Button
}                       from "reactstrap"

import { CKEditor }     from 'ckeditor4-react'

import '@styles/react/libs/editor/editor.scss'

const HelpDetail = ({
    show,
    setShow,
    title,
    size,
    data
}) => {
    return (
        <>
            <Modal
                isOpen  = {show} 
                toggle  = {() => { setShow(!show) }} 
                size    = {size || "sm"}
            >
                <ModalHeader 
                    toggle={() => setShow(!show)} 
                > 
                    {title}
                </ModalHeader>
                <ModalBody>
                    <div>
                        <p>{data && data.content}</p>
                    </div>
                </ModalBody>

                <ModalFooter>
                    <div
                        className   = "text-center col-12"
                    >
                        <Button
                            color   = "primary"
                            onClick = {() => {
                                setShow(false)
                            }}
                        >
                            Tutup
                        </Button>
                    </div>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default HelpDetail