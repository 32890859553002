import { useEffect, useState } 	from "react"
import {
	Card, 
	Media,
	CardBody,
	Row,
	Col, 
} 						 from "reactstrap"
import Avatar 			 from "../avatar"
import PropTypes 		 from "prop-types"
import userManagementAPI from "../../../service/pages/user-management"


const CardCreatedBy = (props) => {
	const { 
		data,
		style 
	} = props

	return (
		<Card className="bg-light-secondary mb-0 " style={style}>
			<CardBody className="p-1">
				<dvi className="d-flex align-items-center" style={{width : '100%', whiteSpace: 'nowrap', overflowX: 'auto'}}>
					<div className="me-1">
						<Avatar
							img			= {
								!data?.photo || data?.photo === "" ? 
									`https://ui-avatars.com/api/?name=${data?.name ? data?.name : "UN"}&background=4e73df&color=fff&bold=true`
								: 
									data?.photo
							}
							imgWidth	= "30"
							imgHeight	= "30"
							onError 	= {(val) => {
								val.target.src = `https://ui-avatars.com/api/?name=${data?.name ? data?.name : "UN"}&background=4e73df&color=fff&bold=true`
							}}
						/>
					</div>

					<div className="text-start">
						<Media className="mb-0 ml-1 fw-bolder text-primary">{data?.name?.toUpperCase()}</Media>
						<small className="ml-0 mt-0">{data?.nip}</small>
					</div>

				</dvi>
			</CardBody>
		</Card>
	)
}

// validation props
CardCreatedBy.propTypes = {
	id: PropTypes.number.isRequired
}

export default CardCreatedBy
