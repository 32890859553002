import {
	createContext,
} 				from "react"

import { 
	base_url,
	sso_client_id, 
	sso_realm,
	sso_url 
} 				from "../interceptor/axiosInstance"

import Keycloak from "keycloak-js"

//Context
const SSOContext = createContext(null)

const SSOProvider = ({ children }) => {

    const urlApiSSO         = "https://fairly-closing-eel.ngrok-free.app/api"

	const _kc = new Keycloak({
		url			: `${sso_url}`,
		realm		: `${sso_realm}`,
		clientId	: `${sso_client_id}`,
	})

	const tryLogin = async () => {
		try {
			const authenticated = await _kc.init({
				checkLoginIframe		: false,
				onLoad					: "login-required",
				// redirectUri: `${window.location.origin}/verify-sso`,
				redirectUri				: `${base_url}/auth/callback`,
				pkceMethod				: "S256",
			}).then((authenticated) => {
				if (!authenticated) {
					console.log("User not authenticated")
				}
			}).catch(error => console.log(error))

			console.log(authenticated, 'token')
			console.log(`User is ${authenticated ? 'authenticated' : 'not authenticated'}`)
		} catch (error) {
			console.error('Failed to initialize adapter:', error)
		}
	}

	return <SSOContext.Provider
		value={{
			_kc,
			tryLogin
		}}
	>
		{children}
	</SSOContext.Provider>
}

export { SSOContext, SSOProvider }