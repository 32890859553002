import { 
    useState,
    createContext,
    useEffect
}                       from "react"

//Context
const WorkunitContext  = createContext(null)

//API
import workunitAPI      from "../service/pages/workunit"

const WorkunitProvider = ({ children }) => {

    //State
    const [listData, setListData]               = useState([])
    const [workunitOption, setWorkunitOption]   = useState([])
    const [workunitRanking, setWorkunitRanking]   = useState([])
    const [isLoading, setIsLoading]             = useState(false)
    const [pagination, setPagination]           = useState(false)
    
    //Get data workunit
    const getData = (params) => {
        setIsLoading(true)
        
        workunitAPI.getWorkunit(params).then(
            res => {

                setIsLoading(false)

                const formatData_ = []

                if (res.count !== 0) {
                    res.results.map((data, idx) => {
                        data.index          = idx + 1
                        data.workunit       = data.name
                        formatData_.push(data)
                    })
                }

                const pagination = {
                    count       : res.count,
                    page        : res.page,
                    page_size   : res.page_size,
                    next        : res.next,
                    previous    : res.previous,
                    total_data  : res.count
                }
                
                setListData(formatData_)
                setPagination(pagination)
            }
        ).catch(
            err => {
                console.log(err, 'err')
            }
        )
    }

    const getDataAll = () => {
        
        workunitAPI.getWorkunit({
            page_size : 5000
        }).then(
            res => {

                const formatData_ = []
                const lvl1        = []
                const lvl2        = []
                const lvl3        = []
                const lvl4        = []

                if (res.count !== 0) {
                    const data = res?.results
                    data?.reverse()

                    data?.map((data) => {
                        if (data?.work_unit_level_id === 1) {
                            lvl1.push({
                                label : `${data?.work_unit_level?.name} ${data?.name}`,
                                value : data?.id,
                                level : 1
                            })
                        } else if (data?.work_unit_level_id === 2) {
                            lvl2.push({
                                label : `${data?.work_unit_level?.name} ${data?.name}`,
                                value : data?.id,
                                level : 2
                            })
                        } else if (data?.work_unit_level_id === 3) {
                            lvl3.push({
                                label : `${data?.work_unit_level?.name} ${data?.name}`,
                                value : data?.id,
                                level : 3
                            })
                        } else if (data?.work_unit_level_id === 4) {
                            lvl4.push({
                                label : `${data?.work_unit_level?.name} ${data?.name}`,
                                value : data?.id,
                                level : 4
                            })
                        }
                        
                    })

                    formatData_?.push({
                        label   : "KEJAKSAAN AGUNG",
                        level   : 1,
                        options : lvl1
                    })

                    formatData_?.push({
                        label   : "KEJAKSAAN TINGGI",
                        level   : 2,
                        options : lvl2
                    })

                    formatData_?.push({
                        label   : "KEJAKSAAN NEGERI",
                        level   : 3,
                        options : lvl3
                    })

                    formatData_?.push({
                        label   : "CABANG KEJAKSAAN NEGERI",
                        level   : 4,
                        options : lvl4
                    })

                    setWorkunitOption(formatData_)
                }
            }
        ).catch(
            err => {
                console.log(err, 'err')
            }
        )
    }

    const getDataAllRanking = (level) => {
        
        workunitAPI.getWorkunit({
            page_size : 5000
        }).then(
            res => {

                const formatData_ = []

                if (res.count !== 0) {
                    res.results.map((data) => {
                        if (level === 0) {
                            formatData_.push({
                                label : data?.name,
                                value : data?.id
                            })
                        } else if (level === 1 && data?.work_unit_level?.name === 'KEJAKSAAN AGUNG') {
                            formatData_.push({
                                label : data?.name,
                                value : data?.id
                            })
                        } else if (level === 2 && data?.work_unit_level?.name === 'KEJAKSAAN TINGGI') {
                            formatData_.push({
                                label : data?.name,
                                value : data?.id
                            })
                        } else if (level === 3 && data?.work_unit_level?.name === 'KEJAKSAAN NEGERI') {
                            formatData_.push({
                                label : data?.name,
                                value : data?.id
                            })
                        } else if (level === 4 && data?.work_unit_level?.name === 'CABANG KEJAKSAAN NEGERI') {
                            formatData_.push({
                                label : data?.name,
                                value : data?.id
                            })
                        } 
                    })

                    setWorkunitRanking(formatData_)
                }
            }
        ).catch(
            err => {
                console.log(err, 'err')
            }
        )
    }

    useEffect(() => {
        if (localStorage.getItem('userData')) {
            getDataAll()
        }
    }, [])

    return <WorkunitContext.Provider
        value={{
            listData,
            setListData,

            pagination,
            setPagination,

            isLoading,
            setIsLoading,

            getData,
            getDataAllRanking,
            workunitOption,
            workunitRanking,
        }}
    > 
        {children}
    </WorkunitContext.Provider>
}

export { WorkunitContext, WorkunitProvider }