import { 
    useState, 
    useEffect,
}                                       from "react"

import { useParams }                    from "react-router-dom"
import OrganizationStructureDetail      from "./component/OrganizationStructureDetail"
import OrganizationStructureList        from "./component/OrganizationStructureList"

const OrganizationStructure = () => {

    const params                    = useParams()
    const id                        = params.id
    const [active, setActive]       = useState("list")
    const [activeId, setActiveId]   = useState(0)

    useEffect(() => {
        if (id !== undefined) {
            setActive("detail")
            setActiveId(id)
        }
    }, [id])

    return (
        <>
            {
                active === "list" &&
                <OrganizationStructureList
                    setActive       = {setActive}
                    setActiveId     = {setActiveId}
                    activeId        = {activeId}
                />
            }

            {
                active === "detail" &&
                <OrganizationStructureDetail
                    setActive       = {setActive}
                    activeId        = {activeId}
                />
            }
        </>
    )
}

export default OrganizationStructure