import { 
    FormGroup,
    Input,
    InputGroup, 
    Label, 
    Modal, 
    ModalBody, 
    ModalHeader,
    CustomInput,
    ModalFooter,
    Button
}                       from "reactstrap"

import { CKEditor }     from 'ckeditor4-react'
import '@styles/react/libs/editor/editor.scss'

const HelpUpdate = ({
    show,
    setShow,
    title,
    size
}) => {
    return (
        <>
            <Modal
                isOpen  = {show} 
                toggle  = {() => { setShow(!show) }} 
                size    = {size || "sm"}
            >
                <ModalHeader 
                    toggle={() => setShow(!show)} 
                > 
                    {title}
                </ModalHeader>
                <ModalBody>
                    <CKEditor
                        name        = "text-editor-1"
                        onChange    = {(event) => {
                            console.log(event.editor.getData())
                        }}
                        initData    = {""}
                        editorUrl   = "https://cdn.ckeditor.com/4.20.1/full/ckeditor.js"
                        config      = {{
                            forcePasteAsPlainText: true,
                            removePlugins: 'notification,notificationaggregator',
                            on: {
                                instanceReady: function(evt) {
                                    const editor = evt.editor
                                    editor.showNotification = function() {
                                        // Tidak melakukan apapun, menghilangkan notifikasi.
                                    }
                                }
                            }
                        }}
                    />
                </ModalBody>

                <ModalFooter>
                    <div
                        className   = "d-flex justify-content-between col-12"
                    >
                        <Button
                            color   = "primary"
                            outline
                            onClick = {() => {
                                setShow(false)
                            }}
                        >
                            Batal
                        </Button>

                        <Button
                            color   = "primary"
                            onClick = {() => {
                                setShow(false)
                            }}
                        >
                            Simpan
                        </Button>
                    </div>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default HelpUpdate