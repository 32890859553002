import React, { useEffect, useState } from 'react'
import Avatar from '../../../components/avatar'
import moment from 'moment'
import classNames from 'classnames'
import reportAPI from '../../../../service/report'
import toast from 'react-hot-toast'

const NotificationList = ({
    item, 
    index,
    singleNotificationHasRead
}) => {

    const [report, setReport] = useState(null)

    const getReportId = (data) => {
		const params = {
			id : data?.id ? data?.id : 1
		}
		reportAPI.getReport(params)
			.then((res) => {
				setReport(res?.results[0])
			})
	}

    const handleMovePage = () => {
        if (item?.notify?.content && report) {
          window.open(`/${report?.report_type?.type?.toString().toLowerCase().replace(/\./g, '')}/${report?.report_type?.seq}?report_id=${report?.id}&action=detail`, '_blank')
        } else {
          toast.error(`Tidak Ada Surat`)
        }
      }

    useEffect(() => {
        getReportId({id : item?.notify?.content})
    }, [])

  return (
    <a
        key			= {index}
        target      = '_blank'
       
        onClick		= {e => {
            if (!item.switch) {
                singleNotificationHasRead(item.id)
            }
            handleMovePage()
        }}
        className	= {`d-flex ${item.is_read && 'cursor-auto'}`}
    >
        <div
            className={classNames('list-item d-flex', {
                "bg-light-secondary border-light": !item.is_read
            })}
        >
                <>
                    <div className='me-1 d-flex align-items-center'>
                    <Avatar 
                        // onError={fallbackImage_} 
                        img         = {item?.notify?.icon  === "" ? `https://ui-avatars.com/api/?name=${item ? item?.notify?.title : "UN"}&background=4e73df&color=fff&bold=true` : item?.notify?.icon} 
                        imgWidth    = '40'
                        imgHeight   = '40'  
                        // className   = 'my-1'
                    />
                    </div>
                    <div className='list-item-body flex-grow-1'>
                        <div className='d-flex justify-content-between text-primary align-items-center mb-0'>
                            <p className='mb-0'>
                                {item?.notify?.title}
                            </p>
                            <p style={{ fontSize : '10px' }} className='mb-0'>
                                {moment(item.created_at).format('D MMMM YYYY, h:mm')}
                            </p>
                        </div>
                        <p className='notification-text'>{item?.notify?.body}</p>
                    </div>
                </>
                
        </div>
    </a>
  )
}

export default NotificationList