// ** Icons Import
import { PieChart, Circle } from 'react-feather'

const IN = [
  {
    id: 'in',
    icon: <PieChart size={20} />,
    title: 'IN - Surat',
    action: 'read',
    resource: 'in-surat',
    children: [
      {
        id: 1,
        icon: <Circle size={12} />,
        title: "IN.1 - SPRINTUG",
        action: "read",
        navLink: "/in/1",
        resource: "in1-sprintug",
      },
      {
        id: 2,
        icon: <Circle size={12} />,
        title: "IN.2 - SP.PPS",
        action: "read",
        navLink: "/in/2",
        resource: "in2-sppps"
      },
      {
        id: 3,
        icon: <Circle size={12} />,
        title: "IN.3 - SPRINTOPS",
        action: "read",
        navLink: "/in/3",
        resource: "in3-sprintops"
      },
      {
        id: 4,
        icon: <Circle size={12} />,
        title: "IN.4 - Analisa Sasaran",
        action: "read",
        navLink: "/in/4",
        resource: "in4-analisa-sasaran",

      },
      {
        id: 5,
        icon: <Circle size={12} />,
        title: "IN.5 - Analisa Tugas",
        action: "read",
        navLink: "/in/5",
        resource: "in5-analisa-tugas"
      },
      {
        id: 6,
        icon: <Circle size={12} />,
        title: "IN.6 - Target Operasi",
        action: "read",
        navLink: "/in/6",
        resource: "in6-target-operasi"
      },
      {
        id: 7,
        icon: <Circle size={12} />,
        title: "IN.7 - Rencana Penyelidikan",
        action: "read",
        navLink: "/in/7",
        resource: "in7-rencana-penyelidikan"
      },
      {
        id: 8,
        icon: <Circle size={12} />,
        title: "IN.8 - Rencana Penggalangan",
        action: "read",
        navLink: "/in/8",
        resource: "in8-rencana-penggalangan"
      },
      {
        id: 9,
        icon: <Circle size={12} />,
        title: "IN.9 - S.D Perm. Keterangan",
        action: "read",
        navLink: "/in/9",
        resource: "in9-sd-perm-keterangan"
      },
      {
        id: 10,
        icon: <Circle size={12} />,
        title: "IN.10 - B.A Perm. Keterangan",
        action: "read",
        navLink: "/in/10",
        resource: "in10-ba-perm-keterangan"
      },
      {
        id: 11,
        icon: <Circle size={12} />,
        title: "IN.11 - Catatan Wawancara",
        action: "read",
        navLink: "/in/11",
        resource: "in11-catatan-wawancara"
      },
      {
        id: 12,
        icon: <Circle size={12} />,
        title: "IN.12 - SU. Paparan PPS",
        action: "read",
        navLink: "/in/12",
        resource: "in12-su-paparan-pps"
      },
      {
        id: 13,
        icon: <Circle size={12} />,
        title: "IN.13 - Surat Persetujuan PPS",
        action: "read",
        navLink: "/in/13",
        resource: "in13-surat-persetujuan-pps"
      },
      {
        id: 14,
        icon: <Circle size={12} />,
        title: "IN.14 - PPS Ditolak",
        action: "read",
        navLink: "/in/14",
        resource: "in14-pps-ditolak"
      },
      {
        id: 15,
        icon: <Circle size={12} />,
        title: "IN.15 - Perkembangan PPS",
        action: "read",
        navLink: "/in/15",
        resource: "in15-perkembangan-pps"
      },
      {
        id: 16,
        icon: <Circle size={12} />,
        title: "IN.16 - Penghentian PPS",
        action: "read",
        navLink: "/in/16",
        resource: "in16-penghentian-pps"
      },
      {
        id: 17,
        icon: <Circle size={12} />,
        title: "IN.17 - Selesai PPS",
        action: "read",
        navLink: "/in/17",
        resource: "in17-selesai-pps"
      },
      {
        id: 18,
        icon: <Circle size={12} />,
        title: "IN.18 - Koordinasi PPS",
        action: "read",
        navLink: "/in/18",
        resource: "in18-koordinasi-pps"
      },
      {
        id: 19,
        icon: <Circle size={12} />,
        title: "IN.19 - Per. Pemantauan",
        action: "read",
        navLink: "/in/19",
        resource: "in19-per-pemantauan"
      },
      {
        id: 20,
        icon: <Circle size={12} />,
        title: "IN.20 - S.K. Membwa P. Intel",
        action: "read",
        navLink: "/in/20",
        resource: "in20-sk-membwa-p-intel"
      },
      {
        id: 21,
        icon: <Circle size={12} />,
        title: "IN.21 - S.P. Penkum",
        action: "read",
        navLink: "/in/21",
        resource: "in21-sp-penkum"
      },
      {
        id: 22,
        icon: <Circle size={12} />,
        title: "IN.22 - Analisa Berita",
        action: "read",
        navLink: "/in/22",
        resource: "in22-analisa-berita"
      },
      {
        id: 23,
        icon: <Circle size={12} />,
        title: "IN.23 - T.T. Peny. Informasi",
        action: "read",
        navLink: "/in/23",
        resource: "in23-tt-peny-informasi"
      },
      {
        id: 93,
        icon: <Circle size={12} />,
        action: "read",
        navLink: "/in/berita_acara_kunjungan_lapangan",
        title: "Berita Acara Kunjungan Lapangan",
        resource: "in24-berita-acara-kunjungan-lapangan"
      }
    ],
    badgeText: '3'
  }
]

export default IN