import { useContext, useEffect, useState }      from "react"
import { WorkunitContext }                      from "../../context/workunitContext"
import { UserManagementContext }                from "../../context/userManagementContext"

import RecapitulationTable                      from "./component/RecapitulationTable"
import ModalDelete                              from "../other/ModalDelete"
import RecapitulationDetail                     from "./component/RecapitulationDetail"
import RecapitulationForm                       from "./component/RecapitulationForm"
import recapitulationAPI                        from "../../service/pages/recapitulation"
import moment                                   from "moment"
import toast                                    from "react-hot-toast"
import userManagementAPI                        from "../../service/pages/user-management"

const Recapitulation = () => {

    const [params, setParams]                             = useState({
        all     : true,
        order   : "-id",
    })
    const [isLoading, setIsLoading]                       = useState(null)

    const [selected, setSelected]                         = useState([])
    const [detail, setDetail]                             = useState(false)
    const [isCreateModalVisible, setIsCreateModalVisible] = useState(false)
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
    const [isDetailModalVisible, setIsDetailModalVisible] = useState(false)
    const [clearSelected, setClearSelected]               = useState(false)

    const {
        workunitOption
    } = useContext(WorkunitContext)

    const [data, setData]           = useState([])

    const getData = (par) => {
        let tempParams = {
            ...params,
            ...par
        }

        if (par?.isReset) {
            tempParams = {
                all     : true,
                order   : "-id",
            }
        }

        setParams(tempParams)

        recapitulationAPI.getRecap(tempParams)
            .then(async (res) => {
                const _data = res?.results
                const temp  = []

                if (_data) {
                    await Promise.all(
                        _data?.map(async (dt, idx) => {
                            dt.index = idx + 1

                            const params = {
                                user : dt.created_by
                            }
                    
                            const user = await userManagementAPI.getUserManagement(params)

                            if (user?.results?.length > 0) {
                                dt.user = user?.results[0]
                            }
            
                            temp.push(dt)
                        })
                    )
        
                    temp.sort((a, b) => a.index - b.index)

                    setData(temp)
                }
            })
    }

    const handleCreate = (payload) => {

        const tempWorkunit = []

        const allWorkunit  = []
            workunitOption?.map((dtLevel) => {
                dtLevel.options?.map((dt) => {
                    allWorkunit.push(dt)
                })
            })

        if (workunitOption) {
            payload.work_unit_id?.map((dtWid) => {
                const check = allWorkunit.find((dtWorkunit) => dtWorkunit.value === dtWid)
        
                if (check) {
                    tempWorkunit.push(check)
                }
            })
        }

        payload.work_unit = tempWorkunit
        
        let title = "Laporan Bulanan"
        
        if (payload.start_date || payload.end_date) {
            title += ` Periode ${moment.unix(payload.start_date).format("DD MMMM YYYY")} s/d ${moment.unix(payload.end_date).format("DD MMMM YYYY")}`
        } else {
            title += ` ${moment(new Date(`${payload.year}-${payload.month}-01`)).format("MMMM YYYY")}`
        }
        
        if (payload?.work_unit) {
            let workunitName = ""
            payload?.work_unit.map((dt, idx) => {
                if (idx !== 0) {
                    workunitName += `, ${dt.label}`
                } else {
                    workunitName += `${dt.label}`
                }
        
            })
        
            if (workunitName !== "") {
                title += ` (${workunitName})`
            }
        }

        payload.title = title

        recapitulationAPI.createRecap(payload)
            .then((res) => {
                toast.success("Data Rekap berhasil ditambahkan.")
                getData()
                setIsCreateModalVisible(false)
            })
    }

    const handleDetail = (status, id, data) => {
        setIsDetailModalVisible(true)

        setSelected(data)

        const param = {
            detail : true
        }

        recapitulationAPI.getDetailRecap(param, id)
            .then((res) => {
                setDetail(res)
            })
    }

    const handleDelete = () => {
        if (selected.length > 0) {
            selected.map((data) => (
                recapitulationAPI.deleteRecap(data.id).then(
                    () => {
                        getData(params)
                    }
                ).catch( 
                    err => {
                        console.log(err)
                    }
                )
            ))

            setSelected([])
        }
    }

    useEffect(() => {
        if (clearSelected) {
            setClearSelected(!clearSelected)
        }
    }, [clearSelected])

    useEffect(() => {
        if (!isDeleteModalVisible) {
            setSelected([])
            setClearSelected(true)
        }
    }, [isDeleteModalVisible])

    useEffect(() => {
        getData()
    }, [])

    return (
        <>
            <RecapitulationTable
                data                    = {data}
                params                  = {params}
                setData                 = {setData}
                getData                 = {getData}
                loading                 = {isLoading}
                selected                = {selected}
                setParams               = {setParams}
                pagination              = {null}
                setSelected             = {setSelected}
                clearSelected           = {clearSelected}
                setClearSelected        = {setClearSelected}
                setIsCreateModalVisible = {setIsCreateModalVisible}
                setIsDeleteModalVisible = {setIsDeleteModalVisible}
                setIsDetailModalVisible = {setIsDetailModalVisible}
                handleDetail            = {handleDetail}
            />

            <RecapitulationForm
                show        = {isCreateModalVisible}
                setShow     = {setIsCreateModalVisible}
                size        = "md"
                handleCreate= {handleCreate}
            />

            <RecapitulationDetail
                show        = {isDetailModalVisible}
                setShow     = {setIsDetailModalVisible}
                data        = {detail}
                selected    = {selected}
                size        = "xl"
            />

            <ModalDelete
                show        = {isDeleteModalVisible}
                setShow     = {setIsDeleteModalVisible}
                size        = "sm"
                title       = "Hapus Rekap Data"
                handleDelete= {handleDelete}
                data        = {selected}
            />
        </>
    )
}

export default Recapitulation