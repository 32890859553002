import React, { useState } from 'react'
import { AlertTriangle } from 'react-feather'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap'
import FileManagementAPI from '../../../../service/pages/file-management'
import toast from 'react-hot-toast'

const SwapSequence = ({show, setShow, getData}) => {

    const [loading, setLoading] = useState(false)
    console.log(show)

    const create = (data) => {
        FileManagementAPI.createCategory(data)
        .then(() => {
            toast.success('Berhasil Menukar Data')
            getData()
            setShow(false)
            setLoading(false)
        })
        .catch((err) => {
            console.log(err)
        })
      }

      const update = (data) => {
        
        FileManagementAPI.updateCategory(show[0]?.id, data)
            .then(() => {
                create(show[1])
            })
            .catch((err) => {
                console.log(err)
                toast.error(err?.result?.message)

            })
      }
      const sumbmitSwapSequence = () => {
        const swapForm = {
            name : show[0].name,
            sequence : Math.floor(Math.random() * (100 - 1 + 1)) + 1,
        }
        setLoading(true)
        update(swapForm)
      }
  return (
    <Modal
        isOpen  = {show} 
        toggle  = {() => { setShow(!show) }} 
        size    = {"sm"}
        centered
    >
        <ModalHeader 
            toggle={() => {
                setShow(!show)
            }} 
        > 
            Tukar Urutan
        </ModalHeader>
    <ModalBody>
        <div className='d-flex justify-content-center p-1'>
            <AlertTriangle size={70} />
        </div>
        <h4>
            Tukar Urutan Category <b>{show[1]?.name}</b> Dengan Category <b>{show[0]?.name}</b> ?
        </h4>
    </ModalBody>
    <ModalFooter className={'d-flex justify-content-between'}>
    <Button
            color = 'primary'
            outline
        >
            Batal
        </Button>
        <Button
            color = 'primary'
            onClick = {sumbmitSwapSequence}
        >
            {
                loading ? (
                    <Spinner size={'sm'}/>
                ) : ('Tukar')
            }
        </Button>
    </ModalFooter>
    </Modal>
  )
}

export default SwapSequence