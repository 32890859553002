// ** Icons Import
import { Award } from 'react-feather'

export default [
  {
    id          : 'ranking',
    icon        : <Award size={20} />,
    title       : 'Papan Peringkat',
    action      : 'read',
    navLink     : "/ranking",
    resource    : 'papan-peringkat'
  }
]
