import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"

const DeclineModal = (props) => {

    const {
        getData,
        declineReason,
        showDeclineForm,
        setDeclineReason,
        handleApprovement,
        setShowDeclineForm,
        
    }                       = props

    return (
        <Modal
            key = "decline_form_"
            isOpen   = {showDeclineForm}
            centered = {true}
        >
            <ModalHeader
                toggle = {() => { setShowDeclineForm(false) }}
            >
                Penolakan Surat
            </ModalHeader>

            <ModalBody className="text-center">
                <p>Anda tidak menyetujui surat ini, <br/> silahkan berikan alasan anda untuk perbaikan.</p>

                <Input
                    type        = "textarea"
                    // value       = {declineReason}
                    onChange    = {(e) => { setDeclineReason(e.target.value) }}
                    placeholder = "Tulis disini."
                />
            </ModalBody>

            <ModalFooter className="d-flex justify-content-between">
                <Button
                    color = "primary"
                    outline
                    onClick = {() => { setShowDeclineForm(false) }}
                >
                    Batal
                </Button>
                <Button
                    color   = "primary"
                    onClick = {async() => { 
                        await handleApprovement(4, declineReason) 
                        getData()
                        setShowDeclineForm(false)
                    }}
                >
                    Lanjutkan
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default DeclineModal