import { useEffect, useState }  from "react"

import { 
    Badge,
    Button,
    Card, 
    CardBody, 
    CardHeader,
    Col,
    Input,
    ListGroup,
    ListGroupItem,
    Row
}                               from "reactstrap"

import { Trash2 }               from "react-feather"

import moment                   from "moment/moment"
import Select                   from "react-select"
import toast                    from "react-hot-toast"
import DoneTicket               from "./DoneTicket"
import ModalDelete              from "../../../other/ModalDelete"
import CreateNewQuestion        from "./createNewQuestion"

//  API
import HelpDeskAPI              from "../../../../service/pages/helpdesk"

const TicketingChatList = ({
    data,
    selected,
    getTicket,
    setSelected,
    handleViewUser,
    ticketSelected, 
    setTicketSelected,
    showDeleteTicket,
    setShowDeleteTicket
}) => {

    const userData                              = JSON.parse(localStorage.getItem('userData'))
    const [status, setStatus]                   = useState(null)
    const [orderBy, setOrderBy]                 = useState(null)
    const [dataTicket, setDataTicket]           = useState(null)
    const [showQuestion, setShowQuestion]       = useState(false)
    const [showDoneTicket, setShowDoneTicket]   = useState(false)

    const selectTicket = (data) => {
        console.log(data)
        if (ticketSelected.map(e => e.id).indexOf(data.id) !== -1) {
            setTicketSelected(ticketSelected.filter(opt => opt.id !== data.id))
        } else {
            setTicketSelected([...ticketSelected, data])
        }
    }

    const doneTicket = (ticket) => {
        const formData = {
            title: ticket.title,
            description: ticket.description,
            status : 3
        }
        console.log(formData)
        HelpDeskAPI.updateTicket(ticket.id, formData)
            .then(res => {
                if (!res.is_error) {
                    setShowDoneTicket(false)
                    getTicket()
                    toast.success('Ticket Selesai!')
                }
            }).catch(() => {
                toast.error('Ticket Error!')
            })
    }

    const deleteTicket = () => {

        if (userData.role !== 'operator-operator') {
            ticketSelected.map((ticket) => {
                if (ticket.status === 1) {
                    HelpDeskAPI.deleteTicket(ticket.id)
                    .then(res => {
                        if (!res.is_error) {
                            setShowDoneTicket(false)
                            getTicket()
                            toast.success('Dihapus!')
                        }
                    }).catch(() => {
                        toast.error('Ticket Error!')
                    })
                } else {
                    toast.error('Pertanyaan Sedang Proses')
                }
            })
        } else {
            ticketSelected.map((ticket) => {
                if (ticket.status === 3) {
                    HelpDeskAPI.deleteTicket(ticket.id)
                    .then(res => {
                        if (!res.is_error) {
                            setShowDoneTicket(false)
                            getTicket()
                            toast.success('Ticket Dihapus!')
                        }
                    }).catch(() => {
                        toast.error('Ticket Error!')
                    })
                } else {
                    toast.error('Pertanyaan Belum Selesai')
                }
            })
        }
      
        
    }

    const renderCardItem = (data) => {
       
        return (
            <>
                <ListGroupItem 
                    onClick     = {() => {
                        setSelected(data)
                    }}
                    className   = {`p-0 m-0 cursor-pointer ${selected?.id === data?.id ? "bg-primary text-white" : ""}`}
                >
                    <div className="p-1">
                        <Row>
                            <Col xs={1} className="d-flex align-items-center pe-0">
                                <Input
                                    type        = "checkbox"
                                    checked     = {ticketSelected && ticketSelected.map((data) => data.id).indexOf(data.id) !== -1 ? "true" : ""}
                                    onClick     = {() => { selectTicket(data) }} 
                                    className   = "cursor-pointer"
                                />
                            </Col>
                            <Col xs={6}>
                                <div className=" align-items-center">
                                        <strong>{handleViewUser(data?.user)}</strong>
                                        <div>{data?.title}</div>
                                </div>
                                
                            </Col>
                            <Col xs={3} className="align-items-center">
                                <div>
                                    {moment(data?.created_at).format('DD-MM-YYYY')}
                                </div>
                            </Col>
                            <Col xs={2} className="d-flex  justify-content-center align-items-center">
                            {
                                data?.status === 1 ?
                                        <Badge pill color='primary' >
                                            <span>1</span>
                                        </Badge>
                                : data?.status === 2 ?
                                    <div  
                                        onClick     = {() => {
                                            if (userData.role === 'operator-operator') {
                                                setShowDoneTicket(true)
                                                setDataTicket(data)
                                            }
                                            
                                        }}
                                    >
                                        <Badge pill color='success ' >
                                            <span>Proses</span>
                                        </Badge>
                                    </div>
                                :   <div>
                                        <Badge pill color='danger' >
                                            <span>Selesai</span>
                                        </Badge>
                                    </div>
                            }
                            </Col>
                        </Row>
                    </div>
                </ListGroupItem>
               
            </>
        )
    }

    const statusOptions = [
        { value: '1', label: 'Belum Di Buka' },
        { value: '2', label: 'Proses' },
        { value: '3', label: 'Selesai' }
      ]

      const orderByOptions = [
        { value: 'desc', label: 'Terbaru' },
        { value: 'asc', label: 'Terlama' },
      ]

      useEffect(() => {
        getTicket({status : status, sort : orderBy})
      }, [status, orderBy])

    return (
        <>
            <Card
                className="h-100"
            >
                 {
                    userData.role !== 'operator-operator' && (
                        <CardHeader tag={'h5'} className="d-flex justify-content-between">
                            Filter Berdasarkan
                            <Button 
                                color="primary"
                                onClick={() => setShowDeleteTicket(true)} 
                                disabled = {ticketSelected.length === 0 }
                                className="btn-icon"
                                style={{ padding : '5px' }} 
                            >
                                <Trash2 />
                            </Button>
                        </CardHeader>
                    ) 
                }
                <CardBody className=" m-0 h-100">
                    {
                        userData.role !== 'operator-operator' && (
                            <Row className="mb-1">
                                <Col xs={6}>
                                    <Select
                                        options={statusOptions}
                                        className="basic-single"
                                        isClearable
                                        classNamePrefix="select"
                                        onChange={(e) => setStatus(e ? e.value : null)}
                                    />
                                </Col>
                                <Col xs={6}>
                                    <Select 
                                        options={orderByOptions}
                                        onChange={(e) => setOrderBy(e ? e.value : null)}
                                        className="basic-single"
                                        isClearable
                                        classNamePrefix="select"
                                    />
                                </Col>
                            </Row>
                        ) 
                    }
                    <ListGroup className= {`overflow-auto ${userData.role !== 'operator-operator' ? "user-height-list" : "h-100"}`}>
                        {
                            data !== null && data?.length > 0 &&
                                data?.map((item) => {
                                    return (
                                        renderCardItem(item)
                                    )
                                })
                        }     
                    </ListGroup>
                    {
                        userData.role !== 'operator-operator' && (
                            <div className="d-flex justify-content-center p-2 mt-2" style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}>
                                <Button 
                                    color="primary" 
                                    onClick={() => setShowQuestion(true)}
                                    className="rounded-pill"
                                >
                                    Buat Pertanyaan Baru
                                </Button>
                            </div>
                        ) 
                    }           
                </CardBody>
            </Card>

            <CreateNewQuestion 
                show        = {showQuestion}
                setShow     = {setShowQuestion}
                getTicket   = {getTicket}
            />

            <DoneTicket 
                name        = {handleViewUser(dataTicket?.user)}
                show        = {showDoneTicket}
                setShow     = {setShowDoneTicket}
                doneTicket  = {() => doneTicket(dataTicket)}
            />

            <ModalDelete
                show            = {showDeleteTicket}
                setShow         = {setShowDeleteTicket}
                size            = "sm"
                title           = "Hapus Pertanyaan"
                handleDelete    = {deleteTicket}
                data            = {ticketSelected}
            />

        </>
    )
}

export default TicketingChatList