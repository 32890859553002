import baseURL, {get, delete_, post, put}  from '../../index'

//get
const getPosition     = (params) => get(`/management/position/`, params)
const getOrgStructure = (params) => get(`/management/position/organizational-structure`, params)

//post
const createPosition  = (formData) => post('/management/position/create', formData)

//put
const updateWorkunit  = (formData, id) => put(`/management/position/update/${id}`, formData)

//delete
const deletePosition  = (id) => delete_(`/management/position/delete/${id}`)

const positionAPI = {
    getPosition,
    getOrgStructure,

    createPosition,

    updateWorkunit,

    deletePosition
}

export default positionAPI