import { 
    useRef,
    useState,
    createContext,
}                   from "react"

import toast        from "react-hot-toast"
import PerformanceAPI from "../service/pages/performance"

const PerformanceContext = createContext(null)

const PerformanceProvider = ({ children }) => {

    const [listData, setListData]                               = useState([])
    const [logPoint, setLogPoint]                               = useState([])
    const [activeTab, setActiveTab]                             = useState("agent")
    const [isLoading, setIsLoading]                             = useState(true)
    const [loadWinner, setLoadWinner]                           = useState(true)
    const [selectUser, setSelectUser]                           = useState(false)
    const [selectYear, setSelectYear]                           = useState(2024)
    const [selectKind, setSelectKind]                           = useState('national')
    const [selectLevel, setSelectLevel]                         = useState(0)
    const [detailWinner, setDetailWinner]                       = useState([])
    const [detailLoading, setDetailLoading]                     = useState(true)
    const [selectSemester, setSelectSemester]                   = useState(0)
    const [listPerformance, setListPerformance]                 = useState(false)
    const [logPointPaginate, setLogPointPaginate]               = useState([])
    const [selectUserRanking, setSelectUserRanking]             = useState(1)
    const [selectYearLogPoint, setSelectYearLogPoint]           = useState(false)
    const [selectSemesterLogPoint, setSelectSemesterLogPoint]   = useState(false)
    const [selectWorkunit, setSelectWorkunit]                   = useState(false)


    const imageHandle = (data) => {
        if (activeTab === 'agent') {
            return data.user?.photo  === "" ? `https://ui-avatars.com/api/?name=${data ? data?.user?.name : "UN"}&background=4e73df&color=fff&bold=true` : data?.user?.photo
        } else {
            return data?.work_unit?.logo ? data?.work_unit?.logo  : 'https://i.pinimg.com/originals/d2/98/4e/d2984ec4b65a8568eab3dc2b640fc58e.jpg'
        }
    }

    const getDetailDataAgent = (data) => {
        setDetailLoading(true)
        if (data) {
            const params = {
                user_id : data?.id ? data.id : 1,
                role : data?.role ? data.role : 0,
                kind : data?.kind ? data.kind : 'national',
                period : data?.period ? data.period : '2024',
                semester : data?.semester ? data.semester : 0,
            }
    
            PerformanceAPI.getPerformanceAgent(params)
                .then(res => {
                    setDetailWinner(res?.results)
                    setDetailLoading(false)
                })
                .catch((err) => {
                    toast.error(err)
                })
        } else {
            setDetailWinner([])
            setDetailLoading(false)
        }
        
    }

    const getDetailDataWorkUnit = (data) => {
        setDetailLoading(true)
        if (data) {
            const params = {
                work_unit_id : data?.id && data.id,
                level : data?.level ? data.level : 0,
                period : data?.period ? data.period : '2024',
                semester : data?.semester ? data.semester : 0,
            }
    
            PerformanceAPI.getPerformanceWorkunit(params)
                .then(res => {
                    // console.log(res)
                    setDetailWinner(res?.results)
                    setDetailLoading(false)
                })
                .catch((err) => {
                    toast.error(err)
                })
        } else {
            setDetailWinner([])
            setDetailLoading(false)

        }
    }

    const getDataAgent = (data) => {
        setIsLoading(true)

        const params = {
            role : data?.role ? data.role : 0,
            kind : data?.kind && data.kind,
            period : data?.period ? data.period : '2024',
            semester : data?.semester ? data.semester : 0,
            work_unit_id : data?.work_unit_id ? data.work_unit_id : null,
            keyword : data?.keyword ? data.keyword : null,
        }

        PerformanceAPI.getPerformanceAgent(params)
            .then(res => {
                setListData(res?.results)
                setIsLoading(false)
                if (Array.isArray(res?.results) && res?.results.length > 0) {
                    getDetailDataAgent({
                        id : res?.results[0].user_id, 
                        role : res?.results[0].role, 
                        kind : res?.results[0].kind, 
                        period : res?.results[0].period, 
                        semester : res?.results[0].semester 
                    })
                } else {
                    getDetailDataAgent(false)
                }
            })
            .catch((err) => {
                toast.error(err)
            })
    }

    const getDataWorkUnit = (data) => {
        setIsLoading(true)
        const params = {
            level : data?.level ? data.level : 0,
            period : data?.period ? data.period : '2024',
            parent_id : data?.parent_id ? data.parent_id : null,
            semester : data?.semester ? data.semester : 0,
            keyword : data?.keyword ? data.keyword : null,
        }

        PerformanceAPI.getPerformanceWorkunit(params)
            .then(res => {
                // console.log(res)
                setListData(res?.results)
                setIsLoading(false)
                if (Array.isArray(res?.results) && res?.results.length > 0) {
                    getDetailDataWorkUnit({
                        id : res?.results[0]?.work_unit_id, 
                        period : res?.results[0]?.period,  
                        semester : res?.results[0]?.semester,
                        level : res?.results[0]?.level
                    })
                } else {
                    getDetailDataWorkUnit(false)
                }
            })
            .catch((err) => {
                toast.error(err)
            })
    }

    const getDataSeasonWinner = (data) => {
        setLoadWinner(true)
        const params = {
            role : data?.role ? data.role : 0,
            kind : data?.kind ? data.kind : 'national',
            period : data?.period ? data.period : '2024',
            semester : data?.semester ? data.semester : 0,
        }

        PerformanceAPI.getPerformanceAgent(params)
            .then(res => {
                setListPerformance(res?.results)
                setLoadWinner(false)

            })
            .catch((err) => {
                toast.error(err)
            })
    }

    const getWorkunitSeasonWinner = (data) => {
        setLoadWinner(true)
        const params = {
            level : data?.level ? data.level : 0,
            period : data?.period ? data.period : '2024',
            semester : data?.semester ? data.semester : 0,
        }

        PerformanceAPI.getPerformanceWorkunit(params)
            .then(res => {
                setListPerformance(res?.results)
                setLoadWinner(false)
            })
            .catch((err) => {
                toast.error(err)
            })
    }

    const ReducePerformancePoint = (id, point, data) => {

        let formData = {}

        if (id.user_id) {
            formData = {
                user_id       : id.user_id,
                point         : parseInt(point),
                note          : `Pengurangan Point `,
                is_deduction  : true
            }
        } else {
            formData = {
                work_unit_id  : id.work_unit_id,
                point         : parseInt(point),
                note          : `Pengurangan Point`,
                is_deduction  : true
            }
        }

        PerformanceAPI.reducePerformancePoint(formData)
            .then((res) => {
                toast.success('Point Berhasil Di Kurangi')
                // if (activeTab === 'agent') {
                //     getDataAgent({period : selectYear, semester : selectSemester.value})
                // } else {
                //     getDataWorkUnit({period : selectYear,  semester : selectSemester.value})
                // }
            })
    }

    const getLogPointPersonal = (data) => {
            setLogPoint([])
            const params = {
                page : data?.page ? data.page : 1,
                user_id : data?.id ? data.id : 1,
                period : data?.period ? data.period : '2024',
                semester : data?.semester ? data.semester : 0,
            }
    
            PerformanceAPI.getLogPointPersonal(params)
                .then(res => {
                    if (res?.results?.length > 0) {

                        const total_points = res?.results[0]?.performance_point_total

                        const groups = res?.results?.reduce((groups, data) => {
                            const date = data.created_at.split('T')[0]
                            if (!groups[date]) {
                                groups[date] = []
                            }
                            groups[date].push(data)
                            return groups
                        }, {})
         
                        const groupArrays = Object.keys(groups).map((date) => {
                            return {
                                date,
                                data: groups[date]
                            }
                        })
        
                        const result = {
                            total_point : total_points,
                            data        : groupArrays,
                        }
                        setLogPoint(result)
                    } else {
                        setLogPoint([])
                    }
                    setLogPointPaginate({
                        page : res.page,
                        page_size : res.page_size,
                        total_page : res.total_page,
                        next : res.next,
                        previous : res.previous,
                        count : res.count
                    })
                })
                
                .catch((err) => {
                    toast.error(err)
                })
    }

    const getLogPointWorkunit = (data) => {
        const params = {
            page : data?.page ? data.page : 1,
            work_unit_id : data?.id ? data.id : 1,
            period : data?.period ? data.period : '2024',
            semester : data?.semester ? data.semester : 0,
            condition : data?.condition ? data.condition : null,
            user_groups : data?.user_groups ? data?.user_groups : "1,4"
        }

        PerformanceAPI.getLogPointWorkunit(params)
            .then(res => {
                setLogPoint(res)
                setLogPointPaginate({
                    page : res.page,
                    page_size : res.page_size,
                    total_page : res.total_page,
                    next : res.next,
                    previous : res.previous,
                    count : res.count
                })
            })
            
            .catch((err) => {
                toast.error(err)
            })
}
    return <PerformanceContext.Provider
        value={{
            // State
            logPoint,
            setLogPoint,
            
            logPointPaginate,

            listData,
            listPerformance,

            selectYear, 
            setSelectYear,

            detailWinner,

            isLoading,
            loadWinner,
            detailLoading,

            activeTab, 
            setActiveTab,

            selectUserRanking, 
            setSelectUserRanking,

            selectUser,
            setSelectUser,

            selectLevel, 
            setSelectLevel,

            selectKind, 
            setSelectKind,

            selectSemester, 
            setSelectSemester,
            
            selectWorkunit, 
            setSelectWorkunit,

            selectSemesterLogPoint, 
            setSelectSemesterLogPoint,

            selectYearLogPoint, 
            setSelectYearLogPoint,

            //Function
            imageHandle,
            getDataAgent,
            getLogPointWorkunit,
            getDataWorkUnit,
            getDetailDataAgent,
            getLogPointPersonal,
            getDataSeasonWinner,
            getDetailDataWorkUnit,
            getWorkunitSeasonWinner,

            ReducePerformancePoint,

        }} >{children}</PerformanceContext.Provider>
}

export { PerformanceContext, PerformanceProvider }

