import { 
    Card, 
    CardBody, 
    CardFooter, 
    CardHeader,
    Spinner, 
}                           from "reactstrap"

import { 
  useContext, 
  useEffect, 
  useState
 }                          from "react"
 
import { PositionContext }  from "../../../context/positionContext"
import OrganizationChart    from "../../../@core/components/org-chart"
// import OrganizationChart from "@dabeng/react-orgchart"

const OrganizationStructureCard = ({
    id,
    type
}) => {
    
    const {
      getDataOrganization
    } = useContext(PositionContext)

    const [data, setData]       = useState([])
    const [loading, setLoading] = useState(true)

    const getTitle = (id) => {
      let title = "-"

      id = parseInt(id)
      if (id === 1) {
        title = "KEJAKSAAN AGUNG"
      } else if (id === 2) {
        title = "KEJAKSAAN TINGGI"
      } else if (id === 3) {
        title = "KEJAKSAAN NEGERI"
      } else if (id === 4) {
        title = "CABANG KEJAKSAAN NEGERI"
      }

      return title
    }

    useEffect(() => {
      if (id !== undefined) {
        getDataOrganization(id, setData, type, setLoading)
      }
    }, [id])

    return (
      <Card className="">
        <CardHeader className="p-1">
            <div className="col-12 text-center p-0">
              <strong>STRUKTUR ORGANISASI <br/> {getTitle(id)}</strong>
            </div>
        </CardHeader>
        <CardBody className="p-0 text-center">
          {
            loading ? (
              <div
                className="p-5"
              >
                <Spinner
                  color = "primary"
                /> <br/>
                Memuat Data...
              </div>
            ) : (
              <OrganizationChart
                draggable   = {true}
                datasource  = {data}
                zoominLimit = {5}
              />
            )
          }
          
        </CardBody>
        <CardFooter 
          className = "text-center border-0 p-1"
        >
            Berikut Merupakan Struktur Organisasi milik <br/>
            {getTitle(id)}
        </CardFooter>
      </Card>
    )
}

export default OrganizationStructureCard