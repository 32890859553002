import React                from 'react'

import { 
    Input, 
    Button, 
    DropdownItem, 
    DropdownMenu, 
    DropdownToggle, 
    UncontrolledDropdown, 
    Row,
    Col
}                           from 'reactstrap'

import { 
    Plus,
    File, 
    Grid, 
    List, 
    Filter, 
    Folder, 
}                           from 'react-feather'

const DataManagementTop = ({
    layout,
    addFile,
    getDrive,
    setLayout,
    addFolder,
    setShowFolder,
    setSelectDrive,
    setShowSroting,
    toggleSidebar
    
}) => {
  return (
            <Row>
                <Col  xs={4} className='d-flex'>
                    <Button 
                        color       = 'primary'
                        hidden 
                        onClick     = {toggleSidebar}
                        className   = "sidebar-toggle me-1 btn-icon" 
                    >
                        ☰
                    </Button>
                    <UncontrolledDropdown>
                        <DropdownToggle 
                            id    = "add-data"
                            size  = 'sm'
                            color = 'primary' 
                        >
                            <Plus/>
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem 
                                tag     = 'a'
                                href    = '#' 
                                onClick = {() => { 
                                    setShowFolder(true) 
                                    setSelectDrive(null)
                                }} 
                            >
                                <Folder 
                                    size    = {16} 
                                    style   = {{ marginRight: "0.5em" }}
                                />
                                Folder Baru
                            </DropdownItem>
                            <hr />
                            <Button.Ripple 
                                tag     = 'label' 
                                color   = "flat-primary" 
                                style   = {{ backgroundColor: "transparent !important", padding : '15px 10px 15px 20px' }}
                            >
                                <Input 
                                    type        = 'file' 
                                    hidden 
                                    onChange    = {(e) => addFile(e.target.files) }
                                />
                                <File 
                                    size    = {16} 
                                    style   = {{ marginRight: "0.5em", marginLeft: "-6px" }}
                                />Unggah File
                            </Button.Ripple>                                   
                            <Button.Ripple 
                                tag     = 'label' 
                                color   = "flat-primary" 
                                style   = {{ backgroundColor: "transparent !important", padding : '15px 10px 15px 15px' }}
                            >
                                <Input 
                                    id              = "upload-folder" 
                                    type            = 'file' 
                                    hidden 
                                    onChange        = {(e) => addFolder(e.target.files) }
                                    directory       = "" 
                                    webkitdirectory = "" 
                                />
                                <Folder 
                                    size    = {16} 
                                    style   = {{ marginRight: "0.5em", marginLeft: "-6px" }}
                                />Unggah Folder
                            </Button.Ripple>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </Col>
                <Col  xs={6}>
                     <Input
                        className='round '
                        placeholder='Search...'
                        onChange={(e) => getDrive(`/?keyword=${e.target.value}`)}
                    />
                </Col>
                <Col  xs={2}>
                    <div className="d-flex justify-content-end">
                        <Button
                            size            = "sm"
                            color           = "primary"
                            className       = "btn-icon me-1"
                            onClick         = {() => {
                                setShowSroting(true)
                            }}
                        >
                            <Filter

                            />
                        </Button>
                        {
                            layout === "grid" ? 
                                <Button
                                    size            = "sm"
                                    color           = "primary"
                                    className       = "btn-icon me-1"
                                    onClick         = {() => {
                                        setLayout("list")
                                    }}
                                >
                                    <List

                                    />
                                </Button>
                            :
                                <Button
                                    size            = "sm"
                                    color           = "primary"
                                    className       = "btn-icon"
                                    onClick         = {() => {
                                        setLayout("grid")
                                    }}
                                >
                                    <Grid

                                    />
                                </Button>
                        }

                    </div>
                </Col>
            </Row>
            
  )
}

export default DataManagementTop
