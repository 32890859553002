import { 
    Button,
    Form,
    FormFeedback,
    FormGroup,
    Input,
    InputGroup, 
    Label, 
    Media, 
    Modal, 
    ModalBody, 
    ModalFooter, 
    ModalHeader 
}                           from "reactstrap"

import { 
    Controller, 
    useForm 
}                           from "react-hook-form"

import { 
    useContext, 
    useEffect, 
    useRef, 
    useState 
}                           from "react"

import {
    UserManagementSchema,
    UserManagementSchemaNonDirChild 
}                           from "./validation"

import Select               from 'react-select'
import SelectSector 	    from "@components/select-sector"
import SelectBidang 		from "@components/select-bidang"
import SelectSubdirektorat  from "@components/select-subdirektorat"
import SelectSeksi          from "@components/select-seksi"

import { SectorContext }    from "../../../../context/sectorContext"
import { WorkunitContext }  from "../../../../context/workunitContext"
import { PositionContext }  from "../../../../context/positionContext"
import { GradeContext }     from "../../../../context/gradeContext"
import { ClassesContext }   from "../../../../context/classesContext"
import { RoleContext }      from "../../../../context/roleContext"
import { yupResolver }      from "@hookform/resolvers/yup"
import userManagementAPI    from "../../../../service/pages/user-management"
import toast                from "react-hot-toast"

const UserManagementCreate = ({
    show,
    setShow,
    title,
    size,
    data,
    handleCreate
}) => {

    const fileInputRef = useRef(null)

    const [isShowModalAccount, setIsShowModalAccount]       = useState(null)
    const [showDirektoratChild, setShowDirektoratChild]     = useState(true)

    const [profilePicture, setProfilePicture]               = useState(null)
    const [profilePictureBase64, setProfilePictureBase64]   = useState(null)
    const [validation, setValidation]                       = useState(null)
    const [positionFiltered, setPositionFiltered]           = useState([])
    
    const [isEditableAccount, setIsEditableAccount]         = useState(false)
    const [account, setAccount]                             = useState({
        username  : "",
        password1 : "",
        password2 : ""
    })

    const [errAccount, setErrAccount]                       = useState({
        username  : "",
        password1 : "",
        password2 : ""
    })

    const {
		reset,
        watch,
        getValues,
		control,
		setValue,
		setError,
		clearErrors,
		handleSubmit,
		formState: { errors },
	} = useForm({
		mode        : "onChange",
		resolver    : yupResolver(
            showDirektoratChild ? UserManagementSchema : UserManagementSchemaNonDirChild
        ),
    })

    const {
        roleOption
    } = useContext(RoleContext)

    const {
        sectorOption
    } = useContext(SectorContext)

    const {
        gradeOption
    } = useContext(GradeContext)

    const {
        classesOption
    } = useContext(ClassesContext)

    const {
        workunitOption
    } = useContext(WorkunitContext)

    const {
        positionOption
    } = useContext(PositionContext)

    const handleSetUserPass = (new_data) => {
        setAccount({
            ...new_data
        })
    }

    const handleFileSelect = () => {
        fileInputRef.current.click()
    }

    const removeSymbols = (e) => {
        const str       = e.target.value
        const regex     = /[^a-zA-Z.,\s]/g
        const temp      = str.replace(regex, '')
        return temp
    }

    const handleCheckUsername = async () => {
        let _validation = null

        if (
            account.username  === "" ||
            account.password1 === "" ||
            account.password2 === ""
        ) {

            const err = errAccount

            if (account.username === "") {
                err.username = "Username Wajib Diisi!"
            } 

            if (account.password1 === "") {
                err.password1 = "Password Wajib Diisi!"
            }
            
            if (account.password2 === "") {
                err.password2 = "Password Wajib Diisi!"
            }

            _validation = false
            setErrAccount({
                ...err
            })
        } else {
            if (account.password1?.length < 8) {
                _validation = false
                setErrAccount({
                    ...errAccount,
                    password1 : "Minimal jumlah karakter adalah 8!"
                })

            } else if (account.password1 !== account.password2) {
                _validation = false
                setErrAccount({
                    ...errAccount,
                    password2 : "Password Tidak Sama!"
                })

            } else if (account?.username) {
                await userManagementAPI.checkUsername({
                    username : account?.username
                })
                .then((res) => {
                    if (res?.results?.length > 0) {
                        _validation = false
                        setErrAccount({
                            ...errAccount,
                            username : "Username Telah Digunakan!"
                        })
                    } else {
                        _validation = true
                        setErrAccount({
                            ...errAccount,
                            username : ""
                        })
                        setIsShowModalAccount(false)
                    }
                })
            }
        }

        if (_validation !== null) {
            setValidation(_validation)
        }

    }

    const onSubmit = (data) => {
        if (validation) {
            handleCreate(data, account, profilePicture)
        } else {
            handleCheckUsername()
            setIsShowModalAccount(true)
        }
    }

    useEffect(() => {
        if (!isShowModalAccount) {
            if (validation !== null) {
                handleCheckUsername()
            }
        }

        setTimeout(() => {
            setIsEditableAccount(isShowModalAccount)
        }, 500)

    }, [isShowModalAccount])

    useEffect(() => {
        // if (!show) {
            reset()
            setProfilePictureBase64(null)
            setProfilePicture(null)
            setAccount({
                username  : "",
                password1 : "",
                password2 : ""
            })

            setErrAccount({
                username  : "",
                password1 : "",
                password2 : ""
            })
        // }
    }, [show])

    useEffect(() => {
        setTimeout(() => {
            setAccount({
                username  : "",
                password1 : "",
                password2 : ""
            })
        }, 1000)
    }, [])

    useEffect(() => {
        const temp = positionOption.filter((dt) => dt.level === watch('workunit')?.level)
        setPositionFiltered(temp)
        setValue("position", null)
        setTimeout(() => {
            setValue("position", undefined)
        }, 500)
    }, [watch('workunit')])
    
    return (
        <>
            <Modal
                isOpen  = {show} 
                toggle  = {() => { setShow(!show) }} 
                size    = {size || "sm"}
            >
                <ModalHeader 
                    toggle={() => setShow(!show)} 
                > 
                    {title}
                </ModalHeader>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <ModalBody>
                        <div>
                            <div
                                className="d-flex col-12 p-0 m-0"
                            >
                                <div
                                    className="col-6 pe-1 p-0 m-0"
                                >
                                    <div
                                        className   = "mb-1 m-0 p-0"
                                    >
                                        <Label>Foto Profil</Label>

                                        <div
                                            className = ""
                                        >
                                            <img
                                                src         = {profilePictureBase64 ? profilePictureBase64 : `https://ui-avatars.com/api/?name=${getValues('name') ? watch("name") : "-"}&background=4e73df&color=fff&bold=true`}
                                                width       = "113"
                                                height      = "113"
                                                className   = "rounded mr-50"
                                            />

                                            <Button.Ripple
												color		= "primary"
                                                className   = "ms-1"
                                                onClick     = {handleFileSelect}
											>
							    				Pilih Foto
    										</Button.Ripple>

                                            <input
                                                type            = "file"
                                                accept          = "image/*"
                                                ref             = {fileInputRef}
                                                style           = {{ display: 'none' }}
                                                onChange        = {(e) => {
                                                    const selectedFile = e.target.files[0]
                                                    setProfilePicture(selectedFile)

                                                    const reader = new FileReader()
                                                    reader.onload = () => {
                                                        setProfilePictureBase64(reader.result)
                                                    }
                                                    reader.readAsDataURL(selectedFile)
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div
                                        className   = "mb-1"
                                    >
                                        <Label>NIP/NRP</Label>
                                        <Controller
                                            name        = "nip"
                                            control     = {control}
                                            render      = {({ field }) => (
                                                <Input
                                                    {...field} 
                                                    invalid             = {errors.nip && true}
                                                    placeholder         = "NIP/NRP"
                                                />
                                            )}
                                            defaultValue= {data?.nip}
                                        />
                                        {errors.nip && <small className="text-danger">{errors.nip.message}</small>}
                                    </div>
                                    {console.log("GETVALUES123", getValues())}
                                    <div
                                        className   = "mb-1"
                                    >
                                        <Label>Nama Lengkap</Label>
                                        <Controller
                                            name        = "name"
                                            control     = {control}
                                            render      = {({ field }) => (
                                                <Input
                                                    {...field} 
                                                    invalid             = {errors.name && true}
                                                    placeholder         = "Nama Lengkap"
                                                    defaultValue        = {data?.name}
                                                    onChange            = {(e) => {
                                                        const temp = removeSymbols(e)
                                                        field.onChange(temp)
                                                    }}     
                                                />
                                            )}
                                            defaultValue= {data?.name}
                                        />
                                        {errors.name && <small className="text-danger">{errors.name.message}</small>}
                                    </div>

                                    <div
                                        className   = "mb-1"
                                    >
                                        <Label>Pangkat</Label>
                                        <Controller
                                            name        = "grade"
                                            control     = {control}
                                            render      = {({ field }) => (
                                                <Select
                                                    {...field} 
                                                    className           = {`react-select ${errors.grade?.value && "is-invalid"}`}
                                                    classNamePrefix     = "select"
                                                    placeholder         = "Pangkat"
                                                    options             = {gradeOption}
                                                />
                                            )}
                                            defaultValue= {data?.grade}
                                        />
                                        {errors.grade && <small className="text-danger">{errors.grade?.value?.message}</small>}
                                    </div>

                                    <div
                                        className   = "mb-0"
                                    >
                                        <Label>Golongan</Label>
                                        <Controller
                                            name        = "class"
                                            control     = {control}
                                            render      = {({ field }) => (
                                                <Select
                                                    {...field} 
                                                    className           = {`react-select ${errors.class?.value && "is-invalid"}`}
                                                    classNamePrefix     = "select"
                                                    placeholder         = "Golongan"
                                                    options             = {classesOption}
                                                />
                                            )}
                                            defaultValue= {data?.class}
                                        />
                                        {errors.class && <small className="text-danger">{errors.class?.value?.message}</small>}
                                    </div>

                                </div>


                                <div
                                    className="col-6 ps-1"
                                >
                                    <div
                                        className   = "mb-1"
                                    >
                                        <Label>Satuan Kerja</Label>
                                        <Controller
                                            name        = "workunit"
                                            control     = {control}
                                            render      = {({ field }) => (
                                                <Select
                                                    {...field} 
                                                    className           = {`react-select ${errors.workunit?.value && "is-invalid"}`}
                                                    classNamePrefix     = "select"
                                                    placeholder         = "Satuan Kerja"
                                                    options             = {workunitOption}
                                                />
                                            )}
                                            defaultValue= {data?.workunit}
                                        />
                                        {errors.workunit && <small className="text-danger">{errors.workunit?.value?.message}</small>}
                                    </div>

                                    <div
                                        className   = "mb-1"
                                    >
                                        <Label>Jabatan <small className="text-primary"></small></Label>
                                        <Controller
                                            name        = "position"
                                            control     = {control}
                                            render      = {({ field }) => (
                                                <Select
                                                    {...field} 
                                                    className           = {`react-select ${errors.position?.value && "is-invalid"}`}
                                                    classNamePrefix     = "select"
                                                    placeholder         = "Jabatan"
                                                    options             = {positionFiltered}
                                                    isDisabled          = {!watch("workunit")}
                                                />
                                            )}
                                            defaultValue= {data?.position}
                                        />
                                        {errors.position && <small className="text-danger">{errors.position?.value?.message}</small>}
                                    </div>

                                    <div
                                        className   = "mb-1"
                                    >
                                        <Label>Privilage Role</Label>
                                        <Controller
                                            name        = "role"
                                            control     = {control}
                                            render      = {({ field }) => (
                                                <Select
                                                    {...field} 
                                                    className           = {`react-select ${errors.role?.value && "is-invalid"}`}
                                                    classNamePrefix     = "select"
                                                    placeholder         = "Privilage Role"
                                                    options             = {roleOption}
                                                />
                                            )}
                                            defaultValue= {data?.role}
                                        />
                                        {errors.role && <small className="text-danger">{errors.role?.value?.message}</small>}
                                    </div>

                                    <div
                                        className   = "mb-1"
                                    >
                                        <Label>Struktur/Bidang</Label>
                                        <Controller
                                            name        = "sector"
                                            control     = {control}
                                            render      = {({ field }) => (
                                                <Select
                                                    {...field} 
                                                    className           = {`react-select ${errors.sector?.value && "is-invalid"}`}
                                                    classNamePrefix     = "select"
                                                    placeholder         = "Struktur/Bidang"
                                                    options             = {sectorOption}
                                                />
                                            )}
                                            defaultValue= {data?.sector}
                                        />
                                        {errors.sector && <small className="text-danger">{errors.sector?.value?.message}</small>}
                                    </div>

                                    <div
                                        className   = "my-1"
                                    >
                                        <Label>No. Telepon <small className="text-primary">(Harus Terhubung Dengan Whatsapp)</small></Label>
                                        <Controller
                                            name        = "phone_number"
                                            control     = {control}
                                            render      = {({ field }) => (
                                                <Input
                                                    {...field} 
                                                    invalid             = {errors.phone_number && true}
                                                    placeholder         = "No. Telepon"
                                                    defaultValue        = {data?.name}
                                                    tye
                                                />
                                            )}
                                            defaultValue= {data?.phone_number}
                                        />
                                        {errors.phone_number && <small className="text-danger">{errors.phone_number.message}</small>}
                                    </div>
                                    
                                    <div
                                        className   = "mb-0"
                                    >
                                        <Label>Surel</Label>
                                        <Controller
                                            name        = "email"
                                            control     = {control}
                                            render      = {({ field }) => (
                                                <Input
                                                    {...field} 
                                                    invalid             = {errors.email && true}
                                                    placeholder         = "Surel"
                                                    defaultValue        = {data?.name}
                                                />
                                            )}
                                            defaultValue= {data?.email}
                                        />
                                        {errors.email && <small className="text-danger">{errors.email.message}</small>}
                                    </div>
                                </div>
                            </div>

                            <div
                                className="mt-1 text-dark"
                            >
                                <Controller
                                    name        = "is_superuser"
                                    control     = {control}
                                    render      = {({ field }) => (
                                        <Input
                                            {...field} 
                                            invalid     = {errors.address && true}
                                            type        = "checkbox"
                                            className   = "me-1 cursor-pointer"
                                        />
                                    )}
                                />
                                <Label>Jadikan Super Admin?</Label>
                            </div>
                            
                            <div className="my-1">
								<Label className="form-label" for="bidang">
							    	Pilih Direktorat
								</Label>
								<Controller
									id		= "bidang"
									name	= "bidang"
									render	= {({ field }) => (
										<SelectBidang
											field		= {field}
											onChange    = {(bidang) => { 
                                                field.onChange(bidang.value) 
                                                if (bidang.label === "Sekretariat Jaksa Agung Muda Bidang Intelijen") {
                                                    setShowDirektoratChild(false)
                                                } else {
                                                    setShowDirektoratChild(true)
                                                }
                                            }}
											className   = {`react-select ${errors.bidang && "is-invalid"}`}
                                            placeholder = "Pilih Direktorat"
                                            show_jam    = {true}
										/>
									)}
									control ={control}
								/>
								{errors.bidang && (<small className="text-danger">{errors.bidang.message}</small>)}
							</div>

                            {
                                showDirektoratChild &&
                                    <>
                                        <div className="my-1">
                                            <Label className="form-label" for="subdirektorat">
                                                Pilih Subdirektorat
                                            </Label>
                                            <Controller
                                                id		= "subdirektorat"
                                                name	= "subdirektorat"
                                                render	= {({ field }) => (
                                                    <SelectSubdirektorat
                                                        field	   = {field}
                                                        bidangId   = {watch('bidang')}
                                                        onChange   = {(subdirektorat) => { field.onChange(subdirektorat.value) }}
                                                        className  = {`react-select ${errors.subdirektorat && "is-invalid"}`}
                                                    />
                                                )}
                                                control = {control}
                                            />
                                            {errors.subdirektorat && (<small className="text-danger">{errors.subdirektorat.message}</small>)}
                                        </div>

                                        <div className="my-1">
                                            <Label className="form-label" for="seksi">
                                                Pilih Seksi
                                            </Label>
                                            <Controller
                                                id		= "seksi"
                                                name	= "seksi"
                                                render	= {({ field }) => (
                                                    <SelectSeksi
                                                        field	            = {field}
                                                        subdirektoratId	    = {watch('subdirektorat')}
                                                        onChange            = {(seksi) => { field.onChange(seksi.value) }}
                                                        className           = {`react-select ${errors.seksi && "is-invalid"}`}
                                                    />
                                                )}
                                                control ={control}
                                            />
                                            {errors.seksi && (<small className="text-danger">{errors.seksi.message}</small>)}
                                        </div>

                                        <div className="my-1">
                                            <Label className="form-label" for="sector">
                                                Pilih Sektor
                                            </Label>
                                            <Controller
                                                defaultValue= ""
                                                control     = {control}
                                                id          = "sektor"
                                                name        = "sektor"
                                                render      ={({ field }) => (
                                                    <SelectSector
                                                        field		= {field}
                                                        seksiId	    = {watch('seksi')}
                                                        onChange 	= {(sector) => { field.onChange(sector.value) }}
                                                        className	= {`react-select ${errors.sektor && "is-invalid"}`}
                                                    />
                                                )}
                                            />
                                            {errors.sektor && (<small className="text-danger">Isian Tidak Boleh Kosong</small>)}
                                        </div>
                                    </>
                            }
                            
                            <div
                                className   = "mb-0"
                                >
                                <Label>Alamat</Label>
                                <Controller
                                    name        = "address"
                                    control     = {control}
                                    render      = {({ field }) => (
                                        <Input
                                            {...field} 
                                            invalid             = {errors.address && true}
                                            type                = "textarea"
                                            placeholder         = "Alamat"
                                        />
                                    )}
                                    defaultValue= {data?.address}
                                />
                                {errors.address && <small className="text-danger">{errors.address.message}</small>}
                            </div>
                        </div>
                    </ModalBody>

                    <ModalFooter>
                        <div
                            className   = "d-flex justify-content-between col-12"
                        >
                            <Button
                                color   = "primary"
                                outline
                                onClick = {() => {
                                    setShow(false)
                                }}
                            >
                                Batal
                            </Button>

                            <Button
                                color   = "primary"
                                outline
                                onClick = {() => {
                                    setIsShowModalAccount(true)
                                }}
                            >
                                Buat Akun
                            </Button>

                            <Button
                                color   = "primary"
                                type    = "submit"
                            >
                                Simpan
                            </Button>
                        </div>
                    </ModalFooter>
                </Form>
            </Modal>

            <Modal
                isOpen  = {isShowModalAccount} 
                toggle  = {() => { handleCheckUsername() }} 
                size    = "sm"
                // backdrop="static" 
                // keyboard={false}
            >
                <ModalHeader 
                    toggle={() => setIsShowModalAccount(false)} 
                > 
                    Buat Akun
                </ModalHeader>
                <Form 
                    onSubmit        = {handleSubmit(handleCreate)}
                    autoComplete    = "off"
                >
                    <ModalBody>
                        <div
                            className   = "mb-1"
                        >
                            <Label>Username</Label>
                            <Input
                                placeholder         = "Username"
                                onChange={(e) => {
                                    const data = account
                                    data.username = `${e.target.value}`
                                    handleSetUserPass(data)

                                    if (e.target.value !== "") {
                                        setErrAccount({
                                            ...errAccount,
                                            username : ""
                                        })
                                    }
                                }}
                                // autoComplete        = {"new-username"}
                                autoComplete        = "off"
                                value               = {account.username}
                                readOnly            = {!isEditableAccount}
                            />
                            {errAccount.username !== "" && <small className="text-danger">{errAccount.username}</small>}

                        </div>
                        <div
                            className   = "mb-1"
                        >
                            <Label>Password</Label>
                            <Input
                                placeholder         = "Password"
                                type                = "password"
                                onChange            = {(e) => {
                                    const data = account
                                    data.password1 = `${e.target.value}`
                                    handleSetUserPass(data)

                                    if (e.target.value !== "") {
                                        setErrAccount({
                                            ...errAccount,
                                            password1 : ""
                                        })
                                    }
                                }}
                                // autoComplete        = {"new-password"}
                                autoComplete        = "off"
                                value               = {account.password1}
                                readOnly            = {!isEditableAccount}
                            />
                            {errAccount.password1 !== "" && <small className="text-danger">{errAccount.password1}</small>}
                        </div>

                        <div
                            className   = "mb-1"
                        >
                            <Label>Ulangi Password</Label>
                            <Input
                                placeholder         = "Ulangi Password"
                                type                = "password"
                                onChange={(e) => {
                                    const data = account
                                    data.password2 = `${e.target.value}`
                                    handleSetUserPass(data)

                                    if (e.target.value !== "") {
                                        setErrAccount({
                                            ...errAccount,
                                            password2 : ""
                                        })
                                    }
                                }}
                                // autoComplete        = {"new-password"}
                                value               = {account.password2}
                                readOnly            = {!isEditableAccount}
                            />
                            {errAccount.password2 !== "" && <small className="text-danger">{errAccount.password2}</small>}
                        </div>

                        <div>
                            <Button
                                color           = "primary"
                                className       = "col-12"
                                onClick         = {() => {
                                    handleCheckUsername()
                                }}
                            >
                                Simpan
                            </Button>
                        </div>
                    </ModalBody>
                </Form>
            </Modal>
        </>
    )
}

export default UserManagementCreate