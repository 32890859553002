import { useContext }           from 'react'

import { 
    Form, 
    Modal, 
    Label, 
    Input, 
    Button, 
    FormFeedback, 
    ModalBody, 
    ModalFooter, 
    ModalHeader 
}                               from 'reactstrap'

import toast                    from 'react-hot-toast'

import { 
    Controller, 
    useForm 
}                               from "react-hook-form"

import driveAPI                 from '../../service/pages/drive'

import { DataManagerContext }   from '../../context/dataManagerContext'

const ModalAddFolder = ({data, show, setShow}) => {

    const {
        active,
        folder,
        getDrive,
        setActive,
    } = useContext(DataManagerContext)

    const {
		control,
		handleSubmit,
		formState: { errors },
	} = useForm({
		mode        : "onChange",
    })

    const create = (data) => {

        const formData = {
            name    : data.folder,
            parent  : folder && folder.id ? active === 'share' ? folder.data.id : folder.id : null,
            is_root : false
        }

        driveAPI.createFolder(formData)
            .then((res) => {
                if (!res.is_error) {
                    toast.success('Folder Created!')
                    getDrive(folder ? `?dir=${folder.code ? folder.code : folder.data.code}` : null)
                    active === 'share' ? setActive('share') : setActive('all')
                    setShow(false)
                } else {
                    toast.error('Folder Not Created!')
                }
            }).catch((err) => {
                    toast.error(err.result.message)
            })

    }

    const update = (typeDrive, id, data) => {
        const formData = {
            name    : data.folder,
        }

        const type = typeDrive === 'file' ? 'files' : typeDrive 

        driveAPI.renameFolder(type, id, formData)
            .then((res) => {
                if (!res.is_error) {
                   getDrive(folder ? `?dir=${folder.code}` : null)
                   setShow(false)
                   toast.success('Folder Rename Successful!')
                } else {
                   toast.success('Folder Failed To Rename!')
                }
            }).catch((err) => {
                console.log(err)
            })
    }

    const onSubmit = dataForm => {
        if (data) {
            update(data.mime.data, data.code, dataForm)
        } else {
            create(dataForm)
        }
    }

  return (
    <Modal
        size    = 'sm'
        isOpen  = {show} 
    >
         <ModalHeader toggle={() => setShow(!show)} > 
            {data ? "Rename" : "Tambah"} Folder
        </ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSubmit(onSubmit)}>
            <div>
                <Label>Nama Folder</Label>
                <Controller
                    name        = "folder"
                    control     = {control}
                    render      = {({ field }) => (
                        <Input
                            {...field} 
                            invalid             = {errors.folder && true}
                            placeholder         = "Folder"
                            defaultValue        ={data ? data.name : null}
                        />
                    )}
                />
                {errors.folder && <FormFeedback>{errors.folder.message}</FormFeedback>}
            </div>
            <ModalFooter className="d-flex justify-content-between">
                <Button 
                    color   = "primary" 
                    outline 
                    onClick = {() => { 
                        setShow(!show)
                     }}
                >
                    Batal
                </Button>&nbsp;
                <Button type='submit' color='primary'>
                    Submit
                </Button>
            </ModalFooter>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default ModalAddFolder
