import { 
    useEffect, 
    useState,
    useContext 
}                                   from "react"
import { RoleContext }              from "../../../context/roleContext"
import RoleTable                    from "./component/RoleTable"
import RoleCreate                   from "./component/RoleCreate"
import RoleUpdate                   from "./component/RoleUpdate"
import ModalDelete                  from "../../other/ModalDelete"
import featureAPI                   from "../../../service/pages/feature"
import toast                        from "react-hot-toast"

const RoleConfiguration = () => {

    const [params, setParams]                             = useState({})
    const [isLoading, setIsLoading]                       = useState(null)

    const [selected, setSelected]                         = useState([])
    const [isCreateModalVisible, setIsCreateModalVisible] = useState(false)
    const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false)
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
    const [clearSelected, setClearSelected]               = useState(false)

    const {
        getData,
        pagination,
        roleData,
        setRoleData
    } = useContext(RoleContext)

    const handleDelete = () => {
        if (selected.length > 0) {
            selected.map((data) => (
                featureAPI.deleteRole(data.id).then(
                    () => {
                        toast.success("Role Berhasil Dihapus!")
                        getData(params)
                    }
                ).catch( 
                    err => {
                        console.log(err)
                    }
                )
            ))

            setSelected([])
        }
    }

    useEffect(() => {
        if (clearSelected) {
            setClearSelected(!clearSelected)
        }
    }, [clearSelected])

    useEffect(() => {
        if (!isUpdateModalVisible) {
            setSelected([])
            setClearSelected(true)
        }
    }, [isUpdateModalVisible])

    useEffect(() => {
        if (!isDeleteModalVisible) {
            setSelected([])
            setClearSelected(true)
        }
    }, [isDeleteModalVisible])

    return (
        <>
            <RoleTable
                data                    = {roleData}
                params                  = {params}
                setData                 = {setRoleData}
                getData                 = {getData}
                loading                 = {isLoading}
                selected                = {selected}
                setParams               = {setParams}
                pagination              = {pagination}
                setSelected             = {setSelected}
                clearSelected           = {clearSelected}
                setClearSelected        = {setClearSelected}
                setIsCreateModalVisible = {setIsCreateModalVisible}
                setIsUpdateModalVisible = {setIsUpdateModalVisible}
                setIsDeleteModalVisible = {setIsDeleteModalVisible}
            />

            <RoleCreate
                show        = {isCreateModalVisible}
                setShow     = {setIsCreateModalVisible}
                getData     = {getData}
                params      = {params}
                title       = "Tambah Role"
                size        = "xl"
            />

            <RoleUpdate
                show        = {isUpdateModalVisible}
                setShow     = {setIsUpdateModalVisible}
                getData     = {getData}
                params      = {params}
                title       = "Ubah Role"
                size        = "xl"
                data        = {selected?.length === 1 ? selected[0] : null}
            />

            <ModalDelete
                show        = {isDeleteModalVisible}
                setShow     = {setIsDeleteModalVisible}
                size        = "sm"
                title       = "Hapus Privilage Role"
                handleDelete= {handleDelete}
                data        = {selected}
            />
        </>
    )
}

export default RoleConfiguration