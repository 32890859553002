import { 
    useContext, 
    useEffect, 
    useState
}                                   from 'react'

import { 
    Button,
    Input,
    InputGroup,
    InputGroupText,
    Label,
    ListGroup, 
    ListGroupItem, 
    Modal, 
    ModalBody, 
    ModalFooter, 
    ModalHeader 
}                                   from 'reactstrap'

import toast                        from 'react-hot-toast'
import Avatar                       from '@components/avatar'
import { ChevronLeft, ChevronRight, ChevronsLeft, ChevronsRight, Search }                   from 'react-feather'
import CustomTableEmptyData         from '../../../@core/components/custom-table/empty'

// API
import chatAPI                      from '../../../service/pages/chat'

// Context
import { UserManagementContext }    from '../../../context/userManagementContext'

const SelectMultiUser = ({show, setShow, getList}) => {
    const {
        // State
        listData,
        pagination,

        // Function
        getData
    } = useContext(UserManagementContext)

    const userData =  JSON.parse(localStorage.getItem('userData'))

    const [keyword, setKeyword] = useState(null)
    const [grupName, setGrupName] = useState(false)
    const [userSelected, setUserSelected]   = useState([])


    const selectUser = id => {
        if (userSelected.indexOf(id) !== -1) {
            setUserSelected(userSelected.filter(opt => opt !== id))
        } else {
            setUserSelected([...userSelected, id])
        }
    }

    const onSubmit = () => {

        if (userSelected.length <= 1) {
            toast.error('Jumlah Kontak Harus Lebih Dari 1')
        } else {
             const formData = { 
                name : grupName,
                room_type : 2,
                members : userSelected.concat(userData.user_id)
            }
            chatAPI.createList(formData)
            .then(() => {
                setShow(false)
                toast.success('Berhasil Menambahkan Room')
                getList()
            })
            .catch(() => {
                toast.error('Gagal Menambahkan')
            })
        }
       
    }

    useEffect(() => {
        getData()
    }, [])

  return (
    <Modal
        isOpen={show}
        toggle={() => setShow(false)}

    >
        <ModalHeader toggle={() => setShow(false)}>
            Daftar User
        </ModalHeader>
            <ModalBody>
                <Label>Nama Group</Label>
                <Input 
                    type='text'
                    onChange={(e) => setGrupName(e.target.value)}
                />
                <InputGroup className='input-group-merge my-1'>
                    <InputGroupText>
                        <Search size={14} />
                    </InputGroupText>
                    <Input 
                        onChange    = {(e) => { setKeyword(e.target.value) }} 
                        placeholder = 'Masukkan Nama Pengguna ...' 
                    />
                    <Button 
                        color      = 'primary' 
                        outline
                        onClick    = {() => { getData({keyword : keyword}) }} 
                    >
                        Cari
                    </Button>
                </InputGroup>
                <div className = 'overflow-auto' style={{ height : '500px' }}>
                    <ListGroup>
                        {
                            Array.isArray(listData) && listData.map((item) => (
                                <ListGroupItem 
                                    key         = {item.id} 
                                    active      = {userSelected.indexOf(item.user_id) !== -1  } 
                                    onClick     = {() => { selectUser(item.user_id) }} 
                                    className   = "cursor-pointer" 
                                >
                                    <div className='d-flex align-items-center'>
                                        <div>
                                            <Avatar 
                                                className   = "me-1 p-0"
                                                size        = "md"
                                                img         = {item?.photo  === "" ? `https://ui-avatars.com/api/?name=${item ? item?.name : "UN"}&background=4e73df&color=fff&bold=true` : item?.photo}
                                            />
                                        </div>
                                        <div>
                                            <h5 className='fw-bolder'>{item.name}</h5>
                                            <h6 className='fw-bolder text-muted'>{item.work_unit?.name}</h6>
                                        </div>
                                    </div>
                                    
                                </ListGroupItem>
                            ))
                        }
                    </ListGroup>
                    {listData && listData.length === 0 && (
                        <CustomTableEmptyData />
                    )}
                </div>
                <div 
                style       = {{ right : 0, bottom : 0 }}
                className   = 'mt-1 d-flex justify-content-end'
            >
                
                {
                    pagination.previous ? (
                        <>
                            <div 
                                onClick     = {() => getData({ page : 1, keyword : keyword  })}
                                className   = 'cursor-pointer'
                            >
                                <ChevronsLeft  />
                            </div>
                            <div 
                                onClick     = {() => getData({ page:pagination.page - 1, keyword : keyword })}
                                className   = 'cursor-pointer'
                            >
                                <ChevronLeft  />
                            </div>
                        </>
                    ) : (
                        <>
                            <div className='text-muted'>
                                <ChevronsLeft  />
                            </div>
                            <div className=' text-muted'>
                                <ChevronLeft  />
                            </div>
                        </>
                    )
                }
                <div className='mx-4'>
                    <p>
                        {(pagination.page * pagination.page_size) - 9} - {' '} 
                        {pagination.count > 10 ? pagination.page_size * pagination.page : pagination.count} dari {' '}
                        {pagination.count}
                    </p>
                </div>
                {
                    pagination.next ? (
                        <>
                            <div 
                                onClick     = {() => getData({ page:pagination.page + 1, keyword : keyword  })}
                                className   = 'cursor-pointer'
                            >
                                <ChevronRight />
                            </div>
                            <div 
                                onClick     = {() => getData({ page: pagination?.total_page, keyword : keyword  })}
                                className   = 'cursor-pointer'
                            >
                                <ChevronsRight />
                            </div>
                        </>
                    ) : (
                        <>
                            <div className='text-muted'>
                                <ChevronRight />
                            </div>
                            <div className='text-muted'>
                                <ChevronsRight />
                            </div>
                        </>
                    ) 
                }
            </div>
            
            </ModalBody>
            <ModalFooter className='d-flex justify-content-between'>
                <Button
                    color='primary'
                    onClick={() => setShow(false)}
                >
                    Batal
                </Button>
                <Button
                    type    = 'submit'
                    color   = 'primary'
                    outline
                    onClick = {onSubmit}
                >
                    Submit</Button>
            </ModalFooter>
    </Modal>
  )
}

export default SelectMultiUser
