import { useEffect, useState }              from "react"
import { Nav, NavItem, NavLink }            from "reactstrap"
import { useParams }                        from "react-router-dom"

import UserManagementConfiguration          from "./user-management"
import RoleConfiguration                    from "./role"
import UserActivityConfiguration            from "./user-activity"
import WorkunitConfiguration                from "./workunit"
import RecyleBinConfiguration               from "./recycle-bin"
import EvaluationIndicatorConfiguration     from "./evaluation-indicator"
import PositionConfiguration                from "./position"
import UserManagementDetail                 from "./user-management/component/UserManagementDetail"
import WorkunitDetail                       from "./workunit/component/WorkunitDetail"

const ConfigurationDetail = () => {

    const params                = useParams()
    const type                  = params.type
    const id                    = params.id 
    const [active, setActive]   = useState(1)

    const renderContent = () => {
        if (active === 1) {
            return <UserManagementDetail id = {id} />
        } else if (active === 2) {
            return <RoleConfiguration />
        } else if (active === 3) {
            return <UserActivityConfiguration />
        } else if (active === 4) {
            return <PositionConfiguration />
        } else if (active === 5) {
            return <WorkunitDetail id = {id} />
        } else if (active === 6) {
            return <RecyleBinConfiguration />
        } else if (active === 7) {
            return <EvaluationIndicatorConfiguration />
        }
    }

    useEffect(() => {
        if (type === "user-management") {
            setActive(1)
        } else if (type === "role") {
            setActive(2)
        } else if (type === "log-activity") {
            setActive(3)
        } else if (type === "position") {
            setActive(4)
        } else if (type === "workunit") {
            setActive(5)
        } else if (type === "recycle-bin") {
            setActive(6)
        } else if (type === "evaluation-indicator") {
            setActive(7)
        }
    }, [type])

    return (
        <>
            {renderContent()}
        </>
    )
}

export default ConfigurationDetail