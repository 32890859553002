import CustomTableBody from "./body"
import CustomTableEmptyData from "./empty"
import CustomTableHeader from "./header"
import CustomTableLoading from "./loading"
import CustomTablePaginate from "./paginate"
import CustomTableSearch from "./search"

const CustomTable = {
    CustomTableHeader: CustomTableHeader,
    CustomTableBody: CustomTableBody,
    CustomTableSearch: CustomTableSearch,
    CustomTablePaginate : CustomTablePaginate,
    CustomTableLoading : CustomTableLoading,
    CustomTableEmptyData : CustomTableEmptyData 
}

export default CustomTable