import {
    Button,
    Card,
    CardBody
}                               from "reactstrap"

import { 
    Bar, 
    Doughnut 
}                               from 'react-chartjs-2'

import { 
    useContext, 
    useEffect, 
    useState 
}                               from "react"

import { WorkunitContext }      from "../../../context/workunitContext"
import { File, Printer }        from "react-feather"
import { Packer }               from "docx"
import moment                   from "moment"
import reportAPI                from "../../../service/report"
import PdfMakeRecapData         from "../../../@core/components/pdfmake-recap-data"
import DocxRecapData            from "../../../@core/components/docx-recap-data"

const tableHeaderProduct = [
    [
        {
            label 	: "NO",
            key     : "index",
            colSpan : 1,
            rowSpan : 1
        }, {
            label 	: "Laporan",
            colSpan : 1,
            rowSpan : 1
        }, {
            label 	: "Total",
            colSpan : 1,
            rowSpan : 1
        }
    ]
]

const tableHeaderReportDir = [
    [
        {
            label 	: "NO",
            key     : "index",
            colSpan : 1,
            rowSpan : 1
        }, {
            label 	: "Direktorat",
            colSpan : 1,
            rowSpan : 1
        }, {
            label 	: "Total",
            colSpan : 1,
            rowSpan : 1
        }
    ]
]

const RecapitulationDetailChart = (props) => {

    const {
        data,
        selected
    } = props

    const {
        workunitOption
    } = useContext(WorkunitContext)

    const [labelWorkunit, setLabelWorkunit] = useState("")

    const renderCardCount = (code, name, count) => {
        return (
            <>
                <Card
                    color="light-primary"
                    className="shadow-none m-0"
                    style={{
                        width: "200px"
                    }}
                >
                    <CardBody>
                        <div
                            className="text-dark h6"
                        >
                            {code} <br />
                            {name}
                        </div>
                        <div className="h1 text-primary"
                            style={{
                                fontSize: '40px'
                            }}
                        >
                            <strong>
                                {count}
                            </strong>
                        </div>
                    </CardBody>
                </Card>
            </>
        )
    }

    const labelsDonut    = [
        {
            id    : 2,
            label : "Direktorat A IPOLHANKAM",
            name  : "Bidang Ideologi, Politik, Pertahanan dan Keamanan"
        },
        {
            id    : 3,
            label : "Direktorat B SOSBUDMAS",
            name  : "Bidang Sosial, Budaya, dan Kemasyarakatan"
        },
        {
            id    : 4,
            label : "Direktorat C EKOEU",
            name  : "Bidang Ekonomi dan Keuangan"
        },
        {
            id    : 5,
            label : "Direktorat D PPS",
            name  : "Bidang Pengamanan Pembangunan Stategis"
        },
        {
            id    : 6,
            label : "Direktorat D TIKKPROIN",
            name  : "Bidang Teknologi Informasi dan Produksi Intelijen"
        },
    ]

    const listColor      = [
        `rgba(255, 99, 132, 1)`,
        `rgba(54, 162, 235, 1)`,
        `rgba(255, 206, 86, 1)`,
        `rgba(75, 192, 192, 1)`
    ]
    
    const optionsDonut   = {
        responsive  : true,
        plugins     : {
            legend: {
                display : false
            },
            title: {
                display: false
            }
        },
        scales: {
            x: {
                display : false
            },
            y: {
                display : false
            }
        }
    }

    const optionsBar  = {
        responsive  : true,
        plugins     : {
            legend: {
                display : false
            },
            title: {
                display: false
            }
        }
    }

    const [dataDonut, setDataDonut]     = useState({
        labels      : [],
        datasets    : []
    })

    const [dataBar, setDataBar]       = useState(
        {
            labels : [],
            datasets : []
        }
    )

    const getDataDirektorat = () => {
        const params = {
			source 		: 'bidang',
			pageSize 	: 100
		}

		reportAPI.getAdditionalData(params).then(
			(res) => {
				const labelsid   = []
				const labels     = []
				const datasets   = {
                    label: 'Jumlah Berkas Per Direktorat',
                    data: [],
                    backgroundColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)'
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)'
                    ],
                    borderWidth: 1
                }

                Object.keys(data?.report_count_per_dir).map((dt) => {
                    labelsid.push(dt)
                })

				if (res.results) {
                    labelsid.map((_id) => {
                        const label = res.results.find((dt) => dt.id === parseInt(_id))
                        if (label) {
                            labels.push(labelsDonut.find((dt) => dt.name === label?.name)?.label)
                        } else {
                            if (data?.report_count_per_dir[_id] > 0) {
                                labels.push(_id)
                            }
                        }
                    })

                    labelsid.map((_id) => {
                        const count = data?.report_count_per_dir[_id]
                        datasets?.data?.push(count)
                    })

                    setDataDonut({
                        labels   : labels,
                        datasets : [datasets]
                    })
				}
			}	
		)
    }

    const handlePrint = () => {
        const tableHeaderChart = [
			[
				{
					label 	: "-",
					colSpan : 1,
					rowSpan : 1
				},
			]
		]

        dataBar?.labels?.map((dt) => {
            tableHeaderChart[0].push({
                label 	: dt,
				colSpan : 1,
				rowSpan : 1
            })
        })

        const dataProduct = []
        {
            Object.keys((data?.report_count))?.map((key, idx) => {
                dataProduct.push([
                    {
                        label 	: idx + 1,
                        colSpan : 1,
                        rowSpan : 1
                    },
                    {
                        label 	: `${
                            key === "IN" ? "SURAT" : 
                                key === "L.IN" ? "LAPORAN" : 
                                    key === "R.IN" ? "REGISTER" :
                                        key === "D.IN" ? "DATA" : "-"}`,
                        colSpan : 1,
                        rowSpan : 1
                    },
                    {
                        label 	: data?.report_count[key],
                        colSpan : 1,
                        rowSpan : 1
                    }
                ])
            })
        }

        const dataReportDir = []
        dataDonut.labels?.map((dt, idx) => {
            dataReportDir.push([
                {
                    label 	: idx + 1,
                    colSpan : 1,
                    rowSpan : 1
                },
                {
                    label 	: `${dt}`,
                    colSpan : 1,
                    rowSpan : 1
                },
                {
                    label 	: dataDonut.datasets[0]?.data[idx],
                    colSpan : 1,
                    rowSpan : 1
                }
            ])
        })

        const dataChart = []
        dataBar?.datasets?.map((dt, idx) => {
            const temp = [
                {
                    label 	: `${dt.label}`,
                    colSpan : 1,
                    rowSpan : 1
                },
            ]

            dataBar?.labels?.map((dtLabel, idxLabel) => {
                temp.push({
                    label 	: dt.data[idxLabel],
                    colSpan : 1,
                    rowSpan : 1
                })
            })

            dataChart.push(temp)
        })

        console.log("dataBar", dataBar)

        const listTitle = [`REKAP DATA`.toUpperCase()]

        if (labelWorkunit && labelWorkunit !== "") {
            listTitle.push(labelWorkunit)
        }

        if (selected.start_date || selected.end_date) {
            listTitle.push(`PERIODE ${moment.unix(selected.start_date).format("DD MMMM YYYY")} s/d ${moment.unix(selected.end_date).format("DD MMMM YYYY")}`.toUpperCase())
        } else if (selected.month || selected.year) {
            listTitle.push(`BULAN ${moment(new Date(`${selected.year}-${selected.month}-01`)).format("MMMM YYYY")}`.toUpperCase())
        }

		PdfMakeRecapData(
            listTitle,
            [
                {
                    title  : "Jumlah Produk Intelijen".toUpperCase(),
                    header : tableHeaderProduct,
                    data   : dataProduct
                },
                {
                    title  : "Persentase Berkas Per Direktori".toUpperCase(),
                    header : tableHeaderReportDir,
                    data   : dataReportDir
                },
                {
                    title  : "Data Grafik Perkara".toUpperCase(),
                    header : tableHeaderChart,
                    data   : dataChart
                },
            ],
            selected?.title
		)
    }

    const handleExport = () => {

        const tableHeaderChart = [
			[
				{
					label 	: "-",
					colSpan : 1,
					rowSpan : 1
				},
			]
		]

        dataBar?.labels?.map((dt) => {
            tableHeaderChart[0].push({
                label 	: dt,
				colSpan : 1,
				rowSpan : 1
            })
        })

        const listTitle = [`REKAP DATA`.toUpperCase()]

        if (labelWorkunit && labelWorkunit !== "") {
            listTitle.push(labelWorkunit)
        }

        if (selected.start_date || selected.end_date) {
            listTitle.push(`PERIODE ${moment.unix(selected.start_date).format("DD MMMM YYYY")} s/d ${moment.unix(selected.end_date).format("DD MMMM YYYY")}`.toUpperCase())
        } else if (selected.month || selected.year) {
            listTitle.push(`BULAN ${moment(new Date(`${selected.year}-${selected.month}-01`)).format("MMMM YYYY")}`.toUpperCase())
        }

        const dataProduct = []
        {
            Object.keys((data?.report_count))?.map((key, idx) => {
                dataProduct.push([
                    {
                        label 	: idx + 1,
                        colSpan : 1,
                        rowSpan : 1
                    },
                    {
                        label 	: `${
                            key === "IN" ? "SURAT" : 
                                key === "L.IN" ? "LAPORAN" : 
                                    key === "R.IN" ? "REGISTER" :
                                        key === "D.IN" ? "DATA" : "-"}`,
                        colSpan : 1,
                        rowSpan : 1
                    },
                    {
                        label 	: data?.report_count[key],
                        colSpan : 1,
                        rowSpan : 1
                    }
                ])
            })
        }

        const dataReportDir = []
        dataDonut.labels?.map((dt, idx) => {
            dataReportDir.push([
                {
                    label 	: idx + 1,
                    colSpan : 1,
                    rowSpan : 1
                },
                {
                    label 	: `${dt}`,
                    colSpan : 1,
                    rowSpan : 1
                },
                {
                    label 	: dataDonut.datasets[0]?.data[idx],
                    colSpan : 1,
                    rowSpan : 1
                }
            ])
        })

        const dataChart = []
        dataBar?.datasets?.map((dt, idx) => {
            const temp = [
                {
                    label 	: `${dt.label}`,
                    colSpan : 1,
                    rowSpan : 1
                },
            ]

            dataBar?.labels?.map((dtLabel, idxLabel) => {
                temp.push({
                    label 	: dt.data[idxLabel],
                    colSpan : 1,
                    rowSpan : 1
                })
            })

            dataChart.push(temp)
        })

		const resultDocx = DocxRecapData(
            listTitle,
            [
                {
                    title  : "Jumlah Produk Intelijen".toUpperCase(),
                    header : tableHeaderProduct,
                    data   : dataProduct
                },
                {
                    title  : "Persentase Berkas Per Direktori".toUpperCase(),
                    header : tableHeaderReportDir,
                    data   : dataReportDir
                },
                {
                    title  : "Data Grafik Perkara".toUpperCase(),
                    header : tableHeaderChart,
                    data   : dataChart
                },
            ]
		)

		Packer.toBlob(resultDocx).then(blob => {
			saveAs(blob, `${selected?.title}.docx`)
		})
	}

    useEffect(() => {
        if (data) {
            const arrKey        = Object.keys(data.case_graph_data)

            if (arrKey && arrKey?.length > 0) {
                const arrLegend = Object.keys(data?.case_graph_data[arrKey[0]])
                const datasets  = []

                arrLegend?.map((dt, idx) => {
                    datasets.push({
                        label           : dt,
                        data            : [],
                        backgroundColor : listColor[idx],
                    })
                })

                arrKey?.map((key) => {
                    const legend = Object.keys(data.case_graph_data[key])

                    legend.map((dt) => {
                        const select = datasets.find((dtDataset) => dtDataset.label === dt)
                        select.data.push(data.case_graph_data[key][dt])
                    })
                })

                setDataBar({
                    labels   : arrKey,
                    datasets : datasets
                })
            }

            getDataDirektorat()
        }
    }, [data])

    useEffect(() => {
        if (selected && workunitOption) {
            const tempWorkunit = []

            const allWorkunit  = []
            workunitOption?.map((dtLevel) => {
                dtLevel.options?.map((dt) => {
                    allWorkunit.push(dt)
                })
            })

            if (workunitOption) {
                selected.work_unit_id?.map((dtWid) => {
                    const check = allWorkunit.find((dtWorkunit) => dtWorkunit.value === dtWid)
            
                    if (check) {
                        tempWorkunit.push(check)
                    }
                })
            }

            console.log("workunitOption", tempWorkunit)

            if (tempWorkunit) {
                let workunitName = ""
                tempWorkunit.map((dt, idx) => {
                    if (idx !== 0) {
                        workunitName += `, ${dt.label}`
                    } else {
                        workunitName += `${dt.label}`
                    }
            
                })
            
                if (workunitName !== "") {
                    setLabelWorkunit(workunitName)
                }
            }
        }
    }, [selected, workunitOption])

    return (
        <>
            <div
                className="p-2"
            >
                <div
                    className       = "d-flex justify-content-end"
                >
                    <Button
                        size            = "sm"
                        color           = "primary"
                        className       = "btn-icon ms-1"
                        disabled        = {!data}
                        onClick         = {() => {
                            handlePrint()
                        }}
                    >
                        <Printer

                        />
                    </Button>

                    <Button
                        size            = "sm"
                        color           = "primary"
                        className       = "btn-icon ms-1"
                        disabled        = {!data}
                        onClick         = {() => {
                            handleExport()
                        }}
                    >
                        <File

                        />
                    </Button>
                </div>
                <div
                    className="h5 text-center mb-4"
                >
                    <strong>REKAPITULASI DATA</strong> <br />
                    {
                        labelWorkunit &&
                            <>
                                <strong>{labelWorkunit}</strong> <br />
                            </>
                    }
                    <strong>
                        {
                            (selected.start_date || selected.end_date) ?
                                `PERIODE ${moment.unix(selected.start_date).format("DD MMMM YYYY")} s/d ${moment.unix(selected.end_date).format("DD MMMM YYYY")}`.toUpperCase()
                            : (selected.month || selected.year) ?
                                `BULAN ${moment(new Date(`${selected.year}-${selected.month}-01`)).format("MMMM YYYY")}`.toUpperCase()
                            : ""
                        }
                    </strong> 
                </div>

                <div
                    className="col-8 m-auto mb-5"
                >
                    <div
                        className="mb-5"
                    >
                        <div className="text-primary mb-1">
                            <strong>Jumlah Produk Intelijen</strong>
                        </div>

                        <div
                            className="d-flex justify-content-between text-center p-0 m-0"
                        >
                            {
                                data?.report_count &&
                                Object.keys((data?.report_count))?.map((key) => {
                                    return (
                                        renderCardCount(key, `${
                                                key === "IN" ? "SURAT" : 
                                                    key === "L.IN" ? "LAPORAN" : 
                                                        key === "R.IN" ? "REGISTER" :
                                                            key === "D.IN" ? "DATA" : "-"}`, data?.report_count[key])
                                    )
                                })
                            }
                        </div>
                    </div>
                    
                    <div
                        className   = "d-flex justify-content-between m-0 p-0"  
                    >
                        <div className="col-3 p-0 text-center">
                            <Doughnut 
                                data    = {dataDonut} 
                                options = {optionsDonut}
                                height  = {150}
                            />
                        </div>
                        <div className="col-8 border-start-dark ps-3">
                            <div className="h5 mb-2">
                                <strong>Persentase Berkas Per Direktori</strong>
                            </div>
                            <div>
                                {
                                    dataDonut.labels !== null &&
                                        dataDonut.labels?.map((dt, idx) => {
                                            return (
                                                <div
                                                    className="d-flex mb-1 justify-content-between border-bottom pb-1"
                                                >
                                                    <div className="d-flex me-2">
                                                        <div
                                                            className="me-1"
                                                            style      = {{
                                                                width  : '20px',
                                                                height : '20px',
                                                                backgroundColor : `${dataDonut.datasets[0]?.backgroundColor[idx]}`
                                                            }}
                                                        /> 
                                                        {dt}
                                                    </div>

                                                    <div>
                                                        <strong>{dataDonut.datasets[0]?.data[idx]}</strong>
                                                    </div>

                                                </div>
                                            )
                                        })
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className="col-8 m-auto"
                >
                    <div className="text-primary mb-1">
                        <strong>Data Grafik Perkara</strong>
                    </div>
                    
                    <div
                        className   = "d-flex justify-content-between m-0 p-0"  
                    >
                        <div className="col-9">
                            <Bar 
                                data    = {dataBar} 
                                options = {optionsBar}
                                height  = {80}
                            />
                        </div>
                        <div className="col-2 border-start-dark ps-2">
                            {
                                dataBar?.datasets !== null &&
                                    dataBar?.datasets?.map((dt, idx) => {
                                        return (
                                            <div
                                                className="d-flex justify-content-between mb-1 border-bottom pb-1"
                                            >
                                                <div
                                                    className   = "d-flex"
                                                >
                                                    <div
                                                        className="me-1"
                                                        style      = {{
                                                            width  : '20px',
                                                            height : '20px',
                                                            backgroundColor : `${dt?.backgroundColor}`
                                                        }}
                                                    /> {dt.label}
                                                </div>
                                            </div>
                                        )
                                    }
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RecapitulationDetailChart