import { 
    useContext,
    useEffect,
    useState 
}                           from "react"

import { 
    Button,
    Card, 
    CardBody,
    CardHeader,
    Nav,
    NavItem,
    NavLink,
}                           from "reactstrap"

import { 
    ProfileContext 
}                           from "../../../context/profileContext"

import { 
    ReportContext 
}                           from "../../../context/reportContext"

import { 
    Filter 
}                           from "react-feather"

import Select               from 'react-select'
import ProfileReportTable   from "./profile_report_table"
import HEADER_IN            from "./json/header_in"
import HEADER_LIN           from "./json/header_lin"
import HEADER_RIN           from "./json/header_rin"
import HEADER_DIN           from "./json/header_din"
import MasterModal          from "../../reports/components/masterModal"
import IcSorting            from "../../../assets/images/icons/svg/IcSorting"

const ProfileReport = ({
    userId
}) => {

    const [active, setActive]               = useState(1)
    const [activeReport, setActiveReport]   = useState(null)
    const [reportOption, setReportOption]   = useState([])
    const [finalHeader, setFinalHeader]     = useState([])

    const {
        reportTypeOption
    }                           = useContext(ProfileContext)

    const {
		hideColumn,
        setHideColumn,

        showModalFilter,
        setShowModalFilter,
	}                           = useContext(ReportContext)

    const tabs = [
        {
            id      : 1,
            name    : "IN"
        },
        {
            id      : 2,
            name    : "L.IN"
        }, 
        {
            id      : 3,
            name    : "R.IN"
        },
        {
            id      : 4,
            name    : "D.IN"
        },
    ]

    const toggle = tab => {
        if (active !== tab) {
            setActive(tab)
        }
    }

    const setHeader = (header) => {
        const _finalHeader = []
        header.map((dt, index) => {
            _finalHeader.push({
                ...dt,
                omit            : hideColumn[index] === false,
                compact         : true,
                sortable        : true,
            })
        })

        setFinalHeader(_finalHeader)

        setHideColumn(() => header.map(() => true))
    }

    const handleChangeHeader = (active) => {
        setHeader([])
        if (active === 1) {
            setHeader(HEADER_IN.IN_1)
        } else if (active === 2) {
            setHeader(HEADER_IN.IN_2)
        } else if (active === 3) {
            setHeader(HEADER_IN.IN_3)
        } else if (active === 4) {
            setHeader(HEADER_IN.IN_4)
        } else if (active === 5) {
            setHeader(HEADER_IN.IN_5)
        } else if (active === 6) {
            setHeader(HEADER_IN.IN_6)
        } else if (active === 7) {
            setHeader(HEADER_IN.IN_7)
        } else if (active === 8) {
            setHeader(HEADER_IN.IN_8)
        } else if (active === 9) {
            setHeader(HEADER_IN.IN_9)
        } else if (active === 10) {
            setHeader(HEADER_IN.IN_10)
        } else if (active === 11) {
            setHeader(HEADER_IN.IN_11)
        } else if (active === 12) {
            setHeader(HEADER_IN.IN_12)
        } else if (active === 13) {
            setHeader(HEADER_IN.IN_13)
        } else if (active === 14) {
            setHeader(HEADER_IN.IN_14)
        } else if (active === 15) {
            setHeader(HEADER_IN.IN_15)
        } else if (active === 16) {
            setHeader(HEADER_IN.IN_16)
        } else if (active === 17) {
            setHeader(HEADER_IN.IN_17)
        } else if (active === 18) {
            setHeader(HEADER_IN.IN_18)
        } else if (active === 19) {
            setHeader(HEADER_IN.IN_19)
        } else if (active === 20) {
            setHeader(HEADER_IN.IN_20)
        } else if (active === 21) {
            setHeader(HEADER_IN.IN_21)
        } else if (active === 22) {
            setHeader(HEADER_IN.IN_22)
        } else if (active === 23) {
            setHeader(HEADER_IN.IN_23)
        } else if (active === 24) {
            setHeader(HEADER_LIN.LIN_1)
        } else if (active === 25) {
            setHeader(HEADER_LIN.LIN_2)
        } else if (active === 26) {
            setHeader(HEADER_LIN.LIN_3)
        } else if (active === 27) {
            setHeader(HEADER_LIN.LIN_4)
        } else if (active === 28) {
            setHeader(HEADER_LIN.LIN_5)
        } else if (active === 29) {
            setHeader(HEADER_LIN.LIN_6)
        } else if (active === 30) {
            setHeader(HEADER_LIN.LIN_7)
        } else if (active === 31) {
            setHeader(HEADER_LIN.LIN_8)
        } else if (active === 32) {
            setHeader(HEADER_LIN.LIN_9)
        } else if (active === 33) {
            setHeader(HEADER_LIN.LIN_10)
        } else if (active === 34) {
            setHeader(HEADER_LIN.LIN_11)
        } else if (active === 35) {
            setHeader(HEADER_LIN.LIN_12)
        } else if (active === 36) {
            setHeader(HEADER_LIN.LIN_13)
        } else if (active === 37) {
            setHeader(HEADER_LIN.LIN_14)
        } else if (active === 38) {
            setHeader(HEADER_LIN.LIN_15)
        } else if (active === 39) {
            setHeader(HEADER_LIN.LIN_16)
        } else if (active === 40) {
            setHeader(HEADER_LIN.LIN_17)
        } else if (active === 41) {
            setHeader(HEADER_LIN.LIN_18)
        } else if (active === 42) {
            setHeader(HEADER_LIN.LIN_19)
        } else if (active === 43) {
            setHeader(HEADER_LIN.LIN_20)
        } else if (active === 44) {
            setHeader(HEADER_LIN.LIN_21)
        } else if (active === 45) {
            setHeader(HEADER_LIN.LIN_22)
        } else if (active === 46) {
            setHeader(HEADER_LIN.LIN_23)
        } else if (active === 47) {
            setHeader(HEADER_LIN.LIN_24)
        } else if (active === 48) {
            setHeader(HEADER_LIN.LIN_25)
        } else if (active === 49) {
            setHeader(HEADER_LIN.LIN_26)
        } else if (active === 50) {
            setHeader(HEADER_LIN.LIN_27)
        } else if (active === 51) {
            setHeader(HEADER_LIN.LIN_28)
        } else if (active === 52) {
            setHeader(HEADER_LIN.LIN_29)
        } else if (active === 53) {
            setHeader(HEADER_RIN.RIN_1)
        } else if (active === 54) {
            setHeader(HEADER_RIN.RIN_2)
        } else if (active === 55) {
            setHeader(HEADER_RIN.RIN_3)
        } else if (active === 56) {
            setHeader(HEADER_RIN.RIN_4)
        } else if (active === 57) {
            setHeader(HEADER_RIN.RIN_5)
        } else if (active === 58) {
            setHeader(HEADER_RIN.RIN_6)
        } else if (active === 59) {
            setHeader(HEADER_RIN.RIN_7)
        } else if (active === 60) {
            setHeader(HEADER_RIN.RIN_8)
        } else if (active === 61) {
            setHeader(HEADER_RIN.RIN_9)
        } else if (active === 62) {
            setHeader(HEADER_RIN.RIN_10)
        } else if (active === 63) {
            setHeader(HEADER_RIN.RIN_11)
        } else if (active === 64) {
            setHeader(HEADER_RIN.RIN_12)
        } else if (active === 65) {
            setHeader(HEADER_RIN.RIN_13)
        } else if (active === 66) {
            setHeader(HEADER_RIN.RIN_14)
        } else if (active === 67) {
            setHeader(HEADER_RIN.RIN_15)
        } else if (active === 68) {
            setHeader(HEADER_RIN.RIN_16)
        } else if (active === 69) {
            setHeader(HEADER_RIN.RIN_17)
        } else if (active === 70) {
            setHeader(HEADER_RIN.RIN_18)
        } else if (active === 71) {
            setHeader(HEADER_RIN.RIN_19)
        } else if (active === 72) {
            setHeader(HEADER_RIN.RIN_20)
        } else if (active === 73) {
            setHeader(HEADER_RIN.RIN_21)
        } else if (active === 74) {
            setHeader(HEADER_RIN.RIN_22)
        } else if (active === 75) {
            setHeader(HEADER_DIN.DIN_1)
        } else if (active === 76) {
            setHeader(HEADER_DIN.DIN_2)
        } else if (active === 77) {
            setHeader(HEADER_DIN.DIN_3)
        } else if (active === 78) {
            setHeader(HEADER_DIN.DIN_4)
        } else if (active === 79) {
            setHeader(HEADER_DIN.DIN_5)
        } else if (active === 80) {
            setHeader(HEADER_DIN.DIN_6)
        } else if (active === 81) {
            setHeader(HEADER_DIN.DIN_7)
        } else if (active === 82) {
            setHeader(HEADER_DIN.DIN_8)
        } else if (active === 83) {
            setHeader(HEADER_DIN.DIN_9)
        } else if (active === 84) {
            setHeader(HEADER_DIN.DIN_10)
        } else if (active === 85) {
            // setHeader(HEADER_DIN.DIN_11)
        } else if (active === 86) {
            setHeader(HEADER_DIN.DIN_12)
        } else if (active === 87) {
            setHeader(HEADER_DIN.DIN_13)
        } else if (active === 88) {
            setHeader(HEADER_DIN.DIN_14)
        } else if (active === 89) {
            setHeader(HEADER_DIN.DIN_15)
        } else if (active === 90) {
            setHeader(HEADER_DIN.DIN_16)
        }
    }

    useEffect(() => {
        if (active === 1) {
            setReportOption(reportTypeOption.IN)
            setActiveReport(reportTypeOption?.IN?.length > 0 ? reportTypeOption.IN[0] : null)
            setHeader(HEADER_IN.IN_1)
        } else if (active === 2) {
            setReportOption(reportTypeOption.LIN)
            setActiveReport(reportTypeOption?.LIN?.length > 0 ? reportTypeOption.LIN[0] : null)
            setHeader(HEADER_LIN.LIN_1)
        } else if (active === 3) {
            setReportOption(reportTypeOption.RIN)
            setActiveReport(reportTypeOption?.RIN?.length > 0 ? reportTypeOption.RIN[0] : null)
            setHeader(HEADER_RIN.RIN_1)
        } else if (active === 4) {
            setReportOption(reportTypeOption.DIN)
            setActiveReport(reportTypeOption?.DIN?.length > 0 ? reportTypeOption.DIN[0] : null)
            setHeader(HEADER_DIN.DIN_1)
        }
    }, [reportTypeOption, active])

    return (
        <>
            <Card
                className="col-12 h-100 bg-white rounded m-0"
            >
                <CardHeader>
                    <div
                        className   = "col-12 text-center"
                    >
                        <strong>Riwayat Berkas Yang Dikerjakan</strong>
                    </div>
                </CardHeader>

                <CardBody
                    className   = ""
                >
                     <Nav
                        className="border-bottom"
                        tabs fill
                    >
                        {
                            tabs?.map((item, index) => {
                                return (
                                    <NavItem key = {`configuration_tab_${index}`}>
                                        <NavLink
                                            active      = {active === item.id}
                                            onClick     = {() => {
                                                toggle(item.id)
                                            }}
                                        >
                                            {item.name}
                                        </NavLink>
                                    </NavItem>
                                )
                            })
                        }
                    </Nav>

                    <div
                        className   = "d-flex mb-1"
                    >
                        <div
                            className   = "col-8 d-flex"
                        >
                            <Button
                                size        = "sm"
                                color       = "primary"
                                className   = "btn-icon me-1"
                                onClick     = {() => {
                                    setShowModalFilter(true)
                                }}
                            >
                                <Filter 
                                        
                                />
                            </Button>

                            <Button
                                size        = "sm"
                                color       = "primary"
                                className   = "btn-icon me-1"
                                onClick     = {() => {
                                }}
                            >
                                <IcSorting />
                            </Button>
                        </div>

                        <div
                            className   = "col-4"
                        >
                            <Select
                                className           = "react-select"
                                classNamePrefix     = "select"
                                placeholder         = "Pilih Laporan"
                                options             = {reportOption}
                                value               = {activeReport}
                                onChange            = {(e) => {
                                    setActiveReport(e)
                                    handleChangeHeader(e.reportTypeId)
                                }}
                            />
                        </div>
                    </div>

                    <div>
                        <ProfileReportTable
                            reportData  = {activeReport}
                            header      = {finalHeader}
                            userId      = {userId}
                        />
                    </div>
                </CardBody>
            </Card>

            <MasterModal
				header          = {finalHeader}
				reportData      = {activeReport}
				// filteredField   = {["nomor_surat", "satuan_kerja", "created_at"]}
			/>
        </>
    )
}

export default ProfileReport