import { 
    Button, 
    ListGroup, 
    ListGroupItem, 
    Modal, 
    ModalBody, 
    ModalFooter, 
    ModalHeader 
} from "reactstrap"

const ModalRestore = ({
    show,
    setShow,
    size,
    title,
    handleDelete,
    data
}) => {
    return (
        <>
            <Modal
                isOpen  = {show} 
                toggle  = {() => { setShow(!show) }} 
                size    = {size || "sm"}
            >
                <ModalHeader
                    toggle={() => setShow(!show)} 
                > 
                    {title}
                </ModalHeader>
                <ModalBody>
                    <div className="text-center mb-1">
                        Yakin Ingin Mengembalikan Data ?
                    </div>
                    <div>
                        <ListGroup className="m-0">
                        {
                            data !== null && data?.length > 0 &&
                                data?.map((dt, idx) => {
                                    return (
                                        <ListGroupItem key={`moda_delete_list_${idx}`}>
                                            {idx + 1}. 
                                            {
                                                dt?.sumber_surat?.judul ? dt?.sumber_surat?.judul
                                                : dt?.judul ? dt?.judul
                                                : dt?.sumber_surat?.judul ? dt?.sumber_surat?.judul
                                                : dt?.judul ? dt?.judul : "-"
                                            }
                                        </ListGroupItem>
                                    )
                                })
                        }
                        </ListGroup>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div
                        className   = "d-flex justify-content-between col-12"
                    >
                        <Button
                            color   = "primary"
                            outline
                            onClick = {() => {
                                setShow(false)
                            }}
                        >
                            Batal
                        </Button>

                        <Button
                            color   = "primary"
                            onClick = {() => {
                                setShow(false)
                                handleDelete()
                            }}
                        >
                            Kembalikan
                        </Button>
                    </div>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default ModalRestore