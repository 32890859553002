import React from 'react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

const DoneTicket = ({ name, show, setShow, doneTicket}) => {
  return (
    <Modal
        isOpen = {show} 
        toggle = {() => setShow(false)}
        centered
        size='sm'
    >
        <ModalHeader toggle = {() => setShow(false)}>
            Ticket Selesai
        </ModalHeader>
        <ModalBody>
            Apakah Ticket {name} Telah Selesai 
        </ModalBody>
        <ModalFooter className='d-flex justify-content-between'>
            <Button outline onClick={() => setShow(false)}>Batal</Button>
            <Button color='primary' onClick={doneTicket}>Submit</Button>
        </ModalFooter>
      
    </Modal>
  )
}

export default DoneTicket
