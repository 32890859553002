import { useEffect, useState }          from "react"
import DataTablesGlobal                 from "../../../../@core/components/data-tables-global"
import ModalField 						from "../../../other/ModalField"
import ModalSorting 					from "../../../other/ModalSorting"
import DropdownTableRecyleBin 			from "../../../../@core/components/data-tables-global/dropdown_recycle_bin"

const RecyleBinTable = (props) => {

	const {
		data,
		params,
		getData,
		loading,
		setParams,
		pagination,
		setIsDeleteModalVisible,
		setIsRestoreModalVisible,

		selected,
		setSelected,
		clearSelected,
		setClearSelected
	} = props
    
    const [hideColumn, setHideColumn]        			    = useState([])
    const [isFilterModalVisible, setIsFilterModalVisible]   = useState(false) 
    const [isSortingModalVisible, setIsSortingModalVisible] = useState(false) 
	const [isHeaderConfigVisible, setIsHeaderConfigVisible] = useState(false) 
	
    const header        = [      
        {
			omit            : hideColumn[0] === false,
			name            : 'No',
            width           : '50px',
			center          : false,
			wrap            : true,
            compact         : true,
			cell            : row => (
                <>
                    {(row.index) + ((pagination?.page - 1) * pagination?.page_size)}
                </>
            ),
			sortable		: true
		},
		{
			omit            : hideColumn[1] === false,
			name            : 'Satuan Kerja',
			center          : false,
			wrap            : true,
            compact         : true,
			cell            : row => (
				<div
					className="text-start"
				>
					{row?.satuan_kerja}
				</div>
			),
			sortable		: true
		},
        {
			omit            : hideColumn[2] === false,
			name            : 'No Surat',
			center          : false,
			wrap            : true,
            compact         : true,
			cell            : row => (
				<div
					className="text-start"
				>
					{
						row?.sumber_surat?.judul ? row?.sumber_surat?.judul
						: row?.judul ? row?.judul
						: "-"
					}
				</div>
			),
			sortable		: true
		},
        {
			omit            : hideColumn[4] === false,
			name            : 'Judul',
			center          : false,
			wrap            : true,
            compact         : true,
			sortable		: true,
			cell            : row => (
				<div
					className="text-start"
				>
					{
						row?.sumber_surat?.judul ? row?.sumber_surat?.judul
						: row?.judul ? row?.judul
						: "-"
					}
				</div>
			),
		},
        {
			omit            : hideColumn[5] === false,
			name            : 'Status Dokumen',
			center          : false,
			wrap            : true,
            compact         : true,
			sortable		: true,
			
			cell            : row => (
				<div
					className="text-start"
				>
					{
						row?.is_draft ? "Draft" : "Terkirim"
					}
				</div>
			),
		},
        {
			omit            : hideColumn[6] === false,
			name            : 'Status Penyelesaian',
			center          : false,
			wrap            : true,
            compact         : true,
			cell            : row => (
				<div
					className="text-start"
				>
					{
						row?.last_track_status ? row?.last_track_status?.status
						: "-"
					}
				</div>
			),
			sortable		: true
		}, 
        {
			omit            : hideColumn[7] === false,
			name            : 'Dibuat Pada',
			center          : false,
			wrap            : true,
            compact         : true,
			selector        : row => row.created_at,
			sortable		: true
		}, 
        {
			omit            : hideColumn[8] === false,
			name            : 'Dibuat Oleh',
			center          : false,
			wrap            : true,
            compact         : true,
			cell            : row => (
				<div
					className="text-start"
				>
					{row?.created_by?.name}
				</div>
			),
			sortable		: true
		},
        {
			omit            : hideColumn[9] === false,
			name            : 'Aksi',
			center          : true,
			wrap            : true,
            compact         : true,
			cell            : row => (
                <>
                    <DropdownTableRecyleBin
                        data 				= {data}
						row 				= {row}
						showRestore 		= {setIsRestoreModalVisible}
						showDelete 	        = {setIsDeleteModalVisible}
						setSelected 		= {setSelected}
						setClearSelected 	= {setClearSelected}
                    />
                </>
            ),
			sortable		: true
		}
	]

    useEffect(() => {
		setHideColumn(() => header.map(() => true))
	}, [])

    return (
        <>
            <DataTablesGlobal
                data               		= {data}
                limit              		= {pagination?.page_size}
                header             		= {header}
                params             		= {params}
                getData            		= {getData}
                loading            		= {loading}
                selected           		= {selected}
                totalData          		= {pagination?.count}
                setParams          		= {setParams}
                setSelected        		= {setSelected}
                toggledClearRows   		= {clearSelected}
				setIsDeleteModalVisible = {setIsDeleteModalVisible}
				setIsRestoreModalVisible= {setIsRestoreModalVisible}
				setIsFilterModalVisible = {setIsFilterModalVisible}
				setIsSortingModalVisible= {setIsSortingModalVisible}
				setIsHeaderConfigVisible= {setIsHeaderConfigVisible}
                visibility  	   		= {{
                    filter          	: true,
                    sorting   			: false,
					delete_permanent    : true,
                    restore             : true,
					header_config 		: true,
					search				: true,
                }}
            />

			<ModalField
                show        	= {isHeaderConfigVisible}
                setShow     	= {setIsHeaderConfigVisible}
                size        	= "sm"
                title       	= "Select"
                data        	= {header}
				hideColumn  	= {hideColumn}
				setHideColumn 	= {setHideColumn}
            />

			<ModalSorting
                show        	= {isSortingModalVisible}
                setShow     	= {setIsSortingModalVisible}
                size        	= "sm"
                title       	= "Select"
                data        	= {header}
            />
        </>
    )
}

export default RecyleBinTable