import { 
    Button,
    Card, 
    CardBody, 
    CardFooter, 
    CardHeader,
    CardTitle,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Input,
    ListGroupItem,
    Offcanvas,
    OffcanvasBody,
    OffcanvasHeader,
    Row,
    Tooltip,
    UncontrolledDropdown
}                   from "reactstrap"

import Avatar       from '@components/avatar'
import { Archive, Edit, Edit2, ExternalLink, Home, MoreVertical, X } from "react-feather"
import SelectSingleUser from "./SelectSingleUser"
import { useContext, useEffect, useState } from "react"
import { ChatContext } from "../../../context/chatContext"
import SelectMultiUser from "./SelectMultiUser"
import moment from "moment"
import ListRoom from "./ListRoom"
import classNames from "classnames"
import InfiniteScroll from "react-infinite-scroll-component"

const ChatList = ({
    selected,
    setSelected,
    // handleViewUser
}) => {

    const {
        // State
        page,
        setPage,
        listData,
        message,
        setShowGroup,

        // Function
        getList,
        getMessage,
        deleteRoom,
        archiveChat,
        unArchiveChat,
        connectChatSocket
    } = useContext(ChatContext)

    const [openArchive, setOpenArchive]         = useState(false)
    const [roomTooltip, setRoomTooltip]         = useState(false)
    const [showMultiUser, setShowMultiUser]     = useState(false)
    const [showSingleUser, setShowSingleUser]   = useState(false)
    const [archiveTooltip, setArchiveTooltip]   = useState(false)


    useEffect(() => {
        getList(openArchive ? {is_archive:true} : {is_archive:false})
    }, [openArchive])

    useEffect(() => {
        if (selected) {
            getMessage(selected.uuid)    
        }
    }, [selected])

    return (
        <>
            <Card
                className="h-100  m-0"
            >
                <CardHeader
                    className="border-bottom p-1"
                >
                    <div className="col-12 d-flex justify-content-between">
                        <div className="h3">
                            <strong>{openArchive ? 'Archive' : 'Percakapan'}</strong>
                        </div>
                        <div className="d-flex justify-content-around align-items-center">
                            <a
                                size        = "sm"
                                color       = "white"
                                className   = "btn-icon m-0 p-0 ms-2"
                                href     = '/dashboard'
                            >
                                <Home  size={22} />
                            </a>
                            <UncontrolledDropdown >
                                <DropdownToggle tag='div' id="buttonRoom">
                                    <Tooltip
                                        placement='top'
                                        isOpen={roomTooltip}
                                        target='buttonRoom'
                                        toggle={() => setRoomTooltip(!roomTooltip)}
                                    >
                                        Buat Room
                                    </Tooltip>
                                    <Edit size={20} className="cursor-pointer mx-2" />
                                </DropdownToggle>
                                <DropdownMenu right>
                                <DropdownItem tag='a' onClick={() => setShowSingleUser(true)}>
                                    Komunikasi Pribadi
                                </DropdownItem>
                                <DropdownItem tag='a' onClick={() => setShowMultiUser(true)}>
                                    Komunikasi Group
                                </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>

                            <Button
                                id          = "buttonArchive"
                                size        = "sm"
                                color       = "white"
                                className   = "btn-icon m-0 p-0 "
                                onClick     = {() => {
                                }}
                            >
                                 
                                {
                                    openArchive ? (
                                        <X size={22} onClick={() => setOpenArchive(false)}/>
                                    ) : (
                                        <>
                                            <Tooltip
                                                placement='top'
                                                isOpen={archiveTooltip}
                                                target='buttonArchive'
                                                toggle={() => setArchiveTooltip(!archiveTooltip)}
                                            >
                                                Archive
                                            </Tooltip>
                                            <Archive size={22} onClick={() => setOpenArchive(true)}/>
                                        </>
                                    )
                                }
                            </Button>
                        </div>
                    </div>
                </CardHeader>
                <CardBody className="p-0 m-0" style={{ height : '100px', overflowY:'auto' }}>
                    {/* <InfiniteScroll
                         next        = {() => { setPage(page + 1) }}
                         loader      = {<h4 className='text-center'>Loading...</h4>}
                         hasMore     = {true}
                         height      = {700}
                         dataLength  = {listData === null ? 0 : listData.length} 
                    > */}
                        {
                            Array.isArray(listData) &&
                                listData?.map((item) => (
                                    <ListRoom 
                                        data        = {item}
                                        selected    = {selected}
                                        message     = {message}
                                        getMessage  = {getMessage}
                                        deleteRoom  = {deleteRoom}
                                        openArchive = {openArchive}
                                        archiveChat = {archiveChat}
                                        setSelected = {setSelected}
                                        setShowGroup = {setShowGroup}
                                        unArchiveChat = {unArchiveChat}
                                        setOpenArchive = {setOpenArchive}
                                        connectChatSocket = {connectChatSocket}
                                    />
                                ))
                        }
                    {/* </InfiniteScroll> */}
                </CardBody>
               
            </Card>
            
            <SelectSingleUser 
                show    = {showSingleUser}
                setShow = {setShowSingleUser}
                getList = {getList}
            />
            <SelectMultiUser 
                show    = {showMultiUser}
                setShow = {setShowMultiUser}
                getList = {getList}
            />

        </>
    )
}

export default ChatList