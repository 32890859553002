import React, { useState } from 'react'
import { File, Image, Link, Upload } from 'react-feather'
import { Button, CardFooter, Input, InputGroup } from 'reactstrap'
import serviceAPI from '../../../../service/drive/service'
import toast from 'react-hot-toast'
import HelpDeskAPI from '../../../../service/pages/helpdesk'
import InputLink from './InputLink'

const RenderCardFooter = ({selected, setMessage, message, sendMessage, uploadFileService}) => {

    const [showLink, setShowLink] = useState(false)

    
  return (
    <CardFooter
        className="p-1 py-1"
    >
        <div className="d-flex">
            <div
                className="d-flex"
            >
                <Button.Ripple 
                    tag         = 'label' 
                    color       = "light" 
                    className   = "p-1 me-1 rounded cursor-pointer"
                >
                    <Input 
                        type        = 'file' 
                        hidden 
                        accept      ="image/png, image/jpg, image/jpeg"
                        onChange    = {(e) => uploadFileService(e.target.files, 2) }
                    />
                    <Image 
                        size    = {16} 
                    />
                </Button.Ripple>     

                <Button.Ripple 
                    tag         = 'label' 
                    color       = "light" 
                    className   ="p-1 me-1 rounded cursor-pointer"
                >
                    <Input 
                        type        = 'file' 
                        hidden 
                        accept      =".doc, .docx, .pdf, .xlsx, .xls, .svg"
                        onChange    = {(e) => uploadFileService(e.target.files, 3) }

                    />
                    <File 
                        size    = {16} 
                    />
                </Button.Ripple>     

                <Button 
                    tag         = 'label' 
                    color       = "light" 
                    className   = "p-1 me-1 rounded cursor-pointer"
                    onClick={() => setShowLink(true)}

                >
                    <Link 
                        size    = {16} 
                    />
                </Button>     
            </div>
            <InputGroup>
                <Input 
                    id='message'
                    type="text"
                    placeholder="Aa.."
                    onChange={(e) => setMessage(e.target.value)}
                />
                <Button 
                    color='primary' 
                    disabled = {!message}
                    onClick={() => sendMessage(false, 1)}
                >
                    Send
                </Button>
            </InputGroup>
        </div>
        <InputLink 
                show    = {showLink}
                setShow = {setShowLink}
                setMessage ={setMessage}
                sendMessage = {sendMessage}
            />
    </CardFooter>
  )
}

export default RenderCardFooter
