// ** Third Party Components
import Proptypes 	from 'prop-types'
import classnames 	from 'classnames'
import { Col, Row } from 'reactstrap'
import moment 		from 'moment'

const Timeline = props => {
	// ** Props
	const { data, tag, className } = props

	// ** Custom Tagg
	const Tag = tag ? tag : 'ul'

	return (
		<Tag
			className={classnames('timeline', {
				[className]: className
			})}
		>
			{data.map((item, i) => {
				const ItemTag = item.tag ? item.tag : 'li'
				return (
					<Row key={`timeline_${i}`}>
						<Col md={3} className='text-right'>
							{item.created_at ? (
								<span
									className={classnames('timeline-event-time', {
										[item.metaClassName]: item.metaClassName
									})}
								>
									<p className='m-0' style={{textAlign: 'right'}}>
										{moment(item.created_at).format('HH.mm')}
									</p>
									<p style={{textAlign: 'right'}}>
										{moment(item.created_at).format('DD MMM YYYY')}
									</p>
								</span>
							) : null}
						</Col>
						<Col md={9}>
							<ItemTag
								key={i}
								className={classnames('timeline-item ms-2', {
									[item.className]: className
								})}
							>
								<span
									className={classnames('timeline-point', {
										[`timeline-point-${item.color}`]: item.color,
										'timeline-point-indicator': !item.icon
									})}
								>
									{item.icon ? item.icon : null}
								</span>
								<div className='timeline-event'>
									<div
										className={classnames('text-start d-flex1 justify-content-between1 flex-sm-row flex-column', {
											'mb-sm-0 mb-1': item.meta
										})}
									>
										<h6>
											{item.title} <br/>
										</h6>
										<div className='text-sm'>{item?.created_by?.name}</div>
									</div>
									<p
										className={classnames({
											'mb-0': i === data.length - 1 && !item.customContent
										})}
									>
										{item.content}
									</p>
									{item.customContent ? item.customContent : null}
								</div>
							</ItemTag>
						</Col>
					</Row>
				)
			})}
		</Tag>
	)
}

export default Timeline

// ** PropTypes
Timeline.propTypes = {
	tag: Proptypes.string,
	className: Proptypes.string,
	data: Proptypes.array.isRequired
}
