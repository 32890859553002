import { getUserData } from "../Utils"

const reportRules = (reportTypeId) => {

    const user = getUserData()

    const params = {}

    //Role ID
    //1 = Operator
    //2 = Pimpinan Pusat
    //3 = Pimpinan Daerah
    //4 = Pelaksana

    //Status
    //1 = Created
    //2 = Approve
    //3 = Process
    //4 = Decline
    //5 = Correct
    //6 = Disposisi
    //7 = Done
    //8 = Menunggu Persetujuan Perpanjangan
    //9 = Perpanjangan Disetujui
    //10 = Perpanjangan Ditolak

    if (parseInt(reportTypeId) === 1) { //IN.1
        if (user.role_id === 2) {
            // params.report_approvement_id = '1,5'
        } else if (user.role_id === 4) {
            // params.report_approvement_id = 2
        }
    } else if (parseInt(reportTypeId) === 2) { //IN.2
        // if (user.role_id === 4) {
        //     params.report_approvement_id = 3
        // }
    } else if (parseInt(reportTypeId) === 3) { //IN.3
        if (user.role_id === 2) {
            // params.report_approvement_id = '1,5'
        } else if (user.role_id === 4) {
            // params.report_approvement_id = '2,3,7'
        }  
    } else if (parseInt(reportTypeId) === 4) { //IN.4
        if (user.role_id === 4) {
            params.report_approvement_id = '1,5'
        }
    } else if (parseInt(reportTypeId) === 5) { //IN.5
        if (user.role_id === 4) {
            params.report_approvement_id = '1,5'
        }
    } else if (parseInt(reportTypeId) === 6) { //IN.6
        if (user.role_id === 2) {
            params.report_approvement_id = '1,5'
        } else if (user.role_id === 4) {
            params.report_approvement_id = '1,2'
        }
    } else if (parseInt(reportTypeId) === 7) { //IN.7
        if (user.role_id === 2) {
            params.report_approvement_id = '1,5'
        } else if (user.role_id === 4) {
            params.report_approvement_id = '1,2'
        }
    } else if (parseInt(reportTypeId) === 8) { //IN.8
        if (user.role_id === 2) {
            params.report_approvement_id = '1,5'
        } else if (user.role_id === 4) {
            params.report_approvement_id = '1,2'
        }
    } else if (parseInt(reportTypeId) === 9) { //IN.9
        if (user.role_id === 4) {
            params.report_approvement_id = '1,5'
        }
    } else if (parseInt(reportTypeId) === 10) { //IN.9
        if (user.role_id === 4) {
            params.report_approvement_id = '1,5'
        }
    } else if (parseInt(reportTypeId) === 10) { //IN.10
        if (user.role_id === 4) {
            params.report_approvement_id = '1,5'
        }
    } else if (parseInt(reportTypeId) === 11) { //IN.11
        if (user.role_id === 4) {
            params.report_approvement_id = '1,5'
        }
    } else if (parseInt(reportTypeId) === 12) { //IN.12
        if (user.role_id === 2) {
            params.report_approvement_id = '1,5'
        } else if (user.role_id === 4) {
            params.report_approvement_id = '1,2'
        }
    } else if (parseInt(reportTypeId) === 13) { //IN.13
        if (user.role_id === 2) {
            // params.report_approvement_id = '1,5'
        } else if (user.role_id === 4) {
            // params.report_approvement_id = '1,2'
        }
    } else if (parseInt(reportTypeId) === 14) { //IN.14
        if (user.role_id === 2) {
            params.report_approvement_id = '1,5'
        } else if (user.role_id === 4) {
            params.report_approvement_id = '1,2'
        }
    } else if (parseInt(reportTypeId) === 15) { //IN.15
        if (user.role_id === 2) {
            params.report_approvement_id = '1,5'
        } else if (user.role_id === 4) {
            params.report_approvement_id = '1,2'
        }
    } else if (parseInt(reportTypeId) === 16) { //IN.16
        if (user.role_id === 4) {
            params.report_approvement_id = '1'
        }
    } else if (parseInt(reportTypeId) === 17) { //IN.17
        if (user.role_id === 4) {
            params.report_approvement_id = '1'
        }
    } else if (parseInt(reportTypeId) === 18) { //IN.18
        if (user.role_id === 4) {
            params.report_approvement_id = '1'
        }
    } else if (parseInt(reportTypeId) === 19) { //IN.19
        if (user.role_id === 4) {
            params.report_approvement_id = '1'
        }
    } else if (parseInt(reportTypeId) === 20) { //IN.20
        // tidak ada penginputan
    } else if (parseInt(reportTypeId) === 21) { //IN.21
        if (user.role_id === 2) {
            // params.report_approvement_id = '1,5'
        } else if (user.role_id === 4) {
            // params.report_approvement_id = '1,2'
        }
    } else if (parseInt(reportTypeId) === 22) { //IN.22
        if (user.role_id === 4) {
            params.report_approvement_id = '1'
        }
    } else if (parseInt(reportTypeId) === 23) { //IN.23
        if (user.role_id === 4) {
            params.report_approvement_id = '1'
        }
    } 

    return params
}

export default reportRules