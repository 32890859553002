import React, { 
    useContext,
    useEffect,
    useState 
} from 'react'

import {
    Card,
    CardBody,
    ListGroup, 
    ListGroupItem, 
    Modal, 
    ModalBody, 
    ModalHeader 
}                                           from 'reactstrap'
import Avatar                               from '../avatar'
import RenderIconSecor                      from './renderIconSecor'

import ReactMapGL, { 
    Marker 
}                                           from 'react-map-gl'

import mapboxgl                             from 'mapbox-gl'
import { ReportContext }                    from '../../../context/reportContext'
import Helper from '../../../helper'

mapboxgl.accessToken = 'pk.eyJ1IjoiemVwaHlyZm4iLCJhIjoiY2trNzI5bWN1MDlubDJ1cW94Z3hicm9qdCJ9.HPNjaaL1I5rkMdSg1AJf5g'
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default

const ModalPreviewMap = (props) => {

    const {
        show,
        data,
        onClose,
        setDataSelected,
        reportData
    }                                       = props

    const { fallbackImage_ }                = Helper

    const {
        detailDinData, 
    } = useContext(ReportContext)

    const defaultVP = {
        width       : "100%",
        height      : "100%",
        latitude    : -2.0616,
        longitude   : 119.4005,
        zoom        : 4.4
        // latitude    : -6.144068016626316,
        // longitude   : 106.73166766866234,
        // zoom        : 10
    }

    const groupBy = (array, key) => {
        return array.reduce((result, currentValue) => {
          // Get the value of the nested key (e.g., sektor.id) from the current object
          const keyValue = currentValue[key]
          
          // If the key doesn't exist in the result object, create it and set it to an empty array
          if (!result[keyValue]) {
            result[keyValue] = []
          }
      
          // Push the current object to the corresponding array in the result object
          result[keyValue].push(currentValue)
      
          // Return the result object for the next iteration
          return result
        }, {}) // Initialize the result object as an empty object
      }

    const [viewport, setViewport]       = useState(defaultVP)
    const [groupedData, setGroupedData] = useState([])

    useEffect(() => {
        if (detailDinData?.detail) {
            const group = groupBy(detailDinData?.detail, 'sector_id')
            setGroupedData(group)
            console.log("groupedData", group)
            // console.log("groupedData", detailDinData)
        }
    }, [detailDinData])

    return (
        <div>
            <Modal
                isOpen      = {show}
                size        = "xl"
                onClosed    = {() => { 
                    setDataSelected([]) 
                }}
                centered
            >
                <ModalHeader
                    toggle={() => {
                        onClose()
                    }}
                >
                    Preview
                </ModalHeader>

                <ModalBody
                    className   = 'p-0 m-0'
                >
                    <div
                        className   = 'd-flex'
                    >
                        <div 
                            className='col-lg-3 col-md-5 col-6 p-0 m-0 overflow-auto'
                            style={{
                                height : 700
                            }}
                        >
                            <Card
                                className   = 'm-0 rounded-0 mb-1 text-white'
                                color       = "primary"
                            >
                                <CardBody>
                                    <div
                                        className='d-flex justify-content-between'
                                    >
                                        <div>
                                            {/* Daftar Sektor <br/> */}
                                            Data {reportData?.reportTitle}
                                            {detailDinData?.header?.name}
                                        </div>

                                        <div className='text-center'>
                                            <strong> {detailDinData?.header?.count}</strong> <br/> Berkas
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                            <ListGroup
                                className='rounded-0'
                            >
                            {
                                Object.keys(groupedData)?.map((key) => {
                                    return (
                                        <ListGroupItem>
                                            <div
                                                className='d-flex justify-content-between'
                                            >
                                                <div className='d-flex col-10'>
                                                    <div className='valign-middle'>
                                                        <img
                                                            className   = "me-1 rounded-circle"
                                                            src         = {groupedData[key][0]?.icon}
                                                            style       = {{
                                                                width   : '35px',
                                                                height  : '35px'
                                                            }}
                                                            onError     = {fallbackImage_}
                                                        />
                                                    </div>
                                                    <div className='text-start'>
                                                        {groupedData[key][0]?.name}
                                                    </div>
                                                </div>
                                                
                                                <div className='col-2 text-center'>
                                                    <strong>{groupedData[key].length}</strong> <br/> Berkas
                                                </div>
                                        </div>
                                        </ListGroupItem>
                                    )
                                })
                            }
                            </ListGroup>
                        </div>

                        <div className='col-lg-9 col-md-7 col-6 p-0 m-0'>

                            <ReactMapGL
                                mapboxApiAccessToken    = "pk.eyJ1IjoiemVwaHlyZm4iLCJhIjoiY2trNzI5bWN1MDlubDJ1cW94Z3hicm9qdCJ9.HPNjaaL1I5rkMdSg1AJf5g"
                                mapStyle                = {'mapbox://styles/mapbox/streets-v12'}
                                onMove                  = {(evt) => {
                                    setViewport(evt.viewState)
                                }}
                                attributionControl      = {false}
                                {...viewport}
                            >
                                {
                                    Array.isArray(detailDinData?.detail) &&
                                        detailDinData?.detail?.map((dt, idx) => {
                                            return (
                                                dt.coordinate?.map((coordinate) => {
                                                    return (
                                                        (!isNaN(coordinate[0]) && !isNaN(coordinate[1])) &&
                                                        <Marker
                                                            key         = {1}
                                                            latitude    = {coordinate[0]}
                                                            longitude   = {coordinate[1]}
                                                        >
                                                            {/* <Avatar
                                                                img         = {`${dt?.icon}`}
                                                                size        = "md"
                                                                className   = "me-1 border-white"
                                                            /> */}
                                                            <img
                                                                className   = "me-1 rounded-circle"
                                                                src         = {dt?.icon}
                                                                style       = {{
                                                                    width   : '30px',
                                                                    height  : '30px'
                                                                }}
                                                                onError     = {fallbackImage_}
                                                            />
                                                        </Marker>
                                                    )
                                                })
                                            )
                                        })
                                }
                                
                            </ReactMapGL>
                            <ListGroup
                                className='rounded-0'
                            >
                            
                            </ListGroup>
                            
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    )
}

export default ModalPreviewMap
