import { 
    ArrowLeft,
    File, 
    Plus, 
    Printer 
}                                   from "react-feather"

import { 
    Button 
}                                   from "reactstrap"

import OrganizationStructureCard    from "./OrganizationStructureCard"

const OrganizationStructureDetail = ({
    activeId,
    setActive
}) => {
    return (
        <>
            <div className="d-flex justify-content-between mb-1">
                <div>
                    <Button
                        size        = "sm"
                        color       = "primary"
                        className   = "btn-icon me-1"
                        onClick     = {() => {
                            setActive("list")
                        }}
                    >
                        <ArrowLeft

                        />
                    </Button>
                </div>
                {/* <div>
                    <Button
                        size        = "sm"
                        color       = "primary"
                        className   = "btn-icon me-1"
                    >
                        <Printer
                        />
                    </Button>

                    <Button
                        size        = "sm"
                        color       = "primary"
                        className   = "btn-icon"
                    >
                        <File
                        />
                    </Button>
                </div> */}
            </div>
            <OrganizationStructureCard
                id          = {activeId}
                type        = "detail"
            />
        </>
    )
}

export default OrganizationStructureDetail