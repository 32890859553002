import { useContext, useState } from 'react'
import { 
    File, 
    Image, 
    Link, 
    Send, 
    Smile 
}                               from 'react-feather'

import { 
    Button, 
    CardFooter, 
    Form, 
    Input, 
    InputGroup,
    InputGroupText
}                               from 'reactstrap'

import { useForm, Controller }  from 'react-hook-form'
import { yupResolver }          from '@hookform/resolvers/yup'
import * as yup                 from 'yup'


import InputLink                from './InputLink'
import EmojiPicker              from 'emoji-picker-react'

import { ChatContext }          from '../../../context/chatContext'

const ChatMessageFooter = () => {

    const {
        // Function
        sendMessage,
        sendAttachment
        
    } = useContext(ChatContext)

    
    const [text, setText]               = useState('')
    const [showLink, setShowLink]       = useState(false)
    const [showEmoji, setShowEmoji]     = useState(false)

      const onSubmit = (e) => {
        e.preventDefault()
        sendMessage({message : text}, 1)
        setText('')
      }

      const handleEmojiClick = (event) => {
        setText((prevText) => prevText + event.emoji)
      }

  return (
    <div>
       <CardFooter
                className="p-1 py-1"
            >
                <div className="d-flex">
                    <div
                        className="d-flex"
                    >
                        <Button.Ripple 
                            tag         = 'label' 
                            color       = "light" 
                            className   = "p-1 me-1 rounded cursor-pointer"
                        >
                            <Input 
                                type        = 'file' 
                                hidden 
                                accept      ="image/png, image/jpg, image/jpeg"
                                onChange    = {(e) => sendAttachment(e.target.files, 1) }
                            />
                            <Image 
                                size    = {16} 
                            />
                        </Button.Ripple>  

                        <Button.Ripple 
                            tag         = 'label' 
                            color       = "light" 
                            className   ="p-1 me-1 rounded cursor-pointer"
                        >
                            <Input 
                                type        = 'file' 
                                hidden 
                                accept      =".doc, .docx, .pdf, .xlsx, .xls, .svg"
                                onChange    = {(e) => sendAttachment(e.target.files, 4) }

                            />
                            <File 
                                size    = {16} 
                            />
                        </Button.Ripple>

                        <Button
                            size        ="sm"
                            color       ="light"
                            className   ="p-1 me-1"
                            onClick     ={() => setShowLink(true)}
                        >
                            <Link
                                size={16}
                            />
                        </Button>
                        {/* <Button
                            size        = "sm"
                            color       = "light"
                            className   = "p-1 me-1"
                            onClick     = {() => setShowEmoji(!showEmoji)}
                        >
                            <Smile
                                size={16}
                            />
                        </Button> */}
                        <div 
                            style       = {{ top : 0 }}
                            className   = {`${showEmoji ? 'h-100 w-100 position-absolute' : 'd-none' }`} 
                        >
                            <div 
                                style           = {{ bottom : 80 }}
                                className       = {`${showEmoji ? 'd-block' : 'd-none' } position-absolute`}
                                onMouseEnter    = {() => setShowEmoji(true)}
                                onMouseLeave    = {() => setShowEmoji(false)}
                            >
                                <EmojiPicker
                                    onEmojiClick={handleEmojiClick}
                                />
                            </div>
                        </div>
                    </div>
                    <Form onSubmit={(e) => onSubmit(e)} className='d-flex w-100'>
                        {/* <Input
                            type            = "text"
                            id              = 'message'
                            // defaultValue    = {text}
                            value           = {text}
                            onChange        = {(e) => setText(e.target.value)}
                        /> */}
                        <InputGroup className='input-group-merge '>
                            <InputGroupText>
                                <Smile  
                                    onClick     = {() => setShowEmoji(!showEmoji)}
                                    className   = 'cursor-pointer'/
                                >
                            </InputGroupText>
                            <Input
                                type            = "text"
                                id              = 'message'
                                // defaultValue    = {text}
                                placeholder     = 'Message'
                                value           = {text}
                                onChange        = {(e) => setText(e.target.value)}
                            />
                            
                        </InputGroup>
                        <Button
                            color="primary"
                            type='submit'
                        >
                            Kirim
                        </Button>
                        {/* <Button.Ripple 
                            // color='flat-primary'
                            type = "submit"
                            // onClick = {() => setSelected(null)}
                            className='btn-icon rounded-circle ' 
                        >
                            <Send />
                        </Button.Ripple> */}
                    </Form>
                </div>
            </CardFooter>

            <InputLink 
                show        = {showLink}
                setShow     = {setShowLink}
                sendMessage = {sendMessage}
            />

    </div>
  )
}

export default ChatMessageFooter
