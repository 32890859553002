import {get, delete_, post, put, patch}  from '../../index'

//get
const getDataPoint                     = (params) => get(`/performance/config/point`, params)
const getDataBadge                     = (params) => get(`/performance/config/badge`, params)

const getDataOrigin                     = (url, params) => get(`/performance/config/badge/origin`, params)

//post
const createPoint                       = (formData) => post('/performance/config/point/create', formData)
const createData                        = (url, formData) => post(`/performance/config/${url}/create`, formData)


// put
const editData                      = (url, id, formData) => put(`/performance/config/${url}/update/${id}`, formData)

const deleteData                    = (url, formData) => delete_(`/performance/config/${url}/delete/${formData}`)

const AssessmentIndicatorAPI = {
    //Get
    getDataPoint,
    getDataBadge,
    getDataOrigin,

    // Post
    createPoint,
    createData,

    editData,

    deleteData,
}

export default AssessmentIndicatorAPI