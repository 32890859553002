import { lazy } 		from 'react'
import { Navigate } 	from 'react-router-dom'

const Ranking = lazy(() => import('../../views/ranking'))

const RankingRoutes = [
	{
		path	: '/ranking',
		element	: <Ranking/>,
		meta	: {
			action: 'read',
			resource: 'papan-peringkat'
		}
	}
]

export default RankingRoutes
