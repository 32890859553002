import { lazy } from 'react'
import { Navigate } from 'react-router-dom'

const RIN1 = lazy(() => import('../../views/reports/rin/rin-1'))
const RIN2 = lazy(() => import('../../views/reports/rin/rin-2'))
const RIN3 = lazy(() => import('../../views/reports/rin/rin-3'))
const RIN4 = lazy(() => import('../../views/reports/rin/rin-4'))
const RIN5 = lazy(() => import('../../views/reports/rin/rin-5'))
const RIN6 = lazy(() => import('../../views/reports/rin/rin-6'))
const RIN7 = lazy(() => import('../../views/reports/rin/rin-7'))
const RIN8 = lazy(() => import('../../views/reports/rin/rin-8'))
const RIN9 = lazy(() => import('../../views/reports/rin/rin-9'))
const RIN10 = lazy(() => import('../../views/reports/rin/rin-10'))
const RIN11 = lazy(() => import('../../views/reports/rin/rin-11'))
const RIN12 = lazy(() => import('../../views/reports/rin/rin-12'))
const RIN13 = lazy(() => import('../../views/reports/rin/rin-13'))
const RIN14 = lazy(() => import('../../views/reports/rin/rin-14'))
const RIN15 = lazy(() => import('../../views/reports/rin/rin-15'))
const RIN16 = lazy(() => import('../../views/reports/rin/rin-16'))
const RIN17 = lazy(() => import('../../views/reports/rin/rin-17'))
const RIN18 = lazy(() => import('../../views/reports/rin/rin-18'))
const RIN19 = lazy(() => import('../../views/reports/rin/rin-19'))
const RIN20 = lazy(() => import('../../views/reports/rin/rin-20'))
const RIN21 = lazy(() => import('../../views/reports/rin/rin-21'))
const RIN22 = lazy(() => import('../../views/reports/rin/rin-22'))


const RinRoutes = [
	{
		path: '/rin',
		exact: true,
		element: <Navigate to='/rin/1' />,
		meta: {
			action: 'read',
			resource: 'rin-register'

		}
	},
	{
		path: "/rin/1",
		element: <RIN1 />,
		meta: {
			action: "read",
			resource: "rin1-surat-masuk"
		}
	},
	{
		path: "/rin/2",
		element: < RIN2 />,
		meta: {
			action: "read",
			resource: "rin2-surat-keluar"
		}
	},
	{
		path: "/rin/3",
		element: < RIN3 />,
		meta: {
			action: "read",
			resource: "rin3-kerja-intelijen"
		}
	},
	{
		path: "/rin/4",
		element: < RIN4 />,
		meta: {
			action: "read",
			resource: "rin4-ekspedisi-surat"
		}
	},
	{
		path: "/rin/5",
		element: < RIN5 />,
		meta: {
			action: "read",
			resource: "rin5-produk-intelijen"
		}
	},
	{
		path: "/rin/6",
		element: < RIN6 />,
		meta: {
			action: "read",
			resource: "rin6-arsip"
		}
	},
	{
		path: "/rin/7",
		element: < RIN7 />,
		meta: {
			action: "read",
			resource: "rin7-kegiatan-bid-ipolhankam"
		}
	},
	{
		path: "/rin/8",
		element: < RIN8 />,
		meta: {
			action: "read",
			resource: "rin8-kegiatan-bid-sosbudmas"
		}
	},
	{
		path: "/rin/9",
		element: < RIN9 />,
		meta: {
			action: "read",
			resource: "rin9-kegiatan-bid-ekonomi-dan-keuangan"
		}
	},
	{
		path: "/rin/10",
		element: < RIN10 />,
		meta: {
			action: "read",
			resource: "rin10-rk-pps"
		}
	},
	{
		path: "/rin/11",
		element: < RIN11 />,
		meta: {
			action: "read",
			resource: "rin11-rk-teknologi-informasi"
		}
	},
	{
		path: "/rin/12",
		element: < RIN12 />,
		meta: {
			action: "read",
			resource: "rin12-ops-intelijen-bid-ipolhankam"
		}
	},
	{
		path: "/rin/13",
		element: < RIN13 />,
		meta: {
			action: "read",
			resource: "rin13-ops-intelijen-bid-sosbudmas"
		}
	},
	{
		path: "/rin/14",
		element: < RIN14 />,
		meta: {
			action: "read",
			resource: "rin14-ops-intelijen-bid-ekonomi-dan-keuangan"
		}
	},
	{
		path: "/rin/15",
		element: < RIN15 />,
		meta: {
			action: "read",
			resource: "rin15-ops-intelijen-bid-pps"
		}
	},
	{
		path: "/rin/16",
		element: < RIN16 />,
		meta: {
			action: "read",
			resource: "rin16-ops-intelijen-bid-teknologi-informasi-dan-produk-intelijen"
		}
	},
	{
		path: "/rin/17",
		element: < RIN17 />,
		meta: {
			action: "read",
			resource: "rin17-berita-masuk"
		}
	},
	{
		path: "/rin/18",
		element: < RIN18 />,
		meta: {
			action: "read",
			resource: "rin18-berita-keluar"
		}
	},
	{
		path: "/rin/19",
		element: < RIN19 />,
		meta: {
			action: "read",
			resource: "rin19-telaahan-intelijen"
		}
	},
	{
		path: "/rin/20",
		element: < RIN20 />,
		meta: {
			action: "read",
			resource: "rin20-ekspedisi-berita"
		}
	},
	{
		path: "/rin/21",
		element: < RIN21 />,
		meta: {
			action: "read",
			resource: "rin21-tamu-pos-pelayanan"
		}
	},
	{
		path: "/rin/22",
		element: < RIN22 />,
		meta: {
			action: "read",
			resource: "rin22-pelaksanaan-kegiatan-penerangan-hukum-dan-penyuluhan-hukum"
		}
	}
]

export default RinRoutes