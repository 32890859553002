import { 
    Button,
    Card,
    CardBody,
    Modal, 
    ModalBody, 
    ModalFooter
}               from "reactstrap"

import { 
    ChevronLeft, 
    ChevronRight 
}               from "react-feather"

import { 
    useState 
}               from "react"

import RecapitulationDetailChart        from "./RecapitulationDetailChart"
import RecapitulationDetailTableCount   from "./RecapitulationDetailTableCount"
import RecapitulationDetailTableData    from "./RecapitulationDetailTableData"

const RecapitulationDetail = ({
    show, 
    setShow,
    size,
    data,
    selected
}) => {

    const [active, setActive] = useState(1)

    const renderUI = () => {
        if (active === 1) {
            return <RecapitulationDetailChart />
        } else if (active === 2) {
            return <RecapitulationDetailTableCount />
        } else if (active === 3) {
            return <RecapitulationDetailTableData />
        }
    }

    return (
        <>
            <Modal
                isOpen  = {show} 
                toggle  = {() => { setShow(!show) }} 
                size    = {size || "sm"}
            >
                <ModalBody>
                    <div
                        className="col-12 d-flex justify-contnet-between"
                    >
                        {/* <div
                            className="col-1"
                        >
                            {
                                active !== 1 &&
                                    <Button
                                        onClick = {() => {
                                            setActive(active - 1)
                                        }}
                                        style   = {{
                                            marginTop : '270px'
                                        }}
                                    >
                                        <ChevronLeft />
                                    </Button>
                            }
                        </div> */}

                        <div className="col-12">
                            {/* {renderUI()} */}
                            <RecapitulationDetailChart 
                                data = {data}
                                selected = {selected}
                            />
                        </div>

                        {/* <div
                            className="col-1"
                        >
                        {
                            active !== 3 &&
                                <Button
                                    onClick = {() => {
                                        setActive(active + 1)
                                    }}
                                    style   = {{
                                        marginTop : '270px'
                                    }}
                                >
                                    <ChevronRight />
                                </Button>
                        }
                        </div> */}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button 
                        className   = "m-auto"
                        onClick     = {() => {
                            setShow(false)
                        }}
                    >
                        Tutup
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default RecapitulationDetail