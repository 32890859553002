import moment from 'moment'
import React, { useState } from 'react'
import { Download, File } from 'react-feather'
import Viewer from 'react-viewer'

const RenderCardMessage = ({item, index, userData}) => {
  const [viewImage, setViewImage] = useState(false)

  
  return (
    <div
        key={index}
        className= {item.sender === userData.user_id ? 'chat-right text-white' : 'chat-left'}
    >
        <div 
          className='chat-body m-0' 
        >
          {
            item.kind === 4 && item?.attachment?.length === 0 ?
              <div key={item.id} className='chat-content'>
                <p>{item.message}</p>
                <div className="text-muted text-end"><small>{moment(item?.created_at).format('DD-MM-YYYY')}</small></div>
              </div>
            : 
              <div key={item.id} className='chat-content'>
                  {
                    item.kind === 1 ? 
                    <a 
                        href    = {item.message} 
                        target  ='__blank'
                        style   = {{color : '#0000EE'}}
                    >
                      <u>{item.message}</u>
                  </a>
                    : item.kind === 2 ?
                      <img 
                        src={Array.isArray(item.attachment) && item.attachment[0].url_file} 
                        width={300} 
                        height={200} 
                        onClick={() => setViewImage(true)}
                        className='cursor-pointer'
                      />
                    : 
                      <a 
                        href={Array.isArray(item.attachment) && item.attachment[0].url_file} 
                        target='_blank'
                        className= {item.sender === userData.user_id ? 'text-white  d-flex me-1 ' : ' d-flex me-1 '}
                      >
                        <div className='me-1'>
                          {item.sender === userData.user_id ? <Download /> :  <File />}
                        </div>
                        <p>{item.message}</p>
                        <div className='ms-1'>
                          {item.sender === userData.user_id ? <File /> : <Download />  }
                        </div>
                      </a>
                  }
                   
                <div className="text-muted text-end"><small>{moment(item?.created_at).format('DD-MM-YYYY')}</small></div>
              </div>
          }
        </div>
        <Viewer 
          visible = {viewImage}
          onClose = {() => { setViewImage(false) } }
          images  = {[{src: `${window.location.origin}${Array.isArray(item.attachment) && item?.attachment[0]?.url_file}}`, alt: 'foto'}]}
        />

    </div>
  )
}

export default RenderCardMessage
