import { 
    useContext, 
    useEffect, 
    useState 
}                       from 'react'

import { 
    File, 
    Download,
    Trash2,
    MoreVertical,
    X, 
}                       from 'react-feather'

import {  
    Col,
    DropdownItem, 
    DropdownMenu, 
    DropdownToggle, 
    Label, 
    Row, 
    UncontrolledDropdown 
}                       from 'reactstrap'

import moment           from 'moment'
import classNames       from 'classnames'
import { ChatContext }  from '../../../context/chatContext'
import Viewer from 'react-viewer'
import { right } from '@popperjs/core'
import userManagementAPI from '../../../service/pages/user-management'

const CardMessage = ({item, index}) => {


    const {    
        // Function
        deleteMessage,
        invisibleMessage
    } = useContext(ChatContext)

    const userData                      =  JSON.parse(localStorage.getItem('userData'))
    const [viewImage, setViewImage]     = useState(false)
    const [createdBy, setCreatedBy]     = useState(false)
    const [optionHover, setOptionHover] = useState(false)


    const getDetailData = (id) => {

        if (id && !isNaN(parseInt(id))) {
            const params = {
                user : id
            }
    
            userManagementAPI.getUserManagement(params).then(
                res => {
                    if (res.results) {
                        setCreatedBy(res?.results[0])
                    }
                }
            )
        }
    }

    const handleCreatedBy = (item) => {
        if (item?.created_by) {
            return item?.created_by
        } else {
            return item?.created_by_id
        }
    }

    useEffect(() => {
        getDetailData(handleCreatedBy(item))
    }, [])

  return (
   
    <div 
        key         = {index}
        className   = {classNames('chat', {
            'chat-left': handleCreatedBy(item) !== userData.user_id
        })}
    >   
        <div 
            className='chat-body m-0'
          
        >
            <div
                 key             = {item.id} 
                 className       = 'chat-content cursor-pointer d-flex justify-content-around h-100'
                 onMouseEnter    = {() => setOptionHover(true)}
                 onMouseLeave    = {() => setOptionHover(false)}
                 style={{
                     borderRadius : '10px',
                     maxWidth : '500px'
                 }}
             >
                
                <div>
                    <div className='d-flex justify-content-between mb-1 '>
                        <Label className={`d-flex ${handleCreatedBy(item) !== userData.user_id ? 'justify-content-start' : 'justify-content-end text-white'}`}>
                            {createdBy?.name}
                        </Label>
                        <UncontrolledDropdown 
                            className={ `${optionHover ? '' : 'chat-hover-none'} `}
                        >
                            <DropdownToggle 
                                tag         = 'div' 
                                className   = 'cursor-pointer float-left'
                            >
                                <MoreVertical />
                            </DropdownToggle>
                            <DropdownMenu 
                                right       = {
                                    item.created_by === userData.user_id 
                                }
                                className   = 'cursor-pointer'
                            >
                                <DropdownItem tag = "a" onClick = {() => deleteMessage(item.id)}>
                                    <Trash2 size={14} /> Hapus Percakapan untuk Semua
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                    <div>
                        {/* Attachment */}
                        {
                            item.content_type === 3 && 
                                item?.message_attachments.length > 0 && item?.message_attachments[0].content_type === 1 ? (
                                    <img 
                                        src     = {item?.message_attachments[0].content}
                                        style   = {{ width : '100%' }} 
                                        height  = {200}
                                        onClick = {() => setViewImage(true)}
                                    />
                                ) : item?.message_attachments.length > 0 && item?.message_attachments[0].content_type === 4 && (
                                    <div>
                                        <a 
                                            href        = {Array.isArray(item.message_attachments) && item?.message_attachments[0].content} 
                                            target      = '_blank'
                                            className   = {`${handleCreatedBy(item) === userData.user_id && 'text-white'} d-flex align-items-center`}
                                        >   
                                            <div>
                                                {item.created_by === userData.user_id ? <Download /> : <File/>}
                                            </div>
                                            <p className='mx-2'>
                                                {item.content}
                                            </p>
                                            <div>
                                                {item.created_by === userData.user_id ? <File/> : <Download />}
                                            </div>
                                        </a>
                                    </div>
                                )
                        }
                        {/* Link */}
                        {
                             item.content_type === 2 && (
                                <div>
                                    <a 
                                        href    = {item.content} 
                                        target  ='__blank'
                                        style   = {{divor : '#0000EE', color :'inherit'}}
                                        // className='w-100'
                                    >
                                        <u>{item.content}</u>
                                    </a>
                                </div>
                             )
                        }
                        {/* Text */}
                        {
                             item.content_type === 1 && (
                                <p>{item.content}</p>
                             )
                        }
                        
                    </div>
                    <div className = "text-muted text-end"><small>{moment(item?.created_at).format('DD-MM-YYYY, h:mm')}</small></div>  
                </div>
                
            </div>
             
        </div>
        <Viewer 
          visible = {viewImage}
          onClose = {() => { setViewImage(false) } }
          images  = {[{src: `${Array.isArray(item.message_attachments) && item?.message_attachments[0]?.content}`, alt: 'foto'}]}
        />
    </div>
  )
}

export default CardMessage
