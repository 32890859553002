import React, { useContext, useEffect, useState }    from 'react'
import { BarChart2, MessageCircle, Share, Share2 } from 'react-feather'
import { 
    Card, 
    Button,
    CardBody, 
    CardHeader, 
    NavItem,
    NavLink,
    Nav,
    TabContent,
    TabPane,
    Media,
    InputGroup,
    Input,
    Label,
    FormFeedback,
    Row,
    Col
}               from 'reactstrap'
import { DataManagerContext } from '../../../context/dataManagerContext'
import { getInitials } from '@utils'

import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import Avatar from '../avatar'
import moment from 'moment'
import DetailShare from '../../../views/data-management/component/DetailShare'

const schema = yup.object().shape({
    text: yup.string().required('Commnet is required'),
  })

const DetailDrive = ({data, setShow}) => {

    const {
        listDetail,
        createComment,
        getDriveDetail,
    } = useContext(DataManagerContext)

    const { handleSubmit, control, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
      })

    const [active, setActive] = useState('activity')

  const toggle = tab => {
    if (active !== tab) {
      setActive(tab)
    }
  }

  const onSubmit = (comment) => {
    createComment(data, comment)
  }

  useEffect(() => {
    getDriveDetail(data.code, active)
  }, [active])
  return (
    <div>
      <Card className='mt-5'>
            <CardHeader tag={'h4'} className='d-flex justify-content-between'>
                <span>
                    {data && data.name}
                </span>
                <Button
                    color           = "primary"
                    className       = "btn-icon me-1"
                    onClick         = {() => {
                        setShow(false)
                    }}
                >
                    Tutup
                </Button>

            </CardHeader>
            <CardBody >
            <hr/>

                <Nav tabs justified>
                    <NavItem>
                    <NavLink
                        active={active === 'activity'}
                        onClick={() => {
                        toggle('activity')
                        }}
                    >
                        <BarChart2 />
                        Aktifitas
                    </NavLink>
                    </NavItem>
                    <NavItem>
                    <NavLink
                        active={active === 'comment'}
                        onClick={() => {
                        toggle('comment')
                        }}
                    >
                        <MessageCircle />
                        Komentar
                    </NavLink>
                    </NavItem>
                    
                    <NavItem>
                    <NavLink
                        active={active === '3'}
                        onClick={() => {
                        toggle('3')
                        }}
                    >
                        <Share2 />
                        Bagikan
                    </NavLink>
                    </NavItem>
                </Nav>
                <hr/>
                <TabContent>
                    {
                        active === 'activity' ? (
                            <div>
                                {
                                    Array.isArray(listDetail) && listDetail.map((item) => (
                                        <Media className='d-flex'>
                                            <Media left className='me-1'> 
                                                <Avatar  
                                                    src = {(item && item?.photo !== "") ? { img: item?.photo, imgWidth: 40, imgHeight: 40 } : {content : item ? getInitials(item?.name) : getInitials('Anonym'), color : 'light-danger'}}
                                                />
                                            </Media>
                                            <Media>
                                                <Media>
                                                    {item.description}
                                                </Media>
                                                <Media>
                                                    {moment(item.created_at).format('DD-MM-YYYY')}
                                                </Media>
                                            </Media>
                                        </Media>
                                    ))
                                }
                            </div>
                        ) : active === 'comment' ? (
                            <div>
                                <form onSubmit={handleSubmit(onSubmit)} >
                                    <Row>
                                        <Col xs={12} md={9}>
                                            <Label>Comment</Label>
                                            <Controller
                                                name        ="text"
                                                control     ={control}
                                                render      ={({ field }) => (
                                                    <Input
                                                        {...field} 
                                                        invalid             = {errors.text && true}
                                                        placeholder         = "Deskripsi"
                                                    />
                                                )}
                                            />
                                            {errors.text && <FormFeedback>{errors.text.message}</FormFeedback>}
                                        </Col>
                                        <Col xs={12} md={3}>
                                            <Button className='mt-2' type='submit'>Kirim</Button>
                                        </Col>
                                    </Row>
                                </form>
                                {
                                    Array.isArray(listDetail) && listDetail.map((item) => (
                                        <Media className='d-flex mt-2'>
                                            <Media left className='me-1'> 
                                                <Avatar  
                                                    src = {(item && item?.photo !== "") ? { img: item?.photo, imgWidth: 40, imgHeight: 40 } : {content : item ? getInitials(item?.name) : getInitials('Anonym'), color : 'light-danger'}}
                                                />
                                            </Media>
                                            <Media>
                                                <Media>
                                                    {item.description}
                                                </Media>
                                                <Media>
                                                    {moment(item.created_at).format('DD-MM-YYYY')}
                                                </Media>
                                            </Media>
                                        </Media>
                                    ))
                                }
                            </div>
                        ) : (
                            <div>
                                <DetailShare
                                    data = {data}
                                />
                            </div>
                        )
                    }
                </TabContent>
            </CardBody>
        </Card>
    </div>
  )
}

export default DetailDrive
