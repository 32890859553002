import { 
    useState,
    createContext,
    useEffect
}                       from "react"

//Context
const SectorContext  = createContext(null)

//API
import sectorAPI      from "../service/pages/sector"

const SectorProvider = ({ children }) => {

    //State
    const [params, setParams]                   = useState({})
    const [listData, setListData]               = useState([])
    const [isLoading, setIsLoading]             = useState(false)
    const [pagination, setPagination]           = useState(false)
    const [sectorOption, setSectorOption]       = useState([])
    
    //Get data workunit
    const getData = (params) => {
        setIsLoading(true)
        
        sectorAPI.getSector(params).then(
            res => {

                setIsLoading(false)

                const formatData_ = []

                if (res.count !== 0) {
                    res.results.map((data, idx) => {
                        data.index          = idx + 1
                        data.workunit       = data.name
                        formatData_.push(data)
                    })
                }

                const pagination = {
                    count       : res.count,
                    page        : res.page,
                    page_size   : res.page_size,
                    next        : res.next,
                    previous    : res.previous,
                    total_data  : res.count
                }
                
                setListData(formatData_)
                setPagination(pagination)
            }
        ).catch(
            err => {
                console.log(err, 'err')
            }
        )
    }

    const getDataAll = () => {
        
        sectorAPI.getSector({
            page_size : 100
        }).then(
            res => {

                const formatData_ = []

                if (res.count !== 0) {
                    res.results.map((data) => {
                        formatData_.push({
                            label : data?.name,
                            value : data?.id
                        })
                    })

                    setSectorOption(formatData_)
                }

            }
        ).catch(
            err => {
                console.log(err, 'err')
            }
        )
    }

    useEffect(() => {
        if (localStorage.getItem('userData')) {
            getDataAll()
        }
    }, [])

    return <SectorContext.Provider
        value={{
            params,
            setParams,

            listData,
            setListData,

            pagination,
            setPagination,

            isLoading,
            setIsLoading,

            getData,
            sectorOption
        }}
    > 
        {children}
    </SectorContext.Provider>
}

export { SectorContext, SectorProvider }